import { useCallback, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useCurrentRefinements, useInstantSearch } from 'react-instantsearch'
import { useIntl } from 'react-intl'
import { NetworkStatus } from '@apollo/client'
import { observer } from 'mobx-react'

import { EditEmployeeFormModal } from 'Modules/employees/components/EditEmployeeFormModal/EditEmployeeFormModal'
import { EmployeesFilterColumn } from 'Modules/employees/components/EmployeesFilterColumn/EmployeesFilterColumn'
import { EmployeesPreviewColumn } from 'Modules/employees/components/EmployeesPreviewColumn/EmployeesPreviewColumn'
import { EmployeesTable as EmployeesTableDefault } from 'Modules/employees/components/EmployeesTable/EmployeesTable'
import { EmployeesTable } from 'Modules/employees/components/EmployeesTableInstantsearch/EmployeesTable'

import { Card } from 'Components/_theme'
import { ContentSidebar } from 'Components/ContentSidebar/ContentSidebar'
import { FaqCard } from 'Components/FaqCard/FaqCard'
import { ImpactTracker } from 'Components/ImpactTracker/ImpactTracker'
import { ObserveMeOnceIAmMounted } from 'Components/ObserveMeOnceIAmMounted/ObserveMeOnceIAmMounted'
import { PageErrorState } from 'Components/PageErrorState/PageErrorState'
import { PageHeader } from 'Components/PageHeader/PageHeader'
import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'
import { TableFooter } from 'Components/TableFooter/TableFooter'
import { TableHeader } from 'Components/TableHeader/TableHeader'

import { useStore } from 'Stores/index'

import { useDefaultListTable, useModal, useSidebarVisibility } from 'Hooks'

import { toggleIntercomVisibility } from 'Utilities'

import {
  E2E_EMPLOYEES_PAGE_NEW_EMPLOYEE,
  E2E_LIST_FILTER_BUTTON,
} from 'Constants/e2e'
import {
  getOrganisationEmployees,
  getOrganisationEmployeesVariables,
} from 'Constants/graphql/queries/listing/__generated__/getOrganisationEmployees'
import { GET_ORGANISATION_EMPLOYEES } from 'Constants/graphql/queries/listing/GetEmployees'
import { PortalSectionEnum } from 'Constants/portalSection'

import { GoToPage } from 'Algolia/components/GoToPage/GoToPage'
import { PageSizeSelect } from 'Algolia/components/PageSizeSelect/PageSizeSelect'
import { Pagination } from 'Algolia/components/Pagination/Pagination'

import { ReportType } from 'Portal/__generated__/globalTypes'

export const EmployeesContent = observer(() => {
  const { portalConfigStore, portalSettingsStore, organisationStore } =
    useStore()
  const { portalConfig } = portalConfigStore
  const { isAppReadOnly } = portalSettingsStore
  const { fetchOrganisationCostCenters } = organisationStore

  const intl = useIntl()

  const { items: activeFilters } = useCurrentRefinements()
  const shouldUseDefaultListTable = activeFilters.length === 0

  const {
    data: dataDefaultListTable,
    error: errorDefaultListTable,
    hasNextPage: hasNextPageDefaultListTable,
    intersectionObserver: intersectionObserverDefaultListTable,
    isLoading: isLoadingDefaultListTable,
    networkStatus: networkStatusDefaultListTable,
    totalCount: totalCountDefaultListTable,
  } = useDefaultListTable<
    getOrganisationEmployees,
    getOrganisationEmployeesVariables
  >({
    dataObjectPropertyName: 'employees',
    isDefaultListTableInactive: !shouldUseDefaultListTable,
    organisationQuery: GET_ORGANISATION_EMPLOYEES,
  })

  const [selectedEntityId, setSelectedEntityId] = useState('')
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null)
  const [isPreviewColumnOpen, setIsPreviewColumnOpen] = useState(false)
  const [isFilterColumnOpen, setIsFilterColumnOpen] = useState(false)
  const isSidebarOpen = isPreviewColumnOpen || isFilterColumnOpen
  const { isVisible: isPreviewColumnVisible } = useSidebarVisibility(
    isPreviewColumnOpen,
    isSidebarOpen,
  )
  const { isVisible: isFilterColumnVisible } = useSidebarVisibility(
    isFilterColumnOpen,
    isSidebarOpen,
  )

  const { status, results, error, refresh } = useInstantSearch()
  const isLoading = status === 'loading' && !results.hits
  const totalCount = results?.nbHits ?? 0

  const handleCloseFilterColumn = useCallback(() => {
    setIsFilterColumnOpen(false)
  }, [])

  const handleOpenPreviewColumn = useCallback(
    (id: string, rowIndex: number | null) => {
      handleCloseFilterColumn()

      toggleIntercomVisibility(false)

      setSelectedEntityId(id)
      setSelectedRowIndex(rowIndex)
      setIsPreviewColumnOpen(true)
    },
    [handleCloseFilterColumn],
  )

  const handleClosePreviewColumn = useCallback(() => {
    toggleIntercomVisibility(true)

    setIsPreviewColumnOpen(false)
    setSelectedRowIndex(null)
  }, [])

  const handleOpenFilterColumn = useCallback(() => {
    handleClosePreviewColumn()

    setIsFilterColumnOpen(true)
  }, [handleClosePreviewColumn])

  const canCreateNewEmployee = portalConfig?.createEmployee && !isAppReadOnly

  const { setModal, hideModal } = useModal()

  const handleOpenEditEmployeeModal = useCallback(() => {
    setModal({
      component: EditEmployeeFormModal,
      isOpen: true,
      props: {
        onHide: hideModal,
        onSuccess: (refetchCostCenters: boolean) => {
          refresh()

          if (refetchCostCenters) {
            fetchOrganisationCostCenters()
          }

          hideModal()
        },
      },
    })
  }, [fetchOrganisationCostCenters, hideModal, refresh, setModal])

  if (shouldUseDefaultListTable ? errorDefaultListTable : error) {
    return <PageErrorState />
  }

  if (
    shouldUseDefaultListTable
      ? isLoadingDefaultListTable && !dataDefaultListTable
      : isLoading
  ) {
    return <PageLoadingState />
  }

  if (shouldUseDefaultListTable && !dataDefaultListTable) {
    return <PageLoadingState />
  }

  return (
    <Container className="pt-6" fluid>
      <div role="banner">
        <PageHeader portalSection={PortalSectionEnum.Employees} />
      </div>

      <Row>
        <Col xl={12} xxl={8}>
          <Card className="mb-4">
            <TableHeader
              buttonE2ESelector={E2E_EMPLOYEES_PAGE_NEW_EMPLOYEE}
              buttonIsVisible={!!canCreateNewEmployee}
              buttonLabel={intl.formatMessage({ id: 'New_employee' })}
              buttonOnClick={handleOpenEditEmployeeModal}
              downloadReportType={ReportType.employees}
              filterButtonE2ESelector={E2E_LIST_FILTER_BUTTON}
              filterButtonOnClick={handleOpenFilterColumn}
              hideFilterButton={!!error}
              totalCount={
                shouldUseDefaultListTable
                  ? totalCountDefaultListTable
                  : totalCount
              }
            />

            {shouldUseDefaultListTable ? (
              <Row>
                <Col>
                  <EmployeesTableDefault
                    employees={dataDefaultListTable}
                    isLoading={isLoadingDefaultListTable}
                    isSearching={
                      networkStatusDefaultListTable ===
                      NetworkStatus.setVariables
                    }
                    onOpenPreviewColumn={handleOpenPreviewColumn}
                    selectedRowIndex={selectedRowIndex}
                  />
                </Col>
              </Row>
            ) : (
              <EmployeesTable
                isLoading={isLoading}
                onOpenPreviewColumn={handleOpenPreviewColumn}
                selectedRowIndex={selectedRowIndex}
              />
            )}

            {!shouldUseDefaultListTable && (
              <TableFooter>
                <PageSizeSelect />

                <Pagination totalCount={totalCount} />

                <GoToPage totalCount={totalCount} />
              </TableFooter>
            )}
          </Card>

          {shouldUseDefaultListTable && (
            <Row className="p-1">
              <Col>
                {hasNextPageDefaultListTable && !isLoadingDefaultListTable && (
                  <ObserveMeOnceIAmMounted
                    intersectionObserver={intersectionObserverDefaultListTable}
                  />
                )}
              </Col>
            </Row>
          )}
        </Col>

        <ContentSidebar
          isSidebarOpen={isSidebarOpen}
          renderStickyColumnSideContent={
            <>
              <ImpactTracker />
              <FaqCard />
            </>
          }
        >
          <EmployeesPreviewColumn
            entityId={selectedEntityId}
            isVisible={isPreviewColumnVisible}
            onClosePreviewColumn={handleClosePreviewColumn}
          />

          <EmployeesFilterColumn
            isVisible={isFilterColumnVisible}
            onCloseFilterColumn={handleCloseFilterColumn}
          />
        </ContentSidebar>
      </Row>
    </Container>
  )
})
