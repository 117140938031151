/* eslint-disable sort-keys-fix/sort-keys-fix */
export const dataPrivacyTranslations = {
  DataPrivacy_footer_title: 'Données personnelles',
  DataPrivacy_Section1_heading: 'Privacy Policy',
  DataPrivacy_Section1_heading2: 'Introduction',
  DataPrivacy_Section1_line1:
    'This privacy policy provides you with an overview of the processing of your personal data at everphone GmbH (hereinafter referred to as "everphone") and the purpose for which this is done. In doing so, we inform you about the legal framework as well as the rights to which you are entitled. ',
  DataPrivacy_Section1_line2: 'Responsible for the portal is: ',
  DataPrivacy_Section1_line3: 'Everphone GmbH',
  DataPrivacy_Section1_line4: 'Zimmerstraße 79-80',
  DataPrivacy_Section1_line5: '10117 Berlin',
  DataPrivacy_Section1_line6: 'Deutschland',
  DataPrivacy_Section1_line7: 'Phone',
  DataPrivacy_Section1_line8: 'Email',
  DataPrivacy_Section1_line9: 'Website',
  DataPrivacy_Section1_line10: 'Our',
  DataPrivacy_Section1_line11: ' data protection officer ',
  DataPrivacy_Section1_line12:
    'is responsible for monitoring and ensuring compliance with data protection: ',
  DataPrivacy_Section1_line13: 'Dr. Sebastian Kraska, ',
  DataPrivacy_Section1_line14:
    'In the following, we have compiled the most important information on typical data processing separately according to data subject groups for you. ',
  DataPrivacy_Section2_heading: 'Website visitors',
  DataPrivacy_Section2_line1:
    'For the operation of our website and the provision of our services, we process automatically collected information in so-called server log files, which your browser automatically transmits to us: ',
  DataPrivacy_Section2_listItem1:
    'IP address of your device (in anonymized form)',
  DataPrivacy_Section2_listItem2:
    'Device data, i.e. the operating system, browser type and browser version',
  DataPrivacy_Section2_listItem3:
    'The so-called referrer URL, i.e. the address that you have visited immediately before',
  DataPrivacy_Section2_listItem4: 'The amount of data transferred',
  DataPrivacy_Section2_listItem5:
    'Message about successful retrieval (http response code)',
  DataPrivacy_Section2_listItem6:
    "Websites that are called up by the user's system via our website",
  DataPrivacy_Section2_listItem7: 'Internet service provider of the user',
  DataPrivacy_Section2_listItem8:
    'The host name of the accessing device and the ',
  DataPrivacy_Section2_listItem9: 'Time and the date of the server request. ',
  DataPrivacy_Section2_line2:
    ' We process this data during an informational visit exclusively in non-personal form. This is done in order to enable the use of the Internet pages you have accessed in the first place, and in order to be able to check whether our websites are being optimally displayed to you.',
  DataPrivacy_Section2_subheading1:
    'Automatically collected data (server log files)',
  DataPrivacy_Section2_subheading4: 'Deletion periods',
  DataPrivacy_Section2_subheading10: 'Third party',
  DataPrivacy_Section2_subheading10_line1:
    'Third party is a natural or legal person, public authority, agency or body other than the data subject, controller, processor and persons who, under the direct authority of the controller or processor, are authorised to process personal data.',
  DataPrivacy_Section2_subheading11: 'Consent',
  DataPrivacy_Section2_subheading11_line1:
    "Consent of the data subject is any freely given, specific, informed and unambiguous indication of the data subject's wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her.",
  DataPrivacy_Section2_subheading1_line1:
    'The purpose of data processing is to operate our website and to ensure its permanent availability, functionality and security.',
  DataPrivacy_Section2_subheading2: 'Purpose of data processing',
  DataPrivacy_Section2_subheading2_line1:
    'The legal basis for this data processing is Art. 6 (1) (f) GDPR, based on our legitimate interest in the proper optimization of our website.',
  DataPrivacy_Section2_subheading3: 'Legal basis for data processing',
  DataPrivacy_Section2_subheading3_line1:
    'Your data will be stored until the contractual and/or legal retention periods are fulfilled.',
  DataPrivacy_Section3_heading: 'Use of Google tools',
  DataPrivacy_Section3_subheading1: ' Data processing by Google Analytics',
  DataPrivacy_Section3_subheading2: 'Legal basis',
  DataPrivacy_Section3_subheading3: 'Deletion periods',

  DataPrivacy_Section3_line1:
    'Based on your consent, the everphone portal uses functions of the web analytics service Google Analytics of Google Ireland Limited, Gordon House, Barrow Street, Dublin, D04 E5W5, Ireland.',
  DataPrivacy_Section3_line2:
    'Google Analytics also uses so-called "cookies". These are text files that are stored on your computer and enable an analysis of your use of the website. The information generated by the cookie about your use of the website is usually transmitted to and stored by Google on servers in the United States.',
  DataPrivacy_Section3_line3:
    "By using Google's IP anonymization on the website, your IP address will be truncated beforehand by Google within member states of the European Union or in other contracting states to the Agreement on the European Economic Area. Only in exceptional cases will the full IP address be transmitted to a Google server in the USA and shortened there. On behalf of the operator of the website, Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity and providing other services relating to website activity and internet usage to the website operator. The IP address transmitted by your browser as part of Google Analytics will not be merged with other data from Google.",
  DataPrivacy_Section3_line4:
    'You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website. You can also prevent the collection of data generated by the cookie and related to your use of the website (including your IP address) to Google, as well as the processing of this data by Google, by downloading and installing the browser plugin available at the following link: ',
  DataPrivacy_Section3_line5:
    'We use Google Analytics to analyze and regularly improve the use of our website. Through the statistics obtained, we optimize our offer and make it more interesting for you as a user.',
  DataPrivacy_Section3_line6: 'You can view the user conditions of Google at ',
  DataPrivacy_Section3_line7:
    ',as well as the privacy policy of Google can be found at ',
  DataPrivacy_Section3_line8:
    'The legal basis for data processing is Art. 6 para. 1 a) GDPR.',
  DataPrivacy_Section3_line9:
    'This user-related data is automatically deleted after 14 months.',
  DataPrivacy_Section4_heading: 'Cookies',
  DataPrivacy_Section4_subheading1: 'Purpose of data processing',
  DataPrivacy_Section4_line1:
    'We use cookies and other similar technologies to operate our website. When you first visit our website, we will ask you for permission to store certain non-essential cookies and other similar technologies on your device. It is entirely up to you whether you agree or not (although some aspects of the Website may not work as well if you do not), and you can change your mind at any time. ',
  DataPrivacy_Section4_line2:
    'The purpose of the data processing regarding the required cookies is to offer the services of everphone.',
  DataPrivacy_Section4_line3:
    'The legal basis for the processing of personal data regarding required cookies is our legitimate interest according to Art. 6 (1) f) GDPR.',
  DataPrivacy_Section4_line4:
    'Your data will be passed on to external service providers or other contractors, among others, for data processing and hosting. In addition, also to other external bodies, insofar as the data subject has given his consent or a transfer is permissible for predominant interest. ',
  DataPrivacy_Section4_line5:
    'Your data will be stored until the contractual and/or legal retention periods are fulfilled.',
  DataPrivacy_Section5_heading: 'Inquiries via contact form in the portal ',
  DataPrivacy_Section5_subheading1: 'Type of data ',
  DataPrivacy_Section5_subheading2: 'Purpose of data processing',
  DataPrivacy_Section5_subheading3: 'Legal basis',
  DataPrivacy_Section5_subheading4: 'Categories of recipients',
  DataPrivacy_Section5_subheading5: 'Deletion periods ',
  DataPrivacy_Section4_subheading2: 'Legal basis',
  DataPrivacy_Section4_subheading3: 'Categories of recipients',
  DataPrivacy_Section4_subheading4: 'Deletion periods ',
  DataPrivacy_Section5_line1:
    'If you would like to contact us via our contact form in our portal, we require an e-mail address, your first and last name and your organization, as well as the topic you would like to talk to us about. ',
  DataPrivacy_Section5_line2:
    'The purpose of data processing is to respond to the request or contact. ',
  DataPrivacy_Section5_line3:
    'The legal basis for data processing is our legitimate interest in processing your request Art. 6 para. 1 f) GDPR and Art. 6 para. 1 b) GDPR, the fulfillment of the contract between everphone and your employer.',
  DataPrivacy_Section5_line4:
    'Your data will be shared with external service providers or other contractors, among others, for data processing and hosting. In addition, also to other external parties, insofar as the data subject has given his/her consent or a transfer is permissible for predominant interest. ',
  DataPrivacy_Section5_line5:
    'Your data will be stored until the contractual and/or legal retention periods are fulfilled.',
  DataPrivacy_Section6_heading: 'Requests via chat in the portal',
  DataPrivacy_Section6_subheading1: 'Type of data ',
  DataPrivacy_Section6_subheading2: 'Purpose of data processing ',
  DataPrivacy_Section6_subheading3: ' Legal basis ',
  DataPrivacy_Section6_subheading4: 'Categories of recipients ',
  DataPrivacy_Section6_subheading5: 'Deletion periods ',
  DataPrivacy_Section6_line1:
    'If you would like to contact us via chat in our portal, we require from you an e-mail address, your first and last name and your organization, as well as the topic you would like to talk to us about. ',
  DataPrivacy_Section6_line2:
    'The purpose of data processing is to respond to the request or contact. ',
  DataPrivacy_Section6_line3:
    'The legal basis for data processing is our legitimate interest in processing your request Art. 6 para. 1 f) GDPR and Art. 6 para. 1 b) GDPR, the fulfillment of the contract between everphone and your employer.',
  DataPrivacy_Section7_heading: 'Ordering a mobile device',
  DataPrivacy_Section7_subheading1: 'Type of data ',
  DataPrivacy_Section7_subheading2: 'Purpose of data processing',
  DataPrivacy_Section7_subheading3: 'Legal basis',
  DataPrivacy_Section7_subheading4: 'Categories of recipients',
  DataPrivacy_Section7_subheading5: 'Deletion periods ',
  DataPrivacy_Section6_line5:
    'Your data will be stored until the contractual and/or legal retention periods are fulfilled.',
  DataPrivacy_Section6_line4:
    'Your data will be shared with external service providers or other contractors, among others, for data processing and hosting. In addition, also to other external parties, insofar as the data subject has given his/her consent or a transfer is permissible for predominant interest. ',
  DataPrivacy_Section7_line1:
    "If your employer has decided to make use of everphone's device-as-a-service solution, the following data has been transmitted to everphone by your employer as the data controller in this respect:",
  DataPrivacy_Section7_listItem1: 'First and last name',
  DataPrivacy_Section7_listItem2: 'Professional e-mail address',
  DataPrivacy_Section7_listItem3: 'Professional address',
  DataPrivacy_Section7_listItem4: 'IT service provider ',
  DataPrivacy_Section7_listItem5:
    'Financing and payment service providers and banks ',
  DataPrivacy_Section7_listItem6: 'Logistics service providers',
  DataPrivacy_Section7_listItem7: 'Insurance companies',
  DataPrivacy_Section7_listItem8: 'Advice and consulting ',
  DataPrivacy_Section7_listItem9: 'Sales partners',
  DataPrivacy_Section7_listItem10: 'Technical partners',
  DataPrivacy_Section7_listItem11: 'Government agencies (e.g. tax authorities)',
  DataPrivacy_Section7_listItem12: 'Backup service',
  DataPrivacy_Section7_line6:
    'Recipients not related to a corporate group (special cases), esp:',
  DataPrivacy_Section7_listItem13:
    'Government agencies (e.g., courts and public prosecutors)',
  DataPrivacy_Section7_listItem14:
    'Potential and actual acquirers of the company',
  DataPrivacy_Section7_line7:
    'Recipients with reference to the corporate group (see section 9) ',
  DataPrivacy_Section7_line8:
    'After the contract has been completely fulfilled, your data will be blocked with regard to tax and commercial law retention periods and deleted after these periods have expired, unless you have expressly consented to further use of your data or we have reserved the right to further use of data that is permitted by law.',
  DataPrivacy_Section7_line2:
    'Optionally, you can enter an alternative address for delivery of the device in our portal. Using your professional e-mail as well as the password sent to you, you can log in to our portal.',
  DataPrivacy_Section7_line3:
    'The purpose of processing your personal data is to prepare, establish, execute and terminate the contract with your employer as well as to process the payment by your employer and to fulfill the related legal obligations. ',
  DataPrivacy_Section8_heading: 'Transfer of personal data to third countries ',
  DataPrivacy_Section7_line4:
    'The legal basis for processing in the case of contracts with legal entities is Art. 6 (1) b) GDPR (contract performance and implementation of pre-contractual measures) and Art. 6 (1) f) GDPR (legitimate interest, namely communication with contact persons relevant to the contract) and always Art. 6 (1) c) GDPR (statutory obligations, in particular tax and commercial law provisions).',
  DataPrivacy_Section7_line5:
    'We only pass on your personal data to other recipients if this is necessary to fulfill the purposes described, you have given us your consent for this, or we are obliged to do so by law or due to a court or official order. Recipients without corporate group reference (in the context of the normal commencement, execution and termination of our contract) esp: ',
  DataPrivacy_Section8_line1:
    'In principle, your personal data is processed in Germany and in other European countries. If, by way of exception, your personal data is processed in countries outside the European Union or the European Economic Area (so-called third countries), this only takes place insofar as certain protective measures ensure that an appropriate level of data protection exists for this purpose. Typically, we take the following protective measures for this purpose:',
  DataPrivacy_Section8_listItem1:
    'Adequacy Decision of the EU Commission: recipients in Andorra, Argentina, Canada, Faroe Islands, Guernsey, Israel, Isle of Man, Japan, Jersey, New Zealand, Switzerland, Uruguay, UK (For more information, please visit ',
  DataPrivacy_Section8_listItem2:
    'Standard Contractual Clauses: Other Recipients (Further information at ',
  DataPrivacy_Section8_listItem3:
    'Exemptions according to Art. 49 GDPR: Other recipients ',
  DataPrivacy_Section8_line2:
    'Further information about third country transfers or copies of these measures can be obtained at the contact addresses listed.',
  DataPrivacy_Section9_heading:
    'Transfer of personal data within group of companies ',
  DataPrivacy_Section9_line1:
    'We are part of the everphone group and work together with other group companies on a division of labor basis. Personal data is only transferred to other group companies if there is a legal basis for doing so and if this is necessary for one of the purposes listed above. The everphone group has concluded a group-wide agreement on the handling of personal data.',
  DataPrivacy_Section10_heading: 'Data subject rights ',
  DataPrivacy_Section10_line1: 'You can contact us at any time by e-mail at ',
  DataPrivacy_Section10_line2:
    ' regarding the rights to which you are entitled and other questions on the subject of personal data with regard to everphone as the data protection officer responsible for the operation of the portal. For inquiries regarding your data as an employee, please contact your employer as the data protection officer.',
  DataPrivacy_Section10_line3:
    'You have the right to revoke your consent at any time without giving reasons with effect for the future.',
  DataPrivacy_Section10_line4:
    'You have the right to information about your stored personal data, its origin and recipient and the purpose of data processing at any time. ',
  DataPrivacy_Section10_line5:
    'You have the right to correct your stored data at any time. ',
  DataPrivacy_Section10_line6:
    'You have the right to have your stored data deleted or blocked for further processing at any time if we are obliged to retain your data (e.g. invoices) due to statutory retention obligations, see also restriction of data processing.',
  DataPrivacy_Section10_line7:
    'You have the right to restrict data processing:',
  DataPrivacy_Section10_listItem1:
    'if you dispute the accuracy of your data and cannot correct it yourself;',
  DataPrivacy_Section10_listItem2:
    'if the processing should be unlawful, but you yourself do not wish it to be deleted',
  DataPrivacy_Section10_listItem3:
    'if we as the controller no longer need your data in principle, but you should need it for the assertion, exercise or defense of legal claims',
  DataPrivacy_Section10_listItem4:
    'if you have lodged an objection to data processing and it has not yet been determined whether legitimate reasons on our part for further data processing outweigh your reasons for objecting.',
  DataPrivacy_Section10_line8:
    'If you have given your consent to the processing of your data, you may revoke it at any time. Such revocation will affect the permissibility of processing your personal data after you have expressed it to us.',
  DataPrivacy_Section10_line9:
    'If requested by you, we will make your data that you have provided to us personally available to you in a structured, common and machine-readable format and / or transfer it to another responsible party, the latter as far as this is technically possible. ',
  DataPrivacy_Section10_line10:
    'You have the right to complain to the supervisory authority for data protection responsible for us at any time.',

  DataPrivacy_Section10_subheading1: 'Right of revocation',
  DataPrivacy_Status: 'Status: October 2022',

  DataPrivacy_Section10_subheading2: 'Right to information',
  DataPrivacy_Section10_subheading3: 'Right to rectification',
  DataPrivacy_Section10_subheading4: 'Right to deletion / blocking',
  DataPrivacy_Section10_subheading5: 'Right to restrict processing',
  DataPrivacy_Section10_subheading6: ' Right to object to processing',
  DataPrivacy_Section10_subheading7: 'Right to data portability',
  DataPrivacy_Section10_subheading8:
    'Right to complain to the supervisory authority  ',
}
