import { gql } from '@apollo/client'

export const GET_ORGANISATION_DEPOTS = gql`
  query getOrganisationDepots(
    $id: ID!
    $first: Int!
    $after: Int!
    $searchterm: String
  ) {
    organisation(id: $id) {
      id
      depots(
        pagination: { after: $after, first: $first }
        searchterm: $searchterm
      ) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        depots {
          id
          locationName
          isDepotOfTheReturn
          stocklistItems {
            totalCount
          }
        }
      }
    }
  }
`

export const GET_ORGANISATION_DEPOTS_FOR_DROPDOWN = gql`
  query getOrganisationDepotsForDropdown($id: ID!, $rentalId: ID) {
    organisation(id: $id) {
      id
      depots(rentalId: $rentalId) {
        depots {
          id
          locationName
        }
      }
    }
  }
`
