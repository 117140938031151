// General portal site gloabal config or settings like languages
// should be limited to only global settings.
import { action, computed, makeObservable, observable } from 'mobx'

import { PreviewColumnTypeEnum } from 'Components/PreviewColumn/PreviewColumn.types'

import { AVAILABLE_LANGUAGES } from 'Constants/locale'
import { PortalSectionEnum } from 'Constants/portalSection'

import { getPreferredLanguage } from 'Portal/src/utilities/locale/getPreferredLanguage.util'

import type { RootStore } from '..'
import {
  I18nConfig,
  PreviewColumnSetting,
  PreviewColumnSettingProps,
  UserModeEnum,
} from './portalSettingsStore.types'

export class PortalSettingsStore {
  i18n: I18nConfig = AVAILABLE_LANGUAGES[getPreferredLanguage()]

  isCompactSidebar: boolean = false

  currentPortalSection: PortalSectionEnum = PortalSectionEnum.Dashboard

  isMenuVisible: boolean = false

  isUserwayEnabled: boolean = false

  isUserwayInitialised: boolean = false

  isAppReady: boolean = false

  isAppReadOnly: boolean = false

  userMode: `${UserModeEnum}` = UserModeEnum.DEFAULT

  previewColumnSettings: PreviewColumnSetting[] | [] = []

  constructor(private readonly store: RootStore) {
    makeObservable(this, {
      currentPortalSection: observable,
      i18n: observable,
      initOrganisationPortalSettings: action,
      isAppReadOnly: observable,
      isAppReady: observable,
      isCompactSidebar: observable,
      isMenuVisible: observable,
      isNewJoiner: computed,
      isUserwayActive: computed,
      isUserwayEnabled: observable,
      isUserwayInitialised: observable,
      previewColumnSettings: observable,
      setCurrentPortalSection: action,
      setI18n: action,
      setIsAppReadOnly: action,
      setIsAppReady: action,
      setIsCompactSidebar: action,
      setIsUserwayEnabled: action,
      setIsUserwayInitialised: action,
      setPreviewColumnInitialiseSettings: action,
      setPreviewColumnSettings: action,
      setTogglePreviewColumn: action,
      setUserMode: action,
      toggleMenuVisibility: action,
      userMode: observable,
    })
  }

  get isNewJoiner() {
    return this.userMode === UserModeEnum.NEW_JOINER
  }

  get isUserwayActive() {
    if (this.isUserwayEnabled && this.isUserwayInitialised) {
      window.UserWay.iconVisibilityOn()

      return true
    }

    if (this.isUserwayInitialised) {
      window.UserWay.iconVisibilityOff()
    }

    return false
  }

  setI18n = (in18Config: I18nConfig) => {
    document.documentElement.setAttribute('lang', in18Config.language)

    this.i18n = in18Config
  }

  setIsCompactSidebar = (isCompact: boolean) => {
    this.isCompactSidebar = isCompact
  }

  setCurrentPortalSection = (currentSection: PortalSectionEnum) => {
    this.currentPortalSection = currentSection
  }

  toggleMenuVisibility = () => {
    this.isMenuVisible = !this.isMenuVisible
  }

  setIsUserwayEnabled = (isEnabled: boolean) => {
    this.isUserwayEnabled = isEnabled
  }

  setIsUserwayInitialised = () => {
    this.isUserwayInitialised = true
  }

  setTogglePreviewColumn = (type: PreviewColumnTypeEnum, isOpen: boolean) => {
    const updatedPreviewColumnSettings =
      this.previewColumnSettings.map<PreviewColumnSetting>(
        previewColumnSetting => {
          if (previewColumnSetting.type === type) {
            return {
              ...previewColumnSetting,
              isOpen,
            }
          }

          return previewColumnSetting
        },
      )

    this.setPreviewColumnSettings(updatedPreviewColumnSettings)
  }

  setPreviewColumnSettings = (
    previewColumnSettings: PreviewColumnSetting[],
  ) => {
    this.previewColumnSettings = previewColumnSettings
  }

  setPreviewColumnInitialiseSettings = (
    previewColumnSetting: PreviewColumnSettingProps,
  ) => {
    this.previewColumnSettings = [
      {
        ...previewColumnSetting,
        isOpen: false,
      },
    ]
  }

  setIsAppReady = (isReady: boolean) => {
    this.isAppReady = isReady
  }

  setIsAppReadOnly = (isReadOnly: boolean) => {
    this.isAppReadOnly = isReadOnly
  }

  setUserMode = (userMode: `${UserModeEnum}`) => {
    this.userMode = userMode
  }

  initOrganisationPortalSettings = (organisationId: string) => {
    const currentAclsOrganisation =
      this.store.aclsStore.allowedAcls?.organisations.organisations?.find(
        ({ id }) => id.toString() === organisationId.toString(),
      )

    if (currentAclsOrganisation) {
      this.setIsAppReadOnly(currentAclsOrganisation.isReadOnly)
    }
  }
}
