import { forwardRef, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { clsx } from 'clsx'
import { isArray } from 'lodash-es'
import Slider from 'rc-slider'

import { RangeInputProps } from './RangeInput.types'

export const RangeInput = forwardRef<HTMLDivElement, RangeInputProps>(
  (props, ref) => {
    const {
      containerClassName,
      className,
      isInvalid,
      max,
      min,
      onChange,
      range = false,
      step,
      type = 'simple',
      value,
      e2eSelector,
    } = props

    const [localValue, setLocalValue] = useState<number | number[]>(
      value ?? [min, max],
    )
    const intl = useIntl()

    const handleChange = (value: number | number[]) => {
      setLocalValue(value)

      if (typeof onChange === 'function') {
        onChange(value)
      }
    }

    const ticks = useMemo(() => {
      if (type !== 'ticks') {
        return undefined
      }

      return Array.from(
        { length: (max - min) / step + 1 },
        (_, i) => min + i * step,
      ).reduce((obj, key) => Object.assign(obj, { [key]: key }), {})
    }, [max, min, step, type])

    const renderValueInput = (value: number | number[]) => {
      if (isArray(value)) {
        return (
          <span className="RangeInput-controls">
            <input
              aria-label={intl.formatMessage({ id: 'RangeInput_min_value' })}
              className="RangeInput-input"
              max={value[1]}
              min={min}
              onChange={event =>
                handleChange([Number(event.target.value), value[1]])
              }
              step={step}
              type="number"
              value={value[0]}
            />
            -
            <input
              aria-label={intl.formatMessage({ id: 'RangeInput_max_value' })}
              className="RangeInput-input"
              max={max}
              min={value[0]}
              onChange={event =>
                handleChange([value[0], Number(event.target.value)])
              }
              step={step}
              type="number"
              value={value[1]}
            />
          </span>
        )
      }

      return (
        <input
          aria-label={intl.formatMessage({ id: 'RangeInput_value' })}
          className="RangeInput-input"
          max={max}
          min={min}
          onChange={event => handleChange(Number(event.target.value))}
          step={step}
          type="number"
          value={value}
        />
      )
    }

    const renderSelectedValue = (value: number | number[]) => {
      switch (type) {
        case 'simple':
          return (
            <div className="RangeInput-legend d-flex justify-content-between">
              <div>
                <span className="RangeInput-selected-value">
                  {intl.formatMessage({ id: 'RangeInput_selectedValue' })}:
                </span>

                {isArray(value) ? `${value[0]} - ${value[1]}` : value}
              </div>

              <span className="RangeInput-legend-max">
                {intl.formatMessage({ id: 'RangeInput_max_value' })}: {max}
              </span>
            </div>
          )

        case 'input':
          return (
            <div className="RangeInput-legend d-flex justify-content-between">
              <div>
                <span className="RangeInput-selected-value">
                  {intl.formatMessage({ id: 'RangeInput_selectedValue' })}:
                </span>

                {renderValueInput(value)}
              </div>

              <span className="RangeInput-legend-max">
                {intl.formatMessage({ id: 'RangeInput_max_value' })}: {max}
              </span>
            </div>
          )

        default:
          return null
      }
    }

    useEffect(() => {
      setLocalValue(value ?? [min, max])
    }, [max, min, value])

    const containerClassNames = clsx(
      containerClassName,
      isInvalid && 'RangeInput-invalid',
      'RangeInput',
    )
    const classNames = clsx(
      className,
      type === 'ticks' && 'RangeInput-with-ticks',
    )

    return (
      <div className={containerClassNames} data-e2e={e2eSelector}>
        <Slider
          ref={ref}
          ariaLabelForHandle={intl.formatMessage(
            {
              id: 'RangeInput_aria_label',
            },
            { max, min },
          )}
          className={classNames}
          data-testid="SLIDER_COMPONENT"
          marks={ticks}
          max={max}
          min={min}
          onChange={handleChange}
          range={range}
          step={step}
          value={localValue}
        />

        {renderSelectedValue(localValue)}
      </div>
    )
  },
)
