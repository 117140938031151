import { AccountRoutePathsEnum } from 'Modules/account/routes/accountRouteConfig.types'
import { AuthRoutePathsEnum } from 'Modules/auth/routes/authRouteConfig.types'
import { ContactRoutePathsEnum } from 'Modules/contact/routes/contactRouteConfig.types'
import { DataPrivacyRoutePathsEnum } from 'Modules/dataPrivacy/routes/dataPrivacyRouteConfig.types'
import { DepotsRoutePathsEnum } from 'Modules/depots/routes/depotsRouteConfig.types'
import { EmployeesRoutePathsEnum } from 'Modules/employees/routes/employeesRouteConfig.types'
import { InvoicesRoutePathsEnum } from 'Modules/invoices/routes/invoicesRouteConfig.types'
import { OrderRoutePathsEnum } from 'Modules/order/routes/orderRouteConfig.types'
import { OrdersRoutePathsEnum } from 'Modules/orders/routes/ordersRouteConfig.types'
import { PhonePlansRoutePathsEnum } from 'Modules/phonePlans/routes/phonePlansRouteConfig.types'
import { PurchaseRoutePathsEnum } from 'Modules/purchase/routes/purchaseRouteConfig.types'
import { RentalsRoutePathsEnum } from 'Modules/rentals/routes/rentalsRouteConfig.types'
import { ReturnsRoutePathsEnum } from 'Modules/returns/routes/returnsRouteConfig.types'
import { SearchResultsRoutePathsEnum } from 'Modules/searchResults/routes/searchResultsRouteConfig.types'
import { SitemapRoutePathsEnum } from 'Modules/sitemap/routes/sitemapRouteConfig.types'

import {
  EMPLOYEE_ADMIN_ID,
  ERNST_YOUNG_ORG_ID,
  EVERPHONE_ORG_ID,
} from 'Tests/e2e/constants/common'
import { E2EDepotIdsEnum } from 'Tests/e2e/types/depot.types'
import { E2EEmployeeIdsEnum } from 'Tests/e2e/types/employee.types'
import { OrderIdsEnum } from 'Tests/e2e/types/order.types'
import { E2EPhonePlanIdsEnum } from 'Tests/e2e/types/phonePlan.types'

import { GlobalRoutePathsEnum, GlobalRouteTypesEnum } from './routeConfig.types'

export const GLOBAL_ROUTE_PATHS = {
  [GlobalRouteTypesEnum.EMPLOYEE]: {
    dashboard: GlobalRoutePathsEnum.EMPLOYEE,
    order: `${GlobalRoutePathsEnum.EMPLOYEE}/${OrdersRoutePathsEnum.ORDER}`,
    orderConfirmation: `${GlobalRoutePathsEnum.EMPLOYEE}/${OrderRoutePathsEnum.ORDER_CONFIRMATION}`,
    orderCustomConfirmation: `${GlobalRoutePathsEnum.EMPLOYEE}/${OrderRoutePathsEnum.ORDER_CUSTOM_CONFIRMATION}`,
    orderEarlyUpgrade: `${GlobalRoutePathsEnum.EMPLOYEE}/${OrderRoutePathsEnum.ORDER_EARLY_UPGRADE}`,
    orderLostReplacement: `${GlobalRoutePathsEnum.EMPLOYEE}/${OrderRoutePathsEnum.ORDER_LOST_REPLACEMENT}`,
    orderNew: `${GlobalRoutePathsEnum.EMPLOYEE}/${OrderRoutePathsEnum.ORDER_NEW}`,
    orderProlong: `${GlobalRoutePathsEnum.EMPLOYEE}/${OrderRoutePathsEnum.ORDER_PROLONG}`,
    orderProlongConfirmation: `${GlobalRoutePathsEnum.EMPLOYEE}/${OrderRoutePathsEnum.ORDER_PROLONG_CONFIRMATION}`,
    orderRepairUpgrade: `${GlobalRoutePathsEnum.EMPLOYEE}/${OrderRoutePathsEnum.ORDER_REPAIR_UPGRADE}`,
    orderUpgrade: `${GlobalRoutePathsEnum.EMPLOYEE}/${OrderRoutePathsEnum.ORDER_UPGRADE}`,
    orders: `${GlobalRoutePathsEnum.EMPLOYEE}/${OrdersRoutePathsEnum.ORDERS}`,
    rental: `${GlobalRoutePathsEnum.EMPLOYEE}/${RentalsRoutePathsEnum.RENTAL}`,
    rentals: `${GlobalRoutePathsEnum.EMPLOYEE}/${RentalsRoutePathsEnum.RENTALS}`,
    return: `${GlobalRoutePathsEnum.EMPLOYEE}/${ReturnsRoutePathsEnum.RETURN}`,
  },
  [GlobalRouteTypesEnum.ORGANISATION]: {
    dashboard: GlobalRoutePathsEnum.ORGANISATION,
    depot: `${GlobalRoutePathsEnum.ORGANISATION}/${DepotsRoutePathsEnum.DEPOT}`,
    depots: `${GlobalRoutePathsEnum.ORGANISATION}/${DepotsRoutePathsEnum.DEPOTS}`,
    employee: `${GlobalRoutePathsEnum.ORGANISATION}/${EmployeesRoutePathsEnum.EMPLOYEE}`,
    employeeOrderConfirmation: `${GlobalRoutePathsEnum.ORGANISATION}/${EmployeesRoutePathsEnum.EMPLOYEE}/${OrderRoutePathsEnum.ORDER_CONFIRMATION}`,
    employeeOrderCustomConfirmation: `${GlobalRoutePathsEnum.ORGANISATION}/${EmployeesRoutePathsEnum.EMPLOYEE}/${OrderRoutePathsEnum.ORDER_CUSTOM_CONFIRMATION}`,
    employeeOrderEarlyUpgrade: `${GlobalRoutePathsEnum.ORGANISATION}/${EmployeesRoutePathsEnum.EMPLOYEE}/${OrderRoutePathsEnum.ORDER_EARLY_UPGRADE}`,
    employeeOrderLostReplacement: `${GlobalRoutePathsEnum.ORGANISATION}/${EmployeesRoutePathsEnum.EMPLOYEE}/${OrderRoutePathsEnum.ORDER_LOST_REPLACEMENT}`,
    employeeOrderNew: `${GlobalRoutePathsEnum.ORGANISATION}/${EmployeesRoutePathsEnum.EMPLOYEE}/${OrderRoutePathsEnum.ORDER_NEW}`,
    employeeOrderProlong: `${GlobalRoutePathsEnum.ORGANISATION}/${EmployeesRoutePathsEnum.EMPLOYEE}/${OrderRoutePathsEnum.ORDER_PROLONG}`,
    employeeOrderProlongConfirmation: `${GlobalRoutePathsEnum.ORGANISATION}/${EmployeesRoutePathsEnum.EMPLOYEE}/${OrderRoutePathsEnum.ORDER_PROLONG_CONFIRMATION}`,
    employeeOrderRepairUpgrade: `${GlobalRoutePathsEnum.ORGANISATION}/${EmployeesRoutePathsEnum.EMPLOYEE}/${OrderRoutePathsEnum.ORDER_REPAIR_UPGRADE}`,
    employeeOrderUpgrade: `${GlobalRoutePathsEnum.ORGANISATION}/${EmployeesRoutePathsEnum.EMPLOYEE}/${OrderRoutePathsEnum.ORDER_UPGRADE}`,
    employees: `${GlobalRoutePathsEnum.ORGANISATION}/${EmployeesRoutePathsEnum.EMPLOYEES}`,
    invoices: `${GlobalRoutePathsEnum.ORGANISATION}/${InvoicesRoutePathsEnum.INVOICES}`,
    order: `${GlobalRoutePathsEnum.ORGANISATION}/${OrdersRoutePathsEnum.ORDER}`,
    orderConfirmation: `${GlobalRoutePathsEnum.ORGANISATION}/${OrderRoutePathsEnum.ORDER_CONFIRMATION}`,
    orderCustomConfirmation: `${GlobalRoutePathsEnum.ORGANISATION}/${OrderRoutePathsEnum.ORDER_CUSTOM_CONFIRMATION}`,
    orderEarlyUpgrade: `${GlobalRoutePathsEnum.ORGANISATION}/${OrderRoutePathsEnum.ORDER_EARLY_UPGRADE}`,
    orderLostReplacement: `${GlobalRoutePathsEnum.ORGANISATION}/${OrderRoutePathsEnum.ORDER_LOST_REPLACEMENT}`,
    orderNew: `${GlobalRoutePathsEnum.ORGANISATION}/${OrderRoutePathsEnum.ORDER_NEW}`,
    orderProlong: `${GlobalRoutePathsEnum.ORGANISATION}/${OrderRoutePathsEnum.ORDER_PROLONG}`,
    orderProlongConfirmation: `${GlobalRoutePathsEnum.ORGANISATION}/${OrderRoutePathsEnum.ORDER_PROLONG_CONFIRMATION}`,
    orderRepairUpgrade: `${GlobalRoutePathsEnum.ORGANISATION}/${OrderRoutePathsEnum.ORDER_REPAIR_UPGRADE}`,
    orderUpgrade: `${GlobalRoutePathsEnum.ORGANISATION}/${OrderRoutePathsEnum.ORDER_UPGRADE}`,
    orders: `${GlobalRoutePathsEnum.ORGANISATION}/${OrdersRoutePathsEnum.ORDERS}`,
    phonePlan: `${GlobalRoutePathsEnum.ORGANISATION}/${PhonePlansRoutePathsEnum.PHONE_PLAN}`,
    phonePlans: `${GlobalRoutePathsEnum.ORGANISATION}/${PhonePlansRoutePathsEnum.PHONE_PLANS}`,
    rental: `${GlobalRoutePathsEnum.ORGANISATION}/${RentalsRoutePathsEnum.RENTAL}`,
    rentals: `${GlobalRoutePathsEnum.ORGANISATION}/${RentalsRoutePathsEnum.RENTALS}`,
    return: `${GlobalRoutePathsEnum.ORGANISATION}/${ReturnsRoutePathsEnum.RETURN}`,
    returns: `${GlobalRoutePathsEnum.ORGANISATION}/${ReturnsRoutePathsEnum.RETURNS}`,
    searchResults: `${GlobalRoutePathsEnum.ORGANISATION}/${SearchResultsRoutePathsEnum.SEARCH_RESULTS}`,
  },
  [GlobalRouteTypesEnum.PUBLIC]: {
    contact: `/${ContactRoutePathsEnum.CONTACT}`,
    dataPrivacy: `/${DataPrivacyRoutePathsEnum.DATA_PRIVACY}`,
    login: `/${AuthRoutePathsEnum.LOGIN}`,
    offer: `/${PurchaseRoutePathsEnum.OFFER}`,
    offerAccepted: `/${PurchaseRoutePathsEnum.OFFER_ACCEPTED}`,
    offerDeclined: `/${PurchaseRoutePathsEnum.OFFER_DECLINED}`,
    paymentFailed: `/${PurchaseRoutePathsEnum.PAYMENT_FAILED}`,
    purchaseDevice: `/${PurchaseRoutePathsEnum.PURCHASE_DEVICE}`,
    purchaseSuccessful: `/${PurchaseRoutePathsEnum.PURCHASE_SUCCESSFUL}`,
    sitemap: `/${SitemapRoutePathsEnum.SITEMAP}`,
    telekom: `/${AuthRoutePathsEnum.TELEKOM}`,
  },
  [GlobalRouteTypesEnum.PRIVATE]: {
    account: `/${AccountRoutePathsEnum.ACCOUNT}`,
  },
} as const

// params are for e2e test
export const ROUTER_REDIRECTS = [
  {
    from: `${GlobalRoutePathsEnum.ORGANISATION}/contact`,
    params: {
      organisationId: ERNST_YOUNG_ORG_ID,
    },
    to: GLOBAL_ROUTE_PATHS.public.contact,
  },
  {
    from: `${GlobalRoutePathsEnum.EMPLOYEE}/contact`,
    params: {
      employeeId: EMPLOYEE_ADMIN_ID,
    },
    to: GLOBAL_ROUTE_PATHS.public.contact,
  },
  {
    from: `${GlobalRoutePathsEnum.ORGANISATION}/data-privacy`,
    params: {
      organisationId: ERNST_YOUNG_ORG_ID,
    },
    to: GLOBAL_ROUTE_PATHS.public.dataPrivacy,
  },
  {
    from: `${GlobalRoutePathsEnum.EMPLOYEE}/data-privacy`,
    params: {
      employeeId: EMPLOYEE_ADMIN_ID,
    },
    to: GLOBAL_ROUTE_PATHS.public.dataPrivacy,
  },
  {
    from: `${GlobalRoutePathsEnum.ORGANISATION}/case-item/:orderId`,
    params: {
      orderId: OrderIdsEnum.REWRITES_ORDER,
      organisationId: ERNST_YOUNG_ORG_ID,
    },
    to: GLOBAL_ROUTE_PATHS.organisation.order,
  },
  {
    from: `${GlobalRoutePathsEnum.ORGANISATION}/case-item`,
    params: {
      organisationId: ERNST_YOUNG_ORG_ID,
    },
    to: GLOBAL_ROUTE_PATHS.organisation.orders,
  },
  {
    from: `${GlobalRoutePathsEnum.ORGANISATION}/employee/:employeeId`,
    params: {
      employeeId: E2EEmployeeIdsEnum.REWRITES_EMPLOYEE,
      organisationId: ERNST_YOUNG_ORG_ID,
    },
    to: GLOBAL_ROUTE_PATHS.organisation.employee,
  },
  {
    from: `${GlobalRoutePathsEnum.ORGANISATION}/employee`,
    params: {
      organisationId: ERNST_YOUNG_ORG_ID,
    },
    to: GLOBAL_ROUTE_PATHS.organisation.employees,
  },
  {
    from: `${GlobalRoutePathsEnum.ORGANISATION}/depot/:depotId`,
    params: {
      depotId: E2EDepotIdsEnum.REWRITES_DEPOT,
      organisationId: ERNST_YOUNG_ORG_ID,
    },
    to: GLOBAL_ROUTE_PATHS.organisation.depot,
  },
  {
    from: `${GlobalRoutePathsEnum.ORGANISATION}/depot`,
    params: {
      organisationId: ERNST_YOUNG_ORG_ID,
    },
    to: GLOBAL_ROUTE_PATHS.organisation.depots,
  },
  {
    from: `${GlobalRoutePathsEnum.ORGANISATION}/phone-plan/:phonePlanId`,
    params: {
      organisationId: ERNST_YOUNG_ORG_ID,
      phonePlanId: E2EPhonePlanIdsEnum.REWRITES_PHONE_PLAN,
    },
    to: GLOBAL_ROUTE_PATHS.organisation.phonePlan,
  },
  {
    from: `${GlobalRoutePathsEnum.ORGANISATION}/phone-plan`,
    params: {
      organisationId: ERNST_YOUNG_ORG_ID,
    },
    to: GLOBAL_ROUTE_PATHS.organisation.phonePlans,
  },
  {
    from: `${GlobalRoutePathsEnum.ORGANISATION}/invoice`,
    params: {
      organisationId: EVERPHONE_ORG_ID,
    },
    to: GLOBAL_ROUTE_PATHS.organisation.invoices,
  },
]
