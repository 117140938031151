import { useState } from 'react'
import { createRoot } from 'react-dom/client'
import { ApolloProvider } from '@apollo/client'
import { config as FAConfig } from '@fortawesome/fontawesome-svg-core'
import { useMountEffect } from '@react-hookz/web'
import * as Sentry from '@sentry/react'
import { createBrowserHistory } from 'history'

import { AppLoader } from 'Components/AppLoader/AppLoader'

import { store, StoreContext } from 'Stores/index'

import {
  AuthError,
  fetchFromApi,
  getPreferredLanguage,
  loadGtagScript,
  setGtagPage,
} from 'Utilities'

import { BROWSER_LOCALE } from 'Constants/browser'
import { LOCAL_STORAGE } from 'Constants/constants'

import { AppEnvEnum } from 'Types/env.types'

import ApolloLoader from 'Portal/src/ApolloLoader'

import { App } from './App'
import { AppAuthResponse, AppState } from './main.types'
import { OpenTelemetryProvider } from './providers'

import 'regenerator-runtime/runtime'
import 'whatwg-fetch'
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/de'
import '@formatjs/intl-pluralrules/locale-data/en'
import '@formatjs/intl-pluralrules/locale-data/fr'
import 'intersection-observer'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import 'Portal/src/styles/app.scss'
import 'chart.js/auto'

FAConfig.autoAddCss = false

const history = createBrowserHistory()

history.listen(location => {
  setGtagPage(location.hash)

  if (window.APP_ENV !== AppEnvEnum.DEV) {
    window.Intercom('update')
  }
})

const AppContainer = () => {
  const [state, setState] = useState<AppState>({
    email: null,
    fullName: null,
    isLoading: true,
    isLoggedInAsNewJoiner: localStorage.getItem('newJoiner') === 'true',
    jwt: null,
    language: getPreferredLanguage(),
    locale: BROWSER_LOCALE,
    userId: null,
  })

  useMountEffect(() => {
    if (!Sentry.isInitialized()) {
      Sentry.init({
        attachStacktrace: true,
        beforeSend(event) {
          try {
            if (
              event?.exception?.values?.[0]?.stacktrace?.frames?.[0]?.filename?.includes(
                '/zone.js',
              )
            ) {
              return null
            }
          } catch (e) {
            return null
          }

          return event
        },
        denyUrls: [
          /userway.org\//, // Ignore all errors from userway broken files
          /chrome-extension:\/\//, // Ignore all errors from Chrome extensions
        ],
        dsn: 'https://f20dc3e4361b49c1ab4d6317c2fb6772@o861883.ingest.sentry.io/5871399',
        enabled: window.APP_ENV === AppEnvEnum.PRODUCTION,
        environment: window.APP_ENV,
        ignoreErrors: ["Unexpected token '<'"],
        tracesSampleRate: 0,
      })
    }

    if (localStorage.getItem(LOCAL_STORAGE.COOKIE_APPROVAL) === 'true') {
      loadGtagScript()
    }

    // TODO: Get rid of old API
    fetchFromApi('/auth/user/me')
      .then((user: AppAuthResponse) => {
        Sentry.setUser({
          email: user.email,
          id: user.id.toString(),
          username: user.fullName,
        })

        setState(prevState => ({
          ...prevState,
          email: user.email,
          fullName: user.fullName,
          isLoading: false,
          jwt: user.jwt,
          language: user.language,
          locale: user.locale,
          userId: user.id,
        }))
      })
      .catch((err: Error) => {
        if (err instanceof AuthError === false) {
          Sentry.captureException(err)
        }

        setState(prevState => ({
          ...prevState,
          isLoading: false,
        }))
      })
  })

  if (state.isLoading) {
    return <AppLoader />
  }

  return (
    <OpenTelemetryProvider>
      <ApolloProvider
        client={ApolloLoader.buildApolloClient(state.jwt, state.userId)}
      >
        <StoreContext.Provider value={store}>
          <App appState={state} />
        </StoreContext.Provider>
      </ApolloProvider>
    </OpenTelemetryProvider>
  )
}

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(<AppContainer />)
