import { forwardRef, Ref, useEffect, useState } from 'react'
import clsx from 'clsx'

import { CheckboxButton } from '../CheckboxButton/CheckboxButton'
import { CheckboxButtonSizeEnum } from '../CheckboxButton/CheckboxButton.types'
import { CheckboxButtonGroupProps } from './CheckboxButtonGroup.types'

const ForwardCheckboxButtonGroup = <IsToggle extends boolean>(
  props: CheckboxButtonGroupProps<IsToggle>,
  ref: Ref<any>,
) => {
  const {
    className,
    disabled,
    isInvalid,
    name,
    onChange,
    checkboxButtons,
    size = CheckboxButtonSizeEnum.medium,
    isToggle,
    value,
    e2eSelector,
  } = props
  const [values, setValues] = useState(
    new Set<string>(isToggle ? ([value] as string[]) : (value as string[])),
  )

  const handleChange = (value: string) => {
    const newValues = new Set(values)

    if (values.has(value)) {
      newValues.delete(value)
    } else {
      if (isToggle) {
        newValues.clear()
      }

      newValues.add(value)
    }

    setValues(newValues)

    if (typeof onChange === 'function') {
      onChange([...newValues])
    }
  }

  useEffect(() => {
    setValues(new Set(isToggle ? ([value] as string[]) : (value as string[])))
  }, [isToggle, value])

  const classNames = clsx('CheckboxButtonGroup', className)

  return (
    <div ref={ref} className={classNames} data-e2e={e2eSelector} role="group">
      {checkboxButtons.map(checkboxButton => (
        <CheckboxButton
          key={checkboxButton.label}
          checked={values.has(checkboxButton.value)}
          disabled={disabled}
          id={checkboxButton.label}
          isInvalid={isInvalid}
          label={checkboxButton.label}
          name={name}
          onChange={() => handleChange(checkboxButton.value)}
          size={size}
        />
      ))}
    </div>
  )
}

export const CheckboxButtonGroup = forwardRef(ForwardCheckboxButtonGroup) as <
  IsToggle extends boolean = false,
>(
  props: CheckboxButtonGroupProps<IsToggle> & { ref?: Ref<any> },
) => JSX.Element
