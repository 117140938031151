import { GroupBase } from 'react-select'

import { SelectOption, SelectProps } from './Select.types'

export const getSelectStateProps = <
  Option extends SelectOption,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: SelectProps<Option, IsMulti, Group>,
): Pick<
  SelectProps<Option, IsMulti, Group>,
  'isClearable' | 'isSearchable' | 'menuIsOpen'
> => {
  const { isDisabled } = props

  return {
    isClearable: isDisabled ? false : props.isClearable ?? true,
    isSearchable: isDisabled ? false : props.isSearchable ?? true,
    menuIsOpen: isDisabled ? !isDisabled : props.menuIsOpen ?? undefined,
  }
}
