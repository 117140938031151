import { useCallback, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { faFile, faPaperclipVertical } from '@fortawesome/pro-solid-svg-icons'
import { CellContext, ColumnDef } from '@tanstack/react-table'

import { OrganisationInvoicesWithUrl } from 'Modules/invoices/pages/Invoices/Invoices.types'

import { PrimaryButton, Table } from 'Components/_theme'
import { FormatDate } from 'Components/FormatDate/FormatDate'
import { FormattedCurrency } from 'Components/FormattedCurrency/FormattedCurrency'

import { E2E_TABLE_LINK_INVOICES } from 'Constants/e2e'

import { InvoicesTableProps } from './InvoicesTable.types'

export const InvoicesTable = (props: InvoicesTableProps) => {
  const { invoices, isLoading, isSearching } = props

  const intl = useIntl()

  const renderInvoiceNumberHeader = useCallback(
    () => <FormattedMessage id="InvoicesContent_header_number" />,
    [],
  )

  const renderDateHeader = useCallback(
    () => <FormattedMessage id="InvoicesContent_header_date" />,
    [],
  )

  const renderAmountHeader = useCallback(
    () => <FormattedMessage id="InvoicesContent_header_amount" />,
    [],
  )

  const renderAttachmentsHeader = useCallback(
    () => (
      <span className="ms-1">
        <FormattedMessage id="InvoicesContent_header_attachments" />
      </span>
    ),
    [],
  )

  const renderInvoiceNumberCell = useCallback(
    ({ cell }: CellContext<OrganisationInvoicesWithUrl, unknown>) => {
      const invoice = cell.row.original

      return (
        <div className="d-inline-flex">
          <strong>{invoice.invoiceNumber}</strong>
        </div>
      )
    },
    [],
  )

  const renderExpandableContent = useCallback(
    ({ cell }: CellContext<OrganisationInvoicesWithUrl, unknown>) => {
      const invoice = cell.row.original

      return (
        <div className="w-100 Table-details">
          <div className="Table-details-row">
            <span className="Table-row--expandable-title">
              {renderDateHeader()}
            </span>

            <span>
              <FormatDate value={invoice.created} />
            </span>
          </div>

          <div className="Table-details-row">
            <span className="Table-row--expandable-title">
              {renderAmountHeader()}
            </span>

            <span className="text-ellipsis">
              <FormattedCurrency value={invoice.sumAmountTotal} />
            </span>
          </div>

          <div className="Table-details-row">
            <span className="Table-row--expandable-title">
              {renderAttachmentsHeader()}
            </span>

            <div className="ms-2">
              <PrimaryButton
                appearance="outline"
                aria-label={intl.formatMessage({
                  id: 'Invoice',
                })}
                className="me-2 my-1"
                iconLeft={faFile}
                onClick={() => {
                  window.location.href = invoice.pdfDownloadLink
                }}
                size="small"
              >
                <FormattedMessage id="Invoice" />
              </PrimaryButton>

              <PrimaryButton
                appearance="outline"
                aria-label={intl.formatMessage({
                  id: 'Attachment_A',
                })}
                className="my-1"
                iconLeft={faPaperclipVertical}
                onClick={() => {
                  window.location.href = invoice.pdfAttachmentDownloadLink
                }}
                size="small"
              >
                <FormattedMessage id="Attachment_A" />
              </PrimaryButton>
            </div>
          </div>
        </div>
      )
    },
    [renderAmountHeader, renderAttachmentsHeader, renderDateHeader, intl],
  )

  const renderDateCell = useCallback(
    ({ cell }: CellContext<OrganisationInvoicesWithUrl, unknown>) => {
      const invoice = cell.row.original

      return (
        <div className="d-flex align-items-center h-100">
          <FormatDate value={invoice.created} />
        </div>
      )
    },
    [],
  )

  const renderAmountCell = useCallback(
    ({ cell }: CellContext<OrganisationInvoicesWithUrl, unknown>) => {
      const invoice = cell.row.original

      return (
        <div className="d-flex align-items-center h-100">
          <span className="text-ellipsis">
            <FormattedCurrency value={invoice.sumAmountTotal} />
          </span>
        </div>
      )
    },
    [],
  )

  const renderAttachmentsCell = useCallback(
    ({ cell }: CellContext<OrganisationInvoicesWithUrl, unknown>) => {
      const invoice = cell.row.original

      return (
        <div className="ms-1">
          <PrimaryButton
            appearance="outline"
            aria-label={intl.formatMessage({
              id: 'Invoice',
            })}
            className="me-2 my-1"
            iconLeft={faFile}
            onClick={() => {
              window.location.href = invoice.pdfDownloadLink
            }}
            size="small"
          >
            <FormattedMessage id="Invoice" />
          </PrimaryButton>

          <PrimaryButton
            appearance="outline"
            aria-label={intl.formatMessage({
              id: 'Attachment_A',
            })}
            className="my-1"
            iconLeft={faPaperclipVertical}
            onClick={() => {
              window.location.href = invoice.pdfAttachmentDownloadLink
            }}
            size="small"
          >
            <FormattedMessage id="Attachment_A" />
          </PrimaryButton>
        </div>
      )
    },
    [intl],
  )

  const columns = useMemo<ColumnDef<OrganisationInvoicesWithUrl>[]>(
    () => [
      {
        cell: renderInvoiceNumberCell,
        header: renderInvoiceNumberHeader,
        id: 'invoice_number',
        meta: {
          ExpandableContent: renderExpandableContent,
        },
      },
      {
        cell: renderDateCell,
        header: renderDateHeader,
        id: 'date',
      },
      {
        cell: renderAmountCell,
        header: renderAmountHeader,
        id: 'amount',
      },
      {
        cell: renderAttachmentsCell,
        header: renderAttachmentsHeader,
        id: 'attachments',
      },
    ],
    [
      renderAmountCell,
      renderAmountHeader,
      renderAttachmentsCell,
      renderAttachmentsHeader,
      renderDateCell,
      renderDateHeader,
      renderExpandableContent,
      renderInvoiceNumberCell,
      renderInvoiceNumberHeader,
    ],
  )

  const data = useMemo<OrganisationInvoicesWithUrl[]>(
    () => invoices,
    [invoices],
  )

  return (
    <Table
      className="Table--invoices"
      columns={columns}
      data={data}
      e2eRowId={E2E_TABLE_LINK_INVOICES}
      hideArrow
      isLoading={isLoading}
      isSearching={isSearching}
    />
  )
}
