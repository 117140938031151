import { FormattedMessage } from 'react-intl'
import { clsx } from 'clsx'

import { Heading } from 'Components/_theme'
import { ImageWithFallback } from 'Components/ImageWithFallback/ImageWithFallback'

import contactPicture from 'Assets/images/contact-picture.jpg'

import { EmployeeTileProps } from './EmployeeTile.types'

export const EmployeeTile = (props: EmployeeTileProps) => {
  const { isLanding } = props

  return (
    <div
      className={clsx(
        isLanding ? 'EmployeeTile-landing' : 'EmployeeTile d-flex',
      )}
    >
      <div>
        <ImageWithFallback
          alt="contact"
          className="EmployeeTile-image"
          src={contactPicture}
        />
      </div>

      <div className="EmployeeTile__content">
        <Heading as={5} className="m-0" size={3}>
          Alexis Knorr
        </Heading>

        <span className="d-block mt-4">
          <FormattedMessage id="Contact_position" />
        </span>

        <p className="mt-6">
          <FormattedMessage id="Contact_position_text" />
        </p>
      </div>
    </div>
  )
}
