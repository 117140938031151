import { store } from 'Stores/index'

import { PortalSectionEnum } from 'Constants/portalSection'

import { Employee, Employees } from '../pages'
import { EmployeesRoutePathsEnum } from './employeesRouteConfig.types'

export const EMPLOYEES_ROUTES = [
  {
    component: Employee,
    onRender: () => {
      const { portalSettingsStore } = store
      const { setCurrentPortalSection } = portalSettingsStore

      setCurrentPortalSection(PortalSectionEnum.Employees)
    },
    path: `${EmployeesRoutePathsEnum.EMPLOYEE}`,
  },
  {
    component: Employees,
    onRender: () => {
      const { portalSettingsStore } = store
      const { setCurrentPortalSection } = portalSettingsStore

      setCurrentPortalSection(PortalSectionEnum.Employees)
    },
    path: `${EmployeesRoutePathsEnum.EMPLOYEES}`,
  },
]
