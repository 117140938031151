import { Controller, useFormContext } from 'react-hook-form'

import { RangeInput } from 'Components/RangeInput/RangeInput'

import { FieldRangeInputProps } from './Field.types'

export const FieldRangeInput = (props: FieldRangeInputProps) => {
  const { name, rangeProps, hasError } = props

  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, value, ...field } }) => (
        <RangeInput
          ref={ref}
          {...field}
          defaultValue={value}
          {...rangeProps}
          isInvalid={hasError}
        />
      )}
    />
  )
}
