import { Route } from 'react-router-dom'
import { observer } from 'mobx-react'

import { UnauthorisedLayout } from '../layouts'
import { PublicRouteProps } from './Router.types'

export const PublicRoute = observer((props: PublicRouteProps) => {
  const { component: Component, onRender, ...rest } = props

  const Layout = (
    <UnauthorisedLayout>
      <Component />
    </UnauthorisedLayout>
  )

  if (onRender) {
    return (
      <Route
        {...rest}
        render={() => {
          onRender()

          return Layout
        }}
      />
    )
  }

  return <Route {...rest}>{Layout}</Route>
})
