import { backendFrTranslations } from './backend/backend.fr'
import { dataPrivacyTranslations } from './dataPrivacy/dataPrivacy.fr'

const fr = {
  Accept: 'Accepter',
  AccessoriesChooser_limitation_info: `Vous ne pouvez sélectionner que {amount} {amount, plural, =0 {accessoires} =1 {accessoire} other {accessoires}}. Merci d'en déselectionner un si vous souhaitez en choisir un autre.`,
  AccessoriesChooser_limitation_info_one: `Vous ne pouvez sélectionner qu'un accessoire. Merci d'en déselectionner un si vous souhaitez en choisir un autre.`,
  AccessoriesChooser_skip: 'Sauter',
  AccessoriesChooser_total_products: 'Nous avons trouvé {total} produits',
  Action: 'Action',
  Active_employees: 'Active employees',
  Active_phone_plans: 'Active phone plans',
  Active_rentals: 'Active rentals',
  Address: 'Adresse',
  Addresses: 'Adresses',
  Admin: 'Admin',
  Admin_dashboard_early_upgrade_text:
    'Rental eligible for upgrade/ early upgrade',
  Agent: 'Agent',
  Amount: 'Montant',
  Appliqué: 'Appliqué',
  Assign: 'Attribuer',
  Assign_device: 'Attribuer un appareil',
  Assign_device_to_employee: 'Attribuer un appareil à un employé',
  Attachment_A: 'Attachment_A',
  Attn: "A l'attention de",
  Available: 'Disponible',
  Awaiting_processing: 'En attente de traitement',
  Back: 'Retour',
  Back_to_login: 'Back to login',
  BenefitSlide_get_in_touch_content:
    "Vous avez besoin d'aide ou vous avez des questions ? N'hésitez pas à nous contacter. Nous serons heureux de vous aider !",
  BenefitSlide_get_in_touch_header: 'Contactez nous',
  BenefitSlide_manage_devices_content:
    "Dans notre portail, vous avez une vue d'ensemble de vos appareils et de vos forfaits mobiles - y compris les identifiants des appareils, les périodes de location et d'autres informations de base sur vos appareils.",
  BenefitSlide_manage_devices_header: 'Gérer appareils',
  BenefitSlide_self_service_content:
    "Quelque chose ne va pas avec votre téléphone ? Ici, vous pouvez contacter directement l'équipe d'Everphone. Pas besoin de déranger votre service informatique ou votre admin !",
  BenefitSlide_self_service_header: 'Libre Service',
  BenefitSlide_upgrade_devices_content:
    'Votre appareil est-il prêt pour une mise à niveau gratuite ? Consultez vos options de mise à niveau pour savoir si vous pouvez commander le dernier modèle de téléphone iOS ou Android.',
  BenefitSlide_upgrade_devices_header: "Mise à niveau d'appareils",
  Broken_device: 'Dispositif défectueux',
  Business_line: 'Numéro de téléphone professionnel',
  Buy_leaseback: 'Achat et Re-location',
  Cancel: 'Annuler',
  Cancel_device: 'Annuler la location',
  Cancel_phone_plan: 'Annuler un forfait mobile',
  Cancelled: 'Annulé',
  CardActions_noActionEmployee_description: `Pour l'instant, vous ne pouvez pas effectuer de changements dans cet employé`,
  CardActions_noActionRental_description: `Pour l'instant, vous ne pouvez pas effectuer de changements dans votre location`,
  CardActions_noActionReturn_description: `Pour l'instant, vous ne pouvez pas effectuer de changements dans votre retourner`,
  CardActions_noAction_title: `Pas d'action possible`,
  Carrier: 'Service des colis',
  ChangeExitDate: 'Change Exit Date',
  ChangeOfDeliveryAddress: "Changer l'adresse de livraison",
  Change_phone_plan: 'Changer de forfait mobile',
  Choose_new_device: 'Sélectionner nouvel appareil',
  Choose_phone_plan: 'Sélectionner un forfait mobile',
  City: 'Ville',
  CityState: 'Ville, État',
  Clear: 'Clear',
  Clear_all: 'Clear all',
  Clear_filter: 'Effacer le filtre',
  Close: 'Fermer',
  Color: 'Couleur',
  Comment: 'Commentaire',
  Completed: 'Terminé',
  Confirm: 'Confirmé',
  Confirmation_date: 'Date de confirmation',
  ContactDescription_description: `Le moyen le plus rapide de nous contacter est par le biais du chat du service clients. Cliquez simplement sur le bouton pour ouvrir la fenêtre du chat. Si ton organisation ne prend pas en charge l'assistance par chat, utilise les options de contact par e-mail et par téléphone.`,
  ContactDescription_header: 'Avez-vous vous une question?',
  ContactDetails_description: 'Des questions ou des remarques ?',
  ContactDetails_header: `Plus d'options de contact`,
  ContactDetails_openingHours: 'Lundi-dimanche, 7h-20h',
  ContactDetails_service: '(Service en allemand et en anglais)',
  ContactLandingDescription_description:
    'Le moyen le plus rapide de nous contacter est par le biais du chat du service clients. Cliquez simplement sur le bouton pour ouvrir la fenêtre du chat.',
  Contact_button: 'Retour au tableau de bord',
  Contact_chat_with_us: 'Ouvrir le chat',
  Contact_login: 'Se connecter',
  Contact_login_text: 'As-tu déjà accès au portal?',
  Contact_position: 'Lead Client Services',
  Contact_position_text:
    'Mon équipe et moi sommes là pour vous - une aide rapide en quelques clics.',
  Contact_us: 'Contactez nous',
  Continue: 'continuer',
  CookieApproval_cookies_stored_on_computer:
    'Ce site Web enregistre des cookies sur votre ordinateur. Ces cookies sont utilisés pour recueillir des informations sur la façon dont vous interagissez avec notre site web et nous permettent de nous souvenir de vous.',
  CookieApproval_heading: 'Ce site web utilise des cookies',
  CookieApproval_if_you_decline:
    'Si vous refusez, vos informations ne seront pas suivies lorsque vous visiterez ce site web.',
  CookieApproval_improve_experience_info:
    "Nous utilisons ces informations afin d'améliorer et de personnaliser votre expérience de navigation, ainsi qu'à des fins d'analyse et de mesure de nos visiteurs, tant sur ce site que sur d'autres supports.",
  Copy: 'Copie',
  CostCenterSelect_Create: 'Créer un centre de coûts',
  CostCenterSelect_NoResults:
    'Veuillez saisir au moins un caractère afin de créer un centre de coûts',
  Cost_center: 'Centre de coûts',
  Costcenter: 'Centre de coût',
  Country: 'Pays',
  Create_DHL_return_label: 'Créer une nouvelle étiquette de retour DHL',
  Create_employee: 'Créer un nouveau employé',
  Created_by: 'Créé par',
  Current: 'Actuel',
  Custom_address: 'Adresse personnalisée',
  DHL_Return_Label: 'Étiquette de retour DHL',
  Dashboard: 'Tableau de bord',
  DashboardFleetOverviewCard_header: 'Aperçu de la flotte',
  DashboardFleetOverviewCard_subtitle: 'Tous les appareils de location',
  DashboardOrdersReportCard_orders_report_header: 'Aperçu des ordres',
  DashboardOrdersReportCard_orders_report_subtitle: `Ordres par type d'ordre`,
  DashboardStatisticCard_contact_us: 'Contactez-nous pour débuter',
  DashboardStatisticCard_this_month: '{percent} % ce mois',
  Dashboard_everphone_contact: 'Votre contact chez Everphone',
  Dashboard_the_benefits: 'The benefits',
  Data_privacy: 'Data Privacy',
  Delivery_status_Delivered: 'Votre appareil a été livré',
  Delivery_status_Delivery: 'Livraison',
  Delivery_status_Failure:
    'Échec de la livraison - votre appareil est renvoyé à Everphone',
  Delivery_status_Transit: 'Votre appareil a été envoyé',
  Delivery_status_return_Delivered: 'Retour à Everphone réussi',
  Delivery_status_return_Failure: `Échec de la livraison - l'appareil est renvoyé à l'expéditeur`,
  Delivery_status_return_Transit: 'Votre retour a été envoyé',
  Depot: 'Dépôt',
  DepotListContent_badge_return: 'Retour',
  DepotListContent_header_devices: 'Appareils',
  DepotListContent_header_name: 'Nom du dépôt',
  DepotListContent_header_type: 'Type',
  DepotStockModal_serial_number: 'Numéro de Série',
  DepotTable_in_depot: 'en dépôt',
  Depot_details: 'Détails dépôt',
  Depots: 'Dépôts',
  Device: 'Appareil',
  DeviceChooserFilters_color: 'Couleur',
  DeviceChooserFilters_device_type: 'Type Appareil',
  DeviceChooserFilters_filter: 'Filtrer',
  DeviceChooserFilters_manufacturer: 'Marque',
  DeviceChooserFilters_memory: 'Capacité de mémoire',
  DeviceChooserFilters_price: 'Prix',
  DeviceChooserFilters_recommended: 'Recommendé',
  DeviceChooserFilters_total_products: 'Nous avons trouvé (xx) produits',
  DeviceChooserFilters_view_all: 'Voir tous les filtres',
  DeviceChooserFilters_view_less: 'Voir moins de filtres',
  DeviceChooser_limit_exceeded:
    'Désolé, vous ne pouvez pas commander d\'autres appareils. Veuillez aller à "Demandes" pour voir vos commandes récentes ou contacter votre administrateur pour plus d\'informations.',
  DeviceDetails_exchange_requested: 'Échange demandé',
  DeviceDetails_replacement_requested: 'Remplacement demandé',
  DeviceListContent_header__employee: 'Employé',
  DeviceListContent_header__name: 'Nom du modèle',
  DeviceListContent_header__start_date: 'Début de Location',
  DeviceListContent_header__status: 'Statut',
  DeviceSlide_purchase_id: "Numéro d'achat",
  DeviceSlide_request_date: 'Date de la demande',
  DeviceSlide_request_id: 'Numéro de la demande',
  DeviceSlide_start_date: 'Début de location',
  DeviceSlide_view_rental: 'Voir la location',
  DeviceSlide_view_request: 'Voir la demande',
  Device_history: 'Historique des appareils',
  Device_locked: 'Dispositif verrouillé',
  Device_locked_alert:
    'Ce dispositif est actuellement soit verrouillé, soit soumis à des restrictions MDM, de sorte que le retour ne peut être traité. Veuillez corriger cela pour continuer.',
  Device_locked_badge_in_customer_lock:
    "Verrouillage de l'appareil - User Lock",
  Device_locked_badge_in_mdm: "Verrouillage de l'appareil - MDM Lock",
  Device_locked_badge_in_mdm_and_customer_lock:
    "Verrouillage de l'appareil  - User & MDM Lock",
  Device_lost_stolen: 'Appareil perdu ou volé',
  Devices: 'Appareils',
  DevicesCard_devices_header: 'Mes appareils et commandes',
  DevicesCard_header: 'Mes appareils',
  DevicesCard_purchases_header: 'Mes achats',
  DevicesCard_total:
    '(Total: {total} {total, plural, =0 {appareils} one {appareil} other {appareils}})',
  Done: 'Terminé',
  Download: 'Téléchargement',
  Download_report_error: "Désolé ! Quelque chose s'est mal passé.",
  Download_report_preparing: 'Préparation',
  Download_report_preparing_message:
    'On s’en occupe ! Votre rapport est en cours de préparation. Un lien pour télécharger le rapport apparaîtra ici dès qu’il sera disponible.',
  Download_report_report_ready:
    'Votre rapport du {time} est prêt à être téléchargé.',
  Download_report_report_ready_download: 'Cliquez ici pour télécharger',
  Download_report_report_ready_order_message:
    "Les commandes de plus d'un an ne sont pas inclus dans le rapport.",
  Download_report_request_report: 'Demander le rapport',
  Duration: 'Durée',
  Early_Upgrade: "Mise à niveau anticipée de l'appareil",
  Early_upgrade_device: "Mise à niveau anticipée de l'appareil",
  Edit: 'Modifier',
  Edit_address: `Modifier l'adresse`,
  Edit_employee: "Modifier l'employé",
  Email: 'Courriel',
  Employee: 'Employé',
  EmployeeDashboardServiceApp_rent_button: 'Rent device',
  EmployeeDashboardServiceApp_title: 'Mes appareils',
  EmployeeDashboard_button: 'Découvrez nos offres',
  EmployeeDashboard_title: 'Vos appareils professionels',
  EmployeeDashboard_title_content:
    'Dans ce portail, vous pouvez gérer les appareils de votre entreprise : organiser les appareils existants, commander des échanges si votre appareil est cassé ou mettre à niveau vos appareils ! Vous pouvez également utiliser ce portail pour consulter les FAQ et entrer en contact avec notre service clientèle en utilisant le chat.',
  EmployeeExitCard_heading: 'Prochaines dates de sortie des employés',
  EmployeeListContent_IMEI: 'IMEI',
  EmployeeListContent_Rentals: 'Locations',
  EmployeeListContent_header_device: 'Appareil',
  EmployeeListContent_header_employee: 'Employé',
  EmployeeListContent_number_of_devices: 'Appareils',
  EmployeeOffboarding_date_in_past: `La date de sortie doit être aujourd'hui ou dans le future.`,
  EmployeeOffboarding_error:
    "Désolé, quelque chose s'est mal passé. Veuillez réessayer.",
  Employee_details: "Coordonnées de l'employé",
  Employee_id: 'Identifiant employé',
  Employee_leaves: "L'employé quitte l'entreprise",
  Employees: 'Employés',
  EmployeesSelect_NoResults: 'Aucun employé trouvé.',
  End: 'Fin',
  English: 'Anglais',
  ErrorSubmittingRequest:
    "Désolé, une erreure s'est produite pendant la soumission de la demande.",
  Error_heading: 'Oops!',
  Error_occurred: "Une erreur s'est produite.",
  Error_refresh_page:
    'Veuillez rafraîchir cette page ou naviguer vers une autre page.',
  EverphonePlusBenefits_buying_offer_content:
    "À la fin de la période de location, vous pouvez choisir de prolonger, de nous retourner l'appareil ou de l'acheter à un prix intéressant.",
  EverphonePlusBenefits_buying_offer_header:
    "Option d'achat à la fin de la période de location",
  EverphonePlusBenefits_damage_swap_content:
    "Avec Everphone Plus, vous bénéficiez d'un appareil couvert complètement, qui peut être échangé sans frais supplémentaires s'il est défectueux ou accidentellement endommagé.",
  EverphonePlusBenefits_damage_swap_header:
    'Recevez un appareil de remplacement en case de casse.',
  EverphonePlusBenefits_enormous_savings_content:
    "Économisez jusqu'à 52% quand vous louez un appareil par le biais de votre employeur au lieu de l'acheter.",
  EverphonePlusBenefits_enormous_savings_header: 'Bonnes Affaires',
  EverphonePlusBenefits_family_friends_content:
    'Vous pouvez louer votre appareil préféré via Everphone. Et pourquoi pas aussi pour vos proches ? Vous, y compris pour vos amis et votre famille, pouvez désormais louer des appareils pour un usage privé.',
  EverphonePlusBenefits_family_friends_header: 'Parfait pour amis et famille',
  EverphonePlusDashboard_most_popular: 'Les appareils les plus populaires',
  EverphonePlusDashboard_see_offers: 'Voir offres',
  EverphonePlusDashboard_the_benefits: 'Les avanages',
  EverphonePlusDashboard_title: 'Louez et économisez avec Everphone Plus',
  EverphonePlusDashboard_title_content:
    "Vous pouvez désormais louer et utiliser par l'intermédiaire de votre employeur, des appareils mobiles et des accessoires pour un usage privé par l'intermédiaire de votre employeur. Le montant de la location est déduit directement de votre salaire mensuel. Au lieu de payer le coût initial élevé de votre appareil préféré, payez une location mensuelle et profitez de déductions avant impôt.",
  Exchange: 'Échangez',
  ExchangeDeviceModal_shipping_label_info:
    "Le colis est accompagné d'une étiquette d'expédition. Veuillez retourner l'ancien appareil dans les prochains 14 jours.",
  Exchange_device: 'Échanger un appareil',
  Excl_tax: 'Hors TVA',
  Exit_date: 'Date de sortie',
  Expand_content: 'Développer le contenu',
  Extend: 'Prolonger',
  Extend_now: 'Prolonger maintenant',
  Extend_rental: 'Prolonger la location ou choisir un nouvel appareil',
  Fallback: 'MISSING_LABEL',
  FaqCard_description_part1:
    'Trouvez les réponses aux questions fréquemment posées dans notre Centre d’Aide (en anglais ou allemand)',
  FaqCard_description_part2:
    'Au cas contraire, nous sommes ravis de répondre à toutes autres questions via le Chat.',
  FaqCard_primary_cta: 'Parcourir les FAQs',
  FaqCard_secondary_cta: 'Ouvrir le Chat',
  FaqCard_title: 'FAQ',
  Field_invalid: "Ce champ n'est pas valide",
  Filter: 'Filtre',
  Filter_by: 'Filtrer par',
  Filter_no_results_content:
    'Veuillez modifier votre filtre ou effacer votre sélection',
  First_and_last_name: 'Prénom et nom de famille',
  First_name: 'Prénom',
  For_employee: "Pour l'employé (optionnel)",
  ForgotPasswordButton_label: 'Avey-vous oublié votre mot de passe?',
  FormMessage_email: 'Veuillez entrer une adresse e-mail valide.',
  FormMessage_maxLength:
    'Ce champ doit contenir au maximum {amount} caractère(s).',
  FormMessage_minLength:
    'Ce champ doit contenir au moins {amount} caractère(s).',
  FormMessage_required: 'Champ obligatoire.',
  German: 'Allemand',
  GlobalSearch_CloseIcon: 'Fermer la recherche',
  GlobalSearch_Heading: 'Vos suggestions de recherche',
  GlobalSearch_InCategory: 'dans {category}',
  GlobalSearch_InputPlaceholder:
    'Rechercher un appareil, un employé ou un forfait mobile...',
  GlobalSearch_InputValidationMessage:
    "Nous n'avons pas trouvé de correspondance, veuillez entrer plus de {limit} caractères.",
  GlobalSearch_NoResultsMessage:
    "Désolé, nous n'avons pas trouvé de résultats pour votre recherche.",
  GlobalSearch_OpenIcon: 'Ouvrir la recherche',
  GlobalSearch_RelatedItems: 'Articles associés',
  GlobalSearch_SearchAll: 'Recherche',
  GlobalSearch_SearchResults_header: 'Résultats de recherche',
  GlobalSearch_SearchResults_show_total:
    'Afficher {amount} {amount, plural, =0 {résultats de recherche} one {résultat de recherche} other {résultats de recherche}}',
  GlobalSearch_SearchResults_total:
    'Nous avons trouvé {amount} {amount, plural, =0 {résultats de recherche} one {résultat de recherche} other {résultats de recherche}}',
  GoToPage_after: 'page',
  GoToPage_pageInput: 'Page input',
  ILostMyDevice: "J'ai perdu mon appareil.",
  IMEI: 'IMEI',
  IMEI2: 'IMEI2',
  Id: 'Identifiant',
  Img_alt_contact_guy:
    "Icône d'un représentant du support client portant un casque, assis à un bureau avec un ordinateur portable affichant le mot 'ever'.",
  Img_alt_dashboard_banner:
    'Un homme debout et une femme assise à un bureau discutant de travail dans un bureau moderne et lumineux avec de grandes fenêtres.',
  Img_alt_faq:
    "Icône d'une loupe avec un point d'interrogation sur deux fenêtres de navigateur web superposées.",
  Img_alt_impact_tracker:
    "Icône représentant une usine sur un globe avec des émissions de CO₂, une feuille et une goutte d'eau symbolisant l'impact environnemental.",
  Img_alt_list_message: "Icône d'actualité",
  Img_alt_login_devices:
    'Quatre appareils électroniques—un ordinateur portable, une tablette et deux smartphones—affichant des motifs abstraits colorés sur leurs écrans.',
  Img_alt_no_action:
    "Icône d'un écran d'ordinateur avec une fenêtre de navigateur web superposée et un symbole 'X', indiquant une erreur ou une action bloquée.",
  Img_alt_order_confirmation:
    "Illustration d'un livreur souriant tenant un colis avec un symbole de coche, indiquant que la commande est confirmée.",
  Img_alt_org_logo: "Logo de l'organisation",
  Img_alt_page_error:
    "Icône indiquant qu'un événement inattendu s'est produit, avec un écran d'ordinateur, une fenêtre de navigateur web et un symbole de rafraîchissement suggérant de rafraîchir la page.",
  ImpactTracker_desc: 'Your environmental impact reduction to date',
  ImpactTracker_popover_desc:
    'The ecological footprint of this refurbished device is compared with the footprint of a new device.',
  ImpactTracker_popover_link: 'Find out more',
  ImpactTracker_prolonging_device: 'Prolonged device',
  ImpactTracker_refurbished_device: 'Refurbished device',
  ImpactTracker_saved_co2: 'Saved CO₂',
  ImpactTracker_saved_raw: 'Saved raw materials',
  ImpactTracker_saved_water: 'Saved water',
  ImpactTracker_title: 'Impact tracker',
  Imprint: 'Mentions légales',
  In_customs: 'Dédouanement',
  In_progress: 'En cours',
  Inactive: 'inactif',
  Incl_tax: 'TVA incl.',
  InvalidCredentials: "Mot de passe ou nom d'utilisateur incorrect",
  Invoice: 'Facture',
  Invoices: 'Factures',
  InvoicesContent_header_amount: 'Montant',
  InvoicesContent_header_attachments: 'Pièces jointes',
  InvoicesContent_header_date: 'Date',
  InvoicesContent_header_due_date: "Date d'échéance",
  InvoicesContent_header_number: 'Numéro',
  InvoicesContent_header_status: 'Statut',
  Item: 'Article',
  Language: 'Langue',
  Last_fetch_days:
    'Dernière modification: il y a {days} {days, plural, =0 {jours} one {jour} other {jours}}',
  Last_fetch_hours:
    'Dernière modification: il y a {hours} {hours, plural, =0 {heures} one {heure} other {heures}}',
  Last_fetch_minutes:
    'Dernière modification: il y a {minutes} {minutes, plural, =0 {minutes} one {minute} other {minutes}}',
  Last_fetch_seconds:
    'Dernière modification: il y a {seconds} {seconds, plural, =0 {secondes} one {seconde} other {secondes}}',
  Last_name: 'Nom de Famille',
  Link_to_external_page_title: '{name} - ouvre une nouvelle fenêtre',
  Link_to_page_title: 'Aller à la page {name}',
  Loading: 'Chargement en cours...',
  Login: 'Connexion',
  Login_SSO: 'Connexion avec SSO',
  Login_error_not_allowed_to_login: "Vous n'êtes pas autorisé à vous connecter",
  Login_failed: 'Échec de la connexion',
  Login_not_authorized: "Vous n'êtes pas autorisé à vous connecter",
  Login_page: 'Page de connexion',
  Logout: 'Déconnexion',
  LostDeviceModal_order_new_device: 'Commander un nouvel appareil',
  LostOrStolenModal_confirmationNoReplacement_heading:
    'Nous avons reçu votre demande',
  LostOrStolenModal_followInternalProcesses:
    'Veuillez vous assurer que vous suivez vos procédures internes pour les appareils perdus ou volés.',
  LostOrStolenModal_header: 'signaler un appareil perdu/volé',
  LostOrStolenModal_noReplacement:
    "je n'ai pas besoin d'un appareil de remplacement",
  LostOrStolenModal_noReplacementFound:
    "Aucun appareil de remplacement approprié n'a été trouvé dans l'un de vos dépôts",
  LostOrStolenModal_orderDepot:
    'commander un appareil de remplacement au dépôt',
  LostOrStolenModal_orderEverphone:
    "commander un appareil de remplacement auprès d'Everphone",
  LostOrStolenModal_replacementModelNote:
    '* un modèle identique ou similaire sera envoyé en remplacement',
  LostReplacement_info_header: 'Appareil perdu/volé',
  LostReplacement_info_text:
    "Veuillez choisir un nouvel appareil de votre choix en remplacement. Une nouvelle location sera créée avec l'appareil sélectionné",
  Lost_stolen_device: 'Appareil perdu / volé',
  Message: 'Message',
  Message_for_everphone: 'Message pour Everphone',
  Most_recent: 'Plus récent',
  MultidatePicker_last_days: 'Derniers {number} days',
  MultidatePicker_last_months: 'Derniers {number} mois',
  MultidatePicker_months: 'Derniers {number} mois',
  MultidatePicker_next_days: '{number} prochains jours',
  MultidatePicker_next_months: '{number} prochains mois',
  MultidatePicker_placeholder: 'Choisissez la date',
  MultidatePicker_today: "Aujourd'hui",
  MultidatePicker_until_today: 'Until today',
  MultidatePicker_yesterday: 'Hier',
  My_account: 'My account',
  My_devices: 'My devices',
  Name: 'Nom',
  NetPriceCalculator_additional_health_insurance:
    "Taux de cotisation pour l'assurance complémentaire de santé",
  NetPriceCalculator_apply: 'Appliquer',
  NetPriceCalculator_calculate: 'Calculer les prix effectifs',
  NetPriceCalculator_child_allowance: 'Allocation pour enfant',
  NetPriceCalculator_church_tax: "Impôt d'église",
  NetPriceCalculator_effective_net_price_calculator:
    'Calculateur du prix net effectif',
  NetPriceCalculator_enter_salary: 'Entrez votre salaire annuel',
  NetPriceCalculator_enter_your_health_insurance:
    'Entrez votre contribution à votre assurance complémentaire de santé',
  NetPriceCalculator_enter_your_tax_info:
    'Saisissez vos informations fiscales pour calculer les prix effectifs estimés après la conversion de votre salaire brut/net.',
  NetPriceCalculator_estimation_disclaimer:
    "Estimation à des fins d'orientation uniquement. La déduction de votre salaire net réel est déterminée par votre déclaration fiscale réelle.",
  NetPriceCalculator_gross_salary: 'Salaire annuel brut',
  NetPriceCalculator_prices_visible:
    'Vos prix effectifs estimés sont maintenant visibles.',
  NetPriceCalculator_recalculate: 'Recalculer les prix effectifs',
  NetPriceCalculator_required_fields: 'Champs obligatoires',
  NetPriceCalculator_state: 'État',
  NetPriceCalculator_tax_class: "Catégorie d'imposition",
  NetPriceCalculator_value: 'Valeur',
  New: 'Nouveau',
  NewOrderConfirmation_color: 'Couleur',
  NewOrderConfirmation_heading: 'Votre commande est confirmée',
  NewOrderConfirmation_items: 'Articles',
  NewOrderConfirmation_months: '{total} mois',
  NewOrderConfirmation_order_date: 'Date de la commande',
  NewOrderConfirmation_price: 'Prix',
  NewOrderConfirmation_quantity: 'Quantité: {count}',
  NewOrderConfirmation_rental_period: 'Période de location',
  NewOrderConfirmation_request_id: 'Numéro de votre demande',
  NewOrderConfirmation_shipping_address: "Adresse d'expédition",
  NewOrderConfirmation_subheading:
    "Nous avons reçu votre commande et la préparons pour l'expédition. Nous vous informerons par message et par courriel lorsque votre commande est en route!",
  NewOrderConfirmation_to_dashboard: 'Retour au tableau de bord',
  NewOrderConfirmation_to_employee: 'Retour au employé',
  NewOrderConfirmation_to_rental: 'Retour au location',
  NewOrderConfirmation_to_rentals: 'Retour au locations',
  NewOrderConfirmation_total_amount: 'Co-paiement mensuel (hors TVA)',
  NewOrderConfirmation_total_amount_basis: 'Montant total par mois',
  NewOrderConfirmation_total_amount_gross: 'Co-paiement mensuel (TVA incluse)',
  NewOrderSummary_accept_terms:
    "J'accepte tous les termes et conditions en cliquant sur le bouton de commande.",
  NewOrderSummary_billing_identifier_placeholder:
    'Veuillez renseigner Billing Identifier (Optionnel, max {limit} {limit, plural, =0 {character} one {character} other {charactères}})',
  NewOrderSummary_custom_client_reference_placeholder:
    'Veuillez renseigner Custom client reference (Optionnel, max {limit} {limit, plural, =0 {character} one {character} other {characters}})',
  NewOrderSummary_device: 'Appareil',
  NewOrderSummary_error_header: "Désolé ! Quelque chose s'est mal passé.",
  NewOrderSummary_error_header_address_error:
    'Sorry! We were unable to complete your order.',
  NewOrderSummary_error_subheader:
    "Nous n'avons pas pu traiter votre commande. Veuillez réessayer.",
  NewOrderSummary_error_subheader_address_error:
    'There is an error in the shipping address you entered. Please correct them and try again',
  NewOrderSummary_external_id: 'External ID',
  NewOrderSummary_external_id_error: "L'external ID doit être unique.",
  NewOrderSummary_external_id_placeholder:
    'Veuillez renseigner external ID (Optionnel, unique, max 60 caractères)',
  NewOrderSummary_label_placeholder:
    'Please enter customer label details (Optional, max {limit} {limit, plural, =0 {character} one {character} other {characters}})',
  NewOrderSummary_months: 'Mois',
  NewOrderSummary_net_total:
    'Votre montant net total approximatif par mois (sans TVA)**.',
  NewOrderSummary_order: 'Commande',
  NewOrderSummary_phone_plan_details:
    'Commentaires facultatifs sur la commande de votre forfait téléphonique',
  NewOrderSummary_po_rcn_placeholder:
    'Veuillez renseigner PO/RCN (Optionnel, max {limit} {limit, plural, =0 {character} one {character} other {charactères}})',
  NewOrderSummary_price: 'Prix',
  NewOrderSummary_quantity: 'Quantity',
  NewOrderSummary_rental_duration: 'Durée de la location',
  NewOrderSummary_shipping_address: 'Adresse de livraison',
  NewOrderSummary_to_dashboard: 'Retour au tableau de bord',
  NewOrderSummary_total: 'Co-paiement mensuel (hors TVA)',
  NewOrderSummary_total_basis: 'Montant total par mois',
  NewOrderSummary_total_gross: 'Co-paiement mensuel (TVA incluse)',
  NewOrder_choose_accessories_header: 'Choisissez vos accessoires (optionnel)',
  NewOrder_choose_accessories_subheader:
    'Les frais de location mensuels et les éventuels co-paiements mensuels sont indiqués sans TVA applicable.',
  NewOrder_choose_device_header: 'Choisir un nouvel appareil',
  NewOrder_choose_device_subheader:
    'Les frais de location mensuels et les éventuels co-paiements mensuels sont indiqués sans TVA applicable.',
  NewOrder_order_summary_header: 'Résumé de la commande',
  NewOrder_order_summary_subheader: 'Veuillez revoir votre commande',
  NewOrder_shipping_address_header: "Adresse d'expédition",
  NewOrder_shipping_address_subheader:
    "Quelle est l'adresse d'expédition ? Où souhaitez-vous recevoir vos appareils ?",
  NewOrder_step: 'Étape',
  NewPhonePlanModal_prices_may_vary:
    'Les prix peuvent varier et sont indicatifs',
  NewSmartphoneExchangeModal_DescriptionExchange:
    "Avec l'expiration de la durée de location, vous avez maintenant le droit de choisir un nouvel appareil.",
  NewSmartphoneFlow_limit_exceeded:
    'Désolé, vous ne pouvez pas commander d\'autres appareils. Veuillez aller à "Demandes" pour voir vos commandes récentes ou contacter votre administrateur pour plus d\'informations.',
  New_device: 'Nouvel appareil',
  New_device_bundle: 'Nouvel appareil Bundle',
  New_employee: 'Nouveau employé',
  New_joiners_this_month: 'New joiners this month',
  New_phone_plan: 'Nouveau forfait mobile',
  New_question: 'Nouvelle question',
  Next: 'Suivant',
  No: 'Non',
  'No {name} available': 'Pas de {nom} disponible',
  No_depot_found: 'Aucun dépôt trouvé',
  No_devices_found: 'Aucun appareil trouvé',
  No_name_given: 'Pas de nom donné',
  No_results: 'Vous n’avez pas encore de cas de {entity}',
  No_results_title: 'Oups ! Impossible de trouver vos résultats',
  OfferAcceptedContent_content:
    "Les instructions de paiement vous seront envoyées sous peu par courrier électronique ; surveillez donc votre boîte de réception. Si vous ne recevez pas d'e-mail, veuillez nous contacter pour obtenir de l'aide.",
  OfferAcceptedContent_heading: 'Confirmation reçue',
  OfferDeclinedContent_content_line1:
    "Veuillez suivre les instructions figurant dans l'e-mail que vous avez reçu pour renvoyer votre appareil dans les délais.",
  OfferDeclinedContent_content_line2:
    "Pour toute autre question, veuillez contacter notre équipe d'assistance.",
  OfferDeclinedContent_heading:
    'Merci de votre confirmation, votre offre est maintenant expirée.',
  OfferErrorContent_content_line1: `Il semble que quelque chose n'a pas fonctionné ici. Pour continuer, veuillez nous contacter par courriel ou par téléphone`,
  OfferErrorContent_heading: `Oups ! Quelque chose s'est mal passé`,
  OfferPaymentForm_billing_details: 'Détails de la facturation',
  OfferPaymentForm_payment_details: 'Modalités de paiement',
  OfferPaymentForm_submit: 'Achat',
  OfferStatus_fallback_content:
    'Seems like something went wrong here. Please contact us via e-mail or phone.',
  OfferStatus_fallback_heading: 'Oops!',
  OfferStatus_payment_failed_content:
    'Unfortunately there was an error processing your purchase. If you need assistance, please contact us.',
  OfferStatus_payment_failed_heading: 'Payment unsuccessful',
  OfferStatus_purchased_content:
    'Thank you for your purchase. We hope you enjoy your device.',
  OfferStatus_purchased_heading: 'Purchase complete!',
  OfferSummaryFooter_conditions_link: 'Conditions',
  OfferSummaryFooter_dataprotection_link: 'Protection des données',
  OfferSummary_heading: 'Paiement à Everphone Enterprise V GmbH',
  OfferSummary_subtotal: 'Sous-total',
  OfferSummary_tax: 'TVA',
  OfferSummary_total: 'Montant total',
  Office: 'Bureau',
  Open: 'En cours',
  Open_accessibility_menu: `Ouvrir le menu d'accessibilité`,
  Open_menu: `Ouvrir le menu`,
  Open_orders: 'Open orders',
  Open_repairs: 'Open repairs',
  Order: 'Command',
  OrderIntercomButton_Contact_form: 'Contactez nous.',
  OrderIntercomButton_Have_a_question: 'Tu as une question ?',
  OrderIntercomButton_Open_chat: 'Ouvrir le chat',
  OrderIntercomButton_Subtitle:
    'Si votre organisation ne supporte pas la fonction de chat, veuillez utiliser',
  OrderListContent_header__title: 'Commande(s)',
  OrderProlong_extend_rental_header: 'Prolonger la location',
  OrderSelectionCard_refurbished_desc:
    'See how choosing a refurbished device will help reducing the impact on environment compared to a brand-new device',
  OrderSelectionCard_refurbished_title:
    'Choose a refurbished model as your next device',
  Order_DeviceRentalStep_heading: `Mise à niveau de l'appareil`,
  Order_EarlyUpgrade_Info: `Pour une mise à niveau pendant la période de location, des frais de traitement seront appliqués. Les mises à niveau sont limitées aux modèles de la catégorie de location actuelle ou supérieure. La période de location pour le nouveau modèle sélectionné sera spécifiée au moment de la sélection.`,
  Order_Upgrade_Info:
    "Avec l'expiration de la durée de location, vous avez maintenant le droit de choisir un nouvel appareil.",
  Order_details: 'Détails de la commande',
  Order_new_device: 'Commander un nouvel appareil',
  Order_phone_plan: 'Commander un forfait mobile',
  Order_replacement_requested: 'Remplacement demandé',
  Order_upgrade_fee_info:
    'Ce montant, ainsi que le paiement supplémentaire, sera déduit de votre salaire.',
  Orders: 'Commandes',
  Orders_and_returns: 'Retours et commandes',
  Organisation: 'Entreprise',
  Organisations: 'Organisations',
  'Original device': 'Appareil original',
  Other: 'Autre',
  OurSupportWillGetInTouch: 'Notre support va bientôt vous contacter',
  Overview: 'Aperçu',
  PageSizeSelect_label: '{size} lignes/page',
  Pagination_jumpBackwardLabel: "Vers l'arrière",
  Pagination_jumpForwardLabel: "Vers l'avant",
  Pagination_nextLabel: 'Page suivante',
  Pagination_pageLabel: 'Page {page}',
  Pagination_previousLabel: 'Page précédente',
  Password: 'Mot de passe',
  Password_reset_content:
    'Please check your company email inbox. You will receive the password reset instructions if your account is linked with the company email address you entered.',
  Password_reset_header: 'Password has been reset',
  Payment: 'Paiement',
  PaymentFailedContent_content_line1:
    "Malheureusement, une erreur s'est produite lors du traitement de votre achat. Veuillez vérifier les détails de votre paiement et réessayer.",
  PaymentFailedContent_content_line2:
    "Si vous avez besoin d'aide, veuillez nous contacter",
  PaymentFailedContent_cta: 'Réessayer',
  PaymentFailedContent_heading: 'Paiement non réussi',
  Percent_of_total: '({total}% of total)',
  Phone: 'Téléphone',
  PhonePlanDetails_no_pin_number: 'Aucun code PIN donné',
  PhonePlanDetails_no_puk_number: 'Pas de code PUK donné',
  PhonePlanDetails_pin_number: 'PIN',
  PhonePlanDetails_provider_hint:
    "Info: Les forfaits mobiles ne peuvent être annulés que par l'employé en contactant le fournisseur directement.",
  PhonePlanDetails_puk_number: 'PUK',
  PhonePlanListContent_header__employee: 'Employé',
  PhonePlanListContent_header__name: 'Nom',
  PhonePlanListContent_header__phone_number: 'Numéro de téléphone',
  PhonePlanListContent_header__start_date: 'Début du Contrat',
  Phone_number: 'Numéro de téléphone',
  Phone_plan: 'Forfait mobile',
  Phone_plan_details: 'Détails du forfait mobile',
  Phone_plans: 'Forfaits mobiles',
  PleaseTryAgainLater: 'Merci de réessayer plus tard.',
  PortalLogin_accountLocked:
    '3 tentatives de connection refusées. Attendez 5 minutes avant de réessayer.',
  PortalLogin_description:
    'La solution tout-en-un pour gérer sans effort les appareils mobiles de votre entreprise',
  PortalLogin_header: 'Bienvenue',
  PortalLogin_header_to: 'Bienvenue chez {name}',
  PortalLogin_title: 'Travailler plus intelligemment avec Everphone',
  Postcode: 'Code postal',
  Preparing: 'Preparing',
  PreviewColumn_copayment_amount: 'Montant du co-paiement',
  PreviewColumn_cost_center: 'Centre de coûts',
  PreviewColumn_created: 'Créé',
  PreviewColumn_delivery_status: 'Statut de la livraison',
  PreviewColumn_eid: 'EID',
  PreviewColumn_email: 'Email',
  PreviewColumn_employee: 'Employé',
  PreviewColumn_employee_id: "Identifiant de l'employé",
  PreviewColumn_footer_button: 'Voir tous les détails',
  PreviewColumn_footer_question: 'Posez une question',
  PreviewColumn_imei_1: 'IMEI',
  PreviewColumn_imei_2: 'IMEI 2',
  PreviewColumn_intercom_intro:
    "J'ai une question concernant ma demande {type}, numéro {id}",
  PreviewColumn_min_term: 'Durée de location minimale',
  PreviewColumn_monthly_amount: 'Prix mensuel',
  PreviewColumn_months:
    "'(Total: {total} {total, plural, =0 {appareils} one {appareil} other {appareils}})'",
  PreviewColumn_office: 'Bureau',
  PreviewColumn_organisation: 'Entreprise',
  PreviewColumn_phone_number: 'Numéro de téléphone',
  PreviewColumn_reason: 'Motif',
  PreviewColumn_request_date: 'Date de la demande',
  PreviewColumn_request_id: 'Numéro de la demande',
  PreviewColumn_return_completed: 'Restitution terminée',
  PreviewColumn_return_date: 'Date de retour',
  PreviewColumn_return_id: 'Numéro de retour',
  PreviewColumn_serial: 'Numéro de série',
  PreviewColumn_sim_card_number: 'Numéro de carte SIM',
  PreviewColumn_start: 'Date de début',
  PreviewColumn_start_of_rental: 'Début de la location',
  PreviewColumn_title_preview: '{columnType} aperçu',
  PreviewColumn_type_employee: 'Employé',
  PreviewColumn_type_phone_plan: 'Forfait mobile',
  PreviewColumn_type_rental: 'Location',
  PreviewColumn_type_request: 'Demande',
  PreviewColumn_upgrade_date: 'Date de la mise à niveau',
  Price: 'Prix',
  Processing: 'Traitement',
  ProgressHeader_title: 'Étape {step}: {title}',
  ProlongConfirmation_device: 'Appareil',
  ProlongConfirmation_heading: 'Extension de la durée de location',
  ProlongConfirmation_new_end_date: `Prolongé jusqu'au`,
  ProlongConfirmation_new_price: 'Prix',
  ProlongConfirmation_subheading: 'Merci de contribuer à un avenir durable!',
  ProlongConfirmation_to_dashboard: 'Retour au tableau de bord',
  ProlongConfirmation_total_amount: 'Co-paiement mensuel (hors TVA)',
  ProlongationSummary_accept:
    "Par la présente, j'accepte les conditions de la prolongation de la location.",
  Prolongation_valid_till: "Prolongation de la location jusqu'à",
  Prolonged_rentals: 'Locations prolongées',
  PurchaseListContent_header__date: "Date'",
  PurchaseListContent_header__name: "Nom de l'appareil'",
  PurchaseListContent_header__status: 'Statut',
  PurchaseMyDevice: "J'aimerais acheter mon appareil.",
  PurchaseSuccessfulContent_content_line1:
    'Nous vous remercions pour votre achat. Votre e-mail de confirmation vous sera envoyé dans les plus brefs délais.',
  PurchaseSuccessfulContent_content_line2:
    'Nous espérons que vous apprécierez votre appareil.',
  PurchaseSuccessfulContent_content_line3:
    "Si vous ne recevez pas d'e-mail de confirmation, veuillez nous contacter pour obtenir de l'aide.",
  PurchaseSuccessfulContent_heading: 'Achat réussi !',
  Purchase_device: 'Dispositif Spruchase',
  Purchases: 'Achats',
  PutEmployeeModal_allow_login: "Autoriser l'utilisateur à se connecter",
  PutEmployeeModal_allow_login_send_email:
    "Autoriser l'utilisateur à se connecter et envoyer un e-mail de bienvenue",
  PutEmployeeModal_save_changes: 'Sauvegarder les changements',
  QRCodeCard_heading: 'Scan this QR code',
  Quantity: 'Quantité',
  QuestionDeliveryTime: 'Demande concernant le délai de livraison',
  QuestionDeviceManagement:
    'Demande concernant la gestion des dispositifs mobiles',
  QuestionReplacingDevice:
    "Demande concernant le remplacement d'un appareil défectueux",
  RangeInput_aria_label:
    'Faire glisser pour sélectionner une ou plusieurs valeurs entre {min} et {max}',
  RangeInput_max_value: 'Valeur maximale',
  RangeInput_min_value: 'Valeur minimale',
  RangeInput_selectedValue: 'Valeur sélectionnée',
  RangeInput_value: 'Valeur',
  Reason: 'Raison',
  ReassignDevice_Button: "Attribuer l'appareil à un autre employé",
  ReassignDevice_ModalSearchEmployee: 'Sélectionner un autre employé',
  ReassignDevice_ModalTitle: "Attribuer l'appareil à un autre employé",
  ReassignDevice_SubmissionError:
    "Désolé, quelque chose s'est mal passé. Veuillez réessayer.",
  ReassignDevice_SubmissionSuccess:
    "Cet appareil en location a été réattribué avec succès  à {employeeName}'",
  Reassign_device: 'Attribuer cet appareil à un autre employé',
  Received: 'Reçu',
  Recent_requests: 'Demandes récentes',
  Recent_shipments: 'Envois récents',
  Refresh_data: 'Actualiser les données',
  Refresh_the_page: 'Actualiser la page',
  Reject: 'Rejeter',
  Rejected: 'Annulé',
  RentCategoryTier: 'Groupe de location Tier',
  Rent_category_tier: 'Catégorie de location tier',
  Rent_new_device: 'Louer un nouvel appareil',
  Rental: 'Location',
  RentalDetails_alert_cancel:
    "Une demande d'annulation de cette location est en cours de traitement. Vous pouvez vérifier le statut de la demande",
  RentalDetails_alert_lost_device:
    'Cet appareil est déclaré comme perdu ou volé. Vous pouvez vérifier le statut de la demande',
  RentalDetails_alert_prolonging:
    'Prolongez votre période de location pour un coût mensuel inférieur ou passez à un nouvel appareil pour une nouvelle période de location.',
  RentalDetails_alert_repair:
    'Cet appareil est déclaré comme défectueux. Vous pouvez vérifier le statut de la demande',
  RentalDetails_alert_subhead: ' ',
  RentalDetails_alert_upgrade:
    'Vous pouvez maintenant passer à un nouvel appareil. Ceci lancera une nouvelle location avec une nouvelle durée.',
  RentalDetails_check_status: 'ici',
  RentalDetails_copayment: 'Co-paiement mensuel',
  RentalDetails_eid: 'EID',
  RentalDetails_imei: 'IMEI',
  RentalDetails_imei2: 'IMEI2',
  RentalDetails_minimum_term: 'Durée de location minimale',
  RentalDetails_monthly_amount: 'Prix de location mensuel',
  RentalDetails_months: '{total} mois',
  RentalDetails_select_a_new:
    'Choisir un nouveau modèle pour une mise à niveau gratuite après le',
  RentalDetails_serial: 'Numéro de série',
  RentalDetails_start_of_rental: 'Début de la location',
  RentalDetails_you_can_now:
    'Vous pouvez désormais passer à un nouvel appareil de votre choix en payant une redevance unique de mise à niveau anticipée.',
  RentalDetails_your_rental: 'Votre Location',
  RentalDuration: 'Durée de location minimale: {total} mois',
  RentalEntityCard_rental_id: 'Identifiant de la location',
  RentalProlongingButton_additional_12_months: `12 mois de service supplémentaires, y compris l'échange rapide de l'appareil`,
  RentalProlongingButton_extend_your_rental: `Cliquez ici pour prolonger votre location et protéger l'environnement`,
  RentalProlongingButton_plant_trees:
    'Pour chaque extension, Everphone plante 30 arbres',
  RentalProlongingButton_save_50: `Cliquez ici pour économiser jusqu'à 50 % en prolongeant votre location et protéger ainsi l'environnement`,
  RentalProlonging_not_eligible:
    'Cette location ne peut actuellement pas être prolongée.',
  Rental_details: 'Détails de la location',
  Rental_prolonging_description:
    "Vous pouvez maintenant prolonger la location de votre appareil actuel et économiser de l'argent. Everphone soutient la durabilité et plante 30 (!) arbres pour chaque prolongation. De plus, en fonction de votre plan, vous pouvez économiser jusqu'à 50% de votre co-paiement. À défaut, vous pouvez également choisir un nouvel appareil.",
  Rental_prolonging_info_title:
    'Prolonger la location ou choisir un nouvel appareil',
  Rental_prolonging_prolong_upgrade: 'Choisir un nouvel appareil',
  Rentals: 'Locations',
  RepairUpgrade_info_header: 'Repair Upgrade',
  RepairUpgrade_info_text:
    'Malheureusement, votre appareil ne peut pas être réparé. Cependant, vous avez la possibilité de passer à un modèle plus récent de votre choix. Cela commencera un nouveau contrat de location.',
  Repair_upgrade: 'Repair upgrade',
  ReplaceBrokenDeviceModal_header: 'Signaler un appareil défectueux',
  ReplaceBrokenDeviceModal_noReplacementFound: `Aucun appareil de remplacement correspondant n'a été trouvé dans le(s) dépôt(s).`,
  ReplaceBrokenDeviceModal_orderDepot: 'Commander le remplacement au dépôt',
  ReplaceBrokenDeviceModal_orderEverphone: `Commander un remplacement auprès d'Everphone *`,
  ReplaceBrokenDeviceModal_replacementModelNote:
    '* Le même modèle ou un modèle similaire sera envoyé en remplacement.',
  Replace_broken_device: 'Remplacer un appareil cassé',
  Replace_from_depot: 'Remplacement au dépôt',
  Replacement_device: "Remplacement de l'appareil",
  RequestDetails_Track_Order: 'Suivre votre commande',
  RequestDetails_agent: 'Agent',
  RequestDetails_billing_identifier: 'Billing identifier',
  RequestDetails_custom_client_reference: 'Custom client reference',
  RequestDetails_customerLabel: 'Customer label details',
  RequestDetails_date: 'Date',
  RequestDetails_device: 'Appareil',
  RequestDetails_employee: 'Employé',
  RequestDetails_monthly_copayment: 'Co-paiement mensuel',
  RequestDetails_po_rcn: 'PO/RCN',
  RequestDetails_rent_category: 'Catégorie de location',
  RequestDetails_request_id: 'Numéro de ticket',
  RequestListContent_header__agent: 'Responsable',
  RequestListContent_header__date: 'Date',
  RequestListContent_header__employee: 'Employé',
  RequestListContent_header__status: 'Statut',
  RequestListContent_header__title: 'Demande',
  RequestPurchaseModal_confirmationDescription:
    'Nous souhaitons  vous proposer le meilleur prix d’achat possible. Notre équipe vous enverra une proposition commerciale par e-mail dans les plus brefs délais.',
  RequestPurchaseModal_confirmationHeader: 'Dispositif d’Achat',
  RequestPurchaseModal_confirmationTitle:
    'Nous te remercions de l’intérêt que tu portes à l’achat de cet appareil.',
  RequestPurchaseModal_invalidDescription:
    'Veuillez contacter votre administrateur pour le retour des appareils.',
  RequestPurchaseModal_invalidHeader: 'Dispositif d’Achat',
  RequestPurchaseModal_invalidTitle:
    'Cet appareil n’ est malheureusement pas être acheté.',
  RequestPurchaseModal_landingCheckbox:
    'L’appareil présente-t-il des dommages visibles ou des problèmes de performance?',
  RequestPurchaseModal_landingDescription:
    'Pour vous faire parvenir une belle offre, veuillez nous envoyer quelques informations sur votre appareil. Veuillez vérifier que le model de l’appareil, le numéro de série et le(s) IMEI(s) correspondent à votre appareil.',
  RequestPurchaseModal_landingHeader: 'Dispositif d’Achat',
  RequestPurchaseModal_landingTitle:
    'Nous vous remercions pour l’intérêt que vous portez à l’achat de cet appareil.',
  RequestPurchaseModal_summaryHeader: 'Dispositif d’Achat - résumé',
  Request_details: 'Détails de la demande',
  Request_id: 'Numéro de Ticket',
  Requests: 'Demandes',
  Required_fields: '* Champs obligatoires',
  Reset: 'Réinitialiser',
  Reset_password: 'Reset Password',
  Reset_password_content:
    'To reset your password, please enter your company e-mail address. We will send the reset instructions to change your password.',
  Reset_password_header: 'Reset your password',
  Resolved_unreturned: 'Résolu sans retour',
  Results: 'Results',
  Return: 'Retourner',
  ReturnDetails_created: 'Créé',
  ReturnDetails_device: 'Appareil',
  ReturnDetails_employee: 'Employé',
  ReturnDetails_imei: 'IMEI',
  ReturnDetails_imei2: 'IMEI2',
  ReturnDetails_reason_for_return: 'Motif du retour',
  ReturnDetails_return_completed: 'Restitution Terminée',
  ReturnDetails_return_date: 'Date de retour',
  ReturnDetails_serial_number: 'Numéro de série',
  ReturnDetails_start_of_rental: 'Date de début',
  ReturnDetails_your_return: 'Votre retour',
  ReturnDeviceModal_no_depots: 'Aucun dépôt de retour trouvé',
  ReturnListContent_header__created: 'Créé',
  ReturnListContent_header__employee: 'Employé',
  ReturnListContent_header__status: 'Statut',
  ReturnListContent_header__title: 'Retour',
  Return_depot: 'Retour dépôt',
  Return_details: 'Détails du retour',
  Return_device: 'Retour appareil',
  Return_id: 'Numéro de retour',
  Returns: 'Retour',
  RouteGuard_continue: 'Continuer',
  RouteGuard_heading: 'Êtes vous sûr de vouloir quitter?',
  RouteGuard_leave_page: 'Quitter la page',
  RouteGuard_subheading:
    'En quittant la page, toutes les modifications que vous avez apportées seront perdues.',
  SSOButton_Google_label: 'Se connecter avec Google',
  SSOButton_Microsoft_label: 'Se connecter avec Microsoft',
  Save: 'Enregistrer',
  Search: 'Rechercher',
  Security: 'Sécurité',
  See_all: 'Voir tous les détails',
  See_delivery_status: `Statut de l'envoi`,
  See_more: 'Voir plus',
  Select_country: 'Sélectionnez un pays',
  Select_org_or_employee: 'Sélectionnez une organisation ou un employé',
  Select_placeholder: 'Sélectionner...',
  SelectionCard_effective_price: 'Prix effectif',
  SelectionCard_monthly_copayment: 'Co-paiement mensuel',
  SelectionCard_monthly_price: 'Prix mensuel',
  Sender_country: 'Pays expéditeur',
  Serial_number: 'Numéro de Série',
  ServiceApp_show_details: 'Show Details',
  ShipmentTrackingCard_delivery_status: 'État de la livraison',
  ShipmentTrackingCard_shipping: 'Livraison',
  ShipmentTrackingCard_shipping_address: 'Adresse de livraison',
  ShipmentTrackingCard_track_and_trace: 'Suivi de colis',
  ShipmentTrackingCard_track_order: 'Suivre votre commande',
  ShipmentTrackingCard_track_return: 'Suivre votre retour',
  ShippedOrdersCard_heading: 'Suivi des envois',
  ShippingAddressForm_heading: "Quelle est l'adresse de livraison ?",
  Shipping_address: "Adresse d'expédition",
  Sim_card_number: 'Numéro de carte SIM',
  Sitemap: 'Plan du site',
  Sitemap_additional_pages: 'Autres pages',
  Sitemap_main_sections: 'Sections principales',
  Sold: 'Vends',
  Start: 'Début',
  Start_date: 'Début de location',
  State: 'État',
  Status: 'Statut',
  Street_addition: "Complément d'adresse",
  Street_name: 'Rue',
  Street_name_and_number: 'Numéro de rue et nom de la rue',
  Street_number: 'Numéro',
  Submit: 'Soumettre',
  Success: 'Succès',
  Summary: 'Résumé',
  TableCard_total:
    '{amount} {amount, plural, =0 {devices} =1 {device} other {devices}} in total',
  TechnicalIssues: 'Erreur(s) technique(s)',
  Terms: 'Conditions générales',
  To: 'Déstinataire',
  Total: 'Total',
  TotalCounter_total: 'de',
  Total_last_30_days: 'Total des 30 derniers jours',
  Total_orders:
    '{total} total {total, plural, =0 {orders} =1 {order} other {orders}}',
  Tracking_reference: "Numéro d'envoi",
  Unknown_case_type: 'Unknown case type',
  Unknown_contact: 'Contact inconnu',
  Unnamed_phone_plan: 'Forfait mobile sans nom',
  UnreturnedDevicesCard_heading: 'Retours ouverts',
  Upcoming: 'En attente',
  Update_tracking_information: "Actualiser le statut de l'envoi",
  Upgrade: 'Mettre à niveau',
  Upgrade_Fee_ExclVAT: 'Frais de mise à niveau unique (hors TVA)',
  Upgrade_device: "Mise à niveau de l'appareil",
  Upgrade_possible: 'Mise à niveau possible',
  Username: "Nom d'utilisateur",
  VAT: 'TVA',
  Valid_from_date: 'Date de validité',
  View_orders: 'Voir les commandes',
  View_rentals: 'Voir les locations',
  Waiting: 'Attendre',
  Yes: 'Oui',
  YourRequestHasBeenSubmitted: 'Votre demande a été soumise',
  Zip: 'ZIP',
  for: 'pour',
  typesenseAttribute_case_item_copayment: 'Copaiement',
  typesenseAttribute_case_item_created: 'Date',
  typesenseAttribute_case_item_device_definition_manufacturer: 'Fabricant',
  typesenseAttribute_case_item_device_definition_name: 'Modèle',
  typesenseAttribute_case_item_device_definition_type: 'Type Appareil',
  typesenseAttribute_case_item_employee: 'Employé',
  typesenseAttribute_case_item_status: 'Status de la commande',
  typesenseAttribute_case_item_type: 'Type de commande',
  typesenseAttribute_employee_cost_center_name: 'Centre de coût',
  typesenseAttribute_employee_email: 'Adresse e-mail',
  typesenseAttribute_employee_exit_date: 'Date de sortie',
  typesenseAttribute_employee_external_reference:
    'Employee ID (External reference)',
  typesenseAttribute_employee_full_name: 'Employé',
  typesenseAttribute_employee_has_active_rental: 'Location active',
  typesenseAttribute_employee_office: 'Bureau',
  typesenseAttribute_employee_phone_number: 'Numéro de téléphone',
  typesenseAttribute_employee_rent_category_tier: 'Catégorie de location tier',
  typesenseAttribute_rental_employee_exit_date: "Date de sortie de l'employé",
  typesenseAttribute_rental_end_at: 'Date de fin de location',
  typesenseAttribute_rental_is_prolonged: 'Est prolongé',
  typesenseAttribute_rental_minimum_contract_duration:
    'Durée minimale du contrat',
  typesenseAttribute_rental_rental_price_copayment_amount: 'Copaiement',
  typesenseAttribute_rental_start_at: 'Date de début de la location',
  typesenseAttribute_rental_stocklist_item_codename: 'Modèle',
  typesenseAttribute_rental_stocklist_item_manufacturer: 'Fabricant',
  typesenseAttribute_rental_upgrade_date:
    'Mise à niveau disponible à partir de',
  typesenseAttribute_return_case_created: 'Retour créé',
  typesenseAttribute_return_case_employee_exit_date:
    "Date de sortie de l'employé",
  typesenseAttribute_return_case_employee_office: 'Bureau',
  typesenseAttribute_return_case_open_since: 'En attente de retour depuis',
  typesenseAttribute_return_case_rental_cost_center: 'Centre de coût',
  typesenseAttribute_return_case_return_depot_name: 'Dépôt de retour',
  typesenseAttribute_return_case_status: 'Statut du retour',
  typesenseAttribute_return_case_stocklist_item_codename: 'Modèle',
  typesenseAttribute_return_case_stocklist_item_manufacturer: 'Fabricant',
  typesenseAttribute_unknown_attribute: 'Attribut inconnu',
  ...dataPrivacyTranslations,
  ...backendFrTranslations,
}

export default fr
