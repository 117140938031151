import { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { PreviewColumn } from 'Components/PreviewColumn/PreviewColumn'
import { PreviewColumnTypeEnum } from 'Components/PreviewColumn/PreviewColumn.types'
import { PreviewColumnContent } from 'Components/PreviewColumn/PreviewColumnContent'
import { PreviewColumnHeader } from 'Components/PreviewColumn/PreviewColumnHeader'

import {
  getEmployeeListPreviewColumn,
  getEmployeeListPreviewColumnVariables,
} from 'Constants/graphql/queries/detail/__generated__/getEmployeeListPreviewColumn'
import { GET_EMPLOYEE_LIST_PREVIEW_COLUMN } from 'Constants/graphql/queries/detail/GetEmployeeDetails'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import { EmployeesPreviewColumnProps } from './EmployeesPreviewColumn.types'
import { generateEmployeesPreviewColumnContent } from './EmployeesPreviewColumn.utils'

export const EmployeesPreviewColumn = (props: EmployeesPreviewColumnProps) => {
  const { entityId, onClosePreviewColumn, isVisible } = props
  const intl = useIntl()
  const { organisationId } = useParams<{
    organisationId?: string
  }>()

  const path = composePath({
    params: {
      employeeId: entityId || '0',
      organisationId,
    },
    path: GLOBAL_ROUTE_PATHS.organisation.employee,
  })

  const [getEmployeesPreviewData, { loading: isLoading, data }] = useLazyQuery<
    getEmployeeListPreviewColumn,
    getEmployeeListPreviewColumnVariables
  >(GET_EMPLOYEE_LIST_PREVIEW_COLUMN)

  const generateContent = useMemo(
    () => generateEmployeesPreviewColumnContent(data?.employee),
    [data?.employee],
  )

  const renderEmployeeName = useMemo(() => {
    if (data?.employee.contact.firstName && data?.employee.contact.lastName) {
      return `${data?.employee.contact.firstName} ${data?.employee.contact.lastName}`
    }

    return ''
  }, [data?.employee.contact.firstName, data?.employee.contact.lastName])

  useEffect(() => {
    if (!entityId) {
      return
    }

    getEmployeesPreviewData({
      variables: {
        id: entityId,
      },
    })
  }, [entityId, getEmployeesPreviewData])

  return (
    <PreviewColumn
      contentElement={
        <PreviewColumnContent content={generateContent} twoColumn />
      }
      headerElement={
        <PreviewColumnHeader
          name={renderEmployeeName}
          onClosePreviewColumn={onClosePreviewColumn}
          title={intl.formatMessage({ id: 'Employee' })}
        />
      }
      id={entityId}
      isLoading={isLoading}
      isVisible={isVisible}
      onClosePreviewColumn={onClosePreviewColumn}
      type={PreviewColumnTypeEnum.Employee}
      url={path}
    />
  )
}
