import { createContext, useCallback, useMemo, useState } from 'react'
import ReactModal from 'react-modal'
import { Transition } from 'react-transition-group'
import { clsx } from 'clsx'
import { isNil } from 'lodash-es'

import { INITIAL_MODAL_STATE } from './ModalProvider.constants'
import {
  ModalProviderContextType,
  ModalProviderProps,
} from './ModalProvider.types'

export const ModalContext = createContext<ModalProviderContextType | null>(null)

export const ModalProvider = (props: ModalProviderProps) => {
  const { children, ...rest } = props

  const [modal, setModal] = useState(INITIAL_MODAL_STATE)

  const hideModal = useCallback(() => {
    setModal({ ...modal, isOpen: false })
  }, [modal])

  const resetState = () => {
    setModal(INITIAL_MODAL_STATE)
  }

  const setOnAfterClose = (onAfterClose: () => void) => {
    setModal(modal => ({ ...modal, onAfterClose }))
  }

  const contextValue = useMemo(
    () => ({ hideModal, isModalOpen: modal.isOpen, setModal }),
    [hideModal, modal.isOpen],
  )

  const {
    component: Component,
    contentClassName,
    overlayClassName,
    type,
  } = modal

  const isPopup = type === 'popup'

  return (
    <ModalContext.Provider value={contextValue} {...rest}>
      {children}

      <Transition in={modal.isOpen} onExited={resetState} timeout={300}>
        <ReactModal
          appElement={document.getElementById('root')!}
          className={clsx(
            'ModalProvider',
            isPopup && 'ModalProvider-popup',
            contentClassName,
          )}
          closeTimeoutMS={300}
          isOpen={modal.isOpen}
          onAfterClose={modal.onAfterClose}
          onRequestClose={hideModal}
          overlayClassName={clsx(
            'ModalProvider-overlay',
            isPopup && 'ModalProvider-overlay_popup',
            overlayClassName,
          )}
        >
          {!isNil(Component) ? (
            <Component
              onClose={hideModal}
              setOnAfterClose={setOnAfterClose}
              {...modal.props}
            />
          ) : null}
        </ReactModal>
      </Transition>
    </ModalContext.Provider>
  )
}
