export enum OrderRoutePathsEnum {
  BASE = 'order',
  ORDER_NEW = 'order/new',
  ORDER_UPGRADE = 'order/upgrade/:rentalId',
  ORDER_REPAIR_UPGRADE = 'order/repair-upgrade/:rentalId',
  ORDER_LOST_REPLACEMENT = 'order/lost-replacement/:rentalId',
  ORDER_EARLY_UPGRADE = 'order/early-upgrade/:rentalId',
  ORDER_PROLONG = 'order/prolong/:rentalId',
  ORDER_CONFIRMATION = 'order/confirmation',
  ORDER_CUSTOM_CONFIRMATION = 'order/confirmation/:customType',
  ORDER_PROLONG_CONFIRMATION = 'order/prolong-confirmation',
  ORDER_CATCH_ALL = 'order/*',
}
