import { createContext, useContext } from 'react'
import { configure } from 'mobx'

import { AclsStore } from './aclsStore/aclsStore'
import { DashboardStore } from './dashboardStore/dashboardStore'
import { EmployeeStore } from './employeeStore/employeeStore'
import { NavigationStore } from './navigationStore/navigationStore'
import { OrderStore } from './orderStore/orderStore'
import { OrganisationStore } from './organisationStore/organisationStore'
import { PortalConfigStore } from './portalConfigStore/portalConfigStore'
import { PortalContentStore } from './portalContentStore/portalContentStore'
import { PortalSettingsStore } from './portalSettingsStore/portalSettingsStore'
import { RentalStore } from './rentalStore/rentalStore'
import { UserStore } from './userStore/userStore'

configure({
  enforceActions: 'always',
})

export class RootStore {
  organisationStore: OrganisationStore

  aclsStore: AclsStore

  employeeStore: EmployeeStore

  navigationStore: NavigationStore

  userStore: UserStore

  orderStore: OrderStore

  portalConfigStore: PortalConfigStore

  portalContentStore: PortalContentStore

  portalSettingsStore: PortalSettingsStore

  rentalStore: RentalStore

  dashboardStore: DashboardStore

  constructor() {
    this.aclsStore = new AclsStore()
    this.employeeStore = new EmployeeStore(this)
    this.navigationStore = new NavigationStore(this)
    this.orderStore = new OrderStore(this)
    this.organisationStore = new OrganisationStore(this)
    this.portalConfigStore = new PortalConfigStore(this)
    this.portalContentStore = new PortalContentStore()
    this.portalSettingsStore = new PortalSettingsStore(this)
    this.userStore = new UserStore(this)
    this.rentalStore = new RentalStore()
    this.dashboardStore = new DashboardStore(this)
  }
}

export const store = new RootStore()

export const StoreContext = createContext(store)

export const useStore = () => useContext(StoreContext)
