import { useMemo } from 'react'
import {
  faChartTreeMap,
  faEnvelope,
  faReceipt,
  faUser,
  faWarehouse,
} from '@fortawesome/pro-solid-svg-icons'

import { MenuItem } from 'Components/PortalMenu/PortalMenu.types'

import { PORTAL_CONTENT_MODEL } from 'Constants/portalContentModel'
import {
  PORTAL_HEADING_TRANSLATION_IDS,
  PortalSectionEnum,
} from 'Constants/portalSection'

import { ContentTypeEnum } from 'Types/content/content.types'

import { useActiveUser } from 'Portal/src/hooks/useActiveUser/useActiveUser'
import { useIsOrganisation } from 'Portal/src/hooks/useIsOrganisation/useIsOrganisation'
import { UseMainMenuItemsProps } from 'Portal/src/hooks/useMainMenuItems/useMainMenuItems.types'
import { useShowGlobalSearch } from 'Portal/src/hooks/useShowGlobalSearch/useShowGlobalSearch'
import { useStore } from 'Portal/src/stores'

export const useMainMenuItems = (props?: UseMainMenuItemsProps) => {
  const isUnauthorized = props?.isUnauthorized
  const { navigationStore, portalConfigStore } = useStore()
  const { mainNavigationConfig } = navigationStore
  const { portalConfig } = portalConfigStore

  const shouldShowGlobalSearch = useShowGlobalSearch()
  const isOrganisation = useIsOrganisation()
  const { activeOrganisation } = useActiveUser()

  const unauthorizedMainMenuItems: MenuItem[] = useMemo(
    () => [
      {
        icon: faUser,
        id: 0,
        key: PortalSectionEnum.LoginPage,
        name: PORTAL_HEADING_TRANSLATION_IDS['login-page'],
        path: '/login',
      },
      {
        icon: faEnvelope,
        id: 1,
        key: PortalSectionEnum.Contact,
        name: PORTAL_HEADING_TRANSLATION_IDS.contact,
        path: `/contact`,
      },
    ],
    [],
  )

  const mainMenuItems: MenuItem[] = useMemo(
    () => [
      {
        icon: faChartTreeMap,
        id: 0,
        key: PortalSectionEnum.Dashboard,
        name: PORTAL_HEADING_TRANSLATION_IDS.dashboard,
        path: `${mainNavigationConfig.url}`,
      },
      {
        icon: PORTAL_CONTENT_MODEL[ContentTypeEnum.rentals].icon,
        id: 2,
        key: PortalSectionEnum.Rentals,
        name: PORTAL_HEADING_TRANSLATION_IDS.rentals,
        path: shouldShowGlobalSearch
          ? `${mainNavigationConfig.url}/rentals?searchModel=${
              PORTAL_CONTENT_MODEL[ContentTypeEnum.rentals].modelType
            }`
          : `${mainNavigationConfig.url}/rentals`,
      },
      ...(isOrganisation &&
      (activeOrganisation?.phonePlans?.totalCount !== 0 ||
        portalConfig?.newPhonePlan)
        ? [
            {
              icon: PORTAL_CONTENT_MODEL[ContentTypeEnum.phone_plans].icon,
              id: 3,
              key: PortalSectionEnum.PhonePlans,
              name: PORTAL_HEADING_TRANSLATION_IDS['phone-plans'],
              path: shouldShowGlobalSearch
                ? `${mainNavigationConfig.url}/phone-plans?searchModel=${
                    PORTAL_CONTENT_MODEL[ContentTypeEnum.phone_plans].modelType
                  }`
                : `${mainNavigationConfig.url}/phone-plans`,
            },
          ]
        : []),
      ...(isOrganisation
        ? [
            {
              icon: PORTAL_CONTENT_MODEL[ContentTypeEnum.employees].icon,
              id: 5,
              key: PortalSectionEnum.Employees,
              name: PORTAL_HEADING_TRANSLATION_IDS.employees,
              path: shouldShowGlobalSearch
                ? `${mainNavigationConfig.url}/employees?searchModel=${
                    PORTAL_CONTENT_MODEL[ContentTypeEnum.employees].modelType
                  }`
                : `${mainNavigationConfig.url}/employees`,
            },
          ]
        : []),
      ...(isOrganisation && activeOrganisation?.depots?.totalCount !== 0
        ? [
            {
              icon: faWarehouse,
              id: 6,
              key: PortalSectionEnum.Depots,
              name: PORTAL_HEADING_TRANSLATION_IDS.depots,
              path: `${mainNavigationConfig.url}/depots`,
            },
          ]
        : []),
      ...(isOrganisation && portalConfig?.showInvoices
        ? [
            {
              icon: faReceipt,
              id: 7,
              key: PortalSectionEnum.Invoices,
              name: PORTAL_HEADING_TRANSLATION_IDS.invoices,
              path: `${mainNavigationConfig.url}/invoices`,
            },
          ]
        : []),
      {
        icon: PORTAL_CONTENT_MODEL[ContentTypeEnum.caseitems].icon,
        id: 4,
        key: PortalSectionEnum.Orders,
        name: isOrganisation
          ? PORTAL_HEADING_TRANSLATION_IDS.orders
          : PORTAL_HEADING_TRANSLATION_IDS['orders-and-returns'],
        path:
          shouldShowGlobalSearch && isOrganisation
            ? `${mainNavigationConfig.url}/orders?searchModel=${
                PORTAL_CONTENT_MODEL[ContentTypeEnum.caseitems].modelType
              }`
            : `${mainNavigationConfig.url}/orders`,
      },
      ...(isOrganisation
        ? [
            {
              icon: PORTAL_CONTENT_MODEL[ContentTypeEnum.return_cases].icon,
              id: 9,
              key: PortalSectionEnum.Returns,
              name: PORTAL_HEADING_TRANSLATION_IDS.returns,
              path: shouldShowGlobalSearch
                ? `${mainNavigationConfig.url}/returns?searchModel=${
                    PORTAL_CONTENT_MODEL[ContentTypeEnum.return_cases].modelType
                  }`
                : `${mainNavigationConfig.url}/returns`,
            },
          ]
        : []),
      {
        icon: faEnvelope,
        id: 8,
        key: PortalSectionEnum.Contact,
        name: PORTAL_HEADING_TRANSLATION_IDS.contact,
        path: `${mainNavigationConfig.url}/contact`,
      },
    ],
    [
      activeOrganisation,
      isOrganisation,
      mainNavigationConfig,
      portalConfig,
      shouldShowGlobalSearch,
    ],
  )

  return {
    mainMenuItems: isUnauthorized ? unauthorizedMainMenuItems : mainMenuItems,
  }
}
