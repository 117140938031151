import { gql } from '@apollo/client'

export const MUTATION_LOST_DEVICE = gql`
  mutation mutationLostDevice($input: EmployeeLostDeviceCaseItemInput) {
    createLostDeviceCaseItem(input: $input) {
      ... on LostDeviceSuccess {
        __typename
        result {
          id
        }
      }

      ... on LostDeviceProblem {
        __typename
        invalidInputs {
          field
          message
        }
      }
    }
  }
`
