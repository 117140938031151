import { z } from 'zod'

import { zSelect } from 'Lib/zod'

import { FORM_MESSAGES_MAP } from 'Constants/formMessages'

export const modalDeviceReplacementFormSchema = z.object({
  depot: zSelect.refine(arg => !!arg?.value, FORM_MESSAGES_MAP.REQUIRED),
  nextDeviceId: zSelect.refine(arg => !!arg?.value, FORM_MESSAGES_MAP.REQUIRED),
  previousDevice: z.string(),
})
