import { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  faCircleInfo,
  faReceipt,
  faTruckArrowRight,
} from '@fortawesome/pro-solid-svg-icons'
import { observer } from 'mobx-react'

import { isReturnBlocked } from 'Modules/returns/utils/returns.utils'

import { Alert } from 'Components/_theme'
import { CardActions } from 'Components/CardActions/CardActions'
import { CardActionProps } from 'Components/CardActions/CardActions.types'
import { CardDetailsList } from 'Components/CardDetailsList/CardDetailsList'
import { CardDetailsListItem } from 'Components/CardDetailsList/CardDetailsList.types'
import { ContentCard } from 'Components/ContentCard/ContentCard'
import { FormatDate } from 'Components/FormatDate/FormatDate'

import { CreateReturnLabelModal } from 'Modals/CreateReturnLabelModal/CreateReturnLabelModal'
import { UpdateTrackingModal } from 'Modals/UpdateTrackingModal/UpdateTrackingModal'

import { useActiveUser, useModal } from 'Hooks'

import {
  dashOrSpaceToUnderscore,
  getCaseItemTypeTranslationId,
} from 'Utilities'

import {
  E2E_CREATE_RETURN_LABEL_ACTION,
  E2E_UPDATE_TRACKING_INFO_ACTION,
} from 'Constants/e2e'

import { ReturnCaseStatus } from 'Portal/__generated__/globalTypes'

import { CREATE_SHIPPING_LABEL_EXCLUDED_ORGS } from './ReturnDetailsCard.constants'
import {
  ReturnDetailsCardModalTypes,
  ReturnDetailsCardProps,
} from './ReturnDetailsCard.types'

export const ReturnDetailsCard = observer((props: ReturnDetailsCardProps) => {
  const { returnCase, title, refetch } = props

  const { activeOrganisation, activeEmployee } = useActiveUser()
  const organisation =
    activeOrganisation || activeEmployee?.organisation || null

  const { setModal, hideModal } = useModal()

  const handleModalSubmit = useCallback(() => {
    refetch()

    hideModal()
  }, [hideModal, refetch])

  const generateDetailsCardListItems = useMemo(
    (): CardDetailsListItem[] => [
      ...(returnCase.returnDate
        ? [
            {
              label: 'ReturnDetails_return_date',
              value: <FormatDate value={returnCase.returnDate} />,
            },
          ]
        : []),
      {
        label: 'ReturnDetails_reason_for_return',
        value: returnCase.caseItem?.caseType ? (
          <FormattedMessage
            id={getCaseItemTypeTranslationId(returnCase.caseItem.caseType)}
          />
        ) : (
          '-'
        ),
      },
    ],
    [returnCase],
  )

  const handleOpenCreateReturnLabelModal = useCallback(() => {
    setModal({
      component: CreateReturnLabelModal,
      isOpen: true,
      props: {
        returnCase,
      },
    })
  }, [setModal, returnCase])

  const handleOpenUpdateTrackingModal = useCallback(() => {
    setModal({
      component: UpdateTrackingModal,
      isOpen: true,
      props: {
        handleSubmit: handleModalSubmit,
        returnCase,
      },
    })
  }, [setModal, handleModalSubmit, returnCase])

  const shouldShowCreateReturnLabelAction = useMemo(() => {
    const shouldHideReturnLabel = [
      ReturnCaseStatus.done,
      ReturnCaseStatus.rejected,
      ReturnCaseStatus.device_sold_closed,
      ReturnCaseStatus.lost_device_closed,
    ]

    if (!organisation?.id || organisation?.currency === 'USD') {
      return false
    }

    if (
      shouldHideReturnLabel.includes(dashOrSpaceToUnderscore(returnCase.status))
    ) {
      return false
    }

    return !CREATE_SHIPPING_LABEL_EXCLUDED_ORGS.includes(
      Number(organisation.id),
    )
  }, [organisation, returnCase])

  const generateActions = useMemo(
    (): CardActionProps[] => [
      ...(shouldShowCreateReturnLabelAction
        ? [
            {
              e2eSelector: E2E_CREATE_RETURN_LABEL_ACTION,
              icon: faTruckArrowRight,
              id: ReturnDetailsCardModalTypes.CreateReturnLabelModal,
              name: 'Create_DHL_return_label',
              onActionClick: handleOpenCreateReturnLabelModal,
            },
          ]
        : []),
      {
        e2eSelector: E2E_UPDATE_TRACKING_INFO_ACTION,
        icon: faReceipt,
        id: ReturnDetailsCardModalTypes.UpdateTrackingModal,
        name: 'Update_tracking_information',
        onActionClick: handleOpenUpdateTrackingModal,
      },
    ],
    [
      handleOpenCreateReturnLabelModal,
      handleOpenUpdateTrackingModal,
      shouldShowCreateReturnLabelAction,
    ],
  )

  return (
    <ContentCard className="ReturnDetailsCard" title={title}>
      <div className="ReturnDetailsCard-body">
        <div>
          <CardDetailsList
            className="mb-4"
            listItems={generateDetailsCardListItems}
          />

          {isReturnBlocked(returnCase) && (
            <Alert
              className="mt-4 mb-2"
              iconLeft={faCircleInfo}
              variant="danger"
            >
              <FormattedMessage id="Device_locked_alert" />
            </Alert>
          )}
        </div>

        <CardActions actions={generateActions} type="return" />
      </div>
    </ContentCard>
  )
})
