import { useRef, useState } from 'react'
import DatePicker, { DateObject } from 'react-multi-date-picker'
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons'
import { clsx } from 'clsx'
import FocusTrap from 'focus-trap-react'

import { useEscapeKeyListenerEffect } from 'Hooks'

import { getDatePattern } from 'Utilities'

import { TertiaryButton } from '../_theme/TertiaryButton/TertiaryButton'
import { MultidatePickerProps } from './MultidatePicker.types'
import { MultidatePickerInput } from './MultidatePickerInput'
import { MultidatePickerToolbar } from './MultidatePickerToolbar'

// TODO: might to change order for different locales
const weekDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']

export const MultidatePicker = (props: MultidatePickerProps) => {
  const {
    autoComplete,
    className,
    onChange,
    customToolbarConfig,
    name = '',
    defaultValue,
    e2eSelector,
    toolbarE2eSelector,
    ...restProps
  } = props
  const [value, setValue] = useState(defaultValue)
  const [isOpen, setIsOpen] = useState(false)
  const datepickerRef = useRef<any>(null)

  const classNames = clsx('MultidatePicker', className)

  const handleChange = (selectedDates: DateObject | DateObject[] | null) => {
    setValue(selectedDates)

    if (typeof onChange === 'function') {
      onChange(selectedDates)
    }
  }

  const handleCloseDatepicker = () => {
    setIsOpen(false)

    datepickerRef.current?.closeCalendar()
  }

  useEscapeKeyListenerEffect(handleCloseDatepicker, isOpen, datepickerRef)

  return (
    <FocusTrap
      active={isOpen}
      focusTrapOptions={{ clickOutsideDeactivates: true }}
    >
      <DatePicker
        ref={datepickerRef}
        arrow={false}
        className={classNames}
        containerClassName="MultidatePicker-container"
        dateSeparator=" - "
        format={getDatePattern()}
        name={name}
        numberOfMonths={2}
        onChange={handleChange}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        plugins={[
          <MultidatePickerToolbar
            customToolbarConfig={customToolbarConfig}
            e2eSelector={toolbarE2eSelector}
            name={name}
            onChange={handleChange}
            position="bottom"
          />,
        ]}
        range
        render={
          <MultidatePickerInput
            autoComplete={autoComplete}
            e2eSelector={e2eSelector}
            isOpen={isOpen}
            onCloseDatepicker={handleCloseDatepicker}
            setValue={setValue}
          />
        }
        renderButton={(
          direction: 'left' | 'right',
          handleClick: () => void,
        ) => (
          <TertiaryButton
            asIcon={direction === 'right' ? faAngleRight : faAngleLeft}
            onClick={handleClick}
            size="small"
          />
        )}
        value={value}
        weekDays={weekDays}
        {...restProps}
      />
    </FocusTrap>
  )
}
