import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { observer } from 'mobx-react'

import {
  shouldShowTracking,
  shouldShowTrackingReference,
  shouldShowTrackingURL,
} from 'Modules/orders/pages/Order/Order.utils'

import { Card, CardHeader } from 'Components/_theme'

import { CarrierAndTrackingId } from '../CarrierAndTrackingId/CarrierAndTrackingId'
import { DeliveryStatus } from '../DeliveryStatus/DeliveryStatus'
import { OrderShipmentTrackingCardProps } from '../ShipmentTrackingCard.types'
import { ShippingAddress } from '../ShippingAddress/ShippingAddress'

export const OrderShipmentTrackingCard = observer(
  (props: OrderShipmentTrackingCardProps) => {
    const { caseItem } = props

    const showTracking = shouldShowTracking(
      caseItem.caseItemShipping?.performedAt,
    )

    const showTrackingURL = shouldShowTrackingURL(
      caseItem.caseItemShipping?.trackingURL,
      caseItem.caseItemShipping?.performedAt,
    )
    const showTrackingReference = shouldShowTrackingReference(
      caseItem.caseItemShipping?.trackingReference,
    )

    const showShippingAddressCard =
      caseItem.data.requestedShippingAddress ||
      showTrackingURL ||
      showTrackingReference

    const renderDeliveryStatus = useMemo(() => {
      if (
        !showTracking ||
        !caseItem.trackingStatuses?.trackingStatuses ||
        !caseItem.trackingStatuses?.totalCount
      ) {
        return null
      }

      return (
        <DeliveryStatus
          trackingStatuses={caseItem.trackingStatuses.trackingStatuses}
          trackingURL={caseItem.caseItemShipping?.trackingURL}
          type="order"
        />
      )
    }, [caseItem, showTracking])

    const renderCarrierAndTrackingId = useMemo(() => {
      if (
        !caseItem.caseItemShipping ||
        (!showTrackingURL && !showTrackingReference)
      ) {
        return null
      }

      return (
        <CarrierAndTrackingId
          serviceProvider={caseItem.caseItemShipping.serviceProvider || ''}
          showTrackingLink={showTrackingURL}
          showTrackingReference={showTrackingReference}
          trackingReference={caseItem.caseItemShipping.trackingReference || ''}
          trackingURL={caseItem.caseItemShipping.trackingURL || ''}
        />
      )
    }, [caseItem, showTrackingURL, showTrackingReference])

    const renderShippingAddress = useMemo(() => {
      if (!caseItem.data.requestedShippingAddress) {
        return null
      }

      return (
        <ShippingAddress
          assignedEmployee={caseItem.assignedEmployee}
          requestedShippingAddress={caseItem.data.requestedShippingAddress}
        />
      )
    }, [caseItem])

    if (!showShippingAddressCard) {
      return null
    }

    return (
      <Card className="ShipmentTrackingCard mb-6">
        <CardHeader>
          <FormattedMessage id="ShipmentTrackingCard_shipping" />
        </CardHeader>

        <div className="ShipmentTrackingCard-body">
          {renderDeliveryStatus}

          {!!renderDeliveryStatus && !!renderCarrierAndTrackingId && (
            <hr className="my-4" />
          )}

          {renderCarrierAndTrackingId}

          {!!renderCarrierAndTrackingId && !!renderShippingAddress && (
            <hr className="my-4" />
          )}

          {renderShippingAddress}
        </div>
      </Card>
    )
  },
)
