import { cloneDeep } from '@apollo/client/utilities'

import { OrderDeviceGroup } from 'Portal/src/mappers/orderDeviceMapper/orderDevice.types'

export const sortGQLOrderDevicesGroups = (
  orderDeviceGroups: OrderDeviceGroup[] | null,
) => {
  let payload: Array<OrderDeviceGroup> = []
  const orderDeviceGroupsClone = cloneDeep(orderDeviceGroups)

  if (!orderDeviceGroupsClone) {
    return payload
  }

  payload = orderDeviceGroupsClone.sort(
    (firstEl: OrderDeviceGroup, secondEl: OrderDeviceGroup) => {
      if (
        firstEl!.displaySortOrder &&
        secondEl!.displaySortOrder &&
        firstEl.displaySortOrder > secondEl.displaySortOrder
      ) {
        return 1
      }

      if (
        firstEl!.displaySortOrder &&
        secondEl!.displaySortOrder &&
        firstEl.displaySortOrder < secondEl.displaySortOrder
      ) {
        return -1
      }

      return 0
    },
  )

  return payload
}
