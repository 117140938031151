import { gql } from '@apollo/client'

export const GET_INTERCOM_USER_VERIFICATION = gql`
  query getIntercomUserVerification {
    intercomUserVerification {
      hashId
      isAdmin
      user {
        id
        email
        firstName
        lastName
      }
    }
  }
`
