import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

export enum ContentTypeEnum {
  employees = 'employees',
  phone_plans = 'phone_plans',
  rentals = 'rentals',
  stocklist_items = 'stocklist_items',
  invoices = 'invoices',
  caseitems = 'caseitems',
  depots = 'depots',
  return_cases = 'return_cases',
}

export interface PortalContentModelItem {
  icon: IconDefinition
  translationId: string
  path: string
  modelType: `${ContentTypeEnum}`
}

export type PortalContentModel = {
  [key: string]: PortalContentModelItem
}
