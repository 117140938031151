import { store } from 'Stores/index'

import { CustomRouterActions } from './Router.types'

const onRouteChange = (prevPath: string, nextPath: string) => {
  const { portalContentStore } = store
  const { algoliaUIState, setAlgoliaUIState } = portalContentStore
  const indexKeys = Object.keys(algoliaUIState)
  const indexName = indexKeys[0] ?? ''

  if (nextPath.includes(indexName)) {
    return
  }

  setAlgoliaUIState({})
}

export const customRouterActions: CustomRouterActions = {
  onRouteChange: [onRouteChange],
}
