import { useEffect, useRef } from 'react'

import { ObserveMeOnceIAmMountedProps } from './ObserveMeOnceIAmMounted.types'

export const ObserveMeOnceIAmMounted = (
  props: ObserveMeOnceIAmMountedProps,
) => {
  const { intersectionObserver } = props
  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!divRef.current) return undefined

    intersectionObserver.observe(divRef.current)

    return () => {
      intersectionObserver.disconnect()
    }
  }, [intersectionObserver])

  return <div ref={divRef} />
}
