import { ReactElement } from 'react'

import {
  CaseItemStatus,
  ReturnCaseStatus,
  ReturnCaseSubstatus,
} from 'Portal/__generated__/globalTypes'

export type PreviewColumnProps = {
  id: string
  url?: string
  type: PreviewColumnTypeEnum
  headerElement?: ReactElement
  contentElement?: ReactElement
  isLoading?: boolean
  onClosePreviewColumn: () => void
  disableFooterAskQuestion?: boolean
  renderCustomFooterButton?: ReactElement
  isVisible: boolean
}

export type PreviewColumnHeaderProps = {
  name: string
  title?: string
  badge?: ReactElement | null
  image?: string | null
  isReturnBlocked?: boolean
  productType?: string | null
  caseItemStatus?: CaseItemStatus
  returnCaseStatus?: ReturnCaseStatus
  returnCaseSubstatus?: ReturnCaseSubstatus | null
  onClosePreviewColumn: () => void
  renderPreviewColumnHeaderSub?: ReactElement
}

export type PreviewColumnContentProps = {
  content: PreviewColumnCellProps[]
  twoColumn?: boolean
  children?: React.ReactNode
}

export type PreviewColumnCellProps = {
  label: string
  value?: string | React.ReactElement | null
  copyable?: boolean
}

export type PreviewColumnFooterProps = {
  id: string
  url?: string
  type: PreviewColumnTypeEnum
  onClosePreviewColumn: () => void
  renderCustomFooterButton?: ReactElement
  disableAskQuestion?: boolean
}

export enum PreviewColumnTypeEnum {
  Rental = 'rental',
  Employee = 'employee',
  PhonePlan = 'phone plan',
  Request = 'request',
  Bundle = 'bundle',
  Return = 'return',
}

export const PREVIEW_COLUMN_TYPE_TRANSLATION_IDS: Record<
  PreviewColumnTypeEnum,
  string
> = {
  [PreviewColumnTypeEnum.Rental]: 'PreviewColumn_type_rental',
  [PreviewColumnTypeEnum.Employee]: 'PreviewColumn_type_employee',
  [PreviewColumnTypeEnum.PhonePlan]: 'PreviewColumn_type_phone_plan',
  [PreviewColumnTypeEnum.Request]: 'PreviewColumn_type_request',
  [PreviewColumnTypeEnum.Bundle]: 'PreviewColumn_type_bundle',
  [PreviewColumnTypeEnum.Return]: 'PreviewColumn_type_return',
}
