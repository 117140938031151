import { ApolloError } from '@apollo/client'

import { ModalProviderContextType } from 'Portal/src/providers/ModalProvider/ModalProvider.types'

import { OrderSummaryErrorModal } from '../components/OrderSummary/OrderSummaryErrorModal'

export const isAddressFieldsErrorDetected = (message: string) =>
  message.includes('field') && message.includes('has incorrect char')

export const isExternalIdErrorDetected = (message: string) =>
  (message.includes('External ID') && message.includes('is not valid')) ||
  message.includes('external_case_id_per_org')

export const showErrorModal = (
  onConfirm: () => void,
  setModal: ModalProviderContextType['setModal'],
  error: ApolloError,
  hideModalAction?: () => void,
) => {
  const isAddressError = isAddressFieldsErrorDetected(error.message)
  const isExternalIdError = isExternalIdErrorDetected(error.message)

  if (isAddressError) {
    setModal({
      component: OrderSummaryErrorModal,
      isOpen: true,
      props: {
        customButtonText: 'Close',
        customHeader: 'NewOrderSummary_error_header_address_error',
        customSubheader: 'NewOrderSummary_error_subheader_address_error',
        onConfirm: hideModalAction,
      },
    })
  } else if (isExternalIdError) {
    setModal({
      component: OrderSummaryErrorModal,
      isOpen: true,
      props: {
        customButtonText: 'Close',
        customHeader: undefined,
        customSubheader: 'NewOrderSummary_external_id_error',
        onConfirm: hideModalAction,
      },
    })
  } else {
    setModal({
      component: OrderSummaryErrorModal,
      isOpen: true,
      props: {
        customButtonText: undefined,
        customHeader: undefined,
        customSubheader: undefined,
        onConfirm,
      },
    })
  }
}
