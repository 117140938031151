import { useCallback, useMemo, useState } from 'react'
import { useCurrentRefinements, useHits } from 'react-instantsearch'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Hit as AlgoliaHit } from '@algolia/client-search'
import { useMediaQuery } from '@react-hookz/web'
import { CellContext, ColumnDef, SortingState } from '@tanstack/react-table'
import { clsx } from 'clsx'
import { observer } from 'mobx-react'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { Table } from 'Components/_theme'

import { buttonize, getFullContactName } from 'Utilities'

import { PORTAL_BREAKPOINTS } from 'Constants/constants'
import { E2E_TABLE_LINK_EMPLOYEES } from 'Constants/e2e'

import { SearchResultsDocument } from 'Types/globalSearch/searchResultsDocument.types'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import { EmployeesTableProps, EmployeeWithUrl } from './EmployeesTable.types'

export const EmployeesTable = observer((props: EmployeesTableProps) => {
  const { isLoading, selectedRowIndex, onOpenPreviewColumn } = props
  const [sorting, setSorting] = useState<SortingState>([])
  const { hits } = useHits<AlgoliaHit<SearchResultsDocument>>()
  const intl = useIntl()

  const { organisationId } = useParams<{
    organisationId: string
  }>()

  const { items } = useCurrentRefinements()

  const routerHistory = useHistory()
  const isMobile = useMediaQuery(`(max-width: ${PORTAL_BREAKPOINTS.LG})`)

  const handleEnterDetails = useCallback(
    (
      _event:
        | React.MouseEvent<HTMLDivElement>
        | React.KeyboardEvent<HTMLDivElement>,
      id: string,
      url: string,
      rowIndex: number,
    ) => {
      if (isMobile) {
        routerHistory.push(url)

        return
      }

      if (typeof onOpenPreviewColumn === 'function') {
        onOpenPreviewColumn(id, rowIndex)
      }
    },
    [isMobile, onOpenPreviewColumn, routerHistory],
  )

  const renderEmployeeHeader = useCallback(
    () => (
      <FormattedMessage key="namme" id="EmployeeListContent_header_employee" />
    ),
    [],
  )

  const renderDeviceHeader = useCallback(
    () => (
      <FormattedMessage key="name" id="EmployeeListContent_header_device" />
    ),
    [],
  )

  const renderExpandableContent = useCallback(
    ({ cell }: CellContext<EmployeeWithUrl, unknown>) => {
      const employee = cell.row.original

      if (employee['employee.count_active_rentals'] === 0) {
        return (
          <div className="w-100 Table-details">
            <div className="Table-details-row">
              <span className="Table-row--expandable-title"> - </span>
            </div>
          </div>
        )
      }

      return (
        <div className="w-100 Table-details">
          <div className="Table-details-row">
            <span className="Table-row--expandable-title">
              {employee['employee.count_active_rentals']}
              &nbsp;
              <FormattedMessage id="EmployeeListContent_number_of_devices" />
            </span>
          </div>
        </div>
      )
    },
    [],
  )

  const renderEmployeeCell = useCallback(
    ({ cell }: CellContext<EmployeeWithUrl, unknown>) => {
      const employee = cell.row.original

      const contact = {
        firstName: employee['employee.first_name'] ?? '',
        lastName: employee['employee.last_name'] ?? '',
      }

      return (
        <div
          className="d-flex flex-column"
          {...buttonize(
            handleEnterDetails,
            employee.id,
            employee.url,
            cell.row.index,
          )}
        >
          <div className="d-flex align-items-center">
            <strong className="text-ellipsis">
              {employee['employee.first_name']
                ? getFullContactName(contact)
                : '-'}
            </strong>
          </div>

          {employee['employee.email'] && (
            <span className="small text-ellipsis">
              {employee['employee.email']}
            </span>
          )}

          {employee['employee.phone_number'] && (
            <span className="text-muted small text-ellipsis">
              {employee['employee.phone_number']}
            </span>
          )}
        </div>
      )
    },
    [handleEnterDetails],
  )

  const renderDeviceCell = useCallback(
    ({ cell }: CellContext<EmployeeWithUrl, unknown>) => {
      const employee = cell.row.original
      const url = composePath({
        params: {
          employeeId: employee.id,
          organisationId,
        },
        path: GLOBAL_ROUTE_PATHS.organisation.employee,
      })

      if (employee['employee.count_active_rentals'] === 0) {
        return (
          <div
            className="d-flex flex-column"
            {...buttonize(
              handleEnterDetails,
              employee.id,
              employee.url,
              cell.row.index,
            )}
          >
            <span> - </span>
          </div>
        )
      }

      if (
        employee['employee.count_active_rentals'] &&
        employee['employee.count_active_rentals'] > 0
      ) {
        return (
          <div className="d-flex">
            <div className="d-flex align-items-center">
              <Link
                className="d-flex align-items-center h-100 link-gray"
                title={intl.formatMessage({
                  id: 'EmployeeListContent_number_of_devices',
                })}
                to={url}
              >
                <span className="text-ellipsis">
                  {employee['employee.count_active_rentals']}
                  &nbsp;
                  <FormattedMessage id="EmployeeListContent_number_of_devices" />
                </span>
              </Link>
            </div>
            {/* Open previewColumn when click the rest cell space */}
            <div
              className="w-100"
              {...buttonize(
                handleEnterDetails,
                employee.id,
                employee.url,
                cell.row.index,
              )}
              aria-label="Open details"
            />
          </div>
        )
      }

      return null
    },
    [handleEnterDetails, organisationId, intl],
  )

  const columns = useMemo<ColumnDef<EmployeeWithUrl>[]>(
    () => [
      {
        accessorFn: (row: EmployeeWithUrl) => row['employee.full_name'],
        cell: renderEmployeeCell,
        enableSorting: true,
        header: renderEmployeeHeader,
        id: 'employee.full_name',
        meta: {
          ExpandableContent: renderExpandableContent,
        },
      },
      {
        accessorFn: (row: EmployeeWithUrl) =>
          row['employee.count_active_rentals'],
        cell: renderDeviceCell,
        header: renderDeviceHeader,
        id: 'employee.count_active_rentals',
      },
    ],
    [
      renderDeviceCell,
      renderDeviceHeader,
      renderEmployeeCell,
      renderEmployeeHeader,
      renderExpandableContent,
    ],
  )

  const data = useMemo<EmployeeWithUrl[]>(
    () =>
      hits.map(hit => {
        const url = composePath({
          params: {
            employeeId: hit['employee.id'],
            organisationId,
          },
          path: GLOBAL_ROUTE_PATHS.organisation.employee,
        })

        return {
          ...hit,
          url,
        }
      }),
    [hits, organisationId],
  )

  const hasFiltersApplied = items.length > 0
  const customNoResultMessage = hasFiltersApplied
    ? intl.formatMessage({
        id: 'Filter_no_results_content',
      })
    : undefined

  return (
    <Table
      className={clsx('Table--employees', 'Table--isAdmin')}
      columns={columns}
      customNoResultsMessage={customNoResultMessage}
      data={data}
      e2eRowId={E2E_TABLE_LINK_EMPLOYEES}
      isLoading={isLoading}
      onSortingChange={setSorting}
      selectedRowIndex={selectedRowIndex}
      sorting={sorting}
    />
  )
})
