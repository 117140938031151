import { faBuildings, faUser } from '@fortawesome/pro-solid-svg-icons'

import { getFullContactName } from 'Utilities'

import { getEmployee_employee } from 'Constants/graphql/queries/employee/__generated__/getEmployee'
import { getAllowedAcls } from 'Constants/graphql/queries/listing/__generated__/getAllowedAcls'
import { getOrganisation_organisation } from 'Constants/graphql/queries/organisation/__generated__/getOrganisation'

export const generateUserDetails = (
  isOrganisation: boolean,
  activeOrganisation: getOrganisation_organisation | null,
  activeEmployee: getEmployee_employee | null,
) => {
  if (isOrganisation) {
    return {
      avatar: activeOrganisation?.logoImageURL || null,
      userName: activeOrganisation?.fullName || '',
      userTitle: 'Admin',
    }
  }

  return {
    avatar: activeEmployee?.organisation?.logoImageURL || '',
    userName: activeEmployee?.contact
      ? getFullContactName(activeEmployee.contact)
      : '',
    userTitle: 'Employee',
  }
}

export const generateAcls = (
  allowedAcls: getAllowedAcls | null,
  isNewJoiner: boolean,
) => ({
  employees: allowedAcls?.allowedEmployees.employees
    ? allowedAcls?.allowedEmployees.employees?.map(employee => ({
        icon: faUser,
        id: employee.id,
        img: employee.organisation?.logoImageURL || null,
        label: getFullContactName(employee.contact),
        redirectTo: `/employee/${employee.id}`,
      }))
    : [],
  organisations:
    !isNewJoiner && allowedAcls?.organisations.organisations
      ? allowedAcls?.organisations.organisations?.map(organisation => ({
          icon: faBuildings,
          id: organisation.id,
          img: organisation.logoImageURL || null,
          label: organisation.fullName || '',
          redirectTo: `/organisation/${organisation.id}`,
        }))
      : [],
})
