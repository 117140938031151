import { EMPTY_SELECT_OPTION } from 'Constants/form'

import { AssignDeviceModalFormSchema } from './AssignDeviceModal.types'

export const assignDeviceFormDefaultValues: AssignDeviceModalFormSchema = {
  depot: EMPTY_SELECT_OPTION,
  depotDevice: EMPTY_SELECT_OPTION,
  employee: '',
  message: '',
}
