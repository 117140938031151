import { useCallback, useMemo, useState } from 'react'
import { useCurrentRefinements, useHits } from 'react-instantsearch'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { useMediaQuery } from '@react-hookz/web'
import { CellContext, ColumnDef, SortingState } from '@tanstack/react-table'
import { clsx } from 'clsx'
import { AlgoliaHit } from 'instantsearch.js'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { Table } from 'Components/_theme'
import { FormatDate } from 'Components/FormatDate/FormatDate'
import { ImageWithFallback } from 'Components/ImageWithFallback/ImageWithFallback'

// import { RentalStatusBadge } from 'Components/RentalStatusBadge/RentalStatusBadge'
// import { RentalStatus } from 'Components/RentalStatusBadge/RentalStatusBadge.types'
import { useIsOrganisation } from 'Hooks'

import { buttonize, getFullContactName } from 'Utilities'

import { PORTAL_BREAKPOINTS } from 'Constants/constants'
import { E2E_TABLE_LINK_RENTALS } from 'Constants/e2e'

import { DeviceTypeEnum } from 'Types/deviceTypes'
import { SearchResultsDocument } from 'Types/globalSearch/searchResultsDocument.types'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import { RentalsTableProps, RentalWithUrl } from './RentalsTable.types'

export const RentalsTable = observer((props: RentalsTableProps) => {
  const { isLoading, selectedRowIndex, onOpenPreviewColumn } = props
  const [sorting, setSorting] = useState<SortingState>([])
  const { hits } = useHits<AlgoliaHit<SearchResultsDocument>>()
  const intl = useIntl()

  const isOrganisation = useIsOrganisation()
  const { employeeId, organisationId } = useParams<{
    employeeId: string
    organisationId: string
  }>()

  const { items } = useCurrentRefinements()

  const routerHistory = useHistory()
  const isMobile = useMediaQuery(`(max-width: ${PORTAL_BREAKPOINTS.LG})`)

  const handleEnterDetails = useCallback(
    (
      _event:
        | React.MouseEvent<HTMLDivElement>
        | React.KeyboardEvent<HTMLDivElement>,
      id: string,
      url: string,
      rowIndex: number,
    ) => {
      if (isMobile) {
        routerHistory.push(url)

        return
      }

      if (typeof onOpenPreviewColumn === 'function') {
        onOpenPreviewColumn(id, rowIndex)
      }
    },
    [isMobile, onOpenPreviewColumn, routerHistory],
  )

  const renderNameHeader = useCallback(
    () => <FormattedMessage key="name" id="DeviceListContent_header__name" />,
    [],
  )

  const renderEmployeeHeader = useCallback(
    () => (
      <FormattedMessage key="name" id="DeviceListContent_header__employee" />
    ),
    [],
  )

  const renderStartDateHeader = useCallback(
    () => (
      <FormattedMessage key="name" id="DeviceListContent_header__start_date" />
    ),
    [],
  )

  const renderNameCell = useCallback(
    ({ cell }: CellContext<RentalWithUrl, unknown>) => {
      const rental = cell.row.original

      return (
        <div
          className="d-inline-flex"
          {...buttonize(
            handleEnterDetails,
            rental.id,
            rental.url,
            cell.row.index,
          )}
        >
          <div className="d-flex justify-content-center align-items-center me-4 Table-device-image">
            <ImageWithFallback
              alt={rental['rental.stocklist_item.name']}
              placeholderType={
                (rental[
                  'rental.stocklist_item.product_type'
                ] as DeviceTypeEnum) || DeviceTypeEnum.other
              }
              src={rental['rental.stocklist_item.image_url'] || ''}
            />
          </div>

          <div className="d-flex flex-column justify-content-center text-ellipsis">
            {/* {(rental.actions.showStandardUpgradeAction ||
              rental.actions.showDeviceProlongingAction) && (
              <div>
                <RentalStatusBadge status={RentalStatus.new_device} />
              </div>
            )}

            {rental.actions.showEarlyUpgradeAction && (
              <div className="mb-1">
                <RentalStatusBadge status={RentalStatus.upgrade} />
              </div>
            )} */}

            <div className="d-flex align-items-center">
              <strong className="text-ellipsis">
                <span>{rental['rental.stocklist_item.name']}</span>
              </strong>
            </div>

            <div className="d-flex table-text-subtitle text-muted">
              {rental['rental.stocklist_item.imei'] ? (
                <>
                  <span>IMEI:</span>

                  <span className="text-ellipsis">
                    {rental['rental.stocklist_item.imei']}
                  </span>
                </>
              ) : (
                <>
                  <span>SN:</span>

                  <span className="text-ellipsis">
                    {rental['rental.stocklist_item.serial_number'] || '-'}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      )
    },
    [handleEnterDetails],
  )

  const renderExpandableContent = useCallback(
    ({ cell }: CellContext<RentalWithUrl, unknown>) => {
      const rental = cell.row.original

      return (
        <div className="w-100 Table-details">
          {isOrganisation && (
            <div className="Table-details-row">
              <span className="Table-row--expandable-title">
                {renderEmployeeHeader()}
              </span>

              <span className="text-ellipsis">
                {getFullContactName({
                  firstName: rental['rental.employee.first_name'] ?? '',
                  lastName: rental['rental.employee.last_name'] ?? '',
                })}
              </span>
            </div>
          )}

          <div className="Table-details-row">
            <span className="Table-row--expandable-title">
              {renderStartDateHeader()}
            </span>

            {rental['rental.start_at'] && rental['rental.start_at'] > 0 && (
              <span>
                <FormatDate
                  value={DateTime.fromSeconds(
                    rental['rental.start_at'],
                  ).toJSDate()}
                />
              </span>
            )}
          </div>
        </div>
      )
    },
    [isOrganisation, renderEmployeeHeader, renderStartDateHeader],
  )

  const renderEmployeeCell = useCallback(
    ({ cell }: CellContext<RentalWithUrl, unknown>) => {
      const rental = cell.row.original

      return (
        <div
          className="d-flex align-items-center h-100"
          {...buttonize(
            handleEnterDetails,
            rental.id,
            rental.url,
            cell.row.index,
          )}
        >
          <span className="text-ellipsis">
            {rental['rental.employee.first_name']
              ? getFullContactName({
                  firstName: rental['rental.employee.first_name'] ?? '',
                  lastName: rental['rental.employee.last_name'] ?? '',
                })
              : '-'}
          </span>
        </div>
      )
    },
    [handleEnterDetails],
  )

  const renderStartDateCell = useCallback(
    ({ cell }: CellContext<RentalWithUrl, unknown>) => {
      const rental = cell.row.original

      return (
        <div
          className="d-flex align-items-center h-100"
          {...buttonize(
            handleEnterDetails,
            rental.id,
            rental.url,
            cell.row.index,
          )}
        >
          {rental['rental.start_at'] && rental['rental.start_at'] > 0 && (
            <FormatDate
              value={DateTime.fromSeconds(rental['rental.start_at']).toJSDate()}
            />
          )}
        </div>
      )
    },
    [handleEnterDetails],
  )

  const columns = useMemo<ColumnDef<RentalWithUrl>[]>(() => {
    const tableColumns: ColumnDef<RentalWithUrl>[] = [
      {
        cell: renderNameCell,
        header: renderNameHeader,
        id: 'name',
        meta: {
          ExpandableContent: renderExpandableContent,
        },
      },
      {
        accessorFn: (row: RentalWithUrl) => row['rental.employee.first_name'],
        cell: renderEmployeeCell,
        enableSorting: true,
        header: renderEmployeeHeader,
        id: 'rental.employee.first_name',
      },
      {
        accessorFn: (row: RentalWithUrl) => row['rental.start_at'],
        cell: renderStartDateCell,
        enableSorting: true,
        header: renderStartDateHeader,
        id: 'rental.start_at',
      },
    ]

    return tableColumns.filter(column => {
      // don't show employee when logged in as employee - they are the same
      if (!isOrganisation && column.id === 'employee') {
        return false
      }

      return true
    })
  }, [
    isOrganisation,
    renderEmployeeCell,
    renderEmployeeHeader,
    renderExpandableContent,
    renderNameCell,
    renderNameHeader,
    renderStartDateCell,
    renderStartDateHeader,
  ])

  const data = useMemo<RentalWithUrl[]>(() => {
    if (!hits.length) {
      return []
    }

    return hits.map(hit => {
      const url = composePath({
        isOrganisation,
        params: {
          employeeId,
          organisationId,
          rentalId: hit['rental.id'],
        },
        paths: {
          employee: GLOBAL_ROUTE_PATHS.employee.rental,
          organisation: GLOBAL_ROUTE_PATHS.organisation.rental,
        },
      })

      return {
        ...hit,
        url,
      }
    })
  }, [employeeId, hits, isOrganisation, organisationId])

  const hasFiltersApplied = items.length > 0
  const customNoResultMessage = hasFiltersApplied
    ? intl.formatMessage({
        id: 'Filter_no_results_content',
      })
    : undefined

  return (
    <Table
      className={clsx(
        'Table--rentals',
        isOrganisation ? 'Table--isAdmin' : 'Table--isEmployee',
      )}
      columns={columns}
      customNoResultsMessage={customNoResultMessage}
      data={data}
      e2eRowId={E2E_TABLE_LINK_RENTALS}
      isLoading={isLoading}
      onSortingChange={setSorting}
      selectedRowIndex={selectedRowIndex}
      sorting={sorting}
    />
  )
})
