import { useMemo } from 'react'
import { useCurrentRefinements } from 'react-instantsearch'
import { FormattedMessage } from 'react-intl'
import { faSliders } from '@fortawesome/pro-solid-svg-icons'
import { useMediaQuery } from '@react-hookz/web'
import { observer } from 'mobx-react'

import { PrimaryButton } from 'Components/_theme'
import { DownloadReportProvider } from 'Components/DownloadReport/DownloadReport'

import { useShowInstantsearchList } from 'Hooks'

import { PORTAL_BREAKPOINTS } from 'Constants/constants'

import { ActiveFilters } from 'Algolia/components/ActiveFilters/ActiveFilters'
import { ClearActiveFilters } from 'Algolia/components/ClearActiveFilters/ClearActiveFilters'
import { TotalCounter } from 'Algolia/components/TotalCounter/TotalCounter'

import { TableHeaderProps } from './TableHeader.types'

export const TableHeader = observer((props: TableHeaderProps) => {
  const {
    buttonLabel,
    buttonE2ESelector,
    downloadReportType,
    downloadReportFilter,
    downloadReportAlertAdditionalReadyMessage,
    buttonIsVisible: isButtonVisible,
    totalCount,
    filterButtonE2ESelector,
    filterButtonOnClick,
    buttonOnClick,
    buttonIsLoading,
    hideDownloadReportComponents,
    hideFilterButton,
  } = props

  const isMobile = useMediaQuery(`(max-width: ${PORTAL_BREAKPOINTS.LG})`)

  const { items: activeFilters } = useCurrentRefinements()

  const shouldHideDownloadReportComponents = useMemo(() => {
    if (hideDownloadReportComponents === undefined) {
      // If `hideDownloadReportComponents` is not defined, we're returning default logic
      return totalCount === 0 || activeFilters.length > 0
    }

    return hideDownloadReportComponents
  }, [activeFilters.length, hideDownloadReportComponents, totalCount])

  const showInstantSearchList =
    useShowInstantsearchList() && activeFilters.length > 0

  return (
    <DownloadReportProvider
      hideDownloadReportComponents={shouldHideDownloadReportComponents}
      reportFilter={downloadReportFilter}
      reportType={downloadReportType}
    >
      <div className="TableHeader">
        <div className="TableHeader-filters-container">
          {showInstantSearchList ? (
            <TotalCounter />
          ) : (
            <div className="TableHeader-results">
              <FormattedMessage id="Total" />: {totalCount}
            </div>
          )}

          {!isMobile && <ActiveFilters />}
        </div>

        <div className="TableHeader-button-container">
          {!isMobile && !hideFilterButton && (
            <PrimaryButton
              appearance="outline"
              data-e2e={filterButtonE2ESelector}
              iconLeft={faSliders}
              isLoading={buttonIsLoading}
              onClick={filterButtonOnClick}
            >
              <FormattedMessage id="Filter" />
            </PrimaryButton>
          )}

          {!isMobile && <DownloadReportProvider.Button />}

          {isButtonVisible && (
            <PrimaryButton data-e2e={buttonE2ESelector} onClick={buttonOnClick}>
              {buttonLabel}
            </PrimaryButton>
          )}
        </div>

        {isMobile && (
          <>
            {!hideFilterButton && (
              <div className="TableHeader-button-mobile-container">
                <PrimaryButton
                  appearance="outline"
                  data-e2e={filterButtonE2ESelector}
                  iconLeft={faSliders}
                  isLoading={buttonIsLoading}
                  onClick={filterButtonOnClick}
                >
                  <FormattedMessage id="Filter" />
                </PrimaryButton>

                <ClearActiveFilters className="ms-2" />
              </div>
            )}

            <div className="TableHeader-button-container">
              <DownloadReportProvider.Button />
            </div>
          </>
        )}
      </div>
      <DownloadReportProvider.Alert
        additionalReadyMessage={downloadReportAlertAdditionalReadyMessage}
      />
    </DownloadReportProvider>
  )
})
