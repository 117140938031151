import { gql } from '@apollo/client'

export const GET_PHONE_PLAN_DETAILS = gql`
  query getPhonePlanDetails($id: ID!, $organisationId: ID) {
    phoneplan(id: $id, organisationId: $organisationId) {
      id
      simcardNumber
      pin
      phoneNumber
      puk
      startAt
      endAt
      phonePlanTariff {
        name
        logo
      }
      rental {
        id
        employee {
          id
          contact {
            firstName
            lastName
            email
            phoneNumber
          }
        }
        stocklistItem {
          imei
          imei2
          serialNumber
          deviceDefinition {
            name
            imageURL
            productType
          }
        }
      }
    }
  }
`

export const GET_PHONEPLAN_LIST_PREVIEW_COLUMN = gql`
  query getPhonePlanListPreviewColumn($id: ID!) {
    phoneplan(id: $id) {
      id
      simcardNumber
      phoneNumber
      startAt
      phonePlanTariff {
        name
        logo
      }
    }
  }
`
