import { useCallback, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useMediaQuery } from '@react-hookz/web'
import { CellContext, ColumnDef } from '@tanstack/react-table'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { Table } from 'Components/_theme'

import { buttonize, getFullContactName } from 'Utilities'

import { PORTAL_BREAKPOINTS } from 'Constants/constants'
import { E2E_TABLE_LINK_EMPLOYEES } from 'Constants/e2e'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import { EmployeesTableProps, EmployeesWithUrl } from './EmployeesTable.types'

export const EmployeesTable = (props: EmployeesTableProps) => {
  const {
    employees,
    isLoading,
    isSearching,
    onOpenPreviewColumn,
    selectedRowIndex,
  } = props
  const routerHistory = useHistory()
  const isTablet = useMediaQuery(`(min-width: ${PORTAL_BREAKPOINTS.LG})`)
  const { organisationId } = useParams<{ organisationId: string }>()
  const intl = useIntl()

  const handleEnterDetails = useCallback(
    (
      _event:
        | React.MouseEvent<HTMLDivElement>
        | React.KeyboardEvent<HTMLDivElement>,
      id: string,
      url: string,
      rowIndex: number,
    ) => {
      if (!isTablet) {
        routerHistory.push(url)

        return
      }

      if (typeof onOpenPreviewColumn === 'function') {
        onOpenPreviewColumn(id, rowIndex)
      }
    },
    [isTablet, onOpenPreviewColumn, routerHistory],
  )

  const renderEmployeeHeader = useCallback(
    () => <FormattedMessage id="EmployeeListContent_header_employee" />,
    [],
  )

  const renderDeviceHeader = useCallback(
    () => <FormattedMessage id="EmployeeListContent_header_device" />,
    [],
  )

  const renderEmployeeCell = useCallback(
    ({ cell }: CellContext<EmployeesWithUrl, unknown>) => {
      const employee = cell.row.original

      return (
        <div
          className="d-flex flex-column"
          {...buttonize(
            handleEnterDetails,
            employee.id,
            employee.url,
            cell.row.index,
          )}
        >
          <div className="d-flex align-items-center">
            <strong className="text-ellipsis">
              {employee.contact?.firstName
                ? getFullContactName(employee.contact)
                : '-'}
            </strong>
          </div>

          {employee.contact?.email && (
            <span className="small text-ellipsis">
              {employee.contact.email}
            </span>
          )}

          {employee.contact?.phoneNumber && (
            <span className="text-muted small text-ellipsis">
              {employee.contact.phoneNumber}
            </span>
          )}
        </div>
      )
    },
    [handleEnterDetails],
  )

  const renderDeviceCell = useCallback(
    ({ cell }: CellContext<EmployeesWithUrl, unknown>) => {
      const employee = cell.row.original
      const url = composePath({
        params: {
          employeeId: employee.id,
          organisationId,
        },
        path: GLOBAL_ROUTE_PATHS.organisation.employee,
      })

      if (!employee.rentals?.rentals || employee.rentals?.totalCount === 0) {
        return (
          <div
            className="d-flex flex-column"
            {...buttonize(
              handleEnterDetails,
              employee.id,
              employee.url,
              cell.row.index,
            )}
          >
            <span> - </span>
          </div>
        )
      }

      if (employee.rentals?.totalCount === 1) {
        return (
          <div
            className="d-flex flex-column"
            {...buttonize(
              handleEnterDetails,
              employee.id,
              employee.url,
              cell.row.index,
            )}
          >
            <div className="d-flex align-items-center">
              <span className="text-ellipsis">
                {
                  employee.rentals.rentals[0].stocklistItem?.deviceDefinition
                    ?.name
                }
              </span>
            </div>
            <div className="d-flex text-muted small">
              <span className="me-1">IMEI:</span>
              :&nbsp;
              <span className="text-ellipsis">
                {employee.rentals.rentals[0].stocklistItem?.imei}
              </span>
            </div>
          </div>
        )
      }

      if (employee.rentals?.totalCount > 1) {
        return (
          <div className="d-flex">
            <div className="d-flex align-items-center">
              <Link
                className="d-flex align-items-center h-100 link-gray"
                title={intl.formatMessage({
                  id: 'EmployeeListContent_number_of_devices',
                })}
                to={url}
              >
                <span className="text-ellipsis">
                  {employee.rentals.totalCount}
                  &nbsp;
                  <FormattedMessage id="EmployeeListContent_number_of_devices" />
                </span>
              </Link>
            </div>
            {/* Open previewColumn when click the rest cell space */}
            <div
              className="w-100"
              {...buttonize(
                handleEnterDetails,
                employee.id,
                employee.url,
                cell.row.index,
              )}
              aria-label="Open details"
            />
          </div>
        )
      }

      return null
    },
    [handleEnterDetails, organisationId, intl],
  )

  const renderExpandableContent = useCallback(
    ({ cell }: CellContext<EmployeesWithUrl, unknown>) => {
      const employee = cell.row.original
      const smartphones = employee.rentals?.rentals?.filter(
        rental =>
          rental.stocklistItem?.deviceDefinition?.productType === 'smartphone',
      )

      if (smartphones?.length) {
        return (
          <div className="w-100 Table-details">
            <div className="Table-details-row">
              <span className="Table-row--expandable-title">
                {renderDeviceHeader()}
              </span>

              <div>
                <div className="d-flex align-items-center">
                  <span className="text-ellipsis">
                    {
                      smartphones[smartphones.length - 1].stocklistItem
                        ?.deviceDefinition?.name
                    }
                  </span>
                </div>

                {smartphones[smartphones.length - 1].stocklistItem?.imei && (
                  <div className="d-flex text-muted small">
                    <span className="me-1">IMEI:</span>
                    :&nbsp;
                    <span className="text-ellipsis">
                      {smartphones[smartphones.length - 1].stocklistItem?.imei}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      }

      if (employee.rentals?.rentals?.length) {
        return (
          <div className="w-100 Table-details">
            <div className="Table-details-row">
              <span className="Table-row--expandable-title">
                {renderDeviceHeader()}
              </span>

              <div>
                <div className="d-flex align-items-center">
                  <span className="text-ellipsis">
                    {
                      employee.rentals.rentals[
                        employee.rentals.rentals.length - 1
                      ].stocklistItem?.deviceDefinition?.name
                    }
                  </span>
                </div>

                {employee.rentals.rentals[employee.rentals.rentals.length - 1]
                  .stocklistItem?.imei && (
                  <div className="text-muted small">
                    <span className="me-1">IMEI:</span>
                    :&nbsp;
                    <span className="text-ellipsis">
                      {
                        employee.rentals.rentals[
                          employee.rentals.rentals.length - 1
                        ].stocklistItem?.imei
                      }
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      }

      return (
        <div className="w-100 Table-details">
          <div className="Table-details-row">
            <span className="Table-row--expandable-title">
              {renderDeviceHeader()}
            </span>

            <span> - </span>
          </div>
        </div>
      )
    },
    [renderDeviceHeader],
  )

  const columns = useMemo<ColumnDef<EmployeesWithUrl>[]>(
    () => [
      {
        cell: renderEmployeeCell,
        header: renderEmployeeHeader,
        id: 'employee',
        meta: {
          ExpandableContent: renderExpandableContent,
        },
      },
      {
        cell: renderDeviceCell,
        header: renderDeviceHeader,
        id: 'device',
      },
    ],
    [
      renderDeviceCell,
      renderDeviceHeader,
      renderEmployeeCell,
      renderEmployeeHeader,
      renderExpandableContent,
    ],
  )

  const data = useMemo<EmployeesWithUrl[]>(
    () =>
      employees.map(employee => {
        const url = composePath({
          params: {
            employeeId: employee.id,
            organisationId,
          },
          path: GLOBAL_ROUTE_PATHS.organisation.employee,
        })

        return {
          ...employee,
          url,
        }
      }),
    [employees, organisationId],
  )

  return (
    <Table
      className="Table--employees"
      columns={columns}
      data={data}
      e2eRowId={E2E_TABLE_LINK_EMPLOYEES}
      isLoading={isLoading}
      isSearching={isSearching}
      selectedRowIndex={selectedRowIndex}
    />
  )
}
