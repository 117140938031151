import { usePagination } from 'react-instantsearch'
import { useIntl } from 'react-intl'
import { observer } from 'mobx-react'

import { DebouncedInput } from 'Components/DebouncedInput/DebouncedInput'

import { useStore } from 'Stores/index'

import { GoToPageProps } from './GoToPage.types'

// breaks the test when value is > 0
// not sure why
const VALUE = process.env.NODE_ENV === 'test' ? 0 : 1

export const GoToPage = observer((props: GoToPageProps) => {
  const { totalCount } = props
  const { portalContentStore } = useStore()
  const { paginationState } = portalContentStore
  const intl = useIntl()

  const { refine } = usePagination()

  const totalPages = Math.ceil(totalCount / paginationState.pageSize)

  const handleInputChange = (value: string | readonly string[] | number) => {
    // pagination is 0-based, so we need to subtract 1
    refine(Number(value) - 1)
  }

  const afterLabel = intl.formatMessage({ id: 'GoToPage_after' })

  return (
    <div className="GoToPage">
      <DebouncedInput
        aria-label={intl.formatMessage({ id: 'GoToPage_pageInput' })}
        autoComplete="off"
        contentAfter={<span>{afterLabel}</span>}
        max={totalPages}
        min={1}
        onChange={handleInputChange}
        type="number"
        value={VALUE}
      />
    </div>
  )
})
