import { Controller, useFormContext } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import { clsx } from 'clsx'

import { formatPhoneNumber } from 'Portal/src/utilities/form/form.util'

import { FieldPhoneInputProps } from './Field.types'

export const FieldPhoneInput = (props: FieldPhoneInputProps) => {
  const { name, phoneInputProps, hasError, e2eSelector } = props
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, onChange, ...field } }) => (
        <PhoneInput
          {...field}
          {...phoneInputProps}
          containerClass={clsx(
            hasError && 'is-invalid',
            phoneInputProps?.containerClass,
          )}
          inputProps={{
            ref,
            ...phoneInputProps?.inputProps,
            'data-e2e': e2eSelector,
            id: name,
          }}
          onChange={value => {
            onChange(formatPhoneNumber(value))
          }}
        />
      )}
    />
  )
}
