import { useMemo } from 'react'

import { isReturnBlocked } from 'Modules/returns/utils/returns.utils'

import { CardDetailsListItem } from 'Components/CardDetailsList/CardDetailsList.types'
import { FormatDate } from 'Components/FormatDate/FormatDate'
import { PresentationCard } from 'Components/PresentationCard/PresentationCard'
import { ReturnBlockedBadge } from 'Components/ReturnBlockedBadge/ReturnBlockedBadge'
import { ReturnStatusBadge } from 'Components/ReturnStatusBadge/ReturnStatusBadge'

import { DeviceTypeEnum } from 'Types/deviceTypes'

import { ReturnPresentationCardProps } from './ReturnPresentationCard.types'

export const ReturnPresentationCard = (props: ReturnPresentationCardProps) => {
  const { returnCase } = props

  const generatePresentationCardListItems = useMemo(
    (): CardDetailsListItem[] => [
      {
        label: 'ReturnDetails_serial_number',
        value: returnCase.stocklistItem?.serialNumber,
      },
      {
        label: 'ReturnDetails_imei',
        value: returnCase.stocklistItem?.imei,
      },
      {
        label: 'ReturnDetails_imei2',
        value: returnCase.stocklistItem?.imei2,
      },
      {
        label: 'ReturnDetails_start_of_rental',
        value: <FormatDate value={returnCase.rental?.startAt} />,
      },
    ],
    [returnCase],
  )

  const renderRightContent = useMemo(() => {
    if (isReturnBlocked(returnCase)) {
      return (
        <ReturnBlockedBadge className="me-2" substatus={returnCase.substatus} />
      )
    }

    return <ReturnStatusBadge status={returnCase.status} />
  }, [returnCase])

  if (!returnCase.stocklistItem) {
    return null
  }

  return (
    <PresentationCard
      header={returnCase.stocklistItem.deviceDefinition?.name || ''}
      imagePlaceholderType={
        returnCase.stocklistItem.deviceDefinition?.productType as DeviceTypeEnum
      }
      imageSource={returnCase.stocklistItem.deviceDefinition?.imageURL}
      listItems={generatePresentationCardListItems}
      rightContent={renderRightContent}
      title="ReturnDetails_device"
    />
  )
}
