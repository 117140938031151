import { useParams } from 'react-router-dom'

import { useStore } from 'Stores/index'

/**
 * This hook returns true if current route has an organisation id
 */
export function useIsOrganisation() {
  const { organisationId } = useParams<{ organisationId: string }>()
  const { organisationStore } = useStore()
  const { organisation } = organisationStore

  return !!organisationId || !!organisation?.id
}
