import { useStore } from 'Stores/index'

/**
 * @description This hook gives you the JSON web token that our portal is using for all authentication,
 * may be useful when making API requests etc. Please wrap component with the `observer` method from
 * 'react-mobx' for the hook to re-sync data properly.
 */
export function useUserAuthToken() {
  const { userStore } = useStore()
  const { user } = userStore

  return user.jwt ? `Bearer ${user.jwt}` : ''
}
