import { gql } from '@apollo/client'

export const GET_ORGANISATION_EMPLOYEES = gql`
  query getOrganisationEmployees(
    $id: ID!
    $first: Int!
    $after: Int!
    $searchterm: String
    $activeDate: Time
  ) {
    organisation(id: $id) {
      id
      employees(
        pagination: { after: $after, first: $first }
        searchterm: $searchterm
        activeDate: $activeDate
      ) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        employees {
          id
          contact {
            id
            firstName
            lastName
            email
            phoneNumber
          }
          rentals {
            totalCount
            rentals {
              stocklistItem {
                imei
                deviceDefinition {
                  name
                  productType
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_ALLOWED_EMPLOYEES = gql`
  query getAllowedEmployees {
    allowedEmployees {
      employees {
        contact {
          firstName
          id
          lastName
        }
        id
      }
      totalCount
    }
  }
`
