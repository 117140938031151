import { AppEnvEnum } from 'Types/env.types'

export const openIntercomChat = (initialMessage: unknown): void => {
  const message =
    typeof initialMessage === 'string' ? initialMessage : undefined

  if (window.APP_ENV === AppEnvEnum.DEV) {
    // eslint-disable-next-line no-alert
    alert(
      `Opens Intercom chat.\nDisabled in DEV environment.\nInitial message: ${message}`,
    )
  } else {
    window.Intercom('update')
    window.Intercom('showNewMessage', message)
  }
}
