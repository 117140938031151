import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { Checkbox } from 'Components/_theme'

import { FieldCheckboxProps } from './Field.types'

export const FieldCheckbox = (props: FieldCheckboxProps) => {
  const {
    inputClassName,
    label,
    name,
    hasError,
    markAsRequired,
    shouldTranslateLabel,
    e2eSelector,
    ...rest
  } = props
  const { register } = useFormContext()
  const intl = useIntl()

  const translatedLabel =
    shouldTranslateLabel && label
      ? intl.formatMessage({
          id: label,
        })
      : label ?? ''

  const formattedLabel = `${translatedLabel} ${
    markAsRequired ? '*' : ''
  }`.trim()

  return (
    <Checkbox
      {...register(name)}
      {...rest}
      className={inputClassName}
      data-e2e={e2eSelector}
      id={name}
      isInvalid={hasError}
      label={formattedLabel}
    />
  )
}
