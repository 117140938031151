import { useMemo } from 'react'

import { FooterMenuItemElement } from 'Components/FooterMenuItem/FooterMenuItem.types'

import { SupportedLanguagesEnum } from 'Constants/locale'

import { GLOBAL_ROUTE_PATHS } from 'Portal/src/router/Router.constants'
import { isActiveLanguage } from 'Portal/src/utilities'

export const useFooterMenuItems = () => {
  const isGermanLanguageActive = isActiveLanguage(SupportedLanguagesEnum.DE)

  const footerMenuItems: FooterMenuItemElement[] = useMemo(
    () => [
      {
        id: 'sitemap',
        nameId: 'Sitemap',
        to: GLOBAL_ROUTE_PATHS.public.sitemap,
      },
      {
        id: 'dataPrivacy',
        nameId: 'DataPrivacy_footer_title',
        to: GLOBAL_ROUTE_PATHS.public.dataPrivacy,
      },
      {
        asExternalLink: true,
        id: 'imprint',
        nameId: 'Imprint',
        to: isGermanLanguageActive
          ? 'https://www.everphone.com/de/impressum/'
          : 'https://www.everphone.com/imprint/',
      },
      {
        asExternalLink: true,
        id: 'security',
        nameId: 'Security',
        to: 'https://security.everphone.app/',
      },
    ],
    [isGermanLanguageActive],
  )

  return { footerMenuItems }
}
