import { FormProvider, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { zodResolver } from '@hookform/resolvers/zod'

import { ButtonGroup } from 'Components/_theme'
import { Field } from 'Components/Field/Field'
import { FieldTypeEnum } from 'Components/Field/Field.types'
import { Form } from 'Components/Form/Form'
import { ModalDevicePresentationBlock } from 'Components/ModalDevicePresentationBlock/ModalDevicePresentationBlock'

import { requestPurchaseModalLandingSchema } from '../RequestPurchaseModal.schema'
import {
  RequestPurchaseModalLandingProps,
  RequestPurchaseModalLandingSchema,
  RequestPurchaseModalStepsEnum,
} from '../RequestPurchaseModal.types'

export const RequestPurchaseModalLanding = (
  props: RequestPurchaseModalLandingProps,
) => {
  const { rental, onSetCurrentStep, onHide } = props

  const intl = useIntl()

  const formMethods = useForm<RequestPurchaseModalLandingSchema>({
    defaultValues: {
      hasIssues: false,
    },
    resolver: zodResolver(requestPurchaseModalLandingSchema),
  })

  const handleSubmit = formMethods.handleSubmit(data => {
    const { hasIssues } = data

    if (hasIssues) {
      onSetCurrentStep(RequestPurchaseModalStepsEnum.INVALID)
    } else {
      onSetCurrentStep(RequestPurchaseModalStepsEnum.SUMMARY)
    }
  })

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit}>
        <p>
          <FormattedMessage id="RequestPurchaseModal_landingTitle" />
        </p>
        <p>
          <FormattedMessage id="RequestPurchaseModal_landingDescription" />
        </p>

        <ModalDevicePresentationBlock rental={rental} small>
          <div className="text-ellipsis">
            <span>IMEI: </span>

            <span title={rental.stocklistItem?.imei ?? ''}>
              {rental.stocklistItem?.imei}
            </span>
          </div>

          <div className="text-ellipsis">
            <span>SN: </span>

            <span title={rental.stocklistItem?.serialNumber ?? ''}>
              {rental.stocklistItem?.serialNumber ?? '-'}
            </span>
          </div>
        </ModalDevicePresentationBlock>

        <Field
          containerClassName="mt-9"
          label="RequestPurchaseModal_landingCheckbox"
          name="hasIssues"
          type={FieldTypeEnum.checkbox}
        />

        <ButtonGroup
          leftButton={{
            onClick: onHide,
            text: intl.formatMessage({ id: 'Back' }),
          }}
          rightButton={{
            text: intl.formatMessage({ id: 'Next' }),
            type: 'submit',
          }}
        />
      </Form>
    </FormProvider>
  )
}
