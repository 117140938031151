import { DateTime } from 'luxon'
import { z } from 'zod'

export const employeeOffboardingSchema = z.object({
  exitDate: z.string().refine(date => {
    const selectedDate = DateTime.fromJSDate(new Date(date))
    const currentDate = DateTime.local()

    if (!date) {
      return true
    }

    return selectedDate.startOf('day') >= currentDate.startOf('day')
  }, 'EmployeeOffboarding_date_in_past'),
})
