import { gql } from '@apollo/client'

export const GET_EMPLOYEE_BY_NAME = gql`
  query getEmployeeByName($orgId: ID!, $searchTerm: String) {
    organisation(id: $orgId) {
      employees(searchterm: $searchTerm) {
        employees {
          id
          contact {
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`
