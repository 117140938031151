import { useEffect, useState } from 'react'

export const useSidebarVisibility = (
  isSectionOpen: boolean,
  isSidebarOpen?: boolean,
) => {
  const [isVisible, setIsVisible] = useState(isSectionOpen)
  const [isClosing, setIsClosing] = useState(false)

  useEffect(() => {
    const timeout = isSidebarOpen ? 0 : 250

    if (isSectionOpen) {
      setIsVisible(true)
      setIsClosing(false)
    } else {
      setIsClosing(true)

      const timer = setTimeout(() => {
        setIsVisible(false)
      }, timeout)

      return () => clearTimeout(timer)
    }

    return () => {}
  }, [isSectionOpen, isSidebarOpen])

  return { isClosing, isVisible }
}
