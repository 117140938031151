import {
  ParsedSearchResultDocument,
  ParsedSearchResults,
} from 'Modules/searchResults/types/searchResults.types'

import { dashOrSpaceToUnderscore } from 'Utilities'

import { CASE_ITEMS_TO_SHOW } from 'Constants/caseItemType'
import { PORTAL_CONTENT_MODEL } from 'Constants/portalContentModel'

import { ContentTypeEnum } from 'Types/content/content.types'
import {
  SearchResultHighlight,
  SearchResults,
} from 'Types/globalSearch/searchResults.types'
import { SearchResultsDocument } from 'Types/globalSearch/searchResultsDocument.types'

import {
  CaseItemStatus,
  CaseItemType,
  ReturnCaseStatus,
} from 'Portal/__generated__/globalTypes'

export const getSearchResultValueFromHighlight = (
  key: keyof ParsedSearchResultDocument,
  document: ParsedSearchResultDocument,
  highlights: SearchResultHighlight[],
) => {
  const highlight = highlights.find(highlight => highlight.field === key)

  if (!highlight?.snippet) {
    return document[key] ?? ''
  }

  return highlight.snippet
}

const getModelUrl = (
  model: ContentTypeEnum,
  document: SearchResultsDocument,
) => {
  switch (model) {
    case ContentTypeEnum.rentals:
      return `/${PORTAL_CONTENT_MODEL[model].path}/${document['rental.id']}`
    case ContentTypeEnum.employees:
      return `/${PORTAL_CONTENT_MODEL[model].path}/${document['employee.id']}`
    case ContentTypeEnum.phone_plans:
      return `/${PORTAL_CONTENT_MODEL[model].path}/${document['phone_plan.id']}`
    case ContentTypeEnum.caseitems:
      return `/${PORTAL_CONTENT_MODEL[model].path}/${document['case_item.id']}`
    case ContentTypeEnum.return_cases:
      return `/${PORTAL_CONTENT_MODEL[model].path}/${document['return_case.id']}`
    default:
      return ''
  }
}

const filterAllowedCaseItems = (searchResults: ParsedSearchResults[]) =>
  searchResults.filter(result => {
    if (
      !result.document['case_item.type'] ||
      CASE_ITEMS_TO_SHOW.includes(result.document['case_item.type'])
    ) {
      return true
    }

    return false
  })

export const parseSearchResults = (
  searchResults: SearchResults,
  basePageUrl: string,
) => {
  const results: ParsedSearchResults[] = []

  searchResults.result.forEach(result => {
    if (!result.hits) {
      return
    }

    result.hits.forEach(hit => {
      if (!hit.document) {
        return
      }

      results.push({
        id: `${result.model}-${hit.document?.id}`,
        model: result.model,
        url: `${basePageUrl}${getModelUrl(result.model, hit.document)}`,
        ...hit,
        // TODO: remove when backend fixes the types
        document: {
          ...hit.document,
          'case_item.status': hit?.document?.['case_item.status']
            ? dashOrSpaceToUnderscore(
                hit.document['case_item.status'] as keyof typeof CaseItemStatus,
              )
            : undefined,
          'case_item.type': hit?.document?.['case_item.type']
            ? dashOrSpaceToUnderscore(
                hit.document['case_item.type'] as keyof typeof CaseItemType,
              )
            : undefined,
          'return_case.status': hit?.document?.['return_case.status']
            ? dashOrSpaceToUnderscore(
                hit.document[
                  'return_case.status'
                ] as keyof typeof ReturnCaseStatus,
              )
            : undefined,
        },
      })
    })
  })

  // TODO: when backend fixes the types move this fn to the fetch then method
  return filterAllowedCaseItems(results)
}

const MODEL_BLACKLIST = [
  ContentTypeEnum.stocklist_items,
  ContentTypeEnum.invoices,
]

export const filterSearchResultsByModel = (searchResults: SearchResults) => {
  const result: SearchResults = {
    result: searchResults.result.filter(
      result => !MODEL_BLACKLIST.includes(result.model),
    ),
  }

  return result
}
