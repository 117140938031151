import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { isReturnBlocked } from 'Modules/returns/utils/returns.utils'

import { PreviewColumn } from 'Components/PreviewColumn/PreviewColumn'
import { PreviewColumnTypeEnum } from 'Components/PreviewColumn/PreviewColumn.types'
import { PreviewColumnContent } from 'Components/PreviewColumn/PreviewColumnContent'
import { PreviewColumnHeader } from 'Components/PreviewColumn/PreviewColumnHeader'

import { useIsOrganisation } from 'Hooks'

import {
  getReturnCasePreviewColumn,
  getReturnCasePreviewColumnVariables,
} from 'Constants/graphql/queries/detail/__generated__/getReturnCasePreviewColumn'
import { GET_RETURN_CASE_PREVIEW_COLUMN } from 'Constants/graphql/queries/detail/GetReturnCase'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import { ReturnsPreviewColumnProps } from './ReturnsPreviewColumn.types'
import { generateReturnsPreviewColumnContent } from './ReturnsPreviewColumn.utils'

export const ReturnsPreviewColumn = (props: ReturnsPreviewColumnProps) => {
  const { entityId, onClosePreviewColumn, isVisible } = props
  const isOrganisation = useIsOrganisation()
  const { employeeId, organisationId } = useParams<{
    employeeId?: string
    organisationId?: string
  }>()

  const path = composePath({
    isOrganisation,
    params: {
      employeeId,
      organisationId,
      returnCaseId: entityId || '0',
    },
    paths: {
      employee: GLOBAL_ROUTE_PATHS.employee.return,
      organisation: GLOBAL_ROUTE_PATHS.organisation.return,
    },
  })

  const [getReturnsPreviewData, { loading: isLoading, data }] = useLazyQuery<
    getReturnCasePreviewColumn,
    getReturnCasePreviewColumnVariables
  >(GET_RETURN_CASE_PREVIEW_COLUMN)

  const generateContent = useMemo(
    () =>
      generateReturnsPreviewColumnContent(data?.returnCaseItem, isOrganisation),
    [data, isOrganisation],
  )

  useEffect(() => {
    if (!entityId) {
      return
    }

    getReturnsPreviewData({
      variables: {
        id: entityId,
      },
    })
  }, [entityId, getReturnsPreviewData])

  return (
    <PreviewColumn
      contentElement={
        <PreviewColumnContent content={generateContent} twoColumn />
      }
      headerElement={
        <PreviewColumnHeader
          image={data?.returnCaseItem.stocklistItem?.deviceDefinition?.imageURL}
          isReturnBlocked={isReturnBlocked(data?.returnCaseItem)}
          name={
            data?.returnCaseItem.stocklistItem?.deviceDefinition?.name || ''
          }
          onClosePreviewColumn={onClosePreviewColumn}
          productType={
            data?.returnCaseItem.stocklistItem?.deviceDefinition?.productType
          }
          returnCaseStatus={data?.returnCaseItem.status}
          returnCaseSubstatus={data?.returnCaseItem.substatus}
          title="Return"
        />
      }
      id={entityId}
      isLoading={isLoading}
      isVisible={isVisible}
      onClosePreviewColumn={onClosePreviewColumn}
      type={PreviewColumnTypeEnum.Return}
      url={path}
    />
  )
}
