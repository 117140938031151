import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash-es'
import { observer } from 'mobx-react'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { EMPLOYEE_PHONE_PLANS } from 'Modules/employees/pages/Employee/Employee.constants'

import { EntityListCard } from 'Components/EntityListCard/EntityListCard'
import { EntityProps } from 'Components/EntityListCard/EntityListCard.types'

import { ImagePlaceholderTypesEnum } from 'Types/imagePlaceholders.type'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import { PhonePlansEntityCardProps } from './PhonePlansEntityCard.types'

export const PhonePlansEntityCard = observer(
  (props: PhonePlansEntityCardProps) => {
    const { phonePlans } = props
    const { organisationId } = useParams<{
      organisationId?: string
    }>()

    const generateEntities = useMemo(
      (): EntityProps[] =>
        phonePlans.map(phonePlan => {
          const link = composePath({
            params: {
              organisationId,
              phonePlanId: phonePlan?.id,
            },
            path: GLOBAL_ROUTE_PATHS.organisation.phonePlan,
          })

          return {
            content: (
              <>
                {phonePlan.phoneNumber && (
                  <p className="mb-0 mt-2 fs-6">
                    <FormattedMessage id="Business_line" />:{' '}
                    <span>{phonePlan.phoneNumber}</span>
                  </p>
                )}

                <p className="mb-0 mt-2 fs-6 text-truncate">
                  <FormattedMessage id="Sim_card_number" />:{' '}
                  <span>{phonePlan.simcardNumber}</span>
                </p>
              </>
            ),
            id: phonePlan.id,
            imagePlaceholderType: ImagePlaceholderTypesEnum.phonePlan,
            imageSource: phonePlan.phonePlanTariff?.logo,
            link,
            title: phonePlan.phonePlanTariff?.name ?? '',
          }
        }),
      [organisationId, phonePlans],
    )

    if (isEmpty(phonePlans)) {
      return null
    }

    return (
      <EntityListCard
        entities={generateEntities}
        testId={EMPLOYEE_PHONE_PLANS}
        title="Phone_plans"
        titleSuffix={
          <span className="ms-2">
            <FormattedMessage id="Total" />: <span>{phonePlans.length}</span>
          </span>
        }
      />
    )
  },
)
