import { DEVICE_TYPE } from 'Constants/constants'

import { OrderDevice } from 'Portal/src/mappers/orderDeviceMapper/orderDevice.types'

export const parseItemTypeForLimitationRules = (item: OrderDevice) => {
  const { productType } = item.deviceDefinition

  switch (productType) {
    case DEVICE_TYPE.Laptop:
    case DEVICE_TYPE.Smartphone:
    case DEVICE_TYPE.Tablet:
      return productType
    default:
      return DEVICE_TYPE.Other
  }
}
