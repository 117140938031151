import { useMemo } from 'react'
import { Row } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { clsx } from 'clsx'

import { Heading } from 'Components/_theme'

import NoActionsFoundImage from 'Assets/images/details/no-actions-found.svg'

import { CardAction } from './CardAction'
import { CardActionsProps } from './CardActions.types'

export const CardActions = (props: CardActionsProps) => {
  const { actions, className, type } = props
  const intl = useIntl()

  const classNames = clsx(
    'row-cols-1 g-4',
    className,
    type === 'rental' || type === 'return' ? 'row-cols-md-2' : 'row-cols-md-4',
  )

  const areActionsEmpty = useMemo(
    () => actions.every(action => action.isHidden),
    [actions],
  )

  const noActionsMessage = () => {
    switch (type) {
      case 'rental':
        return 'CardActions_noActionRental_description'
      case 'employee':
        return 'CardActions_noActionEmployee_description'
      case 'return':
        return 'CardActions_noActionReturn_description'
      default:
        return ''
    }
  }

  return (
    <Row className={classNames}>
      {areActionsEmpty && (
        <div
          aria-live="polite"
          className="w-100 d-flex flex-column align-items-center justify-content-center"
          role="status"
        >
          <img
            alt={intl.formatMessage({ id: 'Img_alt_no_action' })}
            className="Order-no-action-found"
            src={NoActionsFoundImage}
          />

          <Heading as={6} size={3}>
            <FormattedMessage id="CardActions_noAction_title" />
          </Heading>

          <p>
            <FormattedMessage id={noActionsMessage()} />
          </p>
        </div>
      )}

      {actions.map(action => (
        <CardAction key={action.id} {...action} />
      ))}
    </Row>
  )
}
