import { ChangeEvent, FocusEvent, forwardRef, useState } from 'react'
import { useIntl } from 'react-intl'
import {
  faCheckCircle,
  faClose,
  faXmarkCircle,
} from '@fortawesome/pro-solid-svg-icons'
import { clsx } from 'clsx'

import { Icon } from 'Components/Icon/Icon'

import {
  TEXTINPUT_BLOCKED_CHARACTERS,
  TEXTINPUT_BLOCKED_CHARACTERS_ALLOWED_UNDERSCORE,
  TEXTSEARCH_BLOCKED_CHARACTERS,
} from 'Constants/constants'

import { INPUT_CONTAINER } from './Input.constants'
import { InputProps } from './Input.types'

const clearButtonId = 'clearableButton'

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    className,
    contentAfter,
    contentAfterCustomClick,
    contentBefore,
    disabled,
    iconLeft,
    iconRight,
    isClearable,
    isFilled,
    isInvalid,
    onClear,
    onTriggerFocus,
    markAsRequired,
    min,
    size = 'medium',
    onChange,
    allowBlockedCharacters,
    allowUnderscoreCharacter,
    isFormInput = true,
    ...restProps
  } = props

  const [hasFocus, setHasFocus] = useState(false)

  const intl = useIntl()

  const iconSize = size === 'large' ? 20 : 16

  const classNames = clsx(
    'Input',
    `Input-${size}`,
    iconLeft && 'Input-with_icon-left',
    (iconRight || isClearable) && 'Input-with_icon-right',
    isInvalid && 'Input-invalid',
    !isFilled && markAsRequired && 'Input-unfilled',
    disabled && 'Input-disabled',
    hasFocus && 'Input-focus',
    className,
  )

  const handleBlur = (event: FocusEvent) => {
    if (event.relatedTarget?.id === clearButtonId && isFilled) {
      return
    }

    setHasFocus(false)
  }

  const handleClear = () => {
    if (onClear) {
      onClear()
    }

    if (onTriggerFocus) {
      onTriggerFocus()
    }

    if (typeof contentAfterCustomClick === 'function') {
      setHasFocus(false)
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!onChange) {
      return undefined
    }

    if (allowBlockedCharacters) {
      return onChange(e)
    }

    if (restProps.type === 'text') {
      const regExp = allowUnderscoreCharacter
        ? TEXTINPUT_BLOCKED_CHARACTERS_ALLOWED_UNDERSCORE
        : TEXTINPUT_BLOCKED_CHARACTERS

      e.target.value = e.target.value.replace(regExp, '')
    }

    if (restProps.type === 'search') {
      e.target.value = e.target.value.replace(TEXTSEARCH_BLOCKED_CHARACTERS, '')
    }

    return onChange(e)
  }

  return (
    <div className={classNames} data-testid={INPUT_CONTAINER}>
      <div className="Input-content">
        {contentBefore && <div className="Input-before">{contentBefore}</div>}

        <div className="Input-wrapper" onBlur={handleBlur}>
          {iconLeft && (
            <Icon
              className="Input-icon Input-icon-left"
              icon={iconLeft}
              size={iconSize}
            />
          )}

          <input
            ref={ref}
            className={clsx(isInvalid && 'is-invalid')}
            disabled={disabled}
            onFocus={() => setHasFocus(true)}
            {...restProps}
            min={min}
            onChange={e => handleChange(e)}
          />

          {iconRight && !isClearable && (
            <Icon
              className="Input-icon Input-icon-right"
              icon={iconRight}
              size={iconSize}
            />
          )}

          {isFormInput &&
            isClearable &&
            isFilled &&
            !isInvalid &&
            !hasFocus && (
              <Icon
                className="Input-icon Input-icon-right Input-icon-success"
                icon={faCheckCircle}
                size={iconSize}
              />
            )}

          {isFormInput && isClearable && isInvalid && isFilled && !hasFocus && (
            <Icon
              className="Input-icon Input-icon-right Input-icon-error"
              icon={faXmarkCircle}
              size={iconSize}
            />
          )}

          {isClearable && isFilled && hasFocus && (
            <button
              aria-label={intl.formatMessage({ id: 'Clear' })}
              className="Input-icon Input-icon-right Input-icon-clear"
              id={clearButtonId}
              onClick={handleClear}
              tabIndex={-1}
              type="button"
            >
              <Icon icon={faClose} size={iconSize} />
            </button>
          )}
        </div>

        {contentAfter && contentAfterCustomClick && (
          <button
            aria-label={
              typeof contentAfter === 'string'
                ? contentAfter
                : intl.formatMessage({ id: 'Open' })
            }
            className="Input-after"
            onClick={contentAfterCustomClick}
            type="button"
          >
            {contentAfter}
          </button>
        )}

        {contentAfter && !contentAfterCustomClick && (
          <div className="Input-after">{contentAfter}</div>
        )}
      </div>
    </div>
  )
})
