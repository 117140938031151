import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { LinkButton } from 'Components/_theme'
import { FooterMenuItemProps } from 'Components/FooterMenuItem/FooterMenuItem.types'

export const FooterMenuItem = (props: FooterMenuItemProps) => {
  const { item, variant } = props

  const intl = useIntl()

  const linkTitle = useMemo(
    () =>
      item.asExternalLink
        ? intl.formatMessage(
            {
              id: 'Link_to_external_page_title',
            },
            {
              name: intl.formatMessage({
                id: item.nameId,
              }),
            },
          )
        : intl.formatMessage(
            {
              id: 'Link_to_page_title',
            },
            {
              name: intl.formatMessage({
                id: item.nameId,
              }),
            },
          ),
    [intl, item.asExternalLink, item.nameId],
  )

  const externalLinkProps = useMemo(
    () => ({
      ...(item.asExternalLink
        ? { rel: 'noopener noreferrer', target: '_blank' }
        : {}),
    }),
    [item.asExternalLink],
  )

  return (
    <LinkButton
      key={item.id}
      asExternalLink={item.asExternalLink}
      size="small"
      title={linkTitle}
      to={item.to}
      variant={variant}
      {...externalLinkProps}
    >
      <FormattedMessage id={item.nameId} />
    </LinkButton>
  )
}
