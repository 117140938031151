import { clsx } from 'clsx'

import { BadgeProps } from './Badge.types'

export const Badge = (props: BadgeProps) => {
  const {
    children,
    className,
    color = 'teal',
    e2eSelector,
    rounded,
    variant = 'primary',
    textWrap,
  } = props

  const classNames = clsx(
    'Badge',
    `Badge-${variant}`,
    `Badge-${variant}-${color}`,
    rounded && `Badge-rounded`,
    textWrap && `Badge-textWrap`,
    className,
  )

  return (
    <span className={classNames} data-e2e={e2eSelector} role="status">
      {children}
    </span>
  )
}
