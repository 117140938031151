import { EMPTY_SELECT_OPTION } from 'Constants/form'
import { getReturnCase_returnCaseItem } from 'Constants/graphql/queries/detail/__generated__/getReturnCase'

export const updateTrackingModalFormDefaultValues = (
  returnCase: getReturnCase_returnCaseItem,
) => ({
  carrier: returnCase.latestShippingInfo?.carrier
    ? {
        label: returnCase.latestShippingInfo.carrier,
        value: returnCase.latestShippingInfo.carrier,
      }
    : EMPTY_SELECT_OPTION,
  trackingReference: returnCase.latestShippingInfo?.trackingReference || '',
})
