import { dashOrSpaceToUnderscore } from 'Utilities'

import { getEmployeeDashboard_employee_caseitems_caseItems } from 'Constants/graphql/queries/employee/__generated__/getEmployeeDashboard'

import { CaseItemStatus, CaseItemType } from 'Portal/__generated__/globalTypes'

export const getNewCaseItems = (
  caseItems: getEmployeeDashboard_employee_caseitems_caseItems[],
): getEmployeeDashboard_employee_caseitems_caseItems[] =>
  caseItems.filter(
    caseItem =>
      (caseItem.status === CaseItemStatus.new ||
        caseItem.status === CaseItemStatus.pending ||
        dashOrSpaceToUnderscore(caseItem.status) ===
          CaseItemStatus.in_progress) &&
      (dashOrSpaceToUnderscore(caseItem.caseType) === CaseItemType.new_device ||
        dashOrSpaceToUnderscore(caseItem.caseType) ===
          CaseItemType.rental_complete_upgrade_device ||
        dashOrSpaceToUnderscore(caseItem.caseType) ===
          CaseItemType.early_upgrade_device),
  )
