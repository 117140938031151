export interface Auth0Props {
  clientId: string
  domain: string
}

export interface Auth0Env {
  dev: Auth0Props
  production: Auth0Props
  staging: Auth0Props
  demo: Auth0Props
  gcpdev: Auth0Props
}

export enum Auth0ConnectionProvider {
  Google = 'Google',
  Microsoft = 'Microsoft', // AzureAD and other servers
  OIDC = 'OIDC',
}

export type Auth0Config = {
  name: string
  provider: Auth0ConnectionProvider
  domain: string | null
}

export type Auth0Connection = {
  name: string
  provider: Auth0ConnectionProvider
  auth0Env: Auth0Env
}

export interface Auth0Params {
  response_type: string
  response_mode: string
  client_id: string
  redirect_uri: string
  connection: string
  scope: string
}
