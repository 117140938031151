import { FormattedMessage } from 'react-intl'

import { ParsedSearchResultDocument } from 'Modules/searchResults/types/searchResults.types'
import { getSearchResultValueFromHighlight } from 'Modules/searchResults/utils/searchResults.utils'

import { getCaseItemTypeTranslationId } from 'Utilities'

import { CASE_ITEM_TYPE_TRANSLATION_IDS } from 'Constants/caseItemType'

import { ContentTypeEnum } from 'Types/content/content.types'
import { AppEnvEnum } from 'Types/env.types'

import { SearchResultTitleProps } from './SearchResultsTitle.types'

export const SearchResultTitle = (props: SearchResultTitleProps) => {
  const { document, model, highlights } = props

  const renderItem = (keys: (keyof ParsedSearchResultDocument)[]) => {
    const content = keys
      .map(key => getSearchResultValueFromHighlight(key, document, highlights))
      .join(' ')

    if (content.trim() === '') {
      return null
    }

    // eslint-disable-next-line react/no-danger
    return <span dangerouslySetInnerHTML={{ __html: content }} />
  }

  const renderRentalTitle = () => {
    if (!document) {
      return null
    }

    return renderItem(['rental.stocklist_item.name'])
  }

  const renderPhonePlanTitle = () =>
    document?.['phone_plan.phone_plan_tariff_name'] ? (
      renderItem(['phone_plan.phone_plan_tariff_name'])
    ) : (
      <FormattedMessage id="Unnamed_phone_plan" />
    )

  const renderEmployeeTitle = () => {
    if (!document) {
      return null
    }

    return renderItem(['employee.first_name', 'employee.last_name'])
  }

  const renderOrderTitle = () => {
    if (!document || !document['case_item.type']) {
      return null
    }

    if (CASE_ITEM_TYPE_TRANSLATION_IDS[document['case_item.type']]) {
      const title = getCaseItemTypeTranslationId(document['case_item.type'])

      return (
        <span>
          <FormattedMessage id={title} />
        </span>
      )
    }

    if (
      window.APP_ENV === AppEnvEnum.DEV ||
      window.APP_ENV === AppEnvEnum.GCPDEV
    ) {
      return (
        <span style={{ color: '#f0011' }}>{document['case_item.type']}</span>
      )
    }

    return null
  }

  const renderReturnsTitle = () => {
    if (!document) {
      return null
    }

    return renderItem(['return_case.stocklist_item.name'])
  }

  const renderTitle = () => {
    switch (model) {
      case ContentTypeEnum.rentals:
        return renderRentalTitle()
      case ContentTypeEnum.phone_plans:
        return renderPhonePlanTitle()
      case ContentTypeEnum.employees:
        return renderEmployeeTitle()
      case ContentTypeEnum.caseitems:
        return renderOrderTitle()
      case ContentTypeEnum.return_cases:
        return renderReturnsTitle()
      default:
        return null
    }
  }

  return renderTitle()
}
