import { Fragment, useMemo } from 'react'
import { Col, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons'
import { useMediaQuery } from '@react-hookz/web'
import { isEmpty } from 'lodash-es'
import { observer } from 'mobx-react'

import { Alert, Heading } from 'Components/_theme'
import { OrderSelectionCard } from 'Components/OrderSelectionCard/OrderSelectionCard'

import { useStore } from 'Stores/index'

import {
  isActiveLanguage,
  isOrderLimitExceeded,
  sortGQLOrderDevicesGroups,
} from 'Utilities'

import { PORTAL_BREAKPOINTS } from 'Constants/constants'
import { E2E_ORDER_ALERT_CANNOT_ORDER_MORE } from 'Constants/e2e'
import { SupportedLanguagesEnum } from 'Constants/locale'

import { OrderDisabledAlert } from '../OrderDisabledAlert/OrderDisabledAlert'
import { RentCategoryChooserProps } from './RentCategoryChooser.types'

export const RentCategoryChooser = observer(
  (props: RentCategoryChooserProps) => {
    const { onChooseDevice, chosenDevice, groupedDevices, limitationRules } =
      props
    const { orderStore } = useStore()
    const { isCYOD } = orderStore

    const isFullHDDesktop = useMediaQuery(
      `(min-width: ${PORTAL_BREAKPOINTS.XXXL})`,
    )

    const sortedGroupedDevices = useMemo(
      () => sortGQLOrderDevicesGroups(groupedDevices),
      [groupedDevices],
    )

    if (!isCYOD) {
      return <OrderDisabledAlert className="py-6" />
    }

    if (isOrderLimitExceeded(limitationRules) || isEmpty(groupedDevices)) {
      return (
        <div className="py-6">
          <Alert
            data-e2e={E2E_ORDER_ALERT_CANNOT_ORDER_MORE}
            iconLeft={faCircleInfo}
            variant="info"
          >
            <FormattedMessage id="DeviceChooser_limit_exceeded" />
          </Alert>
        </div>
      )
    }

    const xxlColSizeWithTracker = isFullHDDesktop ? 6 : 9

    return (
      <div className="RentCategoryChooser d-flex flex-wrap pb-6">
        <div className="w-100">
          {sortedGroupedDevices?.map(deviceGroup => (
            <Fragment key={deviceGroup.id}>
              <div className="RentCategoryChooser-header">
                <Heading as={5} size={3}>
                  {deviceGroup.name}
                </Heading>

                {isActiveLanguage(SupportedLanguagesEnum.DE) &&
                  !!deviceGroup.descriptionDE && (
                    <div className="rent-category-description pt-2 pb-4">
                      {deviceGroup.descriptionDE}
                    </div>
                  )}

                {isActiveLanguage(SupportedLanguagesEnum.EN) &&
                  !!deviceGroup.descriptionEN && (
                    <div className="rent-category-description pt-2 pb-4">
                      {deviceGroup.descriptionEN}
                    </div>
                  )}

                {isActiveLanguage(SupportedLanguagesEnum.FR) &&
                  !!deviceGroup.descriptionFR && (
                    <div className="rent-category-description pt-2 pb-4">
                      {deviceGroup.descriptionFR}
                    </div>
                  )}
              </div>

              <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-4">
                {deviceGroup.devices.map(device => (
                  <Col
                    key={device.id}
                    lg={device.deviceDefinition.impactFactors ? 8 : 4}
                    sm={device.deviceDefinition.impactFactors ? 12 : 6}
                    xl={device.deviceDefinition.impactFactors ? 6 : 3}
                    xs={12}
                    xxl={
                      device.deviceDefinition.impactFactors
                        ? xxlColSizeWithTracker
                        : 3
                    }
                  >
                    <OrderSelectionCard
                      chooseItem={onChooseDevice}
                      isSelected={
                        chosenDevice ? chosenDevice.id === device.id : false
                      }
                      item={device}
                      limitationRules={limitationRules}
                    />
                  </Col>
                ))}
              </Row>
            </Fragment>
          ))}
        </div>
      </div>
    )
  },
)
