import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { zodResolver } from '@hookform/resolvers/zod'

import { ButtonGroup } from 'Components/_theme'
import { Field } from 'Components/Field/Field'
import { FieldTypeEnum } from 'Components/Field/Field.types'
import { Form } from 'Components/Form/Form'
import { FormOptionalMessage } from 'Components/FormOptionalMessage/FormOptionalMessage'
import { ModalDevicePresentationBlock } from 'Components/ModalDevicePresentationBlock/ModalDevicePresentationBlock'

import { requestPurchaseModalSummarySchema } from '../RequestPurchaseModal.schema'
import {
  RequestPurchaseModalStepsEnum,
  RequestPurchaseModalSummaryProps,
  RequestPurchaseModalSummarySchema,
} from '../RequestPurchaseModal.types'

export const RequestPurchaseModalSummary = (
  props: RequestPurchaseModalSummaryProps,
) => {
  const { rental, onSetCurrentStep, isSubmitting } = props

  const intl = useIntl()

  const formMethods = useForm<RequestPurchaseModalSummarySchema>({
    defaultValues: {
      email: rental.employee?.contact.email ?? '',
      message: '',
    },
    resolver: zodResolver(requestPurchaseModalSummarySchema),
  })

  const handleSubmit = formMethods.handleSubmit(data => {
    onSetCurrentStep(RequestPurchaseModalStepsEnum.CONFIRMATION, data)
  })

  return (
    <FormProvider {...formMethods}>
      <Form noValidate onSubmit={handleSubmit}>
        <ModalDevicePresentationBlock rental={rental} small>
          <div className="text-ellipsis">
            <span>IMEI: </span>

            <span title={rental.stocklistItem?.imei ?? ''}>
              {rental.stocklistItem?.imei}
            </span>
          </div>

          <div className="text-ellipsis">
            <span>SN: </span>

            <span title={rental.stocklistItem?.serialNumber ?? ''}>
              {rental.stocklistItem?.serialNumber ?? '-'}
            </span>
          </div>
        </ModalDevicePresentationBlock>

        <Field
          autoComplete="email"
          containerClassName="mt-6"
          label="Email"
          name="email"
          type={FieldTypeEnum.email}
        />

        <FormOptionalMessage containerClassName="mt-4" />

        <ButtonGroup
          leftButton={{
            onClick: () =>
              onSetCurrentStep(RequestPurchaseModalStepsEnum.LANDING),
            text: intl.formatMessage({ id: 'Back' }),
          }}
          rightButton={{
            disabled: isSubmitting,
            text: intl.formatMessage({ id: 'Next' }),
            type: 'submit',
          }}
        />
      </Form>
    </FormProvider>
  )
}
