import { OrderLimitations } from 'Stores/orderStore/orderStore.types'

export const isOrderLimitExceeded = (
  limitationRules: OrderLimitations | null,
) => {
  if (!limitationRules) {
    return false
  }

  return (
    limitationRules.laptop.current <= 0 &&
    limitationRules.tablet.current <= 0 &&
    limitationRules.smartphone.current <= 0 &&
    limitationRules.other.current <= 0
  )
}
