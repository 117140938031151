import { useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { faLaptopMobile } from '@fortawesome/pro-solid-svg-icons'
import { observer } from 'mobx-react'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { EmployeeModalTypes } from 'Modules/employees/pages/Employee/Employee.types'

import { CardActionProps } from 'Components/CardActions/CardActions.types'
import { CardDetailsListItem } from 'Components/CardDetailsList/CardDetailsList.types'
import { FormatDate } from 'Components/FormatDate/FormatDate'

import { AssignDeviceModal } from 'Modals/AssignDeviceModal/AssignDeviceModal'
import { NewPhonePlanModal } from 'Modals/NewPhonePlanModal/NewPhonePlanModal'

import { useStore } from 'Stores/index'
import { OriginRouteTypeEnum } from 'Stores/orderStore/orderStore.types'

import { getFullContactName } from 'Utilities'

import {
  E2E_ASSIGN_TO_EMPLOYEE_ACTION,
  E2E_EMPLOYEE_LEAVES_ACTION,
  E2E_NEW_ORDER_ACTION,
  E2E_NEW_PHONEPLAN_ACTION,
} from 'Constants/e2e'

import { PageTypeEnum } from 'Types/page/page.types'

import { iconAssignDevice } from 'Icons/iconAssignDevice'
import { iconEmployeeLeaves } from 'Icons/iconEmployeeLeaves'
import { iconNewPhonePlan } from 'Icons/iconNewPhonePlan'

import { useModal } from 'Portal/src/hooks'
import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import { EditEmployeeFormModal } from '../EditEmployeeFormModal/EditEmployeeFormModal'
import { EmployeeOffboarding } from '../EmployeeOffboarding/EmployeeOffboarding'
import { EmployeePresentationCard } from '../EmployeePresentationCard/EmployeePresentationCard'
import { EmployeeDetailsCardProps } from './EmployeeDetailsCard.types'

export const EmployeeDetailsCard = observer(
  (props: EmployeeDetailsCardProps) => {
    const { actions, employee, refetch } = props
    const history = useHistory()

    const {
      portalConfigStore,
      orderStore,
      organisationStore,
      portalSettingsStore,
    } = useStore()
    const { portalConfig } = portalConfigStore
    const { fetchOrganisationCostCenters } = organisationStore
    const { setOriginRoute } = orderStore
    const { isAppReadOnly } = portalSettingsStore
    const { employeeId, organisationId } = useParams<{
      organisationId?: string
      employeeId?: string
    }>()

    const { setModal, hideModal } = useModal()

    const handleOpenAssignDeviceModal = useCallback(() => {
      setModal({
        component: AssignDeviceModal,
        isOpen: true,
        props: {
          employee,
          onSubmit: () => {
            refetch()
            hideModal()
          },
        },
      })
    }, [employee, hideModal, refetch, setModal])

    const handleOpenEditEmployeeModal = useCallback(() => {
      setModal({
        component: EditEmployeeFormModal,
        isOpen: true,
        props: {
          employee,
          onHide: hideModal,
          onSuccess: (refetchCostCenters: boolean) => {
            refetch()

            if (refetchCostCenters) {
              fetchOrganisationCostCenters()
            }

            hideModal()
          },
        },
      })
    }, [employee, fetchOrganisationCostCenters, hideModal, refetch, setModal])

    const handleOpenEmployeeOffboardingModal = useCallback(() => {
      setModal({
        component: EmployeeOffboarding,
        isOpen: true,
        props: {
          employee,
          handleSubmit: () => {
            refetch()
            hideModal()
          },
        },
      })
    }, [employee, hideModal, refetch, setModal])

    const handleOpenNewPhonePlanModal = useCallback(() => {
      setModal({
        component: NewPhonePlanModal,
        isOpen: true,
        props: {
          handleSubmit: () => {
            refetch()
            hideModal()
          },
          hide: hideModal,
          page: PageTypeEnum.EmployeeDetails,
        },
      })
    }, [hideModal, refetch, setModal])

    const handleRentNewDevice = useCallback(() => {
      setOriginRoute({
        type: OriginRouteTypeEnum.Employee,
        url: history.location.pathname,
      })

      // EmployeeDetailsCard is only rendered on organisation
      const path = composePath({
        params: {
          employeeId,
          organisationId,
        },
        path: GLOBAL_ROUTE_PATHS.organisation.employeeOrderNew,
      })

      history.push(path)
    }, [employeeId, history, organisationId, setOriginRoute])

    const generateDetailsList = useMemo(
      (): CardDetailsListItem[] => [
        {
          label: 'Email',
          value: employee.contact.email,
        },
        {
          label: 'Phone',
          value: employee.contact.phoneNumber,
        },
        {
          label: 'Organisation',
          value: employee.organisation?.fullName,
        },
        {
          label: 'Office',
          value: employee.office?.locationName,
        },
        {
          label: 'Cost_center',
          value: employee.costCenter?.name,
        },
        {
          label: 'Rent_category_tier',
          // TODO: Not impletemented yet on the BE
          value: '',
        },
        {
          label: 'Exit_date',
          value:
            employee.exitDate !== '9999-12-31T00:00:00Z' ? (
              <FormatDate value={employee.exitDate} />
            ) : null,
        },
      ],
      [
        employee.contact.email,
        employee.contact.phoneNumber,
        employee.costCenter?.name,
        employee.exitDate,
        employee.office?.locationName,
        employee.organisation?.fullName,
      ],
    )

    const generateActions = useMemo(
      (): CardActionProps[] => [
        {
          e2eSelector: E2E_NEW_ORDER_ACTION,
          icon: faLaptopMobile,
          id: 99,
          isHidden: !actions.showOrderNewDeviceAction,
          name: 'Rent_new_device',
          onActionClick: handleRentNewDevice,
        },
        {
          e2eSelector: E2E_NEW_PHONEPLAN_ACTION,
          icon: iconNewPhonePlan,
          id: EmployeeModalTypes.NewPhonePlan,
          isHidden: !actions.showOrderNewPhonePlanAction,
          name: 'New_phone_plan',
          onActionClick: handleOpenNewPhonePlanModal,
        },
        {
          e2eSelector: E2E_ASSIGN_TO_EMPLOYEE_ACTION,
          icon: iconAssignDevice,
          id: EmployeeModalTypes.AssignDevice,
          isHidden: !actions.showAssignDeviceAction,
          name: 'Assign_device_to_employee',
          onActionClick: handleOpenAssignDeviceModal,
        },
        {
          e2eSelector: E2E_EMPLOYEE_LEAVES_ACTION,
          icon: iconEmployeeLeaves,
          id: EmployeeModalTypes.EmployeeLeavesFirm,
          isHidden: !actions.showEmployeeLeavesFirmAction,
          name: employee?.exitDate ? 'ChangeExitDate' : 'Employee_leaves',
          onActionClick: handleOpenEmployeeOffboardingModal,
          variant: 'danger',
        },
      ],
      [
        actions.showOrderNewDeviceAction,
        actions.showOrderNewPhonePlanAction,
        actions.showAssignDeviceAction,
        actions.showEmployeeLeavesFirmAction,
        handleRentNewDevice,
        handleOpenNewPhonePlanModal,
        handleOpenAssignDeviceModal,
        employee?.exitDate,
        handleOpenEmployeeOffboardingModal,
      ],
    )

    return (
      <EmployeePresentationCard
        actions={isAppReadOnly ? [] : generateActions}
        canEditEmployee={
          (portalConfig?.editEmployee && !isAppReadOnly) || false
        }
        detailsList={generateDetailsList}
        name={getFullContactName(employee.contact)}
        onEditEmployee={handleOpenEditEmployeeModal}
      />
    )
  },
)
