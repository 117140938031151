import { Col, Container, Row } from 'react-bootstrap'
import { NetworkStatus, useQuery } from '@apollo/client'
import { observer } from 'mobx-react'

import { InvoicesTable } from 'Modules/invoices/components/InvoiceTable/InvoicesTable'

import { Card } from 'Components/_theme'
import { FaqCard } from 'Components/FaqCard/FaqCard'
import { ImpactTracker } from 'Components/ImpactTracker/ImpactTracker'
import { InfiniteScrollLoadingState } from 'Components/InfiniteScrollLoadingState/InfiniteScrollLoadingState'
import { ListSearch } from 'Components/ListSearch/ListSearch'
import { ObserveMeOnceIAmMounted } from 'Components/ObserveMeOnceIAmMounted/ObserveMeOnceIAmMounted'
import { PageErrorState } from 'Components/PageErrorState/PageErrorState'
import { PageHeader } from 'Components/PageHeader/PageHeader'
import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'

import { useActiveUser } from 'Hooks'

import { PAGINATION } from 'Constants/constants'
import {
  getOrganisationInvoices,
  getOrganisationInvoicesVariables,
} from 'Constants/graphql/queries/listing/__generated__/getOrganisationInvoices'
import { GET_ORGANISATION_INVOICES } from 'Constants/graphql/queries/listing/GetInvoices'
import { PortalSectionEnum } from 'Constants/portalSection'

export const Invoices = observer(() => {
  const { activeOrganisation } = useActiveUser()

  const {
    loading: isLoading,
    data,
    error,
    fetchMore,
    refetch,
    networkStatus,
  } = useQuery<getOrganisationInvoices, getOrganisationInvoicesVariables>(
    GET_ORGANISATION_INVOICES,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
      variables: {
        after: PAGINATION.AFTER,
        first: PAGINATION.ITEMS_PER_PAGE,
        id: activeOrganisation?.id!,
      },
    },
  )

  if (isLoading && !data) {
    return <PageLoadingState />
  }

  const organisationInvoices: getOrganisationInvoices | undefined = data
  const onLoadMore = () => {
    fetchMore({
      variables: {
        after: organisationInvoices?.organisation.invoices?.pageInfo.endCursor,
      },
    })
  }
  const handleSearch = (value: string) => {
    refetch({
      searchterm: value || null,
    })
  }

  const intersectionObserver = new IntersectionObserver(([entry]) => {
    if (entry.isIntersecting) {
      onLoadMore()
    }
  })

  if (error !== undefined) {
    return <PageErrorState />
  }

  const {
    invoices,
    pageInfo: { hasNextPage },
  } = {
    ...data?.organisation?.invoices,
    pageInfo: {
      hasNextPage: false,
      ...data?.organisation?.invoices?.pageInfo,
    },
  }

  return (
    <Container className="pt-6" fluid>
      <div role="banner">
        <PageHeader portalSection={PortalSectionEnum.Invoices} />
      </div>

      <Row>
        <Col xl={12} xxl={8}>
          <Card className="mb-4">
            <ListSearch
              buttonVisible={false}
              handleSearch={handleSearch}
              totalCount={invoices?.length ?? 0}
            />
            <Row>
              <Col>
                <InvoicesTable
                  invoices={invoices ?? []}
                  isLoading={isLoading}
                  isSearching={networkStatus === NetworkStatus.setVariables}
                />
              </Col>
            </Row>
          </Card>
          <Row className="p-1">
            <Col>
              {hasNextPage &&
                (data && isLoading ? (
                  <InfiniteScrollLoadingState />
                ) : (
                  <ObserveMeOnceIAmMounted
                    intersectionObserver={intersectionObserver}
                  />
                ))}
            </Col>
          </Row>
        </Col>
        <Col xl={12} xxl={4}>
          <div className="sticky-column">
            <ImpactTracker />
            <FaqCard />
          </div>
        </Col>
      </Row>
    </Container>
  )
})
