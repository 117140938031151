import { clsx } from 'clsx'

import { Button } from 'Components/_theme/Button/Button'

import { PrimaryButtonProps } from './PrimaryButton.types'

export const PrimaryButton = (props: PrimaryButtonProps) => {
  const {
    className,
    variant = 'basic',
    appearance = 'fill',
    ...restProps
  } = props

  const classNames = clsx(
    'PrimaryButton',
    variant !== 'basic' && `PrimaryButton-${variant}`,
    appearance !== 'fill' && `PrimaryButton-${appearance}`,
    className,
  )

  return <Button className={classNames} {...restProps} />
}
