import { isEmpty } from 'lodash-es'
import { action, makeObservable, observable } from 'mobx'

import { AppEnvEnum } from 'Types/env.types'

import { initSurvicate } from 'Portal/src/utilities/survicate/survicate.util'

import type { RootStore } from '..'
import { UserModeEnum } from '../portalSettingsStore/portalSettingsStore.types'
import {
  MainNavigationConfig,
  NavigationConfigTypeEnum,
} from './navigationStore.types'

export class NavigationStore {
  mainNavigationConfig: MainNavigationConfig = {
    id: '',
    type: NavigationConfigTypeEnum.Organisation,
    url: '',
  }

  constructor(private readonly store: RootStore) {
    makeObservable(this, {
      createMainNavigationConfig: action,
      mainNavigationConfig: observable,
      setMainNavigationConfig: action,
    })
  }

  createMainNavigationConfig = () => {
    if (!this.store.aclsStore.allowedAcls) {
      return
    }

    const { url } = this.mainNavigationConfig
    const {
      organisations: { organisations },
      allowedEmployees: { employees },
    } = this.store.aclsStore.allowedAcls

    if (
      this.store.portalSettingsStore.userMode === UserModeEnum.NEW_JOINER &&
      employees &&
      !isEmpty(employees)
    ) {
      this.setMainNavigationConfig({
        id: employees[0].id,
        type: NavigationConfigTypeEnum.Employee,
        url: `/employee/${employees[0].id}`,
      })

      return
    }

    const lastSelected = localStorage.getItem('lastSelected')

    const lastSelectedId = localStorage.getItem('lastSelectedId')
    let type
    let route = ''
    let id = ''

    if (organisations && !isEmpty(organisations)) {
      route = `/organisation/${organisations[0].id}`
      id = organisations[0].id
    } else if (employees && !isEmpty(employees)) {
      route = `/employee/${employees[0].id}`
      id = employees[0].id
    }

    type = !isEmpty(organisations)
      ? NavigationConfigTypeEnum.Organisation
      : NavigationConfigTypeEnum.Employee

    if (organisations && lastSelected === 'organisation') {
      organisations.some(organisation => {
        if (organisation.id.toString() === lastSelectedId) {
          route = `/${lastSelected}/${organisation.id}`
          type = NavigationConfigTypeEnum.Organisation
          id = organisation.id

          return true
        }

        return false
      })
    }

    if (employees && lastSelected === 'employee') {
      employees.some(employee => {
        if (employee.id.toString() === lastSelectedId) {
          route = `/${lastSelected}/${employee.id}`
          type = NavigationConfigTypeEnum.Employee
          id = employee.id

          return true
        }

        return false
      })
    }

    if (route !== '' && route !== url) {
      this.setMainNavigationConfig({
        id,
        type,
        url: route,
      })
    }
  }

  setMainNavigationConfig = async (navigationConfig: MainNavigationConfig) => {
    if (
      navigationConfig.url !== '' &&
      navigationConfig.url !== this.mainNavigationConfig.url
    ) {
      this.mainNavigationConfig = navigationConfig

      if (navigationConfig.type === NavigationConfigTypeEnum.Organisation) {
        await this.store.organisationStore.fetchOrganisation(
          navigationConfig.id,
        )

        this.store.portalSettingsStore.initOrganisationPortalSettings(
          navigationConfig.id,
        )

        await this.store.dashboardStore.fetchDashboardData(navigationConfig.id)
      } else {
        await this.store.employeeStore.fetchEmployee(navigationConfig.id)
      }

      this.store.portalSettingsStore.setIsAppReady(true)
    }

    if (window.APP_ENV !== AppEnvEnum.DEV) {
      initSurvicate(
        this.store.userStore.user,
        navigationConfig.type === NavigationConfigTypeEnum.Organisation,
        this.store.organisationStore.organisation,
        this.store.employeeStore.employee,
      )
    }
  }
}
