import { IconProp } from '@fortawesome/fontawesome-svg-core'

export enum ImpactTrackerUnits {
  kilograms = 'kg',
  kilometers = 'kms',
  liters = 'L',
}

export interface ImpactTrackerItemValue {
  active: boolean
  color: string
  value: number
}

interface ImpactTrackerItem {
  values: ImpactTrackerItemValue[]
  totalValue: number
}

export interface ImpactTrackerChartItemProps extends ImpactTrackerItem {
  icon: IconProp
  iconText?: string
  title: string
  className?: string
  defaultIconColor?: string
  interactive?: boolean
  unit?: ImpactTrackerUnits
  valueFormatter?: (value: number) => string
}

export interface ImpactTrackerChartDataset {
  label: string
  color: string
  valueCo2: number
  valueWater: number
  valueRawMaterial: number
  valueKilometers: number
  valueShowers: number
}

export interface ImpactTrackerChartData {
  datasets: ImpactTrackerChartDataset[]
  totalCo2: number
  totalWater: number
  totalRawMaterial: number
}

export interface ImpactTrackerChartProps {
  data: ImpactTrackerChartData
  interactive?: boolean
  showLegend?: boolean
}

export enum ImpactFactorsStatuses {
  NEW = 'new',
  REFURB = 'refurb',
  PROLONG = 'prolong',
}

export enum ImpactFactorsTypes {
  CO2 = 'co2',
  WATER = 'water',
  RAW_MATERIAL = 'rawmaterial',
}
