import { EmployeeStore } from 'Stores/employeeStore/employeeStore'
import { OrganisationStore } from 'Stores/organisationStore/organisationStore'

import {
  getEmployee_employee_office_address,
  getEmployee_employee_organisation_contact_address,
} from 'Constants/graphql/queries/employee/__generated__/getEmployee'
import { getOrganisation_organisation_contact_address } from 'Constants/graphql/queries/organisation/__generated__/getOrganisation'

import { OrderDevice } from 'Portal/src/mappers/orderDeviceMapper/orderDevice.types'

import { OrderLimitations } from './orderStore.types'
import { adjustChosenAccessoryLimitationRules } from './orderStoreLimitationRules.utils'

export const getOrderAddress = (
  isOrganisation: boolean,
  isEmployee: boolean,
  organisation: OrganisationStore['organisation'],
  employee: EmployeeStore['employee'],
):
  | getOrganisation_organisation_contact_address
  | getEmployee_employee_office_address
  | getEmployee_employee_organisation_contact_address
  | null
  | undefined => {
  const organisationAddress = organisation?.contact?.address
  const employeeAddress =
    employee?.office?.address || employee?.organisation?.contact?.address

  return isOrganisation && !isEmployee ? organisationAddress : employeeAddress
}

type CountTotalPriceReturnType = {
  totalBasis: number
  totalCalculatedNet: number
  totalWithTax: number
  totalWithoutTax: number
} | null

export const countTotalPrice = (
  chosenAccessories: Map<string, OrderDevice>,
  chosenDevice: OrderDevice | null,
  previewColumnDevice: OrderDevice | null,
): CountTotalPriceReturnType => {
  if (!chosenDevice && !previewColumnDevice) {
    return null
  }

  const device = chosenDevice || previewColumnDevice

  const quantity = device?.quantity ?? 1
  const primaryDeviceCopaymentNet = device?.copaymentNet ?? 0
  const primaryDeviceCalculatedNet = device?.calculatedNet ?? 0
  const primaryCopaymentGross = device?.copaymentGross ?? 0
  const primaryBasisAmount = device?.basisAmount ?? 0

  const addonDevicesCopaymentNet =
    device?.addonDevices?.reduce(
      (finalCopaymentNet, currentAddonDevice) =>
        finalCopaymentNet + currentAddonDevice.copaymentNet,
      0,
    ) ?? 0

  const addonDevicesCopaymentGross =
    device?.addonDevices?.reduce(
      (finalCopaymentGross, currentAddonDevice) =>
        finalCopaymentGross + currentAddonDevice.copaymentGross!,
      0,
    ) ?? 0

  const addonDevicesBasisAmount =
    device?.addonDevices?.reduce(
      (finalBasisAmount, currentAddonDevice) =>
        finalBasisAmount + currentAddonDevice.basisAmount!,
      0,
    ) ?? 0

  const addonDevicesCalculatedNet =
    device?.addonDevices?.reduce(
      (finalCalculatedNet, currentAddonDevice) =>
        finalCalculatedNet + (currentAddonDevice.calculatedNet ?? 0),
      0,
    ) ?? 0

  const copaymentNet = primaryDeviceCopaymentNet + addonDevicesCopaymentNet
  const copaymentGross = primaryCopaymentGross + addonDevicesCopaymentGross
  const basisAmount = primaryBasisAmount + addonDevicesBasisAmount
  const calculatedNet = primaryDeviceCalculatedNet + addonDevicesCalculatedNet

  let totalWithoutTax = copaymentNet * quantity
  let totalWithTax = copaymentGross * quantity
  let totalCalculatedNet = calculatedNet * quantity
  let totalBasis = basisAmount * quantity

  for (const [_, accessory] of chosenAccessories) {
    if (accessory.copaymentGross) {
      totalWithTax += accessory.copaymentGross * accessory.quantity
    }

    if (accessory.copaymentNet) {
      totalWithoutTax += accessory.copaymentNet * accessory.quantity
    }

    if (accessory.calculatedNet) {
      totalCalculatedNet += accessory.calculatedNet * accessory.quantity
    }

    if (accessory.basisAmount) {
      totalBasis += accessory.basisAmount * accessory.quantity
    }
  }

  return { totalBasis, totalCalculatedNet, totalWithTax, totalWithoutTax }
}

export const skipAccessories = (
  accessories: OrderDevice[] | null,
  limitationRules: OrderLimitations | null,
  isProductOffer: boolean,
) => {
  if (
    isProductOffer &&
    accessories?.some(accessory => !accessory?.isCreateSeparateRental)
  ) {
    return false
  }

  return (
    !accessories?.length ||
    (limitationRules && limitationRules.other.initial <= 0)
  )
}

export const toggleAccessory = (
  accessory: OrderDevice,
  chosenAccessories: Map<string, OrderDevice>,
  isProductOffer: boolean,
  limitationRules: OrderLimitations | null,
  setLimitationRules: (limitations: OrderLimitations | null) => void,
) => {
  if (chosenAccessories.has(accessory.id)) {
    chosenAccessories.delete(accessory.id)

    if (isProductOffer && !accessory.isCreateSeparateRental) {
      return
    }

    const newLimitationRules = adjustChosenAccessoryLimitationRules(
      limitationRules,
      accessory,
      'add',
    )

    if (newLimitationRules) {
      setLimitationRules(newLimitationRules)
    }
  } else {
    chosenAccessories.set(accessory.id, accessory)

    if (isProductOffer && !accessory.isCreateSeparateRental) {
      return
    }

    const newLimitationRules = adjustChosenAccessoryLimitationRules(
      limitationRules,
      accessory,
      'remove',
    )

    if (newLimitationRules) {
      setLimitationRules(newLimitationRules)
    }
  }
}
