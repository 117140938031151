import { useCallback, useMemo } from 'react'
import { useNumericMenu } from 'react-instantsearch'
import { DateObject } from 'react-multi-date-picker'
import { DateTime } from 'luxon'

import { Label } from 'Components/_theme'
import { MultidatePicker } from 'Components/MultidatePicker/MultidatePicker'

import { scrollToTop } from 'Utilities'

import { useCurrentRefinement } from 'Algolia/hooks/useCurrentRefinement/useCurrentRefinement'

import { MultidatepickerFilterProps } from './MultidatepickerFilter.types'
import { parseDateToFilterTimestamp } from './MultidatepickerFilter.utils'

export const MultidatepickerFilter = (props: MultidatepickerFilterProps) => {
  const {
    filterProps,
    label,
    multidatePickerProps,
    autoComplete,
    disableSingleDateRefine,
  } = props
  const { refine } = useNumericMenu({
    // not used but required by the hook
    items: [{ label: 'All' }],
    ...filterProps,
  })
  const currentRefinement = useCurrentRefinement(filterProps.attribute)
  const value = useMemo<DateObject | DateObject[] | null>(() => {
    let firstDate: DateObject | undefined
    let secondDate: DateObject | undefined

    if (currentRefinement?.[0]?.value) {
      const jsDate = DateTime.fromSeconds(
        Number(currentRefinement[0].value),
      ).toJSDate()

      firstDate = new DateObject(jsDate)
    }

    if (currentRefinement?.[1]?.value) {
      const jsDate = DateTime.fromSeconds(
        Number(currentRefinement[1].value),
      ).toJSDate()

      secondDate = new DateObject(jsDate)
    }

    if (firstDate && secondDate) {
      return [firstDate, secondDate]
    }

    return firstDate ?? null
  }, [currentRefinement])

  const handleChange = useCallback(
    (selectedDates: DateObject[] | null) => {
      scrollToTop()

      let firstDateTimestamp: number | undefined
      let secondDateTimestamp: number | undefined

      if (Array.isArray(selectedDates) && selectedDates.length === 2) {
        const [firstDate, secondDate] = selectedDates

        const firstDateUnix = firstDate?.toUnix()
        const secondDateUnix = secondDate?.toUnix()

        if (firstDateUnix <= secondDateUnix) {
          firstDateTimestamp = parseDateToFilterTimestamp({
            dateObject: firstDate,
            time: {
              hours: 0,
              minutes: 0,
              seconds: 0,
            },
          })
          secondDateTimestamp = parseDateToFilterTimestamp({
            dateObject: secondDate,
            time: {
              hours: 23,
              minutes: 59,
              seconds: 59,
            },
          })
        } else {
          firstDateTimestamp = parseDateToFilterTimestamp({
            dateObject: firstDate,
            time: {
              hours: 23,
              minutes: 59,
              seconds: 59,
            },
          })
          secondDateTimestamp = parseDateToFilterTimestamp({
            dateObject: secondDate,
            time: {
              hours: 0,
              minutes: 0,
              seconds: 0,
            },
          })
        }
      } else {
        firstDateTimestamp = parseDateToFilterTimestamp({
          dateObject: selectedDates?.[0] ?? null,
        })
      }

      if (
        disableSingleDateRefine &&
        firstDateTimestamp &&
        !secondDateTimestamp
      ) {
        return
      }

      // If we have only first date selected and it's set to 0 or negative value
      // we want to set refine value to undefined to use <= operator in Algolia (ultil endDate)
      firstDateTimestamp =
        !secondDateTimestamp && firstDateTimestamp && firstDateTimestamp < 1
          ? undefined
          : firstDateTimestamp

      const range = {
        end: secondDateTimestamp,
        start: firstDateTimestamp,
      }

      refine(encodeURI(JSON.stringify(range)))
    },
    [refine, disableSingleDateRefine],
  )

  return (
    <div className="Filter-input-container">
      <Label>{label}</Label>

      <MultidatePicker
        autoComplete={autoComplete}
        onChange={handleChange}
        value={value}
        {...multidatePickerProps}
      />
    </div>
  )
}
