import { useRef } from 'react'
import clsx from 'clsx'
import FocusTrap from 'focus-trap-react'

import { LoadingState } from 'Components/LoadingState/LoadingState'

import { useEscapeKeyListenerEffect } from 'Hooks'

import { PreviewColumnProps } from './PreviewColumn.types'
import { PreviewColumnFooter } from './PreviewColumnFooter'

export const PreviewColumn = (props: PreviewColumnProps) => {
  const {
    id,
    type,
    url,
    contentElement,
    headerElement,
    isLoading,
    onClosePreviewColumn,
    disableFooterAskQuestion,
    renderCustomFooterButton,
    isVisible,
  } = props

  const previewColumnRef = useRef<HTMLDivElement>(null)

  const classNames = clsx('PreviewColumn', {
    'PreviewColumn--is-visible': isVisible,
    'PreviewColumn--loader': isLoading,
  })

  useEscapeKeyListenerEffect(onClosePreviewColumn, isVisible)

  if (isLoading) {
    return (
      <div className={classNames}>
        <LoadingState />
      </div>
    )
  }

  return (
    <FocusTrap
      active={isVisible}
      focusTrapOptions={{ clickOutsideDeactivates: true }}
    >
      <div ref={previewColumnRef} className={classNames}>
        {headerElement}

        {contentElement}

        <PreviewColumnFooter
          disableAskQuestion={disableFooterAskQuestion}
          id={id}
          onClosePreviewColumn={onClosePreviewColumn}
          renderCustomFooterButton={renderCustomFooterButton}
          type={type}
          url={url}
        />
      </div>
    </FocusTrap>
  )
}
