import { Dispatch, SetStateAction } from 'react'
import { z } from 'zod'

import { getRental_rental } from 'Constants/graphql/queries/detail/__generated__/getRental'

import { reassignDeviceModalFormSchema } from './ReassignDeviceModal.schema'

export type ReassignDeviceModalProps = {
  handleSubmit: () => void
  onClose: () => void
  rental: getRental_rental
}

export type ReassignDeviceModalFormProps = {
  rental: getRental_rental
  onClose: () => void
  onSubmit: () => void
  setEmployeeName: Dispatch<SetStateAction<string>>
}

export type ReassignDeviceModalConfirmationProps = {
  employeeName: string
  onClose: () => void
}

export enum ReassignDeviceModalStepsEnum {
  'FORM' = '1',
  'CONFIRMATION' = '2',
}

export type ReassignDeviceModalStepsMap = {
  [key: string]: {
    component: React.FunctionComponent<any>
    props: any
  }
}

export type ReassignDeviceModalFormSchema = z.infer<
  typeof reassignDeviceModalFormSchema
>
