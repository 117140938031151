import { useContext } from 'react'

import { InstantsearchIndexContext } from 'Portal/src/providers/InstantsearchIndexProvider/InstantsearchIndexProvider'

export const useInstantsearchIndexName = () => {
  const indexName = useContext(InstantsearchIndexContext)

  if (!indexName) {
    throw new Error(
      'useInstantsearchIndexName must be used within an InstantsearchIndexProvider',
    )
  }

  return indexName
}
