import { MutableRefObject } from 'react'

import {
  ModalDeviceReplacementFormProps,
  ModalDeviceReplacementFormSchema,
} from 'Components/ModalDeviceReplacementForm/ModalDeviceReplacementForm.types'
import { ModalSummaryProps } from 'Components/ModalSummary/ModalSummary.types'
import { ShippingAddressFormSchema } from 'Components/ShippingAddressForm/ShippingAddressForm.types'

import type { getRental_rental } from 'Constants/graphql/queries/detail/__generated__/getRental'

export enum ReplaceBrokenDeviceModalStepsEnum {
  LANDING = 'LANDING',
  SHIPPING = 'SHIPPING',
  REPLACEMENT_FORM = 'REPLACEMENT_FORM',
  SUMMARY = 'SUMMARY',
  CONFIRMATION = 'CONFIRMATION',
}

export type ReplaceBrokenDeviceModalStepsMap = {
  [key: string]: {
    component: React.FunctionComponent<any>
    props:
      | ReplaceBrokenDeviceLandingProps
      | ReplaceBrokenDeviceShippingFormProps
      | ModalDeviceReplacementFormProps
      | ModalSummaryProps
      | ReplaceBrokenDeviceConfirmationProps
  }
}

export type ReplaceBrokenDeviceModalProps = {
  rental: getRental_rental
  isShown: boolean
  isReplaceFromDepot: boolean
  onClose: () => void
  onSubmit: () => void
  setOnAfterClose: (onAfterClose: () => void) => void
}

export type ReplaceBrokenDeviceLandingProps = {
  isReplacementDeviceAvailableInDepot: boolean
  onSetCurrentStep: (step: `${ReplaceBrokenDeviceModalStepsEnum}`) => void
  heading?: JSX.Element
}

export type ReplaceBrokenDeviceShippingFormProps = {
  isFirstStep: boolean
  rental: getRental_rental
  shippingFormData: ShippingAddressFormSchema | null
  onPreviousStep: () => void
  onSetCurrentStep: (
    step: `${ReplaceBrokenDeviceModalStepsEnum}`,
    shippingData?: ShippingAddressFormSchema,
    replacementData?: ModalDeviceReplacementFormSchema,
  ) => void
  heading?: JSX.Element
}

export type ReplaceBrokenDeviceConfirmationProps = {
  isTelekomTheme: boolean
  requestIdRef: MutableRefObject<string | null>
  onClose: () => void
  closeButtonE2ESelector?: string
}
