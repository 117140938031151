import {
  IconDefinition,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core'

export const iconUpgradeDevice: IconDefinition = {
  icon: [
    32,
    32,
    [],
    'e014',
    'M9.085 0.078 C 8.044 0.269,7.234 0.708,6.507 1.475 C 6.023 1.986,5.760 2.421,5.532 3.093 L 5.360 3.600 5.360 16.000 L 5.360 28.400 5.532 28.907 C 5.778 29.631,6.030 30.031,6.613 30.618 C 7.349 31.361,8.169 31.787,9.157 31.941 C 9.695 32.024,22.305 32.024,22.843 31.941 C 23.831 31.787,24.651 31.361,25.387 30.618 C 25.970 30.031,26.222 29.631,26.468 28.907 L 26.640 28.400 26.640 16.000 L 26.640 3.600 26.468 3.093 C 26.240 2.421,25.977 1.986,25.493 1.475 C 24.870 0.817,24.175 0.400,23.280 0.148 L 22.853 0.028 16.160 0.018 C 10.833 0.010,9.389 0.023,9.085 0.078 M24.000 15.973 L 24.000 27.573 16.000 27.573 L 8.000 27.573 8.000 15.973 L 8.000 4.373 16.000 4.373 L 24.000 4.373 24.000 15.973 M14.013 9.960 C 12.935 11.038,12.053 11.938,12.053 11.960 C 12.053 11.982,12.653 12.000,13.386 12.000 L 14.719 12.000 14.733 15.400 L 14.747 18.800 16.000 18.800 L 17.253 18.800 17.267 15.400 L 17.281 12.000 18.614 12.000 C 19.347 12.000,19.947 11.982,19.947 11.960 C 19.947 11.916,16.043 8.000,15.999 8.000 C 15.985 8.000,15.091 8.882,14.013 9.960 M12.000 22.667 L 12.000 24.000 16.000 24.000 L 20.000 24.000 20.000 22.667 L 20.000 21.333 16.000 21.333 L 12.000 21.333 12.000 22.667',
  ],
  iconName: 'upgradeDevice' as IconName,
  prefix: 'fac' as IconPrefix,
}
