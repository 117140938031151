import { getEmployee_employee } from 'Constants/graphql/queries/employee/__generated__/getEmployee'

const calculateMonthsBetweenTwoDates = (start: Date, end: Date): number => {
  let months

  months = (end.getFullYear() - start.getFullYear()) * 12
  months -= start.getMonth()
  months += end.getMonth()

  return months <= 0 ? 0 : months
}

export const createIntercom = (employee: getEmployee_employee) => {
  window.Intercom('update', {
    all_devices_assigned:
      employee.rentals?.rentals &&
      employee.rentals.rentals
        .map(rental => rental.stocklistItem?.deviceDefinition?.name)
        .join(', '),
    company: {
      company_id: employee.organisation?.id,
      name: employee.organisation?.fullName ?? '',
    },
    links_active_rentals: employee.rentals?.rentals
      ? employee.rentals?.rentals
          .map(({ id }) => `${window.location.origin}/admin/rental/edit/${id}`)
          .join(' ')
      : '',
    links_open_case_items:
      employee.caseitems?.caseItems &&
      employee.caseitems.caseItems
        .filter(caseItem => {
          if (caseItem.status !== 'done') return caseItem

          return false
        })
        .map(({ id }) => `${window.location.origin}/admin/case-item/edit/${id}`)
        .join(' '),
    minimum_contract_duration:
      employee.rentals?.rentals &&
      employee.rentals.rentals
        .map(({ minimumContractDuration }) => minimumContractDuration)
        .slice(-1)
        .toString(),
    organisation_id: employee.organisation?.id,
    organisation_link: `${window.location.origin}/admin/organisation/organisation/${employee.organisation?.id}`,
    rental_duration: calculateMonthsBetweenTwoDates(
      new Date(
        (employee.rentals?.rentals &&
          employee.rentals.rentals
            .map(({ startAt }) => startAt)
            .slice(-1)
            .toString()) ||
          '',
      ),
      new Date(
        (employee.rentals?.rentals &&
          employee.rentals.rentals
            .map(({ minimumContractDuration }) => minimumContractDuration)
            .slice(-1)
            .toString()) ||
          '',
      ),
    ),
    rental_start_date:
      employee.rentals?.rentals &&
      employee.rentals.rentals
        .map(({ startAt }) => startAt)
        .slice(-1)
        .toString(),
  })
}
