import { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react'

import {
  ERROR_MESSAGE_INVALID_CREDENTIALS,
  ERROR_MESSAGE_LOGIN_FAILED,
  ERROR_MESSAGE_NOT_AUTHORISED,
} from 'Modules/auth/constants/loginErrorCodes'

import { useStore } from 'Stores/index'

import { AuthError, fetchFromApi } from 'Utilities'

import { PortalLogin } from '../PortalLogin/PortalLogin'

export const NewJoinerLogin = observer(() => {
  const { userStore } = useStore()
  const { user } = userStore

  const [errorMessage, setErrorMessage] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const getApiErrorMessage = (error: Error | null) => {
    if (error instanceof AuthError && error.apiErrors) {
      switch (error.apiErrors[0].code) {
        case 'notAuthorized':
          return ERROR_MESSAGE_NOT_AUTHORISED
        case 'invalidCredentials':
          return ERROR_MESSAGE_INVALID_CREDENTIALS
        default:
          return ERROR_MESSAGE_LOGIN_FAILED
      }
    } else {
      return ERROR_MESSAGE_LOGIN_FAILED
    }
  }

  const customLoginSubmit = (identifier: string, token: string) => {
    setIsSubmitting(true)

    fetchFromApi('/auth/token-login', {
      body: JSON.stringify({
        identifier,
        token,
      }),
      method: 'POST',
    })
      .then(async () => {
        localStorage.setItem('newJoiner', 'true')

        // TODO: Get rid of old API
        const user = await fetchFromApi('/auth/user/me')

        if (user.jwt) {
          window.location.href = '/portal/app/new-joiner/'
        } else {
          setErrorMessage(getApiErrorMessage(null))
        }
      })
      .catch(error => {
        setErrorMessage(getApiErrorMessage(error))

        setIsSubmitting(false)
      })
  }

  if (user.jwt) {
    return <Redirect to="/portal/app/new-joiner/" />
  }

  return (
    <PortalLogin
      customErrorMessage={errorMessage}
      customIsSubmitting={isSubmitting}
      customShowSubmit
      customSubmit={customLoginSubmit}
      isNewJoiner
      resetCustomErrorMessage={() => setErrorMessage('')}
    />
  )
})
