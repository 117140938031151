import {
  IconDefinition,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core'

export const iconCancelDevice: IconDefinition = {
  icon: [
    32,
    32,
    [],
    'e002',
    'M1.707 3.067 L 0.881 3.894 2.080 5.093 C 3.238 6.252,3.276 6.297,3.202 6.413 C 2.987 6.749,2.823 7.102,2.749 7.386 C 2.677 7.664,2.667 8.609,2.667 15.185 L 2.667 22.667 1.333 22.667 L 0.000 22.667 0.000 24.667 L 0.000 26.667 11.826 26.667 L 23.653 26.667 25.213 28.226 L 26.773 29.786 27.613 28.946 L 28.453 28.106 15.520 15.173 C 8.407 8.060,2.574 2.240,2.559 2.240 C 2.544 2.240,2.161 2.612,1.707 3.067 M10.427 6.667 L 11.759 8.000 20.546 8.000 L 29.333 8.000 29.333 6.667 L 29.333 5.333 19.214 5.333 L 9.094 5.333 10.427 6.667 M12.507 15.520 L 19.653 22.667 12.493 22.667 L 5.333 22.667 5.333 15.520 C 5.333 11.589,5.339 8.373,5.347 8.373 C 5.354 8.373,8.576 11.589,12.507 15.520 M22.191 10.758 C 22.062 10.804,21.855 10.942,21.733 11.064 C 21.329 11.469,21.333 11.426,21.333 14.690 L 21.333 17.574 22.667 18.907 L 24.000 20.239 24.000 16.786 L 24.000 13.333 26.667 13.333 L 29.333 13.333 29.333 18.000 L 29.333 22.667 27.880 22.667 L 26.427 22.667 28.427 24.667 C 30.403 26.644,30.429 26.667,30.644 26.667 C 30.988 26.667,31.356 26.513,31.601 26.268 C 32.028 25.841,32.000 26.377,32.000 18.667 C 32.000 10.956,32.028 11.492,31.601 11.065 C 31.185 10.649,31.396 10.666,26.644 10.671 C 22.968 10.675,22.396 10.686,22.191 10.758',
  ],
  iconName: 'cancelDevice' as IconName,
  prefix: 'fac' as IconPrefix,
}
