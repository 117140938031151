import { FormattedMessage } from 'react-intl'
import { clsx } from 'clsx'

import { Badge } from 'Components/_theme'

import {
  RENTAL_STATUS_BADGE_COLOR_MAP,
  RENTAL_STATUS_BADGE_E2E_IDS,
  RENTAL_STATUS_BADGE_TRANSLATION_IDS,
  RENTAL_STATUS_BADGE_VARIANT_MAP,
  RentalStatusBadgeProps,
} from './RentalStatusBadge.types'

export const RentalStatusBadge = (props: RentalStatusBadgeProps) => {
  const { className, status } = props

  return (
    <Badge
      className={clsx(className)}
      color={RENTAL_STATUS_BADGE_COLOR_MAP[status]}
      e2eSelector={RENTAL_STATUS_BADGE_E2E_IDS[status]}
      variant={RENTAL_STATUS_BADGE_VARIANT_MAP[status]}
    >
      <FormattedMessage id={RENTAL_STATUS_BADGE_TRANSLATION_IDS[status]} />
    </Badge>
  )
}
