import { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { faUsers } from '@fortawesome/pro-solid-svg-icons'
import { useMediaQuery } from '@react-hookz/web'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import { observer } from 'mobx-react'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { TableCard } from 'Components/TableCard/TableCard'

import { useStore } from 'Stores/index'

import { buttonize, formatDate, getFullContactName } from 'Utilities'

import { PORTAL_BREAKPOINTS } from 'Constants/constants'

import { ExitEmployee } from 'Types/dashboard/organisationDashboard.types'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

export const EmployeeExitCard = observer(() => {
  const { dashboardStore, portalSettingsStore } = useStore()
  const { exitEmployees } = dashboardStore
  const { i18n } = portalSettingsStore

  const { organisationId } = useParams<{
    organisationId?: string
  }>()
  const isMobile = useMediaQuery(`(max-width: ${PORTAL_BREAKPOINTS.LG})`)

  const history = useHistory()

  const handleCellClick = useCallback(
    (
      event:
        | React.KeyboardEvent<HTMLDivElement>
        | React.MouseEvent<HTMLDivElement, MouseEvent>,
      employeeId: number,
    ) => {
      event.preventDefault()

      const path = composePath({
        params: {
          employeeId,
          organisationId,
        },
        path: GLOBAL_ROUTE_PATHS.organisation.employee,
      })

      history.push(path)
    },
    [history, organisationId],
  )

  const renderEmployeeHeader = useCallback(
    () => <FormattedMessage id="Employee" />,
    [],
  )

  const renderExitDateHeader = useCallback(
    () => <FormattedMessage id="Exit_date" />,
    [],
  )

  const renderEmployeeCell = useCallback(
    ({ cell }: CellContext<ExitEmployee, unknown>) => {
      const employee = cell.row.original

      return (
        <div
          {...buttonize(handleCellClick, employee.id)}
          className="d-flex flex-column justify-content-center"
        >
          <div className="text-line-clamp fw-600">
            {getFullContactName(employee)}
          </div>

          <div className="d-flex table-text-subtitle text-muted">
            {employee.email}
          </div>
        </div>
      )
    },
    [handleCellClick],
  )

  const renderExitDateCell = useCallback(
    ({ cell }: CellContext<ExitEmployee, unknown>) => {
      const employee = cell.row.original

      return (
        <div
          {...buttonize(handleCellClick, employee.id)}
          className="d-flex align-items-center"
        >
          <span className="text-ellipsis">{formatDate(employee.exitDate)}</span>
        </div>
      )
    },
    [handleCellClick],
  )

  const columns = useMemo<ColumnDef<ExitEmployee>[]>(() => {
    const tableColumns = [
      {
        cell: renderEmployeeCell,
        header: renderEmployeeHeader,
        id: 'employee',
      },
    ]

    if (!isMobile) {
      tableColumns.splice(1, 0, {
        cell: renderExitDateCell,
        header: renderExitDateHeader,
        id: 'exitDate',
      })
    }

    return tableColumns
  }, [
    isMobile,
    renderEmployeeCell,
    renderEmployeeHeader,
    renderExitDateCell,
    renderExitDateHeader,
  ])

  const data = useMemo<ExitEmployee[]>(
    () => exitEmployees || [],
    [exitEmployees],
  )

  if (!exitEmployees) {
    return null
  }

  return (
    <TableCard
      className="mb-6"
      columns={columns}
      data={data}
      heading="EmployeeExitCard_heading"
      icon={faUsers}
      iconColor="orange"
      tableClassName="TableCard--unreturnedDevices"
      total={exitEmployees.length.toLocaleString(i18n.locale)}
    />
  )
})
