import { useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { GroupBase } from 'react-select'
import { useLazyQuery } from '@apollo/client'

import { Field } from 'Components/Field/Field'
import { FieldTypeEnum } from 'Components/Field/Field.types'
import { SelectOption } from 'Components/Select/Select.types'

import {
  getAvailablePhones,
  getAvailablePhonesVariables,
} from 'Constants/graphql/queries/listing/__generated__/getAvailablePhones'
import { GET_AVAILABLE_DEVICES } from 'Constants/graphql/queries/listing/GetDevices'

import { ReplacementDeviceSelectProps } from './ReplacementDeviceSelect.types'

export const ReplacementDeviceSelect = <
  Option extends SelectOption,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: ReplacementDeviceSelectProps<Option, IsMulti, Group>,
) => {
  const { name, depotId, rentalId, ...rest } = props
  const [deviceOptions, setDeviceOptions] = useState<SelectOption[]>([])
  const { setValue } = useFormContext()

  const [getPhones, { loading: isLoading }] = useLazyQuery<
    getAvailablePhones,
    getAvailablePhonesVariables
  >(GET_AVAILABLE_DEVICES, {
    onCompleted: data => {
      const parsedDevices: SelectOption[] =
        data.rental.replacements?.stocklistItems?.map(stocklistItem => {
          const { deviceDefinition } = stocklistItem
          const label = stocklistItem?.imei
            ? `${deviceDefinition?.name} IMEI ${stocklistItem?.imei}`
            : deviceDefinition?.name || ''

          return {
            label,
            value: stocklistItem.id.toString(),
          }
        }) ?? []

      // select first device on load
      if (parsedDevices.length > 0) {
        setValue(name, parsedDevices[0])
      }

      setDeviceOptions(parsedDevices)
    },
  })

  const defaultNoOptionsMessage = useCallback(
    () => <FormattedMessage id="No_devices_found" />,
    [],
  )

  useEffect(() => {
    if (!depotId || !rentalId) {
      return
    }

    getPhones({
      variables: {
        depotID: depotId,
        rentalID: rentalId,
      },
    })
  }, [depotId, getPhones, rentalId])

  return (
    <Field
      {...rest}
      name={name}
      selectProps={{
        isClearable: false,
        isLoading,
        noOptionsMessage: defaultNoOptionsMessage,
        options: deviceOptions,
      }}
      type={FieldTypeEnum.select}
    />
  )
}
