import { FormattedMessage } from 'react-intl'

import { LinkButton } from 'Components/_theme'
import { FilterBadge } from 'Components/FilterBadge/FilterBadge'

import { ListFiltersProps } from './ListFilters.types'

export const ListFilters = (props: ListFiltersProps) => {
  const { activeFilters, onClearFilter } = props

  return (
    <div className="ListFilters">
      {activeFilters.map(filter => (
        <FilterBadge
          key={filter.name}
          onRemove={() => onClearFilter(filter.name)}
        >
          {filter.name.charAt(0).toUpperCase() + filter.name.slice(1)}
        </FilterBadge>
      ))}

      {!!activeFilters.length && (
        <LinkButton onClick={() => onClearFilter()} size="small">
          <FormattedMessage id="Clear_all" />
        </LinkButton>
      )}
    </div>
  )
}
