import { clsx } from 'clsx'

import { ImageWithFallback } from 'Components/ImageWithFallback/ImageWithFallback'

import { DeviceTypeEnum } from 'Types/deviceTypes'

import { ModalDevicePresentationBlockProps } from './ModalDevicePresentationBlock.types'

export const ModalDevicePresentationBlock = (
  props: ModalDevicePresentationBlockProps,
) => {
  const { children, className, small, rental } = props

  return (
    <div className={clsx(className, 'd-flex')}>
      <div
        className={clsx(
          small
            ? 'ModalDevicePresentationBlock-deviceImage-small'
            : 'ModalDevicePresentationBlock-deviceImage',
          'd-flex justify-content-center align-items-center me-4',
        )}
      >
        <ImageWithFallback
          alt={rental.stocklistItem?.deviceDefinition?.name}
          placeholderType={
            rental.stocklistItem?.deviceDefinition
              ?.productType as DeviceTypeEnum
          }
          src={rental.stocklistItem?.deviceDefinition?.imageURL || ''}
        />
      </div>

      <div className="d-flex flex-column justify-content-center overflow-hidden">
        <div>
          <h5 className="text-line-clamp">
            {rental.stocklistItem?.deviceDefinition?.name}
          </h5>
        </div>

        {children}
      </div>
    </div>
  )
}
