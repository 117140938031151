import { FormattedMessage, useIntl } from 'react-intl'

import { ButtonGroup } from 'Components/_theme'

import { E2E_REASSIGN_DEVICE_SUBMIT } from 'Constants/e2e'

import { ReassignDeviceModalConfirmationProps } from '../ReassignDeviceModal.types'

export const ReassignDeviceModalConfirmation = (
  props: ReassignDeviceModalConfirmationProps,
) => {
  const { employeeName, onClose } = props
  const intl = useIntl()

  return (
    <>
      <p aria-live="polite" className="mt-0 mb-0" role="status">
        <FormattedMessage
          id="ReassignDevice_SubmissionSuccess"
          values={{
            employeeName,
          }}
        />
      </p>

      <ButtonGroup
        rightButton={{
          'data-e2e': E2E_REASSIGN_DEVICE_SUBMIT,
          onClick: onClose,
          text: intl.formatMessage({ id: 'Close' }),
        }}
      />
    </>
  )
}
