import * as Sentry from '@sentry/react'

import { ATTRIBUTE_NAME_TRANSLATION_IDS } from 'Algolia/constants/attributeNames'

export const getTypesenseAttributeTranslationId = (attributeName: string) => {
  const attribute = ATTRIBUTE_NAME_TRANSLATION_IDS[attributeName]

  if (!attribute) {
    Sentry.captureException(
      new Error(
        `getTypesenseAttributeTranslationId: untranslated attributeName returned from the API: ${attributeName}`,
      ),
    )

    return 'typesenseAttribute_unknown_attribute'
  }

  return attribute
}
