import type { ModalConfirmationProps } from 'Components/ModalConfirmation/ModalConfirmation.types'
import type { ModalDeviceReplacementFormProps } from 'Components/ModalDeviceReplacementForm/ModalDeviceReplacementForm.types'
import type { ModalShippingFormProps } from 'Components/ModalShippingForm/ModalShippingForm.types'
import type { ModalSummaryProps } from 'Components/ModalSummary/ModalSummary.types'

import type { getRental_rental } from 'Constants/graphql/queries/detail/__generated__/getRental'

import type { LostOrStolenModalLandingProps } from './components/LostOrStolenModalLanding/LostOrStolenModalLanding.types'

export enum LostOrStolenModalTypesEnum {
  'EMPLOYEE' = 'EMPLOYEE',
  'ORGANISATION' = 'ORGANISATION',
  'ORGANISATION_WITH_DEPOT' = 'ORGANISATION_WITH_DEPOT',
}

export enum LostOrStolenModalStepsEnum {
  'LANDING' = '1',
  'REPLACEMENT_FORM' = '2',
  'SHIPPING_FORM' = '3',
  'SUMMARY' = '4',
  'CONFIRMATION' = '5',
}

export type LostOrStolenModalStepsMap = {
  [key: string]: {
    component: React.FunctionComponent<any>
    props:
      | LostOrStolenModalLandingProps
      | ModalDeviceReplacementFormProps
      | ModalShippingFormProps
      | ModalSummaryProps
      | ModalConfirmationProps
  }
}

export type LostOrStolenModalProps = {
  rental: getRental_rental
  type: `${LostOrStolenModalTypesEnum}`
  isShown: boolean
  onClose: () => void
  onSubmit: () => void
  setOnAfterClose: (onAfterClose: () => void) => void
}
