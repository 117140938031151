import { store } from 'Stores/index'

import { PortalSectionEnum } from 'Constants/portalSection'

import { Return, Returns } from '../pages'
import { ReturnsRoutePathsEnum } from './returnsRouteConfig.types'

export const RETURNS_ROUTES = [
  {
    component: Return,
    onRender: () => {
      const { portalSettingsStore } = store
      const { setCurrentPortalSection } = portalSettingsStore

      setCurrentPortalSection(PortalSectionEnum.Returns)
    },
    path: `${ReturnsRoutePathsEnum.RETURN}`,
  },
  {
    component: Returns,
    onRender: () => {
      const { portalSettingsStore } = store
      const { setCurrentPortalSection } = portalSettingsStore

      setCurrentPortalSection(PortalSectionEnum.Returns)
    },
    path: `${ReturnsRoutePathsEnum.RETURNS}`,
  },
]
