import { FormattedMessage } from 'react-intl'
import { clsx } from 'clsx'

import { Badge } from 'Components/_theme'

import { dashOrSpaceToUnderscore } from 'Utilities'

import {
  RETURN_STATUS_BADGE_COLOR_MAP,
  RETURN_STATUS_BADGE_VARIANT_MAP,
  RETURN_STATUS_TRANSLATION_IDS,
} from 'Constants/returnStatus'

import { ReturnStatusBadgeProps } from './ReturnStatusBadge.types'

export const ReturnStatusBadge = (props: ReturnStatusBadgeProps) => {
  const { className, status } = props
  const formattedStatus = dashOrSpaceToUnderscore(status)

  return (
    <Badge
      className={clsx(className)}
      color={RETURN_STATUS_BADGE_COLOR_MAP[formattedStatus]}
      textWrap
      variant={RETURN_STATUS_BADGE_VARIANT_MAP[formattedStatus]}
    >
      <FormattedMessage id={RETURN_STATUS_TRANSLATION_IDS[formattedStatus]} />
    </Badge>
  )
}
