import { gql } from '@apollo/client'

export const MUTATION_CREATE_OFFER_ORDERS = gql`
  mutation mutationCreateOfferOrders($input: OfferOrdersInput!) {
    createOfferOrders(input: $input) {
      offerOrders {
        caseItems {
          id
          data {
            deviceDefinition {
              id
              name
            }
            requestedRentCategory {
              deviceDefinition {
                id
              }
            }
          }
        }
      }
    }
  }
`
