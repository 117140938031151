import { FormattedMessage, useIntl } from 'react-intl'

import { ButtonGroup } from 'Components/_theme'

import { RequestPurchaseModalInvalidProps } from '../RequestPurchaseModal.types'

export const RequestPurchaseModalInvalid = (
  props: RequestPurchaseModalInvalidProps,
) => {
  const { onHide } = props

  const intl = useIntl()

  return (
    <>
      <span aria-live="assertive" role="alert">
        <p>
          <FormattedMessage id="RequestPurchaseModal_invalidTitle" />
        </p>

        <p>
          <FormattedMessage id="RequestPurchaseModal_invalidDescription" />
        </p>
      </span>

      <ButtonGroup
        rightButton={{
          onClick: onHide,
          text: intl.formatMessage({ id: 'Close' }),
        }}
      />
    </>
  )
}
