import { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'

import { Heading, ProgressBar } from 'Components/_theme'

import { setRouteTitle } from 'Portal/src/router/Router.utils'

import { StepperHeaderProps } from './Stepper.types'

export const StepperHeader = (props: StepperHeaderProps) => {
  const { step, headingLabel, headingSufix, subHeadingLabel, progress } = props
  const intl = useIntl()

  const headingText = intl.formatMessage(
    {
      id: 'ProgressHeader_title',
    },
    {
      step,
      title: intl.formatMessage({
        id: headingLabel,
      }),
    },
  )

  useEffect(() => {
    setRouteTitle(
      `${intl.formatMessage({
        id: 'Order',
      })} ${headingText}${headingSufix ? ` ${headingSufix}` : ''}`,
    )
  }, [headingSufix, headingText, intl])

  return (
    <>
      <Row className="pt-6">
        <Col>
          <Heading as={4} size={1}>
            {headingText}
            {headingSufix && ` ${headingSufix}`}
          </Heading>
        </Col>
      </Row>

      <Row className="Stepper-sub_header mt-2">
        <Col>
          <span>
            {subHeadingLabel && <FormattedMessage id={subHeadingLabel} />}
          </span>
        </Col>
      </Row>

      <Row>
        <Col>
          <ProgressBar className="mx-n6" value={progress ?? 0} />
        </Col>
      </Row>
    </>
  )
}
