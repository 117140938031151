import {
  IconDefinition,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core'

export const iconCancelPhonePlan: IconDefinition = {
  icon: [
    32,
    32,
    [],
    'e003',
    'M10.869 1.940 L 8.965 3.873 10.078 4.984 L 11.190 6.095 12.615 4.648 L 14.040 3.200 19.740 3.200 L 25.440 3.200 25.440 11.774 L 25.440 20.347 27.013 21.920 L 28.587 23.493 28.586 13.200 C 28.585 1.796,28.607 2.524,28.232 1.761 C 27.974 1.237,27.373 0.625,26.877 0.381 C 26.116 0.006,26.654 0.032,19.360 0.019 L 12.773 0.007 10.869 1.940 M4.093 4.094 L 3.253 4.934 4.400 6.080 C 5.031 6.710,5.547 7.250,5.547 7.280 C 5.547 7.310,5.042 7.838,4.426 8.454 L 3.305 9.573 3.319 19.547 L 3.333 29.520 3.458 29.852 C 3.822 30.825,4.616 31.565,5.627 31.875 C 5.932 31.969,6.387 31.973,15.893 31.973 C 26.814 31.973,26.073 31.996,26.823 31.641 C 27.308 31.412,27.945 30.801,28.188 30.334 L 28.346 30.029 28.787 30.467 L 29.228 30.905 30.067 30.066 L 30.906 29.226 17.920 16.240 L 4.933 3.253 4.093 4.094 M15.093 16.773 L 22.320 24.000 21.507 24.000 L 20.693 24.000 20.693 25.600 L 20.693 27.200 22.267 27.200 L 23.840 27.200 23.840 26.361 L 23.840 25.521 24.640 26.320 L 25.440 27.119 25.440 27.959 L 25.440 28.800 15.920 28.800 L 6.400 28.800 6.400 19.868 L 6.400 10.936 7.073 10.242 C 7.444 9.859,7.774 9.547,7.807 9.547 C 7.840 9.547,11.119 12.799,15.093 16.773 M8.053 17.600 L 8.053 20.800 9.600 20.800 L 11.147 20.800 11.147 17.600 L 11.147 14.400 9.600 14.400 L 8.053 14.400 8.053 17.600 M20.693 15.000 L 20.693 15.601 22.267 17.173 L 23.840 18.746 23.840 16.573 L 23.840 14.400 22.267 14.400 L 20.693 14.400 20.693 15.000 M14.347 24.000 L 14.347 27.200 15.920 27.200 L 17.493 27.200 17.493 24.000 L 17.493 20.800 15.920 20.800 L 14.347 20.800 14.347 24.000 M8.053 25.600 L 8.053 27.200 9.600 27.200 L 11.147 27.200 11.147 25.600 L 11.147 24.000 9.600 24.000 L 8.053 24.000 8.053 25.600',
  ],
  iconName: 'cancelPhonePlan' as IconName,
  prefix: 'fac' as IconPrefix,
}
