import { useNumericMenu } from 'react-instantsearch'
import { useIntl } from 'react-intl'

import { Label } from 'Components/_theme'
import { CheckboxButtonGroup } from 'Components/_theme/CheckboxButtonGroup/CheckboxButtonGroup'

import { scrollToTop } from 'Utilities'

import { useCurrentRefinement } from 'Algolia/hooks/useCurrentRefinement/useCurrentRefinement'

import { ToggleFilterProps } from './ToggleFilter.types'

export const ToggleFilter = (props: ToggleFilterProps) => {
  const { filterProps, label, checkboxButtonGroupProps } = props

  const intl = useIntl()

  const { refine } = useNumericMenu({
    // not used but required by the hook
    items: [{ label: 'All' }],
    ...filterProps,
  })
  const currentRefinement = useCurrentRefinement(filterProps.attribute)
  const value: string | undefined = currentRefinement?.[0]?.value?.toString()

  const handleChange = (values: string[]) => {
    scrollToTop()

    const range = { end: values[0], start: values[0] }

    refine(encodeURI(JSON.stringify(range)))
  }

  return (
    <div className="Filter-input-container">
      <Label>{label}</Label>

      <CheckboxButtonGroup
        checkboxButtons={[
          { label: intl.formatMessage({ id: 'Yes' }), value: '1' },
          { label: intl.formatMessage({ id: 'No' }), value: '0' },
        ]}
        isToggle
        onChange={handleChange}
        value={value}
        {...checkboxButtonGroupProps}
      />
    </div>
  )
}
