import { BadgeColor, BadgeVariant } from 'Components/_theme/Badge/Badge.types'

import { E2E_BADGE_NEW_DEVICE, E2E_BADGE_UPGRADE_POSSIBLE } from 'Constants/e2e'

export type RentalStatusBadgeProps = {
  status: RentalStatus
  className?: string
}

export enum RentalStatus {
  upgrade,
  new_device,
}

export const RENTAL_STATUS_BADGE_TRANSLATION_IDS: Record<RentalStatus, string> =
  {
    [RentalStatus.upgrade]: 'Upgrade_possible',
    [RentalStatus.new_device]: 'New_device',
  }

export const RENTAL_STATUS_BADGE_E2E_IDS: Record<RentalStatus, string> = {
  [RentalStatus.upgrade]: E2E_BADGE_UPGRADE_POSSIBLE,
  [RentalStatus.new_device]: E2E_BADGE_NEW_DEVICE,
}

export const RENTAL_STATUS_BADGE_COLOR_MAP: Record<RentalStatus, BadgeColor> = {
  [RentalStatus.new_device]: 'orange',
  [RentalStatus.upgrade]: 'blue',
}

export const RENTAL_STATUS_BADGE_VARIANT_MAP: Record<
  RentalStatus,
  BadgeVariant
> = {
  [RentalStatus.new_device]: 'secondary',
  [RentalStatus.upgrade]: 'outline',
}
