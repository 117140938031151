import { ColumnDef, OnChangeFn, SortingState } from '@tanstack/react-table'

export enum TableListStaticColumnsEnum {
  DETAILS = 'DETAILS',
  ICON = 'ICON',
}

export type TableProps<
  T extends {
    id?: string
    url?: string
  },
> = {
  columns: ColumnDef<T, unknown>[]
  data: T[]
  e2eRowId: string
  className?: string
  isLoading?: boolean
  isSearching?: boolean
  hideArrow?: boolean
  onSortingChange?: OnChangeFn<SortingState>
  sorting?: SortingState
  selectedRowIndex?: number | null
  customNoResultsMessage?: string
}
