import { SyntheticEvent } from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash-es'
import { observer } from 'mobx-react'
import { A11y, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { Card, CardBody, Heading, PrimaryButton } from 'Components/_theme'
import { FormattedCurrency } from 'Components/FormattedCurrency/FormattedCurrency'

import { useActiveUser, useIsOrganisation } from 'Hooks'

import { SLIDER_BREAKPOINTS_5 } from 'Constants/constants'

import placeholderimg from 'Assets/images/everphonebox.png'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import { DashboardPopularDevicesCardProps } from './DashboardPopularDevicesCard.types'

export const DashboardPopularDevicesCard = observer(
  (props: DashboardPopularDevicesCardProps) => {
    const { devices, onChooseDevice } = props
    const { activeOrganisation, activeEmployee } = useActiveUser()
    const isOrganisation = useIsOrganisation()

    const history = useHistory()

    if (isEmpty(devices)) {
      return null
    }

    const path = composePath({
      isOrganisation,
      params: {
        employeeId: activeEmployee?.id,
        organisationId: activeOrganisation?.id,
      },
      paths: {
        employee: GLOBAL_ROUTE_PATHS.employee.orderNew,
        organisation: GLOBAL_ROUTE_PATHS.organisation.orderNew,
      },
    })

    return (
      <Card className="mb-6">
        <CardBody>
          <Row>
            <Col className="dashboard-card-title">
              <Heading as={5} size={2}>
                <FormattedMessage id="Dashboard_most_popular" />
              </Heading>
            </Col>
          </Row>

          <Row className="mt-2 pb-6">
            <Col>
              <Swiper
                breakpoints={SLIDER_BREAKPOINTS_5}
                className="mt-1"
                modules={[Navigation, Pagination, A11y]}
                navigation
                pagination={{ clickable: true }}
                spaceBetween={10}
              >
                {devices?.map(device => (
                  <SwiperSlide key={device.id}>
                    <div className="DashboardDeviceSlide d-flex flex-column">
                      <div className="d-flex justify-content-center pb-4">
                        <Image
                          alt={
                            device.customDeviceName ||
                            device.deviceDefinition.name
                          }
                          className="popular-device-image"
                          fluid
                          onError={(
                            e: SyntheticEvent<HTMLImageElement, Event>,
                          ) => {
                            e.currentTarget.onerror = null
                            e.currentTarget.src = placeholderimg
                          }}
                          src={device.deviceDefinition.imageURL ?? ''}
                        />
                      </div>

                      <div className="popular-device-name">
                        <strong>
                          {device.customDeviceName ||
                            device.deviceDefinition.name}
                        </strong>
                      </div>

                      <div className="mt-2">
                        Preis: <FormattedCurrency value={device.copaymentNet} />
                      </div>

                      <div className="popular-device-color">
                        Verfügbare Farbe: {device.deviceDefinition.color}
                      </div>

                      <div className="mt-2">
                        <PrimaryButton
                          appearance="outline"
                          className="w-100"
                          onClick={() => {
                            onChooseDevice(device)
                            history.push(path)
                          }}
                        >
                          Gerät bestellen
                        </PrimaryButton>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  },
)
