import { action, makeObservable, observable } from 'mobx'

import {
  getPortalConfig,
  getPortalConfig_portalConfigStoreForUserAndOrg,
  getPortalConfigVariables,
} from 'Constants/graphql/queries/detail/__generated__/getPortalConfig'
import { GET_PORTAL_CONFIG } from 'Constants/graphql/queries/detail/GetPortalConfig'

import ApolloLoader from 'Portal/src/ApolloLoader'
import { handleFetchErrorFunctional } from 'Portal/src/utilities/handleFetchErrorFunctional/handleFetchErrorFunctional.util'

import type { RootStore } from '..'

export class PortalConfigStore {
  portalConfig: getPortalConfig_portalConfigStoreForUserAndOrg | null = null

  constructor(private readonly store: RootStore) {
    makeObservable(this, {
      fetchPortalConfig: action,
      portalConfig: observable,
      setPortalConfig: action,
    })
  }

  fetchPortalConfig = async (
    organisationID: string,
    employeeID: string | null,
  ) => {
    const { apolloClient } = ApolloLoader

    if (!apolloClient) return

    try {
      const response = await apolloClient.query<
        getPortalConfig,
        getPortalConfigVariables
      >({
        query: GET_PORTAL_CONFIG,
        variables: {
          employeeID,
          organisationID,
        },
      })

      const {
        data: { portalConfigStoreForUserAndOrg },
      } = response

      this.setPortalConfig(portalConfigStoreForUserAndOrg)

      const { setIsUserwayEnabled } = this.store.portalSettingsStore

      if (portalConfigStoreForUserAndOrg.enableAccessibilityWidget) {
        setIsUserwayEnabled(true)
      } else {
        setIsUserwayEnabled(false)
      }
    } catch (error) {
      this.setPortalConfig(null)
      handleFetchErrorFunctional()(error as Error)
    }
  }

  setPortalConfig = (
    portalConfig: getPortalConfig_portalConfigStoreForUserAndOrg | null,
  ) => {
    this.portalConfig = portalConfig
  }
}
