import { IntlShape } from 'react-intl'

import { UserModeEnum } from 'Stores/portalSettingsStore/portalSettingsStore.types'

import {
  createSelectOption,
  getCityAndStateAbbr,
  getFullContactName,
  parseStreetString,
} from 'Utilities'

import { ADDRESSES, countryISOCodes } from 'Constants/constants'
import { getPortalConfig_portalConfigStoreForUserAndOrg } from 'Constants/graphql/queries/detail/__generated__/getPortalConfig'
import {
  getRental_rental,
  getRental_rental_employee_contact,
  getRental_rental_employee_office,
} from 'Constants/graphql/queries/detail/__generated__/getRental'
import { getOrganisation_organisation } from 'Constants/graphql/queries/organisation/__generated__/getOrganisation'

import { ShippingAddressTypeEnum } from 'Types/address/address.types'

import {
  employeeShippingAddressFormSchema,
  officeShippingAddressFormSchema,
  shippingAddressFormSchema,
  usShippingAddressFormSchema,
  vendingMachineShippingAddressFormSchema,
} from './ShippingAddressForm.schema'
import {
  ShippingAddressFormAddressTypeConfig,
  ShippingAddressFormSchema,
} from './ShippingAddressForm.types'
import { shippingFormDefaultValues } from './ShippingAddressForm.values'

export const getShippingAddressFormSchema = (
  { addressType, country }: ShippingAddressFormSchema,
  isOrganisation: boolean,
) => {
  if (addressType.includes(ShippingAddressTypeEnum.VENDING_MACHINE)) {
    return vendingMachineShippingAddressFormSchema
  }

  if (addressType === ShippingAddressTypeEnum.OFFICE && !isOrganisation) {
    return employeeShippingAddressFormSchema
  }

  if (addressType === ShippingAddressTypeEnum.OFFICE) {
    if (country?.value === countryISOCodes.US) {
      return usShippingAddressFormSchema.merge(officeShippingAddressFormSchema)
    }

    return shippingAddressFormSchema.merge(officeShippingAddressFormSchema)
  }

  if (country?.value === countryISOCodes.US) {
    return usShippingAddressFormSchema
  }

  return shippingAddressFormSchema
}

export const getOrderAddressConfigFlags = (
  userMode: `${UserModeEnum}`,
  portalConfig: getPortalConfig_portalConfigStoreForUserAndOrg | null,
) => {
  // controls default selection
  // if false -> office address
  // if true -> custom address
  const defaultIsCustomAddress = !!portalConfig?.customDeliveryAddress
  // controls whether office option is shown
  const canShipToOffice = !!portalConfig?.shipToOfficeAddress
  // controls whether custom address option is shown
  let canShipToCustomAddress = !!portalConfig?.shipDeviceToCustomAddress

  if (
    !!portalConfig?.isCustomDeliveryAddressForNewJoinersDisabled &&
    userMode === UserModeEnum.NEW_JOINER
  ) {
    canShipToCustomAddress = false
  }

  return { canShipToCustomAddress, canShipToOffice, defaultIsCustomAddress }
}

export const parseAddressTypeRadioButtons = (
  intl: IntlShape,
  portalConfig: getPortalConfig_portalConfigStoreForUserAndOrg | null,
  organisation: getOrganisation_organisation | null,
  countryList: { [k: string]: string },
  rental?: getRental_rental | null,
): ShippingAddressFormAddressTypeConfig[] => {
  const addressTypes: ShippingAddressFormAddressTypeConfig[] = []

  const generateName = (
    employeeOffice: getRental_rental_employee_office | null,
    employeeOfficeName: string | null,
    organisationBillingAddressName: string | null,
    defaultName: string | null,
  ): string => {
    if (employeeOffice) {
      return employeeOfficeName || ''
    }

    if (organisationBillingAddressName) {
      return organisationBillingAddressName
    }

    return defaultName || ''
  }

  const generateNameAddition = (
    employeeOffice: getRental_rental_employee_office | null,
    employeeOfficeNameAddition: string | null,
    employeeContact: getRental_rental_employee_contact | null,
    organisationBillingAddressNameAddition: string | null,
    defaultNameAddition: string | null,
  ): string => {
    if (employeeOffice) {
      if (employeeContact && employeeOfficeNameAddition) {
        return `${employeeOfficeNameAddition} ${getFullContactName(employeeContact, false)}`
      }

      if (!employeeContact && employeeOfficeNameAddition) {
        return employeeOfficeNameAddition
      }

      if (employeeContact && !employeeOfficeNameAddition) {
        return getFullContactName(employeeContact, false) || ''
      }

      return ''
    }

    if (employeeContact && organisationBillingAddressNameAddition) {
      return `${organisationBillingAddressNameAddition} ${getFullContactName(employeeContact, false)}`
    }

    if (employeeContact && !organisationBillingAddressNameAddition) {
      return getFullContactName(employeeContact, false) || ''
    }

    if (!employeeContact && organisationBillingAddressNameAddition) {
      return organisationBillingAddressNameAddition
    }

    return defaultNameAddition || ''
  }

  if (
    rental?.organisation &&
    rental?.employee?.office?.address &&
    portalConfig?.shipToOfficeAddress
  ) {
    const { address } = rental.employee.office

    const { city, state } = getCityAndStateAbbr(address.city)
    const parsedStreet = parseStreetString(address.street || '')
    const countryLabel = address.country ? countryList[address.country] : ''

    addressTypes.push({
      _label: `${rental.organisation.shortName} Office`,
      _vendingMachineId: '0',
      addressType: ShippingAddressTypeEnum.OFFICE,
      city,
      country: createSelectOption({
        label: countryLabel,
        value: address.country,
      }),
      name: generateName(
        rental.employee.office,
        rental.employee.office.address.name,
        rental.organisation.contact?.address?.name || null,
        rental.organisation.fullName,
      ),
      nameAddition: generateNameAddition(
        rental.employee.office,
        rental.employee.office.address.nameAddition,
        rental.employee.contact,
        rental.organisation.contact?.address?.nameAddition || null,
        address.nameAddition,
      ),
      state,
      street: parsedStreet.streetName || '',
      streetAddition: address.streetAddition || '',
      streetNumber: parsedStreet.streetNumber || '',
      usAddress: address.street || '',
      zipcode: address.zipcode || '',
    })
  } else if (
    rental?.organisation?.contact?.address &&
    portalConfig?.shipToOfficeAddress
  ) {
    const { address } = rental.organisation.contact

    const { city, state } = getCityAndStateAbbr(address.city)
    const parsedStreet = parseStreetString(address.street || '')
    const countryLabel = address.country ? countryList[address.country] : ''

    addressTypes.push({
      _label: `${rental.organisation.shortName} Office`,
      _vendingMachineId: '0',
      addressType: ShippingAddressTypeEnum.OFFICE,
      city,
      country: createSelectOption({
        label: countryLabel,
        value: address.country,
      }),
      name: generateName(
        rental.employee?.office || null,
        rental.employee?.office?.address?.name || null,
        rental.organisation.contact.address.name,
        rental.organisation.fullName,
      ),
      nameAddition: generateNameAddition(
        rental.employee?.office || null,
        rental.employee?.office?.address?.nameAddition || null,
        rental.employee?.contact || null,
        rental.organisation.contact.address.nameAddition,
        address.nameAddition,
      ),
      state,
      street: parsedStreet.streetName || '',
      streetAddition: address.streetAddition || '',
      streetNumber: parsedStreet.streetNumber || '',
      usAddress: address.street || '',
      zipcode: address.zipcode || '',
    })
  } else if (
    organisation?.contact?.address &&
    portalConfig?.shipToOfficeAddress
  ) {
    const { address } = organisation.contact

    const { city, state } = getCityAndStateAbbr(address.city)
    const parsedStreet = parseStreetString(address.street || '')
    const countryLabel = address.country ? countryList[address.country] : ''

    addressTypes.push({
      _label: `${organisation.shortName} Office`,
      _vendingMachineId: '0',
      addressType: ShippingAddressTypeEnum.OFFICE,
      city,
      country: createSelectOption({
        label: countryLabel,
        value: address.country,
      }),
      name: generateName(
        rental?.employee?.office || null,
        rental?.employee?.office?.address?.name || null,
        organisation.contact.address.name,
        organisation.fullName,
      ),
      nameAddition: generateNameAddition(
        rental?.employee?.office || null,
        rental?.employee?.office?.address?.nameAddition || null,
        rental?.employee?.contact || null,
        organisation.contact.address.nameAddition,
        address.nameAddition,
      ),
      state,
      street: parsedStreet.streetName || '',
      streetAddition: address.streetAddition || '',
      streetNumber: parsedStreet.streetNumber || '',
      usAddress: address.street || '',
      zipcode: address.zipcode || '',
    })
  }

  if (portalConfig?.shipDeviceToCustomAddress) {
    if (rental?.employee?.contact?.address) {
      const { address } = rental.employee.contact
      const name = address.name
        ? address.name
        : getFullContactName(rental.employee.contact)

      const { city, state } = getCityAndStateAbbr(address.city)
      const parsedStreet = parseStreetString(address.street || '')
      const countryLabel = address.country ? countryList[address.country] : ''

      const customAddress: ShippingAddressFormAddressTypeConfig = {
        _label: intl.formatMessage({
          id: ADDRESSES.CUSTOM_LABEL,
        }),
        _vendingMachineId: '0',
        addressType: ShippingAddressTypeEnum.CUSTOM_ADDRESS,
        city,
        country: createSelectOption({
          label: countryLabel,
          value: address.country,
        }),
        name,
        nameAddition: address.nameAddition || '',
        state,
        street: parsedStreet.streetName || '',
        streetAddition: address.streetAddition || '',
        streetNumber: parsedStreet.streetNumber || '',
        usAddress: address.street || '',
        zipcode: address.zipcode || '',
      }

      addressTypes.push(customAddress)
    } else {
      const customAddress: ShippingAddressFormAddressTypeConfig = {
        ...shippingFormDefaultValues,
        _label: intl.formatMessage({
          id: ADDRESSES.CUSTOM_LABEL,
        }),
      }

      if (portalConfig?.customDeliveryAddress) {
        addressTypes.unshift(customAddress)
      } else {
        addressTypes.push(customAddress)
      }
    }
  }

  return addressTypes
}
