import { Footer } from 'Components/Footer/Footer'
import { ScrollToTop } from 'Components/ScrollToTop/ScrollToTop'

import { UnauthorisedLayoutProps } from './UnauthorisedLayout.types'

export const UnauthorisedLayout = (props: UnauthorisedLayoutProps) => {
  const { children, showFooter } = props

  return (
    <>
      <ScrollToTop />

      {children}

      {showFooter && <Footer />}
    </>
  )
}
