import { store } from 'Stores/index'

import { CustomRouterActions } from 'Portal/src/router/Router.types'

import { OrderRoutePathsEnum } from './orderRouteConfig.types'

const orderOnRouteChange = (prevPath: string, nextPath: string) => {
  if (
    !prevPath.includes(`/${OrderRoutePathsEnum.BASE}/`) ||
    nextPath.includes(`/${OrderRoutePathsEnum.BASE}/`)
  ) {
    return
  }

  const { orderStore, portalSettingsStore } = store
  const { setIsCompactSidebar } = portalSettingsStore
  const { resetAll } = orderStore

  setIsCompactSidebar(false)

  resetAll()
}

export const orderCustomRouterActions: CustomRouterActions = {
  onRouteChange: [orderOnRouteChange],
}
