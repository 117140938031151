import { useCallback } from 'react'
import { useSortBy, UseSortByProps } from 'react-instantsearch'
import { faSort, faSortDown, faSortUp } from '@fortawesome/pro-solid-svg-icons'

import { Heading } from 'Components/_theme'
import { Icon } from 'Components/Icon/Icon'

import { useInstantsearchIndexName } from 'Hooks'

import { buttonize, scrollToTop } from 'Utilities'

import { E2E_SORTABLE_TABLE_HEADER } from 'Constants/e2e'

import { SortableHeadingProps } from './SortableHeading.types'

export const SortableHeading = (props: SortableHeadingProps) => {
  const { columnName, getIsSorted, clearSorting, toggleSorting, ...restProps } =
    props
  const indexName = useInstantsearchIndexName()

  const sortConfig = {
    asc: `${indexName}/sort/${columnName}:asc`,
    default: indexName,
    desc: `${indexName}/sort/${columnName}:desc`,
  }

  const items: UseSortByProps['items'] = [
    { label: '', value: sortConfig.default },
    { label: '', value: sortConfig.desc },
    { label: '', value: sortConfig.asc },
  ]

  const { currentRefinement, refine } = useSortBy({
    items,
  })

  const handleColumnSort = useCallback(() => {
    scrollToTop()

    switch (currentRefinement) {
      case sortConfig.default:
        refine(sortConfig.desc)

        toggleSorting(true)

        break

      case sortConfig.desc:
        refine(sortConfig.asc)

        toggleSorting(false)

        break

      default:
        refine(sortConfig.default)

        clearSorting()
    }
  }, [
    clearSorting,
    currentRefinement,
    refine,
    sortConfig.asc,
    sortConfig.default,
    sortConfig.desc,
    toggleSorting,
  ])

  const renderSortIcon = useCallback(() => {
    const isSorted = getIsSorted()
    let icon = faSort

    if (isSorted === 'asc') {
      icon = faSortUp
    } else if (isSorted === 'desc') {
      icon = faSortDown
    }

    return <Icon className="SortableHeading__icon ms-2" icon={icon} size={16} />
  }, [getIsSorted])

  return (
    <div
      className="SortableHeading"
      {...buttonize(handleColumnSort)}
      data-e2e={E2E_SORTABLE_TABLE_HEADER}
    >
      <Heading {...restProps} />

      {renderSortIcon()}
    </div>
  )
}
