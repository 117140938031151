import { useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { LinkButton, PrimaryButton } from 'Components/_theme'

import { openIntercomChat } from 'Utilities'

import { E2E_LINK_CONTACT } from 'Constants/e2e'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import { OrderIntercomButtonProps } from './OrderIntercomButton.types'

export const OrderIntercomButton = (props: OrderIntercomButtonProps) => {
  const { caseItemId } = props
  const intl = useIntl()

  const handleOpenChatButtonClick = useCallback(() => {
    const intercomMessage = intl.formatMessage(
      {
        id: 'PreviewColumn_intercom_intro',
      },
      {
        id: caseItemId,
        type: intl.formatMessage({
          id: 'PreviewColumn_type_request',
        }),
      },
    )

    openIntercomChat(intercomMessage)
  }, [caseItemId, intl])

  return (
    <>
      <div className="d-flex justify-content-end align-items-center w-100 mb-1">
        <p className="mb-0">
          <FormattedMessage id="OrderIntercomButton_Have_a_question" />
        </p>

        <PrimaryButton
          className="ms-2"
          onClick={handleOpenChatButtonClick}
          size="small"
        >
          <FormattedMessage id="OrderIntercomButton_Open_chat" />
        </PrimaryButton>
      </div>

      <div className="d-flex justify-content-end text-muted">
        <small className="text-end">
          <FormattedMessage id="OrderIntercomButton_Subtitle" />{' '}
          <LinkButton
            aria-label="contact"
            className="OrderIntercomButton-contact-form-button"
            data-e2e={E2E_LINK_CONTACT}
            to={composePath({
              params: {},
              path: GLOBAL_ROUTE_PATHS.public.contact,
            })}
          >
            <FormattedMessage id="OrderIntercomButton_Contact_form" />
          </LinkButton>
        </small>
      </div>
    </>
  )
}
