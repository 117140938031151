import { Login } from '../pages/Login/Login.page'
import { AuthRoutePathsEnum } from './authRouteConfig.types'

export const AUTH_ROUTES = [
  {
    component: Login,
    onRender: () => {
      localStorage.removeItem('theme')
      document.documentElement.removeAttribute('data-theme')
    },
    path: `${AuthRoutePathsEnum.LOGIN}`,
  },
  {
    component: Login,
    onRender: () => {
      localStorage.setItem('theme', 'telekom')
      document.documentElement.setAttribute('data-theme', 'telekom')
    },
    path: `${AuthRoutePathsEnum.TELEKOM}`,
  },
]
