import { isEmpty } from 'lodash-es'

import { Badge } from 'Components/_theme'
import { Icon } from 'Components/Icon/Icon'

import { dashOrSpaceToUnderscore, getOrderDisplayName } from 'Utilities'

import {
  ORDER_STATUS_BADGE_COLOR_MAP,
  ORDER_STATUS_TRANSLATION_IDS,
} from 'Constants/orderStatus'
import { PORTAL_CONTENT_MODEL } from 'Constants/portalContentModel'
import {
  RETURN_STATUS_BADGE_COLOR_MAP,
  RETURN_STATUS_TRANSLATION_IDS,
} from 'Constants/returnStatus'

import { GS_AUTOCOMPLETE_DIRECT_FIELDS } from './GlobalSearch.constants'
import { GlobalSearchAutocompleteItemProps } from './GlobalSearch.types'
import {
  parseOrderDisplay,
  parseReturnCaseDisplay,
  stripHtmlFromString,
} from './GlobalSearch.utils'

export const GlobalSearchAutocompleteItem = (
  props: GlobalSearchAutocompleteItemProps,
) => {
  const {
    suggestion,
    onSuggestionItemClickHandler,
    onDirectItemClickHandler,
    intl,
  } = props
  const { type, snippet, highlights, display, category } = suggestion
  const { formatMessage } = intl

  const renderSuggestionTemplate = () => {
    if (onSuggestionItemClickHandler) {
      const categoryIcon = PORTAL_CONTENT_MODEL[category].icon

      return (
        <button
          aria-label={stripHtmlFromString(snippet)}
          className="gs-autocomplete-suggestion-item"
          onClick={onSuggestionItemClickHandler}
          type="button"
        >
          <Icon className="px-1" icon={categoryIcon} />

          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: snippet }}
            className="gs-autocomplete-suggestion-item__primary-display"
          />
        </button>
      )
    }

    return (
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: snippet }}
        aria-label={stripHtmlFromString(snippet)}
        className="gs-autocomplete-suggestion-item"
      />
    )
  }

  const renderDirectItemTemplate = () => {
    if (isEmpty(highlights)) {
      return renderSuggestionTemplate()
    }

    const [highlight] = highlights
    const highlightItemType =
      GS_AUTOCOMPLETE_DIRECT_FIELDS[highlight.field] ??
      highlight.field
        .split('.')
        .splice(-1)[0]
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')

    const categoryIcon = PORTAL_CONTENT_MODEL[category].icon

    const translatedHighlightType = intl.formatMessage({
      defaultMessage: highlightItemType,
      id: highlightItemType,
    })

    return (
      <button
        aria-label={stripHtmlFromString(snippet)}
        className="gs-autocomplete-direct-item d-flex align-items-center"
        onClick={onDirectItemClickHandler}
        type="button"
      >
        <Icon className="px-1" icon={categoryIcon} />

        <div className="d-flex flex-column w-100">
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: snippet }}
            className="gs-autocomplete-direct-item__primary-display"
          />

          {highlight.snippet && (
            <div className="gs-autocomplete-direct-item__secondary-display">
              {translatedHighlightType}:{' '}
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: highlight.snippet }}
              />
            </div>
          )}
        </div>
      </button>
    )
  }

  // always direct, ignore highlights
  const renderOrderItemTemplate = () => {
    const parsedDisplay = parseOrderDisplay(display)
    const displayName = getOrderDisplayName(
      parsedDisplay.type,
      parsedDisplay.name,
    )
    const formattedStatus = dashOrSpaceToUnderscore(parsedDisplay.status)

    const renderDisplayName = () => {
      if (typeof displayName === 'string') {
        return formatMessage({ id: displayName })
      }

      return `${formatMessage({ id: displayName.type })}: ${displayName.name}`
    }

    return (
      <button
        aria-label={stripHtmlFromString(parsedDisplay.highlight)}
        className="gs-autocomplete-direct-item d-flex align-items-center"
        onClick={onDirectItemClickHandler}
        type="button"
      >
        <div className="d-flex flex-column w-100">
          <div className="d-flex align-items-center justify-content-between">
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: renderDisplayName() }}
              className="gs-autocomplete-direct-item__primary-display"
            />

            <Badge
              className="me-1"
              color={ORDER_STATUS_BADGE_COLOR_MAP[formattedStatus]}
            >
              {formatMessage({
                id: ORDER_STATUS_TRANSLATION_IDS[formattedStatus],
              })}
            </Badge>
          </div>

          <div className="gs-autocomplete-direct-item__secondary-display">
            {formatMessage({ id: 'Request_id' })}:{' '}
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: parsedDisplay.highlight }}
            />
          </div>
        </div>
      </button>
    )
  }

  const renderReturnCaseItemTemplate = () => {
    const parsedDisplay = parseReturnCaseDisplay(display)
    const displayName = `${formatMessage({ id: 'Returns' })}: ${
      parsedDisplay.name
    }`
    const formattedStatus = dashOrSpaceToUnderscore(parsedDisplay.status)

    return (
      <button
        aria-label={stripHtmlFromString(parsedDisplay.highlight)}
        className="gs-autocomplete-direct-item d-flex align-items-center"
        onClick={onDirectItemClickHandler}
        type="button"
      >
        <div className="d-flex flex-column w-100">
          <div className="d-flex align-items-center justify-content-between">
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: displayName }}
              className="gs-autocomplete-direct-item__primary-display"
            />

            <Badge
              className="me-1"
              color={RETURN_STATUS_BADGE_COLOR_MAP[formattedStatus]}
            >
              {formatMessage({
                id: RETURN_STATUS_TRANSLATION_IDS[formattedStatus],
              })}
            </Badge>
          </div>

          <div className="gs-autocomplete-direct-item__secondary-display">
            {formatMessage({ id: 'Return_id' })}:{' '}
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: parsedDisplay.highlight }}
            />
          </div>
        </div>
      </button>
    )
  }

  const renderTemplate = () => {
    if (type === 'direct' && category === 'return_cases') {
      return renderReturnCaseItemTemplate()
    }

    if (type === 'direct' && category === 'caseitems') {
      return renderOrderItemTemplate()
    }

    if (type === 'direct') {
      return renderDirectItemTemplate()
    }

    return renderSuggestionTemplate()
  }

  return renderTemplate()
}
