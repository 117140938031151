import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { ApolloError, useMutation } from '@apollo/client'
import { observer } from 'mobx-react'

import { Card, CardHeader } from 'Components/_theme'
import { ModalReplacementForm } from 'Components/ModalDeviceReplacementForm/ModalDeviceReplacementForm'
import { ModalDeviceReplacementFormSchema } from 'Components/ModalDeviceReplacementForm/ModalDeviceReplacementForm.types'
import { modalDeviceReplacementFormDefaultValues } from 'Components/ModalDeviceReplacementForm/ModalDeviceReplacementForm.values'
import { ModalSummary } from 'Components/ModalSummary/ModalSummary'
import { ShippingAddressFormSchema } from 'Components/ShippingAddressForm/ShippingAddressForm.types'

import { useStore } from 'Stores/index'

import { useActiveUser, useTheme } from 'Hooks'

import {
  E2E_REPLACE_BROKEN_DEVICE_BUTTON_BACK,
  E2E_REPLACE_BROKEN_DEVICE_BUTTON_CLOSE,
  E2E_REPLACE_BROKEN_DEVICE_BUTTON_SUBMIT,
  E2E_REPLACE_BROKEN_DEVICE_DEPOT,
  E2E_REPLACE_BROKEN_DEVICE_NEXT_DEVICE,
  E2E_REPLACE_BROKEN_DEVICE_PREVIOUS_DEVICE,
  E2E_REPLACE_BROKEN_DEVICE_SUBMIT,
} from 'Constants/e2e'
import {
  mutationReplaceBrokenDevice,
  mutationReplaceBrokenDeviceVariables,
} from 'Constants/graphql/mutations/__generated__/mutationReplaceBrokenDevice'
import { MUTATION_REPLACE_BROKEN_DEVICE } from 'Constants/graphql/mutations/MutationReplaceBrokenDevice'

import { iconRepairDevice } from 'Icons/iconRepairDevice'

import { ReplacementType } from 'Portal/__generated__/globalTypes'
import { formatShippingAddressDataBeforeSubmit } from 'Portal/src/utilities'

import { ReplaceBrokenDeviceModalConfirmation } from './components/ReplaceBrokenDeviceModalConfirmation/ReplaceBrokenDeviceModalConfirmation'
import { ReplaceBrokenDeviceModalLanding } from './components/ReplaceBrokenDeviceModalLanding/ReplaceBrokenDeviceModalLanding'
import { ReplaceBrokenDeviceShippingForm } from './components/ReplaceBrokenDeviceShippingForm/ReplaceBrokenDeviceShippingForm'
import {
  REPLACE_BROKEN_DEVICE_REPLACEMENT_FORM,
  REPLACE_BROKEN_DEVICE_SUMMARY_FORM,
} from './ReplaceBrokenDeviceModal.constants'
import {
  ReplaceBrokenDeviceModalProps,
  ReplaceBrokenDeviceModalStepsEnum,
  ReplaceBrokenDeviceModalStepsMap,
} from './ReplaceBrokenDeviceModal.types'
import { getReplaceBrokenDeviceModalFirstStep } from './ReplaceBrokenDeviceModal.utils'

export const ReplaceBrokenDeviceModal = observer(
  (props: ReplaceBrokenDeviceModalProps) => {
    const { isReplaceFromDepot, rental, onClose, onSubmit, setOnAfterClose } =
      props

    const { activeEmployee, activeOrganisation } = useActiveUser()
    const organisationID =
      activeEmployee?.organisation?.id || activeOrganisation!.id

    const showDepotReplacement =
      !!activeOrganisation && rental.isReplacementDeviceAvailableInDepot

    const [shippingFormData, setShippingFormData] =
      useState<ShippingAddressFormSchema | null>(null)
    const [replacementFormData, setReplacementFormData] = useState(
      modalDeviceReplacementFormDefaultValues,
    )
    const [currentStep, setCurrentStep] =
      useState<`${ReplaceBrokenDeviceModalStepsEnum}`>(
        getReplaceBrokenDeviceModalFirstStep(
          !!activeOrganisation?.depots?.totalCount,
          isReplaceFromDepot,
        ),
      )
    const [submitError, setSubmitError] = useState<ApolloError | null>(null)
    const requestIdRef = useRef<string | null>(null)

    const { userStore } = useStore()
    const { user } = userStore
    const { isTelekomTheme } = useTheme()

    const [submitReplaceBrokenDevice, { loading: isLoading }] = useMutation<
      mutationReplaceBrokenDevice,
      mutationReplaceBrokenDeviceVariables
    >(MUTATION_REPLACE_BROKEN_DEVICE, {
      onCompleted: data => {
        if (!data.createReplaceBrokenDevice.id) {
          return
        }

        requestIdRef.current = data.createReplaceBrokenDevice.id

        onSubmit()

        setCurrentStep(ReplaceBrokenDeviceModalStepsEnum.CONFIRMATION)
      },
      onError: error => {
        setSubmitError(error)
      },
    })

    const handleSubmitReplaceBrokenDevice = useCallback(() => {
      submitReplaceBrokenDevice({
        variables: {
          input: {
            organisationID,
            rentalID: rental.id,
            replacementType: shippingFormData
              ? ReplacementType.everphone
              : ReplacementType.depot,
            shippingAddress:
              formatShippingAddressDataBeforeSubmit(shippingFormData) || null,
            stocklistItemID: replacementFormData.nextDeviceId?.value,
            userID: user.userId?.toString() ?? '0',
          },
        },
      })
    }, [
      organisationID,
      rental.id,
      replacementFormData.nextDeviceId?.value,
      shippingFormData,
      submitReplaceBrokenDevice,
      user.userId,
    ])

    const handleExitModal = useCallback(() => {
      setCurrentStep(ReplaceBrokenDeviceModalStepsEnum.LANDING)
      // reset form state
      setShippingFormData(null)
      setReplacementFormData(modalDeviceReplacementFormDefaultValues)
      setSubmitError(null)
      requestIdRef.current = null
    }, [])

    const handleSetCurrentStep = useCallback(
      (
        step: `${ReplaceBrokenDeviceModalStepsEnum}`,
        shippingData?: ShippingAddressFormSchema,
        replacementData?: ModalDeviceReplacementFormSchema,
      ) => {
        if (shippingData) {
          setShippingFormData({ ...shippingData })
        }

        if (replacementData) {
          setReplacementFormData({ ...replacementData })
          setShippingFormData(null)
        }

        setCurrentStep(step)
      },
      [],
    )

    const handleShippingFormPreviousStep = useCallback(() => {
      if (showDepotReplacement) {
        handleSetCurrentStep(ReplaceBrokenDeviceModalStepsEnum.LANDING)

        return
      }

      onClose()
    }, [handleSetCurrentStep, onClose, showDepotReplacement])

    const handleReplacementFormPreviousStep = useCallback(() => {
      if (!isReplaceFromDepot) {
        handleSetCurrentStep(ReplaceBrokenDeviceModalStepsEnum.LANDING)

        return
      }

      onClose()
    }, [handleSetCurrentStep, isReplaceFromDepot, onClose])

    const handleReplacementFormSubmit = useCallback(
      (data: ModalDeviceReplacementFormSchema) => {
        handleSetCurrentStep(
          ReplaceBrokenDeviceModalStepsEnum.SUMMARY,
          undefined,
          data,
        )
      },
      [handleSetCurrentStep],
    )

    const handleSummaryPreviousStep = useCallback(() => {
      if (!shippingFormData) {
        setCurrentStep(ReplaceBrokenDeviceModalStepsEnum.LANDING)

        return
      }

      setCurrentStep(ReplaceBrokenDeviceModalStepsEnum.SHIPPING)
    }, [shippingFormData])

    const heading = useMemo(
      () => (
        <CardHeader icon={iconRepairDevice}>
          <FormattedMessage id="ReplaceBrokenDeviceModal_header" />
        </CardHeader>
      ),
      [],
    )

    const stepsMap = useMemo(
      (): ReplaceBrokenDeviceModalStepsMap => ({
        [ReplaceBrokenDeviceModalStepsEnum.LANDING]: {
          component: ReplaceBrokenDeviceModalLanding,
          props: {
            heading,
            isReplacementDeviceAvailableInDepot:
              rental.isReplacementDeviceAvailableInDepot,
            onSetCurrentStep: handleSetCurrentStep,
          },
        },
        [ReplaceBrokenDeviceModalStepsEnum.SHIPPING]: {
          component: ReplaceBrokenDeviceShippingForm,
          props: {
            heading,
            isFirstStep: !showDepotReplacement,
            onPreviousStep: handleShippingFormPreviousStep,
            onSetCurrentStep: handleSetCurrentStep,
            rental,
            shippingFormData,
          },
        },
        [ReplaceBrokenDeviceModalStepsEnum.REPLACEMENT_FORM]: {
          component: ModalReplacementForm,
          props: {
            e2eSelectors: {
              back: E2E_REPLACE_BROKEN_DEVICE_BUTTON_BACK,
              depot: E2E_REPLACE_BROKEN_DEVICE_DEPOT,
              nextDevice: E2E_REPLACE_BROKEN_DEVICE_NEXT_DEVICE,
              previousDevice: E2E_REPLACE_BROKEN_DEVICE_PREVIOUS_DEVICE,
              submit: E2E_REPLACE_BROKEN_DEVICE_BUTTON_SUBMIT,
            },
            formId: REPLACE_BROKEN_DEVICE_REPLACEMENT_FORM,
            heading,
            isReplaceFromDepot,
            onPreviousStep: handleReplacementFormPreviousStep,
            onSubmit: handleReplacementFormSubmit,
            previousDeviceLabel: 'Broken_device',
            rental,
          },
        },
        [ReplaceBrokenDeviceModalStepsEnum.SUMMARY]: {
          component: ModalSummary,
          props: {
            formId: REPLACE_BROKEN_DEVICE_SUMMARY_FORM,
            heading,
            isEverphoneReplacement: !!shippingFormData,
            isLoading,
            leftButtonE2ESelector: E2E_REPLACE_BROKEN_DEVICE_BUTTON_BACK,
            onPreviousStep: handleSummaryPreviousStep,
            onSubmit: handleSubmitReplaceBrokenDevice,
            rental,
            rightButtonE2ESelector: E2E_REPLACE_BROKEN_DEVICE_SUBMIT,
            shippingFormData,
            submitError,
          },
        },
        [ReplaceBrokenDeviceModalStepsEnum.CONFIRMATION]: {
          component: ReplaceBrokenDeviceModalConfirmation,
          props: {
            closeButtonE2ESelector: E2E_REPLACE_BROKEN_DEVICE_BUTTON_CLOSE,
            isTelekomTheme,
            onClose,
            requestIdRef,
          },
        },
      }),
      [
        handleReplacementFormPreviousStep,
        handleReplacementFormSubmit,
        handleSetCurrentStep,
        handleShippingFormPreviousStep,
        handleSubmitReplaceBrokenDevice,
        handleSummaryPreviousStep,
        heading,
        isLoading,
        isReplaceFromDepot,
        isTelekomTheme,
        onClose,
        rental,
        shippingFormData,
        showDepotReplacement,
        submitError,
      ],
    )

    const renderStep = useMemo(() => {
      const { component: Component, props } = stepsMap[currentStep]

      return { component: <Component {...props} /> }
    }, [currentStep, stepsMap])

    useEffect(() => {
      setOnAfterClose(handleExitModal)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleExitModal])

    return <Card>{renderStep.component}</Card>
  },
)
