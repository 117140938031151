import { EmployeeStore } from 'Stores/employeeStore/employeeStore'
import { OrganisationStore } from 'Stores/organisationStore/organisationStore'

import { DEVICE_TYPE } from 'Constants/constants'
import { getOrderEmployee_employee_limitationRules_limitationRule } from 'Constants/graphql/queries/feature/__generated__/getOrderEmployee'

import { OrderDevice } from 'Portal/src/mappers/orderDeviceMapper/orderDevice.types'
import { parseItemTypeForLimitationRules } from 'Portal/src/utilities/parseItemTypeForLimitationRules/parseItemTypeForLimitationRules.util'

import { OrderLimitations } from './orderStore.types'

export const adjustChosenDeviceLimitationRules = (
  limitationRules: OrderLimitations | null,
  chosenDevice: OrderDevice | null,
  mode: 'add' | 'remove',
) => {
  if (!limitationRules || !chosenDevice) {
    return null
  }

  let newLimitationRules: OrderLimitations = {
    ...limitationRules,
  }

  const deviceType = parseItemTypeForLimitationRules(chosenDevice)

  if (mode === 'add') {
    newLimitationRules = {
      ...limitationRules,
      [deviceType]: {
        ...limitationRules[deviceType],
        current: limitationRules[deviceType].current + 1,
      },
    }
  } else {
    newLimitationRules = {
      ...limitationRules,
      [deviceType]: {
        ...limitationRules[deviceType],
        current: limitationRules[deviceType].current - 1,
      },
    }
  }

  return newLimitationRules
}

export const adjustChosenAccessoriesLimitationRules = (
  limitationRules: OrderLimitations | null,
  chosenAccessories: Map<string, OrderDevice>,
  mode: 'add' | 'remove',
) => {
  if (!limitationRules) {
    return null
  }

  let newLimitationRules: OrderLimitations = {
    ...limitationRules,
  }

  for (const [_, accessory] of chosenAccessories) {
    const accessoryType = parseItemTypeForLimitationRules(accessory)

    if (mode === 'add') {
      newLimitationRules = {
        ...newLimitationRules,
        [accessoryType]: {
          ...newLimitationRules[accessoryType],
          current: newLimitationRules[accessoryType].current + 1,
        },
      }
    } else {
      newLimitationRules = {
        ...newLimitationRules,
        [accessoryType]: {
          ...newLimitationRules[accessoryType],
          current: newLimitationRules[accessoryType].current - 1,
        },
      }
    }
  }

  return newLimitationRules
}

export const adjustChosenAccessoryLimitationRules = (
  limitationRules: OrderLimitations | null,
  accessory: OrderDevice,
  mode: 'add' | 'remove',
) => {
  if (!limitationRules) {
    return null
  }

  let newLimitationRules: OrderLimitations = {
    ...limitationRules,
  }

  const productType = parseItemTypeForLimitationRules(accessory)

  if (mode === 'add') {
    newLimitationRules = {
      ...limitationRules,
      [productType]: {
        ...limitationRules[productType],
        current: limitationRules[productType].current + 1,
      },
    }
  } else {
    newLimitationRules = {
      ...limitationRules,
      [productType]: {
        ...limitationRules[productType],
        current: limitationRules[productType].current - 1,
      },
    }
  }

  return newLimitationRules
}

export const createLimitationRules = (
  employeeLimitationRules: getOrderEmployee_employee_limitationRules_limitationRule[],
  upgradeDeviceType?: string | null,
): OrderLimitations => {
  const orderLimitations: OrderLimitations = {
    [DEVICE_TYPE.Laptop]: {
      current: 0,
      initial: 0,
    },
    [DEVICE_TYPE.Tablet]: {
      current: 0,
      initial: 0,
    },
    [DEVICE_TYPE.Smartphone]: {
      current: 0,
      initial: 0,
    },
    [DEVICE_TYPE.Other]: {
      current: 0,
      initial: 0,
    },
  }

  for (const limitation of employeeLimitationRules) {
    if (
      limitation.type !== DEVICE_TYPE.Laptop &&
      limitation.type !== DEVICE_TYPE.Tablet &&
      limitation.type !== DEVICE_TYPE.Smartphone
    ) {
      orderLimitations.other.initial += limitation.available
      orderLimitations.other.current += limitation.available
    } else {
      orderLimitations[limitation.type as keyof OrderLimitations].initial +=
        limitation.available

      orderLimitations[limitation.type as keyof OrderLimitations].current +=
        limitation.available
    }
  }

  if (upgradeDeviceType) {
    orderLimitations[upgradeDeviceType as keyof OrderLimitations].initial += 1
    orderLimitations[upgradeDeviceType as keyof OrderLimitations].current += 1
  }

  return orderLimitations
}

export const getLimitationRules = (
  organisation: OrganisationStore['organisation'],
  employee: EmployeeStore['employee'],
  upgradeDeviceType?: string | null,
): OrderLimitations | null => {
  const isEmployee = !organisation?.id && employee?.id

  const limitationRules = isEmployee && employee.limitationRules?.limitationRule

  if (limitationRules) {
    return createLimitationRules(limitationRules, upgradeDeviceType)
  }

  return null
}
