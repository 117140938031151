import {
  IconDefinition,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core'

export const iconExchangeDevice: IconDefinition = {
  icon: [
    32,
    32,
    [],
    'e006',
    'M9.085 0.078 C 8.044 0.269,7.234 0.708,6.507 1.475 C 6.023 1.986,5.760 2.421,5.532 3.093 L 5.360 3.600 5.360 16.000 L 5.360 28.400 5.532 28.907 C 5.778 29.631,6.030 30.031,6.613 30.618 C 7.349 31.361,8.169 31.787,9.157 31.941 C 9.695 32.024,22.305 32.024,22.843 31.941 C 23.831 31.787,24.651 31.361,25.387 30.618 C 25.970 30.031,26.222 29.631,26.468 28.907 L 26.640 28.400 26.640 16.000 L 26.640 3.600 26.468 3.093 C 26.240 2.421,25.977 1.986,25.493 1.475 C 24.870 0.817,24.175 0.400,23.280 0.148 L 22.853 0.028 16.160 0.018 C 10.833 0.010,9.389 0.023,9.085 0.078 M24.000 15.973 L 24.000 27.573 16.000 27.573 L 8.000 27.573 8.000 15.973 L 8.000 4.373 16.000 4.373 L 24.000 4.373 24.000 15.973 M15.227 10.992 C 14.586 11.085,13.804 11.380,13.241 11.743 L 12.961 11.923 13.807 12.768 L 14.654 13.612 15.017 13.467 C 16.296 12.955,17.826 13.571,18.429 14.840 C 18.598 15.196,18.750 15.899,18.675 15.974 C 18.661 15.988,18.287 16.000,17.845 16.000 L 17.041 16.000 18.447 17.407 L 19.854 18.815 21.228 17.421 L 22.602 16.027 21.841 16.012 L 21.079 15.997 21.016 15.491 C 20.903 14.594,20.651 13.896,20.194 13.209 C 19.737 12.523,18.966 11.836,18.241 11.467 C 17.385 11.031,16.224 10.849,15.227 10.992 M10.760 14.547 C 10.004 15.303,9.387 15.939,9.387 15.960 C 9.387 15.982,9.743 16.000,10.179 16.000 L 10.971 16.000 11.003 16.467 C 11.061 17.312,11.423 18.281,11.942 18.980 C 13.485 21.061,16.278 21.663,18.535 20.401 C 18.722 20.296,18.887 20.192,18.901 20.169 C 18.916 20.145,18.550 19.749,18.089 19.289 L 17.250 18.451 16.932 18.563 C 16.016 18.886,14.941 18.673,14.221 18.027 C 13.717 17.574,13.333 16.799,13.333 16.234 L 13.333 16.000 14.133 16.000 C 14.573 16.000,14.933 15.982,14.933 15.960 C 14.933 15.916,12.203 13.173,12.159 13.173 C 12.145 13.173,11.515 13.792,10.760 14.547',
  ],
  iconName: 'exchangeDevice' as IconName,
  prefix: 'fac' as IconPrefix,
}
