import { FormattedMessage } from 'react-intl'

import { FormatDate } from 'Components/FormatDate/FormatDate'

import { getCaseItemTypeTranslationId, getFullContactName } from 'Utilities'

import { getReturnCasePreviewColumn_returnCaseItem } from 'Constants/graphql/queries/detail/__generated__/getReturnCasePreviewColumn'

export const generateReturnsPreviewColumnContent = (
  returnCase?: getReturnCasePreviewColumn_returnCaseItem,
  isOrganisation?: boolean,
) => [
  {
    copyable: true,
    label: 'PreviewColumn_imei_1',
    value: returnCase?.stocklistItem?.imei,
  },
  {
    copyable: true,
    label: 'PreviewColumn_imei_2',
    value: returnCase?.stocklistItem?.imei2,
  },
  {
    copyable: true,
    label: 'PreviewColumn_serial',
    value: returnCase?.stocklistItem?.serialNumber,
  },
  {
    label: 'PreviewColumn_created',
    value: <FormatDate value={returnCase?.createdAt} />,
  },
  ...(isOrganisation && returnCase?.employee?.contact
    ? [
        {
          label: 'PreviewColumn_employee',
          value: getFullContactName(returnCase?.employee?.contact),
        },
      ]
    : []),
  {
    label: 'PreviewColumn_return_completed',
    value: <FormatDate value={returnCase?.closedAt} />,
  },
  {
    label: 'PreviewColumn_start_of_rental',
    value: <FormatDate value={returnCase?.rental?.startAt} />,
  },
  {
    label: 'PreviewColumn_reason',
    value: returnCase?.caseItem?.caseType ? (
      <FormattedMessage
        id={getCaseItemTypeTranslationId(returnCase.caseItem.caseType)}
      />
    ) : (
      '-'
    ),
  },
]
