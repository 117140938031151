import { germanStates } from 'Constants/germanStates'

export const NetPriceCalculatorDefaultValues = {
  calculationVisible: false,
  childAllowanceValue: 0,
  churchTaxValue: 0,
  collapse: true,
  healthValue: '1.3',
  salaryValue: '',
  stateValue: germanStates['Baden-Wuerttemberg'],
  taxClassValue: 1,
}
