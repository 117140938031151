import { clsx } from 'clsx'

import { CardProps } from 'Components/_theme/Card/Card.types'

export const Card = (props: CardProps) => {
  const { children, className } = props

  const classNames = clsx('Card', className)

  return <div className={classNames}>{children}</div>
}
