/* eslint-disable react/no-danger */
import { useCallback, useEffect, useMemo } from 'react'
import { Container, Image } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { clsx } from 'clsx'
import { observer } from 'mobx-react'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { DashboardBenefitsCard } from 'Modules/dashboard/components/DashboardBenefitsCard/DashboardBenefitsCard'
import { DashboardDevices } from 'Modules/dashboard/components/DashboardDevices/DashboardDevices'
import { DashboardHeaderCard } from 'Modules/dashboard/components/DashboardHeaderCard/DashboardHeaderCard'
import { DashboardPopularDevicesCard } from 'Modules/dashboard/components/DashboardPopularDevicesCard/DashboardPopularDevicesCard'

import { PageErrorState } from 'Components/PageErrorState/PageErrorState'
import { PageHeader } from 'Components/PageHeader/PageHeader'
import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'

import { useStore } from 'Stores/index'

import { useActiveUser } from 'Hooks'

import { isActiveLanguage } from 'Utilities'

import {
  getEmployeeDashboard,
  getEmployeeDashboardVariables,
} from 'Constants/graphql/queries/employee/__generated__/getEmployeeDashboard'
import { GET_EMPLOYEE_DASHBOARD } from 'Constants/graphql/queries/employee/getEmployee'
import { SupportedLanguagesEnum } from 'Constants/locale'
import { PortalSectionEnum } from 'Constants/portalSection'

import everphonePlusSplash from 'Assets/images/everphoneplus/dashboardsplash.png'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import { EMPLOYEE_DASHBOARD_PLUS_BENEFITS_SLIDES } from './EmployeeDashboardPlus.constants'

export const EmployeeDashboardPlus = observer(() => {
  const { portalSettingsStore, portalContentStore, orderStore } = useStore()
  const { setCurrentPortalSection } = portalSettingsStore
  const { portalContent } = portalContentStore
  const { orderDevices: popularDevices, setChosenDevice } = orderStore

  const { activeEmployee } = useActiveUser()
  const history = useHistory()
  const intl = useIntl()
  const isGermanLanguageActive = isActiveLanguage(SupportedLanguagesEnum.DE)
  const { employeeId } = useParams<{ employeeId: string }>()

  const {
    data,
    loading: isLoading,
    error,
  } = useQuery<getEmployeeDashboard, getEmployeeDashboardVariables>(
    GET_EMPLOYEE_DASHBOARD,
    {
      variables: { id: activeEmployee?.id || '' },
    },
  )

  useEffect(() => {
    setCurrentPortalSection(PortalSectionEnum.Dashboard)
  }, [setCurrentPortalSection])

  const renderHeader = useMemo(() => {
    if (isGermanLanguageActive) {
      if (portalContent?.dashboardHeaderDE) {
        return <span>{portalContent.dashboardHeaderDE}</span>
      }
    } else if (portalContent?.dashboardHeaderEN) {
      return <span>{portalContent.dashboardHeaderEN}</span>
    }

    return <FormattedMessage id="EverphonePlusDashboard_title" />
  }, [
    isGermanLanguageActive,
    portalContent?.dashboardHeaderDE,
    portalContent?.dashboardHeaderEN,
  ])

  const renderSubtitle = useMemo(() => {
    if (isGermanLanguageActive) {
      if (portalContent?.dashboardSubtitleDE) {
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: portalContent.dashboardSubtitleDE,
            }}
          />
        )
      }
    } else if (portalContent?.dashboardSubtitleEN) {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: portalContent.dashboardSubtitleEN,
          }}
        />
      )
    }

    return <FormattedMessage id="EverphonePlusDashboard_title_content" />
  }, [
    isGermanLanguageActive,
    portalContent?.dashboardSubtitleDE,
    portalContent?.dashboardSubtitleEN,
  ])

  const renderButtonLabel = useMemo(() => {
    if (isGermanLanguageActive) {
      if (portalContent?.dashboardButtonDE) {
        return portalContent.dashboardButtonDE
      }
    } else if (portalContent?.dashboardButtonEN) {
      return portalContent.dashboardButtonEN
    }

    return intl.formatMessage({ id: 'EverphonePlusDashboard_see_offers' })
  }, [isGermanLanguageActive, portalContent, intl])

  const renderImage = useMemo(
    () => (
      <Image
        alt="Everphone plus"
        className={clsx(
          portalContent?.dashboardImageURL
            ? 'dashboard-splash-image-custom'
            : 'dashboard-splash-image',
        )}
        fluid
        src={portalContent?.dashboardImageURL || everphonePlusSplash}
      />
    ),
    [portalContent?.dashboardImageURL],
  )

  const handleNewOrderButtonClick = useCallback(() => {
    history.push(
      composePath({
        params: { employeeId },
        path: GLOBAL_ROUTE_PATHS.employee.orderNew,
      }),
    )
  }, [employeeId, history])

  if (isLoading) {
    return <PageLoadingState />
  }

  if (error) {
    return <PageErrorState />
  }

  if (!data) {
    return <PageErrorState />
  }

  const { employee } = data

  const headerContent = [
    {
      buttonLabel: renderButtonLabel,
      header: renderHeader,
      id: 0,
      image: renderImage,
      onClick: handleNewOrderButtonClick,
      showButton: true,
      subtitle: renderSubtitle,
    },
  ]

  return (
    <Container className="dashboard pb-9 pt-6" fluid>
      <div role="banner">
        <PageHeader portalSection={PortalSectionEnum.Dashboard} />
      </div>

      <DashboardHeaderCard content={headerContent} />

      <DashboardDevices
        caseItems={employee.caseitems}
        header="DevicesCard_devices_header"
        rentals={employee.rentals?.rentals}
      />

      <DashboardBenefitsCard
        slideProps={EMPLOYEE_DASHBOARD_PLUS_BENEFITS_SLIDES}
      />

      {/* TODO: confirm which devices should be shown here and unhide
          component */}
      {false && (
        <DashboardPopularDevicesCard
          devices={popularDevices?.allDevices || null}
          onChooseDevice={setChosenDevice}
        />
      )}
    </Container>
  )
})
