export const CHART_FONT = {
  family: "'Titillium Web', Arial, sans-serif",
  size: 12,
}

export const CHART_COLORS = [
  '#003594',
  '#BA61FF',
  '#E3224E',
  '#FF8800',
  '#197B78',
  '#C7E3FC',
  '#FAEE82',
  '#FFC7CC',
  '#FDCE84',
  '#AFDECE',
]
