import { useCallback, useMemo, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { NetworkStatus } from '@apollo/client'
import { observer } from 'mobx-react'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { RentalsPreviewColumn } from 'Modules/rentals/components/RentalsPreviewColumn/RentalsPreviewColumn'
import { RentalsTable } from 'Modules/rentals/components/RentalsTable/RentalsTable'

import { Card } from 'Components/_theme'
import { ContentSidebar } from 'Components/ContentSidebar/ContentSidebar'
import { FaqCard } from 'Components/FaqCard/FaqCard'
import { ImpactTracker } from 'Components/ImpactTracker/ImpactTracker'
import { ListSearch } from 'Components/ListSearch/ListSearch'
import { ObserveMeOnceIAmMounted } from 'Components/ObserveMeOnceIAmMounted/ObserveMeOnceIAmMounted'
import { PageErrorState } from 'Components/PageErrorState/PageErrorState'
import { PageHeader } from 'Components/PageHeader/PageHeader'
import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'

import { useStore } from 'Stores/index'
import { OriginRouteTypeEnum } from 'Stores/orderStore/orderStore.types'

import { useActiveUser, useIsOrganisation, useSidebarVisibility } from 'Hooks'

import { toggleIntercomVisibility } from 'Utilities'

import { E2E_NEW_ORDER_BUTTON } from 'Constants/e2e'
import {
  getEmployeeDevices,
  getEmployeeDevicesVariables,
} from 'Constants/graphql/queries/listing/__generated__/getEmployeeDevices'
import {
  getOrganisationDevices,
  getOrganisationDevicesVariables,
} from 'Constants/graphql/queries/listing/__generated__/getOrganisationDevices'
import {
  GET_EMPLOYEE_DEVICES,
  GET_ORGANISATION_DEVICES,
} from 'Constants/graphql/queries/listing/GetDevices'
import { PortalSectionEnum } from 'Constants/portalSection'

import { ReportType } from 'Portal/__generated__/globalTypes'
import { useDefaultListTable } from 'Portal/src/hooks/useDefaultListTable/useDefaultListTable'
import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

export const RentalsDefault = observer(() => {
  const { portalConfigStore, orderStore, portalSettingsStore } = useStore()
  const { portalConfig } = portalConfigStore
  const { isAppReadOnly } = portalSettingsStore
  const { setOriginRoute } = orderStore

  const { activeEmployee, activeOrganisation } = useActiveUser()
  const isOrganisation = useIsOrganisation()
  const intl = useIntl()

  const [selectedEntityId, setSelectedEntityId] = useState('')
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null)
  const [isPreviewColumnOpen, setIsPreviewColumnOpen] = useState(false)
  const isSidebarOpen = isPreviewColumnOpen
  const { isVisible: isPreviewColumnVisible } = useSidebarVisibility(
    isPreviewColumnOpen,
    isSidebarOpen,
  )

  const history = useHistory()

  const {
    data,
    error,
    hasNextPage,
    intersectionObserver,
    isLoading,
    networkStatus,
    totalCount,
    refetch,
  } = useDefaultListTable<
    getOrganisationDevices,
    getOrganisationDevicesVariables,
    getEmployeeDevices,
    getEmployeeDevicesVariables
  >({
    dataObjectPropertyName: 'rentals',
    employeeQuery: GET_EMPLOYEE_DEVICES,
    organisationQuery: GET_ORGANISATION_DEVICES,
  })

  const handleOpenPreviewColumn = useCallback(
    (id: string, rowIndex: number | null) => {
      toggleIntercomVisibility(false)

      setSelectedEntityId(id)
      setSelectedRowIndex(rowIndex)
      setIsPreviewColumnOpen(true)
    },
    [],
  )

  const handleClosePreviewColumn = useCallback(() => {
    toggleIntercomVisibility(true)

    setIsPreviewColumnOpen(false)
    setSelectedRowIndex(null)
  }, [])

  const { newSmartphoneButtonIsVisible } = useMemo((): {
    newSmartphoneButtonIsVisible: boolean
  } => {
    if (isOrganisation) {
      return {
        newSmartphoneButtonIsVisible:
          !!portalConfig?.newDevice &&
          !!portalConfig?.showNewDeviceButtonToAdmin &&
          !isAppReadOnly,
      }
    }

    return {
      newSmartphoneButtonIsVisible:
        !!portalConfig?.newDevice &&
        !!activeEmployee?.canOrderNewDevice &&
        !!activeEmployee?.canOrder,
    }
  }, [
    activeEmployee?.canOrder,
    activeEmployee?.canOrderNewDevice,
    isAppReadOnly,
    isOrganisation,
    portalConfig?.newDevice,
    portalConfig?.showNewDeviceButtonToAdmin,
  ])

  const handleSearch = useCallback(
    (value: string) => {
      refetch({
        searchterm: value || null,
      })
    },
    [refetch],
  )

  if (error) {
    return <PageErrorState />
  }

  if (isLoading && !data) return <PageLoadingState />

  if (!data) return <PageErrorState />

  const handleClickNewSmartphoneButton = () => {
    setOriginRoute({
      type: OriginRouteTypeEnum.Rentals,
      url: history.location.pathname,
    })

    const path = composePath({
      isOrganisation,
      params: {
        employeeId: activeEmployee?.id,
        organisationId: activeOrganisation?.id,
      },
      paths: {
        employee: GLOBAL_ROUTE_PATHS.employee.orderNew,
        organisation: GLOBAL_ROUTE_PATHS.organisation.orderNew,
      },
    })

    history.push(path)
  }

  return (
    <Container className="pt-6" fluid>
      <div role="banner">
        <PageHeader portalSection={PortalSectionEnum.Rentals} />
      </div>

      <Row>
        <Col xl={12} xxl={8}>
          <Card className="mb-4">
            <ListSearch
              buttonE2ESelector={E2E_NEW_ORDER_BUTTON}
              buttonLabel={intl.formatMessage({ id: 'New_device' })}
              buttonOnClick={handleClickNewSmartphoneButton}
              buttonVisible={newSmartphoneButtonIsVisible}
              downloadReportType={ReportType.rentals}
              handleSearch={handleSearch}
              totalCount={totalCount}
            />

            <Row>
              <Col>
                <RentalsTable
                  isActiveEmployee={!isOrganisation}
                  isLoading={isLoading}
                  isSearching={networkStatus === NetworkStatus.setVariables}
                  onOpenPreviewColumn={handleOpenPreviewColumn}
                  rentals={data}
                  selectedRowIndex={selectedRowIndex}
                />
              </Col>
            </Row>
          </Card>
          <Row className="p-1">
            <Col>
              {hasNextPage && !isLoading && (
                <ObserveMeOnceIAmMounted
                  intersectionObserver={intersectionObserver}
                />
              )}
            </Col>
          </Row>
        </Col>

        <ContentSidebar
          isSidebarOpen={isSidebarOpen}
          renderStickyColumnSideContent={
            <>
              <ImpactTracker />
              <FaqCard />
            </>
          }
        >
          <RentalsPreviewColumn
            entityId={selectedEntityId}
            isVisible={isPreviewColumnVisible}
            onClosePreviewColumn={handleClosePreviewColumn}
          />
        </ContentSidebar>
      </Row>
    </Container>
  )
})
