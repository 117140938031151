import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { GroupBase } from 'react-select'
import { useQuery } from '@apollo/client'
import { observer } from 'mobx-react'

import { Field } from 'Components/Field/Field'
import { FieldTypeEnum } from 'Components/Field/Field.types'
import { SelectOption } from 'Components/Select/Select.types'

import { formatCurrency } from 'Utilities'

import { getPhonePlanPricing } from 'Constants/graphql/queries/listing/__generated__/getPhonePlanPricing'
import { GET_PHONE_PLAN_PRICING } from 'Constants/graphql/queries/listing/GetPhonePlanPricing'

import { PhonePlansSelectProps } from './PhonePlansSelect.types'

export const PhonePlansSelect = observer(
  <
    Option extends SelectOption,
    IsMulti extends boolean,
    Group extends GroupBase<Option> = GroupBase<Option>,
  >(
    props: PhonePlansSelectProps<Option, IsMulti, Group>,
  ) => {
    const { name, ...rest } = props
    const [phonePlanOptions, setPhonePlanOptions] = useState<SelectOption[]>([])
    const { setValue } = useFormContext()

    const { loading: isLoading } = useQuery<getPhonePlanPricing>(
      GET_PHONE_PLAN_PRICING,
      {
        onCompleted: data => {
          const parsedPhonePlans: SelectOption[] =
            data.phonePlanPricing.phonePlanPricing?.map(phonePlan => {
              const value = `${phonePlan.name} ${
                phonePlan.includedDataVolumeInGb
              }GB (~${formatCurrency(phonePlan.priceForCustomerPortal * 100)})`

              return {
                label: value,
                value,
              }
            }) ?? []

          // select first phone plan on load
          if (parsedPhonePlans.length > 0) {
            setValue(name, parsedPhonePlans[0])
          }

          setPhonePlanOptions(parsedPhonePlans)
        },
      },
    )

    return (
      <Field
        {...rest}
        feedbackText="NewPhonePlanModal_prices_may_vary"
        name={name}
        selectProps={{
          isClearable: false,
          isLoading,
          options: phonePlanOptions,
        }}
        type={FieldTypeEnum.select}
      />
    )
  },
)
