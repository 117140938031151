import { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react'

import { OrderWrapper } from 'Modules/order/components/OrderWrapper/OrderWrapper'
import { ProlongInfoStep } from 'Modules/order/components/Steps/ProlongInfoStep/ProlongInfoStep'
import { OrderFlowTypesEnum } from 'Modules/order/types/orderFlow.types'

import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'
import { Stepper, StepperStep } from 'Components/Stepper'

import { useStore } from 'Stores/index'

import { useActiveUser, useIsOrganisation } from 'Hooks'

export const OrderProlong = observer(() => {
  const intl = useIntl()

  const { rentalStore, orderStore } = useStore()

  const { fetchRentalDetails, rentalDetails } = rentalStore
  const { orderEmployeeName } = orderStore

  const isOrganisation = useIsOrganisation()

  const { activeEmployee, activeOrganisation } = useActiveUser()
  const { employeeId = '', rentalId } = useParams<{
    employeeId?: string
    rentalId: string
  }>()
  const activeEmployeeId = employeeId || activeEmployee?.id.toString()

  useEffect(() => {
    if (rentalDetails?.rental.id.toString() === rentalId) {
      return
    }

    fetchRentalDetails({
      isOrganisationView: isOrganisation,
      rentalID: rentalId,
    })
  }, [rentalId, fetchRentalDetails, isOrganisation, rentalDetails?.rental.id])

  const orderProlongStepsConfig = useMemo(() => {
    const headingSufix =
      !!activeOrganisation && employeeId && orderEmployeeName
        ? `${intl.formatMessage({ id: 'for' })} ${orderEmployeeName}`
        : undefined

    const baseSteps: StepperStep[] = [
      {
        headingLabel: 'OrderProlong_extend_rental_header',
        headingSufix,
        renderStepContent: () => <ProlongInfoStep />,
      },
    ]

    return baseSteps
  }, [activeOrganisation, employeeId, intl, orderEmployeeName])

  if (!rentalDetails) {
    return <PageLoadingState />
  }

  return (
    <OrderWrapper
      employeeId={activeEmployeeId}
      orderFlowType={OrderFlowTypesEnum.PROLONG}
      renderOrderContent={() => <Stepper steps={orderProlongStepsConfig} />}
    />
  )
})
