import {
  IconDefinition,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core'

export const iconGeneralQuestion: IconDefinition = {
  icon: [
    32,
    32,
    [],
    'e007',
    'M2.529 2.745 C 1.897 2.884,1.187 3.335,0.733 3.889 C 0.463 4.218,0.232 4.700,0.107 5.195 C 0.002 5.610,-0.000 5.790,0.000 16.000 C 0.000 26.210,0.002 26.390,0.107 26.805 C 0.278 27.481,0.496 27.860,1.011 28.376 C 1.337 28.702,1.566 28.880,1.816 29.001 C 2.551 29.356,1.511 29.332,16.000 29.332 C 30.487 29.332,29.448 29.356,30.184 29.001 C 30.436 28.879,30.660 28.706,30.987 28.376 C 31.502 27.858,31.724 27.473,31.893 26.805 C 31.998 26.390,32.000 26.210,32.000 16.000 C 32.000 5.790,31.998 5.610,31.893 5.195 C 31.725 4.530,31.502 4.139,31.015 3.651 C 30.587 3.223,30.200 2.974,29.669 2.786 C 29.431 2.701,28.493 2.694,16.133 2.685 C 5.256 2.676,2.795 2.687,2.529 2.745 M22.447 10.144 C 18.352 12.814,16.086 14.261,16.000 14.261 C 15.913 14.261,13.654 12.818,9.552 10.144 L 3.238 6.027 16.000 6.027 L 28.762 6.027 22.447 10.144 M10.160 13.868 C 13.328 15.932,15.956 17.621,16.000 17.621 C 16.044 17.621,18.672 15.932,21.840 13.868 C 25.008 11.804,27.870 9.940,28.200 9.726 L 28.800 9.336 28.800 17.681 L 28.800 26.027 16.000 26.027 L 3.200 26.027 3.200 17.681 L 3.200 9.336 3.800 9.726 C 4.130 9.940,6.992 11.804,10.160 13.868',
  ],
  iconName: 'generalQuestion' as IconName,
  prefix: 'fac' as IconPrefix,
}
