import { observer } from 'mobx-react'

import { useActiveUser } from 'Hooks'

import { InstantsearchProvider } from 'Portal/src/providers'
import { InstantsearchIndexProvider } from 'Portal/src/providers/InstantsearchIndexProvider/InstantsearchIndexProvider'

import { EmployeesContent } from './Employees.content'

export const EmployeesInstantsearch = observer(() => {
  const { activeEmployee, activeOrganisation } = useActiveUser()
  const organisationId =
    activeEmployee?.organisation?.id ?? activeOrganisation!.id

  return (
    <InstantsearchIndexProvider indexName="employees">
      <InstantsearchProvider
        instantSearchConfig={{
          filters: `employee.organisation_id:${organisationId}`,
        }}
        sortBy="employee.id:desc"
      >
        <EmployeesContent />
      </InstantsearchProvider>
    </InstantsearchIndexProvider>
  )
})
