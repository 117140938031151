import { useCallback, useLayoutEffect, useRef } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { faLeaf } from '@fortawesome/pro-solid-svg-icons'
import { clsx } from 'clsx'
import { observer } from 'mobx-react'

import { Heading, SecondaryButton } from 'Components/_theme'
import { FormattedCurrency } from 'Components/FormattedCurrency/FormattedCurrency'
import { ImageWithFallback } from 'Components/ImageWithFallback/ImageWithFallback'
import { mapOrderImpactFactorsToImpactTrackerChartData } from 'Components/ImpactTracker/ImpactTracker.util'
import { ImpactTrackerChart } from 'Components/ImpactTracker/ImpactTrackerChart'
import { ORDER_SELECTION_CARD } from 'Components/OrderSelectionCard/OrderSelectionCard.constants'
import { OrderSelectionCardProps } from 'Components/OrderSelectionCard/OrderSelectionCard.types'

import { useStore } from 'Stores/index'

import { useIsOrganisation } from 'Hooks'

import { buttonize, parseItemTypeForLimitationRules } from 'Utilities'

import { E2E_ORDER_SELECTION_CARD } from 'Constants/e2e'
import { getOrderOrganisation_organisation_rentCategories_rentcategories_deviceDefinitions_deviceDefinitions_impactFactors } from 'Constants/graphql/queries/feature/__generated__/getOrderOrganisation'

import { ImagePlaceholderTypesEnum } from 'Types/imagePlaceholders.type'

export const OrderSelectionCard = observer((props: OrderSelectionCardProps) => {
  const intl = useIntl()
  const { chooseItem, isSelected, item, limitationRules } = props
  const { portalConfigStore, orderStore } = useStore()
  const { portalConfig } = portalConfigStore
  const { isProductOffer } = orderStore
  const isOrganisation = useIsOrganisation()

  const {
    basisAmount,
    calculatedNet,
    copaymentNet,
    copaymentGross,
    deviceDefinition,
    bundleImage,
    bundleName,
    bundleQuantity,
    bundleBasisAmount,
    bundleCopaymentGross,
    bundleCopaymentNet,
    isBundle,
    isCreateSeparateRental,
    customDeviceName,
  } = item

  const cardRef = useRef<HTMLDivElement>(null)
  const productType = parseItemTypeForLimitationRules(item)
  const isLimitExceeded =
    !!limitationRules && limitationRules[productType].current <= 0
  const cardName = bundleName || customDeviceName || deviceDefinition.name
  const cardImage = bundleImage || deviceDefinition.imageURL

  const handleSelectItem = useCallback(() => {
    if (
      (isProductOffer && !isCreateSeparateRental) ||
      isSelected ||
      !isLimitExceeded
    ) {
      chooseItem(item)
    }
  }, [
    chooseItem,
    isCreateSeparateRental,
    isLimitExceeded,
    isProductOffer,
    isSelected,
    item,
  ])

  useLayoutEffect(() => {
    if (isSelected && cardRef.current) {
      cardRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'center',
      })
    }
  })

  const hasLimitExceededClass =
    !isSelected &&
    (isProductOffer
      ? isCreateSeparateRental && isLimitExceeded
      : isLimitExceeded)

  return (
    <div
      ref={cardRef}
      aria-label={customDeviceName || deviceDefinition.name}
      className={clsx(
        'OrderSelectionCard',
        'w-100 flex-md-row',
        isSelected && 'OrderSelectionCard-selected',
        hasLimitExceededClass && 'OrderSelectionCard-limit-exceeded',
      )}
      data-axe-exclude={hasLimitExceededClass || undefined}
      data-e2e={E2E_ORDER_SELECTION_CARD}
      data-testid={ORDER_SELECTION_CARD}
      {...buttonize(handleSelectItem)}
    >
      <div
        className={clsx(
          deviceDefinition.impactFactors ? 'w-50' : 'w-100',
          'd-flex flex-column mb-4 mb-md-0 h-100',
        )}
      >
        <div className="OrderSelectionCard-image">
          <ImageWithFallback
            alt={cardName || ''}
            placeholderType={ImagePlaceholderTypesEnum.newOrderFlow}
            src={cardImage || ''}
          />
        </div>

        <div className="OrderSelectionCard-name">
          <Heading as={6} size={3}>
            <span>{cardName}</span>
          </Heading>
        </div>

        <div className="mt-auto">
          {isBundle && !isProductOffer && (
            <div className="text-start">
              <FormattedMessage
                id="NewOrderConfirmation_quantity"
                values={{ count: bundleQuantity }}
              />
            </div>
          )}

          {deviceDefinition.color && !isBundle && (
            <div className="text-start">
              <FormattedMessage id="Color" />: {deviceDefinition.color}
            </div>
          )}

          {isOrganisation &&
            portalConfig?.showRentalBasisAmountAtOrderFlow &&
            !item.forcePriceHide && (
              <div>
                <FormattedMessage id="SelectionCard_monthly_price" />
                :&nbsp;
                <FormattedCurrency
                  value={
                    isBundle && bundleBasisAmount
                      ? bundleBasisAmount
                      : basisAmount
                  }
                />
              </div>
            )}

          {portalConfig?.showCopaymentWithAndWithoutTax ? (
            <div className="OrderSelectionCard-copayment">
              <FormattedMessage id="SelectionCard_monthly_copayment" />
              <br />
              <FormattedMessage id="Excl_tax" />
              :&nbsp;
              <FormattedCurrency
                value={
                  isBundle && bundleCopaymentNet
                    ? bundleCopaymentNet
                    : copaymentNet
                }
              />
              <br />
              <FormattedMessage id="Incl_tax" />
              :&nbsp;
              <FormattedCurrency
                value={
                  isBundle && bundleCopaymentGross
                    ? bundleCopaymentGross
                    : copaymentGross
                }
              />
            </div>
          ) : (
            <div className="OrderSelectionCard-copayment">
              <FormattedMessage id="SelectionCard_monthly_copayment" />
              :&nbsp;
              <FormattedCurrency
                value={
                  isBundle && bundleCopaymentNet
                    ? bundleCopaymentNet
                    : copaymentNet
                }
              />
            </div>
          )}

          {!!calculatedNet && (
            <div className="OrderSelectionCard-effective-price">
              <FormattedMessage id="SelectionCard_effective_price" />
              :&nbsp;≈&nbsp;
              <FormattedCurrency value={calculatedNet} />
            </div>
          )}
        </div>
      </div>
      {deviceDefinition.impactFactors && (
        <>
          <div className="d-md-none mb-4 OrderImpactTracker-divider" />
          <div className="w-50 OrderImpactTracker">
            <div className="d-flex align-items-start mb-4 OrderImpactTracker-header">
              <div>
                <Heading className="mt-0 mb-1" size={6}>
                  <FormattedMessage id="OrderSelectionCard_refurbished_title" />
                </Heading>
                <p className="m-0">
                  <FormattedMessage id="OrderSelectionCard_refurbished_desc" />
                </p>
              </div>

              <OverlayTrigger
                flip
                offset={[-110, 10]}
                overlay={
                  <Popover id="ImpactTracker-popover">
                    <Popover.Body className="ImpactTrackerPopoverContent">
                      <FormattedMessage id="ImpactTracker_popover_desc" />
                    </Popover.Body>
                  </Popover>
                }
                placement="top"
                trigger="focus"
              >
                <div className="ms-4">
                  <SecondaryButton
                    aria-label={intl.formatMessage({
                      id: 'ImpactTracker_popover_desc',
                    })}
                    asIcon={faLeaf}
                    onClick={event => {
                      event.stopPropagation()
                      // Safari fix: focus needs to be triggered manualy to open bootstrap popover
                      event.currentTarget.focus()
                    }}
                    size="small"
                  />
                </div>
              </OverlayTrigger>
            </div>

            <ImpactTrackerChart
              data={mapOrderImpactFactorsToImpactTrackerChartData(
                deviceDefinition.impactFactors as getOrderOrganisation_organisation_rentCategories_rentcategories_deviceDefinitions_deviceDefinitions_impactFactors[],
              )}
              interactive={false}
              showLegend={false}
            />
          </div>
        </>
      )}
    </div>
  )
})
