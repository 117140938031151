import { useCallback, useMemo, useState } from 'react'
import { Col } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { observer } from 'mobx-react'

import { PrimaryButton } from 'Components/_theme'
import { ShippingAddressForm } from 'Components/ShippingAddressForm/ShippingAddressForm'
import { ShippingAddressFormSchema } from 'Components/ShippingAddressForm/ShippingAddressForm.types'

import { useStore } from 'Stores/index'

import { E2E_NEW_ORDER_ADDRESS_FORM_NEXT } from 'Constants/e2e'

import { useActiveUser, useCountryList } from 'Portal/src/hooks'

import { OrderStickyFooter } from '../OrderStickyFooter/OrderStickyFooter'
import { ORDER_SHIPPING_ADDRESS_FORM } from './OrderShippingAddressForm.constants'
import { OrderShippingAddressFormProps } from './OrderShippingAddressForm.types'
import { parseAddressTypeRadioButtonsForOrder } from './OrderShippingAddressForm.utils'

export const OrderShippingAddressForm = observer(
  (props: OrderShippingAddressFormProps) => {
    const {
      chosenShippingAddress,
      employeeContact,
      vendingMachines,
      officeAddress,
      onBackButtonClick,
      onSubmitButtonClick,
    } = props
    const { portalConfigStore, portalSettingsStore } = useStore()
    const { portalConfig } = portalConfigStore
    const { userMode } = portalSettingsStore
    const { activeOrganisation, activeEmployee } = useActiveUser()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const intl = useIntl()
    const countryList = useCountryList()

    const handleSubmit = useCallback(
      (data: ShippingAddressFormSchema) => {
        onSubmitButtonClick(data)
      },
      [onSubmitButtonClick],
    )

    const addressTypesConfig = useMemo(
      () =>
        parseAddressTypeRadioButtonsForOrder(
          intl,
          userMode,
          portalConfig,
          activeOrganisation,
          activeEmployee,
          countryList,
          officeAddress,
          employeeContact,
          vendingMachines,
        ),
      [
        activeEmployee,
        activeOrganisation,
        countryList,
        employeeContact,
        intl,
        officeAddress,
        portalConfig,
        userMode,
        vendingMachines,
      ],
    )

    return (
      <div>
        <ShippingAddressForm
          className="pt-6"
          customAddressTypesConfig={addressTypesConfig}
          defaultValues={chosenShippingAddress}
          formId={ORDER_SHIPPING_ADDRESS_FORM}
          onSetIsSubmitting={setIsSubmitting}
          onSubmit={handleSubmit}
        />

        <OrderStickyFooter
          handlePreviousAction={onBackButtonClick}
          renderNextButtonElement={() => (
            <Col className="d-flex justify-content-end">
              <PrimaryButton
                className="ms-2"
                data-e2e={E2E_NEW_ORDER_ADDRESS_FORM_NEXT}
                form={ORDER_SHIPPING_ADDRESS_FORM}
                id="next"
                isLoading={isSubmitting}
                type="submit"
              >
                <FormattedMessage id="Next" />
              </PrimaryButton>
            </Col>
          )}
        />
      </div>
    )
  },
)
