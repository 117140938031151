import { UserData } from 'Stores/userStore/userStore.types'

import { getEmployee_employee } from 'Constants/graphql/queries/employee/__generated__/getEmployee'
import { getOrganisation_organisation } from 'Constants/graphql/queries/organisation/__generated__/getOrganisation'

interface VisitorTraits {
  email: string
  employee_id?: string
  ep_env?: string
  org_full_name?: string
  org_id?: string
}

/* eslint-disable no-underscore-dangle */
export const initSurvicate = (
  user: UserData,
  isOrganisation: boolean,
  activeOrganisation: getOrganisation_organisation | null,
  activeEmployee: getEmployee_employee | null,
) => {
  try {
    const visitorTraits: VisitorTraits = {
      email: user.email || '',
      employee_id: activeEmployee?.id,
      ep_env: window.APP_ENV,
      org_full_name: isOrganisation
        ? activeOrganisation?.fullName
        : activeEmployee?.organisation?.fullName,
      org_id: isOrganisation
        ? activeOrganisation?.id
        : activeEmployee?.organisation?.id,
    }

    if (
      window.isSurvicateReady &&
      window._sva &&
      window._sva.setVisitorTraits &&
      window._sva.retarget
    ) {
      window._sva.setVisitorTraits(visitorTraits)
      window._sva.retarget()
    }
  } catch (e: any) {
    console.error('Survicate error:', e)
  }
}
