import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react'

import { useActiveUser } from 'Hooks'

export const NewJoinerRedirect = observer(() => {
  const { activeEmployee } = useActiveUser()

  if (activeEmployee?.caseitems?.totalCount === 0) {
    return <Redirect to={`${activeEmployee.id}/order/new`} />
  }

  return (
    <Redirect
      to={`${activeEmployee?.id}/orders/${
        activeEmployee?.caseitems?.caseItems![0]!.id
      }`}
    />
  )
})
