import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { Radio } from 'Components/_theme'

import { FieldRadioProps } from './Field.types'

export const FieldRadio = (props: FieldRadioProps) => {
  const {
    inputClassName,
    label,
    name,
    hasError,
    markAsRequired,
    shouldTranslateLabel,
    ...rest
  } = props
  const { register } = useFormContext()
  const intl = useIntl()

  const translatedLabel =
    shouldTranslateLabel && label
      ? intl.formatMessage({
          id: label,
        })
      : label ?? ''

  const formattedLabel = `${translatedLabel} ${
    markAsRequired ? '*' : ''
  }`.trim()

  return (
    <Radio
      {...register(name)}
      {...rest}
      className={inputClassName}
      isInvalid={hasError}
      label={formattedLabel}
    />
  )
}
