import { useCallback, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { isEmpty } from 'lodash-es'
import { observer } from 'mobx-react'

import { EMPLOYEE_RECENT_REQUESTS } from 'Modules/employees/pages/Employee/Employee.constants'

import { LinkButton } from 'Components/_theme'
import { EntityListCard } from 'Components/EntityListCard/EntityListCard'
import { EntityProps } from 'Components/EntityListCard/EntityListCard.types'

import { DEVICE_HISTORY_MAX_TO_SHOW } from './DeviceHistoryEntityCard.constants'
import { DeviceHistoryEntityCardProps } from './DeviceHistoryEntityCard.types'

export const DeviceHistoryEntityCard = observer(
  (props: DeviceHistoryEntityCardProps) => {
    const { rental } = props
    const [seeAll, setSeeAll] = useState(false)

    const generateEntities = useMemo((): EntityProps[] => {
      const stocklistItems = rental.stocklistItemHistory?.stocklistItems

      if (!stocklistItems) {
        return []
      }

      const entities = stocklistItems.map(stocklistItem => ({
        content: (
          <div key={stocklistItem.id} className="d-flex flex-column">
            {stocklistItem?.imei && (
              <span className="m-0 fs-6 text-truncate">
                <FormattedMessage id="IMEI" />: {stocklistItem.imei}
              </span>
            )}

            {stocklistItem?.serialNumber && (
              <span className="m-0 fs-6 text-truncate">
                <FormattedMessage id="RentalDetails_serial" />:{' '}
                {stocklistItem.serialNumber}
              </span>
            )}
          </div>
        ),
        id: stocklistItem.id,
        imageSource: stocklistItem?.deviceDefinition?.imageURL,
        title: stocklistItem?.deviceDefinition?.name || '',
      }))

      if (seeAll) {
        return entities
      }

      return entities.slice(0, DEVICE_HISTORY_MAX_TO_SHOW)
    }, [rental, seeAll])

    const handleSeeAll = useCallback(() => {
      setSeeAll(true)
    }, [])

    const renderSeeAllLink = useMemo(() => {
      if (generateEntities.length < DEVICE_HISTORY_MAX_TO_SHOW || seeAll) {
        return null
      }

      return (
        <LinkButton
          className="btn-link fw-bold"
          onClick={handleSeeAll}
          size="small"
        >
          <FormattedMessage id="See_all" />
        </LinkButton>
      )
    }, [generateEntities.length, handleSeeAll, seeAll])

    if (isEmpty(generateEntities)) {
      return null
    }

    return (
      <EntityListCard
        entities={generateEntities}
        rightContent={renderSeeAllLink}
        testId={EMPLOYEE_RECENT_REQUESTS}
        title="Device_history"
      />
    )
  },
)
