import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'

import { Badge } from 'Components/_theme'

import { useBasePageUrl, useIsOrganisation } from 'Hooks'

import { LogoProps } from './Logo.types'

export const Logo = observer((props: LogoProps) => {
  const { asLink, showAdminBadge } = props
  const isOrganisation = useIsOrganisation()
  const { basePageUrl } = useBasePageUrl()

  const showBadge = isOrganisation && showAdminBadge

  const renderContent = () => (
    <>
      <div className="logo-img">&nbsp;</div>

      {showBadge && (
        <Badge color="blue" variant="secondary">
          <FormattedMessage id="Admin" />
        </Badge>
      )}
    </>
  )

  if (asLink) {
    return (
      <Link className="logo" title="Everphone" to={basePageUrl}>
        {renderContent()}
      </Link>
    )
  }

  return <div className="logo">{renderContent()}</div>
})
