import { FormattedMessage } from 'react-intl'

import { DashboardDeviceSlideTypeEnum } from 'Modules/dashboard/components/DashboardDevices/DashboardDevices.types'

import { Card, Heading } from 'Components/_theme'
import { FormatDate } from 'Components/FormatDate/FormatDate'
import { ImageWithFallback } from 'Components/ImageWithFallback/ImageWithFallback'
import { OrderStatusBadge } from 'Components/OrderStatusBadge/OrderStatusBadge'
import { RentalStatusBadge } from 'Components/RentalStatusBadge/RentalStatusBadge'
import { RentalStatus } from 'Components/RentalStatusBadge/RentalStatusBadge.types'

import { DeviceTypeEnum } from 'Types/deviceTypes'

import { DashboardServiceAppDeviceProps } from './DashboardServiceAppDevice.types'

export const DashboardServiceAppDevice = (
  props: DashboardServiceAppDeviceProps,
) => {
  const {
    imageURL,
    name,
    productType,
    id,
    created,
    status,
    imei,
    startAt,
    type,
    isUpgradable,
    isEarlyUpgradable,
  } = props

  return (
    <Card className="DashboardServiceAppDevice mt-4 p-4 d-flex align-items-center cursor-pointer">
      <ImageWithFallback
        alt={name}
        fluid
        placeholderType={productType as DeviceTypeEnum}
        src={imageURL || ''}
      />

      <div className="ms-4 w-100">
        {status && (
          <div className="DashboardServiceAppDevice-badge mb-4">
            <OrderStatusBadge status={status} />
          </div>
        )}

        {isUpgradable && (
          <div className="DashboardServiceAppDevice-badge mb-4">
            <RentalStatusBadge status={RentalStatus.new_device} />
          </div>
        )}

        {isEarlyUpgradable && !isUpgradable && (
          <div className="DashboardServiceAppDevice-badge mb-4">
            <RentalStatusBadge status={RentalStatus.upgrade} />
          </div>
        )}

        <Heading as={5} className="mb-1" size={2}>
          {name ?? <FormattedMessage id="Device" />}
        </Heading>

        {created && type === DashboardDeviceSlideTypeEnum.Request && (
          <>
            <FormattedMessage id="DeviceSlide_request_id" />: {id}
            <div className="d-flex">
              <FormattedMessage id="DeviceSlide_request_date" />
              :&nbsp;
              <FormatDate value={created} />
            </div>
          </>
        )}

        {startAt && (
          <div className="d-flex flex-column justify-content-end">
            {imei && (
              <>
                <FormattedMessage id="IMEI" />
                :&nbsp;{imei}
              </>
            )}

            <div className="d-flex">
              <FormattedMessage id="DeviceSlide_start_date" />
              :&nbsp;
              <FormatDate value={startAt} />
            </div>
          </div>
        )}
      </div>
    </Card>
  )
}
