/* eslint-disable sort-keys-fix/sort-keys-fix */
export const CountriesDE = Object.fromEntries(
  Object.entries({
    AF: 'Afghanistan',
    EG: 'Ägypten',
    AX: 'Ålandinseln',
    AL: 'Albanien',
    DZ: 'Algerien',
    AS: 'Amerikanisch-Samoa',
    VI: 'Amerikanische Jungferninseln',
    UM: 'Amerikanische Überseeinseln',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarktis',
    AG: 'Antigua und Barbuda',
    GQ: 'Äquatorialguinea',
    AR: 'Argentinien',
    AM: 'Armenien',
    AW: 'Aruba',
    AZ: 'Aserbaidschan',
    ET: 'Äthiopien',
    AU: 'Australien',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesch',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgien',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivien',
    BQ: 'Bonaire, Sint Eustatius und Saba',
    BA: 'Bosnien und Herzegowina',
    BW: 'Botsuana',
    BV: 'Bouvetinsel',
    BR: 'Brasilien',
    VG: 'Britische Jungferninseln',
    IO: 'Britisches Territorium im Indischen Ozean',
    BN: 'Brunei Darussalam',
    BG: 'Bulgarien',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    CV: 'Cabo Verde',
    CL: 'Chile',
    CN: 'China',
    CK: 'Cookinseln',
    CR: 'Costa Rica',
    CI: 'Côte d’Ivoire',
    CW: 'Curaçao',
    DK: 'Dänemark',
    DE: 'Deutschland',
    DM: 'Dominica',
    DO: 'Dominikanische Republik',
    DJ: 'Dschibuti',
    EC: 'Ecuador',
    SV: 'El Salvador',
    ER: 'Eritrea',
    EE: 'Estland',
    SZ: 'Eswatini',
    FK: 'Falklandinseln',
    FO: 'Färöer',
    FJ: 'Fidschi',
    FI: 'Finnland',
    FR: 'Frankreich',
    GF: 'Französisch-Guayana',
    PF: 'Französisch-Polynesien',
    TF: 'Französische Süd- und Antarktisgebiete',
    GA: 'Gabun',
    GM: 'Gambia',
    GE: 'Georgien',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GD: 'Grenada',
    GR: 'Griechenland',
    GL: 'Grönland',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard und McDonaldinseln',
    HN: 'Honduras',
    IN: 'Indien',
    ID: 'Indonesien',
    IQ: 'Irak',
    IR: 'Iran',
    IE: 'Irland',
    IS: 'Island',
    IM: 'Isle of Man',
    IL: 'Israel',
    IT: 'Italien',
    JM: 'Jamaika',
    JP: 'Japan',
    YE: 'Jemen',
    JE: 'Jersey',
    JO: 'Jordanien',
    KY: 'Kaimaninseln',
    KH: 'Kambodscha',
    CM: 'Kamerun',
    CA: 'Kanada',
    KZ: 'Kasachstan',
    QA: 'Katar',
    KE: 'Kenia',
    KG: 'Kirgisistan',
    KI: 'Kiribati',
    CC: 'Kokosinseln',
    CO: 'Kolumbien',
    KM: 'Komoren',
    CG: 'Kongo-Brazzaville',
    CD: 'Kongo-Kinshasa',
    HR: 'Kroatien',
    CU: 'Kuba',
    KW: 'Kuwait',
    LA: 'Laos',
    LS: 'Lesotho',
    LV: 'Lettland',
    LB: 'Libanon',
    LR: 'Liberia',
    LY: 'Libyen',
    LI: 'Liechtenstein',
    LT: 'Litauen',
    LU: 'Luxemburg',
    MG: 'Madagaskar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Malediven',
    ML: 'Mali',
    MT: 'Malta',
    MA: 'Marokko',
    MH: 'Marshallinseln',
    MQ: 'Martinique',
    MR: 'Mauretanien',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexiko',
    FM: 'Mikronesien',
    MC: 'Monaco',
    MN: 'Mongolei',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MZ: 'Mosambik',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NC: 'Neukaledonien',
    NZ: 'Neuseeland',
    NI: 'Nicaragua',
    NL: 'Niederlande',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    KP: 'Nordkorea',
    MP: 'Nördliche Marianen',
    MK: 'Nordmazedonien',
    NF: 'Norfolkinsel',
    NO: 'Norwegen',
    OM: 'Oman',
    AT: 'Österreich',
    PK: 'Pakistan',
    PS: 'Palästinensische Autonomiegebiete',
    PW: 'Palau',
    PA: 'Panama',
    PG: 'Papua-Neuguinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippinen',
    PN: 'Pitcairninseln',
    PL: 'Polen',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    MD: 'Republik Moldau',
    RE: 'Réunion',
    RW: 'Ruanda',
    RO: 'Rumänien',
    RU: 'Russland',
    SB: 'Salomonen',
    ZM: 'Sambia',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'São Tomé und Príncipe',
    SA: 'Saudi-Arabien',
    SE: 'Schweden',
    CH: 'Schweiz',
    SN: 'Senegal',
    RS: 'Serbien',
    SC: 'Seychellen',
    SL: 'Sierra Leone',
    ZW: 'Simbabwe',
    SG: 'Singapur',
    SX: 'Sint Maarten',
    SK: 'Slowakei',
    SI: 'Slowenien',
    SO: 'Somalia',
    HK: 'Sonderverwaltungsregion Hongkong',
    MO: 'Sonderverwaltungsregion Macau',
    ES: 'Spanien',
    SJ: 'Spitzbergen und Jan Mayen',
    LK: 'Sri Lanka',
    BL: 'St. Barthélemy',
    SH: 'St. Helena',
    KN: 'St. Kitts und Nevis',
    LC: 'St. Lucia',
    MF: 'St. Martin',
    PM: 'St. Pierre und Miquelon',
    VC: 'St. Vincent und die Grenadinen',
    ZA: 'Südafrika',
    SD: 'Sudan',
    GS: 'Südgeorgien und die Südlichen Sandwichinseln',
    KR: 'Südkorea',
    SS: 'Südsudan',
    SR: 'Suriname',
    SY: 'Syrien',
    TJ: 'Tadschikistan',
    TW: 'Taiwan',
    TZ: 'Tansania',
    TH: 'Thailand',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad und Tobago',
    TD: 'Tschad',
    CZ: 'Tschechien',
    TN: 'Tunesien',
    TR: 'Türkei',
    TM: 'Turkmenistan',
    TC: 'Turks- und Caicosinseln',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ukraine',
    HU: 'Ungarn',
    UY: 'Uruguay',
    UZ: 'Usbekistan',
    VU: 'Vanuatu',
    VA: 'Vatikanstadt',
    VE: 'Venezuela',
    AE: 'Vereinigte Arabische Emirate',
    US: 'Vereinigte Staaten',
    GB: 'Vereinigtes Königreich',
    VN: 'Vietnam',
    WF: 'Wallis und Futuna',
    CX: 'Weihnachtsinsel',
    EH: 'Westsahara',
    CF: 'Zentralafrikanische Republik',
    CY: 'Zypern',
  }).sort(),
)
