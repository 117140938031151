import { gql } from '@apollo/client'

export const GET_DEVICE_ACCESSORIES = gql`
  query getDeviceAccessories($id: ID!) {
    rentCategory(id: $id) {
      deviceDefinitions {
        deviceDefinitions {
          id
          accessories(rentCategoryID: $id) {
            totalCount
            accessories {
              deviceDefinition {
                id
                codename
                color
                edition
                imageURL
                manufacturer
                name
                productType
                storage
                impactFactors {
                  status
                  factors {
                    name
                    value
                    valueConverted
                    converter
                  }
                }
              }
              rentCategory {
                id
                displayedBasisAmount
                copaymentNet
                copaymentGross
                deviatingAdvertisementPrice
                minimumContractDuration
                forcePriceHide
              }
            }
          }
        }
      }
    }
  }
`
