import { useEffect } from 'react'
import { useAsync } from '@react-hookz/web'
import { observer } from 'mobx-react'

import { useStore } from 'Stores/index'

export const UserwayProvider = observer(() => {
  const { portalSettingsStore } = useStore()
  const { isUserwayEnabled, isUserwayInitialised, setIsUserwayInitialised } =
    portalSettingsStore

  const [state, actions] = useAsync(
    async () =>
      new Promise(resolve => {
        if (window.UserWay) {
          resolve(true)
        }
      }),
  )

  useEffect(() => {
    if (isUserwayEnabled && !isUserwayInitialised) {
      actions.execute()
    }
  }, [actions, isUserwayEnabled, isUserwayInitialised])

  useEffect(() => {
    if (state.status === 'success') {
      setIsUserwayInitialised()
    }
  }, [setIsUserwayInitialised, state])

  return null
})
