import { useCallback, useEffect, useMemo } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { faTurnDownRight } from '@fortawesome/pro-solid-svg-icons'
import { observer } from 'mobx-react'

import { CustomConfirmationPageType } from 'Modules/order/hooks'
import { OrderConfirmationDeviceRendererProps } from 'Modules/order/types/OrderConfirmation.types'
import { getOrderId } from 'Modules/order/utils/getOrderId.utils'

import { Card, CardBody, Heading, PrimaryButton } from 'Components/_theme'
import { FaqCard } from 'Components/FaqCard/FaqCard'
import { FormatDate } from 'Components/FormatDate/FormatDate'
import { FormattedCurrency } from 'Components/FormattedCurrency/FormattedCurrency'
import { Icon } from 'Components/Icon/Icon'
import { ImageWithFallback } from 'Components/ImageWithFallback/ImageWithFallback'

import { useStore } from 'Stores/index'

import { useActiveUser, useCountryList, useTheme } from 'Hooks'

import { handleNewJoinerLogout } from 'Utilities'

import { E2E_NEW_ORDER_DONE } from 'Constants/e2e'

import { ImagePlaceholderTypesEnum } from 'Types/imagePlaceholders.type'

import OrderConfirmationImage from 'Assets/images/orderconfirmationillustration.svg'

import { setRouteTitle } from 'Portal/src/router/Router.utils'

import {
  ORDER_CONFIRMATION_ROW,
  ORDER_TOTAL_AMOUNT,
} from './OrderConfirmation.constants'
import { BACK_BUTTON_TRANSLATION_IDS } from './OrderConfirmation.types'

export const OrderConfirmation = observer(() => {
  const {
    orderStore,
    navigationStore,
    portalConfigStore,
    portalSettingsStore,
  } = useStore()

  const { mainNavigationConfig } = navigationStore
  const { portalConfig } = portalConfigStore
  const { isNewJoiner } = portalSettingsStore
  const {
    chosenShippingAddress,
    chosenAccessories,
    chosenDevice,
    orderedDevices,
    getTotalPrice,
    originRoute,
    orderEmployeeName,
  } = orderStore

  const history = useHistory()
  const intl = useIntl()
  const { isEverphoneTheme } = useTheme()
  const { customType, employeeId = '' } = useParams<{
    customType?: CustomConfirmationPageType
    employeeId?: string
  }>()

  const { activeOrganisation } = useActiveUser()

  const isBundle = chosenDevice?.isBundle
  const chosenDeviceName = isBundle
    ? chosenDevice.bundleName || chosenDevice.customDeviceName
    : chosenDevice?.deviceDefinition.name
  const chosenDeviceImage = isBundle
    ? chosenDevice.bundleImage
    : chosenDevice?.deviceDefinition.imageURL

  const handleBackButtonClick = useCallback(() => {
    if (isNewJoiner) {
      handleNewJoinerLogout()

      return
    }

    if (originRoute?.url) {
      history.replace(originRoute.url)

      return
    }

    history.replace(mainNavigationConfig.url)
  }, [isNewJoiner, history, mainNavigationConfig, originRoute])

  const countryList = useCountryList()
  const isCountryUnitedStates = chosenShippingAddress?.country?.value === 'US'

  const chosenDeviceQuantity = chosenDevice?.quantity ?? 1
  const shouldShowRequestIdColumn =
    chosenDeviceQuantity === 1 &&
    [...chosenAccessories].every(([_id, accessory]) => accessory.quantity === 1)

  useEffect(() => {
    if (!chosenDevice && isNewJoiner) {
      history.replace(mainNavigationConfig.url)

      return
    }

    if (!chosenDevice) {
      handleBackButtonClick()
    }
  }, [
    chosenDevice,
    handleBackButtonClick,
    history,
    isNewJoiner,
    mainNavigationConfig.url,
  ])

  useEffect(() => {
    setRouteTitle(
      `${intl.formatMessage({
        id: 'NewOrderSummary_order',
      })}: ${intl.formatMessage({
        id: 'NewOrderConfirmation_heading',
      })}`,
    )
  }, [intl])

  const generateOrderDoneButtonLabel = useMemo(() => {
    if (isNewJoiner) {
      return intl.formatMessage({ id: 'Logout' })
    }

    if (originRoute?.type) {
      return intl.formatMessage({
        id: BACK_BUTTON_TRANSLATION_IDS[originRoute.type],
      })
    }

    return intl.formatMessage({
      id: 'NewOrderConfirmation_to_dashboard',
    })
  }, [intl, isNewJoiner, originRoute])

  const chosenAccessoriesWithQuantity = useMemo(
    () =>
      [...chosenAccessories].filter(
        ([_id, accessory]) => accessory.quantity > 0,
      ),
    [chosenAccessories],
  )

  const renderConfirmationDeviceRow = useCallback(
    (props: OrderConfirmationDeviceRendererProps) => {
      const {
        deviceName,
        deviceRentalDuration,
        deviceImage,
        devicePrice,
        deviceQuantity,
        deviceId,
        deviceCustomerLabel,
        deviceColor,
        isBundleRow,
        isBundleItemRow,
        hideRentalPeriod,
      } = props

      const orderId = !isBundleRow ? deviceId : null

      return (
        <Row
          key={orderId}
          className="device-overview justify-content-right justify-content-md-between align-items-center"
          data-chosendevice={deviceName}
          data-copayment={devicePrice}
          data-orderid={orderId}
          data-testid={ORDER_CONFIRMATION_ROW}
        >
          <Col className="d-flex" md={6} xs={12}>
            {isBundleItemRow && (
              <Icon className="me-2" icon={faTurnDownRight} size={20} />
            )}

            <div className="pe-4">
              <ImageWithFallback
                alt={deviceName}
                placeholderType={ImagePlaceholderTypesEnum.newOrderFlow}
                src={deviceImage}
              />
            </div>

            <div className="d-flex flex-column justify-content-center">
              <strong>{deviceName}</strong>

              {!isBundleRow && (
                <>
                  <span>
                    <FormattedMessage
                      id="NewOrderConfirmation_quantity"
                      values={{
                        count: deviceQuantity,
                      }}
                    />
                  </span>
                  <span>
                    <FormattedMessage
                      id="NewOrderConfirmation_color"
                      values={{
                        color: deviceColor,
                      }}
                    />
                  </span>
                </>
              )}

              {deviceCustomerLabel && !isBundleRow && (
                <span>
                  <FormattedMessage id="NewOrderConfirmation_label" />
                  &nbsp;
                  {deviceCustomerLabel}
                </span>
              )}
            </div>
          </Col>

          {shouldShowRequestIdColumn && (
            <Col
              className="d-none d-md-flex justify-content-end align-items-center"
              xs={2}
            >
              {!isBundleRow && deviceId}
            </Col>
          )}

          <Col
            className="d-none d-md-flex justify-content-end align-items-center"
            xs={2}
          >
            {!isBundleRow && !hideRentalPeriod && (
              <FormattedMessage
                id="NewOrderConfirmation_months"
                values={{
                  total: deviceRentalDuration,
                }}
              />
            )}
          </Col>

          <Col
            className="d-none d-md-flex justify-content-end align-items-center"
            xs={2}
          >
            {!isBundleRow && <FormattedCurrency value={devicePrice} />}
          </Col>
        </Row>
      )
    },
    [shouldShowRequestIdColumn],
  )

  const renderConfirmationHeadingRow = useMemo(
    () => (
      <Row className="order-container grey-container p-4 d-flex justify-content-right justify-content-md-between align-items-center">
        <Col className="ps-0" md={6} xs={12}>
          <Heading as={6} size={3}>
            <FormattedMessage id="NewOrderConfirmation_items" />
          </Heading>
        </Col>

        {shouldShowRequestIdColumn && (
          <Col
            className="d-none d-md-flex justify-content-md-end"
            md={2}
            xs={0}
          >
            <Heading as={6} size={3}>
              <FormattedMessage id="NewOrderConfirmation_request_id" />
            </Heading>
          </Col>
        )}

        <Col className="d-none d-md-flex justify-content-md-end" md={2} xs={0}>
          <Heading as={6} size={3}>
            <FormattedMessage id="NewOrderConfirmation_rental_period" />
          </Heading>
        </Col>

        <Col
          className="d-none d-md-flex justify-content-md-end pe-0"
          md={2}
          xs={0}
        >
          <Heading as={6} size={3}>
            <FormattedMessage id="NewOrderConfirmation_price" />
          </Heading>
        </Col>
      </Row>
    ),
    [shouldShowRequestIdColumn],
  )

  const renderConfirmationCustomTypeSubheading = useMemo(
    () => (
      <FormattedMessage id={`NewOrderConfirmation_subheading_${customType}`} />
    ),
    [customType],
  )

  const showEmployeeNameSufix =
    !!activeOrganisation && employeeId && orderEmployeeName

  return (
    <Container className="OrderFlow pt-6" fluid>
      <Row>
        <Col xl={12} xxl={8}>
          <Card className="mb-9">
            <CardBody
              aria-live="polite"
              className="OrderFlow-confirmation-header d-flex flex-column-reverse flex-lg-row flex-lg-nowrap overflow-hidden"
              role="status"
            >
              <Col xl={8} xs={12}>
                <Row>
                  <Col className="d-flex flex-column flex-xl-row justify-content-center justify-content-lg-start align-items-center">
                    <Heading as={4} color="light" size={2}>
                      <FormattedMessage id="NewOrderConfirmation_heading" />
                      {showEmployeeNameSufix && (
                        <>
                          {' '}
                          <FormattedMessage id="for" /> {orderEmployeeName}
                        </>
                      )}
                    </Heading>

                    <img
                      alt={intl.formatMessage({
                        id: 'Img_alt_order_confirmation',
                      })}
                      className="OrderFlow-confirmation-header-image d-xl-none mt-2 w-100"
                      src={OrderConfirmationImage}
                    />
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col className="text-white">
                    {customType ? (
                      renderConfirmationCustomTypeSubheading
                    ) : (
                      <FormattedMessage id="NewOrderConfirmation_subheading" />
                    )}
                  </Col>
                </Row>

                <Row className="mt-6">
                  <Col sm={6} xs={12}>
                    <div className="text-white pb-2">
                      <FormattedMessage id="NewOrderConfirmation_order_date" />:
                    </div>
                    <div className="text-white fw-600">
                      <FormatDate value={new Date()} />
                    </div>

                    {isEverphoneTheme && (
                      <div className="OrderFlow-confirmation-header-diglett d-none d-sm-flex h-100">
                        <div />
                        <div />
                        <div />
                        <div />
                      </div>
                    )}
                  </Col>

                  <Col
                    className="overflow-hidden shipping-address"
                    sm={6}
                    xs={12}
                  >
                    <div className="text-white pb-2">
                      <FormattedMessage id="NewOrderConfirmation_shipping_address" />
                      :
                    </div>
                    <div className="text-white fw-600">
                      <span>{chosenShippingAddress?.name}</span>
                      <br />
                      {chosenShippingAddress?.nameAddition ? (
                        <>
                          <span>{chosenShippingAddress.nameAddition}</span>
                          <br />
                        </>
                      ) : null}
                      {isCountryUnitedStates ? (
                        <span>{chosenShippingAddress?.usAddress}</span>
                      ) : (
                        <span>
                          {chosenShippingAddress?.street}{' '}
                          {chosenShippingAddress?.streetNumber}{' '}
                          {chosenShippingAddress?.streetAddition}
                        </span>
                      )}
                      ,
                      <br />
                      <span>{chosenShippingAddress?.zipcode}</span>&nbsp;
                      <span>
                        {isCountryUnitedStates
                          ? `${chosenShippingAddress?.city}, ${
                              chosenShippingAddress.state?.value ?? ''
                            }`.trim()
                          : chosenShippingAddress?.city}
                      </span>
                      <br />
                      {chosenShippingAddress?.country?.value && (
                        <span>
                          {countryList[chosenShippingAddress.country.value]}
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col
                className="d-none d-xl-flex align-items-center justify-content-end"
                xl={4}
                xs={12}
              >
                <img
                  alt={intl.formatMessage({ id: 'Img_alt_order_confirmation' })}
                  className="OrderFlow-confirmation-header-image w-100"
                  src={OrderConfirmationImage}
                />
              </Col>
            </CardBody>

            <CardBody>
              <Container fluid>
                <Row className="overflow-auto">
                  <Col className="scrollable-on-mobile">
                    {renderConfirmationHeadingRow}

                    {chosenDevice &&
                      renderConfirmationDeviceRow({
                        deviceColor: chosenDevice.deviceDefinition.color,
                        deviceCustomerLabel: chosenDevice.customerLabel,
                        deviceId: getOrderId(orderedDevices, chosenDevice) ?? 0,
                        deviceImage: chosenDeviceImage || '',
                        deviceName: chosenDeviceName || '',
                        devicePrice: isBundle
                          ? (getTotalPrice?.totalWithTax ?? 0)
                          : chosenDevice.copaymentNet,
                        deviceQuantity: isBundle
                          ? (chosenDevice.bundleQuantity ?? 0)
                          : (chosenDevice.quantity ?? 0),
                        deviceRentalDuration:
                          chosenDevice.minimumContractDuration ?? 0,
                        isBundleRow: isBundle,
                      })}

                    {chosenDevice &&
                      isBundle &&
                      renderConfirmationDeviceRow({
                        deviceColor: chosenDevice.deviceDefinition.color,
                        deviceCustomerLabel: chosenDevice.customerLabel,
                        deviceId: getOrderId(orderedDevices, chosenDevice) ?? 0,
                        deviceImage:
                          chosenDevice.deviceDefinition.imageURL || '',
                        deviceName:
                          chosenDevice.customDeviceName ||
                          chosenDevice.deviceDefinition.name ||
                          '',
                        devicePrice: chosenDevice.copaymentNet,
                        deviceQuantity: chosenDevice.quantity,
                        deviceRentalDuration:
                          chosenDevice.minimumContractDuration ?? 0,
                        isBundleItemRow: true,
                      })}

                    {chosenDevice?.addonDevices?.map(addonDevice =>
                      renderConfirmationDeviceRow({
                        deviceColor: addonDevice.deviceDefinition.color,
                        deviceCustomerLabel: addonDevice.customerLabel,
                        deviceId: getOrderId(orderedDevices, addonDevice) ?? 0,
                        deviceImage:
                          addonDevice.deviceDefinition.imageURL || '',
                        deviceName:
                          addonDevice.customDeviceName ||
                          addonDevice.deviceDefinition.name,
                        devicePrice: addonDevice.copaymentNet,
                        deviceQuantity: addonDevice.quantity,
                        deviceRentalDuration:
                          addonDevice.minimumContractDuration ?? 0,
                        hideRentalPeriod: !addonDevice.isCreateSeparateRental,
                        isBundleItemRow: true,
                      }),
                    )}

                    {chosenAccessoriesWithQuantity.map(chosenAccessory => {
                      const [_id, accessory] = chosenAccessory

                      return (
                        <Row
                          key={`${accessory.rentCategoryId}-${accessory.deviceDefinition.id}`}
                          className="device-overview justify-content-right justify-content-md-between align-items-center"
                          data-chosenaccessory={
                            accessory.customDeviceName ||
                            accessory.deviceDefinition.name
                          }
                          data-orderid={getOrderId(orderedDevices, accessory)}
                          data-testid={ORDER_CONFIRMATION_ROW}
                        >
                          <Col
                            className="d-flex align-items-center"
                            md={6}
                            xs={12}
                          >
                            <div className="pe-4">
                              <ImageWithFallback
                                alt={
                                  accessory.customDeviceName ||
                                  accessory.deviceDefinition.name
                                }
                                placeholderType={
                                  ImagePlaceholderTypesEnum.newOrderFlow
                                }
                                src={accessory.deviceDefinition.imageURL || ''}
                              />
                            </div>

                            <div className="d-flex flex-column justify-content-center">
                              <strong>
                                {accessory.customDeviceName ||
                                  accessory.deviceDefinition.name}
                              </strong>

                              <span>
                                <FormattedMessage
                                  id="NewOrderConfirmation_quantity"
                                  values={{
                                    count: accessory.quantity,
                                  }}
                                />
                              </span>

                              <span>
                                <FormattedMessage
                                  id="NewOrderConfirmation_color"
                                  values={{
                                    color: accessory.deviceDefinition.color,
                                  }}
                                />
                              </span>
                            </div>
                          </Col>

                          {shouldShowRequestIdColumn && (
                            <Col
                              className="d-none d-md-flex justify-content-end align-items-center"
                              xs={2}
                            >
                              {getOrderId(orderedDevices, accessory)}
                            </Col>
                          )}

                          <Col
                            className="d-none d-md-flex justify-content-end align-items-center"
                            xs={2}
                          >
                            {accessory.isCreateSeparateRental && (
                              <FormattedMessage
                                id="NewOrderConfirmation_months"
                                values={{
                                  total: accessory.minimumContractDuration || 0,
                                }}
                              />
                            )}
                          </Col>

                          <Col
                            className="d-none d-md-flex justify-content-end align-items-center"
                            xs={2}
                          >
                            <FormattedCurrency value={accessory.copaymentNet} />
                          </Col>
                        </Row>
                      )
                    })}
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Row className="pt-6" data-testid={ORDER_TOTAL_AMOUNT}>
                      <Col className="d-flex flex-column justify-content-end">
                        <Row className="price-container px-4 d-flex align-items-center">
                          <Col className="d-flex pe-0 justify-content-end">
                            <Heading as={5} className="mt-1 pe-2" size={4}>
                              <FormattedMessage id="NewOrderConfirmation_total_amount" />
                            </Heading>

                            <Heading as={5} className="mt-1" size={4}>
                              <FormattedCurrency
                                value={getTotalPrice?.totalWithoutTax || 0}
                              />
                            </Heading>
                          </Col>
                        </Row>

                        {portalConfig?.showCopaymentWithAndWithoutTax && (
                          <Row className="price-container px-4 mt-2 d-flex align-items-center">
                            <Col className="d-flex pe-0 justify-content-end">
                              <Heading as={5} className="mt-1 pe-2" size={4}>
                                <FormattedMessage id="NewOrderConfirmation_total_amount_gross" />
                              </Heading>

                              <Heading as={5} className="mt-1" size={4}>
                                <FormattedCurrency
                                  value={getTotalPrice?.totalWithTax || 0}
                                />
                              </Heading>
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </CardBody>
            <div className="sticky-menu">
              <CardBody className="sticky-menu__wrapper d-flex justify-content-end">
                <PrimaryButton
                  className="ms-2"
                  data-e2e={`${E2E_NEW_ORDER_DONE}`}
                  onClick={handleBackButtonClick}
                >
                  {generateOrderDoneButtonLabel}
                </PrimaryButton>
              </CardBody>
            </div>
          </Card>
        </Col>
        <Col xl={0} xxl={4}>
          <FaqCard isOnOrderFlow />
        </Col>
      </Row>
    </Container>
  )
})
