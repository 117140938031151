import { useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { faClose, faSliders } from '@fortawesome/pro-solid-svg-icons'
import clsx from 'clsx'
import FocusTrap from 'focus-trap-react'

import { Heading, LinkButton } from 'Components/_theme'
import { Icon } from 'Components/Icon/Icon'

import { useEscapeKeyListenerEffect } from 'Hooks'

import { E2E_FILTER_COLUMN_CLOSE_BUTTON } from 'Constants/e2e'

import { FilterColumnProps } from './FilterColumn.types'

export const FilterColumn = (props: FilterColumnProps) => {
  const { children, onClose, isVisible } = props

  const classNames = clsx(
    'FilterColumn',
    isVisible && 'FilterColumn--is-visible',
  )

  const intl = useIntl()
  const filterColRef = useRef<HTMLDivElement>(null)

  useEscapeKeyListenerEffect(onClose, isVisible)

  return (
    <FocusTrap
      active={isVisible}
      focusTrapOptions={{
        clickOutsideDeactivates: true,
      }}
    >
      <div ref={filterColRef} className={classNames}>
        <div className="FilterColumn-header">
          <Icon icon={faSliders} size={20} />

          <Heading size={5}>
            <FormattedMessage id="Filter_by" />
          </Heading>

          <div className="FilterColumn-header-close">
            <LinkButton
              aria-label={intl.formatMessage({ id: 'Close' })}
              data-e2e={E2E_FILTER_COLUMN_CLOSE_BUTTON}
              onClick={onClose}
            >
              <Icon icon={faClose} size={20} />
            </LinkButton>
          </div>
        </div>

        <div className="FilterColumn-content">{children}</div>
      </div>
    </FocusTrap>
  )
}
