import { action, makeObservable, observable } from 'mobx'

import type { RootStore } from '..'
import { UserData } from './userStore.types'

export class UserStore {
  user: UserData = {
    email: null,
    fullName: null,
    jwt: null,
    language: null,
    userId: null,
  }

  constructor(private readonly store: RootStore) {
    makeObservable(this, {
      setUser: action,
      user: observable,
    })
  }

  setUser = (user: UserData) => {
    this.user = user
  }

  fetchAclsAndSetUser = async (user: UserData) => {
    if (this.user.jwt !== user.jwt) {
      await this.store.aclsStore.fetchAllowedAcls()

      this.store.navigationStore.createMainNavigationConfig()
    }

    if (!user.jwt) {
      this.store.portalSettingsStore.setIsAppReady(true)
    }

    this.setUser(user)
  }
}
