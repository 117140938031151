import { useContext } from 'react'

import { ModalContext } from 'Portal/src/providers/ModalProvider/ModalProvider'

/**
 * useModal hook is used to set modal context
 */
export const useModal = () => {
  const context = useContext(ModalContext)

  if (typeof context === 'undefined' || context === null) {
    throw new Error('useModal must be used inside ModalProvider')
  }

  return context
}
