import { useCallback, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useParams } from 'react-router-dom'
import { CellContext, ColumnDef } from '@tanstack/react-table'

import { Badge, Table } from 'Components/_theme'

import { E2E_TABLE_LINK_DEPOTS } from 'Constants/e2e'

import { GLOBAL_ROUTE_PATHS } from 'Portal/src/router/Router.constants'
import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import { DepotsTableProps, OrganistionDepotsWithUrl } from './Depots.types'

export const DepotsTable = (props: DepotsTableProps) => {
  const { depots, isLoading, isSearching } = props
  const { organisationId } = useParams<{ organisationId: string }>()
  const intl = useIntl()

  const renderDepotNameHeader = useCallback(
    () => <FormattedMessage id="DepotListContent_header_name" />,
    [],
  )

  const renderDevicesHeader = useCallback(
    () => <FormattedMessage id="DepotListContent_header_devices" />,
    [],
  )

  const renderTypeHeader = useCallback(
    () => <FormattedMessage id="DepotListContent_header_type" />,
    [],
  )

  const renderDepotNameCell = useCallback(
    ({ cell }: CellContext<OrganistionDepotsWithUrl, unknown>) => {
      const depot = cell.row.original
      const url = composePath({
        params: {
          depotId: depot.id,
          organisationId,
        },
        path: GLOBAL_ROUTE_PATHS.organisation.depot,
      })

      return (
        <Link
          className="d-flex align-items-center h-100 link-gray--no-hover"
          title={depot.locationName}
          to={url}
        >
          <strong className="text-ellipsis">{depot.locationName}</strong>
        </Link>
      )
    },
    [organisationId],
  )

  const renderExpandableContent = useCallback(
    ({ cell }: CellContext<OrganistionDepotsWithUrl, unknown>) => {
      const depot = cell.row.original

      return (
        <div className="w-100 Table-details">
          <div className="Table-details-row">
            <span className="Table-row--expandable-title">
              {renderDevicesHeader()}
            </span>

            <span className="text-ellipsis">
              {depot.stocklistItems?.totalCount}
            </span>
          </div>

          <div className="Table-details-row">
            <span className="Table-row--expandable-title">
              {renderTypeHeader()}
            </span>

            <span className="text-ellipsis">
              {depot.isDepotOfTheReturn && (
                <Badge color="pink" variant="secondary">
                  <FormattedMessage id="DepotListContent_badge_return" />
                </Badge>
              )}
            </span>
          </div>
        </div>
      )
    },
    [renderDevicesHeader, renderTypeHeader],
  )

  const renderDevicesCell = useCallback(
    ({ cell }: CellContext<OrganistionDepotsWithUrl, unknown>) => {
      const depot = cell.row.original
      const url = composePath({
        params: {
          depotId: depot.id,
          organisationId,
        },
        path: GLOBAL_ROUTE_PATHS.organisation.depot,
      })

      return (
        <Link
          className="d-flex align-items-center h-100 link-gray--no-hover"
          title={depot.stocklistItems?.totalCount.toString()}
          to={url}
        >
          {depot.stocklistItems?.totalCount}
        </Link>
      )
    },
    [organisationId],
  )

  const renderTypeCell = useCallback(
    ({ cell }: CellContext<OrganistionDepotsWithUrl, unknown>) => {
      const depot = cell.row.original

      if (!depot.isDepotOfTheReturn) {
        return null
      }

      const url = composePath({
        params: {
          depotId: depot.id,
          organisationId,
        },
        path: GLOBAL_ROUTE_PATHS.organisation.depot,
      })

      return (
        <Link
          className="d-flex align-items-center h-100 link-gray--no-hover"
          title={intl.formatMessage({ id: 'DepotListContent_badge_return' })}
          to={url}
        >
          <Badge color="pink" variant="secondary">
            <FormattedMessage id="DepotListContent_badge_return" />
          </Badge>
        </Link>
      )
    },
    [intl, organisationId],
  )

  const columns = useMemo<ColumnDef<OrganistionDepotsWithUrl>[]>(
    () => [
      {
        cell: renderDepotNameCell,
        header: renderDepotNameHeader,
        id: 'depot_name',
        meta: {
          ExpandableContent: renderExpandableContent,
        },
      },
      {
        cell: renderDevicesCell,
        header: renderDevicesHeader,
        id: 'devices',
      },
      {
        cell: renderTypeCell,
        header: renderTypeHeader,
        id: 'type',
      },
    ],
    [
      renderDepotNameCell,
      renderDepotNameHeader,
      renderDevicesCell,
      renderDevicesHeader,
      renderExpandableContent,
      renderTypeCell,
      renderTypeHeader,
    ],
  )

  const data = useMemo<OrganistionDepotsWithUrl[]>(
    () =>
      depots.map(depot => {
        const url = composePath({
          params: {
            depotId: depot.id,
            organisationId,
          },
          path: GLOBAL_ROUTE_PATHS.organisation.depot,
        })

        return {
          ...depot,
          url,
        }
      }),
    [depots, organisationId],
  )

  return (
    <Table
      className="Table--depots"
      columns={columns}
      data={data}
      e2eRowId={E2E_TABLE_LINK_DEPOTS}
      isLoading={isLoading}
      isSearching={isSearching}
    />
  )
}
