import { components, GroupBase, ValueContainerProps } from 'react-select'
import clsx from 'clsx'

import { Icon } from 'Components/Icon/Icon'
import { SelectOption } from 'Components/Select/Select.types'

export const SelectValueContainer = <
  Option extends SelectOption,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: ValueContainerProps<Option, IsMulti, Group>,
) => {
  const { children, selectProps } = props
  const { iconProps } = selectProps

  return (
    <components.ValueContainer {...props}>
      {iconProps?.icon && (
        <Icon
          {...iconProps}
          className={clsx('ReactSelect__custom-icon', iconProps.className)}
        />
      )}

      {children}
    </components.ValueContainer>
  )
}
