import { OverlayTrigger, Popover } from 'react-bootstrap'
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons'
import clsx from 'clsx'

import { Text } from 'Components/_theme'
import { Icon } from 'Components/Icon/Icon'

import { OfferSummaryItemProps } from './OfferSummaryItem.types'

export const OfferSummaryItem = (props: OfferSummaryItemProps) => {
  const { name, price, tooltipContent, muted, largePrice, currencySymbol } =
    props

  return (
    <div className="row">
      <div className="col">
        <div className={clsx('OfferSummaryItem-name', { muted })}>
          {name}
          {tooltipContent && (
            <OverlayTrigger
              overlay={
                <Popover>
                  <Popover.Body>{tooltipContent}</Popover.Body>
                </Popover>
              }
              placement="right"
              trigger={['hover', 'focus']}
            >
              <span className="d-inline-block">
                <Icon className="ms-2" icon={faCircleInfo} size={14} />
              </span>
            </OverlayTrigger>
          )}
        </div>
      </div>

      <div className="col">
        <Text
          className={clsx('OfferSummaryItem-price text-end', {
            large: largePrice,
            muted,
          })}
        >
          {price.toFixed(2)}&nbsp;{currencySymbol}
        </Text>
      </div>
    </div>
  )
}
