import { useCallback, useMemo, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import {
  useCurrentRefinements,
  useInstantSearch,
  useNumericMenu,
} from 'react-instantsearch'
import { useLocation } from 'react-router-dom'
import { useMountEffect } from '@react-hookz/web'
import { has } from 'lodash-es'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react'

import { ReturnsFilterColumn } from 'Modules/returns/components/ReturnsFilterColumn/ReturnsFilterColumn'
import { ReturnsPreviewColumn } from 'Modules/returns/components/ReturnsPreviewColumn/ReturnsPreviewColumn'
import { ReturnsTable as ReturnsTableDefault } from 'Modules/returns/components/ReturnsTable/ReturnsTable'
import { ReturnsTable } from 'Modules/returns/components/ReturnsTableInstantsearch/ReturnsTable'

import { Card } from 'Components/_theme'
import { ContentSidebar } from 'Components/ContentSidebar/ContentSidebar'
import { FaqCard } from 'Components/FaqCard/FaqCard'
import { ImpactTracker } from 'Components/ImpactTracker/ImpactTracker'
import { ObserveMeOnceIAmMounted } from 'Components/ObserveMeOnceIAmMounted/ObserveMeOnceIAmMounted'
import { PageErrorState } from 'Components/PageErrorState/PageErrorState'
import { PageHeader } from 'Components/PageHeader/PageHeader'
import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'
import { TableFooter } from 'Components/TableFooter/TableFooter'
import { TableHeader } from 'Components/TableHeader/TableHeader'

import { useDefaultListTable, useSidebarVisibility } from 'Hooks'

import {
  getActiveFilters,
  getQueryObject,
  toggleIntercomVisibility,
} from 'Utilities'

import {
  getReturnCases,
  getReturnCasesVariables,
} from 'Constants/graphql/queries/listing/__generated__/getReturnCases'
import { GET_RETURN_CASES } from 'Constants/graphql/queries/listing/GetReturnCases'
import { PortalSectionEnum } from 'Constants/portalSection'

import { GoToPage } from 'Algolia/components/GoToPage/GoToPage'
import { PageSizeSelect } from 'Algolia/components/PageSizeSelect/PageSizeSelect'
import { Pagination } from 'Algolia/components/Pagination/Pagination'
import { useCurrentRefinement } from 'Algolia/hooks/useCurrentRefinement/useCurrentRefinement'

import { ReportType } from 'Portal/__generated__/globalTypes'

export const ReturnsContent = observer(() => {
  const { search: locationSearch } = useLocation()
  const parsedSearch = getQueryObject(locationSearch)
  const defaultListActiveFilters = getActiveFilters(parsedSearch)

  const isOverdueFilterActive = useMemo(
    () =>
      Boolean(
        defaultListActiveFilters.find(
          activeFilter => activeFilter.name === 'overdue',
        ),
      ),
    [defaultListActiveFilters],
  )

  const endTimestamp = DateTime.now()
    .startOf('day')
    .set({ hour: 0, minute: 0, second: 0 })
    .minus({ weeks: 6 })
    .set({ hour: 23, minute: 59, second: 59 })
    .toSeconds()

  const { items: activeFilters } = useCurrentRefinements()

  const shouldUseDefaultListTable = activeFilters.length === 0

  const openSinceRefinement = useCurrentRefinement('return_case.open_since')
  const [first, last] = openSinceRefinement

  const isOverdueRefinementActive = Boolean(
    first?.value &&
      last?.value &&
      first.value === -1 &&
      last.value === endTimestamp,
  )

  const isOverdue = shouldUseDefaultListTable
    ? isOverdueFilterActive
    : isOverdueRefinementActive

  const {
    data: dataDefaultListTable,
    error: errorDefaultListTable,
    hasNextPage: hasNextPageDefaultListTable,
    intersectionObserver: intersectionObserverDefaultListTable,
    isLoading: isLoadingDefaultListTable,
    totalCount: totalCountDefaultListTable,
  } = useDefaultListTable<getReturnCases, getReturnCasesVariables>({
    dataObjectPropertyName: 'returnCases',
    extraVariables: {
      isOverdue,
    },
    isDefaultListTableInactive: !shouldUseDefaultListTable,
    organisationQuery: GET_RETURN_CASES,
  })

  const [selectedEntityId, setSelectedEntityId] = useState('')
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null)
  const [isPreviewColumnOpen, setIsPreviewColumnOpen] = useState(false)
  const [isFilterColumnOpen, setIsFilterColumnOpen] = useState(false)
  const isSidebarOpen = isPreviewColumnOpen || isFilterColumnOpen
  const { isVisible: isPreviewColumnVisible } = useSidebarVisibility(
    isPreviewColumnOpen,
    isSidebarOpen,
  )
  const { isVisible: isFilterColumnVisible } = useSidebarVisibility(
    isFilterColumnOpen,
    isSidebarOpen,
  )

  const { status, results, error } = useInstantSearch()
  const isLoading = status === 'loading' && !results.hits
  const totalCount = results?.nbHits ?? 0

  const handleCloseFilterColumn = useCallback(() => {
    setIsFilterColumnOpen(false)
  }, [])

  const handleOpenPreviewColumn = useCallback(
    (id: string, rowIndex: number | null) => {
      handleCloseFilterColumn()

      toggleIntercomVisibility(false)

      setSelectedEntityId(id)
      setSelectedRowIndex(rowIndex)
      setIsPreviewColumnOpen(true)
    },
    [handleCloseFilterColumn],
  )

  const handleClosePreviewColumn = useCallback(() => {
    toggleIntercomVisibility(true)

    setIsPreviewColumnOpen(false)
    setSelectedRowIndex(null)
  }, [])

  const handleOpenFilterColumn = useCallback(() => {
    handleClosePreviewColumn()

    setIsFilterColumnOpen(true)
  }, [handleClosePreviewColumn])

  const { refine } = useNumericMenu({
    attribute: 'return_case.open_since',
    // not used but required by the hook
    items: [{ label: 'All' }],
  })

  const shouldHideDownloadReportComponents = useMemo(() => {
    // For Instantsearch list
    if (!shouldUseDefaultListTable && isOverdueRefinementActive) {
      return (
        totalCount === 0 || // When we don't have any results
        activeFilters.length !== 1 // When more than one refinement is active (for Instantsearch list)
      )
    }

    // For Default (fallback) list
    if (shouldUseDefaultListTable && isOverdueFilterActive) {
      return (
        totalCountDefaultListTable === 0 || // When we don't have any results
        defaultListActiveFilters.length !== 1 // When more than one filter is active (for Default list)
      )
    }

    return undefined
  }, [
    activeFilters.length,
    defaultListActiveFilters.length,
    isOverdueFilterActive,
    isOverdueRefinementActive,
    shouldUseDefaultListTable,
    totalCount,
    totalCountDefaultListTable,
  ])

  useMountEffect(() => {
    // Add overdue refinement if 'overdue' search param is included in URL
    const isOverdueSearchParam = has(parsedSearch, 'overdue')

    if (isOverdueSearchParam) {
      refine(
        encodeURI(
          JSON.stringify({
            end: endTimestamp,
            start: -1,
          }),
        ),
      )
    }
  })

  if (shouldUseDefaultListTable ? errorDefaultListTable : error) {
    return <PageErrorState />
  }

  if (
    shouldUseDefaultListTable
      ? isLoadingDefaultListTable && !dataDefaultListTable
      : isLoading
  ) {
    return <PageLoadingState />
  }

  if (shouldUseDefaultListTable && !dataDefaultListTable) {
    return <PageLoadingState />
  }

  return (
    <Container className="pt-6" fluid>
      <div role="banner">
        <PageHeader portalSection={PortalSectionEnum.Returns} />
      </div>

      <Row>
        <Col xl={12} xxl={8}>
          <Card className="mb-4">
            <TableHeader
              buttonIsVisible={false}
              downloadReportFilter={JSON.stringify({
                is_overdue: isOverdue,
              })}
              downloadReportType={ReportType.return_case}
              filterButtonOnClick={handleOpenFilterColumn}
              hideDownloadReportComponents={shouldHideDownloadReportComponents}
              hideFilterButton={!!error}
              totalCount={
                shouldUseDefaultListTable
                  ? totalCountDefaultListTable
                  : totalCount
              }
            />

            {shouldUseDefaultListTable ? (
              <Row>
                <Col>
                  <ReturnsTableDefault
                    isLoading={isLoadingDefaultListTable}
                    isSearching={false}
                    onOpenPreviewColumn={handleOpenPreviewColumn}
                    returns={dataDefaultListTable}
                    selectedRowIndex={selectedRowIndex}
                  />
                </Col>
              </Row>
            ) : (
              <ReturnsTable
                isLoading={isLoading}
                onOpenPreviewColumn={handleOpenPreviewColumn}
                selectedRowIndex={selectedRowIndex}
              />
            )}

            {!shouldUseDefaultListTable && (
              <TableFooter>
                <PageSizeSelect />

                <Pagination totalCount={totalCount} />

                <GoToPage totalCount={totalCount} />
              </TableFooter>
            )}
          </Card>

          {shouldUseDefaultListTable && (
            <Row className="p-1">
              <Col>
                {hasNextPageDefaultListTable && !isLoadingDefaultListTable && (
                  <ObserveMeOnceIAmMounted
                    intersectionObserver={intersectionObserverDefaultListTable}
                  />
                )}
              </Col>
            </Row>
          )}
        </Col>

        <ContentSidebar
          isSidebarOpen={isSidebarOpen}
          renderStickyColumnSideContent={
            <>
              <ImpactTracker />
              <FaqCard />
            </>
          }
        >
          <ReturnsPreviewColumn
            entityId={selectedEntityId}
            isVisible={isPreviewColumnVisible}
            onClosePreviewColumn={handleClosePreviewColumn}
          />

          <ReturnsFilterColumn
            isVisible={isFilterColumnVisible}
            onCloseFilterColumn={handleCloseFilterColumn}
          />
        </ContentSidebar>
      </Row>
    </Container>
  )
})
