import { getOrganisation_organisation } from 'Constants/graphql/queries/organisation/__generated__/getOrganisation'

export const createIntercom = (data: getOrganisation_organisation) => {
  window.Intercom('update', {
    company: {
      company_id: data.id,
      name: data.fullName,
    },
    organisation_id: data.id,
    organisation_link: `${window.location.origin}/admin/organisation/organisation/${data.id}`,
  })
}
