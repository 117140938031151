import { clsx } from 'clsx'

import { Button } from 'Components/_theme/Button/Button'

import { TertiaryButtonProps } from './TertiaryButton.types'

export const TertiaryButton = (props: TertiaryButtonProps) => {
  const { className, appearance = 'fill', ...restProps } = props

  const classNames = clsx(
    'TertiaryButton',
    appearance !== 'fill' && `TertiaryButton-${appearance}`,
    className,
  )

  return <Button className={classNames} {...restProps} />
}
