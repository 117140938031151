import { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { faTruckArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { useMediaQuery } from '@react-hookz/web'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import { observer } from 'mobx-react'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { TableCard } from 'Components/TableCard/TableCard'

import { useStore } from 'Stores/index'

import { buttonize, getFullContactName } from 'Utilities'

import { PORTAL_BREAKPOINTS } from 'Constants/constants'

import { UnreturnedDevice } from 'Types/dashboard/organisationDashboard.types'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

export const UnreturnedDevicesCard = observer(() => {
  const { dashboardStore, portalSettingsStore } = useStore()
  const { unreturnedDevices } = dashboardStore
  const { i18n } = portalSettingsStore

  const { organisationId } = useParams<{
    organisationId?: string
  }>()
  const isMobile = useMediaQuery(`(max-width: ${PORTAL_BREAKPOINTS.LG})`)

  const history = useHistory()

  const handleCellClick = useCallback(
    (
      event:
        | React.KeyboardEvent<HTMLDivElement>
        | React.MouseEvent<HTMLDivElement, MouseEvent>,
      returnCaseId: number,
    ) => {
      event.preventDefault()

      const path = composePath({
        params: {
          organisationId,
          returnCaseId,
        },
        path: GLOBAL_ROUTE_PATHS.organisation.return,
      })

      history.push(path)
    },
    [history, organisationId],
  )

  const renderDeviceHeader = useCallback(
    () => <FormattedMessage id="Most_recent" />,
    [],
  )

  const renderEmployeeHeader = useCallback(
    () => <FormattedMessage id="Employee" />,
    [],
  )

  const renderDeviceCell = useCallback(
    ({ cell }: CellContext<UnreturnedDevice, unknown>) => {
      const unreturnedDevice = cell.row.original

      return (
        <div
          {...buttonize(handleCellClick, unreturnedDevice.id)}
          className="d-flex align-items-center"
        >
          <span className="text-line-clamp fw-600">
            {unreturnedDevice.deviceName}
          </span>
        </div>
      )
    },
    [handleCellClick],
  )

  const renderEmployeeCell = useCallback(
    ({ cell }: CellContext<UnreturnedDevice, unknown>) => {
      const unreturnedDevice = cell.row.original

      return (
        <div
          {...buttonize(handleCellClick, unreturnedDevice.id)}
          className="d-flex align-items-center"
        >
          <span className="text-ellipsis">
            {getFullContactName(unreturnedDevice)}
          </span>
        </div>
      )
    },
    [handleCellClick],
  )

  const columns = useMemo<ColumnDef<UnreturnedDevice>[]>(() => {
    const tableColumns = [
      {
        cell: renderDeviceCell,
        header: renderDeviceHeader,
        id: 'device',
      },
    ]

    if (!isMobile) {
      tableColumns.splice(1, 0, {
        cell: renderEmployeeCell,
        header: renderEmployeeHeader,
        id: 'employee',
      })
    }

    return tableColumns
  }, [
    renderDeviceCell,
    renderDeviceHeader,
    isMobile,
    renderEmployeeCell,
    renderEmployeeHeader,
  ])

  const data = useMemo<UnreturnedDevice[]>(
    () => unreturnedDevices || [],
    [unreturnedDevices],
  )

  if (!unreturnedDevices) {
    return null
  }

  return (
    <TableCard
      className="mb-6"
      columns={columns}
      data={data}
      heading="UnreturnedDevicesCard_heading"
      icon={faTruckArrowRight}
      iconColor="red"
      tableClassName="TableCard--unreturnedDevices"
      total={unreturnedDevices.length.toLocaleString(i18n.locale)}
    />
  )
})
