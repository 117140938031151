import { useCallback, useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useMediaQuery, useMountEffect } from '@react-hookz/web'
import { isEmpty } from 'lodash-es'
import { observer } from 'mobx-react'

import { getProlongPath } from 'Modules/rentals/utils/rental.utils'

import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'
import { PreviewColumnTypeEnum } from 'Components/PreviewColumn/PreviewColumn.types'
import { ScrollToTop } from 'Components/ScrollToTop/ScrollToTop'

import { useStore } from 'Stores/index'
import {
  OrderFilterTypeEnum,
  OrderSortCriteriaEnum,
  OrderStepEnum,
} from 'Stores/orderStore/orderStore.types'

import { useIsOrganisation } from 'Hooks'

import { isOrderLimitExceeded } from 'Utilities'

import { PORTAL_BREAKPOINTS } from 'Constants/constants'

import { OrderDevice } from 'Portal/src/mappers/orderDeviceMapper/orderDevice.types'

import { DeviceChooser } from '../../DeviceChooser/DeviceChooser'
import { DeviceChooserFilters } from '../../DeviceChooserFilters/DeviceChooserFilters'
import { OrderDisabledAlert } from '../../OrderDisabledAlert/OrderDisabledAlert'
import { OrderStickyFooter } from '../../OrderStickyFooter/OrderStickyFooter'
import { RentalProlongingButton } from '../../RentalProlongingButton/RentalProlongingButton'
import { RentCategoryChooser } from '../../RentCategoryChooser/RentCategoryChooser'
import { DeviceStepProps } from '../Steps.types'

export const DeviceStep = observer((props: DeviceStepProps) => {
  const { handleNextStep, handlePreviousStep, isProlongingPossible } = props
  const isTablet = useMediaQuery(`(min-width: ${PORTAL_BREAKPOINTS.LG})`)
  const { orderStore, rentalStore, portalSettingsStore, portalConfigStore } =
    useStore()
  const {
    setPreviewColumnInitialiseSettings,
    setTogglePreviewColumn,
    previewColumnSettings,
  } = portalSettingsStore
  const { portalConfig } = portalConfigStore
  const {
    chosenDevice,
    clearFilters,
    orderDevices,
    filters,
    limitationRules,
    setFilters,
    setSortCriteria,
    sortCriteria,
    setChosenDevice,
    setCurrentStep,
    isAccessoriesLoading,
    chosenAccessories,
    setPreviewColumnDevice,
    isCYOD,
  } = orderStore
  const { rentalDetails } = rentalStore

  const { employeeId, organisationId } = useParams<{
    rentalId: string
    employeeId?: string
    organisationId?: string
  }>()

  const isOrganisation = useIsOrganisation()

  const history = useHistory()

  const groupDevicesByRentCategory = !!portalConfig?.groupDevicesByRentCategory

  const groupProductOfferDevices =
    portalConfig?.enableProductOffers &&
    orderDevices?.groupedDevices &&
    orderDevices?.groupedDevices?.length > 1

  const handleNextAction = useCallback(() => {
    if (handleNextStep) {
      handleNextStep()
    }
  }, [handleNextStep])

  const handlePreviousAction = useCallback(() => {
    const isBundlePreviewOpen = previewColumnSettings.map(
      previewColumnSetting =>
        previewColumnSetting.type === PreviewColumnTypeEnum.Bundle &&
        previewColumnSetting.isOpen,
    )

    if (isBundlePreviewOpen) {
      setTogglePreviewColumn(PreviewColumnTypeEnum.Bundle, false)
    }

    if (handlePreviousStep) {
      handlePreviousStep()
    }
  }, [handlePreviousStep, previewColumnSettings, setTogglePreviewColumn])

  const handleResetSelectedAccessory = useCallback(
    (device: OrderDevice) => {
      const shouldResetSelectedAccessory =
        !isEmpty(chosenAccessories) &&
        chosenDevice &&
        chosenDevice.id !== device.id

      if (shouldResetSelectedAccessory) {
        chosenAccessories.clear()
      }
    },
    [chosenAccessories, chosenDevice],
  )

  const handleChooseRentCategory = useCallback(
    async (device: OrderDevice) => {
      handleResetSelectedAccessory(device)

      if (chosenDevice) {
        await setChosenDevice(null)
      }

      if (device.isBundle && isTablet) {
        await setPreviewColumnDevice(device)
        setTogglePreviewColumn(PreviewColumnTypeEnum.Bundle, true)
      } else {
        await setChosenDevice(device)
        setTogglePreviewColumn(PreviewColumnTypeEnum.Bundle, false)
        handleNextAction()
      }
    },
    [
      handleResetSelectedAccessory,
      chosenDevice,
      isTablet,
      setChosenDevice,
      setPreviewColumnDevice,
      setTogglePreviewColumn,
      handleNextAction,
    ],
  )

  const handleChooseDevice = useCallback(
    async (device: OrderDevice) => {
      handleResetSelectedAccessory(device)

      if (chosenDevice) {
        await setChosenDevice(null)
      }

      if (device.isBundle && isTablet) {
        await setPreviewColumnDevice(device)
        setTogglePreviewColumn(PreviewColumnTypeEnum.Bundle, true)
      } else {
        await setChosenDevice(device)
        setTogglePreviewColumn(PreviewColumnTypeEnum.Bundle, false)
        handleNextAction()
      }
    },
    [
      chosenDevice,
      handleNextAction,
      handleResetSelectedAccessory,
      isTablet,
      setChosenDevice,
      setTogglePreviewColumn,
      setPreviewColumnDevice,
    ],
  )

  const getFilteredDevices = useMemo(() => {
    if (!orderDevices?.allDevices?.length) {
      return []
    }

    const colorFilters = filters.get(OrderFilterTypeEnum.Colors)
    const productTypeFilters = filters.get(OrderFilterTypeEnum.ProductTypes)
    const manufacturerFilters = filters.get(OrderFilterTypeEnum.Manufacturers)
    const storageFilters = filters.get(OrderFilterTypeEnum.Storages)

    return orderDevices.allDevices
      .filter(orderItem => {
        const { deviceDefinition } = orderItem

        return (
          (!colorFilters?.size || colorFilters.has(deviceDefinition.color)) &&
          (!productTypeFilters?.size ||
            productTypeFilters.has(deviceDefinition.productType || '')) &&
          (!manufacturerFilters?.size ||
            manufacturerFilters.has(deviceDefinition.manufacturer)) &&
          (!storageFilters?.size ||
            storageFilters.has(deviceDefinition.storage.replaceAll(' ', '')))
        )
      })
      .sort((a, b) => {
        switch (sortCriteria) {
          case OrderSortCriteriaEnum.Manufacturer:
            return a.deviceDefinition.manufacturer.localeCompare(
              b.deviceDefinition.manufacturer,
            )
          case OrderSortCriteriaEnum.Storage:
            return (
              parseInt(a.deviceDefinition.storage, 10) -
              parseInt(b.deviceDefinition.storage, 10)
            )
          case OrderSortCriteriaEnum.CopaymentNet:
            return a.copaymentNet - b.copaymentNet
          case OrderSortCriteriaEnum.Default:
          default:
            return 0
        }
      })
  }, [orderDevices, filters, sortCriteria])

  useMountEffect(() => {
    setCurrentStep(OrderStepEnum.device)
  })

  useEffect(() => {
    const hasBundleDevice = orderDevices?.allDevices?.some(
      orderDevice => orderDevice.isBundle,
    )

    if (hasBundleDevice) {
      setPreviewColumnInitialiseSettings({
        handleOnRentAction: handleNextAction,
        type: PreviewColumnTypeEnum.Bundle,
      })
    }
  }, [
    handleNextAction,
    orderDevices?.allDevices,
    setPreviewColumnInitialiseSettings,
  ])

  if (isAccessoriesLoading && !chosenDevice?.isBundle) {
    return <PageLoadingState />
  }

  if (!isCYOD) {
    return <OrderDisabledAlert className="py-6" />
  }

  const redirectToProlonging = () => {
    if (!rentalDetails) {
      return
    }

    const path = getProlongPath(
      isOrganisation,
      rentalDetails?.rental,
      employeeId || rentalDetails.rental.employee?.id,
      organisationId,
    )

    history.push(path)
  }

  return (
    <>
      {!chosenDevice && <ScrollToTop />}

      {isProlongingPossible && (
        <RentalProlongingButton
          copaymentAmount={rentalDetails?.rental.coPaymentAmount}
          deviceName={
            rentalDetails?.rental.stocklistItem?.deviceDefinition?.name
          }
          imageUrl={
            rentalDetails?.rental.stocklistItem?.deviceDefinition?.imageURL ??
            ''
          }
          onClick={() => redirectToProlonging()}
          productType={
            rentalDetails?.rental.stocklistItem?.deviceDefinition?.productType
          }
        />
      )}

      {groupDevicesByRentCategory || groupProductOfferDevices ? (
        <RentCategoryChooser
          chosenDevice={chosenDevice}
          groupedDevices={orderDevices?.groupedDevices || null}
          limitationRules={limitationRules}
          onChooseDevice={handleChooseRentCategory}
        />
      ) : (
        <>
          {(!isEmpty(orderDevices?.allDevices) ||
            !isEmpty(orderDevices?.groupedDevices)) &&
            !isOrderLimitExceeded(limitationRules) && (
              <DeviceChooserFilters
                clearFilters={clearFilters}
                devices={orderDevices?.allDevices || null}
                devicesLength={getFilteredDevices.length}
                filters={filters}
                setFilters={setFilters}
                setSortCriteria={setSortCriteria}
                sortCriteria={sortCriteria}
              />
            )}

          {getFilteredDevices.length === 0 &&
          !isOrderLimitExceeded(limitationRules) &&
          (!isEmpty(orderDevices?.allDevices) ||
            !isEmpty(orderDevices?.groupedDevices)) ? (
            <br />
          ) : (
            <DeviceChooser
              chosenDeviceId={chosenDevice?.id}
              devices={getFilteredDevices}
              limitationRules={limitationRules}
              onChooseDevice={handleChooseDevice}
            />
          )}
        </>
      )}

      <OrderStickyFooter
        handlePreviousAction={handlePreviousStep && handlePreviousAction}
      />
    </>
  )
})
