import { gql } from '@apollo/client'

export const MUTATION_UPDATE_RETURN_CASE_TRACKING_INFORMATION = gql`
  mutation mutationUpdateReturnCaseTrackingInfo(
    $input: createUpdateReturnCaseTrackingInfoInput!
  ) {
    createUpdateReturnCaseTrackingInfo(input: $input) {
      id
      trackingReference
      carrier
    }
  }
`
