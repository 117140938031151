import { action, makeObservable, observable } from 'mobx'

import { PAGINATION } from 'Constants/constants'
import {
  getAllowedAcls,
  getAllowedAclsVariables,
} from 'Constants/graphql/queries/listing/__generated__/getAllowedAcls'
import { GET_ALLOWED_ACLS } from 'Constants/graphql/queries/listing/GetAllowedAcls'

import ApolloLoader from 'Portal/src/ApolloLoader'

export class AclsStore {
  allowedAcls: getAllowedAcls | null = null

  isLoadingAllowedAcls = true

  constructor() {
    makeObservable(this, {
      allowedAcls: observable,
      fetchAllowedAcls: action,
      isLoadingAllowedAcls: observable,
      setAllowedAcls: action,
      setIsLoadingAllowedAcls: action,
    })
  }

  fetchAllowedAcls = async () => {
    const { apolloClient } = ApolloLoader

    if (!apolloClient) return

    try {
      this.setIsLoadingAllowedAcls(true)

      const response = await apolloClient.query<
        getAllowedAcls,
        getAllowedAclsVariables
      >({
        query: GET_ALLOWED_ACLS,
        variables: {
          after: PAGINATION.AFTER,
          first: 1000,
        },
      })

      this.setAllowedAcls(response.data)
    } catch (error) {
      this.setAllowedAcls(null)
    } finally {
      this.setIsLoadingAllowedAcls(false)
    }
  }

  setAllowedAcls = (allowedAcls: getAllowedAcls | null) => {
    this.allowedAcls = allowedAcls
  }

  setIsLoadingAllowedAcls = (isLoading: boolean) => {
    this.isLoadingAllowedAcls = isLoading
  }
}
