import { useCallback, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useHistory, useParams } from 'react-router-dom'
import { faCartShopping, faCircleInfo } from '@fortawesome/pro-solid-svg-icons'
import { observer } from 'mobx-react'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import {
  getEarlyUpgradePath,
  getlostOrStolenModalType,
  getProlongPath,
  getRepairUpgradePath,
  getUpgradePath,
  hasOrganisationDepots,
} from 'Modules/rentals/utils/rental.utils'

import { Alert } from 'Components/_theme'
import { CardActions } from 'Components/CardActions/CardActions'
import { CardActionProps } from 'Components/CardActions/CardActions.types'
import { CardDetailsList } from 'Components/CardDetailsList/CardDetailsList'
import { CardDetailsListItem } from 'Components/CardDetailsList/CardDetailsList.types'
import { ContentCard } from 'Components/ContentCard/ContentCard'
import { FormatDate } from 'Components/FormatDate/FormatDate'
import { FormattedCurrency } from 'Components/FormattedCurrency/FormattedCurrency'

import { CancelDeviceModal } from 'Modals/CancelDeviceModal/CancelDeviceModal'
import { ExchangeDeviceModal } from 'Modals/ExchangeDeviceModal/ExchangeDeviceModal'
import { LegacyUpgradeDeviceModal } from 'Modals/LegacyUpgradeDeviceModal/LegacyUpgradeDeviceModal'
import { LostOrStolenModal } from 'Modals/LostOrStolenModal/LostOrStolenModal'
import { ReassignDeviceModal } from 'Modals/ReassignDeviceModal/ReassignDeviceModal'
import { ReplaceBrokenDeviceModal } from 'Modals/ReplaceBrokenDeviceModal/ReplaceBrokenDeviceModal'
import { RequestPurchaseModal } from 'Modals/RequestPurchaseModal/RequestPurchaseModal'
import { ReturnDeviceModal } from 'Modals/ReturnDeviceModal/ReturnDeviceModal'

import { useStore } from 'Stores/index'
import { OriginRouteTypeEnum } from 'Stores/orderStore/orderStore.types'

import { useActiveUser, useIsOrganisation, useModal } from 'Hooks'

import {
  E2E_CANCEL_DEVICE_ACTION,
  E2E_EXCHANGE_DEVICE_ACTION,
  E2E_LOST_STOLEN_DEVICE_ACTION,
  E2E_LOST_STOLEN_RENTAL_DETAILS_ALERT,
  E2E_ORDER_EARLY_UGPRADE_ACTION,
  E2E_ORDER_LEGACY_UPGRADE_ACTION,
  E2E_ORDER_PROLONG_ACTION,
  E2E_ORDER_UPGRADE_ACTION,
  E2E_PURCHASE_DEVICE_ACTION,
  E2E_REASSIGN_DEVICE_ACTION,
  E2E_REPLACE_BROKEN_DEVICE_ACTION,
  E2E_REPLACE_FROM_DEPOT_ACTION,
  E2E_RETURN_DEVICE_ACTION,
} from 'Constants/e2e'

import { iconCancelDevice } from 'Icons/iconCancelDevice'
import { iconExchangeDevice } from 'Icons/iconExchangeDevice'
import { iconLostDevice } from 'Icons/iconLostDevice'
import { iconReassignDevice } from 'Icons/iconReassignDevice'
import { iconRepairDevice } from 'Icons/iconRepairDevice'
import { iconReturnDevice } from 'Icons/iconReturnDevice'
import { iconUpgradeDevice } from 'Icons/iconUpgradeDevice'

import { CaseItemType } from 'Portal/__generated__/globalTypes'
import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import {
  RentalDetailsCardModalTypes,
  RentalDetailsCardProps,
} from './RentalDetailsCard.types'
import { getOpenCaseItemId } from './RentalDetailsCard.utils'

export const RentalDetailsCard = observer((props: RentalDetailsCardProps) => {
  const { rental, refetch } = props
  const { orderStore, portalSettingsStore } = useStore()
  const { setOriginRoute } = orderStore
  const { isAppReadOnly } = portalSettingsStore
  const isOrganisation = useIsOrganisation()
  const history = useHistory()
  const { employeeId, organisationId } = useParams<{
    organisationId?: string
    employeeId?: string
  }>()
  const { activeOrganisation } = useActiveUser()
  const isOrganisationWithDepots = activeOrganisation
    ? hasOrganisationDepots(activeOrganisation)
    : false

  const intl = useIntl()

  const { setModal, hideModal } = useModal()

  const lostOrStolenModalType = useMemo(
    () => getlostOrStolenModalType(isOrganisation, isOrganisationWithDepots),
    [isOrganisation, isOrganisationWithDepots],
  )

  const handleModalSubmit = useCallback(() => {
    refetch()

    hideModal()
  }, [hideModal, refetch])

  const handleOpenCancelSmartphoneModal = useCallback(() => {
    setModal({
      component: CancelDeviceModal,
      isOpen: true,
      props: {
        handleModalSubmit,
        rental,
      },
    })
  }, [handleModalSubmit, setModal, rental])

  const handleOpenReplaceBrokenDeviceModal = useCallback(
    (isReplaceFromDepot: boolean) => {
      setModal({
        component: ReplaceBrokenDeviceModal,
        isOpen: true,
        props: {
          isReplaceFromDepot,
          onSubmit: refetch,
          rental,
        },
      })
    },
    [refetch, rental, setModal],
  )

  const handleRepairUpgrade = useCallback(() => {
    setOriginRoute({
      type: OriginRouteTypeEnum.Rental,
      url: history.location.pathname,
    })

    const path = getRepairUpgradePath(
      isOrganisation,
      rental,
      employeeId || rental.employee?.id,
      organisationId,
    )

    history.push(path)
  }, [
    employeeId,
    history,
    isOrganisation,
    organisationId,
    rental,
    setOriginRoute,
  ])

  const handleOpenLegacyUpgradeDeviceModal = useCallback(() => {
    setModal({
      component: LegacyUpgradeDeviceModal,
      isOpen: true,
      props: {
        handleSubmit: handleModalSubmit,
        rental,
      },
    })
  }, [handleModalSubmit, rental, setModal])

  const handleOpenExchangeDeviceModal = useCallback(() => {
    setModal({
      component: ExchangeDeviceModal,
      isOpen: true,
      props: {
        handleSubmit: handleModalSubmit,
        rental,
      },
    })
  }, [handleModalSubmit, rental, setModal])

  const handleOpenReturnDeviceModal = useCallback(() => {
    setModal({
      component: ReturnDeviceModal,
      isOpen: true,
      props: {
        handleSubmit: handleModalSubmit,
        rental,
      },
    })
  }, [handleModalSubmit, rental, setModal])

  const handleOpenReassignDeviceModal = useCallback(() => {
    setModal({
      component: ReassignDeviceModal,
      isOpen: true,
      props: {
        handleSubmit: handleModalSubmit,
        rental,
      },
    })
  }, [handleModalSubmit, rental, setModal])

  const handleOpenRequestPurchaseModal = useCallback(() => {
    setModal({
      component: RequestPurchaseModal,
      isOpen: true,
      props: {
        onHide: handleModalSubmit,
        rental,
      },
    })
  }, [handleModalSubmit, rental, setModal])

  const handleOpenLostOrStolenModal = useCallback(() => {
    setModal({
      component: LostOrStolenModal,
      isOpen: true,
      props: {
        onSubmit: refetch,
        rental,
        type: lostOrStolenModalType,
      },
    })
  }, [lostOrStolenModalType, refetch, rental, setModal])

  const handleEarlyUpgrade = useCallback(() => {
    setOriginRoute({
      type: OriginRouteTypeEnum.Rental,
      url: history.location.pathname,
    })

    const path = getEarlyUpgradePath(
      isOrganisation,
      rental,
      employeeId || rental.employee?.id,
      organisationId,
    )

    history.push(path)
  }, [
    employeeId,
    history,
    isOrganisation,
    organisationId,
    rental,
    setOriginRoute,
  ])

  const handleUpgrade = useCallback(() => {
    setOriginRoute({
      type: OriginRouteTypeEnum.Rental,
      url: history.location.pathname,
    })

    const path = getUpgradePath(
      isOrganisation,
      rental,
      employeeId || rental.employee?.id,
      organisationId,
    )

    history.push(path)
  }, [
    employeeId,
    history,
    isOrganisation,
    organisationId,
    rental,
    setOriginRoute,
  ])

  const handleReplaceBrokenDevice = useCallback(
    (isReplaceFromDepot: boolean) => {
      const isRepairUpgradeAvailable =
        rental.actions.showRepairUpgradeAction &&
        rental.actions.showReplacementOrderFlow

      if (isRepairUpgradeAvailable) {
        handleRepairUpgrade()
      } else {
        handleOpenReplaceBrokenDeviceModal(isReplaceFromDepot)
      }
    },
    [
      rental.actions.showRepairUpgradeAction,
      rental.actions.showReplacementOrderFlow,
      handleRepairUpgrade,
      handleOpenReplaceBrokenDeviceModal,
    ],
  )

  const handleProlong = useCallback(() => {
    setOriginRoute({
      type: OriginRouteTypeEnum.Rental,
      url: history.location.pathname,
    })

    const path = getProlongPath(
      isOrganisation,
      rental,
      employeeId || rental.employee?.id,
      organisationId,
    )

    history.push(path)
  }, [
    employeeId,
    history,
    isOrganisation,
    organisationId,
    rental,
    setOriginRoute,
  ])

  const generateListItems = useMemo(
    (): CardDetailsListItem[] => [
      ...(isOrganisation && rental.amount && !rental.forcePriceHide
        ? [
            {
              label: 'RentalDetails_monthly_amount',
              value: <FormattedCurrency value={rental.amount} />,
            },
          ]
        : []),
      {
        label: 'RentalDetails_copayment',
        value: <FormattedCurrency value={rental.coPaymentAmount} />,
      },
      {
        label: 'RentalDetails_minimum_term',
        value: (
          <FormattedMessage
            id="RentalDetails_months"
            values={{
              total: rental.minimumContractDuration,
            }}
          />
        ),
      },
      {
        label: 'RentalDetails_start_of_rental',
        value: <FormatDate value={rental.startAt} />,
      },
      ...(!rental.rentalProlonging && !!rental.newDeviceDate
        ? [
            {
              label: 'RentalDetails_select_a_new',
              value: <FormatDate value={rental.newDeviceDate} />,
            },
          ]
        : [
            {
              label: 'Prolongation_valid_till',
              value: <FormatDate value={rental.rentalProlonging?.validTill} />,
            },
          ]),
      ...(isOrganisation
        ? [
            {
              label: 'RequestDetails_po_rcn',
              value: rental?.poRcn,
            },
            {
              label: 'RequestDetails_custom_client_reference',
              value: rental?.customClientReference,
            },
            {
              label: 'RequestDetails_billing_identifier',
              value: rental?.billingIdentifier,
            },
          ]
        : []),
    ],
    [
      isOrganisation,
      rental.amount,
      rental?.billingIdentifier,
      rental.coPaymentAmount,
      rental?.customClientReference,
      rental.forcePriceHide,
      rental.minimumContractDuration,
      rental.newDeviceDate,
      rental?.poRcn,
      rental.rentalProlonging,
      rental.startAt,
    ],
  )

  const generateActions = useMemo(
    (): CardActionProps[] => [
      {
        e2eSelector: E2E_ORDER_EARLY_UGPRADE_ACTION,
        icon: iconUpgradeDevice,
        id: RentalDetailsCardModalTypes.EarlyUpgradeDevice,
        isHidden: !rental.actions.showEarlyUpgradeAction,
        name: 'Early_upgrade_device',
        onActionClick: handleEarlyUpgrade,
      },
      {
        e2eSelector: E2E_ORDER_LEGACY_UPGRADE_ACTION,
        icon: iconUpgradeDevice,
        id: RentalDetailsCardModalTypes.LegacyUpgradeDevice,
        isHidden: !rental.actions.showUpgradeAction,
        name: 'Upgrade_device',
        onActionClick: handleOpenLegacyUpgradeDeviceModal,
      },
      {
        e2eSelector: E2E_ORDER_UPGRADE_ACTION,
        icon: iconUpgradeDevice,
        id: RentalDetailsCardModalTypes.RentalCompleteUpgradeDevice,
        isHidden: !rental.actions.showStandardUpgradeAction,
        name: 'Upgrade_device',
        onActionClick: handleUpgrade,
      },
      {
        e2eSelector: E2E_ORDER_PROLONG_ACTION,
        icon: iconUpgradeDevice,
        id: RentalDetailsCardModalTypes.ProlongDevice,
        isHidden: !rental.actions.showDeviceProlongingAction,
        name: 'Extend_rental',
        onActionClick: handleProlong,
      },
      {
        e2eSelector: E2E_REASSIGN_DEVICE_ACTION,
        icon: iconReassignDevice,
        id: RentalDetailsCardModalTypes.ReassignDevice,
        isHidden: !rental.actions.showReassignDeviceAction,
        name: 'Reassign_device',
        onActionClick: handleOpenReassignDeviceModal,
      },
      {
        e2eSelector: E2E_REPLACE_BROKEN_DEVICE_ACTION,
        icon: iconRepairDevice,
        id: RentalDetailsCardModalTypes.ReplaceBrokenDevice,
        isHidden: !rental.actions.showReplaceBrokenDeviceAction,
        name: 'Replace_broken_device',
        onActionClick: () => handleReplaceBrokenDevice(false),
      },
      {
        e2eSelector: E2E_REPLACE_FROM_DEPOT_ACTION,
        icon: iconRepairDevice,
        id: RentalDetailsCardModalTypes.ReplaceFromDepot,
        isHidden: !rental.actions.showReplaceFromDepotAction,
        name: 'Replace_from_depot',
        onActionClick: () => handleReplaceBrokenDevice(true),
      },
      {
        e2eSelector: E2E_LOST_STOLEN_DEVICE_ACTION,
        icon: iconLostDevice,
        id: RentalDetailsCardModalTypes.LostOrStolenModal,
        isHidden: !rental.actions.showLostOrStolenAction,
        name: 'Device_lost_stolen',
        onActionClick: handleOpenLostOrStolenModal,
      },
      {
        e2eSelector: E2E_EXCHANGE_DEVICE_ACTION,
        icon: iconExchangeDevice,
        id: RentalDetailsCardModalTypes.ExchangeDevice,
        isHidden: !rental.actions.showExchangeAction,
        name: 'Exchange_device',
        onActionClick: handleOpenExchangeDeviceModal,
      },
      {
        e2eSelector: E2E_RETURN_DEVICE_ACTION,
        icon: iconReturnDevice,
        id: RentalDetailsCardModalTypes.ReturnDevice,
        isHidden: !rental.actions.showReturnAction,
        name: 'Return_device',
        onActionClick: handleOpenReturnDeviceModal,
      },
      {
        e2eSelector: E2E_CANCEL_DEVICE_ACTION,
        icon: iconCancelDevice,
        id: RentalDetailsCardModalTypes.CancelSmartphone,
        isHidden: !rental.actions.showCancelAction,
        name: 'Cancel_device',
        onActionClick: handleOpenCancelSmartphoneModal,
        variant: 'danger',
      },
      {
        e2eSelector: E2E_PURCHASE_DEVICE_ACTION,
        icon: faCartShopping,
        id: RentalDetailsCardModalTypes.RequestPurchase,
        isHidden: !rental.actions.showPurchaseDeviceAction,
        name: 'Purchase_device',
        onActionClick: handleOpenRequestPurchaseModal,
      },
    ],
    [
      rental,
      handleEarlyUpgrade,
      handleOpenLegacyUpgradeDeviceModal,
      handleUpgrade,
      handleProlong,
      handleOpenReassignDeviceModal,
      handleOpenLostOrStolenModal,
      handleOpenExchangeDeviceModal,
      handleOpenReturnDeviceModal,
      handleOpenCancelSmartphoneModal,
      handleOpenRequestPurchaseModal,
      handleReplaceBrokenDevice,
    ],
  )

  return (
    <ContentCard
      className="RentalDetailsCard"
      title="RentalDetails_your_rental"
    >
      <div className="RentalDetailsCard-body">
        <div>
          <CardDetailsList listItems={generateListItems} />

          {rental.alerts.showEarlyUpgradePossibleAlert && (
            <Alert className="mt-4 mb-2" iconLeft={faCircleInfo} variant="info">
              <FormattedMessage id="RentalDetails_you_can_now" />
            </Alert>
          )}

          {rental.alerts.showProlongingPossibleAlert && (
            <Alert className="mt-4 mb-2" iconLeft={faCircleInfo} variant="info">
              <FormattedMessage id="RentalDetails_alert_prolonging" />
            </Alert>
          )}

          {rental.alerts.showUpgradePossibleAlert && (
            <Alert className="mt-4 mb-2" iconLeft={faCircleInfo} variant="info">
              <FormattedMessage id="RentalDetails_alert_upgrade" />
            </Alert>
          )}

          {rental.alerts.showOpenLostDeviceAlert && (
            <Alert
              className="mt-4 mb-2 bg-white RentalDetailsDetailsCard-alert-lost"
              data-e2e={E2E_LOST_STOLEN_RENTAL_DETAILS_ALERT}
              iconLeft={faCircleInfo}
              variant="info"
            >
              <span>
                <FormattedMessage id="RentalDetails_alert_lost_device" />
                &nbsp;
                <Link
                  className="fw-bold"
                  title={intl.formatMessage({
                    id: 'RentalDetails_check_status',
                  })}
                  to={composePath({
                    isOrganisation,
                    params: {
                      employeeId,
                      orderId:
                        getOpenCaseItemId(rental, [CaseItemType.lost_device]) ||
                        '0',
                      organisationId,
                    },
                    paths: {
                      employee: GLOBAL_ROUTE_PATHS.employee.order,
                      organisation: GLOBAL_ROUTE_PATHS.organisation.order,
                    },
                  })}
                >
                  <FormattedMessage id="RentalDetails_check_status" />
                </Link>
                &nbsp;
                <FormattedMessage id="RentalDetails_alert_subhead" />
              </span>
            </Alert>
          )}

          {rental.alerts.showOpenCancelDeviceAlert && (
            <Alert
              className="mt-4 mb-2 RentalDetailsDetailsCard-alert-cancel-banner"
              iconLeft={faCircleInfo}
              variant="warning"
            >
              <span>
                <FormattedMessage id="RentalDetails_alert_cancel" />
                &nbsp;
                <Link
                  className="fw-bold"
                  title={intl.formatMessage({
                    id: 'RentalDetails_check_status',
                  })}
                  to={composePath({
                    isOrganisation,
                    params: {
                      employeeId,
                      orderId:
                        getOpenCaseItemId(rental, [
                          CaseItemType.cancel_device,
                        ]) || '0',
                      organisationId,
                    },
                    paths: {
                      employee: GLOBAL_ROUTE_PATHS.employee.order,
                      organisation: GLOBAL_ROUTE_PATHS.organisation.order,
                    },
                  })}
                >
                  <FormattedMessage id="RentalDetails_check_status" />
                </Link>
                &nbsp;
                <FormattedMessage id="RentalDetails_alert_subhead" />
              </span>
            </Alert>
          )}

          {rental.alerts.showOpenRepairDeviceAlert && (
            <Alert
              className="mt-4 mb-2 RentalDetailsDetailsCard-alert-repair"
              iconLeft={faCircleInfo}
              variant="info"
            >
              <span>
                <FormattedMessage id="RentalDetails_alert_repair" />
                &nbsp;
                <Link
                  className="fw-bold"
                  title={intl.formatMessage({
                    id: 'RentalDetails_check_status',
                  })}
                  to={composePath({
                    isOrganisation,
                    params: {
                      employeeId,
                      orderId:
                        getOpenCaseItemId(rental, [
                          CaseItemType.repair_device,
                        ]) || '0',
                      organisationId,
                    },
                    paths: {
                      employee: GLOBAL_ROUTE_PATHS.employee.order,
                      organisation: GLOBAL_ROUTE_PATHS.organisation.order,
                    },
                  })}
                >
                  <FormattedMessage id="RentalDetails_check_status" />
                </Link>
                &nbsp;
                <FormattedMessage id="RentalDetails_alert_subhead" />
              </span>
            </Alert>
          )}
        </div>

        <CardActions
          actions={isAppReadOnly ? [] : generateActions}
          type="rental"
        />
      </div>
    </ContentCard>
  )
})
