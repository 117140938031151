import { getEmployeeListPreviewColumn_employee } from 'Constants/graphql/queries/detail/__generated__/getEmployeeListPreviewColumn'

export const generateEmployeesPreviewColumnContent = (
  employee?: getEmployeeListPreviewColumn_employee,
) => [
  {
    copyable: true,
    label: 'PreviewColumn_email',
    value: employee?.contact.email,
  },
  {
    copyable: true,
    label: 'PreviewColumn_phone_number',
    value: employee?.contact.phoneNumber,
  },
  {
    label: 'PreviewColumn_organisation',
    value: employee?.organisation?.fullName,
  },
  {
    label: 'PreviewColumn_office',
    value: employee?.office?.locationName,
  },
  {
    label: 'PreviewColumn_cost_center',
    value: employee?.costCenter?.name,
  },
  {
    label: 'PreviewColumn_employee_id',
    value: employee?.externalReference,
  },
]
