import { JsonApiError } from './fetch/fetch.types'

export class AuthError extends Error {
  // __proto__ => Accessor to the internals of the class
  __proto__: AuthError

  public apiErrors: JsonApiError[]

  constructor(message: string, apiErrors: JsonApiError[]) {
    super(message)
    // Maps the es6 class definitions to the older es5
    // Helps to properly compare the instaceOf an object
    // https://medium.com/@xpl/javascript-deriving-from-error-properly-8d2f8f315801
    this.constructor = AuthError
    // eslint-disable-next-line no-proto
    this.__proto__ = AuthError.prototype
    this.name = 'AuthError'
    this.apiErrors = apiErrors
  }
}
