import { useEffect } from 'react'
import { useIntl } from 'react-intl'

import { OfferDeclinedContent } from 'Modules/purchase/components/OfferDeclinedContent/OfferDeclinedContent'
import { PurchaseLayout } from 'Modules/purchase/layouts/PurchaseLayout/PurchaseLayout'

import { setRouteTitle } from 'Portal/src/router/Router.utils'

export const OfferDeclined = () => {
  const intl = useIntl()

  useEffect(() => {
    setRouteTitle(
      intl.formatMessage({
        id: 'OfferDeclinedContent_heading',
      }),
    )
  }, [intl])

  return (
    <PurchaseLayout>
      <OfferDeclinedContent />
    </PurchaseLayout>
  )
}
