import { gql } from '@apollo/client'

export const MUTATION_NEW_DEVICE_CASEITEM = gql`
  mutation mutationNewDeviceCaseItem($input: NewDeviceCaseItemInput!) {
    createNewDeviceCaseItem(input: $input) {
      id
      subCaseItems {
        id
        data {
          deviceDefinition {
            id
            name
          }
          requestedRentCategory {
            deviceDefinition {
              id
            }
          }
        }
      }
    }
  }
`
