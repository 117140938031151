import { FormattedMessage } from 'react-intl'
import { faSpinner } from '@fortawesome/pro-solid-svg-icons'

import { Icon } from 'Components/Icon/Icon'

import { E2E_LOADING } from 'Constants/e2e'

export const PageLoadingState = () => (
  <div className="page-loading-container">
    <Icon className="spinner" icon={faSpinner} size={50} spin />

    <span className="visually-hidden" data-e2e={E2E_LOADING}>
      <FormattedMessage id="Loading" />
    </span>
  </div>
)
