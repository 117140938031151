import { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { RadioButtonSizeEnum } from '../_theme/RadioButton/RadioButton.types'
import { RadioButtonGroup } from '../_theme/RadioButtonGroup/RadioButtonGroup'
import {
  CustomToolbarConfig,
  CustomToolbarProps,
} from './MultidatePicker.types'
import { createCustomToolbarConfig } from './MultidatePicker.utils'

/* eslint-disable sort-keys-fix/sort-keys-fix */
const defaultToolbarConfig: CustomToolbarConfig = {
  next_30days: {
    length: 30,
    period: 'days',
    scope: 'future',
  },
  today: {
    scope: 'today',
  },
  last_7days: {
    length: 7,
    period: 'days',
    scope: 'past',
  },
  last_30days: {
    length: 30,
    period: 'days',
    scope: 'past',
  },
}
/* eslint-enable sort-keys-fix/sort-keys-fix */

export const MultidatePickerToolbar = (
  props: CustomToolbarProps,
): JSX.Element => {
  const {
    name,
    onChange,
    DatePicker,
    customToolbarConfig = defaultToolbarConfig,
    e2eSelector,
  } = props
  const intl = useIntl()

  const { buttons, dates } = useMemo(
    () => createCustomToolbarConfig({ config: customToolbarConfig, intl }),
    [customToolbarConfig, intl],
  )

  const radioButtons = buttons.map(({ content, key }) => ({
    label: content,
    value: key,
  }))

  return (
    <RadioButtonGroup
      className="MultidatePicker-toolbar p-3"
      e2eSelector={e2eSelector}
      name={name}
      onChange={value => {
        if (onChange) {
          onChange(dates[value])
        }

        DatePicker.closeCalendar()
      }}
      radioButtons={radioButtons}
      size={RadioButtonSizeEnum.small}
    />
  )
}
