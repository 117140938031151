import { Container } from 'react-bootstrap'
import { observer } from 'mobx-react'

import { PageHeader } from 'Components/PageHeader/PageHeader'

import { useStore } from 'Stores/index'

import { useActiveUser } from 'Hooks'

import { PortalSectionEnum } from 'Constants/portalSection'

import { AccountInfo } from '../components/AccountInfo/AccountInfo'
import { AccountOptions } from '../components/AccountOptions/AccountOptions'

export const Account = observer(() => {
  const { userStore } = useStore()
  const { user } = userStore
  const { activeEmployee } = useActiveUser()
  const userName = user.fullName ?? ''
  const userEmail = user.email ?? ''
  const organisationShortname = activeEmployee?.organisation?.shortName

  return (
    <Container className="Account" fluid>
      <div className="pt-4" role="banner">
        <PageHeader portalSection={PortalSectionEnum.Account} />
      </div>

      <AccountInfo
        userEmail={userEmail}
        userOrganisationName={organisationShortname}
        username={userName}
      />

      <AccountOptions />
    </Container>
  )
})
