import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash-es'
import { observer } from 'mobx-react'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { EntityListCard } from 'Components/EntityListCard/EntityListCard'
import { EntityProps } from 'Components/EntityListCard/EntityListCard.types'

import { useIsOrganisation } from 'Hooks'

import { DeviceTypeEnum } from 'Types/deviceTypes'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import { RentalEntityCardProps } from './RentalEntityCard.types'

export const RentalEntityCard = observer((props: RentalEntityCardProps) => {
  const { rentals, showTotal = true, testId } = props
  const intl = useIntl()
  const isOrganisation = useIsOrganisation()
  const { employeeId, organisationId } = useParams<{
    employeeId?: string
    organisationId?: string
  }>()

  const generateEntities = useMemo(
    (): EntityProps[] =>
      rentals.map(({ id, stocklistItem }) => {
        const imei = stocklistItem?.imei
          ? stocklistItem.imei
          : stocklistItem?.imei2

        const content = imei ? (
          <p key={id} className="m-0 fs-6">
            IMEI: <span>{imei}</span>
          </p>
        ) : null

        const imagePlaceholderType = stocklistItem?.deviceDefinition
          ?.productType as DeviceTypeEnum

        const link = composePath({
          isOrganisation,
          params: {
            employeeId,
            organisationId,
            rentalId: id,
          },
          paths: {
            employee: GLOBAL_ROUTE_PATHS.employee.rental,
            organisation: GLOBAL_ROUTE_PATHS.organisation.rental,
          },
        })

        return {
          content,
          id,
          imagePlaceholderType,
          imageSource: stocklistItem?.deviceDefinition?.imageURL,
          link,
          title:
            stocklistItem?.deviceDefinition?.name ??
            intl.formatMessage({ id: 'RentalEntityCard_rental_id' }, { id }),
        }
      }),
    [employeeId, intl, isOrganisation, organisationId, rentals],
  )

  const titleSuffix = useMemo(() => {
    if (!showTotal) {
      return null
    }

    return (
      <span className="ms-2">
        <FormattedMessage id="Total" />: <span>{rentals.length}</span>
      </span>
    )
  }, [rentals.length, showTotal])

  if (isEmpty(rentals)) {
    return null
  }

  return (
    <EntityListCard
      entities={generateEntities}
      testId={testId}
      title="Rentals"
      titleSuffix={titleSuffix}
    />
  )
})
