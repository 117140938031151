import { UiState } from 'instantsearch.js'
import { action, makeObservable, observable } from 'mobx'

import { getEmployee_employee_organisation_portalContent } from 'Constants/graphql/queries/employee/__generated__/getEmployee'
import { getOrganisation_organisation_portalContent } from 'Constants/graphql/queries/organisation/__generated__/getOrganisation'
import { PAGE_SIZE } from 'Constants/pagination'

import { PaginationState } from './portalContentStore.types'

export class PortalContentStore {
  portalContent:
    | getOrganisation_organisation_portalContent
    | getEmployee_employee_organisation_portalContent
    | null = null

  algoliaUIState: UiState = {}

  paginationState: PaginationState = {
    pageSize: PAGE_SIZE,
  }

  constructor() {
    makeObservable(this, {
      algoliaUIState: observable,
      paginationState: observable,
      portalContent: observable,
      setAlgoliaUIState: action,
      setPaginationState: action,
      setPortalContent: action,
    })
  }

  setPortalContent = (
    content:
      | getOrganisation_organisation_portalContent
      | getEmployee_employee_organisation_portalContent
      | null,
  ) => {
    this.portalContent = content
  }

  setAlgoliaUIState = (state: UiState) => {
    this.algoliaUIState = state
  }

  setPaginationState = ({ pageSize }: Partial<PaginationState>) => {
    if (pageSize) {
      this.paginationState.pageSize = pageSize
    }
  }
}
