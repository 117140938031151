import { faClose } from '@fortawesome/pro-solid-svg-icons'

import { Icon } from 'Components/Icon/Icon'

import { FilterBadgeProps } from './FilterBadge.types'

export const FilterBadge = (props: FilterBadgeProps) => {
  const { children, onRemove, e2eSelectors } = props

  return (
    <span
      className="FilterBadge"
      data-e2e={e2eSelectors?.container}
      role="status"
    >
      {children}

      <button
        aria-label="Remove filter"
        data-e2e={e2eSelectors?.button}
        onClick={onRemove}
        type="button"
      >
        <Icon icon={faClose} size={10} />
      </button>
    </span>
  )
}
