import { mutationNewDeviceCaseItem_createNewDeviceCaseItem_subCaseItems } from 'Constants/graphql/mutations/__generated__/mutationNewDeviceCaseItem'

import { OrderDevice } from 'Portal/src/mappers/orderDeviceMapper/orderDevice.types'

export const getOrderId = (
  orderedDevices:
    | mutationNewDeviceCaseItem_createNewDeviceCaseItem_subCaseItems[]
    | number
    | null,
  device: OrderDevice,
) => {
  if (!orderedDevices) {
    return null
  }

  if (typeof orderedDevices === 'number') {
    return orderedDevices
  }

  const foundOrder = orderedDevices.find(orderedDevice => {
    const id = orderedDevice.data.deviceDefinition?.id || '0'

    if (id !== '0') {
      return id === device.deviceDefinition.id
    }

    return (
      orderedDevice.data.requestedRentCategory?.deviceDefinition?.id ===
      device.deviceDefinition.id
    )
  })

  return foundOrder?.id ?? null
}
