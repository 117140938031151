import { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { CellContext, ColumnDef } from '@tanstack/react-table'

import {
  GS_SEARCH_RESULTS_ITEM_DESCRIPTION,
  GS_SEARCH_RESULTS_ITEM_STATUS,
} from 'Modules/searchResults/constants/searchResults.contants'
import { ParsedSearchResults } from 'Modules/searchResults/types/searchResults.types'

import { Table } from 'Components/_theme'
import { ImageWithFallback } from 'Components/ImageWithFallback/ImageWithFallback'
import { OrderStatusBadge } from 'Components/OrderStatusBadge/OrderStatusBadge'
import { ReturnStatusBadge } from 'Components/ReturnStatusBadge/ReturnStatusBadge'

import { buttonize } from 'Utilities'

import { E2E_TABLE_LINK_SEARCH_RESULTS } from 'Constants/e2e'
import { PORTAL_CONTENT_MODEL } from 'Constants/portalContentModel'

import { DeviceTypeEnum } from 'Types/deviceTypes'

import { SearchResultDetails } from '../SearchResultsDetails/SearchResultsDetails'
import { SearchResultTitle } from '../SearchResultsTitle/SearchResultsTitle'
import { SearchResultListProps } from './SearchResultsList.types'

export const SearchResultsList = (props: SearchResultListProps) => {
  const { dataSource, isLoading } = props
  const routerHistory = useHistory()

  const handleEnterDetails = useCallback(
    (
      _event:
        | React.MouseEvent<HTMLDivElement>
        | React.KeyboardEvent<HTMLDivElement>,
      url: string,
    ) => {
      routerHistory.push(url)
    },
    [routerHistory],
  )

  const renderNameCell = useCallback(
    ({ cell }: CellContext<ParsedSearchResults, unknown>) => {
      const results = cell.row.original

      return (
        <div
          className="d-inline-flex"
          {...buttonize(handleEnterDetails, results.url)}
          data-testid={GS_SEARCH_RESULTS_ITEM_DESCRIPTION}
        >
          <div className="d-flex justify-content-center align-items-center me-4 Table-device-image">
            <ImageWithFallback
              alt={results.document?.['rental.stocklist_item.name']}
              placeholderType={
                (results.document?.[
                  'rental.stocklist_item.product_type'
                ] as DeviceTypeEnum) || DeviceTypeEnum.other
              }
              src={results.document['rental.stocklist_item.image_url'] || ''}
            />
          </div>

          <div className="d-flex flex-column justify-content-center">
            <p className="Table--search-results-category text-muted m-0">
              <FormattedMessage
                id="GlobalSearch_InCategory"
                values={{
                  category: PORTAL_CONTENT_MODEL[results.model]?.translationId,
                }}
              />
            </p>

            <p className="Table--search-results-title">
              <SearchResultTitle
                document={results.document}
                highlights={results.highlights}
                model={results.model}
              />
            </p>

            <dl className="Table--search-results-details Table--search-results-details--is-desktop">
              <SearchResultDetails
                document={results.document}
                highlights={results.highlights}
                model={results.model}
              />
            </dl>
          </div>
        </div>
      )
    },
    [handleEnterDetails],
  )

  const renderExpandableContent = useCallback(
    ({ cell }: CellContext<ParsedSearchResults, unknown>) => {
      const results = cell.row.original

      return (
        <dl className="Table--search-results-details Table--search-results-details--is-mobile m-0">
          <SearchResultDetails
            document={results.document}
            highlights={results.highlights}
            model={results.model}
          />
        </dl>
      )
    },
    [],
  )

  const renderStatusCell = useCallback(
    ({ cell }: CellContext<ParsedSearchResults, unknown>) => {
      const results = cell.row.original
      const caseItemStatus = results.document['case_item.status']
      const returnCaseStatus = results.document['return_case.status']
      const hasStatus = caseItemStatus || returnCaseStatus

      if (!hasStatus) {
        return null
      }

      return (
        <div
          className="d-flex justify-content-center"
          data-testid={GS_SEARCH_RESULTS_ITEM_STATUS}
        >
          {caseItemStatus && (
            <OrderStatusBadge className="fs-6" status={caseItemStatus} />
          )}

          {returnCaseStatus && (
            <ReturnStatusBadge className="fs-6" status={returnCaseStatus} />
          )}
        </div>
      )
    },
    [],
  )

  const columns = useMemo<ColumnDef<ParsedSearchResults>[]>(
    () => [
      {
        cell: renderNameCell,
        id: 'name',
        meta: {
          ExpandableContent: renderExpandableContent,
        },
      },
      {
        cell: renderStatusCell,
        id: 'status',
      },
    ],
    [renderExpandableContent, renderNameCell, renderStatusCell],
  )

  const data = useMemo<ParsedSearchResults[]>(() => dataSource, [dataSource])

  return (
    <Table
      className="Table--search-results"
      columns={columns}
      data={data}
      e2eRowId={E2E_TABLE_LINK_SEARCH_RESULTS}
      isLoading={isLoading}
    />
  )
}
