import { useLayoutEffect } from 'react'
import { usePagination } from 'react-instantsearch'
import { useIntl } from 'react-intl'
import ReactPaginate from 'react-paginate'
import {
  faAngleLeft,
  faAngleRight,
  faEllipsis,
} from '@fortawesome/pro-solid-svg-icons'
import { usePrevious } from '@react-hookz/web'
import { observer } from 'mobx-react'

import { Icon } from 'Components/Icon/Icon'

import { useStore } from 'Stores/index'

import { scrollToTop } from 'Utilities'

import { PaginationProps } from './Pagination.types'

export const Pagination = observer((props: PaginationProps) => {
  const { totalCount } = props

  const { portalContentStore } = useStore()
  const { paginationState } = portalContentStore

  const totalPages = Math.ceil(totalCount / paginationState.pageSize)

  const intl = useIntl()

  const {
    currentRefinement: currentPage,
    refine,
    canRefine,
  } = usePagination({
    totalPages,
  })
  const previousPage = usePrevious(currentPage)

  const handlePageChange = (selectedItem: { selected: number }) => {
    refine(selectedItem.selected)
  }

  useLayoutEffect(() => {
    if (typeof previousPage !== 'undefined' && currentPage !== previousPage) {
      scrollToTop()
    }
  }, [currentPage, previousPage])

  if (!canRefine) {
    return null
  }

  return (
    <ReactPaginate
      activeLinkClassName="Pagination__item__link--selected"
      ariaLabelBuilder={page =>
        intl.formatMessage({ id: 'Pagination_pageLabel' }, { page })
      }
      breakAriaLabels={{
        backward: intl.formatMessage({ id: 'Pagination_jumpBackwardLabel' }),
        forward: intl.formatMessage({ id: 'Pagination_jumpForwardLabel' }),
      }}
      breakLabel={<Icon icon={faEllipsis} size={16} />}
      breakLinkClassName="Pagination__item__link Pagination__item__link--break"
      containerClassName="Pagination"
      disabledLinkClassName="Pagination__item__link--disabled"
      forcePage={currentPage}
      marginPagesDisplayed={1}
      nextAriaLabel={intl.formatMessage({ id: 'Pagination_nextLabel' })}
      nextLabel={<Icon icon={faAngleRight} size={16} />}
      nextLinkClassName="Pagination__item__link Pagination__item__link--next"
      onPageChange={handlePageChange}
      pageCount={totalPages}
      pageLinkClassName="Pagination__item__link"
      pageRangeDisplayed={2}
      previousAriaLabel={intl.formatMessage({ id: 'Pagination_previousLabel' })}
      previousLabel={<Icon icon={faAngleLeft} size={16} />}
      previousLinkClassName="Pagination__item__link Pagination__item__link--previous"
    />
  )
})
