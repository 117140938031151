import { useCallback, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useCurrentRefinements, useInstantSearch } from 'react-instantsearch'
import { useIntl } from 'react-intl'
import { NetworkStatus } from '@apollo/client'
import { observer } from 'mobx-react'

import { OrdersFilterColumn } from 'Modules/orders/components/OrdersFilterColumn/OrdersFilterColumn'
import { OrdersPreviewColumn } from 'Modules/orders/components/OrdersPreviewColumn/OrdersPreviewColumn'
import { OrdersTable as OrdersTableDefault } from 'Modules/orders/components/OrdersTable/OrdersTable'
import { OrdersTable } from 'Modules/orders/components/OrdersTableInstantsearch/OrdersTable'
import { OrdersPreviewColumnTypeEnum } from 'Modules/orders/types/OrdersPreviewColumnType.type'
import { ReturnsPreviewColumn } from 'Modules/returns/components/ReturnsPreviewColumn/ReturnsPreviewColumn'

import { Card } from 'Components/_theme'
import { ContentSidebar } from 'Components/ContentSidebar/ContentSidebar'
import { FaqCard } from 'Components/FaqCard/FaqCard'
import { ImpactTracker } from 'Components/ImpactTracker/ImpactTracker'
import { ObserveMeOnceIAmMounted } from 'Components/ObserveMeOnceIAmMounted/ObserveMeOnceIAmMounted'
import { PageErrorState } from 'Components/PageErrorState/PageErrorState'
import { PageHeader } from 'Components/PageHeader/PageHeader'
import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'
import { TableFooter } from 'Components/TableFooter/TableFooter'
import { TableHeader } from 'Components/TableHeader/TableHeader'

import { useDefaultListTable, useSidebarVisibility } from 'Hooks'

import { toggleIntercomVisibility } from 'Utilities'

import { E2E_LIST_FILTER_BUTTON } from 'Constants/e2e'
import {
  getOrders,
  getOrdersVariables,
} from 'Constants/graphql/queries/listing/__generated__/getOrders'
import { GET_ORDERS } from 'Constants/graphql/queries/listing/GetOrders'
import { PortalSectionEnum } from 'Constants/portalSection'

import { GoToPage } from 'Algolia/components/GoToPage/GoToPage'
import { PageSizeSelect } from 'Algolia/components/PageSizeSelect/PageSizeSelect'
import { Pagination } from 'Algolia/components/Pagination/Pagination'

import { ReportType } from 'Portal/__generated__/globalTypes'

export const OrdersContent = observer(() => {
  const intl = useIntl()

  const { items: activeFilters } = useCurrentRefinements()
  const shouldUseDefaultListTable = activeFilters.length === 0

  const {
    data: dataDefaultListTable,
    error: errorDefaultListTable,
    hasNextPage: hasNextPageDefaultListTable,
    intersectionObserver: intersectionObserverDefaultListTable,
    isLoading: isLoadingDefaultListTable,
    networkStatus: networkStatusDefaultListTable,
    totalCount: totalCountDefaultListTable,
  } = useDefaultListTable<getOrders, getOrdersVariables>({
    dataObjectPropertyName: 'caseItems',
    isDefaultListTableInactive: !shouldUseDefaultListTable,
    organisationQuery: GET_ORDERS,
  })

  const [selectedEntityId, setSelectedEntityId] = useState('')
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null)
  const [isPreviewColumnOpen, setIsPreviewColumnOpen] = useState(false)
  const [isFilterColumnOpen, setIsFilterColumnOpen] = useState(false)
  const isSidebarOpen = isPreviewColumnOpen || isFilterColumnOpen
  const { isVisible: isPreviewColumnVisible } = useSidebarVisibility(
    isPreviewColumnOpen,
    isSidebarOpen,
  )
  const { isVisible: isFilterColumnVisible } = useSidebarVisibility(
    isFilterColumnOpen,
    isSidebarOpen,
  )

  const [previewColumnType, setPreviewColumnType] =
    useState<OrdersPreviewColumnTypeEnum>(OrdersPreviewColumnTypeEnum.ORDER)

  const { status, results, error } = useInstantSearch()
  const isLoading = status === 'loading' && !results.hits
  const totalCount = results?.nbHits ?? 0

  const handleCloseFilterColumn = useCallback(() => {
    setIsFilterColumnOpen(false)
  }, [])

  const handleOpenPreviewColumn = useCallback(
    (
      id: string,
      rowIndex: number | null,
      previewColumnType: OrdersPreviewColumnTypeEnum,
    ) => {
      handleCloseFilterColumn()
      toggleIntercomVisibility(false)

      setSelectedEntityId(id)
      setSelectedRowIndex(rowIndex)
      setPreviewColumnType(previewColumnType)
      setIsPreviewColumnOpen(true)
    },
    [handleCloseFilterColumn],
  )

  const handleClosePreviewColumn = useCallback(() => {
    toggleIntercomVisibility(true)

    setIsPreviewColumnOpen(false)
    setSelectedRowIndex(null)
  }, [])

  const handleOpenFilterColumn = useCallback(() => {
    handleClosePreviewColumn()

    setIsFilterColumnOpen(true)
  }, [handleClosePreviewColumn])

  if (shouldUseDefaultListTable ? errorDefaultListTable : error) {
    return <PageErrorState />
  }

  if (
    shouldUseDefaultListTable
      ? isLoadingDefaultListTable && !dataDefaultListTable
      : isLoading
  ) {
    return <PageLoadingState />
  }

  if (shouldUseDefaultListTable && !dataDefaultListTable) {
    return <PageLoadingState />
  }

  return (
    <Container className="pt-6" fluid>
      <div role="banner">
        <PageHeader portalSection={PortalSectionEnum.Orders} />
      </div>

      <Row>
        <Col xl={12} xxl={8}>
          <Card className="mb-4">
            <TableHeader
              buttonIsVisible={false}
              downloadReportAlertAdditionalReadyMessage={intl.formatMessage({
                id: 'Download_report_report_ready_order_message',
              })}
              downloadReportType={ReportType.orders}
              filterButtonE2ESelector={E2E_LIST_FILTER_BUTTON}
              filterButtonOnClick={handleOpenFilterColumn}
              hideFilterButton={!!error}
              totalCount={
                shouldUseDefaultListTable
                  ? totalCountDefaultListTable
                  : totalCount
              }
            />

            {shouldUseDefaultListTable ? (
              <Row>
                <Col>
                  <OrdersTableDefault
                    isActiveEmployee={false}
                    isLoading={isLoadingDefaultListTable}
                    isSearching={
                      networkStatusDefaultListTable ===
                      NetworkStatus.setVariables
                    }
                    onOpenPreviewColumn={handleOpenPreviewColumn}
                    orders={dataDefaultListTable}
                    selectedRowIndex={selectedRowIndex}
                  />
                </Col>
              </Row>
            ) : (
              <OrdersTable
                isLoading={isLoading}
                onOpenPreviewColumn={handleOpenPreviewColumn}
                selectedRowIndex={selectedRowIndex}
              />
            )}

            {!shouldUseDefaultListTable && (
              <TableFooter>
                <PageSizeSelect />

                <Pagination totalCount={totalCount} />

                <GoToPage totalCount={totalCount} />
              </TableFooter>
            )}
          </Card>

          {shouldUseDefaultListTable && (
            <Row className="p-1">
              <Col>
                {hasNextPageDefaultListTable && !isLoadingDefaultListTable && (
                  <ObserveMeOnceIAmMounted
                    intersectionObserver={intersectionObserverDefaultListTable}
                  />
                )}
              </Col>
            </Row>
          )}
        </Col>

        <ContentSidebar
          isSidebarOpen={isSidebarOpen}
          renderStickyColumnSideContent={
            <>
              <ImpactTracker />
              <FaqCard />
            </>
          }
        >
          {previewColumnType === OrdersPreviewColumnTypeEnum.RETURN ? (
            <ReturnsPreviewColumn
              entityId={selectedEntityId}
              isVisible={isPreviewColumnVisible}
              onClosePreviewColumn={handleClosePreviewColumn}
            />
          ) : (
            <OrdersPreviewColumn
              entityId={selectedEntityId}
              isVisible={isPreviewColumnVisible}
              onClosePreviewColumn={handleClosePreviewColumn}
            />
          )}

          <OrdersFilterColumn
            isVisible={isFilterColumnVisible}
            onCloseFilterColumn={handleCloseFilterColumn}
          />
        </ContentSidebar>
      </Row>
    </Container>
  )
})
