export enum E2EEmployeeIdsEnum {
  REWRITES_EMPLOYEE = 3,
  NEW_JOINER = 79,
}

export enum E2EEmployeeEnum {
  ADMIN_USER = 'Admin User',
  EMPLOYEE_ACTIONS_ASSIGNABLE = 'Employee Actions - Assignable Employee',
  EMPLOYEE_ACTIONS_UNASSIGNABLE = 'Employee Actions - Unassignable Employee',
  EMPLOYEE_ACTIONS_EXIT_DATE = 'Employee Actions - Exit Date Employee',
  EMPLOYEE_ACTIONS_NO_EXIT_DATE = 'Employee Actions - No Exit Date Employee',
  EMPLOYEE_ACTIONS_CONTACT_US = 'Employee Actions - Contact Us Employee',
  LOST_EP_REPLACEMENT = 'Lost EP Replacement Employee',
  LOST_NO_REPLACEMENT = 'Lost No Replacement Employee',
  LIMITATION_RULES_0000 = 'Limitation Rules 0000 Employee',
  LIMITATION_RULES_1110 = 'Limitation Rules 1110 Employee',
  LIMITATION_RULES_1111 = 'Limitation Rules 1111 Employee',
  LIMITATION_RULES_1112 = 'Limitation Rules 1112 Employee',
  CANCEL_DEVICE = 'Cancel Device Employee',
  REPLACE_BROKEN_DEVICE = 'Replace Broken Device Employee',
  RETURN_DEVICE = 'Return Device Employee',
  NO_RENT_CATEGORIES = 'Order No Rent Categories Employee',
  ORDER_RENT_CATEGORIES_1 = 'Order Rent Categories Employee 1',
  ORDER_RENT_CATEGORIES_2 = 'Order Rent Categories Employee 2',
  ORDER_PRODUCT_OFFERS_1 = 'Order Product Offers Employee 1',
  ORDER_PRODUCT_OFFERS_2 = 'Order Product Offers Employee 2',
  RENTAL_PROLONGING = 'Rental Prolonging Employee',
  ORDER_CUSTOM_ADDRESS_1 = 'Order Custom Address Employee 1',
  ORDER_CUSTOM_ADDRESS_2 = 'Order Custom Address Employee 2',
  ORDER_REPAIR_UPGRADE = 'Order Repair Upgrade Employee',
  ORDER_LOST_REPLACEMENT = 'Order Lost Replacement Employee',
  UPGRADE_RENT_CATEGORIES_1 = 'Upgrade Rent Categories Employee 1',
  UPGRADE_RENT_CATEGORIES_2 = 'Upgrade Rent Categories Employee 2',
  EARLY_UPGRADE_RENT_CATEGORIES_1 = 'Early Upgrade Rent Categories Employee 1',
  EARLY_UPGRADE_RENT_CATEGORIES_2 = 'Early Upgrade Rent Categories Employee 2',
  UPGRADE_PRODUCT_OFFERS_1 = 'Upgrade Product Offers Employee 1',
  UPGRADE_PRODUCT_OFFERS_2 = 'Upgrade Product Offers Employee 2',
  EARLY_UPGRADE_PRODUCT_OFFERS_1 = 'Early Upgrade Product Offers Employee 1',
  EARLY_UPGRADE_PRODUCT_OFFERS_2 = 'Early Upgrade Product Offers Employee 2',
}
