import { Col, Container, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { PhonePlanPresentationCard } from 'Modules/phonePlans/components/PhonePlanPresentationCard/PhonePlanPresentationCard'

import { EmployeeEntityCard } from 'Components/EmployeeEntityCard/EmployeeEntityCard'
import { PageErrorState } from 'Components/PageErrorState/PageErrorState'
import { PageHeader } from 'Components/PageHeader/PageHeader'
import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'
import { RentalEntityCard } from 'Components/RentalEntityCard/RentalEntityCard'

import {
  getPhonePlanDetails,
  getPhonePlanDetailsVariables,
} from 'Constants/graphql/queries/detail/__generated__/getPhonePlanDetails'
import { GET_PHONE_PLAN_DETAILS } from 'Constants/graphql/queries/detail/GetPhonePlanDetails'
import { PortalSectionEnum } from 'Constants/portalSection'

import { PHONE_PLAN_RENTALS_CARD } from './PhonePlan.constants'

export const PhonePlan = () => {
  const { organisationId, phonePlanId } = useParams<{
    organisationId: string
    phonePlanId: string
  }>()

  const {
    loading: isLoading,
    data,
    error,
  } = useQuery<getPhonePlanDetails, getPhonePlanDetailsVariables>(
    GET_PHONE_PLAN_DETAILS,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        id: phonePlanId,
        organisationId,
      },
    },
  )

  if (isLoading) {
    return <PageLoadingState />
  }

  if (error) {
    return <PageErrorState />
  }

  if (!data) {
    return <PageErrorState />
  }

  const { phoneplan } = data

  return (
    <Container className="pt-6" fluid>
      <div role="banner">
        <PageHeader portalSection={PortalSectionEnum.PhonePlanDetails} />
      </div>

      <Row className="mt-4">
        <Col xxl={8}>
          <PhonePlanPresentationCard phonePlan={phoneplan} />
        </Col>

        <Col xxl={4}>
          <Row>
            <Col lg={6} xs={12} xxl={12}>
              <EmployeeEntityCard
                employee={phoneplan.rental?.employee ?? null}
              />
            </Col>

            <Col lg={6} xs={12} xxl={12}>
              <RentalEntityCard
                rentals={phoneplan.rental ? [phoneplan.rental] : []}
                showTotal={false}
                testId={PHONE_PLAN_RENTALS_CARD}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
