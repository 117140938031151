import { useCallback, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import {
  AddressElement,
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {
  StripeAddressElementChangeEvent,
  StripeLinkAuthenticationElementChangeEvent,
} from '@stripe/stripe-js'

import {
  OfferPaymentFormProps,
  UpdateCustomerDataResponse,
} from 'Modules/purchase/components/OfferPaymentForm/OfferPaymentForm.types'

import { Alert, Heading, PrimaryButton } from 'Components/_theme'

import { fetchFromPublicApiUsingPartialPath } from 'Portal/src/utilities'

export const OfferPaymentForm = (props: OfferPaymentFormProps) => {
  const { email, billingCountry, billingName, clientSecret } = props
  const stripe = useStripe()
  const elements = useElements()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [formEmail, setFormEmail] =
    useState<StripeLinkAuthenticationElementChangeEvent['value']>()
  const [formAddress, setFormAddress] =
    useState<StripeAddressElementChangeEvent['value']>()
  const [stripeError, setStripeError] = useState<string | undefined>()

  const { origin } = new URL(window.location.href)

  const intl = useIntl()

  const { uuid = '' } = useParams<{
    uuid: string
  }>()

  const updateCustomerData = useCallback(async () => {
    const payload = JSON.stringify({
      ...formEmail,
      ...formAddress,
    })

    const response =
      await fetchFromPublicApiUsingPartialPath<UpdateCustomerDataResponse>(
        `/update-customer-data/${uuid}`,
        { body: payload, method: 'POST' },
      )

    if (response.error) {
      throw new Error(response.error)
    }
  }, [uuid, formAddress, formEmail])

  const handleSubmit: React.MouseEventHandler<
    HTMLButtonElement
  > = async event => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    setStripeError('')
    setIsSubmitting(true)

    const fallbackErrorMessage = intl.formatMessage({
      id: 'ErrorSubmittingRequest',
    })

    try {
      const elementsSubmit = await elements.submit()

      if (elementsSubmit?.error) {
        throw new Error(elementsSubmit.error.message)
      }

      await updateCustomerData()

      const result = await stripe.confirmPayment({
        clientSecret,
        confirmParams: {
          return_url: `${origin}/portal/app/#/purchase-successful`,
        },
        elements,
      })

      if (result?.error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        setStripeError(result.error.message)
      }
    } catch (error) {
      setStripeError(fallbackErrorMessage)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div className="OfferPaymentForm d-flex flex-fill flex-column">
      <Heading as={3} className="mb-9" size={4}>
        <FormattedMessage id="Payment" />
      </Heading>

      <form>
        <LinkAuthenticationElement
          className="mb-9"
          onChange={event => setFormEmail(event.value)}
          options={{
            defaultValues: {
              email: email || '',
            },
          }}
        />

        <Heading as={6} className="mb-4" size={5}>
          <FormattedMessage id="OfferPaymentForm_billing_details" />
        </Heading>

        <AddressElement
          className="mb-9"
          onChange={event => setFormAddress(event.value)}
          options={{
            allowedCountries: [billingCountry],
            defaultValues: {
              address: {
                country: billingCountry,
              },
              name: billingName || '',
            },
            mode: 'billing',
          }}
        />

        <Heading as={6} className="mb-4" size={5}>
          <FormattedMessage id="OfferPaymentForm_payment_details" />
        </Heading>

        <PaymentElement
          className="mb-9"
          options={{
            paymentMethodOrder: ['card'],
          }}
        />

        {stripeError && (
          <Alert className="mb-2" variant="warning">
            {stripeError}
          </Alert>
        )}

        <PrimaryButton
          className="mt-4 mb-4 w-100"
          isLoading={isSubmitting}
          onClick={handleSubmit}
        >
          <FormattedMessage id="OfferPaymentForm_submit" />
        </PrimaryButton>
      </form>
    </div>
  )
}
