export const SHIPPING_ADDRESS_FORM_ZIPCODE_MIN_CHARACTERS = 4

export const SHIPPING_ADDRESS_FORM_LABELS = {
  city: 'City',
  country: 'Country',
  name: 'To',
  nameAddition: 'Attn',
  state: 'State',
  street: 'Street_name',
  streetAddition: 'Street_addition',
  streetNumber: 'Street_number',
  usAddress: 'Street_name_and_number',
  zip: 'Zip',
  zipcode: 'Postcode',
}

export const SHIPPING_ADDRESS_FORM_LABELS_DISABLED_FIELDS = {
  city: 'City',
  country: 'Country',
  name: 'To',
  nameAddition: 'Attn',
  street: 'Street name',
  streetNumber: 'Street number',
  zipcode: 'Postcode',
}
