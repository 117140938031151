import { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { useMediaQuery } from '@react-hookz/web'
import { CellContext, ColumnDef } from '@tanstack/react-table'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { Table } from 'Components/_theme'
import { FormatDate } from 'Components/FormatDate/FormatDate'
import { ImageWithFallback } from 'Components/ImageWithFallback/ImageWithFallback'

import { buttonize, getFullContactName } from 'Utilities'

import { PORTAL_BREAKPOINTS } from 'Constants/constants'
import { E2E_TABLE_LINK_PHONE_PLANS } from 'Constants/e2e'

import { ImagePlaceholderTypesEnum } from 'Types/imagePlaceholders.type'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import {
  EmployeePhonePlansWithUrl,
  OrganisationPhonePlansWithUrl,
  PhonePlansTableProps,
} from './PhonePlansTable.types'

export const PhonePlansTable = (props: PhonePlansTableProps) => {
  const {
    phonePlans,
    isLoading,
    isSearching,
    onOpenPreviewColumn,
    selectedRowIndex,
  } = props
  const routerHistory = useHistory()
  const isTablet = useMediaQuery(`(min-width: ${PORTAL_BREAKPOINTS.LG})`)
  const { organisationId } = useParams<{ organisationId: string }>()

  const handleEnterDetails = useCallback(
    (
      _event:
        | React.MouseEvent<HTMLDivElement>
        | React.KeyboardEvent<HTMLDivElement>,
      id: string,
      url: string,
      rowIndex: number,
    ) => {
      if (!isTablet) {
        routerHistory.push(url)

        return
      }

      if (typeof onOpenPreviewColumn === 'function') {
        onOpenPreviewColumn(id, rowIndex)
      }
    },
    [isTablet, onOpenPreviewColumn, routerHistory],
  )

  const renderPhonePlanHeader = useCallback(
    () => <FormattedMessage id="PhonePlanListContent_header__name" />,
    [],
  )

  const renderEmployeeHeader = useCallback(
    () => <FormattedMessage id="PhonePlanListContent_header__employee" />,
    [],
  )

  const renderStartDateHeader = useCallback(
    () => <FormattedMessage id="PhonePlanListContent_header__start_date" />,
    [],
  )

  const renderPhoneNumberHeader = useCallback(
    () => <FormattedMessage id="PhonePlanListContent_header__phone_number" />,
    [],
  )

  const renderPhonePlanCell = useCallback(
    ({
      cell,
    }: CellContext<
      EmployeePhonePlansWithUrl | OrganisationPhonePlansWithUrl,
      unknown
    >) => {
      const phonePlan = cell.row.original

      return (
        <div
          className="d-inline-flex"
          {...buttonize(
            handleEnterDetails,
            phonePlan.id,
            phonePlan.url,
            cell.row.index,
          )}
        >
          <div className="d-flex justify-content-center align-items-center me-4 Table-device-image">
            <ImageWithFallback
              alt={phonePlan?.phonePlanTariff?.provider ?? ''}
              placeholderType={ImagePlaceholderTypesEnum.phonePlan}
              src={phonePlan?.phonePlanTariff?.logo ?? ''}
            />
          </div>

          <div className="d-flex flex-column justify-content-center text-ellipsis">
            <div className="d-flex align-items-center">
              {phonePlan?.phonePlanTariff?.name ? (
                <strong className="text-ellipsis">
                  {phonePlan.phonePlanTariff.name}
                </strong>
              ) : (
                <strong>
                  <FormattedMessage id="Unnamed_phone_plan" />
                </strong>
              )}
            </div>

            {phonePlan.simcardNumber && (
              <div className="d-flex table-text-subtitle text-muted">
                <span>
                  <FormattedMessage id="Sim_card_number" />:
                </span>

                <span className="text-ellipsis">{phonePlan.simcardNumber}</span>
              </div>
            )}
          </div>
        </div>
      )
    },
    [handleEnterDetails],
  )

  const renderEmployeeCell = useCallback(
    ({
      cell,
    }: CellContext<
      EmployeePhonePlansWithUrl | OrganisationPhonePlansWithUrl,
      unknown
    >) => {
      const phonePlan = cell.row.original

      return (
        <div
          className="d-flex align-items-center h-100"
          {...buttonize(
            handleEnterDetails,
            phonePlan.id,
            phonePlan.url,
            cell.row.index,
          )}
        >
          <span className="text-ellipsis">
            {phonePlan.rental?.employee?.contact?.firstName
              ? getFullContactName(phonePlan.rental?.employee?.contact)
              : '-'}
          </span>
        </div>
      )
    },
    [handleEnterDetails],
  )

  const renderStartDateCell = useCallback(
    ({
      cell,
    }: CellContext<
      EmployeePhonePlansWithUrl | OrganisationPhonePlansWithUrl,
      unknown
    >) => {
      const phonePlan = cell.row.original

      return (
        <div
          className="d-flex align-items-center h-100"
          {...buttonize(
            handleEnterDetails,
            phonePlan.id,
            phonePlan.url,
            cell.row.index,
          )}
        >
          <FormatDate value={new Date(phonePlan.startAt)} />
        </div>
      )
    },
    [handleEnterDetails],
  )

  const renderPhoneNumberCell = useCallback(
    ({
      cell,
    }: CellContext<
      EmployeePhonePlansWithUrl | OrganisationPhonePlansWithUrl,
      unknown
    >) => {
      const phonePlan = cell.row.original

      if (!phonePlan.phoneNumber) {
        return (
          <div
            className="d-flex align-items-center h-100"
            {...buttonize(
              handleEnterDetails,
              phonePlan.id,
              phonePlan.url,
              cell.row.index,
            )}
          >
            <span> - </span>
          </div>
        )
      }

      return (
        <div
          className="d-flex align-items-center h-100"
          {...buttonize(
            handleEnterDetails,
            phonePlan.id,
            phonePlan.url,
            cell.row.index,
          )}
        >
          <span>{phonePlan.phoneNumber}</span>
        </div>
      )
    },
    [handleEnterDetails],
  )

  const renderExpandableContent = useCallback(
    ({
      cell,
    }: CellContext<
      EmployeePhonePlansWithUrl | OrganisationPhonePlansWithUrl,
      unknown
    >) => {
      const phonePlan = cell.row.original

      return (
        <div className="w-100 Table-details">
          <div className="Table-details-row">
            <span className="Table-row--expandable-title">
              {renderEmployeeHeader()}
            </span>

            <span>
              {phonePlan.rental?.employee?.contact?.firstName
                ? getFullContactName(phonePlan.rental.employee.contact)
                : '-'}
            </span>
          </div>

          <div className="Table-details-row">
            <span className="Table-row--expandable-title">
              {renderStartDateHeader()}
            </span>

            <span>
              <FormatDate value={new Date(phonePlan.startAt)} />
            </span>
          </div>

          <div className="Table-details-row">
            <span className="Table-row--expandable-title">
              {renderPhoneNumberHeader()}
            </span>

            <span>{phonePlan.phoneNumber || '-'}</span>
          </div>
        </div>
      )
    },
    [renderEmployeeHeader, renderPhoneNumberHeader, renderStartDateHeader],
  )

  const columns = useMemo<
    ColumnDef<EmployeePhonePlansWithUrl | OrganisationPhonePlansWithUrl>[]
  >(
    () => [
      {
        cell: renderPhonePlanCell,
        header: renderPhonePlanHeader,
        id: 'name',
        meta: {
          ExpandableContent: renderExpandableContent,
        },
      },
      {
        cell: renderEmployeeCell,
        header: renderEmployeeHeader,
        id: 'employee',
      },
      {
        cell: renderStartDateCell,
        header: renderStartDateHeader,
        id: 'start_date',
      },
      {
        cell: renderPhoneNumberCell,
        header: renderPhoneNumberHeader,
        id: 'phone_number',
      },
    ],
    [
      renderEmployeeCell,
      renderEmployeeHeader,
      renderExpandableContent,
      renderPhoneNumberCell,
      renderPhoneNumberHeader,
      renderPhonePlanCell,
      renderPhonePlanHeader,
      renderStartDateCell,
      renderStartDateHeader,
    ],
  )

  const data = useMemo<
    EmployeePhonePlansWithUrl[] | OrganisationPhonePlansWithUrl[]
  >(
    () =>
      phonePlans.map(phonePlan => {
        const url = composePath({
          params: {
            organisationId,
            phonePlanId: phonePlan.id,
          },
          path: GLOBAL_ROUTE_PATHS.organisation.phonePlan,
        })

        return {
          ...phonePlan,
          url,
        }
      }),
    [organisationId, phonePlans],
  )

  return (
    <Table
      className="Table--phonePlans"
      columns={columns}
      data={data}
      e2eRowId={E2E_TABLE_LINK_PHONE_PLANS}
      isLoading={isLoading}
      isSearching={isSearching}
      selectedRowIndex={selectedRowIndex}
    />
  )
}
