import { useCallback, useEffect, useMemo, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import { usePrevious } from '@react-hookz/web'
import { isEmpty } from 'lodash-es'
import { observer } from 'mobx-react'

import { SearchResultsFilterDropdown } from 'Modules/searchResults/components/SearchResultsFilterDropdown/SearchResultsFilterDropdown'
import { SearchResultsList } from 'Modules/searchResults/components/SearchResultsList/SearchResultsList'
import { ParsedSearchResults } from 'Modules/searchResults/types/searchResults.types'
import { parseSearchResults } from 'Modules/searchResults/utils/searchResults.utils'

import { Card, Heading } from 'Components/_theme'

import { useBasePageUrl } from 'Hooks'

import { getQueryObject, mergeToQueryString } from 'Utilities'

import { setRouteTitle } from 'Portal/src/router/Router.utils'

// import { PORTAL_CONTENT_MODEL } from 'Constants/portalContentModel'
import { useSearchResults } from './SearchResults.hooks'

// import { SearchResultsCategoryCard } from './SearchResultsCategoryCard'

export const SearchResultsPage = observer(() => {
  const { search: locationSearch } = useLocation()
  const { replace } = useHistory()
  const parsedSearch = getQueryObject(locationSearch)
  const prevSearchTerm = usePrevious(parsedSearch?.searchTerm)
  const [parsedSearchResults, setParsedSearchResults] = useState<
    ParsedSearchResults[]
  >([])
  const [selectedCategory, setSelectedCategory] = useState(
    (parsedSearch?.searchFilter as string) ?? '',
  )
  const prevSelectedCategory = usePrevious(selectedCategory)
  const { basePageUrl } = useBasePageUrl()

  const intl = useIntl()

  const { error, hasNextPage, isLoading, items, loadMore } = useSearchResults()

  const [infiniteScrollRef] = useInfiniteScroll({
    disabled: !!error,
    hasNextPage,
    loading: isLoading,
    onLoadMore: loadMore,
  })

  const totalResults = useMemo(() => {
    if (isEmpty(items)) {
      return 0
    }

    return items.result.reduce((result, item) => {
      result += item.count

      return result
    }, 0)
  }, [items])

  const handleSelectCategory = useCallback(
    (category: string) => {
      setSelectedCategory(category)

      const searchQuery = mergeToQueryString(locationSearch, {
        searchFilter: category,
      })

      replace({ search: searchQuery })
    },
    [replace, locationSearch],
  )

  // const renderCategoryCards = () => {
  //   if (!originalResults) {
  //     return null
  //   }

  //   return originalResults.result.map(category => {
  //     if (!category.count || !PORTAL_CONTENT_MODEL[category.model]) {
  //       return null
  //     }

  //     return (
  //       <SearchResultsCategoryCard
  //         key={category.model}
  //         count={category.count}
  //         model={category.model}
  //       />
  //     )
  //   })
  // }

  useEffect(() => {
    if (
      isEmpty(items) ||
      parsedSearch?.searchTerm !== prevSearchTerm ||
      selectedCategory !== prevSelectedCategory
    ) {
      window.scrollTo(0, 0)

      loadMore(true)
    }
  }, [
    items,
    loadMore,
    parsedSearch?.searchTerm,
    prevSearchTerm,
    prevSelectedCategory,
    selectedCategory,
  ])

  useEffect(() => {
    if (isEmpty(items)) {
      return
    }

    const parsedSearchResults = parseSearchResults(items, basePageUrl)

    setParsedSearchResults(parsedSearchResults)
  }, [basePageUrl, items])

  useEffect(() => {
    setRouteTitle(
      intl.formatMessage({
        id: 'GlobalSearch_SearchResults_header',
      }),
    )
  }, [intl])

  return (
    <Container className="pb-6 pt-6 d-flex flex-column" fluid>
      <Row className="align-items-center">
        <Col>
          <Heading as={4} className="mb-6" size={1}>
            <FormattedMessage id="GlobalSearch_SearchResults_header" />
          </Heading>
        </Col>
      </Row>

      <Row>
        <Col xl={12} xxl={8}>
          <Card className="mb-4">
            <div className=" search-results-header d-flex justify-content-between align-items-center position-relative">
              <span className="text-muted">
                <FormattedMessage
                  id="GlobalSearch_SearchResults_total"
                  values={{
                    amount: totalResults,
                  }}
                />
              </span>

              <SearchResultsFilterDropdown
                onSelectCategory={handleSelectCategory}
                selectedCategory={selectedCategory}
              />
            </div>

            <SearchResultsList
              dataSource={parsedSearchResults}
              isLoading={isLoading}
            />
          </Card>
        </Col>

        {/* <Col className="d-none d-xxl-block" xs={12} xxl={4}>
          {renderCategoryCards()}
        </Col> */}
      </Row>

      <Row>
        <Col>
          {hasNextPage && !isLoading && <div ref={infiniteScrollRef} />}
        </Col>
      </Row>
    </Container>
  )
})
