import { Redirect, Route } from 'react-router-dom'
import { observer } from 'mobx-react'

import { useStore } from 'Stores/index'

import { useAuthToken } from 'Hooks'

import { AuthorisedLayout } from '../layouts'
import { GLOBAL_ROUTE_PATHS } from './Router.constants'
import { ProtectedRouteProps } from './Router.types'

export const ProtectedRoute = observer((props: ProtectedRouteProps) => {
  const { component: Component, onRender, isAllowed, ...rest } = props
  const store = useStore()
  const authToken = useAuthToken()
  const routeHash = window.location.hash !== '#/' ? window.location.hash : null

  if (!authToken) {
    return (
      <Redirect
        to={{
          pathname: GLOBAL_ROUTE_PATHS.public.login,
          search: routeHash
            ? `?redirect-to=${encodeURIComponent(routeHash)}`
            : '',
        }}
      />
    )
  }

  if (typeof isAllowed === 'function' && !isAllowed(store)) {
    return <Redirect to="/" />
  }

  const Layout = (
    <AuthorisedLayout>
      <Component />
    </AuthorisedLayout>
  )

  if (onRender) {
    return (
      <Route
        {...rest}
        render={() => {
          onRender()

          return Layout
        }}
      />
    )
  }

  return <Route {...rest}>{Layout}</Route>
})
