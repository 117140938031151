import { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useApolloClient } from '@apollo/client'
import { zodResolver } from '@hookform/resolvers/zod'
import { clsx } from 'clsx'
import { observer } from 'mobx-react'

import { CountrySelect } from 'Components/CountrySelect/CountrySelect'
import { Field } from 'Components/Field/Field'
import { FieldTypeEnum } from 'Components/Field/Field.types'
import { Form } from 'Components/Form/Form'
import { StateSelect } from 'Components/StateSelect/StateSelect'

import { useStore } from 'Stores/index'

import { useActiveUser, useIsOrganisation } from 'Hooks'

import { getSelectValue, setFormErrors } from 'Utilities'

import { countryISOCodes } from 'Constants/constants'
import {
  E2E_ADDRESS_FORM_ADDRESS_TYPE,
  E2E_ADDRESS_FORM_CITY,
  E2E_ADDRESS_FORM_COUNTRY,
  E2E_ADDRESS_FORM_NAME,
  E2E_ADDRESS_FORM_NAME_ADDITION,
  E2E_ADDRESS_FORM_STATE,
  E2E_ADDRESS_FORM_STREET,
  E2E_ADDRESS_FORM_STREET_ADDITION,
  E2E_ADDRESS_FORM_STREET_NUMBER,
  E2E_ADDRESS_FORM_US_ADDRESS,
  E2E_ADDRESS_FORM_ZIPCODE,
} from 'Constants/e2e'
import {
  getAddressValidation,
  getAddressValidationVariables,
} from 'Constants/graphql/queries/validation/__generated__/getAddressValidation'
import { GET_ADDRESS_VALIDATION } from 'Constants/graphql/queries/validation/GetAddressValidation'

import { ShippingAddressTypeEnum } from 'Types/address/address.types'

import {
  SHIPPING_ADDRESS_FORM_LABELS,
  SHIPPING_ADDRESS_FORM_ZIPCODE_MIN_CHARACTERS,
} from './ShippingAddressForm.constants'
import {
  ShippingAddressFormProps,
  ShippingAddressFormSchema,
} from './ShippingAddressForm.types'
import { getShippingAddressFormSchema } from './ShippingAddressForm.utils'
import { shippingFormDefaultValues } from './ShippingAddressForm.values'
import { ShippingFormAddressTypes } from './ShippingAddressFormAddressTypes'

export const ShippingAddressForm = observer(
  (props: ShippingAddressFormProps) => {
    const {
      defaultValues,
      rental,
      formId,
      customAddressTypesConfig,
      className,
      useModalLayout,
      showOptionsHeader,
      onSubmit,
      onSetIsSubmitting,
    } = props
    const fieldColumnWidth = useModalLayout ? 12 : 6
    const fieldContainerClassName = useModalLayout ? 'mt-2' : 'mt-6'
    const client = useApolloClient()
    const isOrganisation = useIsOrganisation()
    const { orderStore } = useStore()
    const { officeAddress } = orderStore

    const { activeOrganisation, activeEmployee } = useActiveUser()
    const organisationId =
      activeOrganisation?.id || activeEmployee?.organisation?.id

    const formMethods = useForm<ShippingAddressFormSchema>({
      defaultValues: defaultValues ?? shippingFormDefaultValues,
      resolver: (data, context, options) => {
        const formSchema = getShippingAddressFormSchema(data, isOrganisation)

        return zodResolver(formSchema)(data, context, options)
      },
    })
    const { reset, watch, setError } = formMethods
    const watchAddressType = watch('addressType')
    const country = watch('country')
    const isCountryUnitedStates = country?.value === countryISOCodes.US
    const isAddressTypeOffice =
      watchAddressType === ShippingAddressTypeEnum.OFFICE
    const isAddressTypeVendingMachine = watchAddressType.includes(
      ShippingAddressTypeEnum.VENDING_MACHINE,
    )

    const isEmployeeOfficeAddress =
      watchAddressType === ShippingAddressTypeEnum.OFFICE && !isOrganisation

    const areFieldsDisabled =
      isAddressTypeVendingMachine || isEmployeeOfficeAddress

    const handleSubmit = formMethods.handleSubmit(async data => {
      // vending maching fields are optional
      // no need to validate them
      if (isAddressTypeVendingMachine) {
        onSubmit(data)

        return
      }

      onSetIsSubmitting(true)

      const shouldValidateOfficeAddress = !!officeAddress

      const address =
        areFieldsDisabled && shouldValidateOfficeAddress
          ? { nameAddition: data.nameAddition }
          : {
              city:
                getSelectValue(data.country) === countryISOCodes.US
                  ? `${data.city}, ${getSelectValue(data.state)}`
                  : data.city,
              country: getSelectValue(data.country),
              name: data.name,
              nameAddition: data.nameAddition,
              street:
                getSelectValue(data.country) === countryISOCodes.US
                  ? data.usAddress
                  : `${data.street} ${data.streetNumber}`,
              zipcode: data.zipcode,
            }

      try {
        const response = await client.query<
          getAddressValidation,
          getAddressValidationVariables & {
            addressID?: string
            organisationID?: string
          }
        >({
          query: GET_ADDRESS_VALIDATION,
          variables: {
            address,
            addressID:
              areFieldsDisabled && shouldValidateOfficeAddress
                ? officeAddress.id
                : undefined,
            organisationID:
              areFieldsDisabled && shouldValidateOfficeAddress
                ? organisationId
                : undefined,
          },
        })

        if (
          response.data.addressValidation?.__typename ===
          'AddressValidationProblem'
        ) {
          setFormErrors<ShippingAddressFormSchema>(
            response.data,
            'addressValidation.invalidInputs',
            formMethods.setError,
          )
          onSetIsSubmitting(false)

          return
        }

        // TODO: Handle middleware fields validation, messy solution should be removed
        // and handled fully by the solution aboive
        if (
          !response.data.addressValidation &&
          response.errors &&
          response.errors?.length > 0
        ) {
          if (response.errors[0].path) {
            let errorPath =
              response.errors[0].path[response.errors[0].path.length - 1]

            if (
              getSelectValue(data.country) === countryISOCodes.US &&
              errorPath === 'street'
            ) {
              errorPath = 'usAddress'
            }

            setError(errorPath as any, { message: response.errors[0].message })
          }

          onSetIsSubmitting(false)

          return
        }

        onSetIsSubmitting(false)
        onSubmit(data)
      } catch (error) {
        onSetIsSubmitting(false)
      }
    })

    useEffect(() => {
      if (!defaultValues) {
        return
      }

      reset(defaultValues)
    }, [defaultValues, reset])

    return (
      <FormProvider {...formMethods}>
        <Form className={clsx(className)} id={formId} onSubmit={handleSubmit}>
          {showOptionsHeader && (
            <h6 className="m-0">
              <FormattedMessage id="ShippingAddressForm_heading" />
            </h6>
          )}

          <ShippingFormAddressTypes
            customAddressTypesConfig={customAddressTypesConfig}
            e2eSelector={E2E_ADDRESS_FORM_ADDRESS_TYPE}
            hasDefaultValues={!!defaultValues}
            rental={rental}
          />

          <Row>
            <Col md={fieldColumnWidth} xs={12}>
              <Field
                autoComplete="name"
                containerClassName="mt-6"
                disabled={areFieldsDisabled}
                e2eSelector={E2E_ADDRESS_FORM_NAME}
                inputProps={{ allowBlockedCharacters: true }}
                label={SHIPPING_ADDRESS_FORM_LABELS.name}
                markAsRequired={!areFieldsDisabled}
                name="name"
                type={FieldTypeEnum.text}
              />
            </Col>

            <Col md={fieldColumnWidth} xs={12}>
              <Field
                autoComplete="additional-name"
                containerClassName={clsx(fieldContainerClassName)}
                disabled={isAddressTypeVendingMachine}
                e2eSelector={E2E_ADDRESS_FORM_NAME_ADDITION}
                inputProps={{ allowBlockedCharacters: true }}
                label={SHIPPING_ADDRESS_FORM_LABELS.nameAddition}
                markAsRequired={isAddressTypeOffice}
                name="nameAddition"
                type={FieldTypeEnum.text}
              />
            </Col>
          </Row>

          <Row>
            {isCountryUnitedStates ? (
              <Col xs={12}>
                <Field
                  autoComplete="address-line1"
                  containerClassName={clsx(fieldContainerClassName)}
                  disabled={areFieldsDisabled}
                  e2eSelector={E2E_ADDRESS_FORM_US_ADDRESS}
                  inputProps={{ allowBlockedCharacters: true }}
                  label={SHIPPING_ADDRESS_FORM_LABELS.usAddress}
                  markAsRequired={!areFieldsDisabled}
                  name="usAddress"
                  type={FieldTypeEnum.text}
                  // for unknown reason, after selecting different address
                  // this field renders inccorect value
                  // even though the value is correct inside the form.
                  // enforce rendering correct value
                  value={formMethods.getValues('usAddress')}
                />
              </Col>
            ) : (
              <>
                <Col md={fieldColumnWidth} xs={12}>
                  <Field
                    autoComplete="address-line1"
                    containerClassName={clsx(fieldContainerClassName)}
                    disabled={areFieldsDisabled}
                    e2eSelector={E2E_ADDRESS_FORM_STREET}
                    inputProps={{ allowBlockedCharacters: true }}
                    label={SHIPPING_ADDRESS_FORM_LABELS.street}
                    markAsRequired={!areFieldsDisabled}
                    name="street"
                    type={FieldTypeEnum.text}
                    // for unknown reason, after selecting different address
                    // this field renders inccorect value
                    // even though the value is correct inside the form.
                    // enforce rendering correct value
                    value={formMethods.getValues('street')}
                  />
                </Col>

                <Col md={fieldColumnWidth} xs={12}>
                  <Field
                    autoComplete="off"
                    containerClassName={clsx(fieldContainerClassName)}
                    disabled={areFieldsDisabled}
                    e2eSelector={E2E_ADDRESS_FORM_STREET_NUMBER}
                    inputProps={{ allowBlockedCharacters: true }}
                    label={SHIPPING_ADDRESS_FORM_LABELS.streetNumber}
                    markAsRequired={!areFieldsDisabled}
                    name="streetNumber"
                    type={FieldTypeEnum.text}
                  />
                </Col>
              </>
            )}
          </Row>

          {!isCountryUnitedStates && (
            <Row>
              <Col xs={12}>
                <Field
                  autoComplete="address-line2"
                  containerClassName={clsx(fieldContainerClassName)}
                  disabled={areFieldsDisabled}
                  e2eSelector={E2E_ADDRESS_FORM_STREET_ADDITION}
                  inputProps={{ allowBlockedCharacters: true }}
                  label={SHIPPING_ADDRESS_FORM_LABELS.streetAddition}
                  name="streetAddition"
                  type={FieldTypeEnum.text}
                />
              </Col>
            </Row>
          )}

          <Row>
            {isCountryUnitedStates ? (
              <>
                <Col md={fieldColumnWidth} xs={12}>
                  <Field
                    autoComplete="address-level2"
                    containerClassName={clsx(fieldContainerClassName)}
                    disabled={areFieldsDisabled}
                    e2eSelector={E2E_ADDRESS_FORM_CITY}
                    inputProps={{ allowBlockedCharacters: true }}
                    label={SHIPPING_ADDRESS_FORM_LABELS.city}
                    markAsRequired={!areFieldsDisabled}
                    name="city"
                    type={FieldTypeEnum.text}
                  />
                </Col>

                <Col md={fieldColumnWidth} xs={12}>
                  <StateSelect
                    containerClassName={clsx(fieldContainerClassName)}
                    disabled={areFieldsDisabled}
                    e2eSelector={E2E_ADDRESS_FORM_STATE}
                    label={SHIPPING_ADDRESS_FORM_LABELS.state}
                    markAsRequired={!areFieldsDisabled}
                    name="state"
                  />
                </Col>
              </>
            ) : (
              <>
                <Col md={fieldColumnWidth} xs={12}>
                  <Field
                    autoComplete="postal-code"
                    containerClassName={clsx(fieldContainerClassName)}
                    disabled={areFieldsDisabled}
                    e2eSelector={E2E_ADDRESS_FORM_ZIPCODE}
                    feedbackTranslationValues={{
                      amount: SHIPPING_ADDRESS_FORM_ZIPCODE_MIN_CHARACTERS,
                    }}
                    label={SHIPPING_ADDRESS_FORM_LABELS.zipcode}
                    markAsRequired={!areFieldsDisabled}
                    name="zipcode"
                    type={FieldTypeEnum.text}
                  />
                </Col>

                <Col md={fieldColumnWidth} xs={12}>
                  <Field
                    autoComplete="address-level2"
                    containerClassName={clsx(fieldContainerClassName)}
                    disabled={areFieldsDisabled}
                    e2eSelector={E2E_ADDRESS_FORM_CITY}
                    inputProps={{ allowBlockedCharacters: true }}
                    label={SHIPPING_ADDRESS_FORM_LABELS.city}
                    markAsRequired={!areFieldsDisabled}
                    name="city"
                    type={FieldTypeEnum.text}
                  />
                </Col>
              </>
            )}
          </Row>

          <Row>
            {isCountryUnitedStates && (
              <Col md={fieldColumnWidth} xs={12}>
                <Field
                  autoComplete="postal-code"
                  containerClassName={clsx(fieldContainerClassName)}
                  disabled={areFieldsDisabled}
                  e2eSelector={E2E_ADDRESS_FORM_ZIPCODE}
                  feedbackTranslationValues={{
                    amount: SHIPPING_ADDRESS_FORM_ZIPCODE_MIN_CHARACTERS,
                  }}
                  label={SHIPPING_ADDRESS_FORM_LABELS.zip}
                  markAsRequired={!areFieldsDisabled}
                  name="zipcode"
                  type={FieldTypeEnum.text}
                />
              </Col>
            )}

            <Col md={fieldColumnWidth} xs={12}>
              <CountrySelect
                containerClassName={clsx(fieldContainerClassName)}
                disabled={areFieldsDisabled}
                e2eSelector={E2E_ADDRESS_FORM_COUNTRY}
                label={SHIPPING_ADDRESS_FORM_LABELS.country}
                markAsRequired={!areFieldsDisabled}
                name="country"
              />
            </Col>
          </Row>
        </Form>
      </FormProvider>
    )
  },
)
