import { FormattedMessage } from 'react-intl'
import { clsx } from 'clsx'

import { Card, CardBody, CardHeader } from 'Components/_theme'

import { ContentCardProps } from './ContentCard.types'

export const ContentCard = (props: ContentCardProps) => {
  const {
    children,
    className,
    employeeName,
    title,
    rightContent,
    titleSuffix,
  } = props

  const classNames = clsx('ContentCard mb-4 mb-lg-6', className)

  return (
    <Card className={classNames}>
      {title && (
        <CardHeader rightContent={rightContent}>
          <FormattedMessage id={title} />

          <span className="ContentCard-title-suffix">{titleSuffix}</span>
        </CardHeader>
      )}

      {employeeName && (
        <CardHeader rightContent={rightContent}>{employeeName}</CardHeader>
      )}

      <CardBody>{children}</CardBody>
    </Card>
  )
}
