import { useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons'

import { Alert } from 'Components/_theme'

import { E2E_ERROR } from 'Constants/e2e'

import { AlertWithErrorProps } from './AlertWithError.types'

export const AlertWithError = (props: AlertWithErrorProps) => {
  const { error } = props
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const { current: container } = containerRef

    if (container) {
      container.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [])

  return (
    <Alert
      ref={containerRef}
      data-e2e={E2E_ERROR}
      iconLeft={faCircleExclamation}
      variant="danger"
    >
      {error ? error.message : <FormattedMessage id="Error_occurred" />}
    </Alert>
  )
}
