import * as Sentry from '@sentry/react'

import { CASE_ITEM_TYPE_TRANSLATION_IDS } from 'Constants/caseItemType'

import { CaseItemType } from 'Portal/__generated__/globalTypes'

import { dashOrSpaceToUnderscore } from '../dashOrSpaceToUnderscore/dashOrSpaceToUnderscore.util'

export const getCaseItemTypeTranslationId = (
  caseItemType: `${CaseItemType}`,
) => {
  const formattedCaseType = dashOrSpaceToUnderscore(caseItemType)
  const title = CASE_ITEM_TYPE_TRANSLATION_IDS[formattedCaseType]

  if (!title) {
    Sentry.captureException(
      new Error(
        `getCaseItemTypeTranslationId: unknown caseType returned from the API: ${formattedCaseType}`,
      ),
    )

    return 'Unknown_case_type'
  }

  return title
}
