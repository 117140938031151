import { FormattedMessage, useIntl } from 'react-intl'
import { observer } from 'mobx-react'

import { getFullContactName, isActiveLanguage } from 'Utilities'

import { CountriesDE } from 'Constants/CountriesDE'
import { CountriesEN } from 'Constants/CountriesEN'
import { SupportedLanguagesEnum } from 'Constants/locale'

import { ShippingAddressProps } from './ShippingAddress.types'
import { renderShippingAddressLine } from './ShippingAddress.utils'

export const ShippingAddress = observer((props: ShippingAddressProps) => {
  const { assignedEmployee, requestedShippingAddress } = props

  const intl = useIntl()
  const isGermanLanguageActive = isActiveLanguage(SupportedLanguagesEnum.DE)

  const { name, nameAddition, street, streetAddition, zipcode, city, country } =
    requestedShippingAddress

  const employeeName = getFullContactName(assignedEmployee?.contact)

  return (
    <>
      <span className="ShipmentTrackingCard-title mt-0">
        <FormattedMessage id="ShipmentTrackingCard_shipping_address" />
      </span>

      {renderShippingAddressLine(name)}
      {name !== nameAddition && renderShippingAddressLine(nameAddition)}
      {renderShippingAddressLine(street)}
      {renderShippingAddressLine(streetAddition)}
      {renderShippingAddressLine(`${zipcode} ${city}`)}
      {renderShippingAddressLine(
        isGermanLanguageActive ? CountriesDE[country] : CountriesEN[country],
      )}
      {employeeName &&
        employeeName !== name &&
        employeeName !== nameAddition &&
        renderShippingAddressLine(
          `${intl.formatMessage({
            id: 'Employee',
          })}: ${employeeName}`,
        )}
    </>
  )
})
