import { startsWith } from 'lodash-es'
import { DateTime } from 'luxon'

export const shouldShowTracking = (performedAt: any) => {
  const shippingDate = DateTime.fromJSDate(new Date(performedAt))
  const daysFromShippingDate = shippingDate.diffNow('days')

  if (Math.abs(daysFromShippingDate.days) > 60) {
    return false
  }

  return true
}

export const shouldShowTrackingURL = (
  trackingURL: string | null | undefined,
  performedAt: any,
) => {
  if (!trackingURL || !performedAt || !startsWith(trackingURL, 'http')) {
    return false
  }

  return shouldShowTracking(performedAt)
}

export const shouldShowTrackingReference = (
  trackingReference: string | null | undefined,
) => !!trackingReference
