export const formatDate = (
  value?: string | Date | null,
  shouldShowTime?: boolean,
) => {
  if (!value) {
    return null
  }

  const date = value instanceof Date ? value : new Date(value)
  const preferredLanguage = navigator.languages[0] || window.navigator.language
  const formattedDate = new Intl.DateTimeFormat(preferredLanguage).format(date)

  if (shouldShowTime) {
    const formattedTime = new Intl.DateTimeFormat(preferredLanguage, {
      timeStyle: 'short',
    }).format(date)

    return `${formattedDate}, ${formattedTime}`
  }

  return formattedDate
}
