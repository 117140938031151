import { FormattedMessage } from 'react-intl'
import { faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { clsx } from 'clsx'

import { Icon } from 'Components/Icon/Icon'

import { E2E_LOADING } from 'Constants/e2e'

import { LoadingStateProps } from './LoadingState.types'

export const LoadingState = (props: LoadingStateProps) => {
  const { classNames, height, margin } = props

  return (
    <div
      className={clsx(
        'LoadingState',
        'd-flex',
        'align-items-center',
        'justify-content-center',
        classNames,
      )}
      style={{ height, margin }}
    >
      <Icon icon={faSpinner} size={50} spin />

      <span className="visually-hidden" data-e2e={E2E_LOADING}>
        <FormattedMessage id="Loading" />
      </span>
    </div>
  )
}
