import { forwardRef, useCallback } from 'react'
import { Col, Row } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import {
  faChevronRight,
  faMessagesQuestion,
} from '@fortawesome/pro-solid-svg-icons'
import { clsx } from 'clsx'

import { LinkButton, PrimaryButton } from 'Components/_theme'
import { Icon } from 'Components/Icon/Icon'

import { openIntercomChat } from 'Utilities'

import { E2E_SHOW_PREVIEW_COLUMN_BUTTON } from 'Constants/e2e'

import {
  PREVIEW_COLUMN_TYPE_TRANSLATION_IDS,
  PreviewColumnFooterProps,
} from './PreviewColumn.types'

export const PreviewColumnFooter = forwardRef(
  (
    props: PreviewColumnFooterProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const {
      id,
      type,
      url,
      onClosePreviewColumn,
      disableAskQuestion,
      renderCustomFooterButton,
    } = props

    const intl = useIntl()
    const history = useHistory()

    const handleAskQuestionButtonClick = useCallback(() => {
      onClosePreviewColumn()

      const intercomMessage = intl.formatMessage(
        {
          id: 'PreviewColumn_intercom_intro',
        },
        {
          id,
          type: intl.formatMessage({
            id: PREVIEW_COLUMN_TYPE_TRANSLATION_IDS[type],
          }),
        },
      )

      openIntercomChat(intercomMessage)
    }, [id, intl, onClosePreviewColumn, type])

    const handleSeeAllButtonClick = () => {
      onClosePreviewColumn()

      if (url) {
        history.push(url)
      }
    }

    return (
      <div
        ref={ref}
        className={clsx(
          'PreviewColumn-footer',
          disableAskQuestion && 'PreviewColumn-footer--no-qBox',
        )}
      >
        <Row className="d-flex flex-column g-0">
          {!disableAskQuestion && (
            <Col
              className="PreviewColumn-footer-ask-question d-flex align-items-center"
              xs={12}
            >
              <LinkButton
                aria-label={intl.formatMessage({
                  id: 'PreviewColumn_footer_question',
                })}
                className="w-100"
                iconRight={faChevronRight}
                onClick={handleAskQuestionButtonClick}
              >
                <span className="d-flex align-items-center">
                  <Icon className="me-2" icon={faMessagesQuestion} size={20} />
                  <FormattedMessage id="PreviewColumn_footer_question" />
                </span>
              </LinkButton>
            </Col>
          )}

          <Col
            className="PreviewColumn-footer-button d-flex justify-content-center align-items-center"
            xs={12}
          >
            {renderCustomFooterButton || (
              <PrimaryButton
                className="w-100"
                data-e2e={E2E_SHOW_PREVIEW_COLUMN_BUTTON}
                onClick={handleSeeAllButtonClick}
              >
                <FormattedMessage id="PreviewColumn_footer_button" />
              </PrimaryButton>
            )}
          </Col>
        </Row>
      </div>
    )
  },
)
