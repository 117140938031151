import { useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import { HashRouter } from 'react-router-dom'
import { useMountEffect } from '@react-hookz/web'
import { observer } from 'mobx-react'

import { Router } from 'Router/Router'

import { AppLoader } from 'Components/AppLoader/AppLoader'
import { CookieApproval } from 'Components/CookieApproval/CookieApproval'

import { useStore } from 'Stores/index'
import { UserModeEnum } from 'Stores/portalSettingsStore/portalSettingsStore.types'

import { LOCAL_STORAGE } from 'Constants/constants'
import {
  AVAILABLE_LANGUAGES,
  isSupportedLanguage,
  SupportedLanguagesEnum,
} from 'Constants/locale'

import { AppEnvEnum } from 'Types/env.types'

import { AppProps } from './App.types'
import {
  IntercomAuthProvider,
  ModalProvider,
  UserwayProvider,
} from './providers'
import { loadGtagScript } from './utilities/analytics/analytics.util'
import { getIntlConfig } from './utilities/getIntlConfig/getIntlConfig.util'
import { handleNewJoinerLogout } from './utilities/handleNewJoinerLogout/handleNewJoinerLogout.util'

export const App = observer((props: AppProps) => {
  const { appState } = props
  const { userStore, portalSettingsStore } = useStore()
  const { fetchAclsAndSetUser } = userStore
  const { setI18n, i18n, isAppReady, setUserMode } = portalSettingsStore
  const location = window.location.href.split('/')

  const handleGoogleAnalytics = (isAccepted: boolean | undefined) => {
    if (isAccepted) {
      loadGtagScript()
    }
  }

  useMountEffect(() => {
    if (!window.INTERCOM_WORKSPACE_ID || window.APP_ENV === AppEnvEnum.DEV) {
      return
    }

    window.Intercom('boot', {
      app_id: window.INTERCOM_WORKSPACE_ID,
    })
  })

  useMountEffect(() => {
    if (location.includes('new-joiner')) {
      setUserMode(UserModeEnum.NEW_JOINER)
    }

    // TODO: remove this once backend handles this case
    if (!location.includes('new-joiner') && appState.isLoggedInAsNewJoiner) {
      handleNewJoinerLogout()

      localStorage.removeItem('newJoiner')
    }

    if (localStorage.getItem(LOCAL_STORAGE.COOKIE_APPROVAL) === 'true') {
      loadGtagScript()
    }
  })

  useEffect(() => {
    fetchAclsAndSetUser(appState).then(() => {
      if (isSupportedLanguage(appState.language)) {
        setI18n(
          AVAILABLE_LANGUAGES[appState.language as SupportedLanguagesEnum],
        )
      }
    })
  }, [fetchAclsAndSetUser, appState, setI18n])

  if (
    !isAppReady ||
    (!location.includes('new-joiner') && appState.isLoggedInAsNewJoiner)
  ) {
    return <AppLoader />
  }

  return (
    <>
      <IntercomAuthProvider />

      <UserwayProvider />

      <IntlProvider {...getIntlConfig(i18n.language, i18n.locale)}>
        <HashRouter>
          <ModalProvider>
            <Router />
          </ModalProvider>
        </HashRouter>

        <CookieApproval getApproval={handleGoogleAnalytics} />
      </IntlProvider>
    </>
  )
})
