/* eslint-disable react/no-unstable-nested-components */
import { Col, Container, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { clsx } from 'clsx'
import { observer } from 'mobx-react'

import { Card, CardBody, CardHeader, Heading } from 'Components/_theme'
import { DecorHeader } from 'Components/DecorHeader/DecorHeader'
import { PageHeader } from 'Components/PageHeader/PageHeader'

import { useAuthToken } from 'Hooks'

import { PortalSectionEnum } from 'Constants/portalSection'

export const DataPrivacy = observer(() => {
  const authToken = useAuthToken()
  const isLoggedIn = !!authToken

  return (
    <Container className={clsx('pt-6', !isLoggedIn && 'DataPrivacy')} fluid>
      <div className={clsx(!isLoggedIn && 'text-center')} role="banner">
        <PageHeader portalSection={PortalSectionEnum.DataPrivacy} />
      </div>

      <Row className={clsx(!isLoggedIn && 'justify-content-center')}>
        <Col xl={12} xxl={8}>
          <Card className="data-privacy-card mb-6">
            <DecorHeader title="DataPrivacy_Section1_heading" />

            <CardHeader className="data-privacy-intro">
              <FormattedMessage id="DataPrivacy_Section1_heading2" />
            </CardHeader>

            <CardBody>
              <div className="subsection">
                <p>
                  <FormattedMessage id="DataPrivacy_Section1_line1" />
                </p>

                <p>
                  <FormattedMessage id="DataPrivacy_Section1_line2" />
                </p>

                <p>
                  <FormattedMessage id="DataPrivacy_Section1_line3" />
                  <br />

                  <FormattedMessage id="DataPrivacy_Section1_line4" />
                  <br />

                  <FormattedMessage id="DataPrivacy_Section1_line5" />
                  <br />

                  <FormattedMessage id="DataPrivacy_Section1_line6" />
                </p>

                <p>
                  <FormattedMessage id="DataPrivacy_Section1_line7" />:{' '}
                  <a
                    href="tel:+4930516958275"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    +4930516958275
                  </a>
                  <br />
                  <FormattedMessage id="DataPrivacy_Section1_line8" />:{' '}
                  <a
                    href="mailto:info@everphone.de"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    info@everphone.de
                  </a>
                  <br />
                  <FormattedMessage id="DataPrivacy_Section1_line9" />:{' '}
                  <a
                    href="https://www.everphone.app/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    https://www.everphone.app
                  </a>
                </p>

                <p>
                  <FormattedMessage id="DataPrivacy_Section1_line10" />

                  <strong>
                    <FormattedMessage id="DataPrivacy_Section1_line11" />
                  </strong>

                  <FormattedMessage id="DataPrivacy_Section1_line12" />
                </p>

                <p>
                  <FormattedMessage id="DataPrivacy_Section1_line13" />
                  <a
                    href="mailto: email@iitr.de"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <u>email@iitr.de</u>
                  </a>
                </p>
              </div>
            </CardBody>

            <CardHeader>
              <FormattedMessage id="DataPrivacy_Section2_heading" />
            </CardHeader>

            <CardBody>
              <div className="subsection">
                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section2_subheading1" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section2_line1" />
                </p>

                <ul>
                  <li>
                    <FormattedMessage id="DataPrivacy_Section2_listItem1" />
                  </li>

                  <li>
                    <FormattedMessage id="DataPrivacy_Section2_listItem2" />
                  </li>

                  <li>
                    <FormattedMessage id="DataPrivacy_Section2_listItem3" />
                  </li>

                  <li>
                    <FormattedMessage id="DataPrivacy_Section2_listItem4" />
                  </li>

                  <li>
                    <FormattedMessage id="DataPrivacy_Section2_listItem5" />
                  </li>

                  <li>
                    <FormattedMessage id="DataPrivacy_Section2_listItem6" />
                  </li>

                  <li>
                    <FormattedMessage id="DataPrivacy_Section2_listItem7" />
                  </li>

                  <li>
                    <FormattedMessage id="DataPrivacy_Section2_listItem8" />
                  </li>

                  <li>
                    <FormattedMessage id="DataPrivacy_Section2_listItem9" />
                  </li>
                </ul>

                <p>
                  <FormattedMessage id="DataPrivacy_Section2_line2" />
                </p>

                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section2_subheading2" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section2_subheading1_line1" />
                </p>

                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section2_subheading3" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section2_subheading2_line1" />
                </p>

                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section2_subheading4" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section2_subheading3_line1" />
                </p>
              </div>
            </CardBody>

            <CardHeader>
              <FormattedMessage id="DataPrivacy_Section3_heading" />
            </CardHeader>

            <CardBody>
              <div className="subsection">
                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section3_subheading1" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section3_line1" />
                </p>

                <p>
                  <FormattedMessage id="DataPrivacy_Section3_line2" />
                </p>

                <p>
                  <FormattedMessage id="DataPrivacy_Section3_line3" />
                </p>

                <p>
                  <FormattedMessage id="DataPrivacy_Section3_line4" />
                  <a
                    href="http://tools.google.com/dlpage/gaoptout?hl=de"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <u>http://tools.google.com/dlpage/gaoptout?hl=de</u>
                  </a>
                  .
                </p>

                <p>
                  <FormattedMessage id="DataPrivacy_Section3_line5" />
                </p>

                <p>
                  <FormattedMessage id="DataPrivacy_Section3_line6" />
                  <a
                    href="http://www.google.com/analytics/terms/de.html"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <u>http://www.google.com/analytics/terms/de.html</u>
                  </a>
                  <FormattedMessage id="DataPrivacy_Section3_line7" />
                  <a
                    href="http://www.google.de/intl/de/policies/privacy"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <u>http://www.google.de/intl/de/policies/privacy</u>
                  </a>
                  .
                </p>

                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section3_subheading2" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section3_line8" />
                </p>

                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section3_subheading3" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section3_line9" />
                </p>
              </div>
            </CardBody>

            <CardHeader>
              <FormattedMessage id="DataPrivacy_Section4_heading" />
            </CardHeader>

            <CardBody>
              <div className="subsection">
                <p>
                  <FormattedMessage id="DataPrivacy_Section4_line1" />
                </p>

                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section4_subheading1" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section4_line2" />
                </p>

                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section4_subheading2" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section4_line3" />
                </p>

                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section4_subheading3" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section4_line4" />
                </p>

                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section4_subheading4" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section4_line5" />
                </p>
              </div>
            </CardBody>

            <CardHeader>
              <FormattedMessage id="DataPrivacy_Section5_heading" />
            </CardHeader>

            <CardBody>
              <div className="subsection">
                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section5_subheading1" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section5_line1" />
                </p>

                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section5_subheading2" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section5_line2" />
                </p>

                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section5_subheading3" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section5_line3" />
                </p>

                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section5_subheading4" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section5_line4" />
                </p>

                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section5_subheading5" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section5_line5" />
                </p>
              </div>
            </CardBody>

            <CardHeader>
              <FormattedMessage id="DataPrivacy_Section6_heading" />
            </CardHeader>

            <CardBody>
              <div className="subsection">
                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section6_subheading1" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section6_line1" />
                </p>

                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section6_subheading2" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section6_line2" />
                </p>

                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section6_subheading3" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section6_line3" />
                </p>

                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section6_subheading4" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section6_line4" />
                </p>

                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section6_subheading5" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section6_line5" />
                </p>
              </div>
            </CardBody>

            <CardHeader>
              <FormattedMessage id="DataPrivacy_Section7_heading" />
            </CardHeader>

            <CardBody>
              <div className="subsection">
                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section7_subheading1" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section7_line1" />
                </p>

                <ul>
                  <li>
                    <FormattedMessage id="DataPrivacy_Section7_listItem1" />
                  </li>

                  <li>
                    <FormattedMessage id="DataPrivacy_Section7_listItem2" />
                  </li>

                  <li>
                    <FormattedMessage id="DataPrivacy_Section7_listItem3" />
                  </li>
                </ul>

                <p>
                  <FormattedMessage id="DataPrivacy_Section7_line2" />
                </p>

                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section7_subheading2" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section7_line3" />
                </p>

                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section7_subheading3" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section7_line4" />
                </p>

                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section7_subheading4" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section7_line5" />
                </p>

                <ul>
                  <li>
                    <FormattedMessage id="DataPrivacy_Section7_listItem4" />
                  </li>

                  <li>
                    <FormattedMessage id="DataPrivacy_Section7_listItem5" />
                  </li>

                  <li>
                    <FormattedMessage id="DataPrivacy_Section7_listItem6" />
                  </li>

                  <li>
                    <FormattedMessage id="DataPrivacy_Section7_listItem7" />
                  </li>

                  <li>
                    <FormattedMessage id="DataPrivacy_Section7_listItem8" />
                  </li>

                  <li>
                    <FormattedMessage id="DataPrivacy_Section7_listItem9" />
                  </li>

                  <li>
                    <FormattedMessage id="DataPrivacy_Section7_listItem10" />
                  </li>

                  <li>
                    <FormattedMessage id="DataPrivacy_Section7_listItem11" />
                  </li>

                  <li>
                    <FormattedMessage id="DataPrivacy_Section7_listItem12" />
                  </li>
                </ul>

                <p>
                  <strong>
                    <FormattedMessage id="DataPrivacy_Section7_line6" />
                  </strong>
                </p>

                <ul>
                  <li>
                    <FormattedMessage id="DataPrivacy_Section7_listItem13" />
                  </li>

                  <li>
                    <FormattedMessage id="DataPrivacy_Section7_listItem14" />
                  </li>
                </ul>

                <p>
                  <strong>
                    <FormattedMessage id="DataPrivacy_Section7_line7" />
                  </strong>
                </p>

                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section7_subheading5" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section7_line8" />
                </p>
              </div>
            </CardBody>

            <CardHeader>
              <FormattedMessage id="DataPrivacy_Section8_heading" />
            </CardHeader>

            <CardBody>
              <div className="subsection">
                <p>
                  <FormattedMessage id="DataPrivacy_Section8_line1" />
                </p>

                <ul>
                  <li>
                    <FormattedMessage id="DataPrivacy_Section8_listItem1" />
                    <a
                      href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimensiondata-protection/adequacy-decisions_en"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <u>
                        https://ec.europa.eu/info/law/law-topic/data-protection/international-dimensiondata-protection/adequacy-decisions_en
                      </u>
                    </a>
                    )
                  </li>

                  <li>
                    <FormattedMessage id="DataPrivacy_Section8_listItem2" />
                    <a
                      href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimensiondata-protection/standard-contractual-clauses-scc_en"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <u>
                        https://ec.europa.eu/info/law/law-topic/data-protection/international-dimensiondata-protection/standard-contractual-clauses-scc_en
                      </u>
                    </a>
                    )
                  </li>

                  <li>
                    <FormattedMessage id="DataPrivacy_Section8_listItem3" />
                  </li>
                </ul>

                <p>
                  <FormattedMessage id="DataPrivacy_Section8_line2" />
                </p>
              </div>
            </CardBody>

            <CardHeader>
              <FormattedMessage id="DataPrivacy_Section9_heading" />
            </CardHeader>

            <CardBody>
              <div className="subsection">
                <p>
                  <FormattedMessage id="DataPrivacy_Section9_line1" />
                </p>
              </div>
            </CardBody>

            <CardHeader>
              <FormattedMessage id="DataPrivacy_Section10_heading" />
            </CardHeader>

            <CardBody>
              <div className="subsection">
                <p>
                  <FormattedMessage id="DataPrivacy_Section10_line1" />
                  <a
                    href="mailto:dataprivacy@everphone.de"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <u>dataprivacy@everphone.de</u>
                  </a>

                  <FormattedMessage id="DataPrivacy_Section10_line2" />
                </p>

                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section10_subheading1" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section10_line3" />
                </p>

                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section10_subheading2" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section10_line4" />
                </p>

                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section10_subheading3" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section10_line5" />
                </p>

                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section10_subheading4" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section10_line6" />
                </p>

                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section10_subheading5" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section10_line7" />
                </p>

                <ul>
                  <li>
                    <FormattedMessage id="DataPrivacy_Section10_listItem1" />
                  </li>

                  <li>
                    <FormattedMessage id="DataPrivacy_Section10_listItem2" />
                  </li>

                  <li>
                    <FormattedMessage id="DataPrivacy_Section10_listItem3" />
                  </li>

                  <li>
                    <FormattedMessage id="DataPrivacy_Section10_listItem4" />
                  </li>
                </ul>

                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section10_subheading6" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section10_line8" />
                </p>

                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section10_subheading7" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section10_line9" />
                </p>

                <Heading as={5} className="mb-1" size={4}>
                  <FormattedMessage id="DataPrivacy_Section10_subheading8" />
                </Heading>

                <p>
                  <FormattedMessage id="DataPrivacy_Section10_line10" />
                </p>
              </div>
            </CardBody>

            <CardBody>
              <p>
                <FormattedMessage id="DataPrivacy_Status" />
              </p>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
})
