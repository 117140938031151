export const NETPRICE_CALCULATOR_TAX_CLASSES = [
  { name: '1', value: 1 },
  { name: '2', value: 2 },
  { name: '3', value: 3 },
  { name: '4', value: 4 },
  { name: '5', value: 5 },
  { name: '6', value: 6 },
]

export const NETPRICE_CALCULATOR_CHURCH_TAXES = [
  { name: 'No', value: 0 },
  { name: 'Yes', value: 1 },
]
