import { useEffect, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { PreviewColumn } from 'Components/PreviewColumn/PreviewColumn'
import { PreviewColumnTypeEnum } from 'Components/PreviewColumn/PreviewColumn.types'
import { PreviewColumnContent } from 'Components/PreviewColumn/PreviewColumnContent'
import { PreviewColumnHeader } from 'Components/PreviewColumn/PreviewColumnHeader'

import { useIsOrganisation } from 'Hooks'

import {
  dashOrSpaceToUnderscore,
  getCaseItemTypeTranslationId,
} from 'Utilities'

import {
  getOrderListPreviewColumn,
  getOrderListPreviewColumnVariables,
} from 'Constants/graphql/queries/detail/__generated__/getOrderListPreviewColumn'
import { GET_ORDER_LIST_PREVIEW_COLUMN } from 'Constants/graphql/queries/detail/GetOrderDetails'

import { CaseItemType } from 'Portal/__generated__/globalTypes'
import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import { OrdersPreviewColumnProps } from './OrdersPreviewColumn.types'
import { generateOrdersPreviewColumnContent } from './OrdersPreviewColumn.utils'

export const OrdersPreviewColumn = (props: OrdersPreviewColumnProps) => {
  const { entityId, onClosePreviewColumn, isVisible } = props
  const intl = useIntl()
  const isOrganisation = useIsOrganisation()
  const { employeeId, organisationId } = useParams<{
    employeeId?: string
    organisationId?: string
  }>()

  const path = composePath({
    isOrganisation,
    params: {
      employeeId,
      orderId: entityId || '0',
      organisationId,
    },
    paths: {
      employee: GLOBAL_ROUTE_PATHS.employee.order,
      organisation: GLOBAL_ROUTE_PATHS.organisation.order,
    },
  })

  const [getOrdersPreviewData, { loading: isLoading, data }] = useLazyQuery<
    getOrderListPreviewColumn,
    getOrderListPreviewColumnVariables
  >(GET_ORDER_LIST_PREVIEW_COLUMN)

  const QRCode = data?.caseItem?.generatedQRCode || ''

  const generateContent = useMemo(
    () => generateOrdersPreviewColumnContent(data?.caseItem),
    [data?.caseItem],
  )

  const generateName = useMemo(() => {
    if (data?.caseItem.offerOrderItem?.customDeviceName) {
      return data?.caseItem.offerOrderItem?.customDeviceName
    }

    const caseItemData = data?.caseItem.data

    if (caseItemData?.requestedDevice) {
      return caseItemData.requestedDevice.name ?? ''
    }

    if (caseItemData?.requestedRentCategory?.deviceDefinition?.name) {
      return caseItemData?.requestedRentCategory?.deviceDefinition?.name
    }

    if (caseItemData?.requestedOfferOrder?.deviceDefinition?.name) {
      return caseItemData?.requestedOfferOrder?.deviceDefinition?.name
    }

    if (
      caseItemData?.requestedPhonePlan?.name &&
      data?.caseItem.caseType &&
      dashOrSpaceToUnderscore(data?.caseItem.caseType) ===
        CaseItemType.new_phone_plan
    ) {
      return caseItemData.requestedPhonePlan.name
    }

    if (data?.caseItem.caseType) {
      const title = getCaseItemTypeTranslationId(data.caseItem.caseType)

      return intl.formatMessage({
        id: title,
      })
    }

    return ''
  }, [
    data?.caseItem.caseType,
    data?.caseItem.data,
    data?.caseItem.offerOrderItem?.customDeviceName,
    intl,
  ])

  const generateTitle = useMemo(() => {
    const requestName =
      data?.caseItem.caseType &&
      data?.caseItem.data.requestedRentCategory?.deviceDefinition
        ? getCaseItemTypeTranslationId(data.caseItem.caseType)
        : intl.formatMessage({
            id: 'Request',
          })

    return intl.formatMessage({
      id: requestName,
    })
  }, [
    data?.caseItem.caseType,
    data?.caseItem.data.requestedRentCategory?.deviceDefinition,
    intl,
  ])

  useEffect(() => {
    if (!entityId) {
      return
    }

    getOrdersPreviewData({
      variables: {
        id: entityId,
      },
    })
  }, [entityId, getOrdersPreviewData])

  return (
    <PreviewColumn
      contentElement={
        <PreviewColumnContent content={generateContent} twoColumn>
          {!!QRCode && (
            <>
              <div className="PreviewColumn-title border-bottom-0">
                <FormattedMessage id="QRCodeCard_heading" />
              </div>

              <div className="px-3">
                <img
                  alt="QR Code"
                  height={128}
                  src={`data:image/png;base64,${QRCode}`}
                  width={128}
                />
              </div>
            </>
          )}
        </PreviewColumnContent>
      }
      headerElement={
        <PreviewColumnHeader
          caseItemStatus={data?.caseItem.status}
          image={
            data?.caseItem.data.requestedRentCategory?.deviceDefinition
              ?.imageURL
          }
          name={generateName}
          onClosePreviewColumn={onClosePreviewColumn}
          productType={
            data?.caseItem.data.requestedRentCategory?.deviceDefinition
              ?.productType
          }
          title={generateTitle}
        />
      }
      id={entityId}
      isLoading={isLoading}
      isVisible={isVisible}
      onClosePreviewColumn={onClosePreviewColumn}
      type={PreviewColumnTypeEnum.Request}
      url={path}
    />
  )
}
