import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { clsx } from 'clsx'

import { FormattedCurrency } from 'Components/FormattedCurrency/FormattedCurrency'

import { OrderUpgradeFeeProps } from './OrderUpgradeFee.types'

export const OrderUpgradeFee = (props: OrderUpgradeFeeProps) => {
  const { price, className } = props

  const amount = useMemo(() => <FormattedCurrency value={price} />, [price])

  return (
    <div className={clsx('d-flex flex-column text-end', className)}>
      <p className="mb-2">
        <strong>
          <FormattedMessage id="Upgrade_Fee_ExclVAT" />:{' '}
          <span className="fs-6 ms-1">{amount}</span>
        </strong>
      </p>

      <p className="mb-0">
        <FormattedMessage id="Order_upgrade_fee_info" />
      </p>
    </div>
  )
}
