export enum AppEnvEnum {
  DEMO = 'demo',
  DEV = 'dev',
  GCPDEV = 'gcpdev',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export enum NodeEnvEnum {
  DEV = 'development',
  TEST = 'test',
  PRODUCTION = 'production',
}
