import { EditEmployeeFormModalEmailConfirmationProps } from '../EditEmployeeFormModal.types'

export const EditEmployeeFormModalEmailConfirmation = (
  props: EditEmployeeFormModalEmailConfirmationProps,
) => {
  const { existingEmail, newEmail } = props

  return (
    <p>
      Your are about to change email from:{' '}
      <span className="fw-bold">{existingEmail}</span> to{' '}
      <span className="fw-bold">{newEmail}</span>. Are you sure you want to make
      this change.
    </p>
  )
}
