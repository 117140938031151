export const DATE_ATTRIBUTES = [
  'start_at',
  'created',
  'updated',
  'end_at',
  'date',
  'open_since',
]

export const FILTER_SEPARATORS = {
  DATE: ' - ',
  DEFAULT: ', ',
  NONE: '',
  NUMERIC: ' - ',
}
