import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import getSymbolFromCurrency from 'currency-symbol-map'

import { OfferPaymentForm } from 'Modules/purchase/components/OfferPaymentForm/OfferPaymentForm'
import { OfferStatus } from 'Modules/purchase/components/OfferStatus/OfferStatus'
import { OfferSummary } from 'Modules/purchase/components/OfferSummary/OfferSummary'
import { OfferError } from 'Modules/purchase/pages/OfferError/OfferError.page'

import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'

import { setRouteTitle } from 'Portal/src/router/Router.utils'
import { fetchFromPublicApiUsingPartialPath } from 'Portal/src/utilities'

import {
  ClientSecretResponse,
  OfferDetailsResponse,
} from './PurchaseDevice.types'

export const PurchaseDevice = () => {
  const stripePromise = loadStripe(window.STRIPE_PUBLISHABLE_KEY)
  const intl = useIntl()

  const { uuid = '' } = useParams<{
    uuid: string
  }>()

  const [offerDetailsResponse, setOfferDetailsResponse] =
    useState<OfferDetailsResponse | null>(null)

  const [clientSecretResponse, setClientSecretResponse] =
    useState<ClientSecretResponse | null>(null)

  const [isError, setIsError] = useState(false)

  useEffect(() => {
    const fetchOfferDetails = async () => {
      try {
        const offerDetailsResponse =
          await fetchFromPublicApiUsingPartialPath<OfferDetailsResponse>(
            `/payment-link/${uuid}`,
            { method: 'POST' },
          )

        if (offerDetailsResponse.error) {
          setIsError(true)

          return
        }

        if (offerDetailsResponse.offer_status !== 'accepted') {
          setOfferDetailsResponse(offerDetailsResponse)

          return
        }

        const clientSecretResponse =
          await fetchFromPublicApiUsingPartialPath<ClientSecretResponse>(
            `/payment-intent/${uuid}`,
            { method: 'POST' },
          )

        if (clientSecretResponse.error) {
          setIsError(true)

          return
        }

        setOfferDetailsResponse(offerDetailsResponse)
        setClientSecretResponse(clientSecretResponse)
      } catch (error) {
        setIsError(true)
      }
    }

    fetchOfferDetails()
  }, [uuid])

  const currencySymbol = useMemo(
    () =>
      offerDetailsResponse
        ? getSymbolFromCurrency(offerDetailsResponse.currency.toUpperCase())
        : null,
    [offerDetailsResponse],
  )

  useEffect(() => {
    setRouteTitle(
      intl.formatMessage({
        id: 'Purchase_device',
      }),
    )
  }, [intl])

  if (isError) {
    return <OfferError />
  }

  if (
    offerDetailsResponse &&
    offerDetailsResponse.offer_status !== 'accepted'
  ) {
    return <OfferStatus offerStatus={offerDetailsResponse?.offer_status} />
  }

  if (
    offerDetailsResponse &&
    clientSecretResponse?.client_secret &&
    currencySymbol
  ) {
    return (
      <div className="PurchaseDevice">
        <div className="PurchaseDevice-offerSummary d-flex w-100 p-9 justify-content-center">
          <OfferSummary
            currencySymbol={currencySymbol}
            itemName={offerDetailsResponse.device_name}
            itemPrice={offerDetailsResponse.net_price}
            subTotal={offerDetailsResponse.net_price}
            tax={offerDetailsResponse.tax_amount}
            total={offerDetailsResponse.payable_price}
          />
        </div>
        <div className="PurchaseDevice-offerPayment d-flex w-100 p-9 justify-content-center bg-white">
          <Elements
            options={{
              clientSecret: clientSecretResponse?.client_secret,
            }}
            stripe={stripePromise}
          >
            <OfferPaymentForm
              billingCountry={offerDetailsResponse.billing_country.toUpperCase()}
              billingName={offerDetailsResponse.employee_full_name}
              clientSecret={clientSecretResponse.client_secret}
              email={offerDetailsResponse.email}
            />
          </Elements>
        </div>
      </div>
    )
  }

  return <PageLoadingState />
}
