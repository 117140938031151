import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { LostOrStolenModalTypesEnum } from 'Modals/LostOrStolenModal/LostOrStolenModal.types'

import { getRental_rental } from 'Constants/graphql/queries/detail/__generated__/getRental'
import { getOrganisation_organisation } from 'Constants/graphql/queries/organisation/__generated__/getOrganisation'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

export const getlostOrStolenModalType = (
  isOrganisation: boolean,
  isOrganisationWithDepots: boolean,
) => {
  if (isOrganisationWithDepots) {
    return LostOrStolenModalTypesEnum.ORGANISATION_WITH_DEPOT
  }

  if (isOrganisation) {
    return LostOrStolenModalTypesEnum.ORGANISATION
  }

  return LostOrStolenModalTypesEnum.EMPLOYEE
}

export const getEarlyUpgradePath = (
  isOrganisation: boolean,
  rental: getRental_rental,
  employeeId: string | undefined,
  organisationId?: string | undefined,
) =>
  composePath({
    isOrganisation,
    params: {
      employeeId: isOrganisation ? rental.employee?.id : employeeId,
      organisationId,
      rentalId: rental.id,
    },
    paths: {
      employee: GLOBAL_ROUTE_PATHS.employee.orderEarlyUpgrade,
      organisation: employeeId
        ? GLOBAL_ROUTE_PATHS.organisation.employeeOrderEarlyUpgrade
        : GLOBAL_ROUTE_PATHS.organisation.orderEarlyUpgrade,
    },
  })

export const getUpgradePath = (
  isOrganisation: boolean,
  rental: getRental_rental,
  employeeId: string | undefined,
  organisationId?: string | undefined,
) =>
  composePath({
    isOrganisation,
    params: {
      employeeId: isOrganisation ? rental.employee?.id : employeeId,
      organisationId,
      rentalId: rental.id,
    },
    paths: {
      employee: GLOBAL_ROUTE_PATHS.employee.orderUpgrade,
      organisation: employeeId
        ? GLOBAL_ROUTE_PATHS.organisation.employeeOrderUpgrade
        : GLOBAL_ROUTE_PATHS.organisation.orderUpgrade,
    },
  })

export const getRepairUpgradePath = (
  isOrganisation: boolean,
  rental: getRental_rental,
  employeeId: string | undefined,
  organisationId?: string | undefined,
) =>
  composePath({
    isOrganisation,
    params: {
      employeeId: isOrganisation ? rental.employee?.id : employeeId,
      organisationId,
      rentalId: rental.id,
    },
    paths: {
      employee: GLOBAL_ROUTE_PATHS.employee.orderRepairUpgrade,
      organisation: employeeId
        ? GLOBAL_ROUTE_PATHS.organisation.employeeOrderRepairUpgrade
        : GLOBAL_ROUTE_PATHS.organisation.orderRepairUpgrade,
    },
  })

export const getProlongPath = (
  isOrganisation: boolean,
  rental: getRental_rental,
  employeeId: string | undefined,
  organisationId?: string | undefined,
) =>
  composePath({
    isOrganisation,
    params: {
      employeeId: isOrganisation ? rental.employee?.id : employeeId,
      organisationId,
      rentalId: rental.id,
    },
    paths: {
      employee: GLOBAL_ROUTE_PATHS.employee.orderProlong,
      organisation: GLOBAL_ROUTE_PATHS.organisation.employeeOrderProlong,
    },
  })

export const getLostReplacementPath = (
  isOrganisation: boolean,
  rental: getRental_rental,
  employeeId: string | undefined,
  organisationId?: string | undefined,
) =>
  composePath({
    isOrganisation,
    params: {
      employeeId: isOrganisation ? rental.employee?.id : employeeId,
      organisationId,
      rentalId: rental.id,
    },
    paths: {
      employee: GLOBAL_ROUTE_PATHS.employee.orderLostReplacement,
      organisation: employeeId
        ? GLOBAL_ROUTE_PATHS.organisation.employeeOrderLostReplacement
        : GLOBAL_ROUTE_PATHS.organisation.orderLostReplacement,
    },
  })

export const hasOrganisationDepots = (
  activeOrganisation: getOrganisation_organisation,
) =>
  !!activeOrganisation?.depots?.stocklistItemCount &&
  activeOrganisation?.depots?.stocklistItemCount > 0
