import {
  fallbackCountryUrlHash,
  pickUpRequestFormUrl,
} from 'Modals/CreateReturnLabelModal/CreateReturnLabelModal.constants'

import { getEmployee_employee_organisation } from 'Constants/graphql/queries/employee/__generated__/getEmployee'
import { getOrganisation_organisation } from 'Constants/graphql/queries/organisation/__generated__/getOrganisation'

export const getReturnLabelURL = (
  orgId: string | undefined,
  returnCaseId: string,
  country: string | undefined,
  fulfillmentProviderMapping:
    | getEmployee_employee_organisation['fulfillmentProviderMapping']
    | getOrganisation_organisation['fulfillmentProviderMapping'],
) => {
  if (!orgId || !country) {
    return undefined
  }

  // For Switzerland and UK we redirect to custom Pick Up Requests link (Google Form)
  if (['CH', 'GB'].includes(country)) {
    return pickUpRequestFormUrl
  }

  const fulfillmentProviderURLHash = fulfillmentProviderMapping?.find(
    fulfillmentProviderData => fulfillmentProviderData.country === country,
  )?.URLHash

  const fallbackURLHash = fallbackCountryUrlHash[country]

  const urlHash = fulfillmentProviderURLHash || fallbackURLHash

  if (!urlHash) {
    return undefined
  }

  return `https://www.dhl.de/retoure/gw/rpcustomerweb/OrderEntry.action?hash=${urlHash}&ShipmentReference=rcid${returnCaseId}_org${orgId}`
}
