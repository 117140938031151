import { gql } from '@apollo/client'

import { FRAGMENT_ORDER_FIELDS } from './GetOrders'

export const GET_ORDERS_AND_RETURNS = gql`
  query getOrdersAndReturns(
    $id: ID!
    $searchterm: String
    $filterByCaseType: [CaseItemType]
  ) {
    employee(id: $id) {
      id
      caseitems(
        searchterm: $searchterm
        orderBy: { field: id, order: DESC }
        filterByCaseType: $filterByCaseType
      ) {
        __typename
        totalCount
        pageInfo {
          __typename
          endCursor
          hasNextPage
        }
        caseItems {
          ...orderFields
        }
      }
      returnCases {
        __typename
        totalCount
        pageInfo {
          __typename
          endCursor
          hasNextPage
        }
        returnCases {
          id
          employee {
            id
            contact {
              id
              firstName
              lastName
            }
          }
          createdAt
          stocklistItem {
            id
            deviceDefinition {
              id
              codename
              imageURL
              manufacturer
              name
              productType
            }
          }
          status
        }
      }
    }
  }
  ${FRAGMENT_ORDER_FIELDS}
`
