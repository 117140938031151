import { useCallback, useMemo, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'

import { ReturnsPreviewColumn } from 'Modules/returns/components/ReturnsPreviewColumn/ReturnsPreviewColumn'
import { ReturnsTable } from 'Modules/returns/components/ReturnsTable/ReturnsTable'

import { Card } from 'Components/_theme'
import { ContentSidebar } from 'Components/ContentSidebar/ContentSidebar'
import { FaqCard } from 'Components/FaqCard/FaqCard'
import { ImpactTracker } from 'Components/ImpactTracker/ImpactTracker'
import { ListSearch } from 'Components/ListSearch/ListSearch'
import { ObserveMeOnceIAmMounted } from 'Components/ObserveMeOnceIAmMounted/ObserveMeOnceIAmMounted'
import { PageErrorState } from 'Components/PageErrorState/PageErrorState'
import { PageHeader } from 'Components/PageHeader/PageHeader'
import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'

import { useDefaultListTable, useSidebarVisibility } from 'Hooks'

import {
  getActiveFilters,
  getQueryObject,
  handleClearListFilter,
  toggleIntercomVisibility,
} from 'Utilities'

import {
  getReturnCases,
  getReturnCasesVariables,
} from 'Constants/graphql/queries/listing/__generated__/getReturnCases'
import { GET_RETURN_CASES } from 'Constants/graphql/queries/listing/GetReturnCases'
import { PortalSectionEnum } from 'Constants/portalSection'

import { ReportType } from 'Portal/__generated__/globalTypes'

export const ReturnsDefault = observer(() => {
  const [selectedEntityId, setSelectedEntityId] = useState('')
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null)
  const [isPreviewColumnOpen, setIsPreviewColumnOpen] = useState(false)
  const isSidebarOpen = isPreviewColumnOpen
  const { isVisible: isPreviewColumnVisible } = useSidebarVisibility(
    isPreviewColumnOpen,
    isSidebarOpen,
  )
  const { search: locationSearch } = useLocation()
  const parsedSearch = getQueryObject(locationSearch)

  const activeFilters = getActiveFilters(parsedSearch)

  const isOverdueFilterActive = useMemo(
    () =>
      Boolean(
        activeFilters.find(activeFilter => activeFilter.name === 'overdue'),
      ),
    [activeFilters],
  )

  const history = useHistory()

  const {
    data,
    error,
    hasNextPage,
    intersectionObserver,
    isLoading,
    totalCount,
  } = useDefaultListTable<getReturnCases, getReturnCasesVariables>({
    dataObjectPropertyName: 'returnCases',
    extraVariables: {
      isOverdue: isOverdueFilterActive,
    },
    organisationQuery: GET_RETURN_CASES,
  })

  const handleOpenPreviewColumn = useCallback(
    (id: string, rowIndex: number | null) => {
      toggleIntercomVisibility(false)

      setSelectedEntityId(id)
      setSelectedRowIndex(rowIndex)
      setIsPreviewColumnOpen(true)
    },
    [],
  )

  const handleClosePreviewColumn = useCallback(() => {
    toggleIntercomVisibility(true)

    setIsPreviewColumnOpen(false)
    setSelectedRowIndex(null)
  }, [])

  const handleClearFilter = useCallback(
    (filterName?: string) => {
      handleClearListFilter(parsedSearch, history, filterName)
    },
    [history, parsedSearch],
  )

  const shouldHideDownloadReportComponents = useMemo(
    () =>
      isOverdueFilterActive
        ? activeFilters.length !== 1 || totalCount === 0
        : undefined,
    [activeFilters.length, isOverdueFilterActive, totalCount],
  )

  if (error) {
    return <PageErrorState />
  }

  if (isLoading && !data) return <PageLoadingState />

  if (!data) return <PageErrorState />

  return (
    <Container className="pt-6" fluid>
      <div role="banner">
        <PageHeader portalSection={PortalSectionEnum.Returns} />
      </div>

      <Row>
        <Col xl={12} xxl={8}>
          <Card className="mb-4">
            <ListSearch
              activeFilters={activeFilters}
              downloadReportFilters={JSON.stringify({
                is_overdue: isOverdueFilterActive,
              })}
              downloadReportType={ReportType.return_case}
              hideDownloadReportComponents={shouldHideDownloadReportComponents}
              hideSearch
              onClearFilter={handleClearFilter}
              showFilters
              totalCount={totalCount}
            />

            <Row>
              <Col>
                <ReturnsTable
                  isLoading={isLoading}
                  isSearching={false}
                  onOpenPreviewColumn={handleOpenPreviewColumn}
                  returns={data}
                  selectedRowIndex={selectedRowIndex}
                />
              </Col>
            </Row>
          </Card>

          <Row className="p-1">
            <Col>
              {hasNextPage && !isLoading && (
                <ObserveMeOnceIAmMounted
                  intersectionObserver={intersectionObserver}
                />
              )}
            </Col>
          </Row>
        </Col>

        <ContentSidebar
          isSidebarOpen={isSidebarOpen}
          renderStickyColumnSideContent={
            <>
              <ImpactTracker />
              <FaqCard />
            </>
          }
        >
          <ReturnsPreviewColumn
            entityId={selectedEntityId}
            isVisible={isPreviewColumnVisible}
            onClosePreviewColumn={handleClosePreviewColumn}
          />
        </ContentSidebar>
      </Row>
    </Container>
  )
})
