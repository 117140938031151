import { observer } from 'mobx-react'

import { useShowInstantsearchList } from 'Hooks'

import { RentalsDefault } from './Default/Rentals.page'
import { RentalsInstantsearch } from './Instantsearch/Rentals.page'

export const Rentals = observer(() => {
  const showInstantSearchList = useShowInstantsearchList()

  if (showInstantSearchList) {
    return <RentalsInstantsearch />
  }

  return <RentalsDefault />
})
