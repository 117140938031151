import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons'

import { LinkButton } from 'Components/_theme'
import { CardActions } from 'Components/CardActions/CardActions'
import { CardDetailsList } from 'Components/CardDetailsList/CardDetailsList'
import { ContentCard } from 'Components/ContentCard/ContentCard'

import { E2E_EDIT_EMPLOYEE_BUTTON } from 'Constants/e2e'

import { EmployeePresentationCardProps } from './EmployeePresentationCard.types'

export const EmployeePresentationCard = (
  props: EmployeePresentationCardProps,
) => {
  const { name, detailsList, actions, canEditEmployee, onEditEmployee } = props

  const renderEditEmployeeButton = useMemo(() => {
    if (!canEditEmployee) {
      return null
    }

    return (
      <LinkButton
        className="btn-link d-inline-flex align-items-center p-0 fw-bold"
        data-e2e={E2E_EDIT_EMPLOYEE_BUTTON}
        iconLeft={faPenToSquare}
        onClick={onEditEmployee}
        size="small"
      >
        <FormattedMessage id="Edit_employee" />
      </LinkButton>
    )
  }, [canEditEmployee, onEditEmployee])

  return (
    <ContentCard
      className="EmployeePresentationCard"
      employeeName={name}
      rightContent={renderEditEmployeeButton}
    >
      <CardDetailsList listItems={detailsList} />

      <CardActions actions={actions} className="mt-4" type="employee" />
    </ContentCard>
  )
}
