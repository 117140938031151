import { Redirect, useParams } from 'react-router-dom'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

export const OrderCatchAll = () => {
  const { employeeId, organisationId } = useParams<{
    organisationId?: string
    employeeId?: string
  }>()

  const path = composePath({
    isOrganisation: !!organisationId,
    params: {
      employeeId,
      organisationId,
    },
    paths: {
      employee: GLOBAL_ROUTE_PATHS.employee.orderNew,
      organisation: employeeId
        ? GLOBAL_ROUTE_PATHS.organisation.employeeOrderNew
        : GLOBAL_ROUTE_PATHS.organisation.orderNew,
    },
  })

  return <Redirect to={path} />
}
