import { FC } from 'react'
import { Col, Row } from 'react-bootstrap'

import { Heading } from 'Components/_theme'
import { ContentCard } from 'Components/ContentCard/ContentCard'
import { ImageWithFallback } from 'Components/ImageWithFallback/ImageWithFallback'

import { E2E_PRESENTATION_CARD_HEADER } from 'Constants/e2e'

import { CardDetailsList } from '../CardDetailsList/CardDetailsList'
import { PresentationCardProps } from './PresentationCard.types'

export const PresentationCard: FC<PresentationCardProps> = props => {
  const {
    imageSource,
    imagePlaceholderType,
    header,
    listItems,
    title,
    rightContent,
    titleSuffix,
    children,
    cardClassName,
  } = props

  return (
    <ContentCard
      className={cardClassName}
      rightContent={rightContent}
      title={title}
      titleSuffix={titleSuffix}
    >
      <Row>
        {imageSource && (
          <Col
            className="d-flex justify-content-center align-items-center"
            lg={3}
            xs={12}
          >
            <ImageWithFallback
              alt={header as string}
              fluid
              placeholderType={imagePlaceholderType}
              src={imageSource}
            />
          </Col>
        )}

        <Col
          className="d-flex flex-column justify-content-center"
          lg={imageSource ? 9 : 12}
          xs={12}
        >
          <Heading
            as={4}
            className="mb-4"
            data-e2e={E2E_PRESENTATION_CARD_HEADER}
            size={3}
          >
            {header}
          </Heading>

          <CardDetailsList listItems={listItems} />

          {children}
        </Col>
      </Row>
    </ContentCard>
  )
}
