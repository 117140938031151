import { store } from 'Stores/index'

import { PortalSectionEnum } from 'Constants/portalSection'

import { PhonePlan, PhonePlans } from '../pages'
import { PhonePlansRoutePathsEnum } from './phonePlansRouteConfig.types'

export const PHONE_PLANS_ROUTES = [
  {
    component: PhonePlan,
    onRender: () => {
      const { portalSettingsStore } = store
      const { setCurrentPortalSection } = portalSettingsStore

      setCurrentPortalSection(PortalSectionEnum.PhonePlans)
    },
    path: `${PhonePlansRoutePathsEnum.PHONE_PLAN}`,
  },
  {
    component: PhonePlans,
    onRender: () => {
      const { portalSettingsStore } = store
      const { setCurrentPortalSection } = portalSettingsStore

      setCurrentPortalSection(PortalSectionEnum.PhonePlans)
    },
    path: `${PhonePlansRoutePathsEnum.PHONE_PLANS}`,
  },
]
