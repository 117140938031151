import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation } from '@apollo/client'
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons'
import { zodResolver } from '@hookform/resolvers/zod'
import { observer } from 'mobx-react'

import {
  Alert,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
} from 'Components/_theme'
import { Field } from 'Components/Field/Field'
import { FieldTypeEnum } from 'Components/Field/Field.types'
import { Form } from 'Components/Form/Form'
import { PageErrorState } from 'Components/PageErrorState/PageErrorState'
import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'

import { E2E_EMPLOYEE_OFFBOARDING_SUBMIT } from 'Constants/e2e'
import {
  mutationEmployeeOffboarding,
  mutationEmployeeOffboardingVariables,
} from 'Constants/graphql/mutations/__generated__/mutationEmployeeOffboarding'
import { MUTATION_EMPLOYEE_OFFBOARDING } from 'Constants/graphql/mutations/MutationEmployeeOffboarding'

import { iconEmployeeLeaves } from 'Icons/iconEmployeeLeaves'

import { EMPLOYEE_OFFBOARDING_DATEPICKER } from './EmployeeOffboarding.constants'
import { employeeOffboardingSchema } from './EmployeeOffboarding.schema'
import {
  EmployeeOffboardingProps,
  EmployeeOffboardingSchema,
} from './EmployeeOffboarding.types'

export const EmployeeOffboarding = observer(
  (props: EmployeeOffboardingProps) => {
    const { employee, handleSubmit: handleClose } = props
    const [hasMutationError, setHasMutationError] = useState(false)
    const [isMutationLoading, setIsMutationLoading] = useState(false)

    const intl = useIntl()

    const formMethods = useForm<EmployeeOffboardingSchema>({
      defaultValues: {
        exitDate: employee?.exitDate
          ? new Date(employee.exitDate).toISOString().split('T')[0]
          : new Date().toISOString().split('T')[0],
      },
      resolver: zodResolver(employeeOffboardingSchema),
    })

    const [employeeOffboarding, { loading: isLoading, error: isError }] =
      useMutation<
        mutationEmployeeOffboarding,
        mutationEmployeeOffboardingVariables
      >(MUTATION_EMPLOYEE_OFFBOARDING, {
        onCompleted: success => {
          setIsMutationLoading(false)

          if (!success) {
            setHasMutationError(true)

            return
          }

          setHasMutationError(false)
          handleClose()
        },
      })

    const handleSubmit = formMethods.handleSubmit(data => {
      setIsMutationLoading(true)

      employeeOffboarding({
        variables: {
          exitDate: data.exitDate
            ? new Date(data.exitDate)
            : '0001-01-01T00:00:00.000Z',
          id: employee.id,
        },
      })
    })

    if (isError) {
      return <PageErrorState />
    }

    if (isLoading) {
      return <PageLoadingState />
    }

    return (
      <Card>
        <CardHeader color="red" icon={iconEmployeeLeaves}>
          <FormattedMessage id="Employee_leaves" />
        </CardHeader>

        <CardBody>
          <FormProvider {...formMethods}>
            <Form noValidate onSubmit={handleSubmit} role="form">
              {hasMutationError && (
                <Alert className="mt-4" iconLeft={faCircleInfo} variant="info">
                  <FormattedMessage id="EmployeeOffboarding_error" />
                </Alert>
              )}

              <Field
                aria-label={intl.formatMessage({ id: 'ChangeExitDate' })}
                autoComplete="off"
                containerClassName="mb-6"
                data-testid={EMPLOYEE_OFFBOARDING_DATEPICKER}
                datepickerProps={{
                  min: new Date().toISOString().split('T')[0],
                  showClearButton: true,
                }}
                name="exitDate"
                type={FieldTypeEnum.datepicker}
              />

              <ButtonGroup
                leftButton={{
                  onClick: handleClose,
                  text: intl.formatMessage({ id: 'Cancel' }),
                }}
                rightButton={{
                  'data-e2e': E2E_EMPLOYEE_OFFBOARDING_SUBMIT,
                  isLoading: isMutationLoading,
                  text: intl.formatMessage({ id: 'Submit' }),
                  type: 'submit',
                }}
              />
            </Form>
          </FormProvider>
        </CardBody>
      </Card>
    )
  },
)
