import { Col, Container, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { Card } from 'Components/_theme'
import { FaqCard } from 'Components/FaqCard/FaqCard'
import { ImpactTracker } from 'Components/ImpactTracker/ImpactTracker'
import { ListSearch } from 'Components/ListSearch/ListSearch'
import { PageErrorState } from 'Components/PageErrorState/PageErrorState'
import { PageHeader } from 'Components/PageHeader/PageHeader'
import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'

import {
  getDepotDetails,
  getDepotDetailsVariables,
} from 'Constants/graphql/queries/detail/__generated__/getDepotDetails'
import { GET_DEPOT_DETAILS } from 'Constants/graphql/queries/detail/GetDepotDetails'
import { PortalSectionEnum } from 'Constants/portalSection'

import { DepotTable } from './DepotTable'

export const Depot = () => {
  const { depotId, organisationId } = useParams<{
    depotId: string
    organisationId: string
  }>()

  const { loading: isLoading, data } = useQuery<
    getDepotDetails,
    getDepotDetailsVariables
  >(GET_DEPOT_DETAILS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      id: depotId,
      organisationId,
    },
  })

  if (isLoading) {
    return <PageLoadingState />
  }

  if (!data) {
    return <PageErrorState />
  }

  const { depot } = data

  return (
    <Container className="pt-6" fluid>
      <div role="banner">
        <PageHeader
          portalSection={PortalSectionEnum.DepotDetails}
          subheading={depot.locationName}
        />
      </div>

      <Row>
        <Col xl={12} xxl={8}>
          <Card className="mb-4">
            <ListSearch
              buttonVisible={false}
              hideSearch
              totalCount={depot.stocklistItems?.totalCount ?? 0}
            />

            <Row>
              <Col>
                <DepotTable depot={depot} />
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xl={12} xxl={4}>
          <div className="sticky-column">
            <ImpactTracker />
            <FaqCard />
          </div>
        </Col>
      </Row>
    </Container>
  )
}
