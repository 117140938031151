import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { uniqueId } from 'lodash-es'

import { CardDetailsList } from 'Components/CardDetailsList/CardDetailsList'
import { CardDetailsListItem } from 'Components/CardDetailsList/CardDetailsList.types'
import { ContentCard } from 'Components/ContentCard/ContentCard'
import { FormatDate } from 'Components/FormatDate/FormatDate'

import { useStore } from 'Stores/index'

import { useIsOrganisation } from 'Hooks'

import { dashOrSpaceToUnderscore, getFullContactName } from 'Utilities'

import { CaseItemType } from 'Portal/__generated__/globalTypes'

import { OrderIntercomButton } from '../OrderIntercomButton/OrderIntercomButton'
import { OrderDetailsCardProps } from './OrderDetailsCard.types'

export const OrderDetailsCard = (props: OrderDetailsCardProps) => {
  const { caseItem, title } = props
  const intl = useIntl()

  const isOrganisation = useIsOrganisation()
  const { portalConfigStore } = useStore()
  const { portalConfig } = portalConfigStore

  const filteredEmployeesList = useMemo(
    () =>
      caseItem.data.requestedPhonePlan?.employees?.filter(
        employeeName => employeeName,
      ),
    [caseItem],
  )

  const employeesLabel = useMemo(
    () =>
      filteredEmployeesList?.length === 1
        ? 'PhonePlanListContent_header__employee'
        : 'Employees',
    [filteredEmployeesList],
  )

  const employeesValue = useMemo(
    () =>
      !filteredEmployeesList?.length ? undefined : (
        <ul className="CardDetailsList-employees-list">
          {filteredEmployeesList.map(employeeName => (
            <li key={uniqueId()}>{employeeName}</li>
          ))}
        </ul>
      ),
    [filteredEmployeesList],
  )

  const isLostDevice =
    dashOrSpaceToUnderscore(caseItem.caseType) === CaseItemType.lost_device &&
    typeof caseItem?.data.isReplacementRequested === 'boolean'

  const generateDetailsCardListItems = useMemo(
    (): CardDetailsListItem[] => [
      {
        label: 'RequestDetails_request_id',
        value: caseItem.id,
      },
      ...(isOrganisation && portalConfig?.poRcnInOrderSummary
        ? [
            {
              label: 'RequestDetails_po_rcn',
              value: caseItem.poRcn,
            },
          ]
        : []),

      ...(isOrganisation && portalConfig?.customClientReferenceInOrderSummary
        ? [
            {
              label: 'RequestDetails_custom_client_reference',
              value: caseItem?.customClientReference,
            },
          ]
        : []),

      ...(isOrganisation && portalConfig?.billingIdentifierInOrderSummary
        ? [
            {
              label: 'RequestDetails_billing_identifier',
              value: caseItem?.billingIdentifier,
            },
          ]
        : []),

      {
        label: 'RequestDetails_date',
        value: <FormatDate value={caseItem.created} />,
      },
      {
        label: 'RequestDetails_customerLabel',
        value: caseItem.customerLabel,
      },
      {
        label: employeesLabel,
        value: employeesValue,
      },
      {
        label: 'Phone_plan',
        value: caseItem.data.requestedPhonePlan?.name,
      },
      {
        label: 'Quantity',
        value: caseItem.data.requestedPhonePlan?.number?.toString(),
      },
      {
        label: 'Created_by',
        value: getFullContactName(caseItem.user, false),
      },
      ...(isLostDevice
        ? [
            {
              label: 'Order_replacement_requested',
              value: intl.formatMessage({
                id: caseItem?.data.isReplacementRequested ? 'Yes' : 'No',
              }),
            },
          ]
        : []),
    ],
    [
      caseItem,
      employeesLabel,
      employeesValue,
      isOrganisation,
      portalConfig,
      isLostDevice,
      intl,
    ],
  )

  return (
    <ContentCard className="OrderDetailsCard" title={title}>
      <CardDetailsList
        className="mb-4"
        listItems={generateDetailsCardListItems}
      />

      <OrderIntercomButton caseItemId={caseItem.id} />
    </ContentCard>
  )
}
