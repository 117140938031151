import { useIntl } from 'react-intl'
import { faBuildingColumns, faTag } from '@fortawesome/pro-solid-svg-icons'
import { observer } from 'mobx-react'

import { FilterColumn } from 'Components/FilterColumn/FilterColumn'

import {
  E2E_EMPLOYEE_FILTER_ACTIVE,
  E2E_EMPLOYEE_FILTER_COST_CENTER,
  E2E_EMPLOYEE_FILTER_EXIT_DATE,
  E2E_EMPLOYEE_FILTER_EXIT_DATE_TOOLBAR,
  E2E_EMPLOYEE_FILTER_RENT_CAT_TIER,
} from 'Constants/e2e'

import { MultidatepickerFilter } from 'Algolia/filters/MultidatepickerFilter/MultidatepickerFilter'
import { MultiselectFilter } from 'Algolia/filters/MultiselectFilter/MultiselectFilter'
import { ToggleFilter } from 'Algolia/filters/ToggleFilter/ToggleFilter'

import { employeeExitDateCustomToolbarConfig } from './EmployeesFilterColumn.constants'
import { EmployeesFilterColumnProps } from './EmployeesFilterColumn.types'

export const EmployeesFilterColumn = observer(
  (props: EmployeesFilterColumnProps) => {
    const { onCloseFilterColumn, isVisible } = props
    const intl = useIntl()

    return (
      <FilterColumn isVisible={isVisible} onClose={onCloseFilterColumn}>
        {/* THESE MAKE NO SENSE AS A MULTISELECT AND ARE TO REMOVE OR REMAKE */}
        {/* <MultiselectFilter
          filterProps={{ attribute: 'employee.full_name' }}
          label={intl.formatMessage({
            id: 'typesenseAttribute_employee_full_name',
          })}
          selectProps={{
            iconProps: {
              icon: faTrademark,
            },
            instanceId: E2E_EMPLOYEE_FILTER_FULL_NAME,
          }}
        />

        <MultiselectFilter
          filterProps={{ attribute: 'employee.email' }}
          label={intl.formatMessage({
            id: 'typesenseAttribute_employee_email',
          })}
          selectProps={{
            iconProps: {
              icon: faTrademark,
            },
            instanceId: E2E_EMPLOYEE_FILTER_EMAIL,
          }}
        />

        <MultiselectFilter
          filterProps={{ attribute: 'employee.phone_number' }}
          label={intl.formatMessage({
            id: 'typesenseAttribute_employee_phone_number',
          })}
          selectProps={{
            iconProps: {
              icon: faTrademark,
            },
            instanceId: E2E_EMPLOYEE_FILTER_PHONE_NUMBER,
          }}
        /> */}

        <ToggleFilter
          checkboxButtonGroupProps={{
            e2eSelector: E2E_EMPLOYEE_FILTER_ACTIVE,
            name: 'hasActiveRental',
          }}
          filterProps={{ attribute: 'employee.rental_has_active' }}
          label={intl.formatMessage({
            id: 'typesenseAttribute_employee_has_active_rental',
          })}
        />

        <MultidatepickerFilter
          autoComplete="off"
          filterProps={{ attribute: 'employee.exit_date' }}
          label={intl.formatMessage({
            id: 'typesenseAttribute_employee_exit_date',
          })}
          multidatePickerProps={{
            customToolbarConfig: employeeExitDateCustomToolbarConfig,
            e2eSelector: E2E_EMPLOYEE_FILTER_EXIT_DATE,
            toolbarE2eSelector: E2E_EMPLOYEE_FILTER_EXIT_DATE_TOOLBAR,
          }}
        />

        <MultiselectFilter
          filterProps={{ attribute: 'employee.rent_category_tier' }}
          label={intl.formatMessage({
            id: 'typesenseAttribute_employee_rent_category_tier',
          })}
          selectProps={{
            iconProps: {
              icon: faTag,
            },
            instanceId: E2E_EMPLOYEE_FILTER_RENT_CAT_TIER,
          }}
        />

        {/* TODO Was not implemented in typesense after all, to enable when ready
        <MultiselectFilter
          filterProps={{ attribute: 'employee.office_combined_str' }}
          label={intl.formatMessage({
            id: 'typesenseAttribute_employee_office',
          })}
          selectProps={{
            iconProps: {
              icon: faBuildings,
            },
            instanceId: E2E_EMPLOYEE_FILTER_OFFICE,
          }}
        /> */}

        <MultiselectFilter
          filterProps={{ attribute: 'employee.cost_center_name' }}
          label={intl.formatMessage({
            id: 'typesenseAttribute_employee_cost_center_name',
          })}
          selectProps={{
            iconProps: {
              icon: faBuildingColumns,
            },
            instanceId: E2E_EMPLOYEE_FILTER_COST_CENTER,
          }}
        />

        {/* THIS MAKES NO SENSE AS A MULTISELECT AND ARE TO REMOVE OR REMAKE */}
        {/* <MultiselectFilter
          filterProps={{ attribute: 'employee.external_reference' }}
          label={intl.formatMessage({
            id: 'typesenseAttribute_employee_external_reference',
          })}
          selectProps={{
            iconProps: {
              icon: faTrademark,
            },
            instanceId: E2E_EMPLOYEE_FILTER_EXTERNAL_REF,
          }}
        /> */}
      </FilterColumn>
    )
  },
)
