import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { clsx } from 'clsx'

import { Icon } from 'Components/Icon/Icon'
import { ImageWithFallback } from 'Components/ImageWithFallback/ImageWithFallback'
import { OrderStatusBadge } from 'Components/OrderStatusBadge/OrderStatusBadge'

import { E2E_ENTITY_LINK } from 'Constants/e2e'

import { iconCancelDevice } from 'Icons/iconCancelDevice'
import { iconCancelPhonePlan } from 'Icons/iconCancelPhonePlan'

import { EntityProps } from './EntityListCard.types'

export const Entity = (props: EntityProps) => {
  const {
    link,
    title,
    content,
    icon,
    imageSource,
    imagePlaceholderType,
    status,
  } = props

  const iconClassName = useMemo(() => {
    switch (icon) {
      case iconCancelDevice:
      case iconCancelPhonePlan:
        return 'Entity-link-danger'
      default:
        return 'Entity-link'
    }
  }, [icon])

  const renderContent = useMemo(
    () => (
      <>
        {icon && <Icon className="Entity-icon me-4" icon={icon} size={38} />}

        {imageSource && (
          <ImageWithFallback
            alt={title}
            className="Entity-image me-4"
            fluid
            placeholderType={imagePlaceholderType}
            src={imageSource}
          />
        )}

        <div className="d-flex flex-column overflow-auto text-start fw-normal">
          <span className="Entity-title mt-0 mb-1 text-truncate">{title}</span>

          {content}
        </div>

        <div className="d-flex align-items-center ms-auto fw-nomal">
          {status && (
            <OrderStatusBadge className="d-inline-block me-4" status={status} />
          )}

          {link && (
            <Icon className="Entity-chevron" icon={faChevronRight} size={12} />
          )}
        </div>
      </>
    ),
    [content, icon, imagePlaceholderType, imageSource, link, status, title],
  )

  if (link) {
    return (
      <li className="Entity p-0">
        <Link
          className={clsx(iconClassName, 'd-flex align-items-center')}
          data-e2e={`${E2E_ENTITY_LINK}`}
          title={title}
          to={link}
        >
          {renderContent}
        </Link>
      </li>
    )
  }

  return (
    <li className="Entity Entity-no-link p-0">
      <div className={clsx(iconClassName, 'd-flex align-items-center')}>
        {renderContent}
      </div>
    </li>
  )
}
