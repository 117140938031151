import { BadgeColor, BadgeVariant } from 'Components/_theme/Badge/Badge.types'

import { CaseItemStatus } from 'Portal/__generated__/globalTypes'

export const ORDER_STATUS_TRANSLATION_IDS: Record<CaseItemStatus, string> = {
  [CaseItemStatus.done]: 'Completed',
  [CaseItemStatus.in_progress]: 'Processing',
  [CaseItemStatus.new]: 'In_progress',
  [CaseItemStatus.pending]: 'Pending',
}

export const ORDER_STATUS_BADGE_COLOR_MAP: Record<CaseItemStatus, BadgeColor> =
  {
    [CaseItemStatus.done]: 'green',
    [CaseItemStatus.in_progress]: 'light',
    [CaseItemStatus.new]: 'blue',
    [CaseItemStatus.pending]: 'teal',
  }

export const ORDER_STATUS_BADGE_VARIANT_MAP: Record<
  CaseItemStatus,
  BadgeVariant
> = {
  [CaseItemStatus.done]: 'secondary',
  [CaseItemStatus.in_progress]: 'primary',
  [CaseItemStatus.new]: 'secondary',
  [CaseItemStatus.pending]: 'outline',
}
