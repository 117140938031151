import { CaseItemType } from 'Portal/__generated__/globalTypes'

/**
 * Please use `getCaseItemTypeTranslationId` instead.
 */
export const CASE_ITEM_TYPE_TRANSLATION_IDS: Record<CaseItemType, string> = {
  [CaseItemType.assign_device]: '',
  [CaseItemType.assign_device_to_employee]: '',
  [CaseItemType.assign_stocklist_items_to_depot]: '',
  [CaseItemType.assign_stocklist_items_to_depot_bucket]: '',
  [CaseItemType.bulk_balb]: '',
  [CaseItemType.bulk_hr_import]: '',
  [CaseItemType.bulk_new_device]: '',
  [CaseItemType.bulk_order_new_device]: '',
  [CaseItemType.bulk_phone_plan]: '',
  [CaseItemType.bulk_sales]: '',
  [CaseItemType.bulk_sourcing]: '',
  [CaseItemType.bulk_stocklist_condition_update]: '',
  [CaseItemType.bulk_user]: '',
  [CaseItemType.bulk_user_bucket]: '',
  [CaseItemType.bundle_early_upgrade_device]: '',
  [CaseItemType.bundle_new_device]: '',
  [CaseItemType.bundle_rental_complete_upgrade_device]: '',
  [CaseItemType.bundle_upgrade_device]: '',
  [CaseItemType.buy_and_leaseback]: 'Buy_leaseback',
  [CaseItemType.buy_and_leaseback_bucket]: '',
  [CaseItemType.cancel_device]: 'Cancel_device',
  [CaseItemType.cancel_phone_plan]: 'Cancel_phone_plan',
  [CaseItemType.change_device]: '',
  [CaseItemType.change_phone_plan]: 'Change_phone_plan',
  [CaseItemType.create_sale_for_stocklist_item]: '',
  [CaseItemType.depot_device_cleaning]: '',
  [CaseItemType.device_shipping]: '',
  [CaseItemType.early_upgrade_device]: 'Early_upgrade_device',
  [CaseItemType.employee_lost_device]: 'Device_lost_stolen', // deprecated
  [CaseItemType.exchange_device]: 'Exchange_device',
  [CaseItemType.general]: 'New_question',
  [CaseItemType.general_bucket]: '',
  [CaseItemType.general_purchases]: 'New_question',
  [CaseItemType.lost_device]: 'Device_lost_stolen',
  [CaseItemType.new_device]: 'New_device',
  [CaseItemType.new_device_bucket]: 'New_device_bundle',
  [CaseItemType.create_employee]: 'Create_employee',
  [CaseItemType.new_employee]: 'New_employee',
  [CaseItemType.new_employee_bucket]: '',
  [CaseItemType.new_phone_plan]: 'New_phone_plan',
  [CaseItemType.new_phone_plan_bucket]: '',
  [CaseItemType.new_tariff_bucket]: '',
  [CaseItemType.order_devices_for_depot]: '',
  [CaseItemType.purchase_device]: 'Purchase_device',
  [CaseItemType.rental_complete_upgrade_device]: 'Upgrade_device',
  [CaseItemType.repair_device]: 'Replace_broken_device',
  [CaseItemType.repair_device_bucket]: '',
  [CaseItemType.repair_upgrade]: 'Repair_upgrade',
  [CaseItemType.request]: '',
  [CaseItemType.request_bucket]: '',
  [CaseItemType.return_of_device]: 'Return_device',
  [CaseItemType.set_stocklist_items_status]: '',
  [CaseItemType.set_stocklist_items_status_bucket]: '',
  [CaseItemType.shipping]: '',
  [CaseItemType.shipping_bucket]: '',
  [CaseItemType.sourcing]: '',
  [CaseItemType.sourcing_bucket]: '',
  [CaseItemType.sourcing_request]: '',
  [CaseItemType.sourcing_request_bucket]: '',
  [CaseItemType.upgrade_device]: 'Upgrade_device',
  [CaseItemType.upgrade_device_bucket]: '',
  [CaseItemType.vending_machine_device]: 'New_device',
  [CaseItemType.bundle_repair_upgrade]: 'Repair_upgrade',
}

export const CASE_ITEMS_TO_SHOW: string[] = [
  CaseItemType.cancel_device,
  CaseItemType.cancel_phone_plan,
  CaseItemType.change_device,
  CaseItemType.change_phone_plan,
  CaseItemType.create_employee,
  CaseItemType.early_upgrade_device,
  CaseItemType.exchange_device,
  CaseItemType.general,
  CaseItemType.general_purchases,
  CaseItemType.lost_device,
  CaseItemType.new_device,
  CaseItemType.new_phone_plan,
  CaseItemType.order_devices_for_depot,
  CaseItemType.rental_complete_upgrade_device,
  CaseItemType.repair_device,
  CaseItemType.request,
  CaseItemType.return_of_device,
  CaseItemType.shipping,
  CaseItemType.sourcing_request,
  CaseItemType.sourcing_request_bucket,
  CaseItemType.upgrade_device,
  CaseItemType.purchase_device,
  CaseItemType.vending_machine_device,
  CaseItemType.repair_upgrade,
]
