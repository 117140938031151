import { useEffect } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation } from '@apollo/client'
import { zodResolver } from '@hookform/resolvers/zod'

import { ButtonGroup, Card, CardBody, CardHeader } from 'Components/_theme'
import { AlertWithError } from 'Components/AlertWithError/AlertWithError'
import { DepotsSelect } from 'Components/DepotsSelect/DepotsSelect'
import { Form } from 'Components/Form/Form'
import { FormOptionalMessage } from 'Components/FormOptionalMessage/FormOptionalMessage'
import { ReplacementDeviceSelect } from 'Components/ReplacementDeviceSelect/ReplacementDeviceSelect'

import { getSelectValue } from 'Utilities'

import {
  E2E_COMBOBOX,
  E2E_DEPOTS_DROPDOWN,
  E2E_EXCHANGE_DEVICE_MODAL_SUBMIT,
} from 'Constants/e2e'
import { EMPTY_SELECT_OPTION } from 'Constants/form'
import {
  mutationExchangeDevice,
  mutationExchangeDeviceVariables,
} from 'Constants/graphql/mutations/__generated__/mutationExchangeDevice'
import { MUTATION_EXCHANGE_DEVICE } from 'Constants/graphql/mutations/MutationExchangeDevice'

import { iconExchangeDevice } from 'Icons/iconExchangeDevice'

import { exchangeDeviceModalFormSchema } from './ExchangeDeviceModal.schema'
import {
  ExchangeDeviceModalFormSchema,
  ExchangeDeviceModalProps,
} from './ExchangeDeviceModal.types'
import { exchangeDeviceFormDefaultValues } from './ExchangeDeviceModal.values'

export const ExchangeDeviceModal = (props: ExchangeDeviceModalProps) => {
  const { handleSubmit, onClose, rental } = props

  const intl = useIntl()

  const formMethods = useForm<ExchangeDeviceModalFormSchema>({
    defaultValues: exchangeDeviceFormDefaultValues,
    resolver: zodResolver(exchangeDeviceModalFormSchema),
  })

  const { control, setValue } = formMethods
  const watchDepot = useWatch({
    control,
    name: 'depot',
  })

  const [createExchangeDeviceCaseItem, { loading, error }] = useMutation<
    mutationExchangeDevice,
    mutationExchangeDeviceVariables
  >(MUTATION_EXCHANGE_DEVICE, {
    onCompleted: () => {
      handleSubmit()
    },
  })

  const handleFormSubmit = formMethods.handleSubmit(data => {
    createExchangeDeviceCaseItem({
      variables: {
        input: {
          currentRentalID: rental.id,
          message: data.message,
          replacementStocklistItemID: getSelectValue(data.depotStocklistItem),
        },
      },
    })
  })

  useEffect(() => {
    // reset depot device if selected depot is changed
    setValue('depotStocklistItem', EMPTY_SELECT_OPTION)
  }, [setValue, watchDepot?.value])

  return (
    <Card>
      <CardHeader icon={iconExchangeDevice}>
        <FormattedMessage id="Exchange_device" />
      </CardHeader>

      <CardBody>
        {error && <AlertWithError error={error} />}

        <FormProvider {...formMethods}>
          <Form id="EXCHANGE_DEVICE_MODAL_FORM" onSubmit={handleFormSubmit}>
            <div className="h6 mb-4">
              <FormattedMessage id="Original device" />
            </div>

            <div className="mb-4">
              {rental.stocklistItem?.deviceDefinition?.name}&nbsp;
              {rental.stocklistItem?.imei || ''}
            </div>

            <DepotsSelect
              containerClassName="mb-4"
              e2eSelector={E2E_DEPOTS_DROPDOWN}
              label="Depots"
              markAsRequired
              name="depot"
              rentalId={rental.id}
              shouldTranslateLabel
            />

            <ReplacementDeviceSelect
              containerClassName="mb-4"
              depotId={watchDepot?.value ?? ''}
              e2eSelector={E2E_COMBOBOX}
              label="Replacement_device"
              markAsRequired
              name="depotStocklistItem"
              rentalId={rental.id}
              shouldTranslateLabel
            />

            <FormOptionalMessage />

            <ButtonGroup
              leftButton={{
                onClick: onClose,
                text: intl.formatMessage({ id: 'Cancel' }),
              }}
              rightButton={{
                'data-e2e': E2E_EXCHANGE_DEVICE_MODAL_SUBMIT,
                disabled: !!error || loading,
                isLoading: loading,
                text: intl.formatMessage({
                  id: 'Exchange',
                }),
                type: 'submit',
              }}
            />
          </Form>
        </FormProvider>
      </CardBody>
    </Card>
  )
}
