import { getOrganisation_organisation } from 'Constants/graphql/queries/organisation/__generated__/getOrganisation'

export const shouldShowPhonePlanStatisticCard = (
  isTelekomTheme: boolean,
  organisation: getOrganisation_organisation | null,
) => {
  if (isTelekomTheme) {
    return false
  }

  if (organisation?.currency === 'USD') {
    return false
  }

  return true
}
