import { ForwardedRef, forwardRef } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { clsx } from 'clsx'

import { Button } from 'Components/_theme/Button/Button'

import { LinkButtonProps } from './LinkButton.types'

export const LinkButton = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  LinkButtonProps
>((props, ref) => {
  const {
    asExternalLink,
    children,
    className,
    disabled,
    iconLeft,
    iconRight,
    isLoading,
    onClick,
    size,
    to,
    variant = 'dark',
    ...restProps
  } = props

  const classNames = clsx(
    !restProps?.asIcon && 'LinkButton',
    variant !== 'dark' && `LinkButton-${variant}`,
    disabled && 'LinkButton-disabled',
    className,
  )

  const buttonProps = {
    'aria-label': restProps['aria-label'],
    'data-e2e': restProps['data-e2e'],
    'data-testid': restProps['data-testid'],
    disabled,
    iconLeft,
    iconRight,
    isLoading,
    size,
  }

  if (asExternalLink) {
    return (
      <a
        ref={ref as ForwardedRef<HTMLAnchorElement>}
        className={clsx(disabled && 'LinkButton-disabled')}
        href={to as string}
        {...restProps}
      >
        <Button className={classNames} tabIndex={-1} {...buttonProps}>
          {children}
        </Button>
      </a>
    )
  }

  if (onClick) {
    return (
      <Button
        ref={ref}
        className={classNames}
        onClick={onClick}
        {...buttonProps}
      >
        {children}
      </Button>
    )
  }

  return (
    <Link
      ref={ref as ForwardedRef<HTMLAnchorElement>}
      className={classNames}
      component={Button}
      to={to as LinkProps['to']}
      {...buttonProps}
      {...restProps}
    >
      {children}
    </Link>
  )
})
