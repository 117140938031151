import { FormattedMessage, useIntl } from 'react-intl'

import { ButtonGroup, CardBody } from 'Components/_theme'

import { countryISOCodes } from 'Constants/constants'
import { E2E_LOST_STOLEN_ORDER_ID } from 'Constants/e2e'

import { ModalConfirmationProps } from './ModalConfirmation.types'

export const ModalConfirmation = (props: ModalConfirmationProps) => {
  const {
    headingTranslationId,
    requestIdRef,
    shippingFormData,
    closeButtonE2ESelector,
    onClose,
  } = props
  const intl = useIntl()

  const isUnitedStatesAddressType =
    shippingFormData?.country?.value === countryISOCodes.US

  return (
    <CardBody>
      <h4 aria-live="polite" className="text-info" role="status">
        <FormattedMessage id={headingTranslationId} />
      </h4>

      <div className="d-flex flex-row mt-6">
        <div className="w-50">
          {shippingFormData && (
            <>
              <h6>
                <FormattedMessage id="Shipping_address" />
              </h6>

              <p>
                {shippingFormData.name}
                <br />
                {shippingFormData.nameAddition}
                <br />
                {isUnitedStatesAddressType
                  ? shippingFormData.usAddress
                  : `${shippingFormData.street} / ${shippingFormData.streetNumber}`}
                <br />
                {shippingFormData.zipcode} / {shippingFormData.city}
                <br />
                {shippingFormData.country?.label}
              </p>
            </>
          )}

          {requestIdRef?.current && (
            <>
              <h6 className="mt-6">
                <FormattedMessage id="NewOrderConfirmation_request_id" />
              </h6>

              <p data-e2e={E2E_LOST_STOLEN_ORDER_ID}>{requestIdRef.current}</p>
            </>
          )}
        </div>
      </div>

      <ButtonGroup
        rightButton={{
          'data-e2e': closeButtonE2ESelector,
          onClick: onClose,
          text: intl.formatMessage({ id: 'Done' }),
        }}
      />
    </CardBody>
  )
}
