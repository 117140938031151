import { useCallback, useRef, useState } from 'react'
import { Overlay, Tooltip } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { faCopy } from '@fortawesome/pro-regular-svg-icons'

import { LinkButton } from 'Components/_theme'
import { Icon } from 'Components/Icon/Icon'

import { CopyClipboardButtonProps } from './CopyClipboardButton.types'

export const CopyClipboardButton = (props: CopyClipboardButtonProps) => {
  const { value } = props
  const [show, setShow] = useState(false)
  const targetRef = useRef(null)
  const intl = useIntl()

  const handleCopyButtonClick = useCallback(() => {
    setShow(true)

    navigator.clipboard.writeText(value)

    setTimeout(() => {
      setShow(false)
    }, 1000)
  }, [value])

  return (
    <>
      <LinkButton
        ref={targetRef}
        aria-label={intl.formatMessage({ id: 'Copy' })}
        className="px-2 py-0 lh-1"
        onClick={handleCopyButtonClick}
        variant="black"
      >
        <Icon icon={faCopy} size={20} />
      </LinkButton>

      <Overlay
        placement="bottom"
        show={show}
        target={targetRef.current}
        transition
      >
        {props => <Tooltip {...props}>Copied to clipboard</Tooltip>}
      </Overlay>
    </>
  )
}
