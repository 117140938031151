import { z } from 'zod'

import { FORM_OPTIONAL_MESSAGE_MAX_CHARACTERS } from 'Components/FormOptionalMessage/FormOptionalMessage.constants'

import { zSelect } from 'Lib/zod'

import { FORM_MESSAGES_MAP } from 'Constants/formMessages'

export const newPhonePlanModalFormSchema = z.object({
  employees: z.array(z.object({ name: z.string() })).optional(),
  message: z
    .string()
    .max(FORM_OPTIONAL_MESSAGE_MAX_CHARACTERS, FORM_MESSAGES_MAP.MAX_LENGTH),
  phonePlan: zSelect.refine(arg => !!arg?.value, FORM_MESSAGES_MAP.REQUIRED),
  quantity: z.string().min(1).max(100),
})
