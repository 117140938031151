import { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { faClose, faMobileAlt } from '@fortawesome/pro-solid-svg-icons'
import { useMediaQuery } from '@react-hookz/web'
import { clsx } from 'clsx'

import { Heading, LinkButton } from 'Components/_theme'
import { CopyClipboardButton } from 'Components/CopyClipboardButton/CopyClipboardButton'
import { Icon } from 'Components/Icon/Icon'
import { ImageWithFallback } from 'Components/ImageWithFallback/ImageWithFallback'
import { OrderStatusBadge } from 'Components/OrderStatusBadge/OrderStatusBadge'
import { ReturnBlockedBadge } from 'Components/ReturnBlockedBadge/ReturnBlockedBadge'
import { ReturnStatusBadge } from 'Components/ReturnStatusBadge/ReturnStatusBadge'

import { PORTAL_BREAKPOINTS } from 'Constants/constants'
import { E2E_PREVIEW_COLUMN_NAME } from 'Constants/e2e'

import { DeviceTypeEnum } from 'Types/deviceTypes'

import { PreviewColumnHeaderProps } from './PreviewColumn.types'

export const PreviewColumnHeader = (props: PreviewColumnHeaderProps) => {
  const {
    badge,
    image,
    isReturnBlocked,
    name,
    onClosePreviewColumn,
    productType,
    caseItemStatus,
    returnCaseStatus,
    returnCaseSubstatus,
    title,
    renderPreviewColumnHeaderSub,
  } = props
  const isTablet = useMediaQuery(`(min-width: ${PORTAL_BREAKPOINTS.LG})`)
  const intl = useIntl()

  useEffect(() => {
    if (typeof isTablet === 'undefined') {
      return
    }

    if (!isTablet) {
      onClosePreviewColumn()
    }
  }, [isTablet, onClosePreviewColumn])

  return (
    <div className="PreviewColumn-header-title">
      <div className="PreviewColumn-header d-flex g-0">
        {(image || productType) && (
          <div className="PreviewColumn-header-image-wrapper d-flex justify-content-center align-items-center">
            <ImageWithFallback
              alt={name}
              placeholderType={productType as DeviceTypeEnum}
              src={image || ''}
              width={50}
            />
          </div>
        )}

        <div
          className={clsx(
            image ? 'flex-column' : 'w-100 align-items-center flex-row py-6',
            'ms-2 d-flex justify-content-center position-relative',
          )}
        >
          {caseItemStatus && (
            <div className={clsx(image ? 'mb-2' : 'mb-1 me-2')}>
              <OrderStatusBadge status={caseItemStatus} />
            </div>
          )}

          {returnCaseStatus && (
            <div className={clsx(image ? 'mb-2' : 'mb-1 me-2')}>
              {isReturnBlocked ? (
                <ReturnBlockedBadge
                  className="me-2"
                  substatus={returnCaseSubstatus}
                />
              ) : (
                <ReturnStatusBadge status={returnCaseStatus} />
              )}
            </div>
          )}

          {badge && (
            <div className={clsx(image ? 'mb-2' : 'mb-1 me-2')}>{badge}</div>
          )}

          <div className="d-flex align-items-center">
            <Heading as={4} data-e2e={E2E_PREVIEW_COLUMN_NAME} size={2}>
              {name}
            </Heading>

            <span className="PreviewColumn-header-copy-button ms-2">
              <CopyClipboardButton value={name} />
            </span>
          </div>

          {renderPreviewColumnHeaderSub}
        </div>

        <div className="PreviewColumn-close-button">
          <LinkButton
            aria-label={intl.formatMessage({ id: 'Close' })}
            className="p-0"
            onClick={onClosePreviewColumn}
          >
            <Icon icon={faClose} size={24} />
          </LinkButton>
        </div>
      </div>

      {title && (
        <div className="PreviewColumn-title">
          <div className="d-flex align-items-center">
            <Icon className="pe-4" icon={faMobileAlt} size={20} />

            <span className="pt-1">
              <FormattedMessage
                id="PreviewColumn_title_preview"
                values={{
                  columnType: title,
                }}
              />
            </span>
          </div>
        </div>
      )}
    </div>
  )
}
