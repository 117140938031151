export type E2E_ORDER_TYPE =
  | 'new'
  | 'upgrade'
  | 'early-upgrade'
  | 'repair-upgrade'
  | 'lost-replacement'

export enum OrderIdsEnum {
  REWRITES_ORDER = 99,
}
