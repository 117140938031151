import {
  faCheck,
  faClose,
  faTruckClock,
} from '@fortawesome/pro-solid-svg-icons'

import { ShipmentTrackingCardType } from 'Components/ShipmentTrackingCard/ShipmentTrackingCard.types'

import {
  TrackingStatusEnum,
  TrackingStatusModel,
} from 'Types/trackingStatus/trackingStatus.types'

export const TRACKING_STATUS_MODEL: Record<
  ShipmentTrackingCardType,
  TrackingStatusModel
> = {
  order: {
    [TrackingStatusEnum.transit]: {
      icon: faTruckClock,
      translationId: {
        delivered: 'Delivery_status_Transit',
        transit: 'Delivery_status_Transit',
      },
    },
    [TrackingStatusEnum.delivered]: {
      icon: faCheck,
      translationId: {
        delivered: 'Delivery_status_Delivered',
        transit: 'Delivery_status_Delivery',
      },
    },
    [TrackingStatusEnum.failure]: {
      icon: faClose,
      translationId: {
        delivered: 'Delivery_status_Failure',
        transit: 'Delivery_status_Failure',
      },
    },
  },
  return: {
    [TrackingStatusEnum.transit]: {
      icon: faTruckClock,
      translationId: {
        delivered: 'Delivery_status_return_Transit',
        transit: 'Delivery_status_return_Transit',
      },
    },
    [TrackingStatusEnum.delivered]: {
      icon: faCheck,
      translationId: {
        delivered: 'Delivery_status_return_Delivered',
        transit: 'Delivery_status_Delivery',
      },
    },
    [TrackingStatusEnum.failure]: {
      icon: faClose,
      translationId: {
        delivered: 'Delivery_status_return_Failure',
        transit: 'Delivery_status_return_Failure',
      },
    },
  },
}
