import { FormattedMessage, useIntl } from 'react-intl'

import { Heading } from 'Components/_theme'

import decor from 'Assets/images/contact/question_header_decor.svg'

import { DecorHeaderProps } from './DecorHeader.types'

export const DecorHeader = (props: DecorHeaderProps) => {
  const { title } = props
  const intl = useIntl()

  return (
    <div className="DecorHeader">
      <div className="DecorHeader-title-content">
        <div className="DecorHeader-title">
          <Heading as={1} size={3}>
            <FormattedMessage id={title} />
          </Heading>
        </div>
      </div>

      <div className="DecorHeader-decor">
        <img
          alt={intl.formatMessage({ id: 'ContactDescription_header' })}
          src={decor}
        />
      </div>
    </div>
  )
}
