import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { cloneDeep, isEmpty } from 'lodash-es'
import { ParsedQuery } from 'query-string'

import { GS_SEARCH_RESULTS_PER_PAGE } from 'Modules/searchResults/constants/searchResults.contants'
import { filterSearchResultsByModel } from 'Modules/searchResults/utils/searchResults.utils'

import { useActiveUser, useUserAuthToken } from 'Hooks'

import { fetchFromApiUsingFullPath, getQueryObject } from 'Utilities'

import { SearchResults } from 'Types/globalSearch/searchResults.types'

import { getApiConfig } from 'Portal/src/config/api.config'

import { SearchResultsResponse } from './SearchResults.types'

function fetchResults(
  page: number,
  authToken: string,
  activeOrganisationId: string | undefined,
  parsedSearch: ParsedQuery<string> | null,
): Promise<SearchResultsResponse> {
  const requestBody = JSON.stringify({
    active_orgId: activeOrganisationId ?? 0,
    model: parsedSearch?.searchFilter,
    page,
    per_page: GS_SEARCH_RESULTS_PER_PAGE,
    query: parsedSearch?.searchTerm,
  })

  return new Promise(resolve => {
    const { globalSearch } = getApiConfig()

    fetchFromApiUsingFullPath(globalSearch.url, {
      body: requestBody,
      headers: { authorization: authToken },
      method: 'POST',
    }).then((response: SearchResults) => {
      const totalPagesArray = response.result.reduce(
        (result: number[], currentItem) => {
          result.push(currentItem.total_pages)

          return result
        },
        [],
      )
      const maxTotalPages = Math.max(...totalPagesArray)

      resolve({ data: response, hasNextPage: page < maxTotalPages })
    })
  })
}

export function useSearchResults() {
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [items, setItems] = useState<SearchResults>({} as SearchResults)
  const [hasNextPage, setHasNextPage] = useState(true)
  const [error, setError] = useState<Error>()
  const authToken = useUserAuthToken()
  const { activeOrganisation } = useActiveUser()
  const { search: locationSearch } = useLocation()
  const parsedSearch = getQueryObject(locationSearch)

  async function loadMore(isInitialLoad?: boolean) {
    if (isLoading) {
      return
    }

    setIsLoading(true)

    try {
      const { data, hasNextPage: newHasNextPage } = await fetchResults(
        isInitialLoad ? 1 : currentPage,
        authToken,
        activeOrganisation?.id,
        parsedSearch,
      )

      const filteredData = filterSearchResultsByModel(data)

      setItems(prevState => {
        if (isEmpty(prevState) || isInitialLoad) {
          return filteredData
        }

        const newItems = cloneDeep(prevState)

        newItems.result.forEach(newItem => {
          filteredData.result.forEach(filteredItem => {
            if (newItem.model !== filteredItem.model) {
              return
            }

            if (!newItem.hits || !filteredItem.hits) {
              return
            }

            newItem.hits = [...newItem.hits, ...filteredItem.hits]
          })
        })

        return newItems
      })

      setHasNextPage(newHasNextPage)
      setCurrentPage(prevState => prevState + 1)
    } catch (error) {
      setError(error as Error)
    } finally {
      setIsLoading(false)
    }
  }

  return { error, hasNextPage, isLoading, items, loadMore }
}
