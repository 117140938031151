export const getLineColor = (progress: number) => {
  if (progress === 0) {
    return '#343A40'
  }

  if (progress > 0) {
    return '#197B78'
  }

  return '#E3224E'
}
