import { gql } from '@apollo/client'

const FRAGMENT_PHONE_PLANS_FIELDS = gql`
  fragment phonePlansFields on PhonePlan {
    id
    simcardNumber
    phoneNumber
    startAt
    rental {
      employee {
        contact {
          firstName
          lastName
          __typename
        }
        __typename
      }
      __typename
    }
    phonePlanTariff {
      name
      provider
      logo
      __typename
    }
    __typename
  }
`

export const GET_EMPLOYEE_PHONE_PLANS = gql`
  query getEmployeePhonePlans(
    $id: ID!
    $first: Int!
    $after: Int!
    $searchterm: String
  ) {
    employee(id: $id) {
      id
      phonePlans(
        pagination: { after: $after, first: $first }
        searchterm: $searchterm
      ) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          __typename
        }
        phonePlans {
          ...phonePlansFields
        }
        __typename
      }
    }
  }
  ${FRAGMENT_PHONE_PLANS_FIELDS}
`

export const GET_ORGANISATION_PHONE_PLANS = gql`
  query getOrganisationPhonePlans(
    $id: ID!
    $first: Int!
    $after: Int!
    $searchterm: String
  ) {
    organisation(id: $id) {
      id
      phonePlans(
        pagination: { after: $after, first: $first }
        searchterm: $searchterm
      ) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          __typename
        }
        phonePlans {
          ...phonePlansFields
        }
        __typename
      }
    }
  }
  ${FRAGMENT_PHONE_PLANS_FIELDS}
`
