import { ZoneContextManager } from '@opentelemetry/context-zone'
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http'
import { registerInstrumentations } from '@opentelemetry/instrumentation'
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch'
import { Resource } from '@opentelemetry/resources'
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base'
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web'
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions'

import { NodeEnvEnum } from 'Types/env.types'

import { OpenTelemetryProviderProps } from './OpenTelemetryProvider.types'

if (
  process.env.NODE_ENV !== NodeEnvEnum.TEST
) {
  const fetchInstrumentation = new FetchInstrumentation({
    ignoreUrls: [window.GRAPHQL_API_URL],
  })

  // Registering instrumentations
  registerInstrumentations({
    instrumentations: [fetchInstrumentation],
  })

  const resource = Resource.default().merge(
    new Resource({
      [SemanticResourceAttributes.SERVICE_NAME]: 'portal',
      [SemanticResourceAttributes.SERVICE_VERSION]: '0.1.0',
    }),
  )

  const provider = new WebTracerProvider({
    resource,
  })

  // const consoleExporter = new ConsoleSpanExporter()
  // const processor = new SimpleSpanProcessor(consoleExporter)

  // provider.addSpanProcessor(processor)

    provider.addSpanProcessor(
      new BatchSpanProcessor(
        new OTLPTraceExporter(
          {
            url: window.OPTL_API_URL,
          }
        ),
      ),
    )

  const zoneContextManager = new ZoneContextManager()

  provider.register({
    contextManager: zoneContextManager,
  })
}

export const OpenTelemetryProvider = (props: OpenTelemetryProviderProps) => {
  const { children } = props

  return <>{children}</>
}
