import { twoSignificantDigits } from '../price/price.util'

const EUR_SYMBOL = '€'

export const formatCurrency = (number: number): string => {
  if (number === 0) {
    return `${EUR_SYMBOL}0.00`
  }

  return `${EUR_SYMBOL}${twoSignificantDigits(number / 100).toFixed(2)}`
}
