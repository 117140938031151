import type * as React from 'react'

/**
 * Helper method which applies necessary properties on non-interactive dom elements.
 * @example
 *
 * const handler = (event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>, ...optionalArgs) => {
 *    // code
 * }
 *
 * <div {...buttonize(handler, 'optional arg')}>click me</div>
 */
export function buttonize<T extends HTMLElement>(
  handlerFunction: (
    event: React.MouseEvent<T> | React.KeyboardEvent<T>,
    ...args: any[]
  ) => void,
  ...args: any[]
) {
  return {
    onClick: (event: React.MouseEvent<T>) => {
      handlerFunction(event, ...args)
    },
    onKeyDown: (event: React.KeyboardEvent<T>) => {
      if (
        ['keydown', 'keypress'].includes(event.type) &&
        ['Enter', ' '].includes(event.key)
      ) {
        handlerFunction(event, ...args)
      }
    },
    role: 'button',
    tabIndex: 0,
  }
}
