import { useMemo } from 'react'
import { Alert, Col, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { observer } from 'mobx-react'

import { LinkButton, PrimaryButton } from 'Components/_theme'
import { OrderSelectionCard } from 'Components/OrderSelectionCard/OrderSelectionCard'

import { useStore } from 'Stores/index'

import {
  E2E_NEW_ORDER_ACCESSORY_NEXT,
  E2E_ORDER_ALERT_ACCESSORIES_LIMIT,
} from 'Constants/e2e'

import { OrderStickyFooter } from '../OrderStickyFooter/OrderStickyFooter'
import { AccessoriesChooserProps } from './AccessoriesChooser.types'

export const AccessoriesChooser = observer((props: AccessoriesChooserProps) => {
  const {
    accessories,
    chosenAccessories,
    limitationRules,
    onBackButtonClick,
    onSkipButtonClick,
    onNextButtonClick,
    toggleAccessorySelection,
  } = props
  const { orderStore } = useStore()
  const { isProductOffer } = orderStore

  const renderLimitationInfo = useMemo(() => {
    if (!limitationRules) {
      return null
    }

    const {
      other: { initial, current },
    } = limitationRules

    const accessoriesSelected = initial - current
    const accessoriesTotal = accessories?.length ?? 0

    if (
      (accessoriesSelected < accessoriesTotal && current !== 0) ||
      accessoriesSelected === accessoriesTotal
    ) {
      return null
    }

    if (
      isProductOffer &&
      accessories.some(accessory => !accessory.isCreateSeparateRental)
    ) {
      return null
    }

    return (
      <Row>
        <Col>
          <Alert
            className="alert mt-6 mb-0"
            data-e2e={E2E_ORDER_ALERT_ACCESSORIES_LIMIT}
            variant="info"
          >
            {initial === 1 ? (
              <FormattedMessage id="AccessoriesChooser_limitation_info_one" />
            ) : (
              <FormattedMessage
                id="AccessoriesChooser_limitation_info"
                values={{
                  amount: initial,
                }}
              />
            )}
          </Alert>
        </Col>
      </Row>
    )
  }, [accessories, isProductOffer, limitationRules])

  return (
    <>
      {renderLimitationInfo}

      <div className="mt-6 mb-1">
        <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-4">
          {accessories?.map(accessory => (
            <Col
              key={accessory.deviceDefinition.id}
              lg={4}
              sm={6}
              xl={3}
              xs={12}
              // TODO if we have impactTracers on accesorries
              // lg={device.deviceDefinition.impactFactors ? 8 : 4}
              // sm={device.deviceDefinition.impactFactors ? 12 : 6}
              // xl={device.deviceDefinition.impactFactors ? 6 : 3}
              // xs={12}
              // xxl={
              //   device.deviceDefinition.impactFactors
              //     ? xxlColSizeWithTracker
              //     : 3
              // }
            >
              <OrderSelectionCard
                chooseItem={toggleAccessorySelection}
                isSelected={chosenAccessories.has(accessory.id)}
                item={accessory}
                limitationRules={limitationRules}
              />
            </Col>
          ))}
        </Row>
      </div>

      <OrderStickyFooter
        handlePreviousAction={onBackButtonClick}
        renderNextButtonElement={() => (
          <>
            <LinkButton id="skip" onClick={onSkipButtonClick}>
              <FormattedMessage id="AccessoriesChooser_skip" />
            </LinkButton>

            <PrimaryButton
              className="ms-4"
              data-e2e={E2E_NEW_ORDER_ACCESSORY_NEXT}
              disabled={!chosenAccessories.size}
              id="next"
              onClick={onNextButtonClick}
            >
              <FormattedMessage id="Next" />
            </PrimaryButton>
          </>
        )}
      />
    </>
  )
})
