import { gql } from '@apollo/client'

export const GET_AVAILABLE_DEVICES = gql`
  query getAvailablePhones($depotID: ID, $rentalID: ID!) {
    rental(id: $rentalID) {
      replacements(depotID: $depotID) {
        stocklistItems {
          id
          depot {
            locationName
          }
          deviceDefinition {
            name
            id
          }
          imei
          rental {
            id
            deviceID
          }
        }
      }
    }
  }
`

export const GET_OWNED_DEVICES = gql`
  query getOwnedDevices($employeeID: ID!) {
    employee(id: $employeeID) {
      id
      rentals {
        rentals {
          id
          deviceID
          stocklistItem {
            id
            imei
            deviceDefinition {
              name
            }
          }
        }
      }
    }
  }
`

export const GET_DEPOT_DEVICES = gql`
  query getDepotDevices($id: ID!, $searchTerm: String) {
    depot(id: $id) {
      id
      locationName
      stocklistItems(searchterm: $searchTerm) {
        stocklistItems {
          id
          imei
          deviceDefinition {
            name
          }
        }
      }
    }
  }
`

const FRAGMENT_RENTALS_FIELDS = gql`
  fragment rentalsFields on Rental {
    __typename
    id
    isEarlyUpgradeEligible
    isUpgradeEligible
    isUpgradePending
    employee {
      __typename
      contact {
        __typename
        firstName
        lastName
      }
    }
    rentalProlonging {
      __typename
      validFrom
    }
    startAt
    stocklistItem {
      __typename
      id
      imei
      serialNumber
      deviceDefinition {
        __typename
        name
        imageURL
        productType
      }
    }
  }
`

export const GET_ORGANISATION_DEVICES = gql`
  query getOrganisationDevices(
    $id: ID!
    $first: Int!
    $after: Int!
    $searchterm: String
  ) {
    organisation(id: $id) {
      __typename
      id
      rentals(
        pagination: { after: $after, first: $first }
        searchterm: $searchterm
      ) {
        __typename
        totalCount
        pageInfo {
          __typename
          endCursor
          hasNextPage
        }
        rentals {
          actions(isOrganisationView: true) {
            showEarlyUpgradeAction
            showStandardUpgradeAction
            showDeviceProlongingAction
          }
          ...rentalsFields
        }
      }
    }
  }
  ${FRAGMENT_RENTALS_FIELDS}
`

export const GET_EMPLOYEE_DEVICES = gql`
  query getEmployeeDevices(
    $id: ID!
    $first: Int!
    $after: Int!
    $searchterm: String
  ) {
    employee(id: $id) {
      __typename
      id
      rentals(
        pagination: { after: $after, first: $first }
        searchterm: $searchterm
      ) {
        __typename
        totalCount
        pageInfo {
          __typename
          endCursor
          hasNextPage
        }
        rentals {
          actions(isOrganisationView: false) {
            showEarlyUpgradeAction
            showStandardUpgradeAction
            showDeviceProlongingAction
          }
          ...rentalsFields
        }
      }
    }
  }
  ${FRAGMENT_RENTALS_FIELDS}
`
