import { useCallback, useMemo } from 'react'
import { useHitsPerPage } from 'react-instantsearch'
import { useIntl } from 'react-intl'
import { SingleValue } from 'react-select'
import { HitsPerPageConnectorParamsItem } from 'instantsearch.js/es/connectors/hits-per-page/connectHitsPerPage'
import { observer } from 'mobx-react'

import { Select } from 'Components/Select/Select'

import { useStore } from 'Stores/index'

import { scrollToTop } from 'Utilities'

import { E2E_LIST_PAGE_SIZE_SELECT } from 'Constants/e2e'
import { PAGE_SIZE, PAGE_SIZE_KEY, PAGE_SIZES } from 'Constants/pagination'

export const PageSizeSelect = observer(() => {
  const { portalContentStore } = useStore()
  const { setPaginationState } = portalContentStore
  const intl = useIntl()

  const pageSizeOptions = useMemo<HitsPerPageConnectorParamsItem[]>(
    () =>
      PAGE_SIZES.map(size => ({
        default: size === PAGE_SIZE,
        label: intl.formatMessage({ id: 'PageSizeSelect_label' }, { size }),
        value: size,
      })),
    [intl],
  )

  const { items, refine } = useHitsPerPage({
    items: pageSizeOptions,
  })

  const handlePageSizeChange = useCallback(
    (newValue: SingleValue<HitsPerPageConnectorParamsItem>) => {
      const pageSize = newValue?.value ?? PAGE_SIZE

      refine(pageSize)

      localStorage.setItem(PAGE_SIZE_KEY, pageSize.toString())

      setPaginationState({ pageSize })

      scrollToTop()
    },
    [refine, setPaginationState],
  )

  const defaultValue: HitsPerPageConnectorParamsItem = {
    label: intl.formatMessage(
      { id: 'PageSizeSelect_label' },
      { size: PAGE_SIZE },
    ),
    value: PAGE_SIZE,
  }

  return (
    <div className="PageSizeSelect">
      <Select
        aria-label={intl.formatMessage(
          { id: 'PageSizeSelect_label' },
          { size: PAGE_SIZE },
        )}
        defaultValue={defaultValue}
        instanceId={E2E_LIST_PAGE_SIZE_SELECT}
        isClearable={false}
        isSearchable={false}
        onChange={handlePageSizeChange}
        options={items}
      />
    </div>
  )
})
