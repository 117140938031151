import { Route, RouteWithChildren } from './Router.types'

export const mapRouteWithChildrenToRoute = ({
  component,
  path,
  children,
}: RouteWithChildren): Route[] => [
  {
    component,
    path,
  },
  ...children.map(child => ({
    ...child,
    path: `${path}/${child.path}`,
  })),
]

export const setRouteTitle = (title: string) => {
  document.title = `Everphone portal ${title.toLowerCase()}`
}
