import { DeviceTypeEnum } from 'Types/deviceTypes'
import {
  ImagePlaceholderTypes,
  ImagePlaceholderTypesEnum,
} from 'Types/imagePlaceholders.type'

import newOrderFlowPlaceholder from 'Assets/images/everphonebox.png'

export const PLACEHOLDER_PHONE_PLAN =
  'https://storage.googleapis.com/everphone-assets/portal-assets/placeholders/simcard.svg'
export const PLACEHOLDER_REQUESTS =
  'https://storage.googleapis.com/everphone-assets/portal-assets/placeholders/messages.svg'
export const PLACEHOLDER_SMARTPHONE =
  'https://storage.googleapis.com/everphone-assets/portal-assets/placeholders/mobile.svg'
export const PLACEHOLDER_TABLET =
  'https://storage.googleapis.com/everphone-assets/portal-assets/placeholders/tablet.svg'
export const PLACEHOLDER_LAPTOP =
  'https://storage.googleapis.com/everphone-assets/portal-assets/placeholders/laptop.svg'
export const PLACEHOLDER_ACCESSORY =
  'https://storage.googleapis.com/everphone-assets/portal-assets/placeholders/accessory.svg'
export const PLACEHOLDER_NEW_ORDER_FLOW = newOrderFlowPlaceholder

export const IMAGE_PLACEHOLDER_MAP: Record<ImagePlaceholderTypes, string> = {
  [ImagePlaceholderTypesEnum.phonePlan]: PLACEHOLDER_PHONE_PLAN,
  [ImagePlaceholderTypesEnum.requests]: PLACEHOLDER_REQUESTS,
  [ImagePlaceholderTypesEnum.newOrderFlow]: PLACEHOLDER_NEW_ORDER_FLOW,
  [DeviceTypeEnum.headset]: PLACEHOLDER_ACCESSORY,
  [DeviceTypeEnum.keyboard]: PLACEHOLDER_ACCESSORY,
  [DeviceTypeEnum.laptop]: PLACEHOLDER_LAPTOP,
  [DeviceTypeEnum.monitor]: PLACEHOLDER_ACCESSORY,
  [DeviceTypeEnum.mouse]: PLACEHOLDER_ACCESSORY,
  [DeviceTypeEnum.other]: PLACEHOLDER_ACCESSORY,
  [DeviceTypeEnum.printer]: PLACEHOLDER_ACCESSORY,
  [DeviceTypeEnum.smartphone]: PLACEHOLDER_SMARTPHONE,
  [DeviceTypeEnum.tablet]: PLACEHOLDER_TABLET,
}
