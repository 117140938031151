import { clsx } from 'clsx'

import { Icon } from 'Components/Icon/Icon'

import { IMPACT_TRACKER_CHART_ITEM } from './ImpactTracker.contants'
import { ImpactTrackerChartItemProps } from './ImpactTracker.types'

export const ImpactTrackerChartItem = (props: ImpactTrackerChartItemProps) => {
  const {
    className,
    defaultIconColor,
    icon,
    iconText,
    title,
    totalValue,
    values,
    valueFormatter,
    unit,
  } = props

  const valuesSum: number = values.reduce(
    (counter, currentItemValue) => counter + currentItemValue.value,
    0,
  )

  // Rendering values in descending order so lower the value = higher in cascade
  const sortedValues = values.sort((a, b) => b.value - a.value)

  // ImpactTrackerChartItem renders all values, even not selected (not active) for the bar animations (see bar width changes)
  return (
    <div
      className={clsx('ImpactTrackerChart-item d-flex', className)}
      data-testid={IMPACT_TRACKER_CHART_ITEM}
    >
      <div
        className={clsx(
          // if there is no defaultIconColor, use color from the lowest value
          defaultIconColor || sortedValues[sortedValues.length - 1].color,
          `ImpactTrackerChart-item--icon d-flex flex-column justify-content-center align-items-center text-center`,
        )}
      >
        <Icon icon={icon} size={16} />

        {iconText}
      </div>

      <div className="ms-4 w-100">
        <div className="d-flex justify-content-between">
          <h6 className="m-0">{title}</h6>

          <p className="text-grey m-0">
            {valueFormatter ? valueFormatter(valuesSum) : valuesSum}/
            {valueFormatter ? valueFormatter(totalValue) : totalValue} {unit}
          </p>
        </div>

        <div className="position-relative w-100 ImpactTrackerChart-item--fill mt-1">
          {sortedValues.map(({ active, value, color }) => (
            <div
              key={color}
              className={clsx(
                'bar position-absolute',
                active && 'active',
                color,
              )}
              style={{ width: active ? `${(value / totalValue) * 100}%` : 0 }}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
