import { useCallback, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { ApolloError, useMutation } from '@apollo/client'
import { observer } from 'mobx-react'

import { OrderWrapper } from 'Modules/order/components/OrderWrapper/OrderWrapper'
import {
  AccessoriesStep,
  AddressStep,
  DeviceRentalStep,
  DeviceStep,
  SummaryStep,
} from 'Modules/order/components/Steps'
import {
  useOfferOrderMutation,
  useRedirectToConfirmationPage,
} from 'Modules/order/hooks'
import { OrderFlowTypesEnum } from 'Modules/order/types/orderFlow.types'
import { showErrorModal } from 'Modules/order/utils/errorModal.utils'
import { notEmpty } from 'Modules/order/utils/notEmpty.utils'
import {
  getOfferOrderMutationVariables,
  getRentCategoryOrderMutationVariables,
} from 'Modules/order/utils/order.utils'

import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'
import { Stepper, StepperStep } from 'Components/Stepper'

import { useStore } from 'Stores/index'

import { useActiveUser, useIsOrganisation, useModal } from 'Hooks'

import {
  mutationEarlyUpgradeDevice,
  mutationEarlyUpgradeDeviceVariables,
} from 'Constants/graphql/mutations/__generated__/mutationEarlyUpgradeDevice'
import { MUTATION_EARLY_UPGRADE_DEVICE } from 'Constants/graphql/mutations/MutationEarlyUpgradeDevice'

import { OrderType } from 'Portal/__generated__/globalTypes'
import { OrderDeviceResponseVariant } from 'Portal/src/mappers/orderDeviceMapper/orderDevice.types'

export const OrderEarlyUpgrade = observer(() => {
  const intl = useIntl()

  const { orderStore, userStore, rentalStore, navigationStore } = useStore()
  const {
    setMutationError,
    setOrderedDevices,
    chosenAccessories,
    chosenShippingAddress,
    chosenDevice,
    shouldSkipAccessories,
    orderDevices,
    officeAddress,
    orderEmployeeName,
    orderExternalId,
    phonePlanDetails,
  } = orderStore
  const { user } = userStore
  const { fetchRentalDetails, rentalDetails } = rentalStore
  const { mainNavigationConfig } = navigationStore
  const history = useHistory()
  const { setModal, hideModal } = useModal()
  const isOrganisation = useIsOrganisation()

  const { activeOrganisation, activeEmployee } = useActiveUser()
  const { employeeId = '', rentalId } = useParams<{
    employeeId?: string
    rentalId: string
  }>()

  const activeEmployeeId = employeeId || activeEmployee?.id.toString()

  const { redirectToConfirmationPage } =
    useRedirectToConfirmationPage(activeEmployeeId)

  const triggerErrorModal = useCallback(
    (error: ApolloError) => {
      showErrorModal(
        () => {
          hideModal()

          history.push(mainNavigationConfig.url)
        },
        setModal,
        error,
        hideModal,
      )
    },
    [hideModal, history, mainNavigationConfig.url, setModal],
  )

  const { handleOfferOrderMutation, isOfferOrderLoading } =
    useOfferOrderMutation({
      handleOnCompletedOrder: () => {
        redirectToConfirmationPage()
      },
      handleOnErrorOrder: triggerErrorModal,
      variables: getOfferOrderMutationVariables({
        activeEmployee,
        activeEmployeeId,
        activeOrganisation,
        chosenAccessories,
        chosenDevice,
        chosenShippingAddress,
        officeAddress,
        orderExternalId,
        orderType: OrderType.earlyUpgrade,
        phonePlanDetails,
        rentalDetails,
        user,
      }),
    })

  const [createEarlyUpgradeDeviceCaseItem, { loading: isMutationLoading }] =
    useMutation<
      mutationEarlyUpgradeDevice,
      mutationEarlyUpgradeDeviceVariables
    >(MUTATION_EARLY_UPGRADE_DEVICE, {
      errorPolicy: 'all',
      onCompleted: data => {
        const metadata = {
          orderedDevice: chosenDevice?.deviceDefinition.name,
          quantity: chosenDevice?.quantity,
          userType: isOrganisation ? 'Admin' : 'Employee',
        }

        Intercom('trackEvent', 'early-upgrade', metadata)

        if (data.earlyUpgradeDevice.caseItem.subCaseItems) {
          const filteredDevices =
            data.earlyUpgradeDevice.caseItem.subCaseItems?.filter(notEmpty)

          setOrderedDevices(filteredDevices)
        } else {
          setOrderedDevices(Number(data.earlyUpgradeDevice.caseItem.id))
        }

        setMutationError(false)

        redirectToConfirmationPage()
      },
      onError: error => {
        setMutationError(true)

        triggerErrorModal(error)
      },
      variables: getRentCategoryOrderMutationVariables({
        activeEmployee,
        activeEmployeeId,
        activeOrganisation,
        chosenAccessories,
        chosenDevice,
        chosenShippingAddress,
        officeAddress,
        orderExternalId,
        orderType: OrderType.earlyUpgrade,
        rentalDetails,
        user,
      }) as mutationEarlyUpgradeDeviceVariables,
    })

  useEffect(() => {
    if (rentalDetails?.rental.id === rentalId) {
      return
    }

    fetchRentalDetails({
      isOrganisationView: isOrganisation,
      rentalID: rentalId,
    })
  }, [rentalId, fetchRentalDetails, isOrganisation, rentalDetails?.rental.id])

  const orderNewStepsConfig = useMemo(() => {
    const headingSufix =
      !!activeOrganisation && employeeId && orderEmployeeName
        ? `${intl.formatMessage({ id: 'for' })} ${orderEmployeeName}`
        : undefined

    const baseSteps: StepperStep[] = [
      {
        headingLabel: 'Early_Upgrade',
        headingSufix,
        renderStepContent: ({ handleNextStep }) => (
          <DeviceRentalStep handleNextStep={handleNextStep} isEarlyUpgrade />
        ),
      },
      {
        headingLabel: 'NewOrder_choose_device_header',
        headingSufix,
        renderStepContent: ({ handleNextStep, handlePreviousStep }) => (
          <DeviceStep
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
          />
        ),
        subHeadingLabel: 'NewOrder_choose_device_subheader',
      },
      {
        headingLabel: 'NewOrder_shipping_address_header',
        headingSufix,
        renderStepContent: ({ handleNextStep, handlePreviousStep }) => (
          <AddressStep
            activeEmployeeId={activeEmployeeId}
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
          />
        ),
        subHeadingLabel: 'NewOrder_shipping_address_subheader',
      },
      {
        headingLabel: 'NewOrder_order_summary_header',
        headingSufix,
        renderStepContent: ({ handleSetCurrentStep, handlePreviousStep }) => (
          <SummaryStep
            activeEmployeeId={activeEmployeeId}
            handleOrderSubmit={() => {
              if (
                orderDevices?.variant === OrderDeviceResponseVariant.CATALOG
              ) {
                handleOfferOrderMutation()
              } else {
                createEarlyUpgradeDeviceCaseItem()
              }
            }}
            handlePreviousStep={handlePreviousStep}
            isEarlyUpgrade
            isSubmitLoading={isMutationLoading || isOfferOrderLoading}
            setCurrentOrderStep={handleSetCurrentStep}
          />
        ),
        subHeadingLabel: 'NewOrder_order_summary_subheader',
      },
    ]

    const accessoriesStep: StepperStep = {
      headingLabel: 'NewOrder_choose_accessories_header',
      headingSufix,
      renderStepContent: ({ handleNextStep, handlePreviousStep }) => (
        <AccessoriesStep
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
        />
      ),
      subHeadingLabel: 'NewOrder_choose_accessories_subheader',
    }

    if (shouldSkipAccessories) {
      return baseSteps
    }

    baseSteps.splice(2, 0, accessoriesStep)

    return baseSteps
  }, [
    activeEmployeeId,
    activeOrganisation,
    createEarlyUpgradeDeviceCaseItem,
    employeeId,
    handleOfferOrderMutation,
    intl,
    isMutationLoading,
    isOfferOrderLoading,
    orderDevices?.variant,
    orderEmployeeName,
    shouldSkipAccessories,
  ])

  if (!rentalDetails) {
    return <PageLoadingState />
  }

  return (
    <OrderWrapper
      employeeId={activeEmployeeId}
      orderFlowType={OrderFlowTypesEnum.EARLY_UPGRADE}
      renderOrderContent={() => <Stepper steps={orderNewStepsConfig} />}
    />
  )
})
