import { DateObject } from 'react-multi-date-picker'

type ParseDateToFilterTimestampArgs = {
  dateObject: DateObject | null
  time?: { hours: number; minutes: number; seconds: number }
}

export const parseDateToFilterTimestamp = ({
  dateObject,
  time,
}: ParseDateToFilterTimestampArgs) =>
  dateObject
    ?.setHour(time?.hours ?? 0)
    ?.setMinute(time?.minutes ?? 0)
    ?.setSecond(time?.seconds ?? 0)
    ?.setMillisecond(0)
    ?.toUnix()
