import { dashOrSpaceToUnderscore } from 'Utilities'

import {
  getRental_rental,
  getRental_rental_caseItems_caseItems,
} from 'Constants/graphql/queries/detail/__generated__/getRental'

import { CaseItemStatus, CaseItemType } from 'Portal/__generated__/globalTypes'

export const getOpenCaseItemId = (
  rental: getRental_rental,
  caseItemTypes: CaseItemType[],
): string | null => {
  let caseItem: getRental_rental_caseItems_caseItems | undefined

  const findCaseItem = (caseItemType: CaseItemType) => {
    const result = rental.caseItems?.caseItems?.find(
      caseItem =>
        dashOrSpaceToUnderscore(caseItem.caseType) === caseItemType &&
        caseItem.status !== CaseItemStatus.done,
    )

    if (result) {
      caseItem = result
    }
  }

  caseItemTypes.forEach(caseItemType => findCaseItem(caseItemType))

  if (!caseItem) {
    return null
  }

  return caseItem.id
}
