import { FormattedMessage, useIntl } from 'react-intl'

import { Card, CardBody, Heading, PrimaryButton, Text } from 'Components/_theme'

import errorBG from 'Assets/images/new-order-error.svg'

import { OrderSummaryErrorModalProps } from './OrderSummary.types'

export const OrderSummaryErrorModal = (props: OrderSummaryErrorModalProps) => {
  const { onConfirm, customHeader, customSubheader, customButtonText } = props

  const intl = useIntl()

  return (
    <Card className="text-center">
      <CardBody>
        <img
          alt={intl.formatMessage({ id: 'NewOrderSummary_error_header' })}
          className="Order-error-modal-image img-fluid"
          src={errorBG}
        />

        <Heading as={4} className="mt-9 mb-2" size={2}>
          <FormattedMessage
            id={customHeader || 'NewOrderSummary_error_header'}
          />
        </Heading>

        <Text className="pb-6">
          <FormattedMessage
            id={customSubheader || 'NewOrderSummary_error_subheader'}
          />
        </Text>

        <PrimaryButton className="mt-6" onClick={onConfirm}>
          <FormattedMessage
            id={customButtonText || 'NewOrderSummary_to_dashboard'}
          />
        </PrimaryButton>
      </CardBody>
    </Card>
  )
}
