import { useCallback, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useLocation, useParams } from 'react-router-dom'
import { NetworkStatus, useQuery } from '@apollo/client'
import { observer } from 'mobx-react'

import { OrdersPreviewColumn } from 'Modules/orders/components/OrdersPreviewColumn/OrdersPreviewColumn'
import { OrdersTable } from 'Modules/orders/components/OrdersTable/OrdersTable'
import { OrdersPreviewColumnTypeEnum } from 'Modules/orders/types/OrdersPreviewColumnType.type'
import { ReturnsPreviewColumn } from 'Modules/returns/components/ReturnsPreviewColumn/ReturnsPreviewColumn'

import { Card } from 'Components/_theme'
import { ContentSidebar } from 'Components/ContentSidebar/ContentSidebar'
import { FaqCard } from 'Components/FaqCard/FaqCard'
import { ImpactTracker } from 'Components/ImpactTracker/ImpactTracker'
import { ListSearch } from 'Components/ListSearch/ListSearch'
import { ObserveMeOnceIAmMounted } from 'Components/ObserveMeOnceIAmMounted/ObserveMeOnceIAmMounted'
import { PageErrorState } from 'Components/PageErrorState/PageErrorState'
import { PageHeader } from 'Components/PageHeader/PageHeader'
import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'

import { useIsOrganisation, useSidebarVisibility } from 'Hooks'

import { getQueryObject, toggleIntercomVisibility } from 'Utilities'

import { PAGINATION } from 'Constants/constants'
import {
  getOrders,
  getOrdersVariables,
} from 'Constants/graphql/queries/listing/__generated__/getOrders'
import {
  getOrdersAndReturns,
  getOrdersAndReturnsVariables,
} from 'Constants/graphql/queries/listing/__generated__/getOrdersAndReturns'
import { GET_ORDERS } from 'Constants/graphql/queries/listing/GetOrders'
import { GET_ORDERS_AND_RETURNS } from 'Constants/graphql/queries/listing/GetOrdersAndReturns'
import { PortalSectionEnum } from 'Constants/portalSection'

import { ReportType } from 'Portal/__generated__/globalTypes'

export const OrdersDefault = observer(() => {
  const [selectedEntityId, setSelectedEntityId] = useState('')
  const [previewColumnType, setPreviewColumnType] =
    useState<OrdersPreviewColumnTypeEnum>(OrdersPreviewColumnTypeEnum.ORDER)

  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null)
  const [isPreviewColumnOpen, setIsPreviewColumnOpen] = useState(false)
  const isSidebarOpen = isPreviewColumnOpen
  const { isVisible: isPreviewColumnVisible } = useSidebarVisibility(
    isPreviewColumnOpen,
    isSidebarOpen,
  )

  const isOrganisation = useIsOrganisation()

  const intl = useIntl()

  const { employeeId, organisationId } = useParams<{
    employeeId?: string
    organisationId?: string
  }>()
  const { search: locationSearch } = useLocation()
  const parsedSearch = getQueryObject(locationSearch)

  const { loading, data, error, fetchMore, refetch, networkStatus } = useQuery<
    getOrders | getOrdersAndReturns,
    getOrdersVariables | getOrdersAndReturnsVariables
  >(isOrganisation ? GET_ORDERS : GET_ORDERS_AND_RETURNS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    variables: {
      after: PAGINATION.AFTER,
      first: PAGINATION.ITEMS_PER_PAGE,
      id: organisationId! ?? employeeId!,
      searchterm: (parsedSearch?.searchTerm as string) ?? null,
    },
  })

  const getCaseItems = (data: getOrders | getOrdersAndReturns | null) =>
    isOrganisation
      ? { caseItems: (data as getOrders).organisation.caseItems }
      : {
          caseItems: (data as getOrdersAndReturns).employee.caseitems,
          returnCases: (data as getOrdersAndReturns).employee.returnCases,
        }

  const handleOpenPreviewColumn = useCallback(
    (
      id: string,
      rowIndex: number | null,
      previewColumnType: OrdersPreviewColumnTypeEnum,
    ) => {
      toggleIntercomVisibility(false)

      setSelectedEntityId(id)
      setSelectedRowIndex(rowIndex)
      setPreviewColumnType(previewColumnType)
      setIsPreviewColumnOpen(true)
    },
    [],
  )

  const handleClosePreviewColumn = useCallback(() => {
    toggleIntercomVisibility(true)

    setIsPreviewColumnOpen(false)
    setSelectedRowIndex(null)
  }, [])

  const handleSearch = useCallback(
    (value: string) => {
      refetch({ searchterm: value || null })
    },
    [refetch],
  )

  const onLoadMore = () => {
    if (data)
      fetchMore({
        variables: {
          after: getCaseItems(data).caseItems?.pageInfo.endCursor,
        },
      })
  }

  const intersectionObserver = new IntersectionObserver(([entry]) => {
    if (entry.isIntersecting) {
      onLoadMore()
    }
  })

  useEffect(() => {
    refetch({ searchterm: (parsedSearch?.searchTerm as string) ?? null })
  }, [refetch, parsedSearch?.searchTerm])

  if (error) {
    return <PageErrorState />
  }

  if (loading && !data) return <PageLoadingState />
  if (!data) return <PageErrorState />

  const caseItemsData = getCaseItems(data)
  const caseItems =
    caseItemsData.caseItems?.caseItems?.filter(value => !!value) ?? []
  const returnCases =
    caseItemsData.returnCases?.returnCases?.filter(value => !!value) ?? []
  const totalCaseItems = isOrganisation
    ? (caseItemsData.caseItems?.totalCount ?? 0)
    : (caseItemsData.caseItems?.totalCount ?? 0) +
      (caseItemsData.returnCases?.totalCount ?? 0)

  const hasNextPage = getCaseItems(data).caseItems?.pageInfo.hasNextPage

  return (
    <Container className="pt-6" fluid>
      <div role="banner">
        {isOrganisation ? (
          <PageHeader portalSection={PortalSectionEnum.Orders} />
        ) : (
          <PageHeader portalSection={PortalSectionEnum.OrdersAndReturns} />
        )}
      </div>

      <Row>
        <Col xl={12} xxl={8}>
          <Card className="mb-4">
            <ListSearch
              downloadReportAlertAdditionalReadyMessage={intl.formatMessage({
                id: 'Download_report_report_ready_order_message',
              })}
              downloadReportType={ReportType.orders}
              handleSearch={handleSearch}
              totalCount={totalCaseItems ?? 0}
            />

            <Row>
              <Col>
                <OrdersTable
                  isActiveEmployee={!isOrganisation}
                  isLoading={loading}
                  isSearching={networkStatus === NetworkStatus.setVariables}
                  onOpenPreviewColumn={handleOpenPreviewColumn}
                  orders={caseItems}
                  returns={returnCases}
                  selectedRowIndex={selectedRowIndex}
                />
              </Col>
            </Row>
          </Card>

          <Row className="p-1">
            <Col>
              {hasNextPage && !loading && (
                <ObserveMeOnceIAmMounted
                  intersectionObserver={intersectionObserver}
                />
              )}
            </Col>
          </Row>
        </Col>

        <ContentSidebar
          isSidebarOpen={isPreviewColumnOpen}
          renderStickyColumnSideContent={
            <>
              <ImpactTracker />
              <FaqCard />
            </>
          }
        >
          {previewColumnType === OrdersPreviewColumnTypeEnum.RETURN ? (
            <ReturnsPreviewColumn
              entityId={selectedEntityId}
              isVisible={isPreviewColumnVisible}
              onClosePreviewColumn={handleClosePreviewColumn}
            />
          ) : (
            <OrdersPreviewColumn
              entityId={selectedEntityId}
              isVisible={isPreviewColumnVisible}
              onClosePreviewColumn={handleClosePreviewColumn}
            />
          )}
        </ContentSidebar>
      </Row>
    </Container>
  )
})
