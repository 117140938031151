import { DateObject } from 'react-multi-date-picker'

import {
  CreateCustomToolbarConfigArgs,
  CreateCustomToolbarConfigParseDateArgs,
  CreateCustomToolbarConfigParseLabelArgs,
  CustomToolbarConfigOutput,
} from './MultidatePicker.types'

export function createCustomToolbarConfigParseLabel({
  intl,
  scope,
  length,
  period,
}: CreateCustomToolbarConfigParseLabelArgs) {
  if (scope === 'today') {
    return intl.formatMessage({ id: 'MultidatePicker_today' })
  }

  if (scope === 'until_today') {
    return intl.formatMessage({ id: 'MultidatePicker_until_today' })
  }

  if (period === 'days') {
    return intl.formatMessage(
      {
        id:
          scope === 'past'
            ? 'MultidatePicker_last_days'
            : 'MultidatePicker_next_days',
      },
      { number: length },
    )
  }

  return intl.formatMessage(
    {
      id:
        scope === 'past'
          ? 'MultidatePicker_last_months'
          : 'MultidatePicker_next_months',
    },
    { number: length },
  )
}

export function createCustomToolbarConfigParseDate({
  scope,
  length,
  period,
}: CreateCustomToolbarConfigParseDateArgs) {
  let firstDate: DateObject
  let secondDate: DateObject

  if (scope === 'today') {
    firstDate = new DateObject()
    secondDate = new DateObject()

    return [firstDate, secondDate]
  }

  if (scope === 'until_today') {
    firstDate = new DateObject().setDate(0)
    secondDate = new DateObject()

    return [firstDate, secondDate]
  }

  if (scope === 'past') {
    firstDate = new DateObject().subtract(length, period).add(1, 'day')
    secondDate = new DateObject()

    return [firstDate, secondDate]
  }

  firstDate = new DateObject()
  secondDate = new DateObject().add(length, period).subtract(1, 'day')

  return [firstDate, secondDate]
}

export const createCustomToolbarConfig = ({
  config,
  intl,
}: CreateCustomToolbarConfigArgs) => {
  const toolbarConfig: CustomToolbarConfigOutput = {
    buttons: [],
    dates: {},
  }

  for (const [key, { length = 0, period = 'days', scope }] of Object.entries(
    config,
  )) {
    toolbarConfig.buttons.push({
      content:
        scope === 'today' || scope === 'until_today'
          ? createCustomToolbarConfigParseLabel({ intl, scope })
          : createCustomToolbarConfigParseLabel({
              intl,
              length,
              period,
              scope,
            }),
      key,
    })

    toolbarConfig.dates[key] =
      scope === 'today' || scope === 'until_today'
        ? createCustomToolbarConfigParseDate({ scope })
        : createCustomToolbarConfigParseDate({ length, period, scope })
  }

  return toolbarConfig
}
