import { ContactLandingDescription } from 'Modules/contact/components/ContactLandingDescription/ContactLandingDescription'
import { ContactLandingDetails } from 'Modules/contact/components/ContactLandingDetails/ContactLandingDetails'

import { Footer } from 'Components/Footer/Footer'

import decorBottomRight from 'Assets/images/contact/contact_decor_bottom_right.svg'
import decorTopRight from 'Assets/images/contact/contact_decor_top_right.svg'
import everphoneLogoLight from 'Assets/images/logos/everphone_logo_light.svg'

export const ContactLanding = () => (
  <div className="ContactLanding">
    <div className="ContactLanding-content row">
      <div className="ContactLanding-content-left col-12 col-xl-8">
        <div className="ContactLanding-logo">
          <img alt="Everphone Logo" src={everphoneLogoLight} />
        </div>

        <div className="ContactLanding-main-content">
          <div className="d-flex justify-content-center align-items-center">
            <div className="col-8">
              <ContactLandingDescription />
            </div>
          </div>
        </div>

        <Footer className="d-none d-xl-flex" variant="light" />
      </div>

      <div className="ContactLanding-content-right col-12 col-xl-4">
        <img
          alt="Everphone shapes"
          className="decor-img img-fluid position-absolute px-6 top-0"
          src={decorTopRight}
        />

        <ContactLandingDetails />

        <img
          alt="Everphone shapes"
          className="decor-img img-fluid position-absolute px-6 bottom-0"
          src={decorBottomRight}
        />
      </div>
    </div>

    <Footer className="d-flex d-xl-none" variant="light" />
  </div>
)
