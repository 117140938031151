import { useCallback, useMemo } from 'react'
import { FormGroup } from 'react-bootstrap'
import { get, useFormContext } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { GroupBase } from 'react-select'
import { clsx } from 'clsx'

import { FeedbackText, Label } from 'Components/_theme'
import { SelectOption } from 'Components/Select/Select.types'

import { FieldProps, FieldTypeEnum } from './Field.types'
import { parseLabel } from './Field.utils'
import { FieldCheckbox } from './FieldCheckbox'
import { FieldDatepicker } from './FieldDatepicker'
import { FieldInput } from './FieldInput'
import { FieldPhoneInput } from './FieldPhoneInput'
import { FieldRadio } from './FieldRadio'
import { FieldRadioButtonGroup } from './FieldRadioButtonGroup'
import { FieldRangeInput } from './FieldRangeInput'
import { FieldSelect } from './FieldSelect'
import { FieldSelectAsync } from './FieldSelectAsync'
import { FieldSelectCreatable } from './FieldSelectCreatable'
import { FieldTextarea } from './FieldTextarea'

export const Field = <
  Option extends SelectOption,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: FieldProps<Option, IsMulti, Group>,
) => {
  const {
    containerClassName,
    inputClassName,
    labelClassName,
    label,
    placeholder,
    name,
    type,
    shouldShowErrorMessage = true,
    shouldTranslateLabel = true,
    markAsRequired = false,
    feedbackClassName,
    feedbackText,
    feedbackTranslationValues,
    e2eSelector,
    errorMessage,
    ...rest
  } = props

  const intl = useIntl()
  const { formState } = useFormContext()
  const { errors } = formState
  const formMessage = errorMessage || get(errors, name)?.message
  const hasError = Boolean(formMessage)

  const renderInput = useCallback(() => {
    if (
      type !== FieldTypeEnum.text &&
      type !== FieldTypeEnum.email &&
      type !== FieldTypeEnum.number
    ) {
      return null
    }

    const { inputProps, autoComplete } = props

    return (
      <FieldInput
        {...rest}
        autoComplete={autoComplete}
        e2eSelector={e2eSelector}
        hasError={hasError}
        inputClassName={inputClassName}
        inputProps={inputProps}
        markAsRequired={markAsRequired}
        name={name}
        placeholder={placeholder}
        type={type}
      />
    )
  }, [
    type,
    props,
    rest,
    hasError,
    inputClassName,
    name,
    placeholder,
    markAsRequired,
    e2eSelector,
  ])

  const renderTextarea = useCallback(() => {
    if (
      type !== FieldTypeEnum.textarea &&
      type !== FieldTypeEnum.textareaAutosize
    ) {
      return null
    }

    return (
      <FieldTextarea
        {...rest}
        e2eSelector={e2eSelector}
        hasError={hasError}
        inputClassName={inputClassName}
        markAsRequired={markAsRequired}
        name={name}
        placeholder={placeholder}
        type={type}
      />
    )
  }, [
    e2eSelector,
    hasError,
    inputClassName,
    markAsRequired,
    name,
    placeholder,
    rest,
    type,
  ])

  const renderCheckbox = useCallback(
    () => (
      <FieldCheckbox
        {...rest}
        e2eSelector={e2eSelector}
        hasError={hasError}
        inputClassName={inputClassName}
        label={label}
        markAsRequired={markAsRequired}
        name={name}
        shouldTranslateLabel={shouldTranslateLabel}
      />
    ),
    [
      e2eSelector,
      hasError,
      inputClassName,
      label,
      markAsRequired,
      name,
      rest,
      shouldTranslateLabel,
    ],
  )

  const renderRadio = useCallback(
    () => (
      <FieldRadio
        {...rest}
        hasError={hasError}
        inputClassName={inputClassName}
        label={label}
        markAsRequired={markAsRequired}
        name={name}
        shouldTranslateLabel={shouldTranslateLabel}
      />
    ),
    [
      hasError,
      inputClassName,
      label,
      markAsRequired,
      name,
      rest,
      shouldTranslateLabel,
    ],
  )

  const renderDatepicker = useCallback(() => {
    if (type !== FieldTypeEnum.datepicker) {
      return null
    }

    const { datepickerProps, autoComplete } = props

    return (
      <FieldDatepicker
        {...rest}
        autoComplete={autoComplete}
        datepickerProps={datepickerProps}
        e2eSelector={e2eSelector}
        hasError={hasError}
        inputClassName={inputClassName}
        name={name}
      />
    )
  }, [e2eSelector, hasError, inputClassName, name, props, rest, type])

  const renderRangeInput = useCallback(() => {
    if (type !== FieldTypeEnum.range) {
      return null
    }

    const { rangeProps } = props

    return (
      <FieldRangeInput
        {...rest}
        hasError={hasError}
        name={name}
        rangeProps={rangeProps}
      />
    )
  }, [hasError, name, props, rest, type])

  const renderRadioButtonGroup = useCallback(() => {
    if (type !== FieldTypeEnum.radioButtonGroup) {
      return null
    }

    const { radioButtonGroupProps } = props

    return (
      <FieldRadioButtonGroup
        {...rest}
        hasError={hasError}
        name={name}
        radioButtonGroupProps={radioButtonGroupProps}
      />
    )
  }, [hasError, name, props, rest, type])

  const renderPhoneNumber = useCallback(() => {
    if (type !== FieldTypeEnum.phoneNumber) {
      return null
    }

    const { phoneInputProps } = props

    return (
      <FieldPhoneInput
        e2eSelector={e2eSelector}
        hasError={hasError}
        name={name}
        phoneInputProps={phoneInputProps}
      />
    )
  }, [e2eSelector, hasError, name, props, type])

  const renderSelect = useCallback(() => {
    if (type !== FieldTypeEnum.select) {
      return null
    }

    const { selectProps } = props

    return (
      <FieldSelect
        e2eSelector={e2eSelector}
        hasError={hasError}
        markAsRequired={markAsRequired}
        name={name}
        placeholder={placeholder}
        selectProps={selectProps}
      />
    )
  }, [e2eSelector, hasError, markAsRequired, name, placeholder, props, type])

  const renderSelectCreatable = useCallback(() => {
    if (type !== FieldTypeEnum.selectCreatable) {
      return null
    }

    const { selectProps } = props

    return (
      <FieldSelectCreatable
        e2eSelector={e2eSelector}
        hasError={hasError}
        markAsRequired={markAsRequired}
        name={name}
        placeholder={placeholder}
        selectProps={selectProps}
      />
    )
  }, [e2eSelector, hasError, markAsRequired, name, placeholder, props, type])

  const renderSelectAsync = useCallback(() => {
    if (type !== FieldTypeEnum.selectAsync) {
      return null
    }

    const { selectProps } = props

    return (
      <FieldSelectAsync
        e2eSelector={e2eSelector}
        hasError={hasError}
        markAsRequired={markAsRequired}
        name={name}
        placeholder={placeholder}
        selectProps={selectProps}
      />
    )
  }, [e2eSelector, hasError, markAsRequired, name, placeholder, props, type])

  const renderInputOfType = useMemo(() => {
    switch (type) {
      case FieldTypeEnum.textarea:
        return renderTextarea()
      case FieldTypeEnum.textareaAutosize:
        return renderTextarea()
      case FieldTypeEnum.checkbox:
        return renderCheckbox()
      case FieldTypeEnum.radio:
        return renderRadio()
      case FieldTypeEnum.phoneNumber:
        return renderPhoneNumber()
      case FieldTypeEnum.select:
        return renderSelect()
      case FieldTypeEnum.selectCreatable:
        return renderSelectCreatable()
      case FieldTypeEnum.selectAsync:
        return renderSelectAsync()
      case FieldTypeEnum.datepicker:
        return renderDatepicker()
      case FieldTypeEnum.range:
        return renderRangeInput()
      case FieldTypeEnum.radioButtonGroup:
        return renderRadioButtonGroup()
      default:
        return renderInput()
    }
  }, [
    renderCheckbox,
    renderDatepicker,
    renderInput,
    renderPhoneNumber,
    renderRadio,
    renderRadioButtonGroup,
    renderRangeInput,
    renderSelect,
    renderSelectAsync,
    renderSelectCreatable,
    renderTextarea,
    type,
  ])

  const renderLabel = useMemo(() => {
    if (!label) {
      return null
    }

    if (type === FieldTypeEnum.checkbox || type === FieldTypeEnum.radio) {
      return null
    }

    return (
      <Label
        className={clsx(labelClassName)}
        htmlFor={name}
        isRequired={markAsRequired}
      >
        {parseLabel(intl, label, shouldTranslateLabel)}
      </Label>
    )
  }, [
    label,
    type,
    labelClassName,
    name,
    markAsRequired,
    intl,
    shouldTranslateLabel,
  ])

  const renderFeedback = useMemo(() => {
    if ((!hasError || !shouldShowErrorMessage) && !feedbackText) {
      return null
    }

    return (
      <FeedbackText
        className={feedbackClassName}
        data-testid={`${hasError ? 'error' : 'feedback'}-${name}`}
        isInvalid={hasError}
      >
        <FormattedMessage
          id={hasError ? formMessage : feedbackText}
          values={feedbackTranslationValues}
        />
      </FeedbackText>
    )
  }, [
    feedbackClassName,
    feedbackText,
    feedbackTranslationValues,
    formMessage,
    hasError,
    name,
    shouldShowErrorMessage,
  ])

  return (
    <FormGroup className={clsx(containerClassName)} controlId={name}>
      {renderLabel}

      {renderInputOfType}

      {renderFeedback}
    </FormGroup>
  )
}
