import { FormattedMessage } from 'react-intl'

import { Card, Heading, Text } from 'Components/_theme'

import { DashboardAccountManagerCardProps } from './DashboardAccountManagerCard.types'

export const DashboardAccountManagerCard = (
  props: DashboardAccountManagerCardProps,
) => {
  const { accountManager } = props

  if (!accountManager) {
    return null
  }

  return (
    <Card className="DashboardAccountManagerCard mb-6">
      <div className="DashboardAccountManagerCard-decoration-container">
        <div className="DashboardAccountManagerCard-left-decoration" />

        <div className="DashboardAccountManagerCard-right-decoration">
          <div className="DashboardAccountManagerCard-right-decoration-circle" />
        </div>
      </div>

      <div className="DashboardAccountManagerCard-content-container">
        <Text className="DashboardAccountManagerCard-title">
          <FormattedMessage id="Dashboard_everphone_contact" />
        </Text>

        <Heading color="light" size={5}>
          {accountManager}
        </Heading>
      </div>
    </Card>
  )
}
