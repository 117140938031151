import { observer } from 'mobx-react'

import { NavigationButton } from 'Components/_theme'

import { useStore } from 'Stores/index'

import { PortalMenuProps } from './PortalMenu.types'

export const PortalMenu = observer((props: PortalMenuProps) => {
  const { menuItems } = props

  const { portalSettingsStore } = useStore()
  const { isCompactSidebar, currentPortalSection, toggleMenuVisibility } =
    portalSettingsStore

  return (
    <div aria-label="Main navigation links" role="navigation">
      <ul className="side-nav" id="menu-bar">
        {menuItems.map(item => (
          <li key={item.path}>
            <NavigationButton
              isActive={currentPortalSection === item.key}
              isCompact={isCompactSidebar}
              item={item}
              toggleMenuVisibility={toggleMenuVisibility}
            />
          </li>
        ))}
      </ul>
    </div>
  )
})
