import { gql } from '@apollo/client'

export const FRAGMENT_ORDER_FIELDS = gql`
  fragment orderFields on CaseItem {
    __typename
    id
    created
    caseType
    status
    data {
      __typename
      requestedDevice {
        __typename
        name
      }
      requestedRentCategory {
        __typename
        deviceDefinition {
          __typename
          imageURL
          manufacturer
          codename
          name
          productType
        }
      }
      requestedOfferOrder {
        __typename
        deviceDefinition {
          __typename
          name
        }
      }
      requestedPhonePlan {
        name
      }
    }
    assignedEmployee {
      __typename
      contact {
        __typename
        firstName
        lastName
      }
    }
    assignedUser {
      __typename
      firstName
      lastName
    }
    offerOrderItem {
      customDeviceName
    }
  }
`

export const GET_ORDERS = gql`
  query getOrders(
    $id: ID!
    $first: Int!
    $after: Int!
    $searchterm: String
    $filterByCaseType: [CaseItemType]
  ) {
    organisation(id: $id) {
      id
      caseItems(
        pagination: { after: $after, first: $first }
        searchterm: $searchterm
        orderBy: { field: id, order: DESC }
        filterByCaseType: $filterByCaseType
      ) {
        __typename
        totalCount
        pageInfo {
          __typename
          endCursor
          hasNextPage
        }
        caseItems {
          ...orderFields
        }
      }
    }
  }
  ${FRAGMENT_ORDER_FIELDS}
`
