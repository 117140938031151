import { FieldPath, FieldValues, UseFormSetError } from 'react-hook-form'
import { get } from 'lodash-es'

export function setFormErrors<S extends FieldValues>(
  response: any,
  errorsPath: string,
  setError: UseFormSetError<S>,
) {
  const errors: {
    field: string
    message: string
  }[] = get(response, errorsPath, [])

  errors.forEach(input => {
    setError(input.field as FieldPath<S>, {
      message: input.message,
      type: 'type-error',
    })
  })
}
