import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  faChevronRight,
  faQuestionCircle,
} from '@fortawesome/pro-solid-svg-icons'
import { observer } from 'mobx-react'

import { LinkButton, PrimaryButton, Widget } from 'Components/_theme'

import { useStore } from 'Stores/index'

import { getFAQLink, isActiveLanguage, openIntercomChat } from 'Utilities'

import { SupportedLanguagesEnum } from 'Constants/locale'

import FaqImage from 'Assets/images/layout/faqillustration.svg'

import { FaqCardProps } from './FaqCard.types'

export const FaqCard = observer((props: FaqCardProps) => {
  const { isOnOrderFlow = false } = props
  const intl = useIntl()
  const { portalContentStore } = useStore()
  const { portalContent } = portalContentStore
  const isGermanLanguageActive = isActiveLanguage(SupportedLanguagesEnum.DE)

  const generateButtonUrl = useMemo(
    () => getFAQLink(isGermanLanguageActive, portalContent),
    [isGermanLanguageActive, portalContent],
  )

  const openFaqWindow = () => {
    const faqWindow = window.open(generateButtonUrl, '_blank')

    if (!faqWindow) {
      window.location.replace(generateButtonUrl)
    }
  }

  const renderContent = () => (
    <>
      <p>
        <FormattedMessage id="FaqCard_description_part1" />
      </p>
      <p>
        <FormattedMessage id="FaqCard_description_part2" />
      </p>
    </>
  )

  if (isOnOrderFlow) {
    return null
  }

  return (
    <Widget
      alt={intl.formatMessage({ id: 'Img_alt_faq' })}
      className="d-none d-xxl-block mb-6"
      content={renderContent()}
      imageSrc={FaqImage}
      primaryIcon={faQuestionCircle}
      title={intl.formatMessage({ id: 'FaqCard_title' })}
    >
      <PrimaryButton
        appearance="outline"
        className="me-7"
        onClick={openFaqWindow}
      >
        <FormattedMessage id="FaqCard_primary_cta" />
      </PrimaryButton>

      <LinkButton iconRight={faChevronRight} onClick={openIntercomChat}>
        <FormattedMessage id="FaqCard_secondary_cta" />
      </LinkButton>
    </Widget>
  )
})
