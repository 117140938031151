import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { ApolloError, useMutation } from '@apollo/client'
import { observer } from 'mobx-react'

import { getLostReplacementPath } from 'Modules/rentals/utils/rental.utils'

import { Card, CardHeader } from 'Components/_theme'
import { ModalDeviceReplacementFormSchema } from 'Components/ModalDeviceReplacementForm/ModalDeviceReplacementForm.types'
import { modalDeviceReplacementFormDefaultValues } from 'Components/ModalDeviceReplacementForm/ModalDeviceReplacementForm.values'
import { ShippingAddressFormSchema } from 'Components/ShippingAddressForm/ShippingAddressForm.types'

import { useStore } from 'Stores/index'

import { useActiveUser, useIsOrganisation } from 'Hooks'

import {
  formatShippingAddressDataBeforeSubmit,
  getSelectValue,
} from 'Utilities'

import {
  E2E_LOST_STOLEN_BUTTON_BACK,
  E2E_LOST_STOLEN_BUTTON_CLOSE,
  E2E_LOST_STOLEN_BUTTON_SUBMIT,
  E2E_LOST_STOLEN_DEPOT,
  E2E_LOST_STOLEN_NEXT_DEVICE,
  E2E_LOST_STOLEN_PREVIOUS_DEVICE,
} from 'Constants/e2e'
import {
  mutationLostDevice,
  mutationLostDeviceVariables,
} from 'Constants/graphql/mutations/__generated__/mutationLostDevice'
import { MUTATION_LOST_DEVICE } from 'Constants/graphql/mutations/MutationLostDevice'

import { iconLostDevice } from 'Icons/iconLostDevice'

import { ReplacementType } from 'Portal/__generated__/globalTypes'
import { OriginRouteTypeEnum } from 'Portal/src/stores/orderStore/orderStore.types'

import { ModalConfirmation } from '../../components/ModalConfirmation/ModalConfirmation'
import { ModalReplacementForm } from '../../components/ModalDeviceReplacementForm/ModalDeviceReplacementForm'
import { ModalShippingForm } from '../../components/ModalShippingForm/ModalShippingForm'
import { ModalSummary } from '../../components/ModalSummary/ModalSummary'
import { LostOrStolenModalLanding } from './components/LostOrStolenModalLanding/LostOrStolenModalLanding'
import {
  LOST_STOLEN_MODAL_REPLACEMENT_FORM,
  LOST_STOLEN_MODAL_SHIPPING_FORM,
  LOST_STOLEN_MODAL_SUMMARY_FORM,
} from './LostOrStolenModal.constants'
import {
  LostOrStolenModalProps,
  LostOrStolenModalStepsEnum,
  LostOrStolenModalStepsMap,
  LostOrStolenModalTypesEnum,
} from './LostOrStolenModal.types'

export const LostOrStolenModal = observer((props: LostOrStolenModalProps) => {
  const { rental, type, onClose, onSubmit, setOnAfterClose } = props
  const { orderStore, portalConfigStore } = useStore()
  const { setOriginRoute } = orderStore
  const { portalConfig } = portalConfigStore
  const isOrganisation = useIsOrganisation()
  const history = useHistory()

  const { activeEmployee, activeOrganisation } = useActiveUser()

  const organisationID =
    activeEmployee?.organisation?.id || activeOrganisation!.id

  const employeeId = activeEmployee?.id || rental.employee?.id

  const [selectionType, setSelectionType] = useState(ReplacementType.none)
  const [replacementFormData, setReplacementFormData] = useState(
    modalDeviceReplacementFormDefaultValues,
  )
  const [shippingFormData, setShippingFormData] =
    useState<ShippingAddressFormSchema | null>(null)
  const [currentStep, setCurrentStep] =
    useState<`${LostOrStolenModalStepsEnum}`>(
      LostOrStolenModalStepsEnum.LANDING,
    )
  const [submitError, setSubmitError] = useState<ApolloError | null>(null)
  const requestIdRef = useRef<string | null>(null)

  const { userStore } = useStore()
  const { user } = userStore

  const [submitLostDevice, { loading: isLoading }] = useMutation<
    mutationLostDevice,
    mutationLostDeviceVariables
  >(MUTATION_LOST_DEVICE, {
    onCompleted: data => {
      if (data.createLostDeviceCaseItem.__typename === 'LostDeviceProblem') {
        return
      }

      requestIdRef.current = data.createLostDeviceCaseItem.result?.id!

      // refresh rental data
      onSubmit()

      setCurrentStep(LostOrStolenModalStepsEnum.CONFIRMATION)
    },
    onError: error => {
      setSubmitError(error)
    },
  })

  const handleSubmitLostDevice = useCallback(() => {
    submitLostDevice({
      variables: {
        input: {
          organisationID,
          rentalID: rental.id,
          replacementType: selectionType,
          requestingUserID: user.userId?.toString() ?? '0',
          shippingAddress:
            formatShippingAddressDataBeforeSubmit(shippingFormData),
          stockListItemID:
            getSelectValue(replacementFormData.nextDeviceId) || null,
        },
      },
    })
  }, [
    organisationID,
    rental.id,
    replacementFormData.nextDeviceId,
    selectionType,
    shippingFormData,
    submitLostDevice,
    user.userId,
  ])

  const handleExitModal = useCallback(() => {
    setCurrentStep(LostOrStolenModalStepsEnum.LANDING)
    // reset forms state
    setReplacementFormData(modalDeviceReplacementFormDefaultValues)
    setShippingFormData(null)
    setSubmitError(null)
    requestIdRef.current = null
  }, [])

  const handleOrderLostReplacementRedirect = useCallback(() => {
    setOriginRoute({
      type: OriginRouteTypeEnum.Rental,
      url: history.location.pathname,
    })

    onClose()

    const path = getLostReplacementPath(
      isOrganisation,
      rental,
      employeeId,
      organisationID,
    )

    history.push(path)
  }, [
    employeeId,
    history,
    isOrganisation,
    onClose,
    organisationID,
    rental,
    setOriginRoute,
  ])

  const handleSetCurrentStep = useCallback(
    (
      step: `${LostOrStolenModalStepsEnum}`,
      shippingData?: ShippingAddressFormSchema,
      replacementData?: ModalDeviceReplacementFormSchema,
    ) => {
      if (shippingData) {
        setShippingFormData({ ...shippingData })
      }

      if (replacementData) {
        setReplacementFormData({ ...replacementData })
      }

      setCurrentStep(step)
    },
    [],
  )

  const handleReplacementFormPreviousStep = useCallback(() => {
    handleSetCurrentStep(LostOrStolenModalStepsEnum.LANDING)
  }, [handleSetCurrentStep])

  const handleReplacementFormSubmit = useCallback(
    (data: ModalDeviceReplacementFormSchema) => {
      handleSetCurrentStep(LostOrStolenModalStepsEnum.SUMMARY, undefined, data)
    },
    [handleSetCurrentStep],
  )

  const handleShippingFormPreviousStep = useCallback(() => {
    handleSetCurrentStep(LostOrStolenModalStepsEnum.LANDING)
  }, [handleSetCurrentStep])

  const handleShippingFormSubmit = useCallback(
    (data: ShippingAddressFormSchema) => {
      handleSetCurrentStep(LostOrStolenModalStepsEnum.SUMMARY, data)
    },
    [handleSetCurrentStep],
  )

  const handleSummaryPreviousStep = useCallback(() => {
    if (selectionType === ReplacementType.none) {
      setCurrentStep(LostOrStolenModalStepsEnum.LANDING)
    } else if (selectionType === ReplacementType.depot) {
      setCurrentStep(LostOrStolenModalStepsEnum.LANDING)
    } else {
      setCurrentStep(LostOrStolenModalStepsEnum.SHIPPING_FORM)
    }
  }, [selectionType])

  const isDeviceSelectionForLostDeviceEnabled =
    !portalConfig?.isDeviceSelectionForLostDeviceDisabled &&
    portalConfig?.enableProductOffers &&
    rental.actions.showReplacementOrderFlow

  const stepsMap = useMemo((): LostOrStolenModalStepsMap => {
    switch (type) {
      case LostOrStolenModalTypesEnum.ORGANISATION_WITH_DEPOT:
        return {
          [LostOrStolenModalStepsEnum.LANDING]: {
            component: LostOrStolenModalLanding,
            props: {
              isDeviceSelectionForLostDeviceEnabled:
                isDeviceSelectionForLostDeviceEnabled ?? false,
              isNoReplacementEnabled: rental.isLostStolenNoReplacementEnabled,
              isReplacementDeviceAvailableInDepot:
                rental.isReplacementDeviceAvailableInDepot,
              onOrderLostReplacementRedirect:
                handleOrderLostReplacementRedirect,
              onSetCurrentStep: handleSetCurrentStep,
              onSetSelectionType: setSelectionType,
              onSetShippingFormData: setShippingFormData,
              type,
            },
          },
          [LostOrStolenModalStepsEnum.REPLACEMENT_FORM]: {
            component: ModalReplacementForm,
            props: {
              e2eSelectors: {
                back: E2E_LOST_STOLEN_BUTTON_BACK,
                depot: E2E_LOST_STOLEN_DEPOT,
                nextDevice: E2E_LOST_STOLEN_NEXT_DEVICE,
                previousDevice: E2E_LOST_STOLEN_PREVIOUS_DEVICE,
                submit: E2E_LOST_STOLEN_BUTTON_SUBMIT,
              },
              formId: LOST_STOLEN_MODAL_REPLACEMENT_FORM,
              onPreviousStep: handleReplacementFormPreviousStep,
              onSubmit: handleReplacementFormSubmit,
              previousDeviceLabel: 'Lost_stolen_device',
              rental,
            },
          },
          [LostOrStolenModalStepsEnum.SHIPPING_FORM]: {
            component: ModalShippingForm,
            props: {
              formId: LOST_STOLEN_MODAL_SHIPPING_FORM,
              leftButtonE2ESelector: E2E_LOST_STOLEN_BUTTON_BACK,
              onPreviousStep: handleShippingFormPreviousStep,
              onSubmit: handleShippingFormSubmit,
              rental,
              rightButtonE2ESelector: E2E_LOST_STOLEN_BUTTON_SUBMIT,
              shippingFormData,
            },
          },
          [LostOrStolenModalStepsEnum.SUMMARY]: {
            component: ModalSummary,
            props: {
              formId: LOST_STOLEN_MODAL_SUMMARY_FORM,
              isLoading,
              leftButtonE2ESelector: E2E_LOST_STOLEN_BUTTON_BACK,
              onPreviousStep: handleSummaryPreviousStep,
              onSubmit: handleSubmitLostDevice,
              rental,
              rightButtonE2ESelector: E2E_LOST_STOLEN_BUTTON_SUBMIT,
              shippingFormData,
              showAlert: true,
              submitError,
            },
          },
          [LostOrStolenModalStepsEnum.CONFIRMATION]: {
            component: ModalConfirmation,
            props: {
              closeButtonE2ESelector: E2E_LOST_STOLEN_BUTTON_CLOSE,
              headingTranslationId:
                selectionType === ReplacementType.none ||
                selectionType === ReplacementType.everphone
                  ? 'LostOrStolenModal_confirmationNoReplacement_heading'
                  : 'NewOrderConfirmation_heading',
              onClose,
              requestIdRef,
              shippingFormData,
            },
          },
        }
      default:
        return {
          [LostOrStolenModalStepsEnum.LANDING]: {
            component: LostOrStolenModalLanding,
            props: {
              isDeviceSelectionForLostDeviceEnabled:
                isDeviceSelectionForLostDeviceEnabled ?? false,
              isNoReplacementEnabled: rental.isLostStolenNoReplacementEnabled,
              isReplacementDeviceAvailableInDepot:
                rental.isReplacementDeviceAvailableInDepot,
              onOrderLostReplacementRedirect:
                handleOrderLostReplacementRedirect,
              onSetCurrentStep: handleSetCurrentStep,
              onSetSelectionType: setSelectionType,
              onSetShippingFormData: setShippingFormData,
              type,
            },
          },
          [LostOrStolenModalStepsEnum.SHIPPING_FORM]: {
            component: ModalShippingForm,
            props: {
              formId: LOST_STOLEN_MODAL_SHIPPING_FORM,
              leftButtonE2ESelector: E2E_LOST_STOLEN_BUTTON_BACK,
              onPreviousStep: handleShippingFormPreviousStep,
              onSubmit: handleShippingFormSubmit,
              rental,
              rightButtonE2ESelector: E2E_LOST_STOLEN_BUTTON_SUBMIT,
              shippingFormData,
            },
          },
          [LostOrStolenModalStepsEnum.SUMMARY]: {
            component: ModalSummary,
            props: {
              formId: LOST_STOLEN_MODAL_SUMMARY_FORM,
              isLoading,
              leftButtonE2ESelector: E2E_LOST_STOLEN_BUTTON_BACK,
              onPreviousStep: handleSummaryPreviousStep,
              onSubmit: handleSubmitLostDevice,
              rental,
              rightButtonE2ESelector: E2E_LOST_STOLEN_BUTTON_SUBMIT,
              shippingFormData,
              showAlert: true,
              submitError,
            },
          },
          [LostOrStolenModalStepsEnum.CONFIRMATION]: {
            component: ModalConfirmation,
            props: {
              closeButtonE2ESelector: E2E_LOST_STOLEN_BUTTON_CLOSE,
              headingTranslationId:
                selectionType === ReplacementType.none ||
                selectionType === ReplacementType.everphone
                  ? 'LostOrStolenModal_confirmationNoReplacement_heading'
                  : 'NewOrderConfirmation_heading',
              onClose,
              requestIdRef,
              shippingFormData,
            },
          },
        }
    }
  }, [
    handleOrderLostReplacementRedirect,
    handleReplacementFormPreviousStep,
    handleReplacementFormSubmit,
    handleSetCurrentStep,
    handleShippingFormPreviousStep,
    handleShippingFormSubmit,
    handleSubmitLostDevice,
    handleSummaryPreviousStep,
    isDeviceSelectionForLostDeviceEnabled,
    isLoading,
    onClose,
    rental,
    selectionType,
    shippingFormData,
    submitError,
    type,
  ])

  const renderStep = useMemo(() => {
    const { component: Component, props } = stepsMap[currentStep]

    return { component: <Component {...props} /> }
  }, [stepsMap, currentStep])

  useEffect(() => {
    setOnAfterClose(handleExitModal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleExitModal])

  return (
    <Card>
      <CardHeader icon={iconLostDevice}>
        <FormattedMessage id="LostOrStolenModal_header" />
      </CardHeader>

      {renderStep.component}
    </Card>
  )
})
