import { isObject } from 'lodash-es'

import { ShippingAddressFormSchema } from 'Components/ShippingAddressForm/ShippingAddressForm.types'

import {
  countryISOCodes,
  STREET_NUMBER_SPLIT_STRING,
} from 'Constants/constants'
import { EMPTY_SELECT_OPTION } from 'Constants/form'

import {
  AddressInput,
  OfferOrderAddressInput,
  OrderAddressType,
} from 'Portal/__generated__/globalTypes'

import { isFalsy } from '../isFalsy/isFalsy.util'

export const splitStreetNumber = (
  fullStreet?: string,
): {
  street: string
  streetNumber: string
} => {
  if (!fullStreet) return { street: '', streetNumber: '' }

  const splitIndex = fullStreet?.lastIndexOf(STREET_NUMBER_SPLIT_STRING) || -1

  if (splitIndex === -1) return { street: fullStreet, streetNumber: '' }

  return {
    street: fullStreet.substring(0, splitIndex),
    streetNumber: fullStreet.substring(
      splitIndex + STREET_NUMBER_SPLIT_STRING.length,
      fullStreet.length,
    ),
  }
}

/**
 * Helper function to get the value of a select option from a form.
 * @returns option value or empty string
 */
export const getSelectValue = <
  T extends {
    label: string
    value: string
  } | null,
>(
  option?: T,
) => option?.value ?? ''

/**
 * Helper function to create a select option object for a form.
 * @returns option object or null
 */
export const createSelectOption = <T extends string>(
  option?: T | { label?: T | null; value?: T | null } | null,
  fallback: { label: string; value: string } | null = EMPTY_SELECT_OPTION,
): { label: string; value: string } | null => {
  if (isObject(option)) {
    return isFalsy(option?.label) || isFalsy(option?.value)
      ? fallback
      : (option as { label: string; value: string })
  }

  return isFalsy(option)
    ? fallback
    : { label: option as string, value: option as string }
}

export const formatShippingAddressDataBeforeSubmit = (
  formData: ShippingAddressFormSchema | undefined | null,
  isEmployeeAndUsingOfficeAddress?: boolean,
): AddressInput | null => {
  if (!formData) {
    return null
  }

  const country = getSelectValue(formData.country)
  const isCountryUnitedStates = country === countryISOCodes.US
  const city = isCountryUnitedStates
    ? `${formData.city}, ${getSelectValue(formData.state)}`
    : formData.city
  const street = isCountryUnitedStates
    ? formData.usAddress
    : `${formData.street} ${formData.streetNumber}`

  if (isEmployeeAndUsingOfficeAddress) {
    return {
      nameAddition: formData.nameAddition,
    }
  }

  return {
    _label: formData._label,
    city,
    country,
    name: formData.name,
    nameAddition: formData.nameAddition,
    street,
    streetAddition: formData.streetAddition,
    zipcode: formData.zipcode,
  }
}

export const formatOffersShippingAddressDataBeforeSubmit = (
  formData: ShippingAddressFormSchema | null,
  type: OrderAddressType,
  isEmployeeAndUsingOfficeAddress?: boolean,
): OfferOrderAddressInput => {
  const country = getSelectValue(formData?.country)
  const isCountryUnitedStates = country === countryISOCodes.US
  const city = isCountryUnitedStates
    ? `${formData?.city}, ${getSelectValue(formData?.state)}`
    : formData?.city
  const street = isCountryUnitedStates
    ? formData?.usAddress
    : `${formData?.street} ${formData?.streetNumber}`

  if (isEmployeeAndUsingOfficeAddress) {
    return {
      nameAddition: formData?.nameAddition || '',
      type,
    }
  }

  return {
    city: city || '',
    country,
    label: formData?._label || '',
    name: formData?.name || '',
    nameAddition: formData?.nameAddition || '',
    street: street || '',
    streetAddition: formData?.streetAddition || '',
    type,
    zipcode: formData?.zipcode || '',
  }
}

export const formatPhoneNumber = (number: string): string =>
  number.startsWith('00') ? number.replace('00', '+') : number

export const validatePhoneNumber = (number: string): string =>
  number.startsWith('+') ? number : `+${number}`

export const cleanPhoneNumberWhenOnlyPrefix = (number: string): string =>
  number.length <= 4 && number.startsWith('+') ? '' : number
