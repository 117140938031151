import { handleFetchErrorFunctional } from '../handleFetchErrorFunctional/handleFetchErrorFunctional.util'

export const handleNewJoinerLogout = () => {
  fetch('/sicherheit/portal/logout', { method: 'POST' })
    .then(() => {
      const newJoinerLoginUrl = window.location.href.split('#')[0]

      window.location.href = newJoinerLoginUrl
    })
    .catch(handleFetchErrorFunctional())
}
