import { Card, CardBody, Col, Container, Row } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'

import { SitemapProps } from 'Modules/sitemap/pages/Sitemap/Sitemap.types'

import { Heading, LinkButton } from 'Components/_theme'
import { DecorHeader } from 'Components/DecorHeader/DecorHeader'
import { FooterMenuItem } from 'Components/FooterMenuItem/FooterMenuItem'

import { useMainMenuItems } from 'Hooks'

import { useFooterMenuItems } from 'Portal/src/hooks/useFooterMenuItems/useFooterMenuItems'

export const Sitemap = (props: SitemapProps) => {
  const { isUnauthorized } = props
  const { mainMenuItems } = useMainMenuItems({ isUnauthorized })
  const { footerMenuItems } = useFooterMenuItems()
  const intl = useIntl()

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card className="mt-6">
            <DecorHeader title="Sitemap" />
            <CardBody>
              <Container fluid>
                <Row>
                  <Col md={6} xs={12}>
                    <Heading as={4} className="mt-8 mb-3" size={3}>
                      <FormattedMessage id="Sitemap_main_sections" />
                    </Heading>
                    <ul className="SitemapLinksList">
                      {mainMenuItems.map(menuItem => (
                        <li key={menuItem.id}>
                          <LinkButton
                            size="small"
                            title={intl.formatMessage(
                              {
                                id: 'Link_to_page_title',
                              },
                              {
                                name: intl.formatMessage({
                                  id: menuItem.name,
                                }),
                              },
                            )}
                            to={menuItem.path}
                          >
                            <FormattedMessage id={menuItem.name} />
                          </LinkButton>
                        </li>
                      ))}
                    </ul>
                  </Col>

                  <Col md={6} xs={12}>
                    <Heading as={4} className="mt-8 mb-3" size={3}>
                      <FormattedMessage id="Sitemap_additional_pages" />
                    </Heading>
                    <ul className="SitemapLinksList">
                      {footerMenuItems.map(menuItem => (
                        <li key={menuItem.id}>
                          <FooterMenuItem item={menuItem} variant="dark" />
                        </li>
                      ))}
                    </ul>
                  </Col>
                </Row>
              </Container>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
