/* eslint-disable react/no-danger */
import { useMediaQuery } from '@react-hookz/web'
import { clsx } from 'clsx'

import { PORTAL_BREAKPOINTS } from 'Constants/constants'

import { Heading } from '../Heading/Heading'
import { TertiaryButton } from '../TertiaryButton/TertiaryButton'
import { BannerProps } from './Banner.types'

export const Banner = (props: BannerProps) => {
  const {
    buttonText,
    onButtonClick,
    showButton,
    className,
    imageSource,
    text,
    title,
    version = 'default',
    alt,
  } = props

  const isFullHDDesktop = useMediaQuery(
    `(min-width: ${PORTAL_BREAKPOINTS.XXXL})`,
  )

  const classNames = clsx(
    'Banner',
    version !== 'default' && `Banner-${version}`,
    className,
  )

  return (
    <div className={classNames}>
      {(version === 'default' || version === 'serviceApp') && (
        <div className="Banner-title-content">
          <div className="Banner-title">
            <Heading as={1} size={2}>
              {title}
            </Heading>
          </div>
        </div>
      )}

      <div className="Banner-content">
        <Heading as={1} className="Banner-compact-title" color="light" size={2}>
          {title}
        </Heading>

        <p
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
        {showButton && (
          <TertiaryButton
            onClick={onButtonClick}
            size={isFullHDDesktop ? 'large' : 'medium'}
          >
            {buttonText}
          </TertiaryButton>
        )}
      </div>

      <img alt={alt || title} src={imageSource} />
    </div>
  )
}
