import { useStore } from 'Stores/index'

/**
 * This hook is used to get jwt token from the store.
 * Please wrap component with the `observer` method from 'react-mobx' for the hook to re-sync data properly.
 */
export function useAuthToken() {
  const { userStore } = useStore()
  const {
    user: { jwt },
  } = userStore

  return jwt ?? ''
}
