import { gql } from '@apollo/client'

const FRAGMENT_ADDRESS_FIELDS = gql`
  fragment newOrderAddressFields on Address {
    city
    country
    id
    name
    nameAddition
    street
    streetAddition
    zipcode
  }
`

export const GET_EMPLOYEE = gql`
  ${FRAGMENT_ADDRESS_FIELDS}
  query getEmployee($id: ID!) {
    employee(id: $id) {
      id
      canOrderNewDevice
      canOrder
      canUpgradeDevice
      office {
        address {
          ...newOrderAddressFields
        }
        locationName
      }
      isCYOD
      contact {
        firstName
        lastName
        email
        address {
          id
          city
          country
          name
          nameAddition
          street
          zipcode
        }
        type
      }
      exitDate
      externalReference
      limitationRules {
        limitationRule {
          active
          available
          pending
          type
        }
      }
      caseitems {
        totalCount
        caseItems {
          id
          assignedEmployee {
            id
            contact {
              id
              firstName
              lastName
            }
          }
          caseType
          created
          data {
            requestedRentCategory {
              deviceDefinition {
                id
                imageURL
                name
                productType
              }
            }
          }
          parentCaseItem {
            caseType
            created
            id
            status
          }
          status
        }
      }
      rentals {
        totalCount
        rentals {
          id
          deviceID
          startAt
          coPaymentAmount
          costCenter {
            name
          }
          isBARBContract
          isEarlyUpgradeEligible
          isUpgradeEligible
          isUpgradePending
          minimumContractDuration
          stocklistItem {
            id
            imei
            deviceDefinition {
              id
              name
              imageURL
              productType
            }
          }
          rentCategory {
            copaymentNet
            copaymentGross
            name
            upgradeAmount
            deviceDefinitions {
              totalCount
              deviceDefinitions {
                codename
                id
                imageURL
                manufacturer
                storage
                productType
              }
            }
          }
          rentalProlonging {
            validFrom
          }
        }
      }
      phonePlans {
        totalCount
        phonePlans {
          id
          startAt
          endAt
          simcardNumber
          phonePlanTariff {
            name
          }
        }
      }
      orderItems {
        orderItems {
          id
          created
          order {
            id
            status
          }
          product {
            id
            title
            deviceDefinition {
              id
              imageURL
            }
          }
        }
      }
      organisation {
        id
        isCYOD
        contact {
          id
          address {
            ...newOrderAddressFields
          }
        }
        emailConfig {
          activationEmails
        }
        fullName
        individualTermsEN
        individualTermsDE
        isPlusOrganisation
        logoImageURL
        portalContent {
          dashboardHeaderEN
          dashboardHeaderDE
          dashboardSubtitleEN
          dashboardSubtitleDE
          dashboardButtonEN
          dashboardButtonDE
          dashboardImageURL
          faqCardURLEN
          faqCardURLDE
        }
        shortName
        currency
        impactFactors {
          status
          factors {
            name
            value
            valueConverted
            converter
          }
        }
        fulfillmentProviderMapping {
          country
          URLHash
        }
      }
      openNewDeviceCaseItems {
        deviceDefinition {
          codename
          manufacturer
          storage
        }
        rentCategory {
          id
          copaymentNet
          copaymentGross
          deviatingAdvertisementPrice
          descriptionDe
          descriptionEn
          name
          individualTerms
          displayedBasisAmount
          upgradeAmount
          isFixedBundle
          displaySortOrder
          deviceDefinitions {
            totalCount
            deviceDefinitions {
              codename
              id
              imageURL
              manufacturer
              storage
              productType
            }
          }
        }
      }
      rentCategories {
        rentcategories {
          deviceDefinitions {
            deviceDefinitions {
              productType
            }
          }
        }
      }
    }
  }
`

export const GET_EMPLOYEE_DASHBOARD = gql`
  query getEmployeeDashboard($id: ID!) {
    employee(id: $id) {
      id
      canOrderNewDevice
      canOrder
      canUpgradeDevice
      caseitems {
        totalCount
        caseItems {
          id
          data {
            requestedRentCategory {
              deviceDefinition {
                id
                imageURL
                name
                productType
              }
            }
          }
          status
          created
          caseType
        }
      }
      limitationRules {
        limitationRule {
          available
          type
        }
      }
      orderItems {
        orderItems {
          id
          created
          order {
            id
            status
          }
          product {
            id
            title
            deviceDefinition {
              id
              imageURL
            }
          }
        }
      }
      rentals {
        rentals {
          id
          actions(isOrganisationView: false) {
            showEarlyUpgradeAction
            showStandardUpgradeAction
            showDeviceProlongingAction
          }
          deviceID
          rentalProlonging {
            validFrom
          }
          startAt
          stocklistItem {
            id
            imei
            deviceDefinition {
              id
              name
              imageURL
              productType
            }
          }
        }
      }
      rentCategories {
        rentcategories {
          deviceDefinitions {
            deviceDefinitions {
              productType
            }
          }
        }
      }
    }
  }
`
