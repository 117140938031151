import { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { GroupBase } from 'react-select'
import { useApolloClient } from '@apollo/client'
import { debounce } from 'lodash-es'

import { Field } from 'Components/Field/Field'
import { FieldTypeEnum } from 'Components/Field/Field.types'
import { SelectOption } from 'Components/Select/Select.types'

import {
  getAssignableDevices,
  getAssignableDevices_assignableDevices_stocklistItems,
  getAssignableDevicesVariables,
} from 'Constants/graphql/queries/listing/__generated__/getAssignableDevices'
import {
  getDepotDevices,
  getDepotDevices_depot_stocklistItems_stocklistItems,
  getDepotDevicesVariables,
} from 'Constants/graphql/queries/listing/__generated__/getDepotDevices'
import { GET_ASSIGNABLE_DEVICES } from 'Constants/graphql/queries/listing/GetAssignableDevices'
import { GET_DEPOT_DEVICES } from 'Constants/graphql/queries/listing/GetDevices'

import { DepotDeviceSelectProps } from './DepotDeviceSelect.types'

export const DepotDeviceSelect = <
  Option extends SelectOption,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: DepotDeviceSelectProps<Option, IsMulti, Group>,
) => {
  const apolloClient = useApolloClient()
  const { depotId, employeeId, isAssignDeviceModal, ...rest } = props

  const promiseOptions = useCallback(
    async (inputValue: string) => {
      const response = await apolloClient.query<
        getDepotDevices | getAssignableDevices,
        getDepotDevicesVariables | getAssignableDevicesVariables
      >({
        query: isAssignDeviceModal ? GET_ASSIGNABLE_DEVICES : GET_DEPOT_DEVICES,
        variables:
          isAssignDeviceModal && !!employeeId
            ? {
                depotId,
                employeeId,
                searchTerm: inputValue,
              }
            : {
                id: depotId,
                searchTerm: inputValue,
              },
      })

      const { data } = response

      let stocklistItems:
        | getDepotDevices_depot_stocklistItems_stocklistItems[]
        | getAssignableDevices_assignableDevices_stocklistItems[]
        | null
        | undefined = []

      if ('depot' in data) {
        stocklistItems = data.depot.stocklistItems?.stocklistItems
      }

      if ('assignableDevices' in data) {
        stocklistItems = data.assignableDevices.stocklistItems
      }

      const parsedResponse: SelectOption[] =
        stocklistItems?.map(stocklistItem => {
          const { deviceDefinition } = stocklistItem
          const label = stocklistItem?.imei
            ? `${deviceDefinition?.name} IMEI ${stocklistItem?.imei}`
            : `${deviceDefinition?.name}`

          return {
            label,
            value: stocklistItem.id.toString(),
          }
        }) ?? []

      return new Promise<SelectOption[]>(resolve => {
        resolve(parsedResponse)
      })
    },
    [apolloClient, depotId, employeeId, isAssignDeviceModal],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      promiseOptions(inputText).then(options => callback(options))
    }, 300),
    [promiseOptions],
  )

  const defaultNoOptionsMessage = ({ inputValue }: { inputValue: string }) => {
    if (inputValue.length < 2) {
      return (
        <FormattedMessage
          id="GlobalSearch_InputValidationMessage"
          values={{ limit: 3 }}
        />
      )
    }

    return <FormattedMessage id="No_devices_found" />
  }

  return (
    <Field
      {...rest}
      placeholder="Device Id, Apple iPhone 8, Samsung Galaxy S9..."
      selectProps={{
        isClearable: true,
        loadOptions,
        noOptionsMessage: defaultNoOptionsMessage,
      }}
      type={FieldTypeEnum.selectAsync}
    />
  )
}
