import { clsx } from 'clsx'

import { Icon } from 'Components/Icon/Icon'

import { Heading } from '../Heading/Heading'
import { CARD_HEADER_ICON } from './CardHeader.constants'
import { CardHeaderProps } from './CardHeader.types'

export const CardHeader = (props: CardHeaderProps) => {
  const {
    children,
    className,
    color = 'green',
    e2eSelector,
    icon,
    iconRight = false,
    number,
    subtitle,
    rightContent,
  } = props

  const classNames = clsx('CardHeader', className)

  return (
    <div className={classNames} data-e2e={e2eSelector}>
      <div className="CardHeader-container">
        {icon && (
          <div
            className={clsx(
              'CardHeader-icon-column',
              iconRight && 'CardHeader-icon-column-right',
            )}
          >
            <div
              className={clsx(`CardHeader-icon CardHeader-icon-${color}`)}
              data-testid={CARD_HEADER_ICON}
            >
              <Icon icon={icon} size={24} />
            </div>
          </div>
        )}

        {number && (
          <div className={clsx('CardHeader-icon-column')}>
            <div
              className={clsx(
                `CardHeader-icon CardHeader-icon-${color} CardHeader-icon-number`,
              )}
              data-testid={CARD_HEADER_ICON}
            >
              {number}
            </div>
          </div>
        )}

        <div
          className={clsx('CardHeader-content-column', !icon && 'mt-6 mb-4')}
        >
          <div className="CardHeader-title">
            <Heading as={icon ? 5 : 4} size={2}>
              {children}
            </Heading>

            <p
              className={clsx(
                'CardHeader-subtitle',
                !children && 'CardHeader-subtitle-muted',
              )}
            >
              {subtitle}
            </p>
          </div>
        </div>

        {rightContent && !iconRight && (
          <div className="CardHeader-right-content-column">
            <div className="CardHeader-right-content">{rightContent}</div>
          </div>
        )}
      </div>
    </div>
  )
}
