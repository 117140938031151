import { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { useMediaQuery } from '@react-hookz/web'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { Table } from 'Components/_theme'
import { FormatDate } from 'Components/FormatDate/FormatDate'
import { ImageWithFallback } from 'Components/ImageWithFallback/ImageWithFallback'
import { RentalStatusBadge } from 'Components/RentalStatusBadge/RentalStatusBadge'
import { RentalStatus } from 'Components/RentalStatusBadge/RentalStatusBadge.types'

import { useIsOrganisation } from 'Hooks'

import { buttonize, getFullContactName } from 'Utilities'

import { PORTAL_BREAKPOINTS } from 'Constants/constants'
import { E2E_TABLE_LINK_RENTALS } from 'Constants/e2e'

import { DeviceTypeEnum } from 'Types/deviceTypes'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import {
  EmployeeRentalsWithUrl,
  OrganisationRentalsWithUrl,
  RentalsTableProps,
} from './RentalsTable.types'

export const RentalsTable = observer((props: RentalsTableProps) => {
  const {
    rentals,
    isActiveEmployee,
    isLoading,
    isSearching,
    onOpenPreviewColumn,
    selectedRowIndex,
  } = props

  const isOrganisation = useIsOrganisation()
  const { employeeId, organisationId } = useParams<{
    employeeId: string
    organisationId: string
  }>()

  const routerHistory = useHistory()
  const isTablet = useMediaQuery(`(min-width: ${PORTAL_BREAKPOINTS.LG})`)

  const handleEnterDetails = useCallback(
    (
      _event:
        | React.MouseEvent<HTMLDivElement>
        | React.KeyboardEvent<HTMLDivElement>,
      id: string,
      url: string,
      rowIndex: number,
    ) => {
      if (!isTablet) {
        routerHistory.push(url)

        return
      }

      if (typeof onOpenPreviewColumn === 'function') {
        onOpenPreviewColumn(id, rowIndex)
      }
    },
    [isTablet, onOpenPreviewColumn, routerHistory],
  )

  const renderNameHeader = useCallback(
    () => <FormattedMessage key="name" id="DeviceListContent_header__name" />,
    [],
  )

  const renderEmployeeHeader = useCallback(
    () => (
      <FormattedMessage key="name" id="DeviceListContent_header__employee" />
    ),
    [],
  )

  const renderStartDateHeader = useCallback(
    () => (
      <FormattedMessage key="name" id="DeviceListContent_header__start_date" />
    ),
    [],
  )

  const renderNameCell = useCallback(
    ({
      cell,
    }: CellContext<
      EmployeeRentalsWithUrl | OrganisationRentalsWithUrl,
      unknown
    >) => {
      const rental = cell.row.original

      return (
        <div
          className="d-inline-flex"
          {...buttonize(
            handleEnterDetails,
            rental.id,
            rental.url,
            cell.row.index,
          )}
        >
          <div className="d-flex justify-content-center align-items-center me-4 Table-device-image">
            <ImageWithFallback
              alt={rental.stocklistItem?.deviceDefinition?.name}
              placeholderType={
                rental.stocklistItem?.deviceDefinition
                  ?.productType as DeviceTypeEnum
              }
              src={rental.stocklistItem?.deviceDefinition?.imageURL || ''}
            />
          </div>

          <div className="d-flex flex-column justify-content-center text-ellipsis">
            {(rental.actions.showStandardUpgradeAction ||
              rental.actions.showDeviceProlongingAction) && (
              <div>
                <RentalStatusBadge status={RentalStatus.new_device} />
              </div>
            )}

            {rental.actions.showEarlyUpgradeAction && (
              <div className="mb-1">
                <RentalStatusBadge status={RentalStatus.upgrade} />
              </div>
            )}

            <div className="d-flex align-items-center">
              <strong className="text-ellipsis">
                <span>{rental.stocklistItem?.deviceDefinition?.name}</span>
              </strong>
            </div>

            <div className="d-flex table-text-subtitle text-muted">
              {rental.stocklistItem?.imei ? (
                <>
                  <span>IMEI:</span>

                  <span className="text-ellipsis">
                    {rental.stocklistItem.imei}
                  </span>
                </>
              ) : (
                <>
                  <span>SN:</span>

                  <span className="text-ellipsis">
                    {rental.stocklistItem?.serialNumber ?? '-'}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      )
    },
    [handleEnterDetails],
  )

  const renderExpandableContent = useCallback(
    ({
      cell,
    }: CellContext<
      EmployeeRentalsWithUrl | OrganisationRentalsWithUrl,
      unknown
    >) => {
      const rental = cell.row.original

      return (
        <div className="w-100 Table-details">
          {!isActiveEmployee && (
            <div className="Table-details-row">
              <span className="Table-row--expandable-title">
                {renderEmployeeHeader()}
              </span>

              <span className="text-ellipsis">
                {getFullContactName(rental.employee?.contact)}
              </span>
            </div>
          )}

          <div className="Table-details-row">
            <span className="Table-row--expandable-title">
              {renderStartDateHeader()}
            </span>

            <span>
              <FormatDate value={new Date(rental.startAt)} />
            </span>
          </div>
        </div>
      )
    },
    [isActiveEmployee, renderEmployeeHeader, renderStartDateHeader],
  )

  const renderEmployeeCell = useCallback(
    ({
      cell,
    }: CellContext<
      EmployeeRentalsWithUrl | OrganisationRentalsWithUrl,
      unknown
    >) => {
      const rental = cell.row.original

      return (
        <div
          className="d-flex align-items-center h-100"
          {...buttonize(
            handleEnterDetails,
            rental.id,
            rental.url,
            cell.row.index,
          )}
        >
          <span className="text-ellipsis">
            {rental.employee?.contact?.firstName
              ? getFullContactName(rental.employee.contact)
              : '-'}
          </span>
        </div>
      )
    },
    [handleEnterDetails],
  )

  const renderStartDateCell = useCallback(
    ({
      cell,
    }: CellContext<
      EmployeeRentalsWithUrl | OrganisationRentalsWithUrl,
      unknown
    >) => {
      const rental = cell.row.original

      return (
        <div
          className="d-flex align-items-center h-100"
          {...buttonize(
            handleEnterDetails,
            rental.id,
            rental.url,
            cell.row.index,
          )}
        >
          <FormatDate value={new Date(rental.startAt)} />
        </div>
      )
    },
    [handleEnterDetails],
  )

  const columns = useMemo<
    ColumnDef<EmployeeRentalsWithUrl | OrganisationRentalsWithUrl>[]
  >(() => {
    const tableColumns: ColumnDef<
      EmployeeRentalsWithUrl | OrganisationRentalsWithUrl
    >[] = [
      {
        cell: renderNameCell,
        header: renderNameHeader,
        id: 'name',
        meta: {
          ExpandableContent: renderExpandableContent,
        },
      },
      {
        cell: renderEmployeeCell,
        header: renderEmployeeHeader,
        id: 'employee',
      },
      {
        cell: renderStartDateCell,
        header: renderStartDateHeader,
        id: 'start_date',
      },
    ]

    return tableColumns.filter(column => {
      // don't show employee when logged in as employee - they are the same
      if (isActiveEmployee && column.id === 'employee') {
        return false
      }

      return true
    })
  }, [
    isActiveEmployee,
    renderEmployeeCell,
    renderEmployeeHeader,
    renderExpandableContent,
    renderNameCell,
    renderNameHeader,
    renderStartDateCell,
    renderStartDateHeader,
  ])

  const data = useMemo<EmployeeRentalsWithUrl[] | OrganisationRentalsWithUrl[]>(
    () =>
      rentals.map(rental => {
        const url = composePath({
          isOrganisation,
          params: {
            employeeId,
            organisationId,
            rentalId: rental.id,
          },
          paths: {
            employee: GLOBAL_ROUTE_PATHS.employee.rental,
            organisation: GLOBAL_ROUTE_PATHS.organisation.rental,
          },
        })

        return {
          ...rental,
          url,
        }
      }),
    [employeeId, isOrganisation, organisationId, rentals],
  )

  return (
    <Table
      className={clsx(
        'Table--rentals',
        isOrganisation ? 'Table--isAdmin' : 'Table--isEmployee',
      )}
      columns={columns}
      data={data}
      e2eRowId={E2E_TABLE_LINK_RENTALS}
      isLoading={isLoading}
      isSearching={isSearching}
      selectedRowIndex={selectedRowIndex}
    />
  )
})
