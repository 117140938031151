import {
  getEmployeeDashboard_employee_caseitems,
  getEmployeeDashboard_employee_rentals_rentals,
} from 'Constants/graphql/queries/employee/__generated__/getEmployeeDashboard'

import { CaseItemStatus } from 'Portal/__generated__/globalTypes'

export type DashboardDevicesProps = {
  caseItems?: getEmployeeDashboard_employee_caseitems | null
  header: string
  rentals?: getEmployeeDashboard_employee_rentals_rentals[] | null
  renderServiceApp?: boolean
}

export type DashboardDeviceSlideProps = {
  imageURL?: string | null
  name?: string
  productType?: string | null
  id?: string
  created?: any
  status?: `${CaseItemStatus}`
  imei?: string | null
  startAt?: any
  type: DashboardDeviceSlideTypeEnum
  isUpgradable?: boolean
  isEarlyUpgradable?: boolean
}

export enum DashboardDeviceSlideTypeEnum {
  Rental,
  Request,
}
