import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

export enum TrackingStatusEnum {
  transit = 'transit',
  delivered = 'delivered',
  failure = 'failure',
}

export interface TrackingStatusModelItem {
  icon: IconDefinition
  translationId: { [key: string]: string }
}

export type TrackingStatusModel = {
  [key: string]: TrackingStatusModelItem
}
