import { IntlShape } from 'react-intl'
import { CurrentRefinementsConnectorParamsRefinement } from 'instantsearch.js/es/connectors/current-refinements/connectCurrentRefinements'

import { dashOrSpaceToUnderscore } from 'Utilities'

import { RETURN_STATUS_TRANSLATION_IDS } from 'Constants/returnStatus'

import { ReturnCaseStatus } from 'Portal/__generated__/globalTypes'
import { formatTimestampToDate } from 'Portal/src/utilities/dates/dates.utils'

import { DATE_ATTRIBUTES, FILTER_SEPARATORS } from './ActiveFilters.constants'

const isDate = (attribute: string) =>
  DATE_ATTRIBUTES.some(string => attribute.includes(string))

const isBoolean = (attribute: string) =>
  attribute.includes('is_') || attribute.includes('has_')

export const getSeparator = (
  refinements: CurrentRefinementsConnectorParamsRefinement[],
) => {
  const { attribute, type, value } = refinements[0]

  if (isDate(attribute)) {
    if (value === -1) {
      return FILTER_SEPARATORS.NONE
    }

    return FILTER_SEPARATORS.DATE
  }

  return type === 'numeric'
    ? FILTER_SEPARATORS.NUMERIC
    : FILTER_SEPARATORS.DEFAULT
}

export const parseRefinement = (
  refinement: CurrentRefinementsConnectorParamsRefinement,
  intl: IntlShape,
) => {
  const { attribute, label, value } = refinement

  if (isDate(attribute)) {
    if (value === -1) {
      return null
    }

    return formatTimestampToDate(value)
  }

  if (isBoolean(attribute) && typeof value === 'number') {
    return value === 1
      ? intl.formatMessage({ id: 'Yes' })
      : intl.formatMessage({ id: 'No' })
  }

  return label
}

export const parseReturnCaseStatusRefinements = (
  refinements: CurrentRefinementsConnectorParamsRefinement[],
  intl: IntlShape,
) =>
  refinements.reduce((result, item) => {
    const { value } = item

    const formattedStatus = dashOrSpaceToUnderscore(value.toString())
    const formattedLabel = intl.formatMessage({
      id: RETURN_STATUS_TRANSLATION_IDS[
        formattedStatus as keyof typeof ReturnCaseStatus
      ],
    })

    if (!result.includes(formattedLabel)) {
      return result.length > 0
        ? result.concat(`, ${formattedLabel}`)
        : formattedLabel
    }

    return result
  }, '')
