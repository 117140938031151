import { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { PreviewColumn } from 'Components/PreviewColumn/PreviewColumn'
import { PreviewColumnTypeEnum } from 'Components/PreviewColumn/PreviewColumn.types'
import { PreviewColumnContent } from 'Components/PreviewColumn/PreviewColumnContent'
import { PreviewColumnHeader } from 'Components/PreviewColumn/PreviewColumnHeader'

import {
  getPhonePlanListPreviewColumn,
  getPhonePlanListPreviewColumnVariables,
} from 'Constants/graphql/queries/detail/__generated__/getPhonePlanListPreviewColumn'
import { GET_PHONEPLAN_LIST_PREVIEW_COLUMN } from 'Constants/graphql/queries/detail/GetPhonePlanDetails'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import { PhonePlansPreviewColumnProps } from './PhonePlansPreviewColumn.types'
import { generatePhonePlansPreviewColumnContent } from './PhonePlansPreviewColumn.utils'

export const PhonePlansPreviewColumn = (
  props: PhonePlansPreviewColumnProps,
) => {
  const { entityId, onClosePreviewColumn, isVisible } = props
  const intl = useIntl()
  const { organisationId } = useParams<{
    organisationId?: string
  }>()

  const path = composePath({
    params: {
      organisationId,
      phonePlanId: entityId || '0',
    },
    path: GLOBAL_ROUTE_PATHS.organisation.phonePlan,
  })

  const [getPhonePlansPreviewData, { loading: isLoading, data }] = useLazyQuery<
    getPhonePlanListPreviewColumn,
    getPhonePlanListPreviewColumnVariables
  >(GET_PHONEPLAN_LIST_PREVIEW_COLUMN)

  const generateContent = useMemo(
    () => generatePhonePlansPreviewColumnContent(data?.phoneplan),
    [data?.phoneplan],
  )

  useEffect(() => {
    if (!entityId) {
      return
    }

    getPhonePlansPreviewData({
      variables: {
        id: entityId,
      },
    })
  }, [entityId, getPhonePlansPreviewData])

  return (
    <PreviewColumn
      contentElement={<PreviewColumnContent content={generateContent} />}
      headerElement={
        <PreviewColumnHeader
          image={data?.phoneplan.phonePlanTariff?.logo}
          name={
            data?.phoneplan.phonePlanTariff?.name ??
            intl.formatMessage({ id: 'Unnamed_phone_plan' })
          }
          onClosePreviewColumn={onClosePreviewColumn}
          title={intl.formatMessage({ id: 'Phone_plan' })}
        />
      }
      id={entityId}
      isLoading={isLoading}
      isVisible={isVisible}
      onClosePreviewColumn={onClosePreviewColumn}
      type={PreviewColumnTypeEnum.PhonePlan}
      url={path}
    />
  )
}
