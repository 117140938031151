import { forwardRef } from 'react'
import { clsx } from 'clsx'

import { SwitchProps } from './Switch.types'

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  (props, ref) => {
    const {
      className,
      disabled,
      id,
      label,
      size = 'medium',
      ...restProps
    } = props

    const classNames = clsx(
      'Switch d-flex align-items-center',
      className,
      size === 'medium' ? 'Switch-medium' : 'Switch-small',
      disabled && 'Switch-disabled',
    )

    return (
      <div className={classNames}>
        <label className="Switch-label" htmlFor={id}>
          <input
            ref={ref}
            aria-label={label}
            disabled={disabled}
            id={id}
            type="checkbox"
            {...restProps}
          />

          <span className="Switch-slider" />
        </label>

        {label}
      </div>
    )
  },
)
