import { PortalContentStore } from 'Portal/src/stores/portalContentStore/portalContentStore'

import { isValidURL } from '../isValidURL/isValidURL.util'

export const getFAQLink = (
  isGermanLanguageActive: boolean,
  portalContent: PortalContentStore['portalContent'],
): string => {
  if (
    isGermanLanguageActive &&
    portalContent?.faqCardURLDE &&
    isValidURL(portalContent?.faqCardURLDE)
  ) {
    return portalContent.faqCardURLDE
  }

  if (
    !isGermanLanguageActive &&
    portalContent?.faqCardURLEN &&
    isValidURL(portalContent?.faqCardURLEN)
  ) {
    return portalContent.faqCardURLEN
  }

  return 'https://help.everphone.app/'
}
