import { IntlShape } from 'react-intl'
import { Chart, ChartEvent, ChartOptions } from 'chart.js'
import { isNumber, sum } from 'lodash-es'
import { DateTime } from 'luxon'

import { I18nConfig } from 'Stores/portalSettingsStore/portalSettingsStore.types'

import { dashOrSpaceToUnderscore } from 'Utilities'

import { CASE_ITEM_TYPE_TRANSLATION_IDS } from 'Constants/caseItemType'
import { CHART_COLORS } from 'Constants/charts'

import { CaseItemOverview } from 'Types/dashboard/organisationDashboard.types'

import { CaseItemType } from 'Portal/__generated__/globalTypes'

import { STATS_LABEL_DATE_FORMAT } from '../DashboardStatisticCards/DashboardStatisticsCards.constants'
import { OrdersReportCardChartDataset } from './OrdersReportCardChart.types'

export const generateOrdersReportCardChartDatasets = (
  caseItemOverview: CaseItemOverview[] | null,
  intl: IntlShape,
  i18n: I18nConfig,
) => {
  const output: OrdersReportCardChartDataset = {
    data: {},
    labels: [],
  }

  caseItemOverview?.forEach(item => {
    const { month } = item
    const { caseType } = item
    const { count } = item

    const monthLabel =
      DateTime.fromFormat(month || '', STATS_LABEL_DATE_FORMAT, {
        locale: i18n.locale,
      }).monthShort || month

    if (!output.labels.includes(monthLabel)) {
      output.labels.push(monthLabel)
    }

    if (!output.data[caseType]) {
      output.data[caseType] = {
        data: [],
      }
    }

    const monthIndex = output.labels.indexOf(monthLabel)

    output.data[caseType].data[monthIndex] = count
  })

  Object.keys(output.data).forEach(caseType => {
    const { data } = output.data[caseType]

    output.labels.forEach((_, index) => {
      if (typeof data[index] === 'undefined') {
        data[index] = 0
      }
    })
  })

  const datasets = Object.entries(output.data).map((item, i) => ({
    backgroundColor: 'rgba(0, 0, 0, 0.0)',
    borderColor: CHART_COLORS[i],
    data: item[1].data,
    fill: true,
    label: intl.formatMessage({
      id: CASE_ITEM_TYPE_TRANSLATION_IDS[
        CaseItemType[dashOrSpaceToUnderscore(item[0]) as CaseItemType]
      ],
    }),
    pointBackgroundColor: CHART_COLORS[i],
  }))

  const { labels } = output

  const sortedDatasets = datasets.sort((a, b) => {
    if (sum(a.data) > sum(b.data)) {
      return -1
    }

    if (sum(a.data) < sum(b.data)) {
      return 1
    }

    return 0
  })

  return { labels, sortedDatasets }
}

export const generateOrdersReportCardChartLegendPlugin = (
  intl: IntlShape,
  i18n: I18nConfig,
) => ({
  afterUpdate(chart: Chart<'line'>, args: any, options: ChartOptions<'line'>) {
    const legendContainer = document.getElementById(options.containerID!)

    let legendContainerList = legendContainer?.querySelector('ul')

    if (!legendContainerList) {
      legendContainerList = document.createElement('ul')
      legendContainerList.setAttribute(
        'class',
        'DashboardOrdersReportCard-legend-container',
      )

      legendContainer?.appendChild(legendContainerList)
    }

    while (legendContainerList.firstChild) {
      legendContainerList.firstChild.remove()
    }

    if (
      typeof chart.options.plugins?.legend?.labels?.generateLabels !==
      'function'
    ) {
      return
    }

    const legendItems =
      chart.options.plugins.legend.labels.generateLabels(chart)

    legendItems.forEach((item, index) => {
      if (typeof item.datasetIndex !== 'number') {
        return
      }

      // Create check box
      const checkbox = document.createElement('label')

      checkbox.setAttribute('class', 'Checkbox')

      const input = document.createElement('input')

      input.setAttribute('type', 'checkbox')

      input.setAttribute(
        'aria-labelledby',
        `OrdersReportCardChartLegendLabel-${index}`,
      )

      if (chart.isDatasetVisible(item.datasetIndex)) {
        input.setAttribute('checked', '')
      }

      checkbox.appendChild(input)

      // Create legend item
      const legendItem = document.createElement('li')

      legendItem.setAttribute('class', 'DashboardOrdersReportCard-legend-item')

      input.onclick = (event: MouseEvent) => {
        event.preventDefault()
      }

      legendItem.onclick = () => {
        const isChecked = input.hasAttribute('checked')

        if (
          isChecked &&
          legendItems.filter(item => !item.hidden).length === 1
        ) {
          return
        }

        chart.setDatasetVisibility(
          item.datasetIndex!,
          !chart.isDatasetVisible(item.datasetIndex!),
        )

        if (isChecked) {
          input.removeAttribute('checked')
        } else {
          input.setAttribute('checked', '')
        }

        chart.update(args)
      }

      // Create color box
      const boxSpan = document.createElement('span')

      boxSpan.style.background = item.strokeStyle?.toString() || ''
      boxSpan.style.outline = `${item.strokeStyle}80 solid 4px`

      boxSpan.setAttribute('class', 'DashboardOrdersReportCard-color-box')

      const detailsContainer = document.createElement('div')

      detailsContainer.setAttribute(
        'class',
        'DashboardOrdersReportCard-legend-details-container',
      )

      const nameContainer = document.createElement('p')

      nameContainer.setAttribute(
        'class',
        'DashboardOrdersReportCard-legend-name',
      )

      nameContainer.setAttribute(
        'id',
        `OrdersReportCardChartLegendLabel-${index}`,
      )

      // Create name
      const name = document.createTextNode(`${item.text}`)

      nameContainer.appendChild(name)

      detailsContainer.appendChild(nameContainer)

      const totalContainer = document.createElement('p')

      totalContainer.setAttribute(
        'class',
        'DashboardOrdersReportCard-legend-total',
      )

      // Create total
      const total = document.createTextNode(
        `${intl.formatMessage(
          {
            id: 'Total_orders',
          },
          {
            total: sum(chart.data.datasets[index].data).toLocaleString(
              i18n.locale,
            ),
          },
        )}`,
      )

      totalContainer.appendChild(total)

      detailsContainer.appendChild(totalContainer)

      // Append values to legend item and then append legend item to legend
      legendItem.appendChild(checkbox)
      legendItem.appendChild(boxSpan)
      legendItem.appendChild(detailsContainer)
      legendContainerList?.appendChild(legendItem)
    })
  },
  id: 'htmlLegend',
})

export const ordersReportCardChartMouseLinePlugin = {
  afterDraw(chart: Chart<'line'>) {
    const { ctx, chartArea } = chart
    const { bottom, top } = chartArea
    const { mouseLine } = chart.options

    if (!mouseLine) {
      return
    }

    const { x, color } = mouseLine

    if (isNumber(x)) {
      ctx.save()
      ctx.strokeStyle = color || ''
      ctx.lineWidth = 1
      ctx.moveTo(x, bottom)
      ctx.lineTo(x, top)
      ctx.stroke()
      ctx.restore()
    }
  },
  afterEvent(chart: Chart<'line'>, { event }: { event: ChartEvent }) {
    const { x, y } = event
    const { left, top, right, bottom } = chart.chartArea
    const { mouseLine } = chart.options

    if (!mouseLine || !x || !y) {
      return
    }

    if (
      x >= left &&
      y >= top &&
      x <= right &&
      y <= bottom &&
      chart.getActiveElements()[0] &&
      chart.getActiveElements()[0].element
    ) {
      const { element } = chart.getActiveElements()[0]

      mouseLine.x = element.x
    } else {
      mouseLine.x = NaN
    }
  },
  id: 'mouseLine',
}
