import { gql } from '@apollo/client'

export const GET_REQUESTED_REPORT_STATUS = gql`
  query getRequestedReportStatus(
    $reportType: ReportType!
    $requestUserID: ID!
    $requestOrganizationID: ID!
  ) {
    getRequestedReportStatus(
      reportType: $reportType
      requestUserID: $requestUserID
      requestOrganizationID: $requestOrganizationID
    ) {
      uuid
      status
      fileName
      generatedAt
    }
  }
`
