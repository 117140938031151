import { useCallback, useMemo } from 'react'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react'
import { A11y, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useStore } from 'Stores/index'

import { useActiveUser, useTheme } from 'Hooks'

import { SLIDER_BREAKPOINTS_ADMIN_DASHBOARD } from 'Constants/constants'

import { ContentTypeEnum } from 'Types/content/content.types'
import { MonthlyCount } from 'Types/dashboard/organisationDashboard.types'

import { DashboardStatisticCard } from '../DashboardStatisticCard/DashboardStatisticCard'
import { DashboardStatisticCardsProps } from './DashboardStatisticCards.types'
import { STATS_LABEL_DATE_FORMAT } from './DashboardStatisticsCards.constants'
import { shouldShowPhonePlanStatisticCard } from './DashboardStatisticsCards.utils'

export const DashboardStatisticCards = observer(
  (props: DashboardStatisticCardsProps) => {
    const { isHDDesktop } = props
    const { dashboardStore, portalSettingsStore } = useStore()
    const { rentalStats, phonePlanStats, employeeCounts, caseItemCounts } =
      dashboardStore
    const { i18n } = portalSettingsStore
    const { isTelekomTheme } = useTheme()
    const { activeOrganisation } = useActiveUser()

    const rentalCount = rentalStats?.monthlyCounts.at(-1)?.count || 0
    const phonePlanCount = phonePlanStats?.monthlyCounts.at(-1)?.count || 0

    const isPhonePlansShown = shouldShowPhonePlanStatisticCard(
      isTelekomTheme,
      activeOrganisation,
    )

    const getStatsLabels = useCallback(
      (monthlyCounts?: MonthlyCount[]) =>
        monthlyCounts?.map(
          count =>
            DateTime.fromFormat(count.month, STATS_LABEL_DATE_FORMAT, {
              locale: i18n.locale,
            }).monthShort || '',
        ),
      [i18n.locale],
    )

    const getStatsCounts = (monthlyCounts?: MonthlyCount[]) =>
      monthlyCounts?.map(count => count.count)

    const renderRentals = useMemo(() => {
      if (rentalCount) {
        return (
          <DashboardStatisticCard
            contentType={ContentTypeEnum.rentals}
            count={rentalCount}
            data={getStatsCounts(rentalStats?.monthlyCounts) || []}
            labels={getStatsLabels(rentalStats?.monthlyCounts) || []}
            progress={rentalStats?.percentageChange || 0}
            title="Active_rentals"
          />
        )
      }

      return null
    }, [getStatsLabels, rentalCount, rentalStats])

    const renderPhonePlans = useMemo(() => {
      if (isPhonePlansShown) {
        return (
          <DashboardStatisticCard
            contentType={ContentTypeEnum.phone_plans}
            count={phonePlanCount}
            data={getStatsCounts(phonePlanStats?.monthlyCounts) || []}
            labels={getStatsLabels(phonePlanStats?.monthlyCounts) || []}
            progress={phonePlanStats?.percentageChange || 0}
            title={phonePlanCount > 0 ? 'Active_phone_plans' : 'Phone_plans'}
          />
        )
      }

      return null
    }, [getStatsLabels, phonePlanCount, phonePlanStats, isPhonePlansShown])

    const renderEmployees = useMemo(
      () => (
        <DashboardStatisticCard
          contentType={ContentTypeEnum.employees}
          count={employeeCounts?.employeeCount || 0}
          secondaryCount={employeeCounts?.newJoinerCount}
          secondaryTitle="New_joiners_this_month"
          title="Active_employees"
        />
      ),
      [employeeCounts],
    )

    const renderCaseItems = useMemo(
      () => (
        <DashboardStatisticCard
          contentType={ContentTypeEnum.caseitems}
          count={caseItemCounts?.orderCount || 0}
          secondaryCount={caseItemCounts?.repairCount}
          secondaryTitle="Open_repairs"
          title="Open_orders"
        />
      ),
      [caseItemCounts],
    )

    if (!isHDDesktop) {
      return (
        <div className="DashboardStatisticCards">
          <Swiper
            breakpoints={SLIDER_BREAKPOINTS_ADMIN_DASHBOARD}
            className="swiper mt-2 w-100"
            modules={[Navigation, Pagination, A11y]}
            navigation
            pagination={{ clickable: true }}
            spaceBetween={16}
          >
            {!!rentalCount && <SwiperSlide>{renderRentals}</SwiperSlide>}

            {isPhonePlansShown && <SwiperSlide>{renderPhonePlans}</SwiperSlide>}

            <SwiperSlide>{renderEmployees}</SwiperSlide>

            <SwiperSlide>{renderCaseItems}</SwiperSlide>
          </Swiper>
        </div>
      )
    }

    return (
      <>
        {renderRentals}
        {renderPhonePlans}
        {renderEmployees}
        {renderCaseItems}
      </>
    )
  },
)
