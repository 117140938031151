import { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'

import { ButtonGroup, CardBody } from 'Components/_theme'
import { ShippingAddressForm } from 'Components/ShippingAddressForm/ShippingAddressForm'
import { ShippingAddressFormSchema } from 'Components/ShippingAddressForm/ShippingAddressForm.types'

import { REPLACE_BROKEN_DEVICE_MODAL_SHIPPING_FORM } from 'Modals/ReplaceBrokenDeviceModal/ReplaceBrokenDeviceModal.constants'
import {
  ReplaceBrokenDeviceModalStepsEnum,
  ReplaceBrokenDeviceShippingFormProps,
} from 'Modals/ReplaceBrokenDeviceModal/ReplaceBrokenDeviceModal.types'

import { E2E_REPLACE_BROKEN_DEVICE_BUTTON } from 'Constants/e2e'

export const ReplaceBrokenDeviceShippingForm = (
  props: ReplaceBrokenDeviceShippingFormProps,
) => {
  const {
    heading,
    isFirstStep,
    rental,
    shippingFormData,
    onPreviousStep,
    onSetCurrentStep,
  } = props
  const [isSubmitting, setIsSubmitting] = useState(false)
  const intl = useIntl()

  const handleSubmit = useCallback(
    (data: ShippingAddressFormSchema) => {
      onSetCurrentStep(ReplaceBrokenDeviceModalStepsEnum.SUMMARY, data)
    },
    [onSetCurrentStep],
  )

  return (
    <>
      {heading}

      <CardBody>
        <ShippingAddressForm
          defaultValues={shippingFormData}
          formId={REPLACE_BROKEN_DEVICE_MODAL_SHIPPING_FORM}
          onSetIsSubmitting={setIsSubmitting}
          onSubmit={handleSubmit}
          rental={rental}
          showOptionsHeader
          useModalLayout
        />

        <ButtonGroup
          leftButton={{
            onClick: onPreviousStep,
            text: intl.formatMessage({ id: isFirstStep ? 'Cancel' : 'Back' }),
          }}
          rightButton={{
            'data-e2e': E2E_REPLACE_BROKEN_DEVICE_BUTTON,
            disabled: isSubmitting,
            form: REPLACE_BROKEN_DEVICE_MODAL_SHIPPING_FORM,
            text: intl.formatMessage({ id: 'Next' }),
            type: 'submit',
          }}
        />
      </CardBody>
    </>
  )
}
