import { gql } from '@apollo/client'

export const GET_RETURN_CASES = gql`
  query getReturnCases(
    $id: ID!
    $first: Int!
    $after: Int!
    $isOverdue: Boolean!
  ) {
    organisation(id: $id) {
      id
      returnCases(
        pagination: { after: $after, first: $first }
        isOverdue: $isOverdue
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
        returnCases {
          id
          employee {
            id
            contact {
              id
              firstName
              lastName
            }
          }
          createdAt
          stocklistItem {
            id
            deviceDefinition {
              id
              codename
              imageURL
              manufacturer
              name
              productType
            }
          }
          status
          substatus
        }
      }
    }
  }
`
