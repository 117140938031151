import { Suspense } from 'react'
import { clsx } from 'clsx'
import { observer } from 'mobx-react'

import ErrorBoundary from 'Components/ErrorBoundary/ErrorBoundary'
import { Footer } from 'Components/Footer/Footer'
import { LeftSidebar } from 'Components/LeftSidebar/LeftSidebar'
import { MobileNavigationBar } from 'Components/MobileNavigationBar/MobileNavigationBar'
import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'
import { ScrollToTop } from 'Components/ScrollToTop/ScrollToTop'
import { Topbar } from 'Components/Topbar/Topbar'

import { useIsServiceApp } from 'Hooks'

import { AuthorisedLayoutProps } from './AuthorisedLayout.types'

export const AuthorisedLayout = observer((props: AuthorisedLayoutProps) => {
  const { children } = props
  const isServiceApp = useIsServiceApp()

  return (
    <div>
      <ScrollToTop />

      <Topbar />

      <Suspense fallback={<PageLoadingState />}>
        <LeftSidebar />
      </Suspense>

      <main className={clsx('page', isServiceApp && 'pb-0')}>
        <div className="wrapper">
          <ErrorBoundary>
            {children}

            {!isServiceApp && <Footer />}
          </ErrorBoundary>
        </div>
      </main>

      {isServiceApp && <MobileNavigationBar />}
    </div>
  )
})
