import { useCallback, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { Heading, PrimaryButton } from 'Components/_theme'
import { ImageWithFallback } from 'Components/ImageWithFallback/ImageWithFallback'
import { PageErrorState } from 'Components/PageErrorState/PageErrorState'
import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'

import { useStore } from 'Stores/index'

import { useActiveUser } from 'Hooks'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import { ServiceAppActionsPopupContainer } from './components/ServiceAppActionsPopupContainer/ServiceAppActionsPopupContainer'
import { ServiceAppActionsPopupContent } from './components/ServiceAppActionsPopupContent/ServiceAppActionsPopupContent'
import { ServiceAppActionsPopupProps } from './ServiceAppActionsPopup.types'

export const ServiceAppActionsPopup = observer(
  (props: ServiceAppActionsPopupProps) => {
    const { onClose, rentalID } = props
    const { rentalStore } = useStore()
    const {
      rentalDetails,
      fetchRentalDetails,
      isLoadingRentalDetails,
      isRentalDetailsError,
    } = rentalStore

    const { employeeId } = useParams<{
      employeeId?: string
    }>()

    const { activeEmployee } = useActiveUser()
    const history = useHistory()

    const handleRentalDetailsFetch = useCallback(() => {
      fetchRentalDetails({
        employeeId,
        isOrganisationView: false,
        rentalID,
      })
    }, [employeeId, fetchRentalDetails, rentalID])

    const handleButtonClick = () => {
      const rentalPath = composePath({
        params: {
          employeeId: activeEmployee?.id,
          rentalId: rentalDetails?.rental.id,
        },
        path: GLOBAL_ROUTE_PATHS.employee.rental,
      })

      history.push(rentalPath)

      onClose()
    }

    useEffect(() => {
      handleRentalDetailsFetch()
    }, [handleRentalDetailsFetch])

    if (isLoadingRentalDetails) {
      return (
        <ServiceAppActionsPopupContainer
          className="ServiceAppActionsPopup-loading"
          onClose={onClose}
        >
          <PageLoadingState />
        </ServiceAppActionsPopupContainer>
      )
    }

    if (isRentalDetailsError || !rentalDetails) {
      return (
        <ServiceAppActionsPopupContainer onClose={onClose}>
          <PageErrorState />
        </ServiceAppActionsPopupContainer>
      )
    }

    const { rental } = rentalDetails

    const rentalName = rental?.stocklistItem?.deviceDefinition?.name
    const rentalImage = rental?.stocklistItem?.deviceDefinition?.imageURL

    return (
      <ServiceAppActionsPopupContainer onClose={onClose}>
        <div className="ServiceAppActionsPopup-header d-flex align-items-center p-4">
          <ImageWithFallback alt={rentalName} fluid src={rentalImage || ''} />
          <Heading as={5} className="ms-4" size={2}>
            {rentalName}
          </Heading>
        </div>

        <ServiceAppActionsPopupContent rental={rental} />

        <div className="m-6 mt-4">
          <PrimaryButton className="w-100" onClick={handleButtonClick}>
            <FormattedMessage id="ServiceApp_show_details" />
          </PrimaryButton>
        </div>
      </ServiceAppActionsPopupContainer>
    )
  },
)
