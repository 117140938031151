import { gql } from '@apollo/client'

export const GET_ORGANISATION_INVOICES = gql`
  query getOrganisationInvoices(
    $id: ID!
    $first: Int!
    $after: Int!
    $searchterm: String
  ) {
    organisation(id: $id) {
      id
      invoices(
        pagination: { after: $after, first: $first }
        searchterm: $searchterm
      ) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        invoices {
          created
          invoiceNumber
          sumAmountTotal
          pdfDownloadLink
          pdfAttachmentDownloadLink
          status
        }
      }
    }
  }
`
