import type * as React from 'react'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { Input } from 'Components/_theme'

import { QUANTITY_INPUT } from './OrderSummary.constants'
import { OrderSummaryQuantityInputProps } from './OrderSummary.types'

export const OrderSummaryQuantityInput = (
  props: OrderSummaryQuantityInputProps,
) => {
  const {
    controlId,
    defaultValue,
    minQuantity = 1,
    maxQuantity,
    onChangeInput,
    canChooseOnlyMinOrMaxQuantity,
  } = props
  const [quantity, setQuantity] = useState(defaultValue)

  const intl = useIntl()

  const handleChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event
    const value = Number(target.value)

    if (value < minQuantity || (maxQuantity && value > maxQuantity)) {
      return
    }

    let valueToSet = Number(
      target.value.length > 2 ? target.value.slice(0, 2) : value,
    )

    if (
      canChooseOnlyMinOrMaxQuantity &&
      typeof minQuantity === 'number' &&
      typeof maxQuantity === 'number'
    ) {
      if (value > quantity) {
        valueToSet = maxQuantity
      } else {
        valueToSet = minQuantity
      }
    }

    setQuantity(valueToSet)
    onChangeInput(valueToSet)
  }

  useEffect(() => {
    // Change the quantity if max quantity is lower. It happens when user changes
    // main device quantity but accessory quantity input is cached here, even when
    // max quantity becomes lower.
    if (maxQuantity && maxQuantity < quantity) {
      setQuantity(maxQuantity)
    }

    if (
      canChooseOnlyMinOrMaxQuantity &&
      quantity > 0 &&
      maxQuantity &&
      maxQuantity !== quantity
    ) {
      setQuantity(maxQuantity)
    }
  }, [canChooseOnlyMinOrMaxQuantity, maxQuantity, onChangeInput, quantity])

  return (
    <Input
      aria-label={intl.formatMessage({ id: 'NewOrderSummary_quantity' })}
      autoComplete="off"
      className="px-0 text-center w-50"
      data-testid={QUANTITY_INPUT}
      id={controlId}
      max={maxQuantity}
      min={minQuantity}
      onChange={handleChangeQuantity}
      required
      step={1}
      type="number"
      // toString prevents input number type leading zeros
      // makes it 1 instead of 00001 when user types "0000number" when minQuantitiy is 0
      value={quantity.toString()}
    />
  )
}
