import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useMutation } from '@apollo/client'
import { zodResolver } from '@hookform/resolvers/zod'

import { ButtonGroup } from 'Components/_theme'
import { EmployeesSelect } from 'Components/EmployeesSelect/EmployeesSelect'
import { Form } from 'Components/Form/Form'

import {
  E2E_REASSIGN_DEVICE_EMPLOYEE_SELECT,
  E2E_REASSIGN_DEVICE_SUBMIT,
} from 'Constants/e2e'
import {
  mutationReassignDevice,
  mutationReassignDeviceVariables,
} from 'Constants/graphql/mutations/__generated__/mutationReassignDevice'
import { MUTATION_REASSIGN_DEVICE } from 'Constants/graphql/mutations/MutationReassignDevice'

import { REASSIGN_DEVICE_MODAL_FORM } from '../ReassignDeviceModal.constants'
import { reassignDeviceModalFormSchema } from '../ReassignDeviceModal.schema'
import {
  ReassignDeviceModalFormProps,
  ReassignDeviceModalFormSchema,
} from '../ReassignDeviceModal.types'
import { reassignDeviceFormDefaultValues } from '../ReassignDeviceModal.values'

export const ReassignDeviceModalForm = (
  props: ReassignDeviceModalFormProps,
) => {
  const { rental, onClose, onSubmit, setEmployeeName } = props
  const intl = useIntl()

  const formMethods = useForm<ReassignDeviceModalFormSchema>({
    defaultValues: {
      ...reassignDeviceFormDefaultValues,
    },
    resolver: zodResolver(reassignDeviceModalFormSchema),
  })

  const [submitReassignDevice, { loading, error }] = useMutation<
    mutationReassignDevice,
    mutationReassignDeviceVariables
  >(MUTATION_REASSIGN_DEVICE, {
    onCompleted: () => {
      onSubmit()
    },
  })

  const handleSubmit = formMethods.handleSubmit(data => {
    if (!data.employee) {
      return
    }

    setEmployeeName(data.employee.label)

    submitReassignDevice({
      variables: {
        employeeID: data.employee.value,
        rentalID: rental.id,
      },
    })
  })

  const { control } = formMethods
  const watchEmployee = useWatch({
    control,
    name: 'employee',
  })

  return (
    <>
      <FormProvider {...formMethods}>
        <Form id="REASSIGN_DEVICE_MODAL_FORM" onSubmit={handleSubmit}>
          <EmployeesSelect
            currentId={rental.employee?.id}
            e2eSelector={E2E_REASSIGN_DEVICE_EMPLOYEE_SELECT}
            errorMessage={error ? 'ReassignDevice_SubmissionError' : undefined}
            label="ReassignDevice_ModalSearchEmployee"
            name="employee"
            shouldShowErrorMessage={!!error}
            shouldTranslateLabel
          />
        </Form>
      </FormProvider>

      <ButtonGroup
        leftButton={{
          onClick: onClose,
          text: intl.formatMessage({ id: 'Cancel' }),
        }}
        rightButton={{
          'data-e2e': E2E_REASSIGN_DEVICE_SUBMIT,
          disabled: !watchEmployee || loading,
          form: REASSIGN_DEVICE_MODAL_FORM,
          text: intl.formatMessage({ id: 'Assign' }),
          type: 'submit',
        }}
      />
    </>
  )
}
