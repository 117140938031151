import {
  faCartShopping,
  faLaptopMobile,
  faSimCard,
  faTruckArrowRight,
  faUsers,
  faWarehouse,
} from '@fortawesome/pro-solid-svg-icons'

import { DepotsRoutePathsEnum } from 'Modules/depots/routes/depotsRouteConfig.types'
import { EmployeesRoutePathsEnum } from 'Modules/employees/routes/employeesRouteConfig.types'
import { OrdersRoutePathsEnum } from 'Modules/orders/routes/ordersRouteConfig.types'
import { PhonePlansRoutePathsEnum } from 'Modules/phonePlans/routes/phonePlansRouteConfig.types'
import { RentalsRoutePathsEnum } from 'Modules/rentals/routes/rentalsRouteConfig.types'
import { ReturnsRoutePathsEnum } from 'Modules/returns/routes/returnsRouteConfig.types'

import {
  ContentTypeEnum,
  PortalContentModel,
} from 'Types/content/content.types'

export const PORTAL_CONTENT_MODEL: PortalContentModel = {
  [ContentTypeEnum.rentals]: {
    icon: faLaptopMobile,
    modelType: 'rentals',
    path: RentalsRoutePathsEnum.RENTALS,
    translationId: 'Rentals',
  },
  [ContentTypeEnum.phone_plans]: {
    icon: faSimCard,
    modelType: 'phone_plans',
    path: PhonePlansRoutePathsEnum.PHONE_PLANS,
    translationId: 'Phone_plans',
  },
  [ContentTypeEnum.employees]: {
    icon: faUsers,
    modelType: 'employees',
    path: EmployeesRoutePathsEnum.EMPLOYEES,
    translationId: 'Employees',
  },
  [ContentTypeEnum.caseitems]: {
    icon: faCartShopping,
    modelType: 'caseitems',
    path: OrdersRoutePathsEnum.ORDERS,
    translationId: 'Orders',
  },
  [ContentTypeEnum.depots]: {
    icon: faWarehouse,
    modelType: 'depots',
    path: DepotsRoutePathsEnum.DEPOTS,
    translationId: 'Depots',
  },
  [ContentTypeEnum.return_cases]: {
    icon: faTruckArrowRight,
    modelType: 'return_cases',
    path: ReturnsRoutePathsEnum.RETURNS,
    translationId: 'Returns',
  },
}

export const GLOBAL_SEARCH_WHITELIST = [
  'rentals',
  'phone_plans',
  'employees',
  'caseitems',
  'return_cases',
]
