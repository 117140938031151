export enum GlobalRoutePathsEnum {
  ORGANISATION = '/organisation/:organisationId',
  EMPLOYEE = '/employee/:employeeId',
}

export enum GlobalRouteTypesEnum {
  ORGANISATION = 'organisation',
  EMPLOYEE = 'employee',
  PUBLIC = 'public',
  PRIVATE = 'private',
}
