export const SHIPPING_PROVIDERS = {
  DHL: 'DHL',
  DHL_DE_PAKET: 'DHL DE Paket',
  DHL_EXPRESS: 'DHL Express',
  DHL_EXPRESS_DE: 'DHL Express DE',
  DHL_EXPRESS_DE_TO_DE: 'DHL Express DE to DE',
  DHL_EXPRESS_DE_TO_EU: 'DHL Express DE to EU',
  DHL_INTERNATIONAL: 'DHL International',
  FEDEX: 'FedEx',
  OTHER: 'Other',
  PERSONAL_HANDOVER: 'Personal handover',
  PUROLATOR: 'Purolator',
  UPS: 'UPS',
  USPS: 'USPS',
}
