import { useCallback, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { CellContext, ColumnDef } from '@tanstack/react-table'

import { PrimaryButton, Table } from 'Components/_theme'
import { ImageWithFallback } from 'Components/ImageWithFallback/ImageWithFallback'

import { DepotStockModal } from 'Modals/DepotStockModal/DepotStockModal'

import {
  E2E_DEPOT_TABLE_SUMMARY_BUTTON,
  E2E_TABLE_LINK_DEPOT,
} from 'Constants/e2e'

import { DeviceTypeEnum } from 'Types/deviceTypes'

import { useModal } from 'Portal/src/hooks'

import { DepotTableProps, GroupedStocklistItemsWithUrl } from './Depot.types'
import { groupStocklistItems } from './Depot.util'

export const DepotTable = (props: DepotTableProps) => {
  const { depot } = props

  const { setModal, hideModal } = useModal()
  const intl = useIntl()

  const handleOpenDepotStockModal = useCallback(
    (stocklistItems: GroupedStocklistItemsWithUrl) => {
      setModal({
        component: DepotStockModal,
        isOpen: true,
        props: {
          hide: () => {
            hideModal()
          },
          stocklistItems,
        },
      })
    },
    [hideModal, setModal],
  )

  const renderNameHeader = useCallback(() => <FormattedMessage id="Name" />, [])

  const renderSummaryHeader = useCallback(
    () => (
      <span className="ms-1">
        <FormattedMessage id="Summary" />
      </span>
    ),
    [],
  )

  const renderNameCell = useCallback(
    ({ cell }: CellContext<GroupedStocklistItemsWithUrl, unknown>) => {
      const stocklistItem = cell.row.original

      return (
        <div className="d-inline-flex">
          <div className="d-flex justify-content-center align-items-center me-4 Table-device-image">
            <ImageWithFallback
              alt={stocklistItem?.deviceDefinition?.name}
              placeholderType={
                stocklistItem?.deviceDefinition?.productType as DeviceTypeEnum
              }
              src={stocklistItem?.deviceDefinition?.imageURL || ''}
            />
          </div>

          <div className="d-flex flex-column justify-content-center text-ellipsis">
            <div className="d-flex align-items-center">
              <strong className="text-ellipsis">
                {stocklistItem?.deviceDefinition?.name}
              </strong>
            </div>
          </div>
        </div>
      )
    },
    [],
  )

  const renderExpandableContent = useCallback(
    ({ cell }: CellContext<GroupedStocklistItemsWithUrl, unknown>) => {
      const deviceDefinition = cell.row.original

      return (
        <div className="w-100 Table-details">
          <div className="Table-details-row">
            <span className="Table-row--expandable-title">
              {renderSummaryHeader()}
            </span>

            <div>
              <PrimaryButton
                appearance="outline"
                className="d-inline-flex text-nowrap"
                onClick={() => handleOpenDepotStockModal(deviceDefinition)}
                size="small"
              >
                {`${deviceDefinition.numOfDevices} ${intl.formatMessage({
                  id: 'DepotTable_in_depot',
                })}`}
              </PrimaryButton>
            </div>
          </div>
        </div>
      )
    },
    [handleOpenDepotStockModal, renderSummaryHeader, intl],
  )

  const renderSummaryCell = useCallback(
    ({ cell }: CellContext<GroupedStocklistItemsWithUrl, unknown>) => {
      const deviceDefinition = cell.row.original

      return (
        <div className="ms-1">
          <PrimaryButton
            appearance="outline"
            className="d-inline-flex text-nowrap"
            data-e2e={E2E_DEPOT_TABLE_SUMMARY_BUTTON}
            onClick={() => handleOpenDepotStockModal(deviceDefinition)}
            size="small"
          >
            {`${deviceDefinition.numOfDevices} ${intl.formatMessage({
              id: 'DepotTable_in_depot',
            })}`}
          </PrimaryButton>
        </div>
      )
    },
    [handleOpenDepotStockModal, intl],
  )

  const columns = useMemo<ColumnDef<GroupedStocklistItemsWithUrl>[]>(
    () => [
      {
        cell: renderNameCell,
        header: renderNameHeader,
        id: 'name',
        meta: {
          ExpandableContent: renderExpandableContent,
        },
      },
      {
        cell: renderSummaryCell,
        header: renderSummaryHeader,
        id: 'summary',
      },
    ],
    [
      renderExpandableContent,
      renderNameCell,
      renderNameHeader,
      renderSummaryCell,
      renderSummaryHeader,
    ],
  )

  const data = useMemo<GroupedStocklistItemsWithUrl[]>(
    () => groupStocklistItems(depot.stocklistItems?.stocklistItems ?? []),
    [depot.stocklistItems?.stocklistItems],
  )

  return (
    <Table
      className="Table--depot"
      columns={columns}
      data={data}
      e2eRowId={E2E_TABLE_LINK_DEPOT}
      hideArrow
    />
  )
}
