import { EMPTY_SELECT_OPTION } from 'Constants/form'

import { ModalDeviceReplacementFormSchema } from './ModalDeviceReplacementForm.types'

export const modalDeviceReplacementFormDefaultValues: ModalDeviceReplacementFormSchema =
  {
    depot: EMPTY_SELECT_OPTION,
    nextDeviceId: EMPTY_SELECT_OPTION,
    previousDevice: '',
  }
