import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  NormalizedCacheObject,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { asyncMap } from '@apollo/client/utilities'
import * as OPTLApi from '@opentelemetry/api'
import { SemanticAttributes } from '@opentelemetry/semantic-conventions'
import * as Sentry from '@sentry/react'

import { cache } from 'Constants/graphql/cache'

import { NodeEnvEnum } from 'Types/env.types'

import { generateOPTLTraceparent } from './utilities/openTelemetry/openTelemetry.utils'

const ApolloLoader: {
  apolloClient: ApolloClient<NormalizedCacheObject> | undefined
  buildApolloClient: (
    jwt: string | null,
    userId: number | null,
  ) => ApolloClient<NormalizedCacheObject>
} = {
  apolloClient: undefined,
  buildApolloClient: (
    jwt: string | null,
    userId: number | null,
  ): ApolloClient<NormalizedCacheObject> => {
    const openTelemetryMiddleware = new ApolloLink((operation, forward) => {
      const tracer = OPTLApi.trace.getTracer('gql-tracer', '0.0.1')

      const span = tracer.startSpan(`gqlquery-${operation.operationName}`, {
        attributes: {
          [SemanticAttributes.HTTP_URL]: window.location.href,
          [SemanticAttributes.HTTP_ROUTE]: operation.operationName,
          // 'everphone.active_org_id': 1,
          'everphone.logged_in_user_id': userId ?? 0,
        },
      })
      const { traceId, spanId } = span.spanContext()

      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          traceparent: generateOPTLTraceparent(traceId, spanId),
        },
      }))

      return asyncMap(forward(operation), async response => {
        span.end()

        return response
      })
    })

    const authLink = setContext((_, { headers }) => ({
      headers: {
        ...headers,
        authorization: jwt ? `Bearer ${jwt}` : '',
      },
    }))

    const httpLink = createHttpLink({
      uri: window.GRAPHQL_API_URL,
    })

    const errorLink = onError(
      ({ forward, operation, graphQLErrors, networkError }) => {
        if (graphQLErrors || networkError) {
          if (process.env.NODE_ENV !== NodeEnvEnum.PRODUCTION) {
            // eslint-disable-next-line no-console
            console.log(graphQLErrors || networkError)
          }

          Sentry.captureException(graphQLErrors || networkError)
        }

        return forward(operation)
      },
    )

    const links = [authLink, errorLink, httpLink]

    if (
      process.env.NODE_ENV !== NodeEnvEnum.TEST) {
      links.unshift(openTelemetryMiddleware)
    }

    const apolloClient = new ApolloClient({
      cache,
      defaultOptions: {
        query: {
          errorPolicy: 'all',
          fetchPolicy: 'no-cache',
        },
        watchQuery: {
          errorPolicy: 'ignore',
          fetchPolicy: 'no-cache',
        },
      },
      link: ApolloLink.from(links),
    })

    ApolloLoader.apolloClient = apolloClient

    return apolloClient
  },
}

export default ApolloLoader
