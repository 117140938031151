import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useMountEffect } from '@react-hookz/web'

import { Heading, PrimaryButton } from 'Components/_theme'

import { LOCAL_STORAGE } from 'Constants/constants'
import { E2E_COOKIE_ACCEPT } from 'Constants/e2e'

import { CookieApprovalProps } from './CookieApproval.types'

export const CookieApproval = (props: CookieApprovalProps) => {
  const { getApproval } = props
  // Setting to undefined to prevent the flickering
  const [open, setOpen] = useState<boolean | undefined>()

  const toggleApproval = (value: boolean) => {
    setOpen(false)
    // Sets localStorage value for cookie approval
    localStorage.setItem(LOCAL_STORAGE.COOKIE_APPROVAL, `${value}`)
    getApproval(value)
  }

  useMountEffect(() => {
    // Checks if the user has made a choice to reshow the popup
    if (localStorage.getItem(LOCAL_STORAGE.COOKIE_APPROVAL)) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  })

  return (
    <>
      {!!open && (
        <div className="cookie-approval-container bg-white p-6 w-100">
          <div className="pe-md-9">
            <Heading as={4} className="pt-2 font-weight-bold" size={2}>
              <FormattedMessage id="CookieApproval_heading" />
            </Heading>
            <p className="cookie-content">
              <FormattedMessage id="CookieApproval_cookies_stored_on_computer" />{' '}
              <span className="d-none d-sm-inline">
                <FormattedMessage id="CookieApproval_improve_experience_info" />
              </span>
            </p>
            <p className="cookie-content d-none d-sm-block">
              <FormattedMessage id="CookieApproval_if_you_decline" />
            </p>
          </div>
          <div className="cookie-actions-container">
            <PrimaryButton
              className="w-100 m-2"
              data-e2e={E2E_COOKIE_ACCEPT}
              onClick={() => toggleApproval(true)}
            >
              <FormattedMessage id="Accept" />
            </PrimaryButton>

            <PrimaryButton
              appearance="outline"
              className="w-100 m-2"
              onClick={() => toggleApproval(false)}
            >
              <FormattedMessage id="Reject" />
            </PrimaryButton>
          </div>
        </div>
      )}
    </>
  )
}
