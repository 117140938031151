import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { observer } from 'mobx-react'

import { Banner } from 'Components/_theme'

import { useStore } from 'Stores/index'

import { isActiveLanguage } from 'Utilities'

import { SupportedLanguagesEnum } from 'Constants/locale'

import { DashboardBannerProps } from './DashboardBanner.types'

export const DashboardBanner = observer((props: DashboardBannerProps) => {
  const { onNewOrderButtonClick, version, showNewOrderButton } = props

  const { portalContentStore } = useStore()
  const { portalContent } = portalContentStore

  const intl = useIntl()

  const isGermanLanguageActive = isActiveLanguage(SupportedLanguagesEnum.DE)

  const renderRentalHeader = useMemo(() => {
    if (isGermanLanguageActive) {
      if (portalContent?.dashboardHeaderDE) {
        return portalContent.dashboardHeaderDE
      }
    } else if (portalContent?.dashboardHeaderEN) {
      return portalContent.dashboardHeaderEN
    }

    return intl.formatMessage({ id: 'EmployeeDashboard_title' })
  }, [intl, portalContent, isGermanLanguageActive])

  const renderRentalSubtitle = useMemo(() => {
    if (isGermanLanguageActive) {
      if (portalContent?.dashboardSubtitleDE) {
        return portalContent.dashboardSubtitleDE
      }
    } else if (portalContent?.dashboardSubtitleEN) {
      return portalContent.dashboardSubtitleEN
    }

    return intl.formatMessage({ id: 'EmployeeDashboard_title_content' })
  }, [intl, portalContent, isGermanLanguageActive])

  const renderRentalButton = useMemo(() => {
    if (isGermanLanguageActive) {
      if (portalContent?.dashboardButtonDE) {
        return portalContent.dashboardButtonDE
      }
    } else if (portalContent?.dashboardButtonEN) {
      return portalContent.dashboardButtonEN
    }

    return intl.formatMessage({ id: 'EmployeeDashboard_button' })
  }, [isGermanLanguageActive, portalContent, intl])

  return (
    <Banner
      alt={
        !portalContent?.dashboardImageURL
          ? intl.formatMessage({ id: 'Img_alt_dashboard_banner' })
          : undefined
      }
      buttonText={renderRentalButton}
      className="mb-6"
      imageSource={
        portalContent?.dashboardImageURL
          ? portalContent.dashboardImageURL
          : 'https://storage.googleapis.com/everphone-assets/portal-assets/dashboard/everphonedashboardsplashnew.png'
      }
      onButtonClick={onNewOrderButtonClick}
      showButton={showNewOrderButton}
      text={renderRentalSubtitle}
      title={renderRentalHeader}
      version={version}
    />
  )
})
