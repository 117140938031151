import { useCallback } from 'react'
import { useClearRefinements, useCurrentRefinements } from 'react-instantsearch'
import { FormattedMessage } from 'react-intl'

import { LinkButton } from 'Components/_theme'

import { scrollToTop } from 'Utilities'

import { E2E_LIST_CLEAR_ALL_FILTERS_BUTTON } from 'Constants/e2e'

import { ClearActiveFiltersProps } from './ClearActiveFilters.types'

export const ClearActiveFilters = (props: ClearActiveFiltersProps) => {
  const { className } = props
  const { items } = useCurrentRefinements()
  const { refine } = useClearRefinements()

  const handleClearAll = useCallback(() => {
    scrollToTop()

    refine()
  }, [refine])

  if (items.length === 0) {
    return null
  }

  return (
    <LinkButton
      className={className}
      data-e2e={E2E_LIST_CLEAR_ALL_FILTERS_BUTTON}
      onClick={handleClearAll}
      size="small"
    >
      <FormattedMessage id="Clear_all" />
    </LinkButton>
  )
}
