import { DebouncedValue, InputArg, Queue } from './debouncePromise.types'

// accumulate all promises during [time] and resolve them all with value from last [fn] call
export const debouncePromise = <
  PayloadFn extends Function,
  PayloadResolveValue,
>(
  fn: PayloadFn,
  time: number,
): DebouncedValue<PayloadResolveValue> => {
  let timeout: NodeJS.Timeout
  const queue: Queue<PayloadResolveValue>[] = []

  const debounced = (...args: InputArg[]) => {
    clearTimeout(timeout)

    return new Promise<PayloadResolveValue>((resolve, reject) => {
      // store all promises which should be resolved during debounce period
      queue.push({ reject, resolve })

      timeout = setTimeout(() => {
        // all queue promises must be resolved
        // create copy of queue promises, and clear list before invoking async function to prevent adding promises to list during async function run
        const pending = [...queue]

        queue.length = 0

        Promise.resolve(fn(...args))
          .then(data => {
            pending.forEach(({ resolve: res }) => res(data))
          })
          .catch(error => {
            pending.forEach(({ reject: rej }) => rej(error))
          })
      }, time)
    })
  }

  return debounced
}
