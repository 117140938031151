import { generatePath } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import queryString from 'query-string'

import type { ComposePath } from './composePath.types'

// Wrapper for generatePath to compose organisation, employee or public path
export const composePath: ComposePath = (
  { path, paths, isOrganisation, params },
  qString,
  basePath = '',
) => {
  const preparedPath =
    path ?? (isOrganisation ? paths.organisation : paths.employee)

  const pathToGenerate = basePath + preparedPath

  try {
    const generatedPath = generatePath(pathToGenerate, params)

    return qString
      ? `${generatedPath}?${queryString.stringify(qString)}`
      : generatedPath
  } catch (error) {
    Sentry.captureException(error, {
      extra: {
        isOrganisation,
        params,
        path,
        paths,
      },
    })

    return ''
  }
}
