import { isNil } from 'lodash-es'

import { getEmployee_employee_organisation } from 'Constants/graphql/queries/employee/__generated__/getEmployee'
import { getOrganisation_organisation } from 'Constants/graphql/queries/organisation/__generated__/getOrganisation'

export const createReturnLabelModalDefaultValues = (
  organisation:
    | getOrganisation_organisation
    | getEmployee_employee_organisation
    | null,
  countries: {
    [k: string]: string
  },
) => {
  const countryLabel = organisation?.contact?.address?.country
    ? countries[organisation?.contact?.address?.country]
    : undefined
  const countryValue = organisation?.contact?.address?.country || undefined

  return !isNil(countryLabel) && !isNil(countryValue)
    ? {
        country: {
          label: countryLabel,
          value: countryValue,
        },
      }
    : undefined
}
