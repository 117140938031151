import { History } from 'history'
import { isEmpty, omit } from 'lodash-es'
import queryString, { ParsedQuery } from 'query-string'

export const handleClearListFilter = (
  parsedSearch: ParsedQuery<string> | null,
  history: History,
  filterName?: string,
) => {
  let queryObject = parsedSearch

  if (!filterName || isEmpty(queryObject)) {
    history.push({ search: queryString.stringify({}) })

    return
  }

  queryObject = omit(parsedSearch, filterName)

  history.push({ search: queryString.stringify(queryObject) })
}
