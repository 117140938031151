import { cloneDeep } from 'lodash-es'

import { Auth0Config, Auth0Connection, Auth0Env } from './auth0.types'

export const defaultEnv: Auth0Env = {
  demo: {
    clientId: 'iYiFTGJWzCMZbRT2aUo6LvJ4C7c8zwNF',
    domain: 'https://test-auth.everphone.app',
  },
  dev: {
    clientId: 'iYiFTGJWzCMZbRT2aUo6LvJ4C7c8zwNF',
    domain: 'https://test-auth.everphone.app',
  },
  gcpdev: {
    clientId: 'iYiFTGJWzCMZbRT2aUo6LvJ4C7c8zwNF',
    domain: 'https://test-auth.everphone.app',
  },
  production: {
    clientId: '6aKVPtyhG8h32PXo2go0bLr8g1SDVGEd',
    domain: 'https://auth.everphone.app',
  },
  staging: {
    clientId: 'iYiFTGJWzCMZbRT2aUo6LvJ4C7c8zwNF',
    domain: 'https://test-auth.everphone.app',
  },
}

export const getAuth0ConnectionConfig = (
  auth0Config: Auth0Config | null,
): Auth0Connection | null => {
  if (!auth0Config) {
    return null
  }

  const defaultEnvs = cloneDeep(defaultEnv)

  if (auth0Config?.domain) {
    defaultEnvs.production.domain = auth0Config.domain
  }

  return {
    auth0Env: defaultEnvs,
    name: auth0Config.name,
    provider: auth0Config.provider,
  }
}
