import { components, GroupBase } from 'react-select'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons'

import { Icon } from 'Components/Icon/Icon'
import { SelectOption } from 'Components/Select/Select.types'

import { SelectDropdownIndicatorProps } from './SelectDropdownIndicator.types'

export const SelectDropdownIndicator = <
  Option extends SelectOption,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: SelectDropdownIndicatorProps<Option, IsMulti, Group>,
) => {
  const {
    selectProps: { menuIsOpen },
  } = props

  return (
    <components.DropdownIndicator {...props}>
      <Icon icon={menuIsOpen ? faChevronUp : faChevronDown} size={20} />
    </components.DropdownIndicator>
  )
}
