import { useIntl } from 'react-intl'
import {
  faBarsProgress,
  faCartShopping,
  faLaptopMobile,
  faTags,
  faTrademark,
} from '@fortawesome/pro-solid-svg-icons'
import { observer } from 'mobx-react'

import { FilterColumn } from 'Components/FilterColumn/FilterColumn'

import {
  E2E_ORDER_FILTER_COPAYMENT,
  E2E_ORDER_FILTER_DATE,
  E2E_ORDER_FILTER_DATE_TOOLBAR,
  E2E_ORDER_FILTER_DEVICE_TYPE,
  E2E_ORDER_FILTER_MANUFACTURER,
  E2E_ORDER_FILTER_MODEL,
  E2E_ORDER_FILTER_STATUS,
  E2E_ORDER_FILTER_TYPE,
} from 'Constants/e2e'

import { MultidatepickerFilter } from 'Algolia/filters/MultidatepickerFilter/MultidatepickerFilter'
import { MultiselectFilter } from 'Algolia/filters/MultiselectFilter/MultiselectFilter'
import { RangeFilter } from 'Algolia/filters/RangeFilter/RangeFilter'

import { OrdersFilterColumnProps } from './OrdersFilterColumn.types'

export const OrdersFilterColumn = observer((props: OrdersFilterColumnProps) => {
  const { onCloseFilterColumn, isVisible } = props
  const intl = useIntl()

  return (
    <FilterColumn isVisible={isVisible} onClose={onCloseFilterColumn}>
      <MultiselectFilter
        filterProps={{ attribute: 'case_item.type' }}
        label={intl.formatMessage({
          id: 'typesenseAttribute_case_item_type',
        })}
        selectProps={{
          iconProps: {
            icon: faCartShopping,
          },
          instanceId: E2E_ORDER_FILTER_TYPE,
        }}
      />

      <MultiselectFilter
        filterProps={{ attribute: 'case_item.status' }}
        label={intl.formatMessage({
          id: 'typesenseAttribute_case_item_status',
        })}
        selectProps={{
          iconProps: {
            icon: faBarsProgress,
          },
          instanceId: E2E_ORDER_FILTER_STATUS,
        }}
      />

      <MultidatepickerFilter
        autoComplete="off"
        filterProps={{ attribute: 'case_item.created' }}
        label={intl.formatMessage({
          id: 'typesenseAttribute_case_item_created',
        })}
        multidatePickerProps={{
          e2eSelector: E2E_ORDER_FILTER_DATE,
          maxDate: new Date().setHours(23, 59, 59),
          toolbarE2eSelector: E2E_ORDER_FILTER_DATE_TOOLBAR,
          /* eslint-disable sort-keys-fix/sort-keys-fix */
          customToolbarConfig: {
            today: {
              scope: 'today',
            },
            last_7days: {
              length: 7,
              period: 'days',
              scope: 'past',
            },
            last_30days: {
              length: 30,
              period: 'days',
              scope: 'past',
            },
            last_6months: {
              length: 6,
              period: 'months',
              scope: 'past',
            },
          },
          /* eslint-enable sort-keys-fix/sort-keys-fix */
        }}
      />

      <MultiselectFilter
        filterProps={{ attribute: 'case_item.device_definition_name' }}
        label={intl.formatMessage({
          id: 'typesenseAttribute_case_item_device_definition_name',
        })}
        selectProps={{
          iconProps: {
            icon: faTags,
          },
          instanceId: E2E_ORDER_FILTER_MODEL,
        }}
      />

      <MultiselectFilter
        filterProps={{ attribute: 'case_item.device_definition_manufacturer' }}
        label={intl.formatMessage({
          id: 'typesenseAttribute_case_item_device_definition_manufacturer',
        })}
        selectProps={{
          iconProps: {
            icon: faTrademark,
          },
          instanceId: E2E_ORDER_FILTER_MANUFACTURER,
        }}
      />

      <MultiselectFilter
        filterProps={{ attribute: 'case_item.device_definition_product_type' }}
        label={intl.formatMessage({
          id: 'typesenseAttribute_case_item_device_definition_type',
        })}
        selectProps={{
          iconProps: {
            icon: faLaptopMobile,
          },
          instanceId: E2E_ORDER_FILTER_DEVICE_TYPE,
        }}
      />

      <RangeFilter
        filterProps={{
          attribute: 'case_item.copayment',
          precision: 2,
        }}
        label={intl.formatMessage({
          id: 'typesenseAttribute_case_item_copayment',
        })}
        rangeInputProps={{
          e2eSelector: E2E_ORDER_FILTER_COPAYMENT,
          range: true,
          step: 1,
          type: 'input',
        }}
      />
    </FilterColumn>
  )
})
