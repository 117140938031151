import { gql } from '@apollo/client'

export const GET_RENTALS_PRESENTATION_DETAILS = gql`
  query getRentalsPresentationDetails($id: ID!, $first: Int!, $after: Int!) {
    employee(id: $id) {
      __typename
      rentals(pagination: { after: $after, first: $first }) {
        __typename
        totalCount
        rentals {
          __typename
          id
          stocklistItem {
            __typename
            id
            imei
            imei2
            serialNumber
            eid
            deviceDefinition {
              __typename
              name
              productType
              imageURL
            }
          }
        }
      }
    }
  }
`
