import { InMemoryCache } from '@apollo/client'

import { pagination } from './polices/pagination'
import { getAllowedEmployees } from './queries/listing/__generated__/getAllowedEmployees'
import { getEmployeeDevices } from './queries/listing/__generated__/getEmployeeDevices'
import { getEmployeePhonePlans_employee } from './queries/listing/__generated__/getEmployeePhonePlans'
import { getOrders_organisation } from './queries/listing/__generated__/getOrders'
import { getOrdersAndReturns_employee } from './queries/listing/__generated__/getOrdersAndReturns'
import { getOrganisationDepots_organisation } from './queries/listing/__generated__/getOrganisationDepots'
import { getOrganisationDevices } from './queries/listing/__generated__/getOrganisationDevices'
import { getOrganisationEmployees_organisation } from './queries/listing/__generated__/getOrganisationEmployees'
import { getOrganisationInvoices_organisation } from './queries/listing/__generated__/getOrganisationInvoices'
import { getOrganisationPhonePlans_organisation } from './queries/listing/__generated__/getOrganisationPhonePlans'
import { getReturnCases_organisation } from './queries/listing/__generated__/getReturnCases'

export const cache = new InMemoryCache({
  /**
   * If id exist, set the dataObject Id to it, otherwise set it to the cursor value.
   * This is to prevent having 2 duplicate objects in cache. As, Ids will remain the same.
   */
  // dataIdFromObject: (o): string => o.id ? `${o.__typename}-${o.id}` : `${o.__typename}-${o.cursor}`,
  typePolicies: {
    Employee: {
      fields: {
        caseitems: pagination<getOrdersAndReturns_employee>(),
        phonePlans: pagination<getEmployeePhonePlans_employee>(),
        rentals: pagination<getEmployeeDevices>(),
      },
    },
    Organisation: {
      fields: {
        caseItems: pagination<getOrders_organisation>(),
        depots: pagination<getOrganisationDepots_organisation>(),
        employees: pagination<getOrganisationEmployees_organisation>(),
        invoices: pagination<getOrganisationInvoices_organisation>(),
        phonePlans: pagination<getOrganisationPhonePlans_organisation>(),
        rentals: pagination<getOrganisationDevices>(),
        returnCases: pagination<getReturnCases_organisation>(),
      },
    },
    Query: {
      fields: {
        allowedEmployees: pagination<getAllowedEmployees>(),
      },
    },
  },
})
