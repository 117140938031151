import { ReturnWithUrl } from 'Modules/returns/components/ReturnsTableInstantsearch/ReturnsTable.types'

import { dashOrSpaceToUnderscore } from 'Utilities'

import {
  ReturnCaseStatus,
  ReturnCaseSubstatus,
} from 'Portal/__generated__/globalTypes'

export const isReturnBlocked = (data?: {
  status?: ReturnCaseStatus | null
  substatus?: ReturnCaseSubstatus | null
}) => {
  if (!data?.status || !data?.substatus) {
    return false
  }

  const returnCaseSubstatus = dashOrSpaceToUnderscore(data.substatus)

  return (
    data.status === ReturnCaseStatus.blocked &&
    (returnCaseSubstatus === ReturnCaseSubstatus.in_customer_lock ||
      returnCaseSubstatus === ReturnCaseSubstatus.in_mdm ||
      returnCaseSubstatus === ReturnCaseSubstatus.in_mdm_and_customer_lock)
  )
}

export const extractReturnCaseStatusData = (returnCase: ReturnWithUrl) => ({
  status: (returnCase?.['return_case.status'] as ReturnCaseStatus) || null,
  substatus:
    (returnCase?.['return_case.sub_status'] as ReturnCaseSubstatus) || null,
})
