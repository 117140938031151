import { useCallback, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash-es'
import { observer } from 'mobx-react'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { EMPLOYEE_RECENT_REQUESTS } from 'Modules/employees/pages/Employee/Employee.constants'

import { LinkButton } from 'Components/_theme'
import { EntityListCard } from 'Components/EntityListCard/EntityListCard'
import { EntityProps } from 'Components/EntityListCard/EntityListCard.types'
import { RECENT_REQUESTS_MAX_TO_SHOW } from 'Components/RecentRequestsEntityCard/RecentRequestsEntityCard.constants'

import { useIsOrganisation } from 'Hooks'

import { getCaseItemTypeTranslationId } from 'Utilities'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'
import { getCaseItemIcon } from 'Portal/src/utilities/getCaseItemIcon/getCaseItemIcon'

import { RecentRequestsEntityCardProps } from './RecentRequestsEntityCard.types'

export const RecentRequestsEntityCard = observer(
  (props: RecentRequestsEntityCardProps) => {
    const { caseItems } = props
    const intl = useIntl()
    const [seeAll, setSeeAll] = useState(false)
    const isOrganisation = useIsOrganisation()
    const { employeeId, organisationId } = useParams<{
      employeeId?: string
      organisationId?: string
    }>()

    const generateEntities = useMemo((): EntityProps[] => {
      const entities = caseItems.map(caseItem => {
        const title = getCaseItemTypeTranslationId(caseItem.caseType)
        const translatedTitle = intl.formatMessage({ id: title })
        const link = composePath({
          isOrganisation,
          params: {
            employeeId,
            orderId: caseItem.id,
            organisationId,
          },
          paths: {
            employee: GLOBAL_ROUTE_PATHS.employee.order,
            organisation: GLOBAL_ROUTE_PATHS.organisation.order,
          },
        })

        return {
          content: (
            <p key={caseItem.id} className="mb-0 mt-2 fs-6 text-truncate">
              <FormattedMessage id="Request_id" />: #<span>{caseItem.id}</span>
            </p>
          ),
          icon: getCaseItemIcon(caseItem.caseType),
          id: caseItem.id,
          link,
          status: caseItem.status,
          title: translatedTitle,
        }
      })

      if (seeAll) {
        return entities
      }

      return entities.slice(0, RECENT_REQUESTS_MAX_TO_SHOW)
    }, [caseItems, employeeId, intl, isOrganisation, organisationId, seeAll])

    const handleSeeAll = useCallback(() => {
      setSeeAll(true)
    }, [])

    const renderSeeAllLink = useMemo(() => {
      if (generateEntities.length < RECENT_REQUESTS_MAX_TO_SHOW || seeAll) {
        return null
      }

      return (
        <LinkButton
          className="btn-link fw-bold"
          onClick={handleSeeAll}
          size="small"
        >
          <FormattedMessage id="See_all" />
        </LinkButton>
      )
    }, [generateEntities.length, handleSeeAll, seeAll])

    if (isEmpty(generateEntities)) {
      return null
    }

    return (
      <EntityListCard
        entities={generateEntities}
        rightContent={renderSeeAllLink}
        testId={EMPLOYEE_RECENT_REQUESTS}
        title="Recent_requests"
        titleSuffix={
          <span className="ms-2">
            <FormattedMessage id="Total" />: <span>{caseItems.length}</span>
          </span>
        }
      />
    )
  },
)
