import { ContentCard } from '../ContentCard/ContentCard'
import { Entity } from './Entity'
import { EntityListCardProps } from './EntityListCard.types'

export const EntityListCard = (props: EntityListCardProps) => {
  const { entities, title, rightContent, titleSuffix, testId } = props

  return (
    <ContentCard
      rightContent={rightContent}
      title={title}
      titleSuffix={titleSuffix}
    >
      <ul className="EntityCardList p-0" data-testid={testId}>
        {entities.map(entity => (
          <Entity key={entity.id} {...entity} />
        ))}
      </ul>
    </ContentCard>
  )
}
