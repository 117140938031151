import type * as React from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Redirect, useLocation } from 'react-router-dom'
import {
  faLock,
  faUniversalAccess,
  faUser,
} from '@fortawesome/pro-solid-svg-icons'
import * as OPTLApi from '@opentelemetry/api'
import { SemanticAttributes } from '@opentelemetry/semantic-conventions'
import { useMediaQuery, useMountEffect } from '@react-hookz/web'
import { observer } from 'mobx-react'

import {
  ERROR_MESSAGE_INVALID_CREDENTIALS,
  ERROR_MESSAGE_LOGIN_FAILED,
  ERROR_MESSAGE_NOT_AUTHORISED,
  LoginModeEnum,
} from 'Modules/auth/constants/loginErrorCodes'

import {
  FeedbackText,
  Heading,
  Input,
  LinkButton,
  PrimaryButton,
} from 'Components/_theme'
import { Footer } from 'Components/Footer/Footer'
import { Icon } from 'Components/Icon/Icon'
import { LoadingState } from 'Components/LoadingState/LoadingState'

import { useStore } from 'Stores/index'

import { useTheme } from 'Hooks'

import {
  AuthError,
  clearThemeWhenNotLogged,
  fetchFromApi,
  generateOPTLTraceparent,
  getAuth0ConnectionConfig,
  initialiseUserway,
} from 'Utilities'

import { PORTAL_BREAKPOINTS } from 'Constants/constants'
import {
  E2E_LOGIN_EMAIL,
  E2E_LOGIN_PASSWORD,
  E2E_LOGIN_SUBMIT,
} from 'Constants/e2e'
import { BRAND_NAMES } from 'Constants/theme'

import { AppEnvEnum, NodeEnvEnum } from 'Types/env.types'

import loginBrandingImage from 'Assets/images/login/login_branding.png'
import loginDecorBottom from 'Assets/images/login/login_decor_bottom.svg'
import loginDecorLeft from 'Assets/images/login/login_decor_left.svg'
import otherBrandingImage from 'Assets/images/login/other_branding.png'
import everphoneLogoLight from 'Assets/images/logos/everphone_logo_light.svg'
import everphoneLogoPoweredBy from 'Assets/images/logos/everphone_logo_powered_by.svg'
import googleLogo from 'Assets/images/logos/google-logo.png'
import microsoftLogo from 'Assets/images/logos/microsoft-logo.png'

import { setRouteTitle } from 'Portal/src/router/Router.utils'
import {
  Auth0Config,
  Auth0Connection,
  Auth0ConnectionProvider,
  Auth0Env,
  Auth0Params,
} from 'Portal/src/utilities/auth0/auth0.types'

import { LOGIN_PASSWORD_INPUT } from './PortalLogin.constants'
import { PortalLoginProps } from './PortalLogin.types'

// generate a auth0 url with the correct url redirect params
const generateAuth0Url = (
  connection: Auth0Connection,
  email: string,
): string => {
  const appEnv = window.APP_ENV
  const auth0Env = connection.auth0Env[appEnv as keyof Auth0Env]
  const searchParams = window.location.href.split('?')[1]
  const urlParams = new URLSearchParams(searchParams)
  const urlRedirectParam = urlParams.get('redirect-to')
  const redirectUrl = new URL(`${window.origin}/auth/sso`)

  if (urlRedirectParam) {
    redirectUrl.searchParams.append('redirect-to', urlRedirectParam)
  }

  const params: { [key: string]: string } = {
    client_id: auth0Env.clientId,
    connection: connection.name,
    redirect_uri: redirectUrl.href,
    response_mode: 'form_post',
    response_type: 'token',
    scope: 'openid email',
  }

  if (connection.provider === Auth0ConnectionProvider.Google) {
    // have the ui default to the email typed in
    params.login_hint = email
  }

  const queryString = Object.keys(params)
    .map(k => `${k}=${params[k as keyof Auth0Params]}`)
    .join('&')

  return `${auth0Env.domain}/authorize?${queryString}`
}

// partial function to use the correct connection for generating an auth0Url
const loginWithAuth0 =
  (
    connection: Auth0Connection,
    email: string,
  ): ((event: React.FormEvent) => void) =>
  (event: any) => {
    event.preventDefault()
    window.location.href = generateAuth0Url(connection, email)
  }

export const PortalLogin = observer((props: PortalLoginProps) => {
  const {
    isNewJoiner,
    customSubmit,
    customErrorMessage,
    customIsSubmitting,
    customShowSubmit,
    resetCustomErrorMessage,
  } = props
  const [isLoading, setIsLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [showPassword, setShowPassword] = useState(isNewJoiner ?? false)
  const [showSubmit, setShowSubmit] = useState(true)
  const [auth0Connection, setAuth0Connection] =
    useState<Auth0Connection | null>(null)
  const [email, setEmail] = useState<string>('')
  const [loginMode, setLoginMode] = useState<LoginModeEnum>(
    isNewJoiner ? LoginModeEnum.newJoiner : LoginModeEnum.initial,
  )
  const [password, setPassword] = useState('')
  const intl = useIntl()
  const location = useLocation()

  const emailRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)

  const { userStore } = useStore()
  const { user } = userStore

  const isDesktop = useMediaQuery(`(min-width: ${PORTAL_BREAKPOINTS.XXL})`)
  const { isEverphoneTheme } = useTheme()
  const localStorageTheme = localStorage.getItem('theme')

  const isDefaultTheme = isEverphoneTheme && !localStorageTheme

  // used for QA purposes, we switch off list filtering in gcpdev/dev
  // environments but this can be switched on manually using localStorage
  useMountEffect(() => {
    localStorage.setItem('enable_list_filtering', 'false')
  })

  const fetchAuthConfig = async (
    email: string,
  ): Promise<Auth0Config | null> => {
    try {
      const response = await fetch(`${window.GO_API_URL}/auth0-sso-config`, {
        body: JSON.stringify({ email }),
        method: 'POST',
      })

      return await response.json()
    } catch (error) {
      return null
    }
  }

  const getAuthConfig = async (event: React.FormEvent<HTMLFormElement>) => {
    setErrorMsg('')

    const email = new FormData(event.currentTarget).get('_username')?.toString()

    if (!email) return

    const auth0Config = await fetchAuthConfig(email)
    const auth0ConnectionConfig = getAuth0ConnectionConfig(auth0Config)

    setIsLoading(true)
    setLoginMode(LoginModeEnum.passwordPhase)
    setAuth0Connection(auth0ConnectionConfig)

    let headers = {}
    let span: OPTLApi.Span | undefined

    if (process.env.NODE_ENV !== NodeEnvEnum.TEST) {
      const tracer = OPTLApi.trace.getTracer('gql-tracer', '0.0.1')

      span = tracer.startSpan('auth-config', {
        attributes: {
          [SemanticAttributes.HTTP_URL]: window.location.href,
          [SemanticAttributes.HTTP_ROUTE]: `/auth-config?email=${email}`,
        },
      })

      const { traceId, spanId } = span.spanContext()

      headers = {
        traceparent: generateOPTLTraceparent(traceId, spanId),
      }
    }

    fetch(`${window.GO_API_URL}/auth-config`, {
      body: JSON.stringify({ email }),
      headers,
      method: 'POST',
    })
      .then(res =>
        // eslint-disable-next-line prefer-promise-reject-errors
        res.status !== 200 ? Promise.reject('/auth-config failed') : res.json(),
      )
      .then((data: { can_login_with_password: boolean }) => {
        if (
          window.APP_ENV === AppEnvEnum.PRODUCTION ||
          window.APP_ENV === undefined
        ) {
          setShowPassword(data.can_login_with_password)
          setShowSubmit(data.can_login_with_password)
        } else {
          setShowPassword(true)
          setShowSubmit(true)
        }
      })
      .catch(() => {
        if (
          window.APP_ENV === AppEnvEnum.PRODUCTION ||
          window.APP_ENV === AppEnvEnum.GCPDEV ||
          window.APP_ENV === undefined
        ) {
          setShowPassword(!auth0ConnectionConfig)
          setShowSubmit(!auth0ConnectionConfig)
        } else {
          setShowPassword(true)
          setShowSubmit(true)
        }
      })
      .finally(() => {
        span?.end()

        setIsLoading(false)
      })
  }

  const getApiErrorMessage = (error: Error | null) => {
    if (error instanceof AuthError && error.apiErrors) {
      switch (error.apiErrors[0].code) {
        case 'notAuthorized':
          return ERROR_MESSAGE_NOT_AUTHORISED
        case 'invalidCredentials':
          return ERROR_MESSAGE_INVALID_CREDENTIALS
        case 'accountLocked':
          return intl.formatMessage({
            id: 'PortalLogin_accountLocked',
          })
        case 'notAllowedToLogin':
          return intl.formatMessage({ id: 'Login_error_not_allowed_to_login' })
        case 'passwordExpired':
          return intl.formatMessage({ id: 'Login_error_password_reset' })
        default:
          return ERROR_MESSAGE_LOGIN_FAILED
      }
    } else {
      return ERROR_MESSAGE_LOGIN_FAILED
    }
  }

  const login = () => {
    setIsLoading(true)
    setErrorMsg('')
    // TODO: Get rid of old API
    fetchFromApi('/auth/login', {
      body: JSON.stringify({
        email,
        password,
      }),
      method: 'POST',
    })
      .then(data => {
        if (data.jwt) {
          localStorage.removeItem('newJoiner')

          window.removeEventListener('beforeunload', () => {
            clearThemeWhenNotLogged(user.jwt)
          })

          const queryString = window.location
          const urlParams = new URLSearchParams(queryString.search)
          const urlRedirectParam = urlParams.get('redirect-to')
          const hashUrlParams = new URLSearchParams(location.search)
          const hashRedirectParams = hashUrlParams.get('redirect-to')

          if (urlRedirectParam && urlRedirectParam.indexOf('admin') > -1) {
            // Redirect back to the specific admin page
            window.location.href = '/redirect'
          } else if (hashRedirectParams) {
            // Redirect to specific portal page
            window.location.href = `/${hashRedirectParams}`
          } else {
            // Redirect to home if no parameter exist
            window.location.href = '/'
          }
        } else {
          setErrorMsg(getApiErrorMessage(null))
          setIsLoading(false)
          console.error('A token was not found in the response')
        }
      })
      .catch(error => {
        setErrorMsg(getApiErrorMessage(error))
        setIsLoading(false)
      })
  }

  const resetState = () => {
    setLoginMode(LoginModeEnum.initial)
    setShowPassword(false)
    setShowSubmit(true)
    setAuth0Connection(null)
    setPassword('')
    setIsLoading(false)
    setErrorMsg('')
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (loginMode === LoginModeEnum.newJoiner && customSubmit) {
      customSubmit(email, password)

      return
    }

    if (loginMode === LoginModeEnum.passwordPhase) {
      login()
    } else {
      getAuthConfig(event)
    }
  }

  const generateLoginButtonLabel = useMemo(() => {
    if (showPassword) {
      return intl.formatMessage({ id: 'Login' })
    }

    return intl.formatMessage({ id: 'Continue' })
  }, [intl, showPassword])

  useEffect(() => {
    const searchParams = window.location.href.split('?')[1]
    const urlParams = new URLSearchParams(searchParams)
    const ssoLoginFailedParam = urlParams.get('ssoLoginFailed')
    const ssoErrorCode = urlParams.get('errCode')

    if (ssoLoginFailedParam && ssoLoginFailedParam[0] === '1') {
      if (ssoErrorCode && ssoErrorCode[0] === 'notAllowedToLogin') {
        setErrorMsg(
          intl.formatMessage({ id: 'Login_error_not_allowed_to_login' }),
        )
      } else {
        setErrorMsg(intl.formatMessage({ id: 'Login_not_authorized' }))
      }
    }
  }, [intl])

  useEffect(() => {
    if (window.APP_ENV === AppEnvEnum.PRODUCTION) {
      initialiseUserway()
    }
  }, [])

  useMountEffect(() => {
    setRouteTitle(
      intl.formatMessage({
        id: 'Login',
      }),
    )
  })

  // already logged in, redirecting...
  if (user.jwt) {
    return <Redirect to="/" />
  }

  return (
    <div className="Login">
      <div className="Login-logo position-relative" role="banner">
        <img alt="Logo" src={everphoneLogoLight} />
      </div>

      <div className="Login-content container-fluid">
        <div className="row position-relative overflow-hidden">
          {isDefaultTheme && (
            <div className="Login-decor-bottom position-absolute offset-1 col-4 d-none d-xl-flex justify-content-center">
              <img
                alt="Decor bottom"
                className="w-100"
                src={loginDecorBottom}
              />
            </div>
          )}

          <div
            className="Login-content-left offset-md-1 col-12 col-md-4 pr-0 d-flex align-items-center order-1 order-md-0"
            role="complementary"
          >
            <div className="position-relative d-flex flex-column align-items-center w-100">
              {isDefaultTheme && (
                <img
                  alt="Decor left"
                  className="Login-decor-left position-absolute h-100 d-none d-xl-inline"
                  src={loginDecorLeft}
                />
              )}

              <img
                alt={intl.formatMessage({ id: 'Img_alt_login_devices' })}
                className="Login-image w-100"
                src={!isDefaultTheme ? otherBrandingImage : loginBrandingImage}
              />

              <Heading as={3} className="text-center" color="light" size={1}>
                <FormattedMessage id="PortalLogin_title" />
              </Heading>

              <p className="text-center">
                <FormattedMessage id="PortalLogin_description" />
              </p>
            </div>
          </div>

          <div
            className="Login-content-right col offset-md-1 d-flex flex-column position-relative"
            role="main"
          >
            {isDefaultTheme && (
              <img
                alt="Decor left"
                className="Login-decor-left mobile position-absolute d-none d-md-inline d-xl-none h-100"
                src={loginDecorLeft}
              />
            )}

            <div className="Login-form h-100 d-flex flex-column align-items-center justify-content-center position-relative">
              {isLoading ? (
                <LoadingState />
              ) : (
                <form className="w-100" onSubmit={handleSubmit}>
                  <Heading className="text-center" size={2}>
                    <FormattedMessage
                      id={
                        isDefaultTheme
                          ? 'PortalLogin_header_to'
                          : 'PortalLogin_header'
                      }
                      values={{ name: BRAND_NAMES.everphone }}
                    />
                  </Heading>

                  <Input
                    ref={emailRef}
                    aria-label={intl.formatMessage({
                      id: isNewJoiner ? 'Username' : 'Email',
                    })}
                    autoComplete="username"
                    autoFocus
                    data-e2e={E2E_LOGIN_EMAIL}
                    iconLeft={faUser}
                    id="email"
                    isClearable
                    isFilled={!!email}
                    isInvalid={!!errorMsg || !!customErrorMessage}
                    markAsRequired
                    name="_username"
                    onChange={({ target }) => {
                      if (customErrorMessage && resetCustomErrorMessage) {
                        resetCustomErrorMessage()
                      }

                      if (loginMode === LoginModeEnum.passwordPhase) {
                        resetState()
                      }

                      setEmail(target.value)
                    }}
                    onClear={() => {
                      if (loginMode === LoginModeEnum.passwordPhase) {
                        resetState()
                      }

                      setEmail('')
                    }}
                    onTriggerFocus={() => emailRef.current?.focus()}
                    placeholder={intl.formatMessage({
                      id: isNewJoiner ? 'Username' : 'Email',
                    })}
                    required
                    size={isDesktop ? 'large' : 'medium'}
                    type={isNewJoiner ? 'text' : 'email'}
                    value={email}
                  />

                  {showPassword && (
                    <Input
                      ref={passwordRef}
                      aria-label={intl.formatMessage({
                        id: 'Password',
                      })}
                      autoComplete="current-password"
                      className="Login-form-password"
                      data-e2e={E2E_LOGIN_PASSWORD}
                      data-testid={LOGIN_PASSWORD_INPUT}
                      iconLeft={faLock}
                      id="password"
                      isClearable
                      isFilled={!!password}
                      isInvalid={!!errorMsg || !!customErrorMessage}
                      markAsRequired
                      name="_password"
                      onChange={({ target }) => {
                        if (customErrorMessage && resetCustomErrorMessage) {
                          resetCustomErrorMessage()
                        }

                        setErrorMsg('')

                        setPassword(target.value)
                      }}
                      onClear={() => {
                        setPassword('')
                      }}
                      onTriggerFocus={() => passwordRef.current?.focus()}
                      placeholder={intl.formatMessage({
                        id: 'Password',
                      })}
                      required
                      size={isDesktop ? 'large' : 'medium'}
                      type="password"
                      value={password}
                    />
                  )}

                  {(errorMsg || customErrorMessage) && (
                    <FeedbackText isInvalid>
                      <FormattedMessage id={errorMsg || customErrorMessage} />
                    </FeedbackText>
                  )}

                  {(showSubmit || customShowSubmit) && (
                    <PrimaryButton
                      className="Login-form-submit w-100"
                      data-e2e={E2E_LOGIN_SUBMIT}
                      isLoading={customIsSubmitting}
                      size={isDesktop ? 'large' : 'medium'}
                      type="submit"
                    >
                      {generateLoginButtonLabel}
                    </PrimaryButton>
                  )}

                  {loginMode === LoginModeEnum.passwordPhase &&
                    auth0Connection &&
                    (() => {
                      switch (auth0Connection.provider) {
                        case Auth0ConnectionProvider.Google:
                          return (
                            <button
                              aria-label={intl.formatMessage({
                                id: 'SSOButton_Google_label',
                              })}
                              className="Login-form-sso-button"
                              onClick={loginWithAuth0(auth0Connection, email)}
                              type="submit"
                            >
                              <span>
                                <img
                                  alt="Google sign-in"
                                  src={googleLogo}
                                  width="20px"
                                />
                              </span>
                              <p>
                                <FormattedMessage id="SSOButton_Google_label" />
                              </p>
                            </button>
                          )
                        case Auth0ConnectionProvider.Microsoft:
                          return (
                            <button
                              aria-label={intl.formatMessage({
                                id: 'SSOButton_Microsoft_label',
                              })}
                              className="Login-form-sso-button"
                              onClick={loginWithAuth0(auth0Connection, email)}
                              type="submit"
                            >
                              <span>
                                <img
                                  alt="Microsoft sign-in"
                                  src={microsoftLogo}
                                  width="20px"
                                />
                              </span>
                              <p>
                                <FormattedMessage id="SSOButton_Microsoft_label" />
                              </p>
                            </button>
                          )
                        case Auth0ConnectionProvider.OIDC:
                          return (
                            <button
                              aria-label={intl.formatMessage({
                                id: 'Login_SSO',
                              })}
                              className="Login-form-sso-button"
                              onClick={loginWithAuth0(auth0Connection, email)}
                              type="submit"
                            >
                              <p>
                                <FormattedMessage id="Login_SSO" />
                              </p>
                            </button>
                          )
                        default:
                          return null
                      }
                    })()}

                  {showPassword && loginMode !== LoginModeEnum.newJoiner && (
                    <LinkButton
                      asExternalLink
                      className="Login-form-remind-password w-100"
                      size="small"
                      title={intl.formatMessage({
                        id: 'ForgotPasswordButton_label',
                      })}
                      to="/sicherheit/neues-passwort"
                    >
                      <FormattedMessage id="ForgotPasswordButton_label" />
                    </LinkButton>
                  )}
                </form>
              )}

              {!isDefaultTheme && (
                <div className="Login-form-powered-by d-flex position-absolute">
                  powered by
                  <img
                    alt="Powered by Everphone"
                    src={everphoneLogoPoweredBy}
                  />
                </div>
              )}

              <button
                aria-label={intl.formatMessage({
                  id: 'Open_accessibility_menu',
                })}
                className="accessibility-icon-button"
                onClick={e => e.preventDefault()}
                type="button"
              >
                <Icon icon={faUniversalAccess} size={32} />
              </button>
            </div>

            <Footer className="d-none d-xl-flex" onLogin variant="light" />
          </div>

          <Footer
            className="Footer-mobile d-xl-none col-12 order-2"
            onLogin
            variant="light"
          />
        </div>
      </div>
    </div>
  )
})
