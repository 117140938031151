import { gql } from '@apollo/client'

export const GET_RETURN_DEPOTS = gql`
  query getReturnDepots($id: ID!) {
    organisation(id: $id) {
      returnDepots {
        depots {
          id
          locationName
        }
      }
    }
  }
`
