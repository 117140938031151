import { useCallback, useEffect, useMemo } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Redirect, useParams } from 'react-router-dom'
import { useMediaQuery } from '@react-hookz/web'
import { clsx } from 'clsx'
import { observer } from 'mobx-react'

import { getRedirectUrl } from 'Modules/order/components/OrderWrapper/OrderWrapper.utils'

import { ContentSidebar } from 'Components/ContentSidebar/ContentSidebar'
import { FaqCard } from 'Components/FaqCard/FaqCard'
import { PageErrorState } from 'Components/PageErrorState/PageErrorState'
import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'
import { PreviewColumnTypeEnum } from 'Components/PreviewColumn/PreviewColumn.types'

import { useStore } from 'Stores/index'
import { OrderStepEnum } from 'Stores/orderStore/orderStore.types'

import { useActiveUser, useIsOrganisation, useSidebarVisibility } from 'Hooks'

import { PORTAL_BREAKPOINTS } from 'Constants/constants'
import { E2E_PREVIEW_COLUMN_ORDER_FOOTER_BUTTON } from 'Constants/e2e'

import { NetPriceCalculator } from '../NetPriceCalculator/NetPriceCalculator'
import { OrderBundlePreviewColumn } from '../OrderBundlePreviewColumn/OrderBundlePreviewColumn'
import { OrderGuardModal } from '../OrderGuardModal/OrderGuardModal'
import { OrderWrapperProps } from './OrderWrapper.types'

export const OrderWrapper = observer((props: OrderWrapperProps) => {
  const { employeeId, orderFlowType, renderOrderContent } = props

  const { orderStore, portalConfigStore, portalSettingsStore, rentalStore } =
    useStore()
  const {
    currentStep,
    fetchCatalog,
    isLoading,
    setChosenDevice,
    clearChosenDeviceLimitationRules,
    previewColumnDevice,
    isOrderDisabled,
    orderDevices,
    isUpgradeFromProlongingPage,
  } = orderStore
  const { portalConfig } = portalConfigStore
  const { setTogglePreviewColumn, previewColumnSettings, isAppReadOnly } =
    portalSettingsStore
  const { rentalDetails } = rentalStore
  const { activeEmployee, activeOrganisation } = useActiveUser()
  const isOrganisation = useIsOrganisation()

  const {
    employeeId: employeeIdFromParams,
    organisationId: organisationIdFromParams,
  } = useParams<{
    employeeId: string
    organisationId: string
  }>()

  const isTablet = useMediaQuery(`(min-width: ${PORTAL_BREAKPOINTS.LG})`)
  const isExtraLarge = useMediaQuery(`(min-width: ${PORTAL_BREAKPOINTS.XXL})`)

  const previewColumnSetting = previewColumnSettings.find(
    previewColumnSetting =>
      previewColumnSetting.type === PreviewColumnTypeEnum.Bundle,
  )

  const isSidebarOpen = Boolean(previewColumnSetting?.isOpen)
  const { isVisible: isBundlePreviewColumnVisible } = useSidebarVisibility(
    Boolean(previewColumnSetting?.isOpen),
    isSidebarOpen,
  )

  const isBundlePreviewColumnSetting =
    previewColumnSetting &&
    previewColumnSetting.type === PreviewColumnTypeEnum.Bundle

  const isNetPriceCalculatorVisible = useMemo(() => {
    const groupDevicesByRentCategory =
      !!portalConfig?.groupDevicesByRentCategory

    if (
      !groupDevicesByRentCategory &&
      currentStep !== OrderStepEnum.result &&
      (activeEmployee?.organisation?.isPlusOrganisation ||
        activeOrganisation?.isPlusOrganisation)
    ) {
      return true
    }

    return false
  }, [currentStep, activeEmployee, activeOrganisation, portalConfig])

  const renderSideContent = useMemo(
    () => (
      <>
        {isNetPriceCalculatorVisible && <NetPriceCalculator />}

        <FaqCard isOnOrderFlow />
      </>
    ),
    [isNetPriceCalculatorVisible],
  )

  const onClosePreviewColumn = useCallback(() => {
    clearChosenDeviceLimitationRules()
    setTogglePreviewColumn(PreviewColumnTypeEnum.Bundle, false)
  }, [clearChosenDeviceLimitationRules, setTogglePreviewColumn])

  const renderPreviewColumn = useMemo(() => {
    if (
      !previewColumnDevice?.isBundle ||
      !isTablet ||
      !isBundlePreviewColumnSetting
    ) {
      return null
    }

    return (
      <OrderBundlePreviewColumn
        basisAmount={previewColumnDevice.bundleBasisAmount}
        copaymentGross={previewColumnDevice.bundleCopaymentGross}
        copaymentNet={previewColumnDevice.bundleCopaymentNet ?? 0}
        device={previewColumnDevice}
        e2eSelectorFooterBtn={E2E_PREVIEW_COLUMN_ORDER_FOOTER_BUTTON}
        isVisible={isBundlePreviewColumnVisible}
        onClosePreviewColumn={onClosePreviewColumn}
        onRentBundleButtonAction={async () => {
          await setChosenDevice(previewColumnDevice)
          previewColumnSetting?.handleOnRentAction()
          setTogglePreviewColumn(PreviewColumnTypeEnum.Bundle, false)
        }}
      />
    )
  }, [
    isBundlePreviewColumnSetting,
    isTablet,
    onClosePreviewColumn,
    previewColumnDevice,
    previewColumnSetting,
    setChosenDevice,
    setTogglePreviewColumn,
    isBundlePreviewColumnVisible,
  ])

  useEffect(() => {
    if (employeeIdFromParams && !organisationIdFromParams && !activeEmployee)
      return

    fetchCatalog(orderFlowType, employeeId, isOrganisation)
  }, [
    activeEmployee,
    employeeId,
    employeeIdFromParams,
    fetchCatalog,
    isOrganisation,
    orderFlowType,
    organisationIdFromParams,
  ])

  const redirectUrl = getRedirectUrl({
    activeEmployee,
    employeeId,
    isOrderDisabled,
    isOrganisation,
    isUpgradeFromProlongingPage,
    orderDevices,
    orderFlowType,
    portalConfig,
    rentalDetails,
  })

  if (redirectUrl) {
    return <Redirect to={redirectUrl} />
  }

  if (isAppReadOnly) {
    return <PageErrorState />
  }

  return (
    <Container className="OrderFlow pt-6" fluid>
      <Row
        className={clsx(
          isExtraLarge &&
            currentStep === OrderStepEnum.device &&
            'fill-content-height',
        )}
      >
        <Col xl={12} xs={{ order: 'last' }} xxl={{ order: 'first', span: 8 }}>
          {isLoading ? <PageLoadingState /> : renderOrderContent()}
        </Col>

        {isBundlePreviewColumnSetting ? (
          <ContentSidebar
            isSidebarOpen={isSidebarOpen}
            renderStickyColumnSideContent={renderSideContent}
          >
            {renderPreviewColumn}
          </ContentSidebar>
        ) : (
          <Col xl={12} xs={{ order: 'first' }} xxl={{ order: 'last', span: 4 }}>
            {renderSideContent}
          </Col>
        )}
      </Row>

      <OrderGuardModal />
    </Container>
  )
})
