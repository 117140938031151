import { FormProvider, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation } from '@apollo/client'
import { faReceipt } from '@fortawesome/pro-solid-svg-icons'
import { zodResolver } from '@hookform/resolvers/zod'
import { observer } from 'mobx-react'

import {
  Alert,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
} from 'Components/_theme'
import { CarrierSelect } from 'Components/CarrierSelect/CarrierSelect'
import { Field } from 'Components/Field/Field'
import { Form } from 'Components/Form/Form'

import { useStore } from 'Stores/index'

import { useActiveUser } from 'Hooks'

import {
  E2E_UPDATE_TRACKING_MODAL_HEADER,
  E2E_UPDATE_TRACKING_MODAL_SUBMIT,
} from 'Constants/e2e'
import {
  mutationUpdateReturnCaseTrackingInfo,
  mutationUpdateReturnCaseTrackingInfoVariables,
} from 'Constants/graphql/mutations/__generated__/mutationUpdateReturnCaseTrackingInfo'
import { MUTATION_UPDATE_RETURN_CASE_TRACKING_INFORMATION } from 'Constants/graphql/mutations/MutationUpdateReturnCaseTrackingInformation'

import { UPDATE_TRACKING_MODAL_FORM } from './UpdateTrackingModal.constants'
import { updateTrackingModalFormSchema } from './UpdateTrackingModal.schema'
import {
  UpdateTrackingModalFormSchema,
  UpdateTrackingModalProps,
} from './UpdateTrackingModal.types'
import { updateTrackingModalFormDefaultValues } from './UpdateTrackingModal.values'

export const UpdateTrackingModal = observer(
  (props: UpdateTrackingModalProps) => {
    const { onClose, returnCase, handleSubmit } = props
    const intl = useIntl()

    const { userStore } = useStore()
    const { user } = userStore
    const { activeOrganisation, activeEmployee } = useActiveUser()

    const [submitUpdateReturnCaseTrackingInfo, { loading, error }] =
      useMutation<
        mutationUpdateReturnCaseTrackingInfo,
        mutationUpdateReturnCaseTrackingInfoVariables
      >(MUTATION_UPDATE_RETURN_CASE_TRACKING_INFORMATION, {
        onCompleted: () => {
          handleSubmit()
        },
      })

    const formMethods = useForm<UpdateTrackingModalFormSchema>({
      defaultValues: updateTrackingModalFormDefaultValues(returnCase),
      resolver: zodResolver(updateTrackingModalFormSchema),
    })

    const handleSubmitForm = formMethods.handleSubmit(data => {
      submitUpdateReturnCaseTrackingInfo({
        variables: {
          input: {
            carrier: data.carrier?.value,
            organisationID: activeOrganisation?.id || activeEmployee?.organisation?.id || '',
            returnCaseId: returnCase.id,
            trackingReference: data.trackingReference,
            userId: user.userId?.toString() || '',
          },
        },
      })
    })

    return (
      <Card>
        <CardHeader
          e2eSelector={E2E_UPDATE_TRACKING_MODAL_HEADER}
          icon={faReceipt}
        >
          <FormattedMessage id="Update_tracking_information" />
        </CardHeader>

        <CardBody>
          {error && (
            <Alert className="mb-4" variant="danger">
              {error?.message}
            </Alert>
          )}

          <FormProvider {...formMethods}>
            <Form id={UPDATE_TRACKING_MODAL_FORM} onSubmit={handleSubmitForm}>
              <Field
                autoComplete="off"
                containerClassName="mb-4"
                inputProps={{
                  isClearable: true,
                }}
                label="Tracking_reference"
                name="trackingReference"
                shouldTranslateLabel
                type="text"
              />

              <CarrierSelect
                label="Carrier"
                name="carrier"
                shouldTranslateLabel
              />
            </Form>
          </FormProvider>

          <ButtonGroup
            leftButton={{
              onClick: onClose,
              text: intl.formatMessage({ id: 'Cancel' }),
            }}
            rightButton={{
              'data-e2e': E2E_UPDATE_TRACKING_MODAL_SUBMIT,
              form: UPDATE_TRACKING_MODAL_FORM,
              isLoading: loading,
              text: intl.formatMessage({
                id: 'Save',
              }),
              type: 'submit',
            }}
          />
        </CardBody>
      </Card>
    )
  },
)
