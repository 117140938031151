export const ATTRIBUTE_NAME_TRANSLATION_IDS: Record<string, string> = {
  'case_item.copayment': 'typesenseAttribute_case_item_copayment',
  'case_item.created': 'typesenseAttribute_case_item_created',
  'case_item.device_definition_manufacturer':
    'typesenseAttribute_case_item_device_definition_manufacturer',
  'case_item.device_definition_name':
    'typesenseAttribute_case_item_device_definition_name',
  'case_item.device_definition_product_type':
    'typesenseAttribute_case_item_device_definition_type',
  'case_item.employee_full_name': 'typesenseAttribute_case_item_employee',
  'case_item.status': 'typesenseAttribute_case_item_status',
  'case_item.type': 'typesenseAttribute_case_item_type',
  'employee.cost_center_name': 'typesenseAttribute_employee_cost_center_name',
  'employee.email': 'typesenseAttribute_employee_email',
  'employee.exit_date': 'typesenseAttribute_employee_exit_date',
  'employee.external_reference':
    'typesenseAttribute_employee_external_reference',
  'employee.full_name': 'typesenseAttribute_employee_full_name',
  'employee.office_combined_str': 'typesenseAttribute_employee_office',
  'employee.phone_number': 'typesenseAttribute_employee_phone_number',
  'employee.rent_category_tier':
    'typesenseAttribute_employee_rent_category_tier',
  'employee.rental_has_active': 'typesenseAttribute_employee_has_active_rental',
  'rental.employee.exit_date': 'typesenseAttribute_rental_employee_exit_date',
  'rental.end_at': 'typesenseAttribute_rental_end_at',
  'rental.is_prolonged': 'typesenseAttribute_rental_is_prolonged',
  'rental.minimum_contract_duration':
    'typesenseAttribute_rental_minimum_contract_duration',
  'rental.rental_price.copayment_amount':
    'typesenseAttribute_rental_rental_price_copayment_amount',
  'rental.start_at': 'typesenseAttribute_rental_start_at',
  'rental.stocklist_item.codename':
    'typesenseAttribute_rental_stocklist_item_codename',
  'rental.stocklist_item.manufacturer':
    'typesenseAttribute_rental_stocklist_item_manufacturer',
  'rental.upgrade_date': 'typesenseAttribute_rental_upgrade_date',
  'return_case.created': 'typesenseAttribute_return_case_created',
  'return_case.employee.exit_date':
    'typesenseAttribute_return_case_employee_exit_date',
  'return_case.employee.office':
    'typesenseAttribute_return_case_employee_office',
  'return_case.open_since': 'typesenseAttribute_return_case_open_since',
  'return_case.rental.cost_center':
    'typesenseAttribute_return_case_rental_cost_center',
  'return_case.return_depot_name':
    'typesenseAttribute_return_case_return_depot_name',
  'return_case.status': 'typesenseAttribute_return_case_status',
  'return_case.stocklist_item.codename':
    'typesenseAttribute_return_case_stocklist_item_codename',
  'return_case.stocklist_item.manufacturer':
    'typesenseAttribute_return_case_stocklist_item_manufacturer',
}
