import {
  IconDefinition,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core'

export const iconReturnDevice: IconDefinition = {
  icon: [
    32,
    32,
    [],
    'e013',
    'M9.287 0.059 C 8.801 0.116,7.945 0.403,7.544 0.645 C 6.494 1.279,5.781 2.216,5.479 3.360 L 5.360 3.813 5.360 16.000 L 5.360 28.187 5.479 28.640 C 5.785 29.801,6.557 30.801,7.600 31.387 C 8.056 31.643,8.807 31.881,9.362 31.944 C 10.011 32.019,21.989 32.019,22.640 31.944 C 23.204 31.880,24.028 31.612,24.456 31.355 C 25.471 30.742,26.138 29.896,26.490 28.771 L 26.640 28.293 26.640 16.000 L 26.640 3.707 26.490 3.229 C 26.133 2.088,25.406 1.178,24.400 0.613 C 23.944 0.357,23.193 0.119,22.638 0.056 C 22.053 -0.011,9.866 -0.008,9.287 0.059 M24.000 15.973 L 24.000 27.627 16.000 27.627 L 8.000 27.627 8.000 15.973 L 8.000 4.320 16.000 4.320 L 24.000 4.320 24.000 15.973 M13.333 13.360 L 10.694 16.000 13.347 18.653 L 15.999 21.306 16.933 20.374 L 17.867 19.441 16.814 18.387 L 15.761 17.333 18.547 17.333 L 21.333 17.333 21.333 16.000 L 21.333 14.667 18.547 14.667 L 15.761 14.667 16.814 13.613 L 17.867 12.559 16.946 11.639 C 16.440 11.134,16.014 10.720,15.999 10.720 C 15.985 10.720,14.785 11.908,13.333 13.360',
  ],
  iconName: 'returnDevice' as IconName,
  prefix: 'fac' as IconPrefix,
}
