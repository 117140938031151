import { useCallback, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { observer } from 'mobx-react'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { DashboardBenefitsCard } from 'Modules/dashboard/components/DashboardBenefitsCard/DashboardBenefitsCard'
import { DashboardDevices } from 'Modules/dashboard/components/DashboardDevices/DashboardDevices'
import { DashboardPopularDevicesCard } from 'Modules/dashboard/components/DashboardPopularDevicesCard/DashboardPopularDevicesCard'
import { DashboardServiceAppTitle } from 'Modules/dashboard/components/DashboardServiceAppTitle/DashboardServiceAppTitle'
import { NewJoinerRedirect } from 'Modules/dashboard/components/NewJoinerRedirect/NewJoinerRedirect'
import { getNewCaseItems } from 'Modules/dashboard/utils/getNewCaseItems.utils'

import { PageErrorState } from 'Components/PageErrorState/PageErrorState'
import { PageHeader } from 'Components/PageHeader/PageHeader'
import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'

import { useStore } from 'Stores/index'
import { UserModeEnum } from 'Stores/portalSettingsStore/portalSettingsStore.types'

import { useActiveUser, useIsServiceApp } from 'Hooks'

import {
  getEmployeeDashboard,
  getEmployeeDashboardVariables,
} from 'Constants/graphql/queries/employee/__generated__/getEmployeeDashboard'
import { GET_EMPLOYEE_DASHBOARD } from 'Constants/graphql/queries/employee/getEmployee'
import { PortalSectionEnum } from 'Constants/portalSection'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import { DashboardBanner } from '../DashboardBanner/DashboardBanner'
import { EMPLOYEE_DASHBOARD_DEFAULT_BENEFITS_SLIDES } from './EmployeeDashboardDefault.constants'

export const EmployeeDashboardDefault = observer(() => {
  const { portalSettingsStore, orderStore, portalConfigStore, employeeStore } =
    useStore()
  const { setCurrentPortalSection, userMode } = portalSettingsStore
  const { orderDevices: popularDevices, setChosenDevice } = orderStore
  const { portalConfig } = portalConfigStore
  const { employeeError } = employeeStore
  const { activeEmployee } = useActiveUser()
  const history = useHistory()
  const { employeeId } = useParams<{ employeeId: string }>()

  const [isNewOrderButtonShown, setIsNewOrderButtonShown] = useState(false)

  const isServiceApp = useIsServiceApp()

  const {
    data,
    loading: isLoading,
    error,
  } = useQuery<getEmployeeDashboard, getEmployeeDashboardVariables>(
    GET_EMPLOYEE_DASHBOARD,
    {
      errorPolicy: 'all',
      variables: { id: activeEmployee?.id || '' },
    },
  )

  useEffect(() => {
    setCurrentPortalSection(PortalSectionEnum.Dashboard)
  }, [setCurrentPortalSection])

  useEffect(() => {
    const newSmartphoneButtonIsVisible =
      !!activeEmployee?.canOrderNewDevice &&
      !!portalConfig?.newDevice &&
      !!activeEmployee?.canOrder

    setIsNewOrderButtonShown(newSmartphoneButtonIsVisible)
  }, [portalConfig?.newDevice, activeEmployee])

  const handleNewOrderButtonClick = useCallback(() => {
    history.push(
      composePath({
        params: { employeeId },
        path: GLOBAL_ROUTE_PATHS.employee.orderNew,
      }),
    )
  }, [employeeId, history])

  if (userMode === UserModeEnum.NEW_JOINER) {
    if (!activeEmployee?.canOrderNewDevice) {
      return <PageErrorState />
    }

    return <NewJoinerRedirect />
  }

  if (error || employeeError) {
    return <PageErrorState />
  }

  if (isLoading || !data) {
    return <PageLoadingState />
  }

  const { employee } = data
  const rentals = employee.rentals?.rentals
  let devicesCount = rentals?.length ?? 0

  if (employee.caseitems?.caseItems) {
    devicesCount += getNewCaseItems(employee.caseitems?.caseItems).length
  }

  return (
    <Container className="dashboard" fluid>
      {isServiceApp ? (
        <DashboardServiceAppTitle
          devicesCount={devicesCount}
          handleRentDeviceButtonClick={handleNewOrderButtonClick}
          showRentDeviceButton={isNewOrderButtonShown}
        />
      ) : (
        <div role="banner">
          <PageHeader portalSection={PortalSectionEnum.Dashboard} />
        </div>
      )}

      {(!isServiceApp || (isServiceApp && devicesCount === 0)) && (
        <DashboardBanner
          onNewOrderButtonClick={handleNewOrderButtonClick}
          showNewOrderButton={isNewOrderButtonShown}
          version={isServiceApp ? 'serviceApp' : 'default'}
        />
      )}

      <DashboardDevices
        caseItems={employee.caseitems}
        header="DevicesCard_devices_header"
        renderServiceApp
        rentals={rentals}
      />

      {/* TODO: confirm which content should be shown here and unhide
          component. Remember to not show it in Service App  */}
      {false && (
        <DashboardBenefitsCard
          slideProps={EMPLOYEE_DASHBOARD_DEFAULT_BENEFITS_SLIDES}
        />
      )}

      {/* TODO: confirm which devices should be shown here and unhide
          component */}
      {false && (
        <DashboardPopularDevicesCard
          devices={popularDevices?.allDevices || null}
          onChooseDevice={setChosenDevice}
        />
      )}
    </Container>
  )
})
