import { useCallback, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { GroupBase } from 'react-select'
import { useQuery } from '@apollo/client'
import { observer } from 'mobx-react'

import { Field } from 'Components/Field/Field'
import { FieldTypeEnum } from 'Components/Field/Field.types'
import { SelectOption } from 'Components/Select/Select.types'

import {
  getOrganisationDepotsForDropdown,
  getOrganisationDepotsForDropdownVariables,
} from 'Constants/graphql/queries/listing/__generated__/getOrganisationDepotsForDropdown'
import { GET_ORGANISATION_DEPOTS_FOR_DROPDOWN } from 'Constants/graphql/queries/listing/GetDepots'

import { useActiveUser } from 'Portal/src/hooks'

import { DepotsSelectProps } from './DepotsSelect.types'

export const DepotsSelect = observer(
  <
    Option extends SelectOption,
    IsMulti extends boolean,
    Group extends GroupBase<Option> = GroupBase<Option>,
  >(
    props: DepotsSelectProps<Option, IsMulti, Group>,
  ) => {
    const { name, rentalId, ...rest } = props
    const [depotOptions, setDepotOptions] = useState<SelectOption[]>([])
    const { activeOrganisation } = useActiveUser()
    const { setValue } = useFormContext()

    const { loading: isLoading } = useQuery<
      getOrganisationDepotsForDropdown,
      getOrganisationDepotsForDropdownVariables
    >(GET_ORGANISATION_DEPOTS_FOR_DROPDOWN, {
      onCompleted: data => {
        const parsedDepots: SelectOption[] =
          data.organisation.depots?.depots?.map(depot => ({
            label: depot.locationName,
            value: depot.id.toString(),
          })) ?? []

        // select first depot on load
        if (parsedDepots.length > 0) {
          setValue(name, parsedDepots[0])
        }

        setDepotOptions(parsedDepots)
      },
      variables: {
        id: activeOrganisation?.id!,
        rentalId,
      },
    })

    const defaultNoOptionsMessage = useCallback(
      () => <FormattedMessage id="No_depot_found" />,
      [],
    )

    return (
      <Field
        {...rest}
        name={name}
        selectProps={{
          isClearable: false,
          isLoading,
          noOptionsMessage: defaultNoOptionsMessage,
          options: depotOptions,
        }}
        type={FieldTypeEnum.select}
      />
    )
  },
)
