import type * as React from 'react'
import { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { useMediaQuery } from '@react-hookz/web'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import { clsx } from 'clsx'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { isReturnBlocked } from 'Modules/returns/utils/returns.utils'

import { Table } from 'Components/_theme'
import { FormatDate } from 'Components/FormatDate/FormatDate'
import { ImageWithFallback } from 'Components/ImageWithFallback/ImageWithFallback'
import { ReturnBlockedBadge } from 'Components/ReturnBlockedBadge/ReturnBlockedBadge'
import { ReturnStatusBadge } from 'Components/ReturnStatusBadge/ReturnStatusBadge'

import { buttonize, getFullContactName } from 'Utilities'

import { PORTAL_BREAKPOINTS } from 'Constants/constants'
import { E2E_TABLE_DEVICE_NAME, E2E_TABLE_LINK_RETURNS } from 'Constants/e2e'

import { ImagePlaceholderTypesEnum } from 'Types/imagePlaceholders.type'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import { ReturnsTableProps, ReturnsWithUrl } from './ReturnsTable.types'

export const ReturnsTable = (props: ReturnsTableProps) => {
  const {
    returns,
    isLoading,
    isSearching,
    onOpenPreviewColumn,
    selectedRowIndex,
  } = props
  const { organisationId } = useParams<{
    organisationId: string
  }>()

  const routerHistory = useHistory()
  const isTablet = useMediaQuery(`(min-width: ${PORTAL_BREAKPOINTS.LG})`)

  const handleEnterDetails = useCallback(
    (
      _event:
        | React.MouseEvent<HTMLDivElement>
        | React.KeyboardEvent<HTMLDivElement>,
      id: string,
      url: string,
      rowIndex: number,
    ) => {
      if (!isTablet) {
        routerHistory.push(url)

        return
      }

      if (typeof onOpenPreviewColumn === 'function') {
        onOpenPreviewColumn(id, rowIndex)
      }
    },
    [isTablet, onOpenPreviewColumn, routerHistory],
  )

  const renderReturnsHeader = useCallback(
    () => <FormattedMessage id="ReturnListContent_header__title" />,
    [],
  )

  const renderEmployeeHeader = useCallback(
    () => <FormattedMessage id="ReturnListContent_header__employee" />,
    [],
  )

  const renderCreatedHeader = useCallback(
    () => <FormattedMessage id="ReturnListContent_header__created" />,
    [],
  )

  const renderStatusHeader = useCallback(
    () => <FormattedMessage id="ReturnListContent_header__status" />,
    [],
  )

  const renderReturnCell = useCallback(
    (cell: CellContext<ReturnsWithUrl, unknown>) => {
      const returnCase = cell.row.original

      const getRequestedItemName = () => {
        if (returnCase.stocklistItem?.deviceDefinition?.manufacturer) {
          return `${returnCase.stocklistItem.deviceDefinition.manufacturer} ${returnCase.stocklistItem.deviceDefinition.codename}`
        }

        return <FormattedMessage id="Return_device" />
      }

      return (
        <div
          className="d-inline-flex"
          {...buttonize(
            handleEnterDetails,
            returnCase.id,
            returnCase.url,
            cell.row.index,
          )}
        >
          <div className="d-flex justify-content-center align-items-center me-4 Table-device-image">
            <ImageWithFallback
              alt={
                returnCase.stocklistItem?.deviceDefinition?.name ||
                'New return case icon'
              }
              placeholderType={ImagePlaceholderTypesEnum.newOrderFlow}
              src={returnCase.stocklistItem?.deviceDefinition?.imageURL || ''}
            />
          </div>

          <div className="d-flex flex-column justify-content-center text-ellipsis">
            <div className="d-flex align-items-center">
              <strong
                className="text-ellipsis"
                data-e2e={E2E_TABLE_DEVICE_NAME}
              >
                {getRequestedItemName()}
              </strong>
            </div>

            <div className="d-flex table-text-subtitle text-muted">
              <span>#</span>

              <span className="text-ellipsis" data-orderid={returnCase.id}>
                {returnCase.id}
              </span>
            </div>
          </div>
        </div>
      )
    },
    [handleEnterDetails],
  )

  const renderExpandableContent = useCallback(
    (cell: CellContext<ReturnsWithUrl, unknown>) => {
      const returnCase = cell.row.original

      return (
        <div className="w-100 Table-details">
          <div className="Table-details-row">
            <span className="Table-row--expandable-title">
              {renderEmployeeHeader()}
            </span>

            <span className="text-ellipsis">
              {returnCase.employee?.contact.firstName
                ? getFullContactName(returnCase.employee.contact)
                : '-'}
            </span>
          </div>

          <div className="Table-details-row">
            <span className="Table-row--expandable-title">
              {renderCreatedHeader()}
            </span>

            <span>
              <FormatDate value={returnCase.createdAt} />
            </span>
          </div>

          <div className="Table-details-row">
            <span className="Table-row--expandable-title">
              {renderStatusHeader()}
            </span>

            <span>
              {isReturnBlocked(returnCase) ? (
                <ReturnBlockedBadge
                  className="me-2"
                  substatus={returnCase.substatus}
                />
              ) : (
                <ReturnStatusBadge status={returnCase.status} />
              )}
            </span>
          </div>
        </div>
      )
    },
    [renderCreatedHeader, renderEmployeeHeader, renderStatusHeader],
  )

  const renderEmployeeCell = useCallback(
    (cell: CellContext<ReturnsWithUrl, unknown>) => {
      const returnCase = cell.row.original

      return (
        <div
          className="d-flex align-items-center h-100"
          {...buttonize(
            handleEnterDetails,
            returnCase.id,
            returnCase.url,
            cell.row.index,
          )}
        >
          <span className="text-ellipsis">
            {returnCase.employee?.contact.firstName
              ? getFullContactName(returnCase.employee.contact)
              : '-'}
          </span>
        </div>
      )
    },
    [handleEnterDetails],
  )

  const renderCreatedCell = useCallback(
    (cell: CellContext<ReturnsWithUrl, unknown>) => {
      const returnCase = cell.row.original

      return (
        <div
          className="d-flex align-items-center h-100"
          {...buttonize(
            handleEnterDetails,
            returnCase.id,
            returnCase.url,
            cell.row.index,
          )}
        >
          <FormatDate value={returnCase.createdAt} />
        </div>
      )
    },
    [handleEnterDetails],
  )

  const renderStatusCell = useCallback(
    (cell: CellContext<ReturnsWithUrl, unknown>) => {
      const returnCase = cell.row.original

      return (
        <div
          className="d-flex align-items-center h-100"
          {...buttonize(
            handleEnterDetails,
            returnCase.id,
            returnCase.url,
            cell.row.index,
          )}
        >
          {isReturnBlocked(returnCase) ? (
            <ReturnBlockedBadge
              className="me-2"
              substatus={returnCase.substatus}
            />
          ) : (
            <ReturnStatusBadge status={returnCase.status} />
          )}
        </div>
      )
    },
    [handleEnterDetails],
  )

  const columns = useMemo<ColumnDef<ReturnsWithUrl>[]>(() => {
    const tableColumns = [
      {
        cell: renderReturnCell,
        header: renderReturnsHeader,
        id: 'order',
        meta: {
          ExpandableContent: renderExpandableContent,
        },
      },
      {
        cell: renderEmployeeCell,
        header: renderEmployeeHeader,
        id: 'employee',
      },
      {
        cell: renderCreatedCell,
        header: renderCreatedHeader,
        id: 'date',
      },
      {
        cell: renderStatusCell,
        header: renderStatusHeader,
        id: 'status',
      },
    ]

    return tableColumns
  }, [
    renderCreatedCell,
    renderCreatedHeader,
    renderEmployeeCell,
    renderEmployeeHeader,
    renderExpandableContent,
    renderReturnCell,
    renderReturnsHeader,
    renderStatusCell,
    renderStatusHeader,
  ])

  const data = useMemo<ReturnsWithUrl[]>(() => {
    if (!returns) {
      return []
    }

    return returns.map((returnCase: any) => {
      const url = composePath({
        params: {
          organisationId,
          returnCaseId: returnCase.id,
        },
        path: GLOBAL_ROUTE_PATHS.organisation.return,
      })

      return {
        ...returnCase,
        url,
      }
    })
  }, [returns, organisationId])

  return (
    <Table
      className={clsx('Table--returns Table--isAdmin')}
      columns={columns}
      data={data}
      e2eRowId={E2E_TABLE_LINK_RETURNS}
      isLoading={isLoading}
      isSearching={isSearching}
      selectedRowIndex={selectedRowIndex}
    />
  )
}
