import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { Heading, PrimaryButton } from 'Components/_theme'
import { FormatDate } from 'Components/FormatDate/FormatDate'
import { ImageWithFallback } from 'Components/ImageWithFallback/ImageWithFallback'
import { OrderStatusBadge } from 'Components/OrderStatusBadge/OrderStatusBadge'
import { RentalStatusBadge } from 'Components/RentalStatusBadge/RentalStatusBadge'
import { RentalStatus } from 'Components/RentalStatusBadge/RentalStatusBadge.types'

import { useActiveUser } from 'Hooks'

import { DeviceTypeEnum } from 'Types/deviceTypes'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import {
  DashboardDeviceSlideProps,
  DashboardDeviceSlideTypeEnum,
} from './DashboardDevices.types'

export const DashboardDeviceSlide = (props: DashboardDeviceSlideProps) => {
  const {
    imageURL,
    name,
    productType,
    id,
    created,
    status,
    imei,
    startAt,
    type,
    isUpgradable,
    isEarlyUpgradable,
  } = props

  const { activeEmployee } = useActiveUser()

  const history = useHistory()
  const intl = useIntl()

  const handleViewButtonClick = () => {
    if (type === DashboardDeviceSlideTypeEnum.Rental) {
      const rentalPath = composePath({
        params: {
          employeeId: activeEmployee?.id,
          rentalId: id,
        },
        path: GLOBAL_ROUTE_PATHS.employee.rental,
      })

      history.push(rentalPath)
    }

    if (type === DashboardDeviceSlideTypeEnum.Request) {
      const orderPath = composePath({
        params: {
          employeeId: activeEmployee?.id,
          orderId: id,
        },
        path: GLOBAL_ROUTE_PATHS.employee.order,
      })

      history.push(orderPath)
    }
  }

  const renderButtonLabel = useMemo(() => {
    if (type === DashboardDeviceSlideTypeEnum.Rental) {
      return intl.formatMessage({ id: 'DeviceSlide_view_rental' })
    }

    return intl.formatMessage({ id: 'DeviceSlide_view_request' })
  }, [intl, type])

  return (
    <div className="DashboardDeviceSlide">
      <div className="DashboardDeviceSlide-image">
        <ImageWithFallback
          alt={name}
          fluid
          placeholderType={productType as DeviceTypeEnum}
          src={imageURL || ''}
        />
      </div>

      <div className="DashboardDeviceSlide-content">
        {status && (
          <div className="DashboardDeviceSlide-badge">
            <OrderStatusBadge status={status} />
          </div>
        )}

        {isUpgradable && (
          <div className="DashboardDeviceSlide-badge">
            <RentalStatusBadge status={RentalStatus.new_device} />
          </div>
        )}

        {isEarlyUpgradable && !isUpgradable && (
          <div className="DashboardDeviceSlide-badge">
            <RentalStatusBadge status={RentalStatus.upgrade} />
          </div>
        )}

        <div className="DashboardDeviceSlide-name">
          <Heading as={5} size={2}>
            {name ?? <FormattedMessage id="Device" />}
          </Heading>
        </div>

        {created && type === DashboardDeviceSlideTypeEnum.Request && (
          <div className="DashboardDeviceSlide-details d-flex flex-column justify-content-end">
            <FormattedMessage id="DeviceSlide_request_id" />: {id}
            <div className="d-flex flex-row">
              <FormattedMessage id="DeviceSlide_request_date" />
              :&nbsp;
              <FormatDate value={created} />
            </div>
          </div>
        )}

        {startAt && (
          <div className="DashboardDeviceSlide-details d-flex flex-column justify-content-end">
            {imei && (
              <>
                <FormattedMessage id="IMEI" />
                :&nbsp;{imei}
              </>
            )}

            <div className="d-flex flex-row">
              <FormattedMessage id="DeviceSlide_start_date" />
              :&nbsp;
              <FormatDate value={startAt} />
            </div>
          </div>
        )}

        <div className="DashboardDeviceSlide-button">
          <PrimaryButton
            appearance="outline"
            className="mt-4"
            onClick={handleViewButtonClick}
          >
            {renderButtonLabel}
          </PrimaryButton>
        </div>
      </div>
    </div>
  )
}
