import { useMemo, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { sum } from 'lodash-es'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react'

import { Card, CardBody } from 'Components/_theme'
import { Switch } from 'Components/_theme/Switch/Switch'

import { useStore } from 'Stores/index'

import { ProlongedRentalsChart } from '../ProlongedRentalsChart/ProlongedRentalsChart'

export const DashboardProlongedRentalsCard = observer(() => {
  const { dashboardStore, portalSettingsStore } = useStore()
  const { prolongedRentals } = dashboardStore
  const { i18n } = portalSettingsStore
  const intl = useIntl()

  const [showConfirmed, setShowConfirmed] = useState(true)

  const confirmed = useMemo(
    () => prolongedRentals?.confirmed || {},
    [prolongedRentals],
  )

  const validFrom = useMemo(
    () => prolongedRentals?.validFrom || {},
    [prolongedRentals],
  )

  const chartData = useMemo(() => {
    if (showConfirmed) {
      return {
        data: Object.values(confirmed).map(rental => rental),
        labels: Object.keys(confirmed).map(
          month =>
            DateTime.fromFormat(month, 'yyyy-MM', { locale: i18n.locale })
              .monthShort || '',
        ),
      }
    }

    return {
      data: Object.values(validFrom).map(rental => rental),
      labels: Object.keys(validFrom).map(
        month =>
          DateTime.fromFormat(month, 'yyyy-MM', { locale: i18n.locale })
            .monthShort || '',
      ),
    }
  }, [i18n.locale, confirmed, validFrom, showConfirmed])

  const count = sum(chartData.data)

  const totalCount =
    sum(Object.values(confirmed).map(rental => rental)) +
    sum(Object.values(validFrom).map(rental => rental))

  if (!totalCount) {
    return null
  }

  return (
    <Card className="mb-6">
      <CardBody>
        <Row>
          <Col className="mb-1 d-flex flex-column" xs={6}>
            <p>
              <FormattedMessage id="Prolonged_rentals" />
            </p>

            <span className="h4">{count.toLocaleString(i18n.locale)}</span>

            <Switch
              checked={showConfirmed}
              className="mt-auto"
              id="dateSwitch"
              label={
                showConfirmed
                  ? intl.formatMessage({ id: 'Confirmation_date' })
                  : intl.formatMessage({ id: 'Valid_from_date' })
              }
              name="dateSwitch"
              onChange={() => {
                setShowConfirmed(!showConfirmed)
              }}
              size="small"
            />
          </Col>

          <Col className="d-flex align-items-center" xs={6}>
            <ProlongedRentalsChart
              data={chartData.data}
              labels={chartData.labels}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
})
