import { useMemo } from 'react'
import { Col, Row } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { clsx } from 'clsx'
import { observer } from 'mobx-react'

import { store } from 'Stores/index'

import { PORTAL_HEADING_TRANSLATION_IDS } from 'Constants/portalSection'

import noResultsImage from 'Assets/images/table/no-results.svg'

import { NoResultsProps, NoResultsTypeEnum } from './NoResults.types'

// For now we can have 2 types of no result message, the old simple "No results" message
// and the new one with image, title and subtitle. Let's keep it this way
// to not change the look of old tables with no filters. When we are ready, we can simplify
// this and use only the "with image" type.
export const NoResults = observer((props: NoResultsProps) => {
  const { noResultsText, className, type = NoResultsTypeEnum.simple } = props
  const { portalSettingsStore } = store
  const { currentPortalSection } = portalSettingsStore
  const { i18n } = portalSettingsStore
  const intl = useIntl()

  const entity = useMemo(() => {
    let message = intl.formatMessage({
      id: PORTAL_HEADING_TRANSLATION_IDS[currentPortalSection],
    })

    if (i18n.language !== 'de') {
      message = message.toLowerCase()
    }

    return message
  }, [currentPortalSection, i18n.language, intl])

  return type === NoResultsTypeEnum.simple ? (
    <Row
      className={clsx('align-items-center justify-content-center', className)}
      style={{
        height: '100%',
      }}
    >
      <Col className="text-center" sm="auto" xs="auto">
        <p className="text-secondary my-0">
          <FormattedMessage
            id="No_results"
            values={{
              entity,
            }}
          />
          .
        </p>
      </Col>
    </Row>
  ) : (
    <div className="NoResults">
      <img
        alt={intl.formatMessage({ id: 'No_results_title' })}
        src={noResultsImage}
      />

      <p className="mb-2">
        <FormattedMessage id="No_results_title" />
      </p>

      <p>{noResultsText}</p>
    </div>
  )
})
