import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { faSearch } from '@fortawesome/pro-solid-svg-icons'
import { debounce } from 'lodash-es'

import { Input, PrimaryButton } from 'Components/_theme'
import { DownloadReportProvider } from 'Components/DownloadReport/DownloadReport'
import { Icon } from 'Components/Icon/Icon'
import { ListFilters } from 'Components/ListFilters/ListFilters'

import { E2E_PAGE_TABLE_SEARCH } from 'Constants/e2e'

import { LIST_SEARCH_TIMEOUT_DELAY_MS } from './ListSearch.constants'
import { ListSearchProps } from './ListSearch.types'

export const ListSearch = (props: ListSearchProps) => {
  const {
    handleSearch,
    hideSearch,
    buttonIsLoading,
    buttonLabel,
    buttonE2ESelector,
    buttonOnClick,
    buttonVisible,
    totalCount,
    downloadReportType,
    downloadReportAlertAdditionalReadyMessage,
    downloadReportFilters,
    showFilters,
    activeFilters,
    onClearFilter,
    hideDownloadReportComponents,
  } = props
  const [searchTerm, setSearchTerm] = useState('')
  const intl = useIntl()

  const handleSearchTerm = useCallback(
    (term: string) => {
      if (!handleSearch) {
        return
      }

      handleSearch(encodeURI(term))
    },
    [handleSearch],
  )

  const debouncedHandleSearchTerm = useMemo(
    () => debounce(handleSearchTerm, LIST_SEARCH_TIMEOUT_DELAY_MS),
    [handleSearchTerm],
  )

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value)
      debouncedHandleSearchTerm(event.target.value)
    },
    [debouncedHandleSearchTerm],
  )

  const handleInputKeyPress = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault()
      }
    },
    [],
  )

  const shouldHideDownloadReportComponents = useMemo(() => {
    if (hideDownloadReportComponents === undefined) {
      // If `hideDownloadReportComponents` is not defined, we're returning default logic
      return totalCount === 0
    }

    return hideDownloadReportComponents
  }, [hideDownloadReportComponents, totalCount])

  return (
    <DownloadReportProvider
      hideDownloadReportComponents={shouldHideDownloadReportComponents}
      reportFilter={downloadReportFilters}
      reportType={downloadReportType}
    >
      <div className="table-search-container">
        <div className="d-flex flex-wrap w-100 align-items-lg-center">
          <div className="d-flex flex-row text-muted align-items-center me-auto">
            <span className="filter-list-text">
              <FormattedMessage id="Total" />
              :&nbsp;
              {totalCount}
            </span>
          </div>

          {showFilters && (
            <ListFilters
              activeFilters={activeFilters}
              onClearFilter={onClearFilter}
            />
          )}

          <div className="table-button-container d-flex no-wrap">
            <DownloadReportProvider.Button className="ms-md-4" />

            {buttonVisible && (
              <PrimaryButton
                className="ms-4"
                data-e2e={buttonE2ESelector}
                id={buttonE2ESelector}
                isLoading={buttonIsLoading}
                onClick={buttonOnClick}
              >
                {buttonLabel}
              </PrimaryButton>
            )}
          </div>

          {!hideSearch && (
            <div className="table-search-input-container">
              <form>
                <Input
                  aria-label={intl.formatMessage({
                    id: 'Search',
                  })}
                  autoComplete="off"
                  className="w-100"
                  contentAfter={<Icon icon={faSearch} size={20} />}
                  data-e2e={E2E_PAGE_TABLE_SEARCH}
                  onChange={handleInputChange}
                  onKeyPress={handleInputKeyPress}
                  type="search"
                  value={searchTerm}
                />
              </form>
            </div>
          )}
        </div>
      </div>

      <DownloadReportProvider.Alert
        additionalReadyMessage={downloadReportAlertAdditionalReadyMessage}
      />
    </DownloadReportProvider>
  )
}
