import { DateTime } from 'luxon'
import { action, makeObservable, observable } from 'mobx'

import {
  CaseItemCounts,
  CaseItemOverview,
  EmployeeCounts,
  ExitEmployee,
  FleetItem,
  MonthlyStatistics,
  OrganisationDashboardResponse,
  ProlongedRentals,
  ShippedOrder,
  UnreturnedDevice,
} from 'Types/dashboard/organisationDashboard.types'

import type { RootStore } from '..'

export class DashboardStore {
  rentalStats: MonthlyStatistics | null = null

  phonePlanStats: MonthlyStatistics | null = null

  employeeCounts: EmployeeCounts | null = null

  caseItemCounts: CaseItemCounts | null = null

  exitEmployees: ExitEmployee[] | null = null

  shippedOrders: ShippedOrder[] | null = null

  unreturnedDevices: UnreturnedDevice[] | null = null

  prolongedRentals: ProlongedRentals | null = null

  fleetOverview: FleetItem[] | null = null

  caseItemMonthlyOverview: CaseItemOverview[] | null = null

  isLoadingCachedData: boolean = false

  isLoadingData: boolean = false

  isError: boolean = false

  lastFetch: DateTime | null = null

  organisationId: string | null = null

  constructor(private readonly store: RootStore) {
    makeObservable(this, {
      caseItemCounts: observable,
      caseItemMonthlyOverview: observable,
      employeeCounts: observable,
      exitEmployees: observable,
      fetchDashboardData: action,
      fleetOverview: observable,
      isError: observable,
      isLoadingCachedData: observable,
      isLoadingData: observable,
      lastFetch: observable,
      phonePlanStats: observable,
      prolongedRentals: observable,
      refetchDashboardData: action,
      rentalStats: observable,
      setCaseItemCounts: action,
      setCaseItemMonthlyOverview: action,
      setEmployeeCounts: action,
      setExitEmployees: action,
      setFleetOverview: action,
      setIsError: action,
      setIsLoadingCachedData: action,
      setIsLoadingData: action,
      setLastFetch: action,
      setOrganisationId: action,
      setPhonePlanStats: action,
      setProlongedRentals: action,
      setRentalStats: action,
      setShippedOrders: action,
      setUnreturnedDevices: action,
      shippedOrders: observable,
      unreturnedDevices: observable,
    })
  }

  fetchDashboardData = async (
    organisationId: string | null | undefined,
    force?: boolean,
  ) => {
    if (!organisationId) {
      return
    }

    this.setIsLoadingData(true)

    const { jwt } = this.store.userStore.user

    if (!force) {
      this.setIsLoadingCachedData(true)

      await this.fetchDashboardDataFromApiCached(organisationId, jwt)
    }

    await this.fetchDashboardDataFromApi(organisationId, jwt, force)
  }

  fetchDashboardDataFromApiCached = async (
    organisationId: string,
    jwt: string | null,
  ) => {
    fetch(`${window.GO_API_URL}/organisation-dashboard-cached`, {
      body: JSON.stringify({
        organisationId: Number(organisationId),
      }),
      headers: {
        authorization: `Bearer ${jwt}`,
      },
      method: 'POST',
    })
      .then(response => response.json())
      .then((response: OrganisationDashboardResponse) => {
        this.setDashboardData(response)
        this.setOrganisationId(organisationId)
        this.setLastFetch(DateTime.fromISO(response.last_updated_at))
        this.setIsLoadingCachedData(false)
      })
      .catch(() => {
        this.setIsError(true)
        this.setIsLoadingCachedData(false)
      })
  }

  fetchDashboardDataFromApi = async (
    organisationId: string,
    jwt: string | null,
    force?: boolean,
  ) => {
    const fetchUrl = force
      ? `${window.GO_API_URL}/organisation-dashboard?force_fetch=true`
      : `${window.GO_API_URL}/organisation-dashboard`

    fetch(fetchUrl, {
      body: JSON.stringify({
        organisationId: Number(organisationId),
      }),
      headers: {
        authorization: `Bearer ${jwt}`,
      },
      method: 'POST',
    })
      .then(response => response.json())
      .then((response: OrganisationDashboardResponse) => {
        this.setDashboardData(response)
        this.setOrganisationId(organisationId)
        this.setLastFetch(DateTime.fromISO(response.last_updated_at))
        this.setIsLoadingData(false)
      })
      .catch(() => {
        this.setIsError(true)
        this.setIsLoadingData(false)
      })
  }

  refetchDashboardData = async () => {
    await this.fetchDashboardData(this.organisationId, true)
  }

  setDashboardData = (data: OrganisationDashboardResponse) => {
    this.setRentalStats(data.rentalStats)
    this.setEmployeeCounts(data.employeeCounts)
    this.setPhonePlanStats(data.phonePlanStats)
    this.setCaseItemCounts(data.caseItemCounts)
    this.setExitEmployees(data.exitEmployees)
    this.setShippedOrders(data.shippedOrders)
    this.setUnreturnedDevices(data.unreturnedDevices)
    this.setProlongedRentals(data.prolongedRentals)
    this.setFleetOverview(data.fleetOverview)
    this.setCaseItemMonthlyOverview(data.caseItemMonthlyOverview)
  }

  setRentalStats = (rentalStats: MonthlyStatistics | null) => {
    this.rentalStats = rentalStats
  }

  setPhonePlanStats = (phonePlanStats: MonthlyStatistics | null) => {
    this.phonePlanStats = phonePlanStats
  }

  setEmployeeCounts = (employeeCounts: EmployeeCounts | null) => {
    this.employeeCounts = employeeCounts
  }

  setCaseItemCounts = (caseItemCounts: CaseItemCounts | null) => {
    this.caseItemCounts = caseItemCounts
  }

  setExitEmployees = (exitEmployees: ExitEmployee[] | null) => {
    this.exitEmployees = exitEmployees
  }

  setShippedOrders = (shippedOrders: ShippedOrder[] | null) => {
    this.shippedOrders = shippedOrders
  }

  setUnreturnedDevices = (unreturnedDevices: UnreturnedDevice[] | null) => {
    this.unreturnedDevices = unreturnedDevices
  }

  setProlongedRentals = (prolongedRentals: ProlongedRentals | null) => {
    this.prolongedRentals = prolongedRentals
  }

  setFleetOverview = (fleetOverview: FleetItem[] | null) => {
    this.fleetOverview = fleetOverview
  }

  setCaseItemMonthlyOverview = (
    caseItemMonthlyOverview: CaseItemOverview[] | null,
  ) => {
    this.caseItemMonthlyOverview = caseItemMonthlyOverview
  }

  setIsLoadingCachedData = (isLoading: boolean) => {
    this.isLoadingCachedData = isLoading
  }

  setIsLoadingData = (isLoading: boolean) => {
    this.isLoadingData = isLoading
  }

  setIsError = (isError: boolean) => {
    this.isError = isError
  }

  setLastFetch = (time: DateTime | null) => {
    this.lastFetch = time
  }

  setOrganisationId = (organisationId: string | null) => {
    this.organisationId = organisationId
  }
}
