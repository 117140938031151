import { z } from 'zod'

import { zSelect } from 'Lib/zod'

import { FORM_MESSAGES_MAP } from 'Constants/formMessages'

export const updateTrackingModalFormSchema = z.object({
  carrier: zSelect.refine(arg => !!arg?.value, FORM_MESSAGES_MAP.REQUIRED),
  trackingReference: z.string().min(1, FORM_MESSAGES_MAP.REQUIRED),
})
