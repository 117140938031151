import { EMPTY_SELECT_OPTION } from 'Constants/form'

import { NewPhonePlanModalFormSchema } from './NewPhonePlanModal.types'

export const newPhonePlanModalDefaultValues: NewPhonePlanModalFormSchema = {
  employees: [{ name: '' }],
  message: '',
  phonePlan: EMPTY_SELECT_OPTION,
  quantity: '1',
}
