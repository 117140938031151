import { PortalSectionEnum } from './portalSection'

// Page links
export const E2E_LINK_DASHBOARD = 'E2E_LINK_DASHBOARD'
export const E2E_LINK_DEPOTS = 'E2E_LINK_DEPOTS'
export const E2E_LINK_DEPOT_DETAILS = 'E2E_LINK_DEPOT_DETAILS'
export const E2E_LINK_EMPLOYEES = 'E2E_LINK_EMPLOYEES'
export const E2E_LINK_EMPLOYEE_DETAILS = 'E2E_LINK_EMPLOYEE_DETAILS'
export const E2E_LINK_INVOICES = 'E2E_LINK_INVOICES'
export const E2E_LINK_PHONE_PLANS = 'E2E_LINK_PHONE_PLANS'
export const E2E_LINK_PHONE_PLAN_DETAILS = 'E2E_LINK_PHONE_PLAN_DETAILS'
export const E2E_LINK_PURCHASES = 'E2E_LINK_PURCHASES'
export const E2E_LINK_RENTALS = 'E2E_LINK_RENTALS'
export const E2E_LINK_RENTAL_DETAILS = 'E2E_LINK_RENTAL_DETAILS'
export const E2E_LINK_ORDERS = 'E2E_LINK_ORDERS'
export const E2E_LINK_ORDER_DETAILS = 'E2E_LINK_ORDER_DETAILS'
export const E2E_LINK_CONTACT = 'E2E_LINK_CONTACT'
export const E2E_LINK_NEW_ORDER = 'E2E_LINK_NEW_ORDER'
export const E2E_LINK_DATA_PRIVACY = 'E2E_LINK_DATA_PRIVACY'
export const E2E_LINK_ACCOUNT = 'E2E_LINK_ACCOUNT'
export const E2E_LINK_RETURNS = 'E2E_LINK_RETURNS'
export const E2E_LINK_RETURN_DETAILS = 'E2E_LINK_RETURN_DETAILS'
export const E2E_LINK_ORDERS_AND_RETURNS = 'E2E_LINK_ORDERS_AND_RETURNS'
export const E2E_LINK_SITEMAP = 'E2E_LINK_SITEMAP'
export const E2E_LINK_LOGIN_PAGE = 'E2E_LINK_LOGIN_PAGE'

export const E2E_LINK_SECTION_KEYS: Record<PortalSectionEnum, string> = {
  [PortalSectionEnum.Dashboard]: E2E_LINK_DASHBOARD,
  [PortalSectionEnum.Depots]: E2E_LINK_DEPOTS,
  [PortalSectionEnum.DepotDetails]: E2E_LINK_DEPOT_DETAILS,
  [PortalSectionEnum.Employees]: E2E_LINK_EMPLOYEES,
  [PortalSectionEnum.EmployeeDetails]: E2E_LINK_EMPLOYEE_DETAILS,
  [PortalSectionEnum.Invoices]: E2E_LINK_INVOICES,
  [PortalSectionEnum.PhonePlans]: E2E_LINK_PHONE_PLANS,
  [PortalSectionEnum.PhonePlanDetails]: E2E_LINK_PHONE_PLAN_DETAILS,
  [PortalSectionEnum.Purchases]: E2E_LINK_PURCHASES,
  [PortalSectionEnum.Rentals]: E2E_LINK_RENTALS,
  [PortalSectionEnum.RentalDetails]: E2E_LINK_RENTAL_DETAILS,
  [PortalSectionEnum.Orders]: E2E_LINK_ORDERS,
  [PortalSectionEnum.OrderDetails]: E2E_LINK_ORDER_DETAILS,
  [PortalSectionEnum.Contact]: E2E_LINK_CONTACT,
  [PortalSectionEnum.NewOrder]: E2E_LINK_NEW_ORDER,
  [PortalSectionEnum.DataPrivacy]: E2E_LINK_DATA_PRIVACY,
  [PortalSectionEnum.Account]: E2E_LINK_ACCOUNT,
  [PortalSectionEnum.Returns]: E2E_LINK_RETURNS,
  [PortalSectionEnum.ReturnDetails]: E2E_LINK_RETURN_DETAILS,
  [PortalSectionEnum.OrdersAndReturns]: E2E_LINK_ORDERS_AND_RETURNS,
  [PortalSectionEnum.Sitemap]: E2E_LINK_SITEMAP,
  [PortalSectionEnum.LoginPage]: E2E_LINK_LOGIN_PAGE,
}

// Page headings
export const E2E_HEADING_DASHBOARD = 'E2E_HEADING_DASHBOARD'
export const E2E_HEADING_DEPOTS = 'E2E_HEADING_DEPOTS'
export const E2E_HEADING_DEPOT_DETAILS = 'E2E_HEADING_DEPOT_DETAILS'
export const E2E_HEADING_EMPLOYEES = 'E2E_HEADING_EMPLOYEES'
export const E2E_HEADING_EMPLOYEE_DETAILS = 'E2E_HEADING_EMPLOYEE_DETAILS'
export const E2E_HEADING_INVOICES = 'E2E_HEADING_INVOICES'
export const E2E_HEADING_PHONE_PLANS = 'E2E_HEADING_PHONE_PLANS'
export const E2E_HEADING_PHONE_PLAN_DETAILS = 'E2E_HEADING_PHONE_PLAN_DETAILS'
export const E2E_HEADING_PURCHASES = 'E2E_HEADING_PURCHASES'
export const E2E_HEADING_RENTALS = 'E2E_HEADING_RENTALS'
export const E2E_HEADING_RENTAL_DETAILS = 'E2E_HEADING_RENTAL_DETAILS'
export const E2E_HEADING_ORDERS = 'E2E_HEADING_ORDERS'
export const E2E_HEADING_ORDER_DETAILS = 'E2E_HEADING_ORDER_DETAILS'
export const E2E_HEADING_CONTACT = 'E2E_HEADING_CONTACT'
export const E2E_HEADING_NEW_ORDER = 'E2E_HEADING_NEW_ORDER'
export const E2E_HEADING_DATA_PRIVACY = 'E2E_HEADING_DATA_PRIVACY'
export const E2E_HEADING_ACCOUNT = 'E2E_HEADING_ACCOUNT'
export const E2E_HEADING_RETURNS = 'E2E_HEADING_RETURNS'
export const E2E_HEADING_RETURN_DETAILS = 'E2E_HEADING_RETURN_DETAILS'
export const E2E_HEADING_ORDERS_AND_RETURNS = 'E2E_HEADING_ORDERS_AND_RETURNS'
export const E2E_HEADING_SITEMAP = 'E2E_HEADING_SITEMAP'
export const E2E_HEADING_LOGIN_PAGE = 'E2E_HEADING_LOGIN_PAGE'

export const E2E_HEADING_SECTION_KEYS: Record<PortalSectionEnum, string> = {
  [PortalSectionEnum.Dashboard]: E2E_HEADING_DASHBOARD,
  [PortalSectionEnum.Depots]: E2E_HEADING_DEPOTS,
  [PortalSectionEnum.DepotDetails]: E2E_HEADING_DEPOT_DETAILS,
  [PortalSectionEnum.Employees]: E2E_HEADING_EMPLOYEES,
  [PortalSectionEnum.EmployeeDetails]: E2E_HEADING_EMPLOYEE_DETAILS,
  [PortalSectionEnum.Invoices]: E2E_HEADING_INVOICES,
  [PortalSectionEnum.PhonePlans]: E2E_HEADING_PHONE_PLANS,
  [PortalSectionEnum.PhonePlanDetails]: E2E_HEADING_PHONE_PLAN_DETAILS,
  [PortalSectionEnum.Purchases]: E2E_HEADING_PURCHASES,
  [PortalSectionEnum.Rentals]: E2E_HEADING_RENTALS,
  [PortalSectionEnum.RentalDetails]: E2E_HEADING_RENTAL_DETAILS,
  [PortalSectionEnum.Orders]: E2E_HEADING_ORDERS,
  [PortalSectionEnum.OrderDetails]: E2E_HEADING_ORDER_DETAILS,
  [PortalSectionEnum.Contact]: E2E_HEADING_CONTACT,
  [PortalSectionEnum.NewOrder]: E2E_HEADING_NEW_ORDER,
  [PortalSectionEnum.DataPrivacy]: E2E_HEADING_DATA_PRIVACY,
  [PortalSectionEnum.Account]: E2E_HEADING_ACCOUNT,
  [PortalSectionEnum.Returns]: E2E_HEADING_RETURNS,
  [PortalSectionEnum.ReturnDetails]: E2E_HEADING_RETURN_DETAILS,
  [PortalSectionEnum.OrdersAndReturns]: E2E_HEADING_ORDERS_AND_RETURNS,
  [PortalSectionEnum.Sitemap]: E2E_HEADING_SITEMAP,
  [PortalSectionEnum.LoginPage]: E2E_HEADING_LOGIN_PAGE,
}

// Page table links
export const E2E_TABLE_LINK_RENTALS = 'E2E_TABLE_LINK_RENTALS'
export const E2E_TABLE_LINK_PHONE_PLANS = 'E2E_TABLE_LINK_PHONE_PLANS'
export const E2E_TABLE_LINK_EMPLOYEES = 'E2E_TABLE_LINK_EMPLOYEES'
export const E2E_TABLE_LINK_DEPOTS = 'E2E_TABLE_LINK_DEPOTS'
export const E2E_TABLE_LINK_DEPOT = 'E2E_TABLE_LINK_DEPOT'
export const E2E_TABLE_LINK_INVOICES = 'E2E_TABLE_LINK_INVOICES'
export const E2E_TABLE_LINK_ORDERS = 'E2E_TABLE_LINK_ORDERS'
export const E2E_TABLE_LINK_SEARCH_RESULTS = 'E2E_TABLE_LINK_SEARCH_RESULTS'
export const E2E_TABLE_LINK_RETURNS = 'E2E_TABLE_LINK_SEARCH_RESULTS'

// Table misc
export const E2E_PAGE_TABLE_SEARCH = 'E2E_PAGE_TABLE_SEARCH'
export const E2E_TABLE_DEVICE_NAME = 'E2E_TABLE_DEVICE_NAME'
export const E2E_EMPLOYEES_PAGE_NEW_EMPLOYEE = 'E2E_EMPLOYEES_PAGE_NEW_EMPLOYEE'
export const E2E_DEPOT_TABLE_SUMMARY_BUTTON = 'E2E_DEPOT_TABLE_SUMMARY_BUTTON'
export const E2E_PHONE_PLAN_TABLE_NEW_PHONE_PLAN =
  'E2E_PHONE_PLAN_TABLE_NEW_PHONE_PLAN'
export const E2E_TABLE_ROW = 'E2E_TABLE_ROW'
export const E2E_SORTABLE_TABLE_HEADER = 'E2E_SORTABLE_TABLE_HEADER'
export const E2E_TABLE_DOWNLOAD_REPORT = 'E2E_TABLE_DOWNLOAD_REPORT'
export const E2E_TABLE_DOWNLOAD_REPORT_INFO = 'E2E_TABLE_DOWNLOAD_REPORT_INFO'

// Login form
export const E2E_LOGIN_EMAIL = 'E2E_LOGIN_EMAIL'
export const E2E_LOGIN_PASSWORD = 'E2E_LOGIN_PASSWORD'
export const E2E_LOGIN_SUBMIT = 'E2E_LOGIN_SUBMIT'

// Logout
export const E2E_LOGOUT = 'E2E_LOGOUT'

// Dashboard cards
export const E2E_RENTALS_CARD = 'E2E_RENTALS_CARD'
export const E2E_EMPLOYEES_CARD = 'E2E_EMPLOYEES_CARD'
export const E2E_DEPOTS_CARD = 'E2E_DEPOTS_CARD'
export const E2E_ORDERS_CARD = 'E2E_ORDERS_CARD'
export const E2E_PHONEPLANS_CARD = 'E2E_PHONEPLANS_CARD'
export const E2E_INVOICES_CARD = 'E2E_INVOICES_CARD'

// Dashboard buttons
export const E2E_NEW_PHONEPLAN_BUTTON = 'E2E_NEW_PHONEPLAN_BUTTON'

// Preview column
export const E2E_SHOW_PREVIEW_COLUMN_BUTTON = 'E2E_SHOW_PREVIEW_COLUMN_BUTTON'
export const E2E_PREVIEW_COLUMN_NAME = 'E2E_PREVIEW_COLUMN_NAME'

// Preview column order
export const E2E_PREVIEW_COLUMN_ORDER_FOOTER_BUTTON =
  'E2E_PREVIEW_COLUMN_ORDER_FOOTER_BUTTON'

// Rental actions
export const E2E_ORDER_EARLY_UGPRADE_ACTION = 'E2E_ORDER_EARLY_UGPRADE_ACTION'
export const E2E_ORDER_LEGACY_UPGRADE_ACTION = 'E2E_ORDER_LEGACY_UPGRADE_ACTION'
export const E2E_ORDER_UPGRADE_ACTION = 'E2E_ORDER_UPGRADE_ACTION'
export const E2E_ORDER_PROLONG_ACTION = 'E2E_ORDER_PROLONG_ACTION'
export const E2E_REASSIGN_DEVICE_ACTION = 'E2E_REASSIGN_DEVICE_ACTION'
export const E2E_REPLACE_BROKEN_DEVICE_ACTION =
  'E2E_REPLACE_BROKEN_DEVICE_ACTION'
export const E2E_LOST_STOLEN_DEVICE_ACTION = 'E2E_LOST_STOLEN_DEVICE_ACTION'
export const E2E_EXCHANGE_DEVICE_ACTION = 'E2E_EXCHANGE_DEVICE_ACTION'
export const E2E_RETURN_DEVICE_ACTION = 'E2E_RETURN_DEVICE_ACTION'
export const E2E_CANCEL_DEVICE_ACTION = 'E2E_CANCEL_DEVICE_ACTION'
export const E2E_PURCHASE_DEVICE_ACTION = 'E2E_PURCHASE_DEVICE_ACTION'
export const E2E_NEW_PHONEPLAN_ACTION = 'E2E_NEW_PHONEPLAN_ACTION'
export const E2E_REPLACE_FROM_DEPOT_ACTION = 'E2E_REPLACE_FROM_DEPOT_ACTION'

// Employee actions
export const E2E_NEW_ORDER_ACTION = 'E2E_NEW_ORDER_ACTION'
export const E2E_ASSIGN_TO_EMPLOYEE_ACTION = 'E2E_ASSIGN_TO_EMPLOYEE_ACTION'
export const E2E_EMPLOYEE_LEAVES_ACTION = 'E2E_EMPLOYEE_LEAVES_ACTION'

// Return actions
export const E2E_CREATE_RETURN_LABEL_ACTION = 'E2E_CREATE_RETURN_LABEL_ACTION'
export const E2E_UPDATE_TRACKING_INFO_ACTION = 'E2E_UPDATE_TRACKING_INFO_ACTION'

// Order flow
export const E2E_NEW_ORDER_BUTTON = 'E2E_NEW_ORDER_BUTTON'
export const E2E_NEW_ORDER_SUBMIT = 'E2E_NEW_ORDER_SUBMIT'
export const E2E_NEW_ORDER_DONE = 'E2E_NEW_ORDER_DONE'
export const E2E_NEW_ORDER_ACCESSORY_NEXT = 'E2E_NEW_ORDER_ACCESSORY_NEXT'
export const E2E_NEW_ORDER_ADDRESS_FORM_NEXT = 'E2E_NEW_ORDER_ADDRESS_FORM_NEXT'
export const E2E_NEW_ORDER_FOOTER_PREV = 'E2E_NEW_ORDER_FOOTER_PREV'
export const E2E_NEW_ORDER_FOOTER_NEXT = 'E2E_NEW_ORDER_FOOTER_NEXT'

export const E2E_ORDER_SELECTION_CARD = 'E2E_ORDER_SELECTION_CARD'

export const E2E_ORDER_ALERT_CANNOT_ORDER_MORE =
  'E2E_ORDER_ALERT_CANNOT_ORDER_MORE'
export const E2E_ORDER_ALERT_ACCESSORIES_LIMIT =
  'E2E_ORDER_ALERT_ACCESSORIES_LIMIT'

// Modals
export const E2E_REPLACE_BROKEN_DEVICE_BUTTON =
  'E2E_REPLACE_BROKEN_DEVICE_BUTTON'
export const E2E_REPLACE_BROKEN_DEVICE_SUBMIT =
  'E2E_REPLACE_BROKEN_DEVICE_SUBMIT'
export const E2E_CANCEL_DEVICE_HEADER = 'E2E_CANCEL_DEVICE_HEADER'
export const E2E_CANCEL_DEVICE_TEXTAREA = 'E2E_CANCEL_DEVICE_TEXTAREA'
export const E2E_CANCEL_DEVICE_SUBMIT = 'E2E_CANCEL_DEVICE_SUBMIT'
export const E2E_REASSIGN_DEVICE_SUBMIT = 'E2E_REASSIGN_DEVICE_SUBMIT'
export const E2E_REASSIGN_DEVICE_EMPLOYEE_SELECT =
  'E2E_REASSIGN_DEVICE_EMPLOYEE_SELECT'
export const E2E_RETURN_DEVICE_MODAL_SUBMIT = 'E2E_RETURN_DEVICE_MODAL_SUBMIT'
export const E2E_EMPLOYEE_OFFBOARDING_SUBMIT = 'E2E_EMPLOYEE_OFFBOARDING_SUBMIT'
export const E2E_NEW_PHONE_PLAN_MODAL_NAME_INPUT =
  'E2E_NEW_PHONE_PLAN_MODAL_NAME_INPUT'
export const E2E_NEW_PHONE_PLAN_MODAL_SUBMIT = 'E2E_NEW_PHONE_PLAN_MODAL_SUBMIT'
export const E2E_NEW_PHONE_PLAN_MODAL_MESSAGE =
  'E2E_NEW_PHONE_PLAN_MODAL_MESSAGE'
export const E2E_DEPOTS_DROPDOWN = 'E2E_DEPOTS_DROPDOWN'
export const E2E_ASSIGN_TO_EMPLOYEE_MODAL_SUBMIT =
  'E2E_ASSIGN_TO_EMPLOYEE_MODAL_SUBMIT'
export const E2E_EXCHANGE_DEVICE_MODAL_SUBMIT =
  'E2E_EXCHANGE_DEVICE_MODAL_SUBMIT'
export const E2E_CREATE_SHIPPING_LABEL_HEADER =
  'E2E_CREATE_SHIPPING_LABEL_HEADER'
export const E2E_CREATE_SHIPPING_LABEL_SUBMIT =
  'E2E_CREATE_SHIPPING_LABEL_SUBMIT'
export const E2E_CREATE_SHIPPING_LABEL_COUNTRY =
  'E2E_CREATE_SHIPPING_LABEL_COUNTRY'
export const E2E_UPDATE_TRACKING_MODAL_HEADER =
  'E2E_UPDATE_TRACKING_MODAL_HEADER'
export const E2E_UPDATE_TRACKING_MODAL_SUBMIT =
  'E2E_UPDATE_TRACKING_MODAL_SUBMIT'

// Modals - Lost/Stolen device
export const E2E_LOST_STOLEN_REPLACEMENT_NONE =
  'E2E_LOST_STOLEN_REPLACEMENT_NONE'
export const E2E_LOST_STOLEN_REPLACEMENT_EVERPHONE =
  'E2E_LOST_STOLEN_REPLACEMENT_EVERPHONE'
export const E2E_LOST_STOLEN_REPLACEMENT_DEPOT =
  'E2E_LOST_STOLEN_REPLACEMENT_DEPOT'

export const E2E_LOST_STOLEN_BUTTON_BACK = 'E2E_LOST_STOLEN_BUTTON_BACK'
export const E2E_LOST_STOLEN_BUTTON_SUBMIT = 'E2E_LOST_STOLEN_SUBMIT'
export const E2E_LOST_STOLEN_BUTTON_CLOSE = 'E2E_LOST_STOLEN_BUTTON_CLOSE'

export const E2E_LOST_STOLEN_ORDER_ID = 'E2E_LOST_STOLEN_ORDER_ID'

// Modals - RepairExhange device
export const E2E_REPLACE_BROKEN_DEVICE_BUTTON_BACK =
  'E2E_REPLACE_BROKEN_DEVICE_BUTTON_BACK'
export const E2E_REPLACE_BROKEN_DEVICE_BUTTON_SUBMIT =
  'E2E_REPLACE_BROKEN_DEVICE_BUTTON_SUBMIT'
export const E2E_REPLACE_BROKEN_DEVICE_BUTTON_CLOSE =
  'E2E_REPLACE_BROKEN_DEVICE_BUTTON_CLOSE'

// Modals - Assign device
export const E2E_ASSIGN_DEVICE_BUTTON_CANCEL = 'E2E_ASSIGN_DEVICE_BUTTON_CANCEL'
export const E2E_ASSIGN_DEVICE_BUTTON_SUBMIT = 'E2E_ASSIGN_DEVICE_BUTTON_SUBMIT'
export const E2E_ASSIGN_DEVICE_DEPOT = 'E2E_ASSIGN_DEVICE_DEPOT'
export const E2E_ASSIGN_DEVICE_DEVICE = 'E2E_ASSIGN_DEVICE_DEVICE'
export const E2E_ASSIGN_DEVICE_MESSAGE = 'E2E_ASSIGN_DEVICE_MESSAGE'

// Presentation card
export const E2E_PRESENTATION_CARD_HEADER = 'E2E_PRESENTATION_CARD_HEADER'

// Entity card
export const E2E_ENTITY_LINK = 'E2E_ENTITY_LINK'

// Rental details card
export const E2E_LOST_STOLEN_RENTAL_DETAILS_ALERT =
  'E2E_LOST_STOLEN_RENTAL_DETAILS_ALERT'

// Cookie Approval
export const E2E_COOKIE_ACCEPT = 'E2E_COOKIE_ACCEPT'

// Badges
export const E2E_BADGE_UPGRADE_POSSIBLE = 'E2E_BADGE_UPGRADE_POSSIBLE'
export const E2E_BADGE_NEW_DEVICE = 'E2E_BADGE_NEW_DEVICE'

// States
export const E2E_ERROR = 'E2E_ERROR'
export const E2E_LOADING = 'E2E_LOADING'

// ACL Dropdown
export const E2E_ACL_DROPDOWN = 'E2E_ACL_DROPDOWN'
export const E2E_ACL_DROPDOWN_CONTENT = 'E2E_ACL_DROPDOWN_CONTENT'

// Forms
export const E2E_DATE_PICKER_CLEAR_BUTTON = 'E2E_CLEAR_BUTTON'
export const E2E_COMBOBOX = 'E2E_COMBOBOX'
export const E2E_PROLONGING_EXTEND_BUTTON = 'E2E_PROLONGING_EXTEND_BUTTON'
export const E2E_PROLONGING_BACK_TO_RENTAL_BUTTON =
  'E2E_PROLONGING_BACK_TO_RENTAL_BUTTON'
export const E2E_PROLONGING_CHOOSE_NEW_DEVICE =
  'E2E_PROLONGING_CHOOSE_NEW_DEVICE'
export const E2E_PROLONGING_BANNER_BUTTON = 'E2E_PROLONGING_BANNER_BUTTON'

// Edit employee form
export const E2E_EDIT_EMPLOYEE_FORM_FIRST_NAME =
  'E2E_EDIT_EMPLOYEE_FORM_FIRST_NAME'
export const E2E_EDIT_EMPLOYEE_FORM_LAST_NAME =
  'E2E_EDIT_EMPLOYEE_FORM_LAST_NAME'
export const E2E_EDIT_EMPLOYEE_FORM_EMAIL = 'E2E_EDIT_EMPLOYEE_FORM_EMAIL'
export const E2E_EDIT_EMPLOYEE_FORM_PHONE_NUMBER =
  'E2E_EDIT_EMPLOYEE_FORM_PHONE_NUMBER'
export const E2E_EDIT_EMPLOYEE_FORM_EMPLOYEE_ID =
  'E2E_EDIT_EMPLOYEE_FORM_EMPLOYEE_ID'
export const E2E_EDIT_EMPLOYEE_FORM_OFFICE = 'E2E_EDIT_EMPLOYEE_FORM_OFFICE'
export const E2E_EDIT_EMPLOYEE_FORM_COST_CENTER =
  'E2E_EDIT_EMPLOYEE_FORM_COST_CENTER'
export const E2E_EDIT_EMPLOYEE_FORM_RENT_CATEGORY_TIER =
  'E2E_EDIT_EMPLOYEE_FORM_RENT_CATEGORY_TIER'
export const E2E_EDIT_EMPLOYEE_FORM_ALLOW_LOGIN =
  'E2E_EDIT_EMPLOYEE_FORM_ALLOW_LOGIN'
export const E2E_EDIT_EMPLOYEE_FORM_SUBMIT_BUTTON =
  'E2E_EDIT_EMPLOYEE_FORM_SUBMIT_BUTTON'
export const E2E_EDIT_EMPLOYEE_FORM_BACK_BUTTON =
  'E2E_EDIT_EMPLOYEE_FORM_BACK_BUTTON'
export const E2E_EDIT_EMPLOYEE_FORM_EDIT_CONTENT =
  'E2E_EDIT_EMPLOYEE_FORM_EDIT_CONTENT'
export const E2E_EDIT_EMPLOYEE_FORM_CONFIRMATION_CONTENT =
  'E2E_EDIT_EMPLOYEE_FORM_CONFIRMATION_CONTENT'

// Forms - lost/stolen depot replacement form
export const E2E_LOST_STOLEN_PREVIOUS_DEVICE = 'E2E_LOST_STOLEN_PREVIOUS_DEVICE'
export const E2E_LOST_STOLEN_DEPOT = 'E2E_LOST_STOLEN_DEPOT'
export const E2E_LOST_STOLEN_NEXT_DEVICE = 'E2E_LOST_STOLEN_NEXT_DEVICE'

export const E2E_REPLACE_BROKEN_DEVICE_PREVIOUS_DEVICE =
  'E2E_REPLACE_BROKEN_DEVICE_PREVIOUS_DEVICE'
export const E2E_REPLACE_BROKEN_DEVICE_DEPOT = 'E2E_REPLACE_BROKEN_DEVICE_DEPOT'
export const E2E_REPLACE_BROKEN_DEVICE_NEXT_DEVICE =
  'E2E_REPLACE_BROKEN_DEVICE_NEXT_DEVICE'

// Forms - shared shipping address form
export const E2E_ADDRESS_FORM_ADDRESS_TYPE = 'E2E_ADDRESS_FORM_ADDRESS_TYPE'
export const E2E_ADDRESS_FORM_NAME = 'E2E_ADDRESS_FORM_NAME'
export const E2E_ADDRESS_FORM_NAME_ADDITION = 'E2E_ADDRESS_FORM_NAME_ADDITION'
export const E2E_ADDRESS_FORM_STREET = 'E2E_ADDRESS_FORM_STREET'
export const E2E_ADDRESS_FORM_STREET_ADDITION =
  'E2E_ADDRESS_FORM_STREET_ADDITION'
export const E2E_ADDRESS_FORM_STREET_NUMBER = 'E2E_ADDRESS_FORM_STREET_NUMBER'
export const E2E_ADDRESS_FORM_ZIPCODE = 'E2E_ADDRESS_FORM_ZIPCODE'
export const E2E_ADDRESS_FORM_CITY = 'E2E_ADDRESS_FORM_CITY'
export const E2E_ADDRESS_FORM_COUNTRY = 'E2E_ADDRESS_FORM_COUNTRY'
export const E2E_ADDRESS_FORM_US_ADDRESS = 'E2E_ADDRESS_FORM_US_ADDRESS'
export const E2E_ADDRESS_FORM_STATE = 'E2E_ADDRESS_FORM_STATE'

// Employee details page
export const E2E_EDIT_EMPLOYEE_BUTTON = 'E2E_EDIT_EMPLOYEE_BUTTON'

// Topbar
export const E2E_LANGUAGE_DROPDOWN = 'E2E_LANGUAGE_DROPDOWN'

// Alerts
export const E2E_DISABLED_ORDER_PROCESS_ALERT =
  'E2E_DISABLED_ORDER_PROCESS_ALERT'

// ServiceApp
export const E2E_ASK_QUESTION_ACTION = 'E2E_ASK_QUESTION_ACTION'

// GlobalSearch
export const E2E_GS_AUTOCOMPLETE_OPTIONS_TOGGLE =
  'E2E_GS_AUTOCOMPLETE_OPTIONS_TOGGLE'
export const E2E_GS_AUTOCOMPLETE_OPTIONS_ITEM =
  'E2E_GS_AUTOCOMPLETE_OPTIONS_ITEM'
export const E2E_GS_AUTOCOMPLETE_OPTIONS_ALL = 'E2E_GS_AUTOCOMPLETE_OPTIONS_ALL'

// filter column
export const E2E_FILTER_COLUMN_CLOSE_BUTTON = 'E2E_FILTER_COLUMN_CLOSE_BUTTON'

// list instantsearch
export const E2E_LIST_FILTER_BUTTON = 'E2E_LIST_FILTER_BUTTON'
export const E2E_LIST_CLEAR_ALL_FILTERS_BUTTON =
  'E2E_LIST_CLEAR_ALL_FILTERS_BUTTON'
export const E2E_LIST_ACTIVE_FILTER_BADGE = 'E2E_LIST_ACTIVE_FILTER_BADGE'
export const E2E_LIST_ACTIVE_FILTER_CLEAR_BUTTON =
  'E2E_LIST_ACTIVE_FILTER_CLEAR_BUTTON'

export const E2E_LIST_PAGE_SIZE_SELECT = 'E2E_LIST_PAGE_SIZE_SELECT'

export const E2E_LIST_TOTAL_FROM = 'E2E_LIST_TOTAL_FROM'
export const E2E_LIST_TOTAL_TO = 'E2E_LIST_TOTAL_TO'
export const E2E_LIST_TOTAL_OF = 'E2E_LIST_TOTAL_OF'

// filters
export const E2E_RENTAL_FILTER_START_AT = 'E2E_RENTAL_FILTER_START_AT'
export const E2E_RENTAL_FILTER_START_AT_TOOLBAR =
  'E2E_RENTAL_FILTER_START_AT_TOOLBAR'
export const E2E_RENTAL_FILTER_END_AT = 'E2E_RENTAL_FILTER_END_AT'
export const E2E_RENTAL_FILTER_END_AT_TOOLBAR =
  'E2E_RENTAL_FILTER_END_AT_TOOLBAR'
export const E2E_RENTAL_FILTER_EMPLOYEE_EXIT_DATE =
  'E2E_RENTAL_FILTER_EMPLOYEE_EXIT_DATE'
export const E2E_RENTAL_FILTER_EMPLOYEE_EXIT_DATE_TOOLBAR =
  'E2E_RENTAL_FILTER_EMPLOYEE_EXIT_DATE_TOOLBAR'
export const E2E_RENTAL_FILTER_MANUFACTURER = 'E2E_RENTAL_FILTER_MANUFACTURER'
export const E2E_RENTAL_FILTER_MODEL = 'E2E_RENTAL_FILTER_MODEL'
export const E2E_RENTAL_FILTER_MINIMUM_CONTRACT_DURATION =
  'E2E_RENTAL_FILTER_MINIMUM_CONTRACT_DURATION'
export const E2E_RENTAL_FILTER_COPAYMENT_AMOUNT =
  'E2E_RENTAL_FILTER_COPAYMENT_AMOUNT'
export const E2E_RENTAL_FILTER_IS_PROLONGED = 'E2E_RENTAL_FILTER_IS_PROLONGED'

export const E2E_EMPLOYEE_FILTER_FULL_NAME = 'E2E_EMPLOYEE_FILTER_FULL_NAME'
export const E2E_EMPLOYEE_FILTER_EMAIL = 'E2E_EMPLOYEE_FILTER_EMAIL'
export const E2E_EMPLOYEE_FILTER_PHONE_NUMBER =
  'E2E_EMPLOYEE_FILTER_PHONE_NUMBER'
export const E2E_EMPLOYEE_FILTER_ACTIVE = 'E2E_EMPLOYEE_FILTER_ACTIVE'
export const E2E_EMPLOYEE_FILTER_EXIT_DATE = 'E2E_EMPLOYEE_FILTER_EXIT_DATE'
export const E2E_EMPLOYEE_FILTER_EXIT_DATE_TOOLBAR =
  'E2E_EMPLOYEE_FILTER_EXIT_DATE_TOOLBAR'
export const E2E_EMPLOYEE_FILTER_RENT_CAT_TIER =
  'E2E_EMPLOYEE_FILTER_RENT_CAT_TIER'
export const E2E_EMPLOYEE_FILTER_OFFICE = 'E2E_EMPLOYEE_FILTER_OFFICE'
export const E2E_EMPLOYEE_FILTER_COST_CENTER = 'E2E_EMPLOYEE_FILTER_COST_CENTER'
export const E2E_EMPLOYEE_FILTER_EXTERNAL_REF =
  'E2E_EMPLOYEE_FILTER_EXTERNAL_REF'

export const E2E_ORDER_FILTER_TYPE = 'E2E_ORDER_FILTER_TYPE'
export const E2E_ORDER_FILTER_STATUS = 'E2E_ORDER_FILTER_STATUS'
export const E2E_ORDER_FILTER_DATE = 'E2E_ORDER_FILTER_DATE'
export const E2E_ORDER_FILTER_DATE_TOOLBAR = 'E2E_ORDER_FILTER_DATE_TOOLBAR'
export const E2E_ORDER_FILTER_MODEL = 'E2E_ORDER_FILTER_MODEL'
export const E2E_ORDER_FILTER_MANUFACTURER = 'E2E_ORDER_FILTER_MANUFACTURER'
export const E2E_ORDER_FILTER_DEVICE_TYPE = 'E2E_ORDER_FILTER_DEVICE_TYPE'
export const E2E_ORDER_FILTER_COPAYMENT = 'E2E_ORDER_FILTER_COPAYMENT'
export const E2E_ORDER_FILTER_EMPLOYEE = 'E2E_ORDER_FILTER_EMPLOYEE'
