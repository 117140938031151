import { FormattedMessage } from 'react-intl'
import { observer } from 'mobx-react'

import { Heading, PrimaryButton, Text } from 'Components/_theme'

import { DashboardServiceAppTitleProps } from './DashboardServiceAppTitle.types'

export const DashboardServiceAppTitle = observer(
  (props: DashboardServiceAppTitleProps) => {
    const { devicesCount, showRentDeviceButton, handleRentDeviceButtonClick } =
      props

    return (
      <div className="d-flex mb-2 justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <Heading as={4} size={1}>
            <FormattedMessage id="EmployeeDashboardServiceApp_title" />
          </Heading>
          {devicesCount > 0 && (
            <Text className="ms-2">
              <FormattedMessage id="Total" />: {devicesCount}
            </Text>
          )}
        </div>
        {devicesCount > 0 && showRentDeviceButton && (
          <PrimaryButton onClick={handleRentDeviceButtonClick}>
            <FormattedMessage id="EmployeeDashboardServiceApp_rent_button" />
          </PrimaryButton>
        )}
      </div>
    )
  },
)
