import { useCallback, useMemo } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { Card, PrimaryButton } from 'Components/_theme'
import { FaqCard } from 'Components/FaqCard/FaqCard'
import { FormatDate } from 'Components/FormatDate/FormatDate'
import { FormattedCurrency } from 'Components/FormattedCurrency/FormattedCurrency'
import { ImageWithFallback } from 'Components/ImageWithFallback/ImageWithFallback'
import { PageErrorState } from 'Components/PageErrorState/PageErrorState'

import { useStore } from 'Stores/index'

import { E2E_PROLONGING_BACK_TO_RENTAL_BUTTON } from 'Constants/e2e'

import { ImagePlaceholderTypesEnum } from 'Types/imagePlaceholders.type'

import { ORDER_TOTAL_AMOUNT } from '../OrderConfirmation/OrderConfirmation.constants'
import { BACK_BUTTON_TRANSLATION_IDS } from '../OrderConfirmation/OrderConfirmation.types'

export const ProlongConfirmation = () => {
  const { navigationStore, orderStore, rentalStore } = useStore()
  const { mainNavigationConfig } = navigationStore
  const { rentalDetails, rentalProlonging } = rentalStore
  const { originRoute } = orderStore

  const history = useHistory()
  const intl = useIntl()

  const handleBackButtonClick = useCallback(() => {
    if (originRoute?.url) {
      history.push(originRoute.url)

      return
    }

    history.push(mainNavigationConfig.url)
  }, [history, mainNavigationConfig, originRoute])

  const generateProlongDoneButtonLabel = useMemo(() => {
    if (originRoute?.type) {
      return intl.formatMessage({
        id: BACK_BUTTON_TRANSLATION_IDS[originRoute.type],
      })
    }

    return intl.formatMessage({
      id: 'ProlongConfirmation_to_dashboard',
    })
  }, [intl, originRoute])

  if (!rentalDetails || !rentalProlonging) {
    return <PageErrorState />
  }

  const { rental } = rentalDetails

  return (
    <Container className="OrderFlow pt-4" fluid>
      <Row>
        <Col xl={12} xxl={8}>
          <Card className="mb-9">
            <Row className="p-6 d-flex flex-column-reverse flex-lg-row flex-lg-nowrap">
              <Col lg={8} xs={12}>
                <Row>
                  <Col className="d-flex justify-content-center justify-content-lg-start">
                    <h4 className="mt-9 mt-lg-0">
                      <FormattedMessage id="ProlongConfirmation_heading" />
                    </h4>
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col>
                    <FormattedMessage id="ProlongConfirmation_subheading" />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="py-2 px-4 py-md-4 px-md-6">
              <Col className="my-2 mx-4 py-md-0 px-md-0">
                <Row className="grey-container p-4 d-flex justify-content-right justify-content-md-between">
                  <Col md={6} xs={12}>
                    <FormattedMessage id="ProlongConfirmation_device" />
                  </Col>

                  <Col
                    className="d-none d-md-flex justify-content-md-end"
                    md={2}
                    xs={0}
                  >
                    <FormattedMessage id="ProlongConfirmation_new_end_date" />
                  </Col>

                  <Col
                    className="d-none d-md-flex justify-content-md-end"
                    md={2}
                    xs={0}
                  >
                    <FormattedMessage id="ProlongConfirmation_new_price" />
                  </Col>
                </Row>

                <Row className="px-0 py-4 px-md-2 device-overview border-bottom justify-content-right justify-content-md-between">
                  <Col className="d-flex" md={6} xs={12}>
                    <div className="pe-4">
                      <ImageWithFallback
                        alt={rental.stocklistItem?.deviceDefinition?.name}
                        placeholderType={ImagePlaceholderTypesEnum.newOrderFlow}
                        src={
                          rental.stocklistItem?.deviceDefinition?.imageURL || ''
                        }
                      />
                    </div>

                    <div className="d-flex flex-column justify-content-center">
                      <strong>
                        {rental.stocklistItem?.deviceDefinition?.name}
                      </strong>
                    </div>
                  </Col>

                  <Col
                    className="d-none d-md-flex justify-content-end align-items-center"
                    xs={2}
                  >
                    <FormatDate value={rentalProlonging?.validTill} />
                  </Col>

                  <Col
                    className="d-none d-md-flex justify-content-end align-items-center"
                    xs={2}
                  >
                    <FormattedCurrency
                      value={rental.rentalProlongingPrice?.copaymentAmount || 0}
                    />
                  </Col>
                </Row>

                <Row className="p-2" data-testid={ORDER_TOTAL_AMOUNT}>
                  <Col className="d-flex flex-column justify-content-end">
                    <div className="d-flex justify-content-end align-items-center fw-bold">
                      <div>
                        <FormattedMessage id="ProlongConfirmation_total_amount" />
                      </div>

                      <div className="d-flex align-items-center ps-4">
                        <h5>
                          <FormattedCurrency
                            value={
                              rental.rentalProlongingPrice?.copaymentAmount || 0
                            }
                          />
                        </h5>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="pt-4 pt-md-0 pb-4 px-4">
              <Row className="sticky-menu p-2 p-sm-4">
                <Col className="px-0 d-flex justify-content-end">
                  <PrimaryButton
                    className="ms-2"
                    data-e2e={E2E_PROLONGING_BACK_TO_RENTAL_BUTTON}
                    onClick={handleBackButtonClick}
                  >
                    {generateProlongDoneButtonLabel}
                  </PrimaryButton>
                </Col>
              </Row>
            </Row>
          </Card>
        </Col>

        <Col xl={0} xxl={4}>
          <FaqCard isOnOrderFlow />
        </Col>
      </Row>
    </Container>
  )
}
