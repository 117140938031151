import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { clsx } from 'clsx'

import { IconProps } from './Icon.types'

export const Icon = (props: IconProps) => {
  const { className, size = 24, ...restProps } = props

  const classNames = clsx(
    'icon d-inline-flex align-items-center justify-content-center m-0',
    className,
  )

  return (
    <figure className={classNames}>
      <FontAwesomeIcon height={size} width={size} {...restProps} />
    </figure>
  )
}
