import { useCallback, useMemo, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useCurrentRefinements, useInstantSearch } from 'react-instantsearch'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { NetworkStatus } from '@apollo/client'
import { observer } from 'mobx-react'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { RentalsFilterColumn } from 'Modules/rentals/components/RentalsFilterColumn/RentalsFilterColumn'
import { RentalsPreviewColumn } from 'Modules/rentals/components/RentalsPreviewColumn/RentalsPreviewColumn'
import { RentalsTable as RentalsTableDefault } from 'Modules/rentals/components/RentalsTable/RentalsTable'
import { RentalsTable } from 'Modules/rentals/components/RentalsTableInstantsearch/RentalsTable'

import { Card } from 'Components/_theme'
import { ContentSidebar } from 'Components/ContentSidebar/ContentSidebar'
import { FaqCard } from 'Components/FaqCard/FaqCard'
import { ImpactTracker } from 'Components/ImpactTracker/ImpactTracker'
import { ObserveMeOnceIAmMounted } from 'Components/ObserveMeOnceIAmMounted/ObserveMeOnceIAmMounted'
import { PageErrorState } from 'Components/PageErrorState/PageErrorState'
import { PageHeader } from 'Components/PageHeader/PageHeader'
import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'
import { TableFooter } from 'Components/TableFooter/TableFooter'
import { TableHeader } from 'Components/TableHeader/TableHeader'

import { useStore } from 'Stores/index'
import { OriginRouteTypeEnum } from 'Stores/orderStore/orderStore.types'

import { useActiveUser, useIsOrganisation, useSidebarVisibility } from 'Hooks'

import { toggleIntercomVisibility } from 'Utilities'

import { E2E_LIST_FILTER_BUTTON, E2E_NEW_ORDER_BUTTON } from 'Constants/e2e'
import {
  getEmployeeDevices,
  getEmployeeDevicesVariables,
} from 'Constants/graphql/queries/listing/__generated__/getEmployeeDevices'
import {
  getOrganisationDevices,
  getOrganisationDevicesVariables,
} from 'Constants/graphql/queries/listing/__generated__/getOrganisationDevices'
import {
  GET_EMPLOYEE_DEVICES,
  GET_ORGANISATION_DEVICES,
} from 'Constants/graphql/queries/listing/GetDevices'
import { PortalSectionEnum } from 'Constants/portalSection'

import { GoToPage } from 'Algolia/components/GoToPage/GoToPage'
import { PageSizeSelect } from 'Algolia/components/PageSizeSelect/PageSizeSelect'
import { Pagination } from 'Algolia/components/Pagination/Pagination'

import { ReportType } from 'Portal/__generated__/globalTypes'
import { useDefaultListTable } from 'Portal/src/hooks/useDefaultListTable/useDefaultListTable'
import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

export const RentalsContent = observer(() => {
  const { portalConfigStore, orderStore, portalSettingsStore } = useStore()
  const { portalConfig } = portalConfigStore
  const { isAppReadOnly } = portalSettingsStore
  const { setOriginRoute } = orderStore

  const { activeEmployee, activeOrganisation } = useActiveUser()
  const isOrganisation = useIsOrganisation()
  const intl = useIntl()

  const { items: activeFilters } = useCurrentRefinements()
  const shouldUseDefaultListTable = activeFilters.length === 0

  const {
    data: dataDefaultListTable,
    error: errorDefaultListTable,
    hasNextPage: hasNextPageDefaultListTable,
    intersectionObserver: intersectionObserverDefaultListTable,
    isLoading: isLoadingDefaultListTable,
    networkStatus: networkStatusDefaultListTable,
    totalCount: totalCountDefaultListTable,
  } = useDefaultListTable<
    getOrganisationDevices,
    getOrganisationDevicesVariables,
    getEmployeeDevices,
    getEmployeeDevicesVariables
  >({
    dataObjectPropertyName: 'rentals',
    employeeQuery: GET_EMPLOYEE_DEVICES,
    isDefaultListTableInactive: !shouldUseDefaultListTable,
    organisationQuery: GET_ORGANISATION_DEVICES,
  })

  const [selectedEntityId, setSelectedEntityId] = useState('')
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null)
  const [isPreviewColumnOpen, setIsPreviewColumnOpen] = useState(false)
  const [isFilterColumnOpen, setIsFilterColumnOpen] = useState(false)
  const isSidebarOpen = isPreviewColumnOpen || isFilterColumnOpen
  const { isVisible: isPreviewColumnVisible } = useSidebarVisibility(
    isPreviewColumnOpen,
    isSidebarOpen,
  )
  const { isVisible: isFilterColumnVisible } = useSidebarVisibility(
    isFilterColumnOpen,
    isSidebarOpen,
  )

  const { status, results, error } = useInstantSearch()
  const isLoading = status === 'loading' && !results.hits
  const totalCount = results?.nbHits ?? 0

  const history = useHistory()

  const handleCloseFilterColumn = useCallback(() => {
    setIsFilterColumnOpen(false)
  }, [])

  const handleOpenPreviewColumn = useCallback(
    (id: string, rowIndex: number | null) => {
      handleCloseFilterColumn()

      toggleIntercomVisibility(false)

      setSelectedEntityId(id)
      setSelectedRowIndex(rowIndex)
      setIsPreviewColumnOpen(true)
    },
    [handleCloseFilterColumn],
  )

  const handleClosePreviewColumn = useCallback(() => {
    toggleIntercomVisibility(true)

    setIsPreviewColumnOpen(false)
    setSelectedRowIndex(null)
  }, [])

  const handleOpenFilterColumn = useCallback(() => {
    handleClosePreviewColumn()

    setIsFilterColumnOpen(true)
  }, [handleClosePreviewColumn])

  const { isNewSmartphoneButtonVisible } = useMemo((): {
    isNewSmartphoneButtonVisible: boolean
  } => {
    if (isOrganisation) {
      return {
        isNewSmartphoneButtonVisible:
          !!portalConfig?.newDevice &&
          !!portalConfig?.showNewDeviceButtonToAdmin &&
          !isAppReadOnly,
      }
    }

    const isNewSmartphoneButtonVisible =
      !!portalConfig?.newDevice &&
      !!activeEmployee?.canOrderNewDevice &&
      !!activeEmployee?.canOrder

    return {
      isNewSmartphoneButtonVisible,
    }
  }, [
    activeEmployee?.canOrder,
    activeEmployee?.canOrderNewDevice,
    isAppReadOnly,
    isOrganisation,
    portalConfig?.newDevice,
    portalConfig?.showNewDeviceButtonToAdmin,
  ])

  const handleClickNewSmartphoneButton = () => {
    setOriginRoute({
      type: OriginRouteTypeEnum.Rentals,
      url: history.location.pathname,
    })

    const path = composePath({
      isOrganisation,
      params: {
        employeeId: activeEmployee?.id,
        organisationId: activeOrganisation?.id,
      },
      paths: {
        employee: GLOBAL_ROUTE_PATHS.employee.orderNew,
        organisation: GLOBAL_ROUTE_PATHS.organisation.orderNew,
      },
    })

    history.push(path)
  }

  if (shouldUseDefaultListTable ? errorDefaultListTable : error) {
    return <PageErrorState />
  }

  if (
    shouldUseDefaultListTable
      ? isLoadingDefaultListTable && !dataDefaultListTable
      : isLoading
  ) {
    return <PageLoadingState />
  }

  if (shouldUseDefaultListTable && !dataDefaultListTable) {
    return <PageLoadingState />
  }

  return (
    <Container className="pt-6" fluid>
      <div role="banner">
        <PageHeader portalSection={PortalSectionEnum.Rentals} />
      </div>

      <Row>
        <Col xl={12} xxl={8}>
          <Card className="mb-4">
            <TableHeader
              buttonE2ESelector={E2E_NEW_ORDER_BUTTON}
              buttonIsVisible={isNewSmartphoneButtonVisible}
              buttonLabel={intl.formatMessage({ id: 'New_device' })}
              buttonOnClick={handleClickNewSmartphoneButton}
              downloadReportType={ReportType.rentals}
              filterButtonE2ESelector={E2E_LIST_FILTER_BUTTON}
              filterButtonOnClick={handleOpenFilterColumn}
              hideFilterButton={!!error}
              totalCount={
                shouldUseDefaultListTable
                  ? totalCountDefaultListTable
                  : totalCount
              }
            />

            {shouldUseDefaultListTable ? (
              <Row>
                <Col>
                  <RentalsTableDefault
                    isActiveEmployee={!isOrganisation}
                    isLoading={isLoadingDefaultListTable}
                    isSearching={
                      networkStatusDefaultListTable ===
                      NetworkStatus.setVariables
                    }
                    onOpenPreviewColumn={handleOpenPreviewColumn}
                    rentals={dataDefaultListTable}
                    selectedRowIndex={selectedRowIndex}
                  />
                </Col>
              </Row>
            ) : (
              <RentalsTable
                isLoading={isLoading}
                onOpenPreviewColumn={handleOpenPreviewColumn}
                selectedRowIndex={selectedRowIndex}
              />
            )}

            {!shouldUseDefaultListTable && (
              <TableFooter>
                <PageSizeSelect />

                <Pagination totalCount={totalCount} />

                <GoToPage totalCount={totalCount} />
              </TableFooter>
            )}
          </Card>

          {shouldUseDefaultListTable && (
            <Row className="p-1">
              <Col>
                {hasNextPageDefaultListTable && !isLoadingDefaultListTable && (
                  <ObserveMeOnceIAmMounted
                    intersectionObserver={intersectionObserverDefaultListTable}
                  />
                )}
              </Col>
            </Row>
          )}
        </Col>

        <ContentSidebar
          isSidebarOpen={isSidebarOpen}
          renderStickyColumnSideContent={
            <>
              <ImpactTracker />
              <FaqCard />
            </>
          }
        >
          <RentalsPreviewColumn
            entityId={selectedEntityId}
            isVisible={isPreviewColumnVisible}
            onClosePreviewColumn={handleClosePreviewColumn}
          />

          <RentalsFilterColumn
            isVisible={isFilterColumnVisible}
            onCloseFilterColumn={handleCloseFilterColumn}
          />
        </ContentSidebar>
      </Row>
    </Container>
  )
})
