import { FormatDate } from 'Components/FormatDate/FormatDate'
import { FormattedCurrency } from 'Components/FormattedCurrency/FormattedCurrency'

import { getOrderListPreviewColumn_caseItem } from 'Constants/graphql/queries/detail/__generated__/getOrderListPreviewColumn'

export const generateOrdersPreviewColumnContent = (
  caseItem?: getOrderListPreviewColumn_caseItem,
) => [
  {
    copyable: true,
    label: 'PreviewColumn_imei_1',
    value: caseItem?.rental?.stocklistItem?.imei,
  },
  {
    copyable: true,
    label: 'PreviewColumn_imei_2',
    value: caseItem?.rental?.stocklistItem?.imei2,
  },
  {
    copyable: true,
    label: 'PreviewColumn_serial',
    value: caseItem?.rental?.stocklistItem?.serialNumber,
  },
  {
    copyable: true,
    label: 'PreviewColumn_eid',
    value: caseItem?.rental?.stocklistItem?.eid,
  },
  {
    copyable: true,
    label: 'PreviewColumn_request_id',
    value: caseItem?.id,
  },
  {
    label: 'PreviewColumn_request_date',
    value: <FormatDate value={caseItem?.created} />,
  },
  {
    label: 'PreviewColumn_copayment_amount',
    value: (
      <FormattedCurrency
        value={
          caseItem?.data.requestedRentCategory?.rentCategory?.copaymentNet ??
          caseItem?.data.requestedOfferOrder?.copaymentPrice ??
          0
        }
      />
    ),
  },
]
