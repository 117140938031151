import { clsx } from 'clsx'

import { RadioButtonProps, RadioButtonSizeEnum } from './RadioButton.types'

export const RadioButton = (props: RadioButtonProps) => {
  const {
    disabled,
    id,
    isInvalid,
    label,
    name,
    onChange,
    size = RadioButtonSizeEnum.medium,
    value,
  } = props

  const classNames = clsx(
    'Button',
    'TertiaryButton',
    size === 'small' ? 'Button-small' : 'Button-medium',
    value && 'RadioButton-checked',
    disabled && 'RadioButton-disabled',
    isInvalid && 'RadioButton-is-invalid',
  )

  const handleKeyDown = (event: React.KeyboardEvent<HTMLImageElement>) => {
    if (event.key === 'Enter') {
      onChange(null)
    }
  }

  return (
    <div
      className="RadioButton"
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
    >
      <label className={classNames} htmlFor={id}>
        {label}
      </label>

      <input
        checked={value}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        type="radio"
      />
    </div>
  )
}
