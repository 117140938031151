import { useHistory } from 'react-router-dom'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { useActiveUser, useIsOrganisation } from 'Hooks'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

export enum CustomConfirmationPageType {
  RepairUpgrade = 'repair-upgrade',
}

/**
 * This hook is used to redirect user to the correct confirmation page inside
 * order flow as we have multiple order flows one for organisation and
 * other for employee
 */
export const useRedirectToConfirmationPage = (activeEmployeeId?: string) => {
  const history = useHistory()
  const { activeOrganisation } = useActiveUser()
  const isOrganisation = useIsOrganisation()

  const redirectToConfirmationPage = (
    customType?: CustomConfirmationPageType,
  ) => {
    const organisationEmployeeConfirmation = customType
      ? GLOBAL_ROUTE_PATHS.organisation.employeeOrderCustomConfirmation
      : GLOBAL_ROUTE_PATHS.organisation.employeeOrderConfirmation

    const organisationConfirmation = customType
      ? GLOBAL_ROUTE_PATHS.organisation.orderCustomConfirmation
      : GLOBAL_ROUTE_PATHS.organisation.orderConfirmation

    const path = composePath({
      isOrganisation,
      params: {
        customType: customType ?? undefined,
        employeeId: activeEmployeeId,
        organisationId: activeOrganisation?.id,
      },
      paths: {
        employee: customType
          ? GLOBAL_ROUTE_PATHS.employee.orderCustomConfirmation
          : GLOBAL_ROUTE_PATHS.employee.orderConfirmation,
        organisation: activeEmployeeId
          ? organisationEmployeeConfirmation
          : organisationConfirmation,
      },
    })

    history.replace(path)
  }

  return {
    redirectToConfirmationPage,
  }
}
