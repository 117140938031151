import { useCallback, useMemo, useState } from 'react'
import { useCurrentRefinements, useHits } from 'react-instantsearch'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { useMediaQuery } from '@react-hookz/web'
import { CellContext, ColumnDef, SortingState } from '@tanstack/react-table'
import { clsx } from 'clsx'
import { AlgoliaHit } from 'instantsearch.js'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { extractReturnCaseStatusData } from 'Modules/returns/utils/returns.utils'

import { Table } from 'Components/_theme'
import { FormatDate } from 'Components/FormatDate/FormatDate'
import { ImageWithFallback } from 'Components/ImageWithFallback/ImageWithFallback'
import { ReturnStatusBadge } from 'Components/ReturnStatusBadge/ReturnStatusBadge'

import { buttonize, getFullContactName } from 'Utilities'

import { PORTAL_BREAKPOINTS } from 'Constants/constants'
import { E2E_TABLE_DEVICE_NAME, E2E_TABLE_LINK_ORDERS } from 'Constants/e2e'

import { SearchResultsDocument } from 'Types/globalSearch/searchResultsDocument.types'
import { ImagePlaceholderTypesEnum } from 'Types/imagePlaceholders.type'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import { ReturnsTableProps, ReturnWithUrl } from './ReturnsTable.types'

export const ReturnsTable = observer((props: ReturnsTableProps) => {
  const { isLoading, selectedRowIndex, onOpenPreviewColumn } = props
  const [sorting, setSorting] = useState<SortingState>([])
  const { hits } = useHits<AlgoliaHit<SearchResultsDocument>>()
  const intl = useIntl()

  const { organisationId } = useParams<{
    organisationId: string
  }>()

  const { items } = useCurrentRefinements()

  const routerHistory = useHistory()
  const isTablet = useMediaQuery(`(min-width: ${PORTAL_BREAKPOINTS.LG})`)

  const handleEnterDetails = useCallback(
    (
      _event:
        | React.MouseEvent<HTMLDivElement>
        | React.KeyboardEvent<HTMLDivElement>,
      id: string,
      url: string,
      rowIndex: number,
    ) => {
      if (!isTablet) {
        routerHistory.push(url)

        return
      }

      if (typeof onOpenPreviewColumn === 'function') {
        onOpenPreviewColumn(id, rowIndex)
      }
    },
    [isTablet, onOpenPreviewColumn, routerHistory],
  )

  const renderReturnsHeader = useCallback(
    () => <FormattedMessage id="ReturnListContent_header__title" />,
    [],
  )

  const renderEmployeeHeader = useCallback(
    () => <FormattedMessage id="ReturnListContent_header__employee" />,
    [],
  )

  const renderCreatedHeader = useCallback(
    () => <FormattedMessage id="ReturnListContent_header__created" />,
    [],
  )

  const renderStatusHeader = useCallback(
    () => <FormattedMessage id="ReturnListContent_header__status" />,
    [],
  )

  const renderReturnCell = useCallback(
    (cell: CellContext<ReturnWithUrl, unknown>) => {
      const returnCase = cell.row.original

      return (
        <div
          className="d-inline-flex"
          {...buttonize(
            handleEnterDetails,
            returnCase.id,
            returnCase.url,
            cell.row.index,
          )}
        >
          <div className="d-flex justify-content-center align-items-center me-4 Table-device-image">
            <ImageWithFallback
              alt={
                returnCase['return_case.stocklist_item.name'] ||
                'New return case icon'
              }
              placeholderType={ImagePlaceholderTypesEnum.newOrderFlow}
              src={returnCase['return_case.stocklist_item.image_url'] || ''}
            />
          </div>

          <div className="d-flex flex-column justify-content-center text-ellipsis">
            <div className="d-flex align-items-center">
              <strong
                className="text-ellipsis"
                data-e2e={E2E_TABLE_DEVICE_NAME}
              >
                {returnCase['return_case.stocklist_item.name']}
              </strong>
            </div>

            <div className="d-flex table-text-subtitle text-muted">
              <span>#</span>

              <span className="text-ellipsis" data-orderid={returnCase.id}>
                {returnCase.id}
              </span>
            </div>
          </div>
        </div>
      )
    },
    [handleEnterDetails],
  )

  const renderExpandableContent = useCallback(
    (cell: CellContext<ReturnWithUrl, unknown>) => {
      const returnCase = cell.row.original

      const badgeData = extractReturnCaseStatusData(returnCase)

      return (
        <div className="w-100 Table-details">
          <div className="Table-details-row">
            <span className="Table-row--expandable-title">
              {renderEmployeeHeader()}
            </span>

            <span className="text-ellipsis">
              {returnCase['return_case.employee.first_name']
                ? getFullContactName({
                    firstName:
                      returnCase['return_case.employee.first_name'] ?? '',
                    lastName:
                      returnCase['return_case.employee.last_name'] ?? '',
                  })
                : '-'}
            </span>
          </div>

          <div className="Table-details-row">
            <span className="Table-row--expandable-title">
              {renderCreatedHeader()}
            </span>

            {returnCase['return_case.created'] &&
              returnCase['return_case.created'] > 0 && (
                <span>
                  <FormatDate
                    value={DateTime.fromSeconds(
                      returnCase['return_case.created'],
                    ).toJSDate()}
                  />
                </span>
              )}
          </div>

          <div className="Table-details-row">
            <span className="Table-row--expandable-title">
              {renderStatusHeader()}
            </span>

            {badgeData.status && (
              <span>
                <span>
                  <ReturnStatusBadge status={badgeData.status} />
                </span>
              </span>
            )}
          </div>
        </div>
      )
    },
    [renderCreatedHeader, renderEmployeeHeader, renderStatusHeader],
  )

  const renderEmployeeCell = useCallback(
    (cell: CellContext<ReturnWithUrl, unknown>) => {
      const returnCase = cell.row.original

      return (
        <div
          className="d-flex align-items-center h-100"
          {...buttonize(
            handleEnterDetails,
            returnCase.id,
            returnCase.url,
            cell.row.index,
          )}
        >
          <span className="text-ellipsis">
            {returnCase['return_case.employee.first_name']
              ? getFullContactName({
                  firstName:
                    returnCase['return_case.employee.first_name'] ?? '',
                  lastName: returnCase['return_case.employee.last_name'] ?? '',
                })
              : '-'}
          </span>
        </div>
      )
    },
    [handleEnterDetails],
  )

  const renderCreatedCell = useCallback(
    (cell: CellContext<ReturnWithUrl, unknown>) => {
      const returnCase = cell.row.original

      return (
        <div
          className="d-flex align-items-center h-100"
          {...buttonize(
            handleEnterDetails,
            returnCase.id,
            returnCase.url,
            cell.row.index,
          )}
        >
          {returnCase['return_case.created'] &&
            returnCase['return_case.created'] > 0 && (
              <FormatDate
                value={DateTime.fromSeconds(
                  returnCase['return_case.created'],
                ).toJSDate()}
              />
            )}
        </div>
      )
    },
    [handleEnterDetails],
  )

  const renderStatusCell = useCallback(
    (cell: CellContext<ReturnWithUrl, unknown>) => {
      const returnCase = cell.row.original

      const badgeData = extractReturnCaseStatusData(returnCase)

      return (
        <div
          className="d-flex align-items-center h-100"
          {...buttonize(
            handleEnterDetails,
            returnCase.id,
            returnCase.url,
            cell.row.index,
          )}
        >
          {badgeData.status && (
            <span>
              <ReturnStatusBadge status={badgeData.status} />
            </span>
          )}
        </div>
      )
    },
    [handleEnterDetails],
  )

  const columns = useMemo<ColumnDef<ReturnWithUrl>[]>(() => {
    const tableColumns: ColumnDef<ReturnWithUrl>[] = [
      {
        cell: renderReturnCell,
        header: renderReturnsHeader,
        id: 'order',
        meta: {
          ExpandableContent: renderExpandableContent,
        },
      },
      {
        accessorFn: (row: ReturnWithUrl) =>
          row['return_case.employee.first_name'],
        cell: renderEmployeeCell,
        enableSorting: true,
        header: renderEmployeeHeader,
        id: 'return_case.employee.first_name',
      },
      {
        accessorFn: (row: ReturnWithUrl) => row['return_case.created'],
        cell: renderCreatedCell,
        enableSorting: true,
        header: renderCreatedHeader,
        id: 'return_case.created',
      },
      {
        cell: renderStatusCell,
        header: renderStatusHeader,
        id: 'status',
      },
    ]

    return tableColumns
  }, [
    renderCreatedCell,
    renderCreatedHeader,
    renderEmployeeCell,
    renderEmployeeHeader,
    renderExpandableContent,
    renderReturnCell,
    renderReturnsHeader,
    renderStatusCell,
    renderStatusHeader,
  ])

  const data = useMemo<ReturnWithUrl[]>(() => {
    if (!hits.length) {
      return []
    }

    return hits.map(hit => {
      const url = composePath({
        params: {
          organisationId,
          returnCaseId: hit['return_case.id'],
        },
        path: GLOBAL_ROUTE_PATHS.organisation.return,
      })

      return {
        ...hit,
        url,
      }
    })
  }, [hits, organisationId])

  const hasFiltersApplied = items.length > 0
  const customNoResultMessage = hasFiltersApplied
    ? intl.formatMessage({
        id: 'Filter_no_results_content',
      })
    : undefined

  return (
    <Table
      className={clsx('Table--orders Table--isAdmin')}
      columns={columns}
      customNoResultsMessage={customNoResultMessage}
      data={data}
      e2eRowId={E2E_TABLE_LINK_ORDERS}
      isLoading={isLoading}
      onSortingChange={setSorting}
      selectedRowIndex={selectedRowIndex}
      sorting={sorting}
    />
  )
})
