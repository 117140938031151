import { ApolloQueryResult } from '@apollo/client'

import {
  getReturnCase,
  getReturnCase_returnCaseItem,
  getReturnCaseVariables,
} from 'Constants/graphql/queries/detail/__generated__/getReturnCase'

export type ReturnDetailsCardProps = {
  returnCase: getReturnCase_returnCaseItem
  title: string
  refetch: (
    variables?: Partial<getReturnCaseVariables> | undefined,
  ) => Promise<ApolloQueryResult<getReturnCase>>
}

export enum ReturnDetailsCardModalTypes {
  CreateReturnLabelModal,
  UpdateTrackingModal,
}
