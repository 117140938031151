import { useMemo } from 'react'
import { useNumericMenu } from 'react-instantsearch'

import { Label } from 'Components/_theme'
import { CheckboxButtonGroup } from 'Components/_theme/CheckboxButtonGroup/CheckboxButtonGroup'

import { scrollToTop } from 'Utilities'

import { useCurrentRefinement } from 'Algolia/hooks/useCurrentRefinement/useCurrentRefinement'

import { RadioFilterProps } from './RadioFilter.types'

export const RadioFilter = (props: RadioFilterProps) => {
  const { filterProps, label, checkboxButtonGroupProps } = props
  const { refine } = useNumericMenu({
    // not used but required by the hook
    items: [{ label: 'All' }],
    ...filterProps,
  })
  const currentRefinement = useCurrentRefinement(filterProps.attribute)
  const value = useMemo(() => {
    const [first, last] = currentRefinement
    let start: number | undefined
    let end: number | undefined

    if (first?.operator === '>=' && last?.operator === '<=') {
      start = Number(first.value)
      end = Number(last.value)
    } else if (first?.operator === '<=') {
      end = Number(first.value)
    } else if (first?.operator === '>=') {
      start = Number(first.value)
    }

    return encodeURI(
      JSON.stringify({
        end,
        start,
      }),
    )
  }, [currentRefinement])

  const handleChange = (values: string[]) => {
    scrollToTop()

    refine(
      values[0] ??
        encodeURI(
          JSON.stringify({
            end: undefined,
            start: undefined,
          }),
        ),
    )
  }

  return (
    <div className="Filter-input-container">
      <Label>{label}</Label>

      <CheckboxButtonGroup
        isToggle
        onChange={handleChange}
        value={value}
        {...checkboxButtonGroupProps}
      />
    </div>
  )
}
