import {
  IconDefinition,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core'

export const iconChangePhonePlan: IconDefinition = {
  icon: [
    32,
    32,
    [],
    'e004',
    'M8.115 4.792 L 3.360 9.588 3.361 19.394 C 3.362 30.183,3.341 29.501,3.692 30.221 C 3.912 30.673,4.468 31.271,4.913 31.535 C 5.100 31.646,5.421 31.790,5.627 31.855 L 6.000 31.973 15.973 31.973 C 25.339 31.973,25.967 31.968,26.287 31.879 C 26.828 31.730,27.242 31.482,27.694 31.034 C 28.027 30.705,28.149 30.538,28.316 30.179 C 28.431 29.934,28.550 29.591,28.582 29.417 C 28.664 28.971,28.664 3.029,28.582 2.583 C 28.386 1.510,27.501 0.522,26.400 0.145 L 26.053 0.027 19.462 0.012 L 12.871 -0.004 8.115 4.792 M25.547 16.000 L 25.547 28.800 16.027 28.800 L 6.507 28.800 6.507 19.850 L 6.507 10.899 10.307 7.051 L 14.107 3.202 19.827 3.201 L 25.547 3.200 25.547 16.000 M15.513 13.288 C 15.259 13.308,14.863 13.375,14.633 13.437 C 14.210 13.552,13.367 13.939,13.120 14.132 L 12.987 14.236 13.813 15.064 L 14.640 15.892 14.987 15.752 C 15.403 15.583,16.084 15.528,16.547 15.624 C 17.336 15.788,18.083 16.370,18.422 17.085 C 18.598 17.456,18.720 17.897,18.720 18.163 L 18.720 18.293 17.880 18.293 L 17.040 18.293 18.440 19.693 L 19.840 21.093 21.240 19.693 L 22.640 18.293 21.834 18.293 L 21.029 18.293 20.995 17.847 C 20.856 16.040,19.633 14.359,17.948 13.657 C 17.173 13.335,16.414 13.220,15.513 13.288 M10.784 16.838 L 9.447 18.213 10.204 18.240 L 10.960 18.267 10.974 18.427 C 11.155 20.547,12.154 22.046,13.944 22.885 C 14.607 23.196,15.200 23.323,16.000 23.326 C 16.983 23.331,17.792 23.117,18.608 22.639 L 18.946 22.441 18.105 21.598 L 17.263 20.755 16.996 20.860 C 16.323 21.125,15.523 21.082,14.837 20.744 C 13.955 20.309,13.358 19.448,13.319 18.557 L 13.307 18.267 14.098 18.252 L 14.890 18.237 13.543 16.865 C 12.803 16.111,12.180 15.486,12.159 15.478 C 12.138 15.469,11.519 16.081,10.784 16.838',
  ],
  iconName: 'changePhonePlan' as IconName,
  prefix: 'fac' as IconPrefix,
}
