import { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { observer } from 'mobx-react'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { PreviewColumn } from 'Components/PreviewColumn/PreviewColumn'
import { PreviewColumnTypeEnum } from 'Components/PreviewColumn/PreviewColumn.types'
import { PreviewColumnContent } from 'Components/PreviewColumn/PreviewColumnContent'
import { PreviewColumnHeader } from 'Components/PreviewColumn/PreviewColumnHeader'

import { useActiveUser, useIsOrganisation } from 'Hooks'

import {
  getRentalsPreviewColumn,
  getRentalsPreviewColumnVariables,
} from 'Constants/graphql/queries/detail/__generated__/getRentalsPreviewColumn'
import { GET_RENTALS_PREVIEW_COLUMN } from 'Constants/graphql/queries/detail/GetRental'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import { RentalsPreviewColumnProps } from './RentalsPreviewColumn.types'
import {
  generateRentalBadge,
  generateRentalsPreviewColumnContent,
} from './RentalsPreviewColumn.util'

export const RentalsPreviewColumn = observer(
  (props: RentalsPreviewColumnProps) => {
    const { entityId, onClosePreviewColumn, isVisible } = props
    const { activeEmployee, activeOrganisation } = useActiveUser()
    const isOrganisation = useIsOrganisation()
    const { employeeId, organisationId } = useParams<{
      employeeId?: string
      organisationId?: string
    }>()
    const intl = useIntl()

    const path = composePath({
      isOrganisation,
      params: {
        employeeId,
        organisationId,
        rentalId: entityId || '0',
      },
      paths: {
        employee: GLOBAL_ROUTE_PATHS.employee.rental,
        organisation: GLOBAL_ROUTE_PATHS.organisation.rental,
      },
    })

    const [getRentalsPreviewData, { loading: isLoading, data }] = useLazyQuery<
      getRentalsPreviewColumn,
      getRentalsPreviewColumnVariables
    >(GET_RENTALS_PREVIEW_COLUMN)

    const generateContent = useMemo(
      () =>
        generateRentalsPreviewColumnContent(
          intl,
          !!activeOrganisation,
          data?.rental,
        ),
      [activeOrganisation, data?.rental, intl],
    )

    const generateBadge = useMemo(
      () => generateRentalBadge(activeEmployee, data?.rental),
      [data?.rental, activeEmployee],
    )

    useEffect(() => {
      if (!entityId) {
        return
      }

      getRentalsPreviewData({
        variables: {
          id: entityId,
        },
      })
    }, [entityId, getRentalsPreviewData])

    return (
      <PreviewColumn
        contentElement={
          <PreviewColumnContent content={generateContent} twoColumn />
        }
        headerElement={
          <PreviewColumnHeader
            badge={generateBadge}
            image={data?.rental.stocklistItem?.deviceDefinition?.imageURL}
            name={data?.rental.stocklistItem?.deviceDefinition?.name ?? ''}
            onClosePreviewColumn={onClosePreviewColumn}
            productType={
              data?.rental.stocklistItem?.deviceDefinition?.productType
            }
            title={intl.formatMessage({ id: 'Rental' })}
          />
        }
        id={entityId}
        isLoading={isLoading}
        isVisible={isVisible}
        onClosePreviewColumn={onClosePreviewColumn}
        type={PreviewColumnTypeEnum.Rental}
        url={path}
      />
    )
  },
)
