import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import { isNull } from 'lodash-es'
import { observer } from 'mobx-react'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { EntityListCard } from 'Components/EntityListCard/EntityListCard'
import { EntityProps } from 'Components/EntityListCard/EntityListCard.types'

import { getFullContactName } from 'Utilities'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import { EmployeeEntityCardProps } from './EmployeeEntityCard.types'

export const EmployeeEntityCard = observer((props: EmployeeEntityCardProps) => {
  const { employee } = props
  const { organisationId } = useParams<{
    organisationId?: string
  }>()

  const generateEntity = useMemo((): EntityProps => {
    const link = composePath({
      params: {
        employeeId: employee?.id || '0',
        organisationId,
      },
      path: GLOBAL_ROUTE_PATHS.organisation.employee,
    })

    return {
      content: (
        <>
          {employee?.contact.email && (
            <p className="mb-0 mt-2 fs-6 text-truncate">
              <FormattedMessage id="Email" />:{' '}
              <span>{employee?.contact.email}</span>
            </p>
          )}

          {employee?.contact.phoneNumber && (
            <p className="mb-0 mt-2 fs-6 text-truncate">
              <FormattedMessage id="Phone_number" />:{' '}
              <span>{employee?.contact.phoneNumber}</span>
            </p>
          )}
        </>
      ),
      id: employee?.id ?? '0',
      link,
      title: getFullContactName(employee?.contact),
    }
  }, [employee?.contact, employee?.id, organisationId])

  if (isNull(employee)) {
    return null
  }

  return <EntityListCard entities={[generateEntity]} title="Employee" />
})
