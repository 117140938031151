import { useCallback, useEffect, useRef } from 'react'
import { Col } from 'react-bootstrap'
import { useMediaQuery, useMountEffect } from '@react-hookz/web'
import { clsx } from 'clsx'
import { debounce } from 'lodash-es'

import { PORTAL_BREAKPOINTS } from 'Constants/constants'

import { setContainerWidth } from 'Portal/src/utilities'

import { ContentSidebarProps } from './ContentSidebar.types'

export const ContentSidebar = (props: ContentSidebarProps) => {
  const { children, isSidebarOpen, renderStickyColumnSideContent } = props

  const destinationRef = useRef<HTMLDivElement>(null)
  const sourceRef = useRef<HTMLDivElement>(null)

  const isDesktop = useMediaQuery(`(min-width: ${PORTAL_BREAKPOINTS.XXL})`)

  const setFilterColumnWidth = useCallback(() => {
    setContainerWidth(destinationRef, sourceRef, isDesktop)
  }, [isDesktop])

  useMountEffect(() => {
    setFilterColumnWidth()
  })

  useEffect(() => {
    const debouncedUpdateContainerWidth = debounce(setFilterColumnWidth, 120)

    window.addEventListener('resize', debouncedUpdateContainerWidth)

    return () => {
      window.removeEventListener('resize', debouncedUpdateContainerWidth)
    }
  }, [setFilterColumnWidth])

  return (
    <Col ref={sourceRef} className="ContentSidebar" xl={12} xxl={4}>
      <aside>
        {renderStickyColumnSideContent && (
          <div
            className={clsx('ContentSidebar-static d-none d-lg-block', {
              'ContentSidebar-static--non-interactive': isSidebarOpen,
            })}
          >
            {renderStickyColumnSideContent}
          </div>
        )}

        <div
          ref={destinationRef}
          className={clsx(
            'ContentSidebar-dynamic',
            isSidebarOpen
              ? 'ContentSidebar-dynamic--opened'
              : 'ContentSidebar-dynamic--closed',
          )}
          role="dialog"
        >
          {children}
        </div>
      </aside>
    </Col>
  )
}
