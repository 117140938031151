import {
  faArrowRight,
  faArrowTrendDown,
  faArrowTrendUp,
} from '@fortawesome/pro-solid-svg-icons'

export const getProgressIcon = (progress: number) => {
  if (progress === 0) {
    return faArrowRight
  }

  if (progress > 0) {
    return faArrowTrendUp
  }

  return faArrowTrendDown
}
