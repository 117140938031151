import { gql } from '@apollo/client'

export const GET_CATALOG = gql`
  query getCatalog(
    $after: Int!
    $first: Int!
    $filter: Filter!
    $organisationId: Int!
  ) {
    catalog(filter: $filter) {
      offers {
        offerConfigGroups {
          id
          name
          displaySortOrder
          descriptions {
            descriptions {
              language
              content
            }
          }
          offerConfigs(
            filter: $filter
            pagination: { after: $after, first: $first }
          ) {
            offerConfigs {
              id
              forcePriceHide
              terms {
                offerTerms {
                  locale
                  content
                }
              }
              offer {
                id
                alternativeName
                customImage
                displaySortOrder
                offerItems(
                  filter: $filter
                  pagination: { after: $after, first: $first }
                ) {
                  offerItems {
                    id
                    created
                    updated
                    offerID
                    copaymentPrice
                    copaymentGross(organisationId: $organisationId)
                    customDeviceName
                    basePrice
                    deviatingPrice
                    externalProductID
                    rentalDuration
                    deviceDefinition {
                      id
                      codename
                      color
                      edition
                      imageURL
                      manufacturer
                      name
                      productType
                      storage
                      impactFactors {
                        status
                        factors {
                          name
                          value
                          valueConverted
                          converter
                        }
                      }
                    }
                    displaySortOrder
                    isPrimaryDevice
                    isMandatory
                    isCreateSeparateRental
                  }
                }
                totalCopaymentNet
                totalCopaymentGross(organisationId: $organisationId)
                totalBasisPrice
              }
              hasDeviceWithCoPaymentPrice
            }
          }
        }
      }
      rentCategories {
        rentcategories {
          id
          copaymentNet
          copaymentGross
          displayedBasisAmount
          descriptionDe
          descriptionEn
          minimumContractDuration
          name
          displaySortOrder
          forcePriceHide
          deviceDefinitions(filter: $filter) {
            totalCount
            deviceDefinitions {
              id
              codename
              color
              edition
              imageURL
              manufacturer
              name
              productType
              storage
              impactFactors {
                status
                factors {
                  name
                  value
                  valueConverted
                  converter
                }
              }
            }
          }
          deviatingAdvertisementPrice
        }
      }
      employee {
        contact {
          firstName
          lastName
        }
        actions {
          showOrderNewDeviceAction
        }
      }
    }
  }
`
