import { FormattedMessage } from 'react-intl'

import { Heading } from 'Components/_theme'
import { LanguageDropdown } from 'Components/LanguageDropdown/LanguageDropdown'

export const OfferErrorContent = () => (
  <>
    <Heading className="PurchaseContent-heading" size={1}>
      <FormattedMessage id="OfferErrorContent_heading" />
    </Heading>

    <div className="PurchaseContent-content">
      <p>
        <FormattedMessage id="OfferErrorContent_content_line1" />
      </p>
    </div>

    <LanguageDropdown isLandingPage />
  </>
)
