import { useCallback, useEffect, useMemo } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { faRefresh } from '@fortawesome/pro-solid-svg-icons'
import { useMediaQuery } from '@react-hookz/web'
import { observer } from 'mobx-react'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { DashboardAccountManagerCard } from 'Modules/dashboard/components/DashboardAccountManagerCard/DashboardAccountManagerCard'
import { DashboardBanner } from 'Modules/dashboard/components/DashboardBanner/DashboardBanner'
import { DashboardFleetOverviewCard } from 'Modules/dashboard/components/DashboardFleetOverviewCard/DashboardFleetOverviewCard'
import { DashboardOrdersReportCard } from 'Modules/dashboard/components/DashboardOrdersReportCard/DashboardOrdersReportCard'
import { DashboardProlongedRentalsCard } from 'Modules/dashboard/components/DashboardProlongedRentalsCard/DashboardProlongedRentalsCard'
import { DashboardStatisticCards } from 'Modules/dashboard/components/DashboardStatisticCards/DashboardStatisticCards'
import { EmployeeExitCard } from 'Modules/dashboard/components/EmployeeExitCard/EmployeeExitCard'
import { ShippedOrdersCard } from 'Modules/dashboard/components/ShippedOrdersCard/ShippedOrdersCard'
import { UnreturnedDevicesCard } from 'Modules/dashboard/components/UnreturnedDevicesCard/UnreturnedDevicesCard'
import { getLastFetchTranslation } from 'Modules/dashboard/pages/OrganisationDashboard/OrganisationDashboard.util'

import { LinkButton } from 'Components/_theme'
import { FaqCard } from 'Components/FaqCard/FaqCard'
import { ImpactTracker } from 'Components/ImpactTracker/ImpactTracker'
import { PageErrorState } from 'Components/PageErrorState/PageErrorState'
import { PageHeader } from 'Components/PageHeader/PageHeader'
import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'

import { useStore } from 'Stores/index'

import { getFullContactName } from 'Utilities'

import { PORTAL_BREAKPOINTS } from 'Constants/constants'
import { PortalSectionEnum } from 'Constants/portalSection'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

export const OrganisationDashboard = observer(() => {
  const {
    portalConfigStore,
    portalSettingsStore,
    dashboardStore,
    organisationStore,
  } = useStore()
  const { portalConfig } = portalConfigStore
  const { setCurrentPortalSection } = portalSettingsStore
  const {
    isError: isFetchError,
    isLoadingCachedData,
    isLoadingData,
    refetchDashboardData,
    lastFetch,
  } = dashboardStore
  const { organisation } = organisationStore

  const showImpactTracker =
    portalConfig?.impactTracker && organisation?.impactFactors
  const intl = useIntl()
  const history = useHistory()
  const { organisationId } = useParams<{ organisationId: string }>()

  useEffect(() => {
    setCurrentPortalSection(PortalSectionEnum.Dashboard)
  }, [setCurrentPortalSection])

  const isNewOrderButtonShown =
    portalConfig?.showNewDeviceButtonToAdmin ?? false

  const handleNewOrderButtonClick = useCallback(() => {
    history.push(
      composePath({
        params: { organisationId },
        path: GLOBAL_ROUTE_PATHS.organisation.orderNew,
      }),
    )
  }, [organisationId, history])

  const isHDDesktop = useMediaQuery(`(min-width: ${PORTAL_BREAKPOINTS.XXL})`)

  const getLastFetch = useMemo(
    () => getLastFetchTranslation(intl, lastFetch),
    [intl, lastFetch],
  )

  const renderContent = useMemo(() => {
    if (isLoadingCachedData) {
      return <PageLoadingState />
    }

    if (isFetchError) {
      return <PageErrorState />
    }

    return (
      <Container className="OrganisationDashboard dashboard" fluid>
        <Row>
          <Col role="banner">
            <PageHeader portalSection={PortalSectionEnum.Dashboard} />
          </Col>

          <Col
            className="d-flex justify-content-end align-items-start"
            xs="auto"
          >
            <div className="d-flex align-items-center">
              <span className="OrganisationDashboard-last-updated me-4 d-none d-sm-block">
                {!isLoadingData && getLastFetch}
              </span>

              <LinkButton
                disabled={isLoadingData}
                iconLeft={faRefresh}
                isLoading={isLoadingData}
                onClick={refetchDashboardData}
                size="small"
              >
                <FormattedMessage id="Refresh_data" />
              </LinkButton>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12} xxl={8}>
            <DashboardBanner
              onNewOrderButtonClick={handleNewOrderButtonClick}
              showNewOrderButton={isNewOrderButtonShown}
              version="compact"
            />

            {!isHDDesktop && (
              <DashboardStatisticCards isHDDesktop={isHDDesktop} />
            )}

            <div className="OrganisationDashboard-list-container">
              <ShippedOrdersCard />

              <UnreturnedDevicesCard />

              <EmployeeExitCard />
            </div>

            {!isHDDesktop && <DashboardProlongedRentalsCard />}

            <DashboardFleetOverviewCard />

            <DashboardOrdersReportCard />
          </Col>

          <Col xs={12} xxl={4}>
            {showImpactTracker && <ImpactTracker />}

            {isHDDesktop && (
              <>
                <div className="OrganisationDashboard-statistic-card-container mb-6">
                  <DashboardStatisticCards isHDDesktop={isHDDesktop} />
                </div>

                <DashboardProlongedRentalsCard />
              </>
            )}

            <FaqCard />

            {organisation?.accountManager && (
              <DashboardAccountManagerCard
                accountManager={getFullContactName(organisation.accountManager)}
              />
            )}
          </Col>
        </Row>
      </Container>
    )
  }, [
    getLastFetch,
    handleNewOrderButtonClick,
    isFetchError,
    isHDDesktop,
    isLoadingCachedData,
    isLoadingData,
    isNewOrderButtonShown,
    organisation,
    refetchDashboardData,
    showImpactTracker,
  ])

  return renderContent
})
