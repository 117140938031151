import { useStore } from 'Stores/index'

import { SupportedLanguagesEnum } from 'Constants/locale'

export const isActiveLanguage = (language: SupportedLanguagesEnum): boolean => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { portalSettingsStore } = useStore()
  const {
    i18n: { language: activeLanguage },
  } = portalSettingsStore

  return language === activeLanguage
}
