import { forwardRef, ReactElement, Ref, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import ReactSelect, { GroupBase } from 'react-select'
import { clsx } from 'clsx'
import { SelectComponents } from 'node_modules/react-select/dist/declarations/src/components'

import { SelectClearIndicator } from 'Components/SelectComponents/SelectClearIndicator'
import { SelectDropdownIndicator } from 'Components/SelectComponents/SelectDropdownIndicator'
import { SelectMultiValueLabel } from 'Components/SelectComponents/SelectMultiValueLabel'
import { SelectMultiValueRemove } from 'Components/SelectComponents/SelectMultiValueRemove'
import { SelectOptionWithCheckbox } from 'Components/SelectComponents/SelectOptionWithCheckbox'
import { SelectValueContainer } from 'Components/SelectComponents/SelectValueContainer'

import { SelectOption, SelectProps } from './Select.types'
import { getSelectStateProps } from './Select.utils'

const ForwardSelect = <
  Option extends SelectOption,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: SelectProps<Option, IsMulti, Group>,
  _ref: Ref<any>,
) => {
  const {
    className,
    components,
    isDisabled,
    isFilled,
    isMulti,
    markAsRequired,
    iconProps,
    ...selectProps
  } = props

  const selectRef = useRef<any>(null)
  const intl = useIntl()

  const baseComponents: Partial<SelectComponents<Option, IsMulti, Group>> = {
    ClearIndicator: SelectClearIndicator,
    DropdownIndicator: SelectDropdownIndicator,
    ValueContainer: SelectValueContainer,
    ...(isMulti && {
      MultiValueLabel: SelectMultiValueLabel,
      MultiValueRemove: SelectMultiValueRemove,
      Option: SelectOptionWithCheckbox,
    }),
  }

  const multiSelectProps: Pick<
    SelectProps<Option, IsMulti, Group>,
    'closeMenuOnSelect' | 'hideSelectedOptions'
  > = {
    ...(isMulti && {
      closeMenuOnSelect: false,
      hideSelectedOptions: false,
    }),
  }

  const { isClearable, isSearchable, menuIsOpen } = getSelectStateProps(props)

  const classNames = clsx(
    'CustomReactSelect ReactSelect',
    isMulti && 'ReactSelect--isMulti',
    isDisabled && 'ReactSelect--isDisabled',
    !isFilled && markAsRequired && 'ReactSelect--isUnfilled',
    iconProps?.icon && 'ReactSelect--hasIcon',
    className,
  )

  useEffect(() => {
    const { current: select } = selectRef

    if (!select) {
      return
    }

    if (isDisabled) {
      select.inputRef.setAttribute('disabled', '')
    } else {
      select.inputRef.removeAttribute('disabled')
    }
  }, [isDisabled])

  return (
    <ReactSelect
      ref={node => {
        _ref = node as any
        selectRef.current = node
      }}
      className={classNames}
      classNamePrefix="ReactSelect"
      components={{
        ...baseComponents,
        ...components,
      }}
      isMulti={isMulti}
      placeholder={intl.formatMessage({ id: 'Select_placeholder' })}
      {...multiSelectProps}
      {...selectProps}
      iconProps={iconProps}
      isClearable={isClearable}
      isSearchable={isSearchable}
      menuIsOpen={menuIsOpen}
    />
  )
}

// forwardRef can't use generics. Workaround:
// https://stackoverflow.com/a/58473012
export const Select = forwardRef(ForwardSelect) as <
  Option extends SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: SelectProps<Option, IsMulti, Group> & {
    ref?: Ref<any>
  },
) => ReactElement
