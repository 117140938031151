import { ChangeEvent, useCallback } from 'react'
import { useIntl } from 'react-intl'
import {
  faCalendar,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/pro-solid-svg-icons'
import { isEmpty } from 'lodash-es'

import { Icon } from 'Components/Icon/Icon'

import { Input } from '../_theme/Input/Input'
import { MultidatePickerInputProps } from './MultidatePicker.types'

export const MultidatePickerInput = (props: MultidatePickerInputProps) => {
  const {
    isOpen,
    onFocus,
    value,
    setValue,
    onCloseDatepicker,
    autoComplete,
    e2eSelector,
    handleValueChange,
  } = props
  const intl = useIntl()
  const isFilled = !isEmpty(value)

  const handleContentAfterCustomClick = useCallback(() => {
    if (isOpen) {
      onCloseDatepicker()
    } else if (typeof onFocus === 'function') {
      onFocus()
    }
  }, [isOpen, onFocus, onCloseDatepicker])

  const handleClear = () => {
    setValue(null)

    /* This is a "mock" of syntheticEvent which needs to be passed 
     to react-multi-datepicker handleValueChange to properly reset the input value
     which is later passed to this component as "value". */
    if (handleValueChange) {
      const syntheticEvent = {
        target: {
          value: null,
        },
      } as unknown as ChangeEvent<HTMLInputElement>

      handleValueChange(syntheticEvent)
    }
  }

  return (
    <Input
      autoComplete={autoComplete}
      contentAfter={
        <Icon icon={isOpen ? faChevronUp : faChevronDown} size={20} />
      }
      contentAfterCustomClick={handleContentAfterCustomClick}
      data-e2e={e2eSelector}
      iconLeft={faCalendar}
      isClearable
      isFilled={isFilled}
      isFormInput={false}
      onClear={handleClear}
      onClick={onFocus}
      placeholder={intl.formatMessage({ id: 'MultidatePicker_placeholder' })}
      tabIndex={-1}
      value={value}
    />
  )
}
