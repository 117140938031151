import { BadgeColor, BadgeVariant } from 'Components/_theme/Badge/Badge.types'

import { ReturnCaseStatus } from 'Portal/__generated__/globalTypes'

export const RETURN_STATUS_TRANSLATION_IDS: Record<ReturnCaseStatus, string> = {
  [ReturnCaseStatus.paused]: 'Upcoming',
  [ReturnCaseStatus.open]: 'Open',
  [ReturnCaseStatus.action_needed]: 'Processing',
  [ReturnCaseStatus.blocked]: 'Processing',
  [ReturnCaseStatus.done]: 'Completed',
  [ReturnCaseStatus.rejected]: 'Rejected',
  [ReturnCaseStatus.received]: 'Awaiting_processing',
  [ReturnCaseStatus.device_sold_closed]: 'Sold',
  [ReturnCaseStatus.lost_device_closed]: 'Resolved_unreturned',
  [ReturnCaseStatus.return_depot]: 'In_progress',
  [ReturnCaseStatus.broken_device_closed]: 'Completed',
  [ReturnCaseStatus.internal_correction_closed]: 'Cancelled',
  [ReturnCaseStatus.in_customs]: 'In_customs',
  [ReturnCaseStatus.blocked_mdm_lock]: 'Device_locked_badge_in_mdm',
  [ReturnCaseStatus.blocked_user_lock]: 'Device_locked_badge_in_customer_lock',
  [ReturnCaseStatus.blocked_user_mdm_lock]:
    'Device_locked_badge_in_mdm_and_customer_lock',
  [ReturnCaseStatus.device_confirmed]: 'Processing',
  [ReturnCaseStatus.package_delivered]: 'Awaiting_processing',
}

export const RETURN_STATUS_BADGE_COLOR_MAP: Record<
  ReturnCaseStatus,
  BadgeColor
> = {
  [ReturnCaseStatus.paused]: 'light',
  [ReturnCaseStatus.open]: 'light',
  [ReturnCaseStatus.action_needed]: 'blue',
  [ReturnCaseStatus.blocked]: 'blue',
  [ReturnCaseStatus.done]: 'green',
  [ReturnCaseStatus.rejected]: 'red',
  [ReturnCaseStatus.received]: 'blue',
  [ReturnCaseStatus.device_sold_closed]: 'green',
  [ReturnCaseStatus.lost_device_closed]: 'green',
  [ReturnCaseStatus.return_depot]: 'light',
  [ReturnCaseStatus.broken_device_closed]: 'green',
  [ReturnCaseStatus.internal_correction_closed]: 'orange',
  [ReturnCaseStatus.in_customs]: 'blue',
  [ReturnCaseStatus.blocked_mdm_lock]: 'pink',
  [ReturnCaseStatus.blocked_user_lock]: 'pink',
  [ReturnCaseStatus.blocked_user_mdm_lock]: 'pink',
  [ReturnCaseStatus.device_confirmed]: 'blue',
  [ReturnCaseStatus.package_delivered]: 'blue',
}

export const RETURN_STATUS_BADGE_VARIANT_MAP: Record<
  ReturnCaseStatus,
  BadgeVariant
> = {
  [ReturnCaseStatus.paused]: 'primary',
  [ReturnCaseStatus.open]: 'primary',
  [ReturnCaseStatus.action_needed]: 'secondary',
  [ReturnCaseStatus.blocked]: 'secondary',
  [ReturnCaseStatus.done]: 'secondary',
  [ReturnCaseStatus.rejected]: 'primary',
  [ReturnCaseStatus.received]: 'secondary',
  [ReturnCaseStatus.device_sold_closed]: 'secondary',
  [ReturnCaseStatus.lost_device_closed]: 'secondary',
  [ReturnCaseStatus.return_depot]: 'primary',
  [ReturnCaseStatus.broken_device_closed]: 'secondary',
  [ReturnCaseStatus.internal_correction_closed]: 'secondary',
  [ReturnCaseStatus.in_customs]: 'secondary',
  [ReturnCaseStatus.blocked_mdm_lock]: 'secondary',
  [ReturnCaseStatus.blocked_user_lock]: 'secondary',
  [ReturnCaseStatus.blocked_user_mdm_lock]: 'secondary',
  [ReturnCaseStatus.device_confirmed]: 'secondary',
  [ReturnCaseStatus.package_delivered]: 'secondary',
}
