import { useCallback, useEffect, useState } from 'react'
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client'
import { DateTime } from 'luxon'

import { DownloadReportState } from 'Components/DownloadReport/DownloadReport.types'

import { useStore } from 'Stores/index'

import { useActiveUser, useIsOrganisation, useUserAuthToken } from 'Hooks'

import { ENVIROMENTAL_VARIABLES } from 'Constants/enviromentalVariables'
import {
  generateReport,
  generateReportVariables,
} from 'Constants/graphql/mutations/__generated__/generateReport'
import { MUTATION_GENERATE_REPORT } from 'Constants/graphql/mutations/MutationGenerateReport'
import {
  getRequestedReportStatus,
  getRequestedReportStatus_getRequestedReportStatus,
  getRequestedReportStatusVariables,
} from 'Constants/graphql/queries/listing/__generated__/getRequestedReportStatus'
import { GET_REQUESTED_REPORT_STATUS } from 'Constants/graphql/queries/listing/GetRequestedReportStatus'

import {
  GenerateReportInput,
  ReportStatus,
  ReportType,
} from 'Portal/__generated__/globalTypes'

/**
 * This hook is used to handle download report feature for a specified report type.
 */
export const useDownloadReport = (
  reportType?: ReportType,
  hideDownloadReportComponents?: boolean,
  reportFilter?: GenerateReportInput['filter'],
): DownloadReportState => {
  const { portalSettingsStore, userStore } = useStore()
  const { isAppReadOnly } = portalSettingsStore
  const { user } = userStore
  const { activeOrganisation } = useActiveUser()
  const authToken = useUserAuthToken()

  const isOrganisation = useIsOrganisation()

  const [reportData, setReportData] =
    useState<getRequestedReportStatus_getRequestedReportStatus | null>(null)
  const [reportUrl, setReportUrl] = useState('')
  const [reportError, setReportError] = useState<ApolloError | null>(null)

  const [
    getRequestedReportStatusData,
    { loading: isGetRequestedReportLoading },
  ] = useLazyQuery<getRequestedReportStatus, getRequestedReportStatusVariables>(
    GET_REQUESTED_REPORT_STATUS,
    {
      onCompleted: data => {
        if (
          data.getRequestedReportStatus?.status !== reportData?.status ||
          data.getRequestedReportStatus?.uuid !== reportData?.uuid
        ) {
          setReportError(null)
          setReportData(data.getRequestedReportStatus)
        }

        if (data.getRequestedReportStatus?.status === ReportStatus.process) {
          setTimeout(() => {
            getRequestedReportStatusData()
          }, 10000)
        }

        if (
          data.getRequestedReportStatus?.status === ReportStatus.done &&
          data.getRequestedReportStatus.uuid
        ) {
          fetch(
            `${window.GO_API_URL}/${
              ENVIROMENTAL_VARIABLES.PUBLICBOX_API_VERSION
            }/download-report/${user?.userId ?? '0'}/${
              data.getRequestedReportStatus.uuid
            }`,
            {
              headers: { Authorization: authToken },
            },
          )
            .then(response => response.text())
            .then((response: string) => {
              setReportError(null)

              const file = new Blob([response], {
                type: 'text/csv',
              })

              const url = URL.createObjectURL(file)

              setReportUrl(url)
            })
            .catch(error =>
              setReportError(new ApolloError({ errorMessage: error })),
            )
        }
      },
      onError: error => setReportError(error),
      variables: {
        reportType: reportType ?? ReportType.orders,
        requestOrganizationID: `${activeOrganisation?.id ?? 0}`,
        requestUserID: `${user?.userId ?? 0}`,
      },
    },
  )

  const [generateReport, { loading: isGenerateReport }] = useMutation<
    generateReport,
    generateReportVariables
  >(MUTATION_GENERATE_REPORT)

  const handleReportRequest = useCallback(() => {
    generateReport({
      onCompleted: () => {
        setReportError(null)
        getRequestedReportStatusData()
      },
      onError: error => setReportError(error),
      variables: {
        input: {
          filter: reportFilter,
          reportType: reportType ?? ReportType.orders,
          requestDate: DateTime.now().toISO(),
          requestOrganizationID: `${activeOrganisation?.id ?? 0}`,
          requestUserID: `${user?.userId ?? 0}`,
        },
      },
    })
  }, [
    activeOrganisation?.id,
    generateReport,
    getRequestedReportStatusData,
    reportType,
    user?.userId,
    reportFilter,
  ])

  const isDownloadPossible = !!reportType && !isAppReadOnly && isOrganisation

  const handleRequestReportClick = () => {
    handleReportRequest()
  }

  useEffect(() => {
    if (reportType && isDownloadPossible) {
      getRequestedReportStatusData()
    }
  }, [getRequestedReportStatusData, isDownloadPossible, reportType])

  return {
    hideDownloadReportComponents,
    isDownloadEnabled: isDownloadPossible,
    isLoading: isGetRequestedReportLoading || isGenerateReport,
    onRequestReportClick: handleRequestReportClick,
    reportData,
    reportError,
    reportUrl,
  }
}
