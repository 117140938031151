import { memo, useCallback, useMemo, useState } from 'react'

import { Card } from 'Components/_theme'

import { StepperProps } from './Stepper.types'
import { StepperHeader } from './StepperHeader'

export const Stepper = memo((props: StepperProps) => {
  const { steps } = props
  const [activeStep, setActiveStep] = useState(1)

  const handleNextStep = useCallback(() => {
    setActiveStep(prev => prev + 1)
  }, [])

  const handlePreviousStep = useCallback(() => {
    setActiveStep(prev => prev - 1)
  }, [])

  const handleSetCurrentStep = useCallback((currentStep: number) => {
    setActiveStep(currentStep)
  }, [])

  const stepperSteps = useMemo(() => {
    const calculateStepProgress = (step: number) => (step / steps.length) * 100

    return steps.map((stepItem, index) => ({
      ...stepItem,
      progress: calculateStepProgress(index + 1),
      step: index + 1,
    }))
  }, [steps])

  const selectedStep = useMemo(
    () => stepperSteps.find(stepItem => stepItem.step === activeStep),
    [activeStep, stepperSteps],
  )

  if (!selectedStep) {
    return null
  }

  const {
    renderStepContent,
    step,
    progress,
    headingLabel,
    headingSufix,
    subHeadingLabel,
  } = selectedStep
  const headerProps = {
    headingLabel,
    headingSufix,
    progress,
    step,
    subHeadingLabel,
  }

  return (
    <Card className="mb-9 px-6">
      <StepperHeader {...headerProps} />

      {renderStepContent({
        handleNextStep,
        handlePreviousStep,
        handleSetCurrentStep,
      })}
    </Card>
  )
})
