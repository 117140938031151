export enum RadioButtonSizeEnum {
  small = 'small',
  medium = 'medium',
}

export type RadioButtonProps = {
  id: string
  label: string
  name: string
  onChange: (event: React.ChangeEvent<HTMLInputElement> | null) => void
  value: boolean
  disabled?: boolean
  isInvalid?: boolean
  size?: RadioButtonSizeEnum
}
