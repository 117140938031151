import { gql } from '@apollo/client'

export const GET_ASSIGNABLE_DEVICES = gql`
  query getAssignableDevices(
    $depotId: ID!
    $employeeId: ID!
    $searchTerm: String
  ) {
    assignableDevices(
      depotId: $depotId
      employeeId: $employeeId
      searchTerm: $searchTerm
    ) {
      totalCount
      stocklistItems {
        id
        imei
        deviceDefinition {
          name
        }
      }
    }
  }
`
