import { Dropdown } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { faFilterList } from '@fortawesome/pro-solid-svg-icons'

import {
  GS_SEARCH_RESULTS_FILTER_BUTTON,
  SEARCH_RESULTS_MODEL_FILTER_OPTIONS,
} from 'Modules/searchResults/constants/searchResults.contants'

import { Icon } from 'Components/Icon/Icon'

import { PORTAL_CONTENT_MODEL } from 'Constants/portalContentModel'

import { SearchResultsFilterDropdownProps } from './SearchResultsFilterDropdown.types'

export const SearchResultsFilterDropdown = (
  props: SearchResultsFilterDropdownProps,
) => {
  const { selectedCategory, onSelectCategory } = props

  const intl = useIntl()

  const handleSelectCategory = (category: string) => {
    onSelectCategory(category)
  }

  return (
    <Dropdown className="search-results-filter">
      <Dropdown.Toggle
        aria-label={intl.formatMessage({
          id: PORTAL_CONTENT_MODEL[selectedCategory]?.translationId || 'Filter',
        })}
        className="d-flex align-items-center"
        data-testid={GS_SEARCH_RESULTS_FILTER_BUTTON}
        variant=""
      >
        <Icon className="arrow-right-icon" icon={faFilterList} size={20} />

        <span className="d-inline-block ms-1 me-auto">
          <FormattedMessage
            id={
              PORTAL_CONTENT_MODEL[selectedCategory]?.translationId || 'Filter'
            }
          />
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {SEARCH_RESULTS_MODEL_FILTER_OPTIONS.map(category => {
          if (selectedCategory === category.modelType) {
            return null
          }

          return (
            <Dropdown.Item
              key={category.modelType}
              aria-label={intl.formatMessage({
                id: category.translationId,
              })}
              as="button"
              onClick={() => handleSelectCategory(category.modelType)}
            >
              <FormattedMessage id={category.translationId} />
            </Dropdown.Item>
          )
        })}

        <Dropdown.Divider className="mx-4" />

        <Dropdown.Item
          aria-label={intl.formatMessage({
            id: 'Clear_filter',
          })}
          as="button"
          onClick={() => handleSelectCategory('')}
        >
          <FormattedMessage id="Clear_filter" />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
