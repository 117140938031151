import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { MultiValue } from 'react-select'
import {
  faBuildingColumns,
  faBuildings,
  faLaptopMobile,
  faTrademark,
  faWarehouse,
} from '@fortawesome/pro-solid-svg-icons'
import { CurrentRefinementsConnectorParamsRefinement } from 'instantsearch.js/es/connectors/current-refinements/connectCurrentRefinements'
import { RefinementListItem } from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList'
import { DateTime } from 'luxon'

import { FilterColumn } from 'Components/FilterColumn/FilterColumn'

import { dashOrSpaceToUnderscore } from 'Utilities'

import { RETURN_STATUS_TRANSLATION_IDS } from 'Constants/returnStatus'

import { MultidatepickerFilter } from 'Algolia/filters/MultidatepickerFilter/MultidatepickerFilter'
import { MultiselectFilter } from 'Algolia/filters/MultiselectFilter/MultiselectFilter'
import { RadioFilter } from 'Algolia/filters/RadioFilter/RadioFilter'

import { ReturnCaseStatus } from 'Portal/__generated__/globalTypes'

import { ReturnsFilterColumnProps } from './ReturnsFilterColumn.types'

export const ReturnsFilterColumn = (props: ReturnsFilterColumnProps) => {
  const { onCloseFilterColumn, isVisible } = props
  const intl = useIntl()
  const today = DateTime.now()
    .startOf('day')
    .set({ hour: 0, minute: 0, second: 0 })

  const transformReturnStatusItems = useCallback(
    (items: RefinementListItem[]) => {
      const transformedItems = items.reduce((result, item) => {
        const formattedStatus = dashOrSpaceToUnderscore(item.value)
        const formattedLabel =
          RETURN_STATUS_TRANSLATION_IDS[
            formattedStatus as keyof typeof ReturnCaseStatus
          ]

        // check if label in result exists
        const existingItem = result.find(
          resultItem => resultItem.label === formattedLabel,
        )

        // if label exists, merge the values and counts
        if (existingItem) {
          existingItem.value = `${existingItem.value},${item.value}`
          existingItem.count += item.count

          return result
        }

        // if label does not exist, add the item to the result
        // use formatted label
        item.label = intl.formatMessage({
          id: formattedLabel,
        })

        return [...result, item]
      }, [] as RefinementListItem[])

      return transformedItems
    },
    [intl],
  )

  const returnCaseStatusValueMapper = useCallback(
    (
      result: MultiValue<RefinementListItem>,
      item: CurrentRefinementsConnectorParamsRefinement,
    ) => {
      const formattedStatus = dashOrSpaceToUnderscore(item.value.toString())
      const formattedLabel =
        RETURN_STATUS_TRANSLATION_IDS[
          formattedStatus as keyof typeof ReturnCaseStatus
        ]

      // check if label in result exists
      const existingItem = result.find(
        resultItem => resultItem.label === formattedLabel,
      )

      // if label exists, merge the values and counts
      if (existingItem) {
        existingItem.value = `${existingItem.value},${item.value}`
        existingItem.count += item?.count ?? 0
        existingItem.isRefined = true

        return result
      }

      const transformedItem: RefinementListItem = {
        count: item.count ?? 0,
        isRefined: true,
        label: intl.formatMessage({
          id: formattedLabel,
        }),
        value: item.value.toString(),
      }

      return [...result, transformedItem]
    },
    [intl],
  )

  return (
    <FilterColumn isVisible={isVisible} onClose={onCloseFilterColumn}>
      <MultiselectFilter
        filterProps={{
          attribute: 'return_case.status',
          transformItems: transformReturnStatusItems,
        }}
        label={intl.formatMessage({
          id: 'typesenseAttribute_return_case_status',
        })}
        selectProps={{
          iconProps: {
            icon: faTrademark,
          },
        }}
        valueMapper={returnCaseStatusValueMapper}
      />

      <MultidatepickerFilter
        autoComplete="off"
        filterProps={{ attribute: 'return_case.created' }}
        label={intl.formatMessage({
          id: 'typesenseAttribute_return_case_created',
        })}
        multidatePickerProps={{
          /* eslint-disable sort-keys-fix/sort-keys-fix */
          customToolbarConfig: {
            today: {
              scope: 'today',
            },
            last_7days: {
              length: 7,
              period: 'days',
              scope: 'past',
            },
            last_30days: {
              length: 30,
              period: 'days',
              scope: 'past',
            },
            last_6months: {
              length: 6,
              period: 'months',
              scope: 'past',
            },
          },
          /* eslint-enable sort-keys-fix/sort-keys-fix */
          maxDate: new Date().setHours(23, 59, 59),
        }}
      />

      <MultidatepickerFilter
        autoComplete="off"
        filterProps={{ attribute: 'return_case.employee.exit_date' }}
        label={intl.formatMessage({
          id: 'typesenseAttribute_return_case_employee_exit_date',
        })}
      />

      <RadioFilter
        checkboxButtonGroupProps={{
          checkboxButtons: [
            {
              label: '2-4 weeks',
              value: encodeURI(
                JSON.stringify({
                  end: today
                    .minus({ weeks: 2 })
                    .set({ hour: 23, minute: 59, second: 59 })
                    .toSeconds(),
                  start: today
                    .minus({ weeks: 4 })
                    .plus({ days: 1 })
                    .toSeconds(),
                }),
              ),
            },
            {
              label: '4-6 weeks',
              value: encodeURI(
                JSON.stringify({
                  end: today
                    .minus({ weeks: 4 })
                    .set({ hour: 23, minute: 59, second: 59 })
                    .toSeconds(),
                  start: today
                    .minus({ weeks: 6 })
                    .plus({ days: 1 })
                    .toSeconds(),
                }),
              ),
            },
            {
              label: '6+ weeks',
              value: encodeURI(
                JSON.stringify({
                  end: today
                    .minus({ weeks: 6 })
                    .set({ hour: 23, minute: 59, second: 59 })
                    .toSeconds(),
                  start: -1,
                }),
              ),
            },
          ],
          name: 'openSince',
        }}
        filterProps={{ attribute: 'return_case.open_since' }}
        label={intl.formatMessage({
          id: 'typesenseAttribute_return_case_open_since',
        })}
      />

      <MultiselectFilter
        filterProps={{ attribute: 'return_case.stocklist_item.manufacturer' }}
        label={intl.formatMessage({
          id: 'typesenseAttribute_return_case_stocklist_item_manufacturer',
        })}
        selectProps={{
          iconProps: {
            icon: faTrademark,
          },
        }}
      />

      <MultiselectFilter
        filterProps={{ attribute: 'return_case.stocklist_item.codename' }}
        label={intl.formatMessage({
          id: 'typesenseAttribute_return_case_stocklist_item_codename',
        })}
        selectProps={{
          iconProps: {
            icon: faLaptopMobile,
          },
        }}
      />

      <MultiselectFilter
        filterProps={{ attribute: 'return_case.return_depot_name' }}
        label={intl.formatMessage({
          id: 'typesenseAttribute_return_case_return_depot_name',
        })}
        selectProps={{
          iconProps: {
            icon: faWarehouse,
          },
        }}
      />

      <MultiselectFilter
        filterProps={{ attribute: 'return_case.employee.office' }}
        label={intl.formatMessage({
          id: 'typesenseAttribute_return_case_employee_office',
        })}
        selectProps={{
          iconProps: {
            icon: faBuildings,
          },
        }}
      />

      <MultiselectFilter
        filterProps={{ attribute: 'return_case.rental.cost_center' }}
        label={intl.formatMessage({
          id: 'typesenseAttribute_return_case_rental_cost_center',
        })}
        selectProps={{
          iconProps: {
            icon: faBuildingColumns,
          },
        }}
      />
    </FilterColumn>
  )
}
