import { useCallback, useMemo, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  faArrowUpAZ,
  faBarsFilter,
  faChevronDown,
  faChevronUp,
  faClose,
} from '@fortawesome/pro-solid-svg-icons'
import { clsx } from 'clsx'
import { observer } from 'mobx-react'

import { shouldShowFilters } from 'Modules/order/utils/shouldShowFilters.utils'

import { Badge, Checkbox, LinkButton } from 'Components/_theme'
import { Icon } from 'Components/Icon/Icon'

import { buttonize } from 'Utilities'

import {
  OrderFilterTypeEnum,
  OrderSortCriteriaEnum,
} from 'Portal/src/stores/orderStore/orderStore.types'

import { DeviceChooserFilterProps } from './DeviceChooserFilters.types'

const DISPLAY_MAX_FILTER_ROWS = 4

export const DeviceChooserFilters = observer(
  (props: DeviceChooserFilterProps) => {
    const {
      devices,
      devicesLength,
      clearFilters,
      filters,
      setFilters,
      setSortCriteria,
      sortCriteria,
    } = props

    const intl = useIntl()

    const [showFiltersUI, setShowFiltersUI] = useState(false)
    const [showAllFilters, setShowAllFilters] = useState(false)

    const handleShowFiltersUI = useCallback(() => {
      setShowFiltersUI(prevState => !prevState)
    }, [])

    const { isFiltered, colors, manufacturers, productTypes, storages } =
      useMemo(
        () => ({
          colors: [
            ...new Set(devices?.map(device => device.deviceDefinition.color)),
          ].filter(Boolean),
          isFiltered: [...filters.values()].some(filters => filters.size > 0),
          manufacturers: [
            ...new Set(
              devices?.map(device => device.deviceDefinition.manufacturer),
            ),
          ].filter(Boolean),
          productTypes: [
            ...new Set(
              devices?.map(device => device.deviceDefinition.productType || ''),
            ),
          ].filter(Boolean),
          storages: [
            ...new Set(
              devices?.map(device =>
                device.deviceDefinition.storage.replaceAll(' ', ''),
              ),
            ),
          ].filter(Boolean),
        }),
        [devices, filters],
      )

    const showViewAllButton =
      colors.length > DISPLAY_MAX_FILTER_ROWS ||
      productTypes.length > DISPLAY_MAX_FILTER_ROWS ||
      manufacturers.length > DISPLAY_MAX_FILTER_ROWS ||
      storages.length > DISPLAY_MAX_FILTER_ROWS

    const toggleFilter = (filter: string, filterType: OrderFilterTypeEnum) => {
      const next = new Map(filters)
      const current = new Set(filters.get(filterType))

      if (current.has(filter)) current.delete(filter)
      else current.add(filter)
      next.set(filterType, current)
      setFilters(next)
    }

    if (!shouldShowFilters(colors, manufacturers, productTypes, storages)) {
      return <div className="mb-6" />
    }

    return (
      <div className="DeviceChooserFilters my-6">
        <Row className="d-flex align-items-center g-4">
          <Col>
            {isFiltered && (
              <div className="py-2 text-muted">
                <FormattedMessage
                  id="DeviceChooserFilters_total_products"
                  values={{
                    total: devicesLength,
                  }}
                />
              </div>
            )}
          </Col>

          <Col
            className="order-1 position-relative filter-dropdown-content"
            md={6}
            xs={12}
            {...buttonize(handleShowFiltersUI)}
          >
            <div
              aria-label={intl.formatMessage({
                id: 'DeviceChooserFilters_filter',
              })}
              className="filter-dropdown-toggle px-4 w-100 position-relative"
            >
              <Icon className="pe-4" icon={faBarsFilter} size={16} />

              <div className="d-inline-flex align-items-center w-100 flex-wrap mt-1">
                {!isFiltered && (
                  <span
                    className="filter-dropdown-color-grey mb-1"
                    data-axe-exclude
                  >
                    <FormattedMessage id="DeviceChooserFilters_filter" />
                  </span>
                )}

                {[...filters].map(([filterType, filters]) =>
                  [...filters].map(filter => (
                    <Badge
                      key={filter}
                      className="text-capitalize me-1 d-inline-flex align-items-center mb-1"
                      color="blue"
                      variant="secondary"
                    >
                      {filter}

                      <LinkButton
                        aria-label={`${filter} ${intl.formatMessage({ id: 'Clear' })}`}
                        className="DeviceChooserFilters-filter-clear ms-1"
                        onClick={() => {
                          toggleFilter(filter, filterType)
                        }}
                        size="small"
                      >
                        <Icon icon={faClose} size={10} />
                      </LinkButton>
                    </Badge>
                  )),
                )}
              </div>

              {isFiltered && (
                <div className="ms-4 d-inline-flex align-items-center">
                  <LinkButton
                    aria-label={intl.formatMessage({ id: 'Clear' })}
                    className="DeviceChooserFilters-clear filter-dropdown-color-grey"
                    onClick={() => {
                      clearFilters()
                    }}
                    size="small"
                    variant="light"
                  >
                    <Icon icon={faClose} size={16} />
                  </LinkButton>
                </div>
              )}
            </div>

            <div className="filter-dropdown-chevron d-flex justify-content-center align-items-center position-absolute h-100">
              <Icon
                icon={showFiltersUI ? faChevronUp : faChevronDown}
                size={16}
              />
            </div>
          </Col>

          <Col className="order-md-1" md={3} xs={12}>
            <div className="position-relative d-flex">
              <Icon
                className="pe-0 position-absolute ps-4 align-self-center pe-none"
                icon={faArrowUpAZ}
                size={20}
              />

              <Form.Select
                aria-label={intl.formatMessage({
                  id: sortCriteria,
                })}
                className="p-2 ps-9 align-self-center form-select"
                name="sort"
                onChange={event =>
                  setSortCriteria(event.target.value as OrderSortCriteriaEnum)
                }
                value={sortCriteria}
              >
                {Object.values(OrderSortCriteriaEnum).map(sortCriteria => (
                  <option key={sortCriteria} value={sortCriteria}>
                    {intl.formatMessage({
                      id: sortCriteria,
                    })}
                  </option>
                ))}
              </Form.Select>

              <Icon
                className="position-absolute end-0 align-self-center pe-4 pe-none"
                icon={faChevronDown}
                size={16}
              />
            </div>
          </Col>
        </Row>

        <div
          className={clsx(
            'filter-dropdown mx-n6',
            !showFiltersUI && 'hidden',
            showAllFilters && 'show-all',
          )}
        >
          <Form className="grey-container mt-6 p-6">
            <Row className="row-cols-2 row-cols-md-4 g-4">
              {productTypes.length > 1 && (
                <Col className="d-flex flex-column">
                  <span className="text-uppercase pb-4">
                    <FormattedMessage id="DeviceChooserFilters_device_type" />
                  </span>

                  {productTypes.map((productType, i) => (
                    <Form.Group key={productType} controlId={productType}>
                      <Checkbox
                        checked={filters
                          .get(OrderFilterTypeEnum.ProductTypes)
                          ?.has(productType)}
                        className={clsx(
                          'text-capitalize',
                          !(showAllFilters || i < DISPLAY_MAX_FILTER_ROWS) &&
                            'd-none',
                        )}
                        id={`${productType} ${intl.formatMessage({
                          id: 'DeviceChooserFilters_device_type',
                        })}`}
                        label={productType}
                        name="productType"
                        onChange={() =>
                          toggleFilter(
                            productType,
                            OrderFilterTypeEnum.ProductTypes,
                          )
                        }
                        value={productType}
                      />
                    </Form.Group>
                  ))}
                </Col>
              )}

              {manufacturers.length > 1 && (
                <Col className="d-flex flex-column">
                  <span className="text-uppercase pb-4">
                    <FormattedMessage id="DeviceChooserFilters_manufacturer" />
                  </span>

                  {manufacturers.map((manufacturer, i) => (
                    <Form.Group key={manufacturer} controlId={manufacturer}>
                      <Checkbox
                        checked={filters
                          .get(OrderFilterTypeEnum.Manufacturers)
                          ?.has(manufacturer)}
                        className={clsx(
                          'text-capitalize',
                          !(showAllFilters || i < DISPLAY_MAX_FILTER_ROWS) &&
                            'd-none',
                        )}
                        id={`${manufacturer} ${intl.formatMessage({
                          id: 'DeviceChooserFilters_manufacturer',
                        })}`}
                        label={manufacturer}
                        name="manufacturer"
                        onChange={() =>
                          toggleFilter(
                            manufacturer,
                            OrderFilterTypeEnum.Manufacturers,
                          )
                        }
                        value={manufacturer}
                      />
                    </Form.Group>
                  ))}
                </Col>
              )}

              {storages.length > 1 && (
                <Col className="d-flex flex-column">
                  <span className="text-uppercase pb-4">
                    <FormattedMessage id="DeviceChooserFilters_memory" />
                  </span>

                  {storages.map((storage, i) => (
                    <Form.Group key={storage} controlId={storage}>
                      <Checkbox
                        checked={filters
                          .get(OrderFilterTypeEnum.Storages)
                          ?.has(storage)}
                        className={clsx(
                          'text-capitalize',
                          !(showAllFilters || i < DISPLAY_MAX_FILTER_ROWS) &&
                            'd-none',
                        )}
                        id={`${storage} ${intl.formatMessage({
                          id: 'DeviceChooserFilters_memory',
                        })}`}
                        label={storage}
                        name="storage"
                        onChange={() =>
                          toggleFilter(storage, OrderFilterTypeEnum.Storages)
                        }
                        value={storage}
                      />
                    </Form.Group>
                  ))}
                </Col>
              )}

              {colors.length > 1 && (
                <Col className="d-flex flex-column">
                  <span className="text-uppercase pb-4">
                    <FormattedMessage id="Color" />
                  </span>

                  {colors.map((color, i) => (
                    <Form.Group key={color} controlId={color}>
                      <Checkbox
                        checked={filters
                          .get(OrderFilterTypeEnum.Colors)
                          ?.has(color)}
                        className={clsx(
                          'text-capitalize',
                          !(showAllFilters || i < DISPLAY_MAX_FILTER_ROWS) &&
                            'd-none',
                        )}
                        id={`${color} ${intl.formatMessage({
                          id: 'Color',
                        })}`}
                        label={color}
                        name="color"
                        onChange={() =>
                          toggleFilter(color, OrderFilterTypeEnum.Colors)
                        }
                        value={color}
                      />
                    </Form.Group>
                  ))}
                </Col>
              )}
            </Row>

            {showViewAllButton && (
              <LinkButton
                aria-label={intl.formatMessage({
                  id: showAllFilters
                    ? 'DeviceChooserFilters_view_less'
                    : 'DeviceChooserFilters_view_all',
                })}
                className="mt-6"
                id="show-all-button"
                onClick={() => setShowAllFilters(!showAllFilters)}
                size="small"
              >
                <span className="d-inline-flex align-items-end">
                  <Icon
                    icon={showAllFilters ? faChevronUp : faChevronDown}
                    size={16}
                  />

                  {showAllFilters ? (
                    <FormattedMessage id="DeviceChooserFilters_view_less" />
                  ) : (
                    <FormattedMessage id="DeviceChooserFilters_view_all" />
                  )}
                </span>
              </LinkButton>
            )}
          </Form>
        </div>
      </div>
    )
  },
)
