import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faShoppingCart } from '@fortawesome/pro-solid-svg-icons'

import { dashOrSpaceToUnderscore } from 'Utilities'

import { iconCancelDevice } from 'Icons/iconCancelDevice'
import { iconCancelPhonePlan } from 'Icons/iconCancelPhonePlan'
import { iconChangePhonePlan } from 'Icons/iconChangePhonePlan'
import { iconExchangeDevice } from 'Icons/iconExchangeDevice'
import { iconGeneralQuestion } from 'Icons/iconGeneralQuestion'
import { iconLostDevice } from 'Icons/iconLostDevice'
import { iconNewDevice } from 'Icons/iconNewDevice'
import { iconNewEmployee } from 'Icons/iconNewEmployee'
import { iconNewPhonePlan } from 'Icons/iconNewPhonePlan'
import { iconUpgradeDevice } from 'Icons/iconUpgradeDevice'

import { CaseItemType } from 'Portal/__generated__/globalTypes'

const CASE_ITEM_TYPE_ICON_MAP: Partial<
  Record<CaseItemType, IconDefinition | null>
> = {
  [CaseItemType.assign_stocklist_items_to_depot]: null,
  [CaseItemType.assign_stocklist_items_to_depot_bucket]: null,
  [CaseItemType.cancel_device]: iconCancelDevice,
  [CaseItemType.cancel_phone_plan]: iconCancelPhonePlan,
  [CaseItemType.change_device]: iconExchangeDevice,
  [CaseItemType.change_phone_plan]: iconChangePhonePlan,
  [CaseItemType.device_shipping]: null,
  [CaseItemType.early_upgrade_device]: iconUpgradeDevice,
  [CaseItemType.lost_device]: iconLostDevice,
  [CaseItemType.exchange_device]: iconExchangeDevice,
  [CaseItemType.general]: iconGeneralQuestion,
  [CaseItemType.general_purchases]: iconGeneralQuestion,
  [CaseItemType.new_device]: iconNewDevice,
  [CaseItemType.create_employee]: iconNewEmployee,
  [CaseItemType.new_phone_plan]: iconNewPhonePlan,
  [CaseItemType.order_devices_for_depot]: null,
  [CaseItemType.rental_complete_upgrade_device]: iconUpgradeDevice,
  [CaseItemType.repair_device]: iconExchangeDevice,
  [CaseItemType.request]: iconGeneralQuestion,
  [CaseItemType.return_of_device]: iconCancelDevice,
  [CaseItemType.shipping]: null,
  [CaseItemType.sourcing_request]: null,
  [CaseItemType.sourcing_request_bucket]: null,
  [CaseItemType.upgrade_device]: iconUpgradeDevice,
  [CaseItemType.purchase_device]: faShoppingCart,
}

export const getCaseItemIcon = (caseItemType: `${CaseItemType}`) => {
  const formattedCaseItemType = dashOrSpaceToUnderscore(caseItemType)

  return CASE_ITEM_TYPE_ICON_MAP[formattedCaseItemType]
}
