import { gql } from '@apollo/client'

export const MUTATION_EMPLOYEE_ONBOARDING = gql`
  mutation mutationEmployeeOnboarding($input: EmployeeOnboardingRequest!) {
    createEmployeeOnboarding(input: $input) {
      ... on EmployeeOnboardingSuccess {
        __typename
        employee {
          id
        }
      }

      ... on EmployeeOnboardingProblem {
        __typename
        title
        invalidInputs {
          field
          message
        }
      }
    }
  }
`
