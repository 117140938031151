import { FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { faChartLine } from '@fortawesome/pro-solid-svg-icons'
import { observer } from 'mobx-react'

import { GlobalRouteTypesEnum } from 'Router/routeConfig.types'
import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { Card, CardBody, CardHeader, LinkButton } from 'Components/_theme'

import { useStore } from 'Stores/index'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import { OrdersReportCardChart } from '../OrdersReportCardChart/OrdersReportCardChart'

export const DashboardOrdersReportCard = observer(() => {
  const { dashboardStore } = useStore()
  const { caseItemMonthlyOverview } = dashboardStore
  const intl = useIntl()

  const hasCaseItems = caseItemMonthlyOverview?.some(
    overview => !!overview.count,
  )

  const { organisationId } = useParams<{
    organisationId?: string
  }>()

  const ordersPath = composePath({
    params: {
      organisationId,
    },
    path: GLOBAL_ROUTE_PATHS[GlobalRouteTypesEnum.ORGANISATION].orders,
  })

  if (!hasCaseItems) {
    return null
  }

  return (
    <Card className="DashboardOrdersReportCard">
      <CardHeader
        icon={faChartLine}
        rightContent={
          <LinkButton size="small" to={ordersPath}>
            <FormattedMessage id="View_orders" />
          </LinkButton>
        }
        subtitle={intl.formatMessage({
          id: 'DashboardOrdersReportCard_orders_report_subtitle',
        })}
      >
        <FormattedMessage id="DashboardOrdersReportCard_orders_report_header" />
      </CardHeader>

      <CardBody>
        <OrdersReportCardChart data={caseItemMonthlyOverview} />
      </CardBody>
    </Card>
  )
})
