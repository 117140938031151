import { FullContactName } from './getFullContactName.types'

export function getFullContactName(contact: FullContactName): string

export function getFullContactName(
  contact: FullContactName,
  showDefaultText: boolean,
): string | undefined

export function getFullContactName(
  contact: FullContactName,
  showDefaultText: boolean = true,
) {
  if (!contact) {
    return showDefaultText ? 'Unknown contact' : undefined
  }

  if (!contact.firstName && !contact.lastName) {
    return showDefaultText ? 'No name given' : undefined
  }

  if (!contact.lastName && contact.firstName) {
    return contact.firstName
  }

  if (!contact.firstName && contact.lastName) {
    return contact.lastName
  }

  return `${contact.firstName} ${contact.lastName}`
}
