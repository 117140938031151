import { useIsOrganisation } from '../useIsOrganisation/useIsOrganisation'

/**
 * This hook is used to determine if the global search should be shown in the app.
 * Please wrap component with the `observer` method from 'react-mobx' for the hook to re-sync data properly.
 */
export function useShowGlobalSearch() {
  const isOrganisation = useIsOrganisation()

  return isOrganisation
}
