import { clsx } from 'clsx'

import { LabelProps } from './Label.types'

export const Label = (props: LabelProps) => {
  const { children, className, htmlFor, isRequired, ...restProps } = props

  const classNames = clsx('Label', className)

  return (
    <label className={classNames} htmlFor={htmlFor} {...restProps}>
      {children}
      {isRequired && <span className="Label-required">&nbsp;*</span>}
    </label>
  )
}
