import {
  EmployeeOrdersWithUrl,
  EmployeeReturnsWithUrl,
  OranisationReturnsWithUrl,
  OrganisationOrdersWithUrl,
} from './OrdersTable.types'

export const getContact = (
  item:
    | EmployeeOrdersWithUrl
    | EmployeeReturnsWithUrl
    | OranisationReturnsWithUrl
    | OrganisationOrdersWithUrl,
) => {
  if ('assignedEmployee' in item && item.assignedEmployee?.contact?.firstName) {
    return item.assignedEmployee?.contact
  }

  if ('employee' in item && item.employee?.contact.firstName) {
    return item.employee.contact
  }

  return null
}

export const getDate = (
  item:
    | EmployeeOrdersWithUrl
    | EmployeeReturnsWithUrl
    | OranisationReturnsWithUrl
    | OrganisationOrdersWithUrl,
) => {
  if ('createdAt' in item) {
    return item.createdAt
  }

  return item.created
}
