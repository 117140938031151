/* eslint-disable jsx-a11y/label-has-associated-control */
import { components, GroupBase } from 'react-select'

import { Badge } from 'Components/_theme'
import { SelectOption } from 'Components/Select/Select.types'

import { SelectOptionWithCheckboxProps } from './SelectOptionWithCheckbox.types'

export const SelectOptionWithCheckbox = <
  Option extends SelectOption,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: SelectOptionWithCheckboxProps<Option, IsMulti, Group>,
) => {
  const { label, isSelected, data } = props

  return (
    <components.Option
      className="Checkbox ReactSelect__option--with-checkbox"
      {...props}
    >
      <input checked={isSelected} onChange={() => null} type="checkbox" />

      <label>{label}</label>

      {data?.count && (
        <Badge color="dark" variant="outline">
          {data.count}
        </Badge>
      )}
    </components.Option>
  )
}
