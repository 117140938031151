import { useIntl } from 'react-intl'

import { OfferSummaryProps } from 'Modules/purchase/components/OfferSummary/OfferSymmary.types'
// import { OfferSummaryFooter } from 'Modules/purchase/components/OfferSummaryFooter/OfferSummaryFooter'
import { OfferSummaryHeader } from 'Modules/purchase/components/OfferSummaryHeader/OfferSummaryHeader'
import { OfferSummaryItem } from 'Modules/purchase/components/OfferSummaryItem/OfferSummaryItem'

export const OfferSummary = (props: OfferSummaryProps) => {
  const { itemName, itemPrice, subTotal, tax, total, currencySymbol } = props
  const intl = useIntl()

  return (
    <div className="OfferSummary d-flex flex-fill flex-column">
      <OfferSummaryHeader currencySymbol={currencySymbol} total={total} />

      <OfferSummaryItem
        currencySymbol={currencySymbol}
        name={itemName}
        price={itemPrice}
      />

      <hr />

      <OfferSummaryItem
        currencySymbol={currencySymbol}
        name={intl.formatMessage({
          id: 'OfferSummary_subtotal',
        })}
        price={subTotal}
      />

      <OfferSummaryItem
        currencySymbol={currencySymbol}
        muted
        name={intl.formatMessage({
          id: 'OfferSummary_tax',
        })}
        price={tax}
      />

      <hr />

      <OfferSummaryItem
        currencySymbol={currencySymbol}
        largePrice
        name={intl.formatMessage({
          id: 'OfferSummary_total',
        })}
        price={total}
      />

      {/* <OfferSummaryFooter /> */}
    </div>
  )
}
