import { useIntl } from 'react-intl'
import { faUniversalAccess } from '@fortawesome/pro-solid-svg-icons'
import { useMediaQuery } from '@react-hookz/web'

import { Icon } from 'Components/Icon/Icon'

import { PORTAL_BREAKPOINTS } from 'Constants/constants'

export const AccessibilityButton = () => {
  const isMobile = useMediaQuery(`(max-width: ${PORTAL_BREAKPOINTS.LG})`)
  const intl = useIntl()

  return (
    <button
      aria-label={intl.formatMessage({
        id: 'Open_accessibility_menu',
      })}
      className="accessibility-icon-button"
      onClick={e => e.preventDefault()}
      type="button"
    >
      <Icon icon={faUniversalAccess} size={isMobile ? 32 : 40} />
    </button>
  )
}
