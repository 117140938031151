import { Image } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'

import { CardBody, Heading } from 'Components/_theme'

import { E2E_ERROR } from 'Constants/e2e'

import errorPageImage from 'Assets/images/errorpage/refresh_the_page.svg'

import { ModalErrorStateProps } from './ModalErrorState.types'

export const ModalErrorState = (props: ModalErrorStateProps) => {
  const { error } = props

  const intl = useIntl()

  return (
    <CardBody>
      <div className="ModalErrorState" data-e2e={E2E_ERROR}>
        <Image
          alt={intl.formatMessage({
            id: 'Img_alt_page_error',
          })}
          src={errorPageImage}
        />

        <div className="text-center">
          <Heading as={4} size={2}>
            <FormattedMessage id="Error_occurred" />
          </Heading>
        </div>

        {error && <span className="mt-4">{error.message}</span>}
      </div>
    </CardBody>
  )
}
