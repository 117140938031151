import { useEffect, useMemo, useRef } from 'react'
import { FormattedMessage } from 'react-intl'

import { PrimaryButton } from 'Components/_theme'

import {
  E2E_NEW_ORDER_FOOTER_NEXT,
  E2E_NEW_ORDER_FOOTER_PREV,
} from 'Constants/e2e'

import { OrderStickyFooterProps } from './OrderStickyFooter.types'

export const OrderStickyFooter = (props: OrderStickyFooterProps) => {
  const { handleNextAction, handlePreviousAction, renderNextButtonElement } =
    props
  const stickyRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!stickyRef.current) {
      return undefined
    }

    const observer = new IntersectionObserver(
      entries => {
        const [firstElement] = entries

        stickyRef.current?.classList.toggle(
          'is-pinned',
          firstElement.intersectionRatio < 1,
        )
      },
      { threshold: [1] },
    )

    observer.observe(stickyRef.current)

    return () => {
      observer.disconnect()
    }
  }, [stickyRef])

  const renderBackButton = useMemo(() => {
    if (handlePreviousAction) {
      return (
        <div className="me-auto">
          <PrimaryButton
            appearance="outline"
            data-e2e={E2E_NEW_ORDER_FOOTER_PREV}
            id="back"
            onClick={handlePreviousAction}
          >
            <FormattedMessage id="Back" />
          </PrimaryButton>
        </div>
      )
    }

    return null
  }, [handlePreviousAction])

  const renderNextButton = useMemo(() => {
    if (renderNextButtonElement) {
      return (
        <div className="ms-auto d-flex justify-content-end">
          {renderNextButtonElement()}
        </div>
      )
    }

    if (handleNextAction) {
      return (
        <div className="ms-auto d-flex justify-content-end">
          <PrimaryButton
            className="ms-2"
            data-e2e={E2E_NEW_ORDER_FOOTER_NEXT}
            id="next"
            onClick={handleNextAction}
          >
            <FormattedMessage id="Next" />
          </PrimaryButton>
        </div>
      )
    }

    return null
  }, [handleNextAction, renderNextButtonElement])

  if (!handleNextAction && !handlePreviousAction && !renderNextButtonElement) {
    return null
  }

  return (
    <div ref={stickyRef} className="sticky-menu">
      <div className="sticky-menu__wrapper d-flex py-4 py-sm-6 mx-n6 px-6">
        {renderBackButton}
        {renderNextButton}
      </div>
    </div>
  )
}
