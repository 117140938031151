import enFlag from 'Assets/images/flags/english.svg'
import frFlag from 'Assets/images/flags/french.svg'
import deFlag from 'Assets/images/flags/german.svg'
import usFlag from 'Assets/images/flags/usEnglish.svg'

import { I18nConfig } from '../stores/portalSettingsStore/portalSettingsStore.types'

export enum SupportedLanguagesEnum {
  EN = 'en',
  DE = 'de',
  FR = 'fr',
}

export const AVAILABLE_LANGUAGES: Record<SupportedLanguagesEnum, I18nConfig> = {
  [SupportedLanguagesEnum.EN]: {
    flag: navigator.language === 'en-US' ? usFlag : enFlag,
    language: 'en',
    locale: 'en',
    name: 'English',
  },
  [SupportedLanguagesEnum.DE]: {
    flag: deFlag,
    language: 'de',
    locale: 'de',
    name: 'Deutsch',
  },
  [SupportedLanguagesEnum.FR]: {
    flag: frFlag,
    language: 'fr',
    locale: 'fr',
    name: 'Français',
  },
}

export const AVAILABLE_LANGUAGES_ARRAY = Object.entries(
  AVAILABLE_LANGUAGES,
).map(([_key, language]) => Object.assign(language, {}))

export const isSupportedLanguage = (
  locale: string,
): locale is SupportedLanguagesEnum => {
  if (typeof locale !== 'string') {
    return false
  }

  return Object.values(SupportedLanguagesEnum).includes(
    locale as SupportedLanguagesEnum,
  )
}
