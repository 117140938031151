import { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import { useIntl } from 'react-intl'
import { ChartConfiguration } from 'chart.js'

import { CHART_FONT } from 'Constants/charts'

import {
  PROLONGED_RENTALS_CHART_BACKGROUND_COLOR,
  PROLONGED_RENTALS_CHART_BORDER_COLOR,
  PROLONGED_RENTALS_CHART_FONT_COLOR,
} from './ProlongedRentalsChart.constants'
import { ProlongedRentalsChartProps } from './ProlongedRentalsChart.types'

export const ProlongedRentalsChart = (props: ProlongedRentalsChartProps) => {
  const { data, labels } = props

  const intl = useIntl()

  const config: ChartConfiguration<'bar'> = {
    data: {
      datasets: [
        {
          backgroundColor: PROLONGED_RENTALS_CHART_BACKGROUND_COLOR,
          borderColor: PROLONGED_RENTALS_CHART_BORDER_COLOR,
          borderRadius: 4,
          borderSkipped: false,
          borderWidth: 1,
          data,
          label: intl.formatMessage({ id: 'Total' }),
        },
      ],
      labels,
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          bodyFont: CHART_FONT,
          displayColors: false,
          titleFont: CHART_FONT,
        },
      },
      responsive: true,
      scales: {
        x: {
          border: {
            display: false,
          },
          grid: {
            display: false,
          },
          ticks: {
            color: PROLONGED_RENTALS_CHART_FONT_COLOR,
            font: CHART_FONT,
          },
        },
        y: {
          border: { display: false },
          grid: {
            drawTicks: false,
          },
          position: 'right',
          ticks: {
            color: PROLONGED_RENTALS_CHART_FONT_COLOR,
            font: CHART_FONT,
          },
        },
      },
    },
    type: 'bar',
  }

  const ariaLabel = useMemo(
    () =>
      `${intl.formatMessage({ id: 'Prolonged_rentals' })}:${labels.map(
        (item, index) =>
          ` ${item} ${intl.formatMessage({ id: 'Total' })} ${data[index]}`,
      )}`,
    [data, intl, labels],
  )

  return (
    <div className="DashboardProlongedRentalsCard-chart">
      <Bar {...config} aria-label={ariaLabel} />
    </div>
  )
}
