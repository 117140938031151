import { store } from 'Stores/index'

import { PortalSectionEnum } from 'Constants/portalSection'

import { Depot, Depots } from '../pages'
import { DepotsRoutePathsEnum } from './depotsRouteConfig.types'

export const DEPOT_ROUTES = [
  {
    component: Depot,
    onRender: () => {
      const { portalSettingsStore } = store
      const { setCurrentPortalSection } = portalSettingsStore

      setCurrentPortalSection(PortalSectionEnum.Depots)
    },
    path: `${DepotsRoutePathsEnum.DEPOT}`,
  },
  {
    component: Depots,
    onRender: () => {
      const { portalSettingsStore } = store
      const { setCurrentPortalSection } = portalSettingsStore

      setCurrentPortalSection(PortalSectionEnum.Depots)
    },
    path: `${DepotsRoutePathsEnum.DEPOTS}`,
  },
]
