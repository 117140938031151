import {
  GLOBAL_SEARCH_WHITELIST,
  PORTAL_CONTENT_MODEL,
} from 'Constants/portalContentModel'

import { PortalContentModelItem } from 'Types/content/content.types'

export const GS_SEARCH_RESULTS_PER_PAGE = 10

export const GS_SEARCH_RESULTS_FILTER_BUTTON = 'GS_SEARCH_FILTER_BUTTON'
export const GS_SEARCH_RESULTS_ITEM_DESCRIPTION =
  'GS_SEARCH_RESULTS_ITEM_DESCRIPTION'
export const GS_SEARCH_RESULTS_ITEM_STATUS = 'GS_SEARCH_RESULTS_ITEM_STATUS'

export const SEARCH_RESULTS_MODEL_FILTER_OPTIONS = Object.keys(
  PORTAL_CONTENT_MODEL,
).reduce(
  (acc: PortalContentModelItem[], key) =>
    GLOBAL_SEARCH_WHITELIST.includes(PORTAL_CONTENT_MODEL[key].modelType)
      ? [...acc, PORTAL_CONTENT_MODEL[key]]
      : acc,
  [],
)
