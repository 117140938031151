import { isEmpty } from 'lodash-es'
import { ParsedQuery } from 'query-string'

// TODO: remove after instantsearch is implemented
export const getActiveFilters = (parsedSearch: ParsedQuery<string> | null) =>
  !isEmpty(parsedSearch)
    ? Object.keys(parsedSearch)
        .map(key => {
          if (key === 'searchModel') {
            return undefined
          }

          return {
            name: key,
            value: parsedSearch[key] as string,
          }
        })
        .filter(
          (
            item,
          ): item is {
            name: string
            value: string
          } => !!item,
        )
    : []
