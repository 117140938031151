import { gql } from '@apollo/client'

export const MUTATION_EMPLOYEE_UPDATE = gql`
  mutation mutationEmployeeUpdate($input: EmployeeUpdateInput!) {
    employeeUpdate(input: $input) {
      ... on EmployeeUpdateSuccess {
        __typename
        employee {
          id
        }
      }

      ... on EmployeeUpdateProblem {
        __typename
        title
        invalidInputs {
          field
          message
        }
      }
    }
  }
`
