import { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { GroupBase } from 'react-select'
import { useQuery } from '@apollo/client'
import { observer } from 'mobx-react'

import { Field } from 'Components/Field/Field'
import { FieldTypeEnum } from 'Components/Field/Field.types'
import { SelectOption } from 'Components/Select/Select.types'

import { getFullContactName } from 'Utilities'

import {
  getEmployeeByName,
  getEmployeeByName_organisation_employees_employees,
  getEmployeeByNameVariables,
} from 'Constants/graphql/queries/employee/__generated__/getEmployeeByName'
import { GET_EMPLOYEE_BY_NAME } from 'Constants/graphql/queries/employee/getEmployeeByName'

import { useActiveUser } from 'Portal/src/hooks'

import { EmployeesSelectProps } from './EmployeesSelect.types'

export const EmployeesSelect = observer(
  <
    Option extends SelectOption,
    IsMulti extends boolean,
    Group extends GroupBase<Option> = GroupBase<Option>,
  >(
    props: EmployeesSelectProps<Option, IsMulti, Group>,
  ) => {
    const { name, currentId, ...rest } = props
    const [employeeOptions, setEmployeeOptions] = useState<SelectOption[]>([])
    const { activeOrganisation } = useActiveUser()

    const defaultNoOptionsMessage = useCallback(
      () => <FormattedMessage id="EmployeesSelect_NoResults" />,
      [],
    )

    if (!activeOrganisation) {
      return null
    }

    const { loading: isLoading } = useQuery<
      getEmployeeByName,
      getEmployeeByNameVariables
    >(GET_EMPLOYEE_BY_NAME, {
      onCompleted: data => {
        const parsedEmployees: SelectOption[] =
          data.organisation.employees?.employees?.reduce(
            (
              acc: SelectOption[],
              employee: getEmployeeByName_organisation_employees_employees,
            ) => {
              if (currentId !== employee.id) {
                const option = {
                  label: `${getFullContactName(employee.contact)} ${
                    employee.contact.email ? ` (${employee.contact.email})` : ''
                  }`,
                  value: employee.id.toString(),
                }

                return [option, ...acc]
              }

              return acc
            },
            [],
          ) || []

        setEmployeeOptions(parsedEmployees)
      },
      variables: {
        orgId: activeOrganisation.id.toString(),
      },
    })

    return (
      <Field
        {...rest}
        name={name}
        selectProps={{
          isClearable: true,
          isLoading,
          noOptionsMessage: defaultNoOptionsMessage,
          options: employeeOptions,
        }}
        type={FieldTypeEnum.select}
      />
    )
  },
)
