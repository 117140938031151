import { FormattedMessage } from 'react-intl'
import { clsx } from 'clsx'

import { Badge } from 'Components/_theme'

import { dashOrSpaceToUnderscore } from 'Utilities'

import {
  ORDER_STATUS_BADGE_COLOR_MAP,
  ORDER_STATUS_BADGE_VARIANT_MAP,
  ORDER_STATUS_TRANSLATION_IDS,
} from 'Constants/orderStatus'

import { OrderStatusBadgeProps } from './OrderStatusBadge.types'

export const OrderStatusBadge = (props: OrderStatusBadgeProps) => {
  const { className, status } = props
  const formattedStatus = dashOrSpaceToUnderscore(status)

  return (
    <Badge
      className={clsx(className)}
      color={ORDER_STATUS_BADGE_COLOR_MAP[formattedStatus]}
      variant={ORDER_STATUS_BADGE_VARIANT_MAP[formattedStatus]}
    >
      <FormattedMessage id={ORDER_STATUS_TRANSLATION_IDS[formattedStatus]} />
    </Badge>
  )
}
