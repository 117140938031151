// import { useMediaQuery } from '@react-hookz/web'

// import { useStore } from 'Stores/index'

// import { PORTAL_BREAKPOINTS } from 'Constants/constants'

/**
 * This hook is used to check if the ap is in serviceApp mode
 * Please wrap component with the `observer` method from 'react-mobx' for the hook to re-sync data properly.
 */
export function useIsServiceApp() {
  // const { employeeStore } = useStore()
  // const { employee } = employeeStore
  // const isMobileView = useMediaQuery(`(max-width: ${PORTAL_BREAKPOINTS.SM})`)

  // return !!employee?.id && !!isMobileView

  // hide service app features for now
  return false
}
