import { Image } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'

import { DashboardBenefitSlideProps } from './BenefitSlide.types'

export const DashboardBenefitSlide = (props: DashboardBenefitSlideProps) => {
  const { content, imageSource, header } = props
  const intl = useIntl()

  return (
    <div className="benefit-card">
      <Image
        alt={intl.formatMessage({
          id: header,
        })}
        className="benefit-card-image"
        fluid
        src={imageSource}
      />
      <span className="benefit-card-title">
        <FormattedMessage id={header} />
      </span>
      <span className="benefit-card-content">
        <FormattedMessage id={content} />
      </span>
    </div>
  )
}
