import {
  getCatalog_catalog_offers_offerConfigGroups_offerConfigs_offerConfigs_terms_offerTerms,
  getCatalog_catalog_rentCategories_rentcategories_deviceDefinitions_deviceDefinitions,
} from 'Constants/graphql/queries/feature/__generated__/getCatalog'
import { getOrderOrganisation_organisation_rentCategories_rentcategories_deviceDefinitions_deviceDefinitions } from 'Constants/graphql/queries/feature/__generated__/getOrderOrganisation'

export enum OrderDeviceResponseVariant {
  RENT_CATEGORY = 'RENT_CATEGORY',
  CATALOG = 'CATALOG',
}

export interface OrderDevice {
  copaymentGross: number
  basisAmount: number
  copaymentNet: number
  deviceDefinition:
    | getOrderOrganisation_organisation_rentCategories_rentcategories_deviceDefinitions_deviceDefinitions
    | getCatalog_catalog_rentCategories_rentcategories_deviceDefinitions_deviceDefinitions
  id: string
  offerId: string
  minimumContractDuration: number | null
  rentCategoryId: string
  quantity: number
  forcePriceHide: boolean
  offerItemId?: string
  bundleImage?: string | null
  bundleName?: string | null
  bundleQuantity?: number
  isBundle?: boolean
  calculatedNet?: number
  addonDevices?: OrderDevice[]
  optionalDevices?: OrderDevice[]
  customerLabel?: string
  termsLabels?: getCatalog_catalog_offers_offerConfigGroups_offerConfigs_offerConfigs_terms_offerTerms[]
  bundleCopaymentGross?: number
  bundleCopaymentNet?: number
  bundleBasisAmount?: number
  hasDeviceWithCoPaymentPrice?: boolean
  isCreateSeparateRental?: boolean
  customClientReference?: string
  poRcn?: string
  billingIdentifier?: string
  customDeviceName?: string | null
}

export interface OrderDeviceGroup {
  id: string
  name: string
  descriptionDE: string | null
  descriptionEN: string | null
  descriptionFR?: string | null
  devices: OrderDevice[]
  displaySortOrder: number | null
}

export interface OrderDeviceMappedResponse {
  variant: OrderDeviceResponseVariant
  allDevices: OrderDevice[] | null
  groupedDevices: OrderDeviceGroup[] | null
}

export interface OrderDeviceItemPayload {
  quantity: number
  offerItemId: string
  customerLabel?: string
  billingIdentifier?: string
  customClientReference?: string
  poRcn?: string
}
