import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { observer } from 'mobx-react'

import { PrimaryButton } from 'Components/_theme'
import { FormattedCurrency } from 'Components/FormattedCurrency/FormattedCurrency'
import { ImageWithFallback } from 'Components/ImageWithFallback/ImageWithFallback'
import { PreviewColumn } from 'Components/PreviewColumn/PreviewColumn'
import { PreviewColumnTypeEnum } from 'Components/PreviewColumn/PreviewColumn.types'

import { useIsOrganisation } from 'Hooks'

import { DeviceTypeEnum } from 'Types/deviceTypes'

import { useStore } from 'Portal/src/stores'

import { OrderBundlePreviewColumnProps } from './OrderBundlePreviewColumn.types'

export const OrderBundlePreviewColumn = observer(
  (props: OrderBundlePreviewColumnProps) => {
    const { portalConfigStore } = useStore()
    const { portalConfig } = portalConfigStore
    const isOrganisation = useIsOrganisation()

    const {
      device,
      onClosePreviewColumn,
      onRentBundleButtonAction,
      e2eSelectorFooterBtn,
      isVisible,
    } = props

    const renderFooterButton = useMemo(
      () => (
        <PrimaryButton
          className="w-100"
          data-e2e={e2eSelectorFooterBtn}
          iconRight={faChevronRight}
          onClick={onRentBundleButtonAction}
        >
          <span className="d-flex align-items-center justify-content-center">
            <FormattedMessage id="Next" />
          </span>
        </PrimaryButton>
      ),
      [e2eSelectorFooterBtn, onRentBundleButtonAction],
    )

    const renderBundleItems = useMemo(() => {
      const bundleItems = [device, ...(device.addonDevices || [])]

      return (
        <div className="OrderBundlePreviewColumn">
          <ul className="OrderBundlePreviewColumn-items m-0 p-4 overflow-auto">
            {bundleItems?.map(addonDevice => (
              <li
                key={addonDevice.id}
                className="OrderBundlePreviewColumn-item card d-flex flex-row p-4 align-items-center mb-4"
              >
                <div className="me-4">
                  <ImageWithFallback
                    alt={
                      addonDevice.customDeviceName ||
                      addonDevice.deviceDefinition.name
                    }
                    placeholderType={
                      addonDevice.deviceDefinition.productType as DeviceTypeEnum
                    }
                    src={addonDevice.deviceDefinition.imageURL || ''}
                  />
                </div>
                <div>
                  <h5 className="fw-bold m-0">
                    {addonDevice.customDeviceName ||
                      addonDevice.deviceDefinition.name}
                  </h5>
                  <div className="mt-2">
                    {addonDevice.isCreateSeparateRental && (
                      <span className="d-block">
                        <FormattedMessage
                          id="RentalDuration"
                          values={{
                            total: addonDevice.minimumContractDuration,
                          }}
                        />
                      </span>
                    )}

                    {isOrganisation &&
                      portalConfig?.showRentalBasisAmountAtOrderFlow &&
                      !addonDevice.forcePriceHide && (
                        <span className="d-block">
                          <div>
                            <FormattedMessage id="SelectionCard_monthly_price" />
                            :{' '}
                            <FormattedCurrency
                              value={addonDevice.basisAmount ?? 0}
                            />
                          </div>
                        </span>
                      )}

                    {portalConfig?.showCopaymentWithAndWithoutTax ? (
                      <div className="OrderSelectionCard-copayment">
                        <FormattedMessage id="SelectionCard_monthly_copayment" />
                        <br />
                        <FormattedMessage id="Excl_tax" />
                        :&nbsp;
                        <FormattedCurrency value={addonDevice.copaymentNet} />
                        <br />
                        <FormattedMessage id="Incl_tax" />
                        :&nbsp;
                        <FormattedCurrency value={addonDevice.copaymentGross} />
                      </div>
                    ) : (
                      <div className="OrderSelectionCard-copayment">
                        <FormattedMessage id="SelectionCard_monthly_copayment" />
                        :&nbsp;
                        <FormattedCurrency value={addonDevice.copaymentNet} />
                      </div>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )
    }, [device, isOrganisation, portalConfig])

    return (
      <PreviewColumn
        contentElement={renderBundleItems}
        disableFooterAskQuestion
        id={device.id}
        isVisible={isVisible}
        onClosePreviewColumn={onClosePreviewColumn}
        renderCustomFooterButton={renderFooterButton}
        type={PreviewColumnTypeEnum.Bundle}
      />
    )
  },
)
