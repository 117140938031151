import { createElement, Fragment, useEffect, useRef } from 'react'
import { createRoot, Root } from 'react-dom/client'
import { useHistory, useLocation } from 'react-router-dom'
import { autocomplete } from '@algolia/autocomplete-js'
import { isEmpty } from 'lodash-es'

import { SearchResultsRoutePathsEnum } from 'Modules/searchResults/routes/searchResultsRouteConfig.types'

import { useBasePageUrl } from 'Hooks'

import { getQueryObject, mergeToQueryString } from 'Utilities'

import { PORTAL_CONTENT_MODEL } from 'Constants/portalContentModel'

import { GlobalSearchAutocompleteSuggestion } from './GlobalSearch.types'
import { stripHtmlFromString } from './GlobalSearch.utils'

export const GlobalSearchAutocomplete = (props: any) => {
  const { isInitiallyOpen, initialCollection, ...restProps } = props
  const autocompleteRef = useRef(null)
  const panelRootRef = useRef<Root | null>(null)
  const rootRef = useRef<HTMLElement | null>(null)
  const { replace } = useHistory()
  const { search: routerSearch } = useLocation()
  const { basePageUrl } = useBasePageUrl()

  useEffect(() => {
    if (!autocompleteRef.current) {
      return undefined
    }

    const currentSearchQuery = getQueryObject(routerSearch)

    const search = autocomplete({
      classNames: {
        panel: 'gs-panel',
        panelLayout: 'gs-panel-layout',
        submitButton: 'gs-submit-button',
      },
      container: autocompleteRef.current,
      detachedMediaQuery: 'none',
      getInputProps({ props }) {
        return {
          ...props,
          tabIndex: 2,
        }
      },
      initialState: {
        collections: isEmpty(initialCollection) ? [] : [initialCollection],
        isOpen: isInitiallyOpen,
        query: currentSearchQuery?.searchTerm || '',
      },
      onReset: () => {
        const clearedSearchQuery = mergeToQueryString(routerSearch, {
          searchTerm: '',
        })

        replace({ search: clearedSearchQuery })
      },
      onSubmit: params => {
        const { state } = params

        if (!state.query || state.query?.length < 3) {
          return
        }

        const selectedItem = state.collections
          .map(collectionItem =>
            collectionItem.items.find(
              item => item.__autocomplete_id === state.activeItemId,
            ),
          )
          .filter(item => item !== undefined)
          .flat()[0] as GlobalSearchAutocompleteSuggestion | undefined

        if (selectedItem && selectedItem.type === 'direct') {
          let searchTerm =
            selectedItem?.highlights?.[0]?.snippet || selectedItem.snippet

          if (
            selectedItem.category === 'caseitems' ||
            selectedItem.category === 'return_cases'
          ) {
            searchTerm = selectedItem.id
          }

          const submitSearchQuery = mergeToQueryString(routerSearch, {
            searchTerm: stripHtmlFromString(searchTerm),
          })

          const modelPath = PORTAL_CONTENT_MODEL[selectedItem.category].path
          const itemPath = `${basePageUrl}/${modelPath}/${selectedItem.id}`

          replace({ pathname: itemPath, search: submitSearchQuery })
        } else {
          const submitSearchQuery = mergeToQueryString(routerSearch, {
            searchTerm: stripHtmlFromString(
              selectedItem?.snippet || state.query,
            ),
          })

          replace({
            pathname: `${basePageUrl}/${SearchResultsRoutePathsEnum.SEARCH_RESULTS}`,
            search: submitSearchQuery,
          })
        }
      },
      render: ({ children }, root) => {
        if (!panelRootRef.current || rootRef.current !== root) {
          rootRef.current = root

          panelRootRef.current?.unmount()
          panelRootRef.current = createRoot(root)
        }

        panelRootRef?.current?.render(children)
      },
      renderer: { Fragment, createElement, render: () => {} },
      shouldPanelOpen: () => true,
      stallThreshold: 1500,
      ...restProps,
    })

    return () => {
      search.destroy()
    }
  }, [
    restProps,
    isInitiallyOpen,
    replace,
    initialCollection,
    routerSearch,
    basePageUrl,
  ])

  return <div ref={autocompleteRef} className="gs-autocomplete w-100" />
}
