export const isValidURL = (url: string) => {
  try {
    // eslint-disable-next-line no-new
    new URL(url)
  } catch (e) {
    console.error(e)

    return false
  }

  return true
}
