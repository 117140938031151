import { gql } from '@apollo/client'

export const GET_DEPOT_DETAILS = gql`
  query getDepotDetails($id: ID!, $organisationId: ID) {
    depot(id: $id, organisationId: $organisationId) {
      id
      locationName
      isDepotOfTheReturn
      stocklistItems {
        totalCount
        stocklistItems {
          id
          imei
          imei2
          serialNumber
          eid
          deviceDefinition {
            id
            manufacturer
            codename
            storage
            ram
            color
            edition
            displaySize
            rentPriceForCustomerPortal
            legacyInlineDeviceDefinition
            name
            releaseDate
            imageURL
            productType
          }
        }
      }
      organisationID
    }
  }
`
