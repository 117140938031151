import { getPortalConfig_portalConfigStoreForUserAndOrg } from 'Constants/graphql/queries/detail/__generated__/getPortalConfig'

export const setTheme = (
  portalConfig: getPortalConfig_portalConfigStoreForUserAndOrg,
) => {
  if (portalConfig.customTheme) {
    document.documentElement.setAttribute(
      'data-theme',
      portalConfig.customTheme,
    )
    localStorage.setItem('theme', portalConfig.customTheme)
  } else {
    document.documentElement.setAttribute('data-theme', 'everphone')
    localStorage.removeItem('theme')
  }
}

export const clearThemeWhenNotLogged = (jwt: string | null | undefined) => {
  if (!jwt) {
    document.documentElement.setAttribute('data-theme', 'everphone')
    localStorage.removeItem('theme')
  }
}
