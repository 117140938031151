import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { ApolloError, useMutation } from '@apollo/client'
import { observer } from 'mobx-react'

import { OrderWrapper } from 'Modules/order/components/OrderWrapper/OrderWrapper'
import {
  AccessoriesStep,
  AddressStep,
  DeviceStep,
  SummaryStep,
} from 'Modules/order/components/Steps'
import {
  useOfferOrderMutation,
  useRedirectToConfirmationPage,
} from 'Modules/order/hooks'
import { OrderFlowTypesEnum } from 'Modules/order/types/orderFlow.types'
import { showErrorModal } from 'Modules/order/utils/errorModal.utils'
import { notEmpty } from 'Modules/order/utils/notEmpty.utils'
import {
  getOfferOrderMutationVariables,
  getRentCategoryOrderMutationVariables,
} from 'Modules/order/utils/order.utils'

import { Stepper, StepperStep } from 'Components/Stepper'

import { useStore } from 'Stores/index'

import { useActiveUser, useIsOrganisation, useModal } from 'Hooks'

import {
  mutationNewDeviceCaseItem,
  mutationNewDeviceCaseItemVariables,
} from 'Constants/graphql/mutations/__generated__/mutationNewDeviceCaseItem'
import { MUTATION_NEW_DEVICE_CASEITEM } from 'Constants/graphql/mutations/MutationNewDeviceCaseItem'

import { OrderType } from 'Portal/__generated__/globalTypes'
import { OrderDeviceResponseVariant } from 'Portal/src/mappers/orderDeviceMapper/orderDevice.types'

export const OrderNew = observer(() => {
  const intl = useIntl()

  const { orderStore, userStore, portalSettingsStore, navigationStore } =
    useStore()

  const {
    setMutationError,
    setOrderedDevices,
    orderDevices,
    chosenAccessories,
    chosenShippingAddress,
    chosenDevice,
    shouldSkipAccessories,
    officeAddress,
    orderEmployeeName,
    orderExternalId,
    phonePlanDetails,
  } = orderStore

  const { user } = userStore
  const { userMode } = portalSettingsStore
  const { mainNavigationConfig } = navigationStore
  const history = useHistory()

  const { setModal, hideModal } = useModal()

  const isOrganisation = useIsOrganisation()
  const { activeEmployee, activeOrganisation } = useActiveUser()
  const { employeeId = '' } = useParams<{ employeeId?: string }>()
  const activeEmployeeId = employeeId || activeEmployee?.id.toString()

  const { redirectToConfirmationPage } =
    useRedirectToConfirmationPage(activeEmployeeId)

  const triggerErrorModal = useCallback(
    (error: ApolloError) => {
      showErrorModal(
        () => {
          hideModal()

          history.push(mainNavigationConfig.url)
        },
        setModal,
        error,
        hideModal,
      )
    },
    [hideModal, history, mainNavigationConfig.url, setModal],
  )

  const { handleOfferOrderMutation, isOfferOrderLoading } =
    useOfferOrderMutation({
      handleOnCompletedOrder: () => {
        redirectToConfirmationPage()
      },

      handleOnErrorOrder: triggerErrorModal,
      variables: getOfferOrderMutationVariables({
        activeEmployee,
        activeEmployeeId,
        activeOrganisation,
        chosenAccessories,
        chosenDevice,
        chosenShippingAddress,
        officeAddress,
        orderExternalId,
        orderType: OrderType.newDevice,
        phonePlanDetails,
        user,
      }),
    })

  const [createNewDeviceCaseItem, { loading: isMutationLoading }] = useMutation<
    mutationNewDeviceCaseItem,
    mutationNewDeviceCaseItemVariables
  >(MUTATION_NEW_DEVICE_CASEITEM, {
    errorPolicy: 'all',
    onCompleted: data => {
      const metadata = {
        orderedDevice: chosenDevice?.deviceDefinition.name,
        quantity: chosenDevice?.quantity,
        userType: isOrganisation ? 'Admin' : 'Employee',
      }

      Intercom('trackEvent', 'new-order', metadata)

      if (data.createNewDeviceCaseItem.subCaseItems) {
        const filteredDevices =
          data.createNewDeviceCaseItem.subCaseItems.filter(notEmpty)

        setOrderedDevices(filteredDevices)
      } else {
        setOrderedDevices(Number(data.createNewDeviceCaseItem.id))
      }

      setMutationError(false)

      redirectToConfirmationPage()
    },
    onError: error => {
      setMutationError(true)

      triggerErrorModal(error)
    },
    variables: getRentCategoryOrderMutationVariables({
      activeEmployee,
      activeEmployeeId,
      activeOrganisation,
      chosenAccessories,
      chosenDevice,
      chosenShippingAddress,
      officeAddress,
      orderExternalId,
      orderType: OrderType.newDevice,
      user,
      userMode,
    }),
  })

  const orderNewStepsConfig = useMemo(() => {
    const headingSufix =
      !!activeOrganisation && employeeId && orderEmployeeName
        ? `${intl.formatMessage({ id: 'for' })} ${orderEmployeeName}`
        : undefined

    const baseSteps: StepperStep[] = [
      {
        headingLabel: 'NewOrder_choose_device_header',
        headingSufix,
        renderStepContent: ({ handleNextStep }) => (
          <DeviceStep handleNextStep={handleNextStep} />
        ),
        subHeadingLabel: 'NewOrder_choose_device_subheader',
      },
      {
        headingLabel: 'NewOrder_shipping_address_header',
        headingSufix,
        renderStepContent: ({ handleNextStep, handlePreviousStep }) => (
          <AddressStep
            activeEmployeeId={activeEmployeeId}
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
          />
        ),
        subHeadingLabel: 'NewOrder_shipping_address_subheader',
      },
      {
        headingLabel: 'NewOrder_order_summary_header',
        headingSufix,
        renderStepContent: ({ handleSetCurrentStep, handlePreviousStep }) => (
          <SummaryStep
            activeEmployeeId={employeeId}
            handleOrderSubmit={() => {
              if (
                orderDevices?.variant === OrderDeviceResponseVariant.CATALOG
              ) {
                handleOfferOrderMutation()
              } else {
                createNewDeviceCaseItem()
              }
            }}
            handlePreviousStep={handlePreviousStep}
            isNewOrder
            isSubmitLoading={isMutationLoading || isOfferOrderLoading}
            setCurrentOrderStep={handleSetCurrentStep}
          />
        ),
        subHeadingLabel: 'NewOrder_order_summary_subheader',
      },
    ]

    const accessoriesStep: StepperStep = {
      headingLabel: 'NewOrder_choose_accessories_header',
      headingSufix,
      renderStepContent: ({ handleNextStep, handlePreviousStep }) => (
        <AccessoriesStep
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
        />
      ),
      subHeadingLabel: 'NewOrder_choose_accessories_subheader',
    }

    if (shouldSkipAccessories) {
      return baseSteps
    }

    baseSteps.splice(1, 0, accessoriesStep)

    return baseSteps
  }, [
    activeEmployeeId,
    activeOrganisation,
    createNewDeviceCaseItem,
    employeeId,
    handleOfferOrderMutation,
    intl,
    isMutationLoading,
    isOfferOrderLoading,
    orderDevices?.variant,
    orderEmployeeName,
    shouldSkipAccessories,
  ])

  return (
    <OrderWrapper
      employeeId={activeEmployeeId}
      orderFlowType={OrderFlowTypesEnum.NEW_ORDER}
      renderOrderContent={() => <Stepper steps={orderNewStepsConfig} />}
    />
  )
})
