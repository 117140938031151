import { Col, Container, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { observer } from 'mobx-react'

import { ReturnDetailsCard } from 'Modules/returns/components/ReturnDetailsCard/ReturnDetailsCard'
import { ReturnPresentationCard } from 'Modules/returns/components/ReturnPresentationCard/ReturnPresentationCard'

import { EmployeeEntityCard } from 'Components/EmployeeEntityCard/EmployeeEntityCard'
import { PageErrorState } from 'Components/PageErrorState/PageErrorState'
import { PageHeader } from 'Components/PageHeader/PageHeader'
import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'
import { ReturnShipmentTrackingCard } from 'Components/ShipmentTrackingCard/Return/ReturnShipmentTrackingCard'

import { useActiveUser } from 'Hooks'

import {
  getReturnCase,
  getReturnCaseVariables,
} from 'Constants/graphql/queries/detail/__generated__/getReturnCase'
import { GET_RETURN_CASE } from 'Constants/graphql/queries/detail/GetReturnCase'
import { PortalSectionEnum } from 'Constants/portalSection'

export const Return = observer(() => {
  const { activeOrganisation } = useActiveUser()
  const isOrganisation = Boolean(activeOrganisation?.id)

  const { returnCaseId } = useParams<{
    returnCaseId: string
  }>()

  const {
    data,
    loading: isLoading,
    error,
    refetch,
  } = useQuery<getReturnCase, getReturnCaseVariables>(GET_RETURN_CASE, {
    variables: {
      id: returnCaseId,
    },
  })

  if (isLoading) {
    return <PageLoadingState />
  }

  if (error) {
    return <PageErrorState />
  }

  if (!data) {
    return <PageErrorState />
  }

  return (
    <Container className="pt-6" fluid>
      <div role="banner">
        <PageHeader portalSection={PortalSectionEnum.ReturnDetails} />
      </div>

      <Row>
        <Col xxl={8}>
          <ReturnPresentationCard returnCase={data.returnCaseItem} />

          <ReturnDetailsCard
            refetch={refetch}
            returnCase={data.returnCaseItem}
            title="ReturnDetails_your_return"
          />
        </Col>

        <Col xxl={4}>
          <Row>
            <Col lg={6} xs={12} xxl={12}>
              <ReturnShipmentTrackingCard returnCase={data.returnCaseItem} />
            </Col>

            <Col lg={6} xs={12} xxl={12}>
              {isOrganisation && (
                <EmployeeEntityCard employee={data.returnCaseItem.employee} />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
})
