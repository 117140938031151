import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { clsx } from 'clsx'

import { Badge } from 'Components/_theme'

import { ReturnCaseSubstatus } from 'Portal/__generated__/globalTypes'
import { dashOrSpaceToUnderscore } from 'Portal/src/utilities'

import { ReturnBlockedBadgeProps } from './ReturnBlockedBadge.types'

export const ReturnBlockedBadge = (props: ReturnBlockedBadgeProps) => {
  const { className, substatus } = props

  const badgeMessageId = useMemo(() => {
    if (!substatus) {
      return null
    }

    const returnCaseSubstatus = dashOrSpaceToUnderscore(substatus)

    if (returnCaseSubstatus === ReturnCaseSubstatus.in_customer_lock) {
      return 'Device_locked_badge_in_customer_lock'
    }

    if (returnCaseSubstatus === ReturnCaseSubstatus.in_mdm) {
      return 'Device_locked_badge_in_mdm'
    }

    if (returnCaseSubstatus === ReturnCaseSubstatus.in_mdm_and_customer_lock) {
      return 'Device_locked_badge_in_mdm_and_customer_lock'
    }

    return null
  }, [substatus])

  if (!badgeMessageId) {
    return null
  }

  return (
    <Badge
      className={clsx(className)}
      color="pink"
      textWrap
      variant="secondary"
    >
      <FormattedMessage id={badgeMessageId} />
    </Badge>
  )
}
