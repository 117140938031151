import { createContext } from 'react'

import { InstantsearchIndexProviderProps } from './InstantsearchIndexProvider.types'

export const InstantsearchIndexContext = createContext<string | undefined>(
  undefined,
)

export const InstantsearchIndexProvider = (
  props: InstantsearchIndexProviderProps,
) => {
  const { children, indexName, ...rest } = props

  return (
    <InstantsearchIndexContext.Provider value={indexName} {...rest}>
      {children}
    </InstantsearchIndexContext.Provider>
  )
}
