import type * as React from 'react'
import { useState } from 'react'
import { Form } from 'react-bootstrap'

import {
  PFR_PROGRESS_TRACK,
  PFR_SLIDER_THUMB,
} from './ProgressFormRange.constants'
import { ProgressFormRangeProps } from './ProgressFormRange.types'

export const ProgressFormRange = (props: ProgressFormRangeProps) => {
  const {
    disabled,
    max,
    min,
    name,
    ref,
    step,
    onChangeCallback,
    onInputCallback,
    value,
    id,
  } = props
  const [currentValue, setCurrentValue] = useState(value || 0)
  const currentPercentage =
    ((currentValue - (min || 0)) * 100) / ((max || 100) - (min || 0))

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(parseFloat(event.target.value))
    if (onChangeCallback) onChangeCallback(event)
  }

  return (
    <div className="ProgressFormRange">
      <div className="background-track" />
      <div
        className="progress-track"
        data-testid={PFR_PROGRESS_TRACK}
        style={{ minWidth: `${currentPercentage}%` }}
      />
      <div
        className="slider-thumb"
        data-testid={PFR_SLIDER_THUMB}
        style={{
          left: `${currentPercentage}%`,
          transform: `translateX(-${currentPercentage}%)`,
        }}
      />
      <Form.Range
        ref={ref}
        disabled={disabled}
        id={id}
        max={max}
        min={min}
        name={name}
        onChange={handleChange}
        onInput={onInputCallback}
        step={step}
        value={currentValue}
      />
    </div>
  )
}
