import { useFormContext } from 'react-hook-form'

import { Input } from 'Components/_theme'

import { FieldInputProps } from './Field.types'

export const FieldInput = (props: FieldInputProps) => {
  const {
    type,
    name,
    hasError,
    inputClassName,
    inputProps,
    markAsRequired,
    e2eSelector,
    autoComplete,
    ...rest
  } = props
  const { register, setValue, watch, setFocus } = useFormContext()
  const isFilled = !!watch(name)

  const handleClear = () => {
    setValue(name, '')
  }

  const handleFocus = () => {
    setFocus(name)
  }

  return (
    <Input
      className={inputClassName}
      {...register(name)}
      {...inputProps}
      {...rest}
      autoComplete={autoComplete}
      data-e2e={e2eSelector}
      id={name}
      isFilled={isFilled}
      isInvalid={hasError}
      markAsRequired={markAsRequired}
      onClear={handleClear}
      onTriggerFocus={handleFocus}
      type={type}
    />
  )
}
