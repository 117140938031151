import { ForwardedRef, forwardRef } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { clsx } from 'clsx'

import { Icon } from 'Components/Icon/Icon'

import { OptionButtonProps } from './OptionButton.types'

export const OptionButton = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  OptionButtonProps
>((props, ref) => {
  const {
    className,
    iconLeft,
    children,
    to,
    onClick,
    variant = 'basic',
    asExternalLink,
    ...restProps
  } = props

  const classNames = clsx(
    'OptionButton',
    variant !== 'basic' && `OptionButton-${variant}`,
    className,
  )

  const content = (
    <div className="option" data-testid={restProps['data-testid']}>
      <div className="d-flex">
        <div className="icon-bg">
          {iconLeft && (
            <Icon className="m-0" color="white" icon={iconLeft} size={20} />
          )}
        </div>

        <span className="px-4 option-text">{children}</span>
      </div>

      <div className="option-chevron">
        <Icon icon={faChevronRight} size={16} />
      </div>
    </div>
  )

  if (asExternalLink) {
    return (
      <a
        ref={ref as ForwardedRef<HTMLAnchorElement>}
        className={classNames}
        href={to as string}
        {...restProps}
      >
        {content}
      </a>
    )
  }

  if (onClick) {
    return (
      <button
        className={clsx(classNames, 'button-unstyled')}
        onClick={onClick}
        type="button"
      >
        {content}
      </button>
    )
  }

  return (
    <Link
      ref={ref as ForwardedRef<HTMLAnchorElement>}
      className={classNames}
      to={to as LinkProps['to']}
      {...restProps}
    >
      {content}
    </Link>
  )
})
