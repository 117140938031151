import {
  IconDefinition,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core'

export const iconLostDevice: IconDefinition = {
  icon: [
    32,
    32,
    [],
    'e008',
    'M9.292 0.059 C 8.815 0.114,8.029 0.372,7.600 0.613 C 6.594 1.178,5.867 2.088,5.510 3.229 L 5.360 3.707 5.360 16.000 L 5.360 28.293 5.510 28.771 C 5.867 29.912,6.594 30.822,7.600 31.387 C 8.056 31.643,8.807 31.881,9.362 31.944 C 10.011 32.019,21.989 32.019,22.640 31.944 C 23.204 31.880,24.028 31.612,24.456 31.355 C 25.471 30.742,26.138 29.896,26.490 28.771 L 26.640 28.293 26.640 16.000 L 26.640 3.707 26.490 3.229 C 26.133 2.088,25.406 1.178,24.400 0.613 C 23.944 0.357,23.193 0.119,22.638 0.056 C 22.056 -0.011,9.867 -0.008,9.292 0.059 M24.000 16.000 L 24.000 27.680 16.000 27.680 L 8.000 27.680 8.000 16.000 L 8.000 4.320 16.000 4.320 L 24.000 4.320 24.000 16.000 M14.960 10.112 C 13.750 10.328,12.661 10.900,11.780 11.780 C 10.008 13.553,9.530 16.188,10.564 18.493 C 11.079 19.642,12.187 20.784,13.360 21.374 C 14.824 22.111,16.765 22.159,18.352 21.499 C 19.671 20.950,20.950 19.671,21.499 18.352 C 22.159 16.765,22.111 14.824,21.374 13.360 C 20.657 11.935,19.328 10.793,17.813 10.302 C 16.984 10.033,15.833 9.957,14.960 10.112 M16.827 14.800 L 16.827 16.613 16.013 16.628 L 15.200 16.643 15.200 14.800 L 15.200 12.957 16.013 12.972 L 16.827 12.987 16.827 14.800 M16.800 18.400 L 16.800 19.040 16.000 19.040 L 15.200 19.040 15.200 18.400 L 15.200 17.760 16.000 17.760 L 16.800 17.760 16.800 18.400',
  ],
  iconName: 'lostDevice' as IconName,
  prefix: 'fac' as IconPrefix,
}
