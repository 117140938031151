import { useMemo } from 'react'
import { Line } from 'react-chartjs-2'
import { useIntl } from 'react-intl'
import { ChartConfiguration } from 'chart.js'
import { observer } from 'mobx-react'

import { useStore } from 'Stores/index'

import { CHART_FONT } from 'Constants/charts'

import {
  ORDERS_REPORT_CARD_CHART_X_COLOR,
  ORDERS_REPORT_CARD_CHART_Y_COLOR,
} from './OrdersReportCardChart.constants'
import { OrdersReportCardChartProps } from './OrdersReportCardChart.types'
import {
  generateOrdersReportCardChartDatasets,
  generateOrdersReportCardChartLegendPlugin,
  ordersReportCardChartMouseLinePlugin,
} from './OrdersReportCardChart.util'

export const OrdersReportCardChart = observer(
  (props: OrdersReportCardChartProps) => {
    const { data } = props
    const { portalSettingsStore } = useStore()
    const { i18n } = portalSettingsStore
    const intl = useIntl()

    const ordersReportCardChartLegendPlugin = useMemo(
      () => generateOrdersReportCardChartLegendPlugin(intl, i18n),
      [intl, i18n],
    )

    const { sortedDatasets: datasets, labels } =
      generateOrdersReportCardChartDatasets(data, intl, i18n)

    const chartConfig: ChartConfiguration<'line'> = {
      data: {
        datasets,
        labels,
      },
      options: {
        elements: {
          line: {
            tension: 0.3,
          },
        },
        interaction: {
          intersect: false,
          mode: 'index',
        },
        maintainAspectRatio: false,
        mouseLine: {
          color: '#ADB5BD',
        },
        plugins: {
          htmlLegend: {
            containerID: 'ordersReportCardLegend',
          },
          legend: {
            display: false,
          },
          tooltip: {
            bodyFont: CHART_FONT,
            titleFont: CHART_FONT,
          },
        },
        responsive: true,
        scales: {
          x: {
            border: {
              dash: [1, 2],
              display: false,
            },
            grid: {
              drawTicks: false,
            },
            ticks: {
              color: ORDERS_REPORT_CARD_CHART_X_COLOR,
              font: CHART_FONT,
              padding: 7,
            },
          },
          y: {
            border: {
              display: false,
            },
            grid: {
              display: false,
            },
            position: 'right',
            ticks: {
              color: ORDERS_REPORT_CARD_CHART_Y_COLOR,
              font: { ...CHART_FONT, size: 8 },
            },
          },
        },
      },
      plugins: [
        ordersReportCardChartMouseLinePlugin,
        ordersReportCardChartLegendPlugin,
      ],
      type: 'line',
    }

    const ariaLabel = useMemo(
      () =>
        `${intl.formatMessage({ id: 'DashboardOrdersReportCard_orders_report_header' })}:${datasets.map(
          dataset =>
            labels.map(
              (label, index) =>
                ` ${label} ${dataset.label} ${dataset.data[index]}`,
            ),
        )}`,
      [datasets, intl, labels],
    )

    return (
      <div className="d-flex flex-column-reverse flex-lg-row">
        <div
          className="DashboardOrdersReportCard-legend"
          id="ordersReportCardLegend"
        />

        <div className="DashboardOrdersReportCard-chart">
          <Line {...chartConfig} aria-label={ariaLabel} />
        </div>
      </div>
    )
  },
)
