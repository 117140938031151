import { PhoneInputProps } from 'react-phone-input-2'
import { GroupBase } from 'react-select'
import { AsyncAdditionalProps } from 'node_modules/react-select/dist/declarations/src/useAsync'
import { CreatableAdditionalProps } from 'node_modules/react-select/dist/declarations/src/useCreatable'

import { InputProps } from 'Components/_theme/Input/Input.types'
import { RadioButtonGroupProps } from 'Components/_theme/RadioButtonGroup/RadioButtonGroup.types'
import { TextareaProps } from 'Components/_theme/Textarea/Textarea.types'
import { RangeInputProps } from 'Components/RangeInput/RangeInput.types'
import { SelectOption, SelectProps } from 'Components/Select/Select.types'
import { SelectAsyncProps } from 'Components/SelectAsync/SelectAsync.types'
import { SelectCreateableProps } from 'Components/SelectCreateable/SelectCreateable.types'

export enum FieldTypeEnum {
  'text' = 'text',
  'password' = 'password',
  'email' = 'email',
  'textarea' = 'textarea',
  'textareaAutosize' = 'textareaAutosize',
  'checkbox' = 'checkbox',
  'radio' = 'radio',
  'phoneNumber' = 'phoneNumber',
  'select' = 'select',
  'selectCreatable' = 'selectCreatable',
  'selectAsync' = 'selectAsync',
  'datepicker' = 'datepicker',
  'number' = 'number',
  'range' = 'range',
  'radioButtonGroup' = 'radioButtonGroup',
}

type FieldWithoutComplexTypes = Exclude<
  FieldTypeEnum,
  | FieldTypeEnum.text
  | FieldTypeEnum.password
  | FieldTypeEnum.email
  | FieldTypeEnum.phoneNumber
  | FieldTypeEnum.select
  | FieldTypeEnum.selectCreatable
  | FieldTypeEnum.selectAsync
  | FieldTypeEnum.datepicker
  | FieldTypeEnum.textarea
  | FieldTypeEnum.textareaAutosize
  | FieldTypeEnum.number
  | FieldTypeEnum.range
  | FieldTypeEnum.radioButtonGroup
>

type FieldPropsTypeGeneric = {
  type: `${FieldWithoutComplexTypes}`
}

type FieldPropsTypeWithPhoneInput = {
  type: `${FieldTypeEnum.phoneNumber}`
  phoneInputProps: PhoneInputProps
}

export type FieldPropsTypeWithSelect<
  Option extends SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = {
  type: `${FieldTypeEnum.select}`
  selectProps: SelectProps<Option, IsMulti, Group>
}

export type FieldPropsTypeWithSelectCreateable<
  Option extends SelectOption,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = {
  type: `${FieldTypeEnum.selectCreatable}`
  selectProps: SelectCreateableProps<Option, IsMulti, Group> &
    CreatableAdditionalProps<Option, Group>
}

export type FieldPropsTypeWithSelectAsync<
  Option extends SelectOption,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = {
  type: `${FieldTypeEnum.selectAsync}`
  selectProps: SelectAsyncProps<Option, IsMulti, Group> &
    AsyncAdditionalProps<Option, Group>
}

export type FieldPropsWithDatepicker = {
  type: `${FieldTypeEnum.datepicker}`
  autoComplete: InputProps['autoComplete']
  datepickerProps?: {
    showClearButton?: boolean
    min?: string | number | undefined
  }
}

export type FieldPropsWithRange = {
  type: `${FieldTypeEnum.range}`
  rangeProps: RangeInputProps
}

export type FieldPropsWithRadioButtonGroup = {
  type: `${FieldTypeEnum.radioButtonGroup}`
  radioButtonGroupProps: Omit<RadioButtonGroupProps, 'name'>
}

export type FieldPropsTypeWithInput = {
  type:
    | `${FieldTypeEnum.text}`
    | `${FieldTypeEnum.email}`
    | `${FieldTypeEnum.password}`
    | `${FieldTypeEnum.number}`
  inputProps?: Omit<InputProps, 'autoComplete'>
  autoComplete: InputProps['autoComplete']
}

export type FieldPropsTypeWithTextarea = {
  type: `${FieldTypeEnum.textarea}` | `${FieldTypeEnum.textareaAutosize}`
  textareaProps?: TextareaProps
}

export type FieldCommon = {
  name: string
  ariaLabel?: string
  containerClassName?: string
  feedbackClassName?: string
  labelClassName?: string
  inputClassName?: string
  label?: string
  placeholder?: string
  value?: string
  shouldShowErrorMessage?: boolean
  shouldTranslateLabel?: boolean
  markAsRequired?: boolean
  disabled?: boolean
  feedbackText?: string
  feedbackTranslationValues?: Record<string, string | number>
  e2eSelector?: string
  errorMessage?: string
  hasError?: boolean
}

export type FieldProps<
  Option extends SelectOption,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = FieldCommon &
  (
    | FieldPropsTypeGeneric
    | FieldPropsTypeWithInput
    | FieldPropsTypeWithPhoneInput
    | FieldPropsTypeWithSelect<Option, IsMulti, Group>
    | FieldPropsTypeWithSelectCreateable<Option, IsMulti, Group>
    | FieldPropsTypeWithSelectAsync<Option, IsMulti, Group>
    | FieldPropsWithDatepicker
    | FieldPropsTypeWithTextarea
    | FieldPropsWithRange
    | FieldPropsWithRadioButtonGroup
  )

export type FieldInputProps = FieldCommon & FieldPropsTypeWithInput

export type FieldTextareaProps = FieldCommon &
  Pick<FieldPropsTypeWithTextarea, 'textareaProps'> & {
    type: `${FieldTypeEnum}`
  }

export type FieldTextareaAutosizeProps = FieldTextareaProps

export type FieldCheckboxProps = FieldCommon

export type FieldRadioProps = FieldCommon

export type FieldDatepickerProps = FieldCommon & {
  datepickerProps?: {
    showClearButton?: boolean
    min?: string | number | undefined
  }
  autoComplete: InputProps['autoComplete']
}

export type FieldRangeInputProps = FieldCommon &
  Pick<FieldPropsWithRange, 'rangeProps'>

export type FieldRadioButtonGroupProps = FieldCommon &
  Pick<FieldPropsWithRadioButtonGroup, 'radioButtonGroupProps'>

export type FieldPhoneInputProps = FieldCommon &
  Pick<FieldPropsTypeWithPhoneInput, 'phoneInputProps'>

export type FieldSelectProps<
  Option extends SelectOption,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = Pick<
  FieldCommon,
  'name' | 'hasError' | 'markAsRequired' | 'placeholder' | 'e2eSelector'
> & {
  selectProps: SelectProps<Option, IsMulti, Group>
}

export type FieldSelectCreatableProps<
  Option extends SelectOption,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = Omit<FieldSelectProps<Option, IsMulti, Group>, 'selectProps'> &
  Pick<FieldCommon, 'placeholder' | 'e2eSelector'> & {
    selectProps: SelectCreateableProps<Option, IsMulti, Group> &
      CreatableAdditionalProps<Option, Group>
  }

export type FieldSelectAsyncProps<
  Option extends SelectOption,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = Omit<FieldSelectProps<Option, IsMulti, Group>, 'selectProps'> &
  Pick<FieldCommon, 'placeholder' | 'e2eSelector'> & {
    selectProps: SelectAsyncProps<Option, IsMulti, Group> &
      AsyncAdditionalProps<Option, Group>
  }
