import { GroupBase } from 'react-select'

import { Field } from 'Components/Field/Field'
import { FieldTypeEnum } from 'Components/Field/Field.types'
import { SelectOption } from 'Components/Select/Select.types'

import { US_STATES } from 'Constants/usStates'

import { StateSelectProps } from './StateSelect.types'

export const StateSelect = <
  Option extends SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: StateSelectProps<Option, IsMulti, Group>,
) => {
  const { disabled, ...rest } = props

  return (
    <Field
      {...rest}
      selectProps={{
        isDisabled: disabled,
        options: US_STATES,
      }}
      type={FieldTypeEnum.select}
    />
  )
}
