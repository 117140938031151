import { useCallback, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { NetworkStatus, useQuery } from '@apollo/client'
import { observer } from 'mobx-react'

import { PhonePlansPreviewColumn } from 'Modules/phonePlans/components/PhonePlansPreviewColumn/PhonePlansPreviewColumn'
import { PhonePlansTable } from 'Modules/phonePlans/components/PhonePlansTable/PhonePlansTable'

import { Card } from 'Components/_theme'
import { ContentSidebar } from 'Components/ContentSidebar/ContentSidebar'
import { FaqCard } from 'Components/FaqCard/FaqCard'
import { ImpactTracker } from 'Components/ImpactTracker/ImpactTracker'
import { ListSearch } from 'Components/ListSearch/ListSearch'
import { ObserveMeOnceIAmMounted } from 'Components/ObserveMeOnceIAmMounted/ObserveMeOnceIAmMounted'
import { PageErrorState } from 'Components/PageErrorState/PageErrorState'
import { PageHeader } from 'Components/PageHeader/PageHeader'
import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'

import { NewPhonePlanModal } from 'Modals/NewPhonePlanModal/NewPhonePlanModal'

import { useStore } from 'Stores/index'

import { useIsOrganisation, useModal, useSidebarVisibility } from 'Hooks'

import { getQueryObject, toggleIntercomVisibility } from 'Utilities'

import { PAGINATION } from 'Constants/constants'
import { E2E_PHONE_PLAN_TABLE_NEW_PHONE_PLAN } from 'Constants/e2e'
import {
  getEmployeePhonePlans,
  getEmployeePhonePlans_employee,
  getEmployeePhonePlansVariables,
} from 'Constants/graphql/queries/listing/__generated__/getEmployeePhonePlans'
import {
  getOrganisationPhonePlans,
  getOrganisationPhonePlans_organisation,
  getOrganisationPhonePlansVariables,
} from 'Constants/graphql/queries/listing/__generated__/getOrganisationPhonePlans'
import {
  GET_EMPLOYEE_PHONE_PLANS,
  GET_ORGANISATION_PHONE_PLANS,
} from 'Constants/graphql/queries/listing/GetPhonePlans'
import { PortalSectionEnum } from 'Constants/portalSection'

import { PageTypeEnum } from 'Types/page/page.types'

export const PhonePlans = observer(() => {
  const { portalConfigStore, navigationStore, portalSettingsStore } = useStore()
  const { portalConfig } = portalConfigStore
  const { mainNavigationConfig } = navigationStore
  const { isAppReadOnly } = portalSettingsStore

  const intl = useIntl()

  const [selectedEntityId, setSelectedEntityId] = useState('')
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null)
  const [isPreviewColumnOpen, setIsPreviewColumnOpen] = useState(false)
  const isSidebarOpen = isPreviewColumnOpen
  const { isVisible: isPreviewColumnVisible } = useSidebarVisibility(
    isPreviewColumnOpen,
    isSidebarOpen,
  )
  const isOrganisation = useIsOrganisation()

  const { search: locationSearch } = useLocation()
  const parsedSearch = getQueryObject(locationSearch)

  const { setModal, hideModal } = useModal()

  const PHONE_PLAN_QUERY = isOrganisation
    ? GET_ORGANISATION_PHONE_PLANS
    : GET_EMPLOYEE_PHONE_PLANS

  const {
    loading: isLoading,
    error,
    data,
    fetchMore,
    refetch,
    networkStatus,
  } = useQuery<
    getOrganisationPhonePlans | getEmployeePhonePlans,
    getOrganisationPhonePlansVariables | getEmployeePhonePlansVariables
  >(PHONE_PLAN_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    variables: {
      after: PAGINATION.AFTER,
      first: PAGINATION.ITEMS_PER_PAGE,
      id: mainNavigationConfig.id,
      searchterm: (parsedSearch?.searchTerm as string) ?? null,
    },
  })

  // Retrieves phonePlans based on persona (route)
  const getAllPhonePlans = (
    data: getEmployeePhonePlans | getOrganisationPhonePlans,
  ):
    | getEmployeePhonePlans_employee
    | getOrganisationPhonePlans_organisation => {
    if (isOrganisation) {
      return (data as getOrganisationPhonePlans).organisation
    }

    return (data as getEmployeePhonePlans).employee
  }

  const handleOpenPreviewColumn = useCallback(
    (id: string, rowIndex: number | null) => {
      toggleIntercomVisibility(false)

      setSelectedEntityId(id)
      setSelectedRowIndex(rowIndex)
      setIsPreviewColumnOpen(true)
    },
    [],
  )

  const handleClosePreviewColumn = useCallback(() => {
    toggleIntercomVisibility(true)

    setIsPreviewColumnOpen(false)
    setSelectedRowIndex(null)
  }, [])

  const openNewPhonePlanModal = () => {
    setModal({
      component: NewPhonePlanModal,
      isOpen: true,
      props: {
        handleSubmit: () => {
          refetch()
          hideModal()
        },
        hide: hideModal,
        page: PageTypeEnum.Overview,
      },
    })
  }

  const handleSearch = useCallback(
    (value: string) => {
      refetch({
        searchterm: value || null,
      })
    },
    [refetch],
  )

  const onLoadMore = () => {
    if (data) {
      fetchMore({
        variables: {
          after: getAllPhonePlans(data)?.phonePlans?.pageInfo.endCursor,
        },
      })
    }
  }

  const intersectionObserver = new IntersectionObserver(([entry]) => {
    if (entry.isIntersecting) {
      onLoadMore()
    }
  })

  useEffect(() => {
    refetch({ searchterm: (parsedSearch?.searchTerm as string) ?? null })
  }, [refetch, parsedSearch?.searchTerm])

  if (error) {
    return <PageErrorState />
  }

  if (isLoading && !data) {
    return <PageLoadingState />
  }

  const plansData = data && getAllPhonePlans(data)
  const plans = plansData?.phonePlans?.phonePlans
  const plansCount = plansData?.phonePlans?.totalCount
  const hasNextPage = plansData?.phonePlans?.pageInfo.hasNextPage
  const showNewPhonePlanButton =
    isOrganisation && portalConfig?.newPhonePlan && !isAppReadOnly

  return (
    <Container className="pt-6" fluid>
      <div role="banner">
        <PageHeader portalSection={PortalSectionEnum.PhonePlans} />
      </div>

      <Row>
        <Col xl={12} xxl={8}>
          <Card className="mb-4">
            <ListSearch
              buttonE2ESelector={E2E_PHONE_PLAN_TABLE_NEW_PHONE_PLAN}
              buttonLabel={intl.formatMessage({ id: 'New_phone_plan' })}
              buttonOnClick={openNewPhonePlanModal}
              buttonVisible={showNewPhonePlanButton}
              handleSearch={handleSearch}
              totalCount={plansCount ?? 0}
            />

            <Row>
              <Col>
                <PhonePlansTable
                  isLoading={isLoading}
                  isSearching={networkStatus === NetworkStatus.setVariables}
                  onOpenPreviewColumn={handleOpenPreviewColumn}
                  phonePlans={plans ?? []}
                  selectedRowIndex={selectedRowIndex}
                />
              </Col>
            </Row>
          </Card>
          <Row className="p-1">
            <Col>
              {hasNextPage && !isLoading && (
                <ObserveMeOnceIAmMounted
                  intersectionObserver={intersectionObserver}
                />
              )}
            </Col>
          </Row>
        </Col>

        <ContentSidebar
          isSidebarOpen={isSidebarOpen}
          renderStickyColumnSideContent={
            <>
              <ImpactTracker />
              <FaqCard />
            </>
          }
        >
          <PhonePlansPreviewColumn
            entityId={selectedEntityId}
            isVisible={isPreviewColumnVisible}
            onClosePreviewColumn={handleClosePreviewColumn}
          />
        </ContentSidebar>
      </Row>
    </Container>
  )
})
