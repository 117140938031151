import type * as React from 'react'
import { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { E2E_ERROR } from 'Constants/e2e'

type Props = RouteComponentProps & {
  children?: React.ReactNode
}

type State = { hasError: boolean }

class ErrorBoundary extends PureComponent<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props)

    this.state = {
      hasError: false,
    }

    const { history } = props

    history.listen(() => {
      const { hasError } = this.state

      if (hasError) {
        this.setState({
          hasError: false,
        })
      }
    })
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    return {
      hasError: true,
    }
  }

  componentDidUpdate(prevProps: RouteComponentProps) {
    const { location } = this.props

    if (location !== prevProps.location) {
      window.Intercom('update', {
        hide_default_launcher: false,
      })
    }
  }

  componentDidCatch(
    error: Error,
  ): ReturnType<NonNullable<PureComponent['componentDidCatch']>> {
    Sentry.captureException(error)
  }

  render(): ReturnType<PureComponent['render']> {
    const {
      props: { children },
      state: { hasError },
    } = this

    if (hasError) {
      return (
        <div
          className="d-flex align-items-center justify-content-center h-100"
          data-e2e={E2E_ERROR}
        >
          <span className="text-center text-muted">
            <span>
              <FormattedMessage id="Error_occurred" />
            </span>
            <br />
            <span>
              <FormattedMessage id="Error_refresh_page" />
            </span>
          </span>
        </div>
      )
    }

    return children
  }
}

export default withRouter(ErrorBoundary)
