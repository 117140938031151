import { Component, PureComponent } from 'react'
import * as Sentry from '@sentry/react'

import { ENVIROMENTAL_VARIABLES } from 'Constants/enviromentalVariables'

import { AuthError } from '../AuthError'
import { JsonApiError } from './fetch.types'

// Wrapper for errors with status codes
class ErrorWithStatus extends Error {
  // __proto__ => Accessor to the internals of the class
  __proto__: ErrorWithStatus

  public apiErrors: JsonApiError[]

  public stringifiedApiErrors: string

  constructor(message: string, apiErrors: JsonApiError[]) {
    super(message)
    // Maps the es6 class definitions to the older es5
    // Helps to properly compare the instaceOf an object
    // https://medium.com/@xpl/javascript-deriving-from-error-properly-8d2f8f315801
    this.constructor = ErrorWithStatus
    // eslint-disable-next-line no-proto
    this.__proto__ = ErrorWithStatus.prototype
    this.name = 'ErrorWithStatus'
    this.apiErrors = apiErrors
    this.stringifiedApiErrors = JSON.stringify(apiErrors)
  }
}

const responseHasJson = (response: Response): boolean => {
  const contentType = response.headers.get('content-type')

  return contentType?.indexOf('application/json') !== -1
}

// TODO Turning this into an arrow function breaks test-portal-unit
//      https://everphone.slack.com/archives/G01LS16AWBY/p1615477102003700
async function getJsonApiErrors(response: Response): Promise<JsonApiError[]> {
  if (!responseHasJson(response)) {
    return []
  }

  try {
    const json = await response.json()

    return json.errors
  } catch (e) {
    // In case of legacy browsers and other obscure problems, we will survive without a subtype
    console.error('Failed to parse error subtype from response body:', e)

    return []
  }
}

export const getFetchError = (fullPath: string, response: Response): string =>
  `${fullPath} ${response.status} (${response.statusText})`

export const handleFetchError = function handleFetchError(
  this:
    | Component<unknown, { hasError: boolean }>
    | PureComponent<unknown, { hasError: boolean }>,
) {
  return (error: Error) => {
    console.error(error)

    // Report only Errors with status codes to Sentry
    // Prevents fetch errors from being tracked in Sentry
    if (error instanceof ErrorWithStatus) {
      Sentry.captureException(error)
    }

    this.setState({
      hasError: true,
    })
  }
}

export const fetchFromApiUsingFullPath = (
  fullPath: string,
  options?: RequestInit,
): Promise<any> =>
  fetch(fullPath, options).then(async response => {
    if (response.status && !response.ok) {
      // TODO Error Status 404 needs to be handled appropriately adn 404 should correspond to actual page not found
      const message = getFetchError(fullPath, response)
      const jsonApiErrors = await getJsonApiErrors(response)

      throw [401, 403, 404].includes(response.status)
        ? new AuthError(message, jsonApiErrors)
        : new ErrorWithStatus(message, jsonApiErrors)
    }

    return response.json()
  })

export const fetchFromPublicApiUsingPartialPath = <T>(
  partialPath: string,
  options?: RequestInit,
): Promise<T> => {
  const fullPath = window.GO_API_URL + partialPath

  return fetchFromApiUsingFullPath(fullPath, options)
}

export const fetchFromApi = (
  partialPath: string,
  options?: RequestInit,
): Promise<any> => {
  const fullPath = `/api/${ENVIROMENTAL_VARIABLES.API_VERSION}${partialPath}`

  return fetchFromApiUsingFullPath(fullPath, options)
}

export const fetchFromLegacyApi = (
  partialPath: string,
  options?: RequestInit,
): Promise<any> => {
  const fullPath = `/api/${ENVIROMENTAL_VARIABLES.LEGACY_API_VERSION}${partialPath}`

  return fetchFromApiUsingFullPath(fullPath, options)
}

export const fetchFromPublicboxApi = (
  partialPath: string,
  options?: RequestInit,
): Promise<any> => {
  const fullPath = `/api/${ENVIROMENTAL_VARIABLES.PUBLICBOX_API_VERSION}${partialPath}`

  return fetchFromApiUsingFullPath(fullPath, options)
}
