import { store } from 'Stores/index'

import { PortalSectionEnum } from 'Constants/portalSection'

import { Rental, Rentals } from '../pages'
import { RentalsRoutePathsEnum } from './rentalsRouteConfig.types'

export const RENTAL_ROUTES = [
  {
    component: Rental,
    onRender: () => {
      const { portalSettingsStore } = store
      const { setCurrentPortalSection } = portalSettingsStore

      setCurrentPortalSection(PortalSectionEnum.Rentals)
    },
    path: `${RentalsRoutePathsEnum.RENTAL}`,
  },
  {
    component: Rentals,
    onRender: () => {
      const { portalSettingsStore } = store
      const { setCurrentPortalSection } = portalSettingsStore

      setCurrentPortalSection(PortalSectionEnum.Rentals)
    },
    path: `${RentalsRoutePathsEnum.RENTALS}`,
  },
]
