import { ForwardedRef, forwardRef } from 'react'
import { clsx } from 'clsx'

import { Icon } from 'Components/Icon/Icon'

import { Heading } from '../Heading/Heading'
import { LinkButton } from '../LinkButton/LinkButton'
import { AlertProps } from './Alert.types'

export const Alert = forwardRef(
  (props: AlertProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      children,
      className,
      iconLeft,
      iconRight,
      buttonRight,
      variant = 'info',
      title,
      ...restProps
    } = props

    const classNames = clsx(
      'Alert',
      `Alert-${variant}`,
      title && 'Alert-with_title',
      className,
    )

    const getAriaLive = (): React.AriaAttributes['aria-live'] => {
      if (variant === 'warning' || variant === 'danger') {
        return 'assertive'
      }

      return 'polite'
    }

    const getRole = (): React.AriaRole => {
      if (variant === 'warning' || variant === 'danger') {
        return 'alert'
      }

      return 'status'
    }

    return (
      <div
        aria-live={getAriaLive()}
        className={classNames}
        role={getRole()}
        {...restProps}
        ref={ref}
      >
        {iconLeft && (
          <Icon
            className="Alert-icon Alert-icon-left"
            icon={iconLeft}
            size={16}
          />
        )}

        <div className="Alert-content">
          {title && (
            <div className="Alert-title">
              <Heading as={4} size={2}>
                {title}
              </Heading>

              {buttonRight && (
                <div className="Alert-button-content">
                  <LinkButton className="Alert-button" {...buttonRight} />
                </div>
              )}

              {!buttonRight && iconRight && (
                <Icon className="Alert-icon" icon={iconRight} size={16} />
              )}
            </div>
          )}

          {children}
        </div>

        {buttonRight && !title && (
          <div className="Alert-button-content">
            <LinkButton className="Alert-button" {...buttonRight} />
          </div>
        )}

        {iconRight && !buttonRight && !title && (
          <Icon className="Alert-icon" icon={iconRight} size={16} />
        )}
      </div>
    )
  },
)
