/* eslint-disable sort-keys-fix/sort-keys-fix */
import { AUTH_ROUTES } from 'Modules/auth/routes/authRouteConfig'
import {
  EmployeeDashboard,
  OrganisationDashboard,
} from 'Modules/dashboard/pages'
import { DEPOT_ROUTES } from 'Modules/depots/routes/depotsRouteConfig'
import { EMPLOYEES_ROUTES } from 'Modules/employees/routes/employeesRouteConfig'
import { INVOICE_ROUTES } from 'Modules/invoices/routes/invoicesRouteConfig'
import {
  ORDER_EMPLOYEE_ROUTES,
  ORDER_ROUTES,
} from 'Modules/order/routes/orderRouteConfig'
import { orderCustomRouterActions } from 'Modules/order/routes/orderRouteConfig.actions'
import { ORDERS_ROUTES } from 'Modules/orders/routes/ordersRouteConfig'
import { PHONE_PLANS_ROUTES } from 'Modules/phonePlans/routes/phonePlansRouteConfig'
import { RENTAL_ROUTES } from 'Modules/rentals/routes/rentalsRouteConfig'
import { RETURNS_ROUTES } from 'Modules/returns/routes/returnsRouteConfig'
import { SEARCH_RESULTS_ROUTES } from 'Modules/searchResults/routes/searchResultsRouteConfig'

import { customRouterActions } from './Router.actions'
import { GLOBAL_ROUTE_PATHS } from './Router.constants'
import { RouterConfig } from './Router.types'

export const ROUTER_CONFIG: RouterConfig = {
  publicRoutes: [...AUTH_ROUTES],
  protectedRoutes: {
    organisation: {
      component: OrganisationDashboard,
      path: GLOBAL_ROUTE_PATHS.organisation.dashboard,
      children: [
        ...DEPOT_ROUTES,
        ...EMPLOYEES_ROUTES,
        ...INVOICE_ROUTES,
        ...ORDER_ROUTES,
        ...ORDER_EMPLOYEE_ROUTES,
        ...ORDERS_ROUTES,
        ...PHONE_PLANS_ROUTES,
        ...RENTAL_ROUTES,
        ...SEARCH_RESULTS_ROUTES,
        ...RETURNS_ROUTES,
      ],
    },
    employee: {
      component: EmployeeDashboard,
      path: GLOBAL_ROUTE_PATHS.employee.dashboard,
      children: [
        ...ORDER_ROUTES,
        ...ORDERS_ROUTES,
        ...RENTAL_ROUTES,
        RETURNS_ROUTES[0],
      ],
    },
  },
  customRouterActions: {
    onRouteChange: [
      ...customRouterActions.onRouteChange,
      ...orderCustomRouterActions.onRouteChange,
    ],
  },
}
