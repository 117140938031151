import { useIntl } from 'react-intl'
import { faClose, faSearch } from '@fortawesome/pro-solid-svg-icons'
import { observer } from 'mobx-react'

import { Icon } from 'Components/Icon/Icon'

import { GlobalSearchMobileTogglerProps } from './GlobalSearch.types'

export const GlobalSearchMobileToggler = observer(
  (props: GlobalSearchMobileTogglerProps) => {
    const { onToggle, isOpen, children } = props
    const intl = useIntl()
    const openSearchText = intl.formatMessage({ id: 'GlobalSearch_OpenIcon' })
    const closeSearchText = intl.formatMessage({ id: 'GlobalSearch_CloseIcon' })

    return (
      <div className="search-mobile-toggler d-flex">
        <button
          aria-label={openSearchText}
          className="search-mobile-toggler-button d-inline-flex border-0 p-2 bg-transparent"
          onClick={onToggle}
          type="button"
        >
          <Icon icon={faSearch} size={28} />
        </button>

        {isOpen && (
          <div className="gs-toggler-content w-100 bg-white position-absolute top-0 start-0 p-2 d-flex align-items-start">
            <button
              aria-label={closeSearchText}
              className="d-inline-flex border-0 p-0 bg-transparent me-2"
              onClick={onToggle}
              type="button"
            >
              <Icon icon={faClose} size={24} />
            </button>
            {children}
          </div>
        )}
      </div>
    )
  },
)
