import { DEVICE_TYPE } from 'Constants/constants'
import { getEmployeeContact_employee_contact } from 'Constants/graphql/queries/employee/__generated__/getEmployeeContact'
import { getVendingMachineData_checkVendingMachineAvailability_vendingMachines } from 'Constants/graphql/queries/feature/__generated__/getVendingMachineData'

export type NetPriceCalculatorInput = {
  calculationVisible: boolean
  childAllowanceValue: number
  churchTaxValue: number
  collapse: boolean
  healthValue: string
  salaryValue: string
  stateValue: string
  taxClassValue: number
}

export type OrderAddressFields = {
  city: string | null
  country: string | null
  id: string
  locationName?: string | null
  name: string | null
  nameAddition: string | null
  street: string | null
  streetAddition: string | null
  zipcode: string | null
}

export type OrderLimitation = {
  initial: number
  current: number
}

export type OrderLimitations = {
  [DEVICE_TYPE.Laptop]: OrderLimitation
  [DEVICE_TYPE.Tablet]: OrderLimitation
  [DEVICE_TYPE.Smartphone]: OrderLimitation
  [DEVICE_TYPE.Other]: OrderLimitation
}

export enum OrderFilterTypeEnum {
  Colors,
  ProductTypes,
  Manufacturers,
  Storages,
}

export type OrderFilters = Map<OrderFilterTypeEnum, Set<string>>

export enum OrderStepEnum {
  device = 'device',
  accessories = 'accessories',
  address = 'address',
  summary = 'summary',
  result = 'result',
  finished = 'finished',
}

export enum OrderSortCriteriaEnum {
  Default = 'DeviceChooserFilters_recommended',
  CopaymentNet = 'DeviceChooserFilters_price',
  Manufacturer = 'DeviceChooserFilters_manufacturer',
  Storage = 'DeviceChooserFilters_memory',
}

export type OrderTotalPrice = {
  totalWithoutTax: number
  totalWithTax: number
}

export enum OriginRouteTypeEnum {
  Employee = 'employee',
  Rental = 'rental',
  Rentals = 'rentals',
}

export type OriginRoute = {
  type: OriginRouteTypeEnum
  url: string
} | null

export type VendingMachinesData = {
  isLoading: boolean
  data:
    | getVendingMachineData_checkVendingMachineAvailability_vendingMachines[]
    | null
}

export type EmployeeContactData = {
  isLoading: boolean
  data: getEmployeeContact_employee_contact | null
}
