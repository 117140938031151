import { Form as BSForm } from 'react-bootstrap'

import { FormProps } from './Form.types'

export const Form = (props: FormProps) => {
  const { children } = props

  return (
    <BSForm noValidate role="form" {...props}>
      {children}
    </BSForm>
  )
}
