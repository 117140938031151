export const localStorageMock: Storage = ((): {
  clear: () => void
  getItem: (key: string) => string
  removeItem: (key: string) => void
  setItem: (key: string, value: string) => void
  length: number
  key: (number: number) => string
} => {
  let store: { [key: string]: string } = {}

  return {
    clear: () => {
      store = {}
    },
    getItem: (key: string): string => store[key],
    key: (number: number): string => Object.keys(store)[number],
    length: Object.keys(store).length,
    removeItem: (key: string) => {
      delete store[key]
    },
    setItem: (key: string, value: string) => {
      store[key] = value.toString()
    },
  }
})()
