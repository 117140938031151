export const BASE_URL = 'http://localhost:8000'
export const BASE_PATH = '/portal/app/#'
export const BASE_PATH_NEW_JOINER = '/portal/app/new-joiner/#'

export const EMPLOYEE_ADMIN_ID = 8
export const EVERPHONE_ORG_ID = 61
export const EVERPHONE_EMPLOYEE_ID = 1

export const ERNST_YOUNG_ORG_ID = 163

export const ADMIN_EMAIL = 'admin.user@everphone.de'
export const EMPLOYEE_EMAIL = 'everphone.employee1@everphone.de'
export const USER_PASSWORD = 'everphone2019!'

export const NEW_JOINER_USERNAME = 'nodeviceuser'
export const NEW_JOINER_PASSWORD = 'dummy-external-token'

export const DATE_FORMAT = 'dd/MM/yyyy'
