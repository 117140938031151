import { action, makeObservable, observable } from 'mobx'

import {
  getEmployee,
  getEmployee_employee,
  getEmployeeVariables,
} from 'Constants/graphql/queries/employee/__generated__/getEmployee'
import { GET_EMPLOYEE } from 'Constants/graphql/queries/employee/getEmployee'

import ApolloLoader from 'Portal/src/ApolloLoader'
import { setTheme } from 'Portal/src/utilities/theme/theme.util'

import type { RootStore } from '..'
import { createIntercom } from './employeeStore.util'

export class EmployeeStore {
  employee: getEmployee_employee | null = null

  employeeError: unknown | null = null

  isLoadingEmployee = true

  constructor(private readonly store: RootStore) {
    makeObservable(this, {
      employee: observable,
      employeeError: observable,
      fetchEmployee: action,
      isLoadingEmployee: observable,
      setEmployee: action,
      setEmployeeError: action,
      setIsLoadingEmployee: action,
    })
  }

  fetchEmployee = async (id: string) => {
    const { apolloClient } = ApolloLoader

    if (!apolloClient) return

    try {
      this.setIsLoadingEmployee(true)

      const response = await apolloClient.query<
        getEmployee,
        getEmployeeVariables
      >({
        query: GET_EMPLOYEE,
        variables: {
          id,
        },
      })

      const {
        data: { employee },
      } = response

      this.store.organisationStore.setOrganisation(null)
      this.setEmployee(employee)

      if (employee.organisation) {
        await this.store.portalConfigStore.fetchPortalConfig(
          employee.organisation.id,
          employee.id,
        )
      }

      if (employee.office?.address) {
        this.store.orderStore.setOfficeAddress(employee.office?.address)
      } else if (employee.organisation?.contact?.address) {
        this.store.orderStore.setOfficeAddress(
          employee.organisation?.contact?.address,
        )
      }

      const { portalConfig } = this.store.portalConfigStore

      if (portalConfig) {
        setTheme(portalConfig)
      }

      this.store.portalContentStore.setPortalContent(
        employee.organisation?.portalContent || null,
      )
      this.store.orderStore.setIsCYOD(employee.isCYOD)

      if (this.store.portalSettingsStore.isAppReadOnly) {
        this.store.portalSettingsStore.setIsAppReadOnly(false)
      }

      localStorage.setItem('lastSelected', 'employee')
      localStorage.setItem('lastSelectedId', employee.id)

      this.setEmployeeError(null)

      createIntercom(employee)
    } catch (error) {
      this.setEmployee(null)
      this.store.organisationStore.setOrganisation(null)
      this.setEmployeeError(error)
    } finally {
      this.setIsLoadingEmployee(false)
    }
  }

  setIsLoadingEmployee = (isLoading: boolean) => {
    this.isLoadingEmployee = isLoading
  }

  setEmployee = (employee: getEmployee_employee | null) => {
    this.employee = employee
  }

  setEmployeeError = (error: unknown | null) => {
    this.employeeError = error
  }
}
