import { useEffect, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { DocumentNode, useQuery } from '@apollo/client'

import { PAGINATION } from 'Constants/constants'

import { getQueryObject } from 'Portal/src/utilities'

import { useIsOrganisation } from '../useIsOrganisation/useIsOrganisation'
import {
  DefaultListTableDataType,
  DefaultListTableDataTypeWithEmployee,
  DefaultListTableDataTypeWithOrganisation,
  DefaultListTableMainDataType,
  DefaultListTableProps,
  DefaultListTableQueryVariables,
} from './useDefaultListTable.types'

export const useDefaultListTable = <
  OrganisationDataType extends DefaultListTableDataType,
  OrganisationDataQueryVariables extends DefaultListTableQueryVariables,
  EmployeeDataType extends DefaultListTableDataType = { employee: null },
  EmployeeDataQueryVariables extends DefaultListTableQueryVariables = {
    after: 0
    first: 0
    id: ''
  },
>(
  props: DefaultListTableProps,
) => {
  const {
    organisationQuery,
    employeeQuery,
    dataObjectPropertyName,
    extraVariables,
    isDefaultListTableInactive,
  } = props

  const { employeeId, organisationId } = useParams<{
    employeeId?: string
    organisationId?: string
  }>()
  const { search: locationSearch } = useLocation()
  const parsedSearch = getQueryObject(locationSearch)
  const isOrganisation = useIsOrganisation()

  const { query, queryId } = useMemo((): {
    query: DocumentNode
    queryId: string
  } => {
    if (isOrganisation || !employeeQuery) {
      return {
        query: organisationQuery,
        queryId: organisationId!,
      }
    }

    return {
      query: employeeQuery,
      queryId: employeeId!,
    }
  }, [
    employeeId,
    employeeQuery,
    isOrganisation,
    organisationId,
    organisationQuery,
  ])

  const {
    data: responseData,
    error,
    fetchMore,
    loading: isLoading,
    networkStatus,
    refetch,
  } = useQuery<
    OrganisationDataType | EmployeeDataType,
    OrganisationDataQueryVariables | EmployeeDataQueryVariables
  >(query, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    variables: {
      after: PAGINATION.AFTER,
      first: PAGINATION.ITEMS_PER_PAGE,
      id: queryId,
      searchterm: (parsedSearch?.searchTerm as string) ?? null,
      ...extraVariables,
    },
  })

  useEffect(() => {
    if (!isDefaultListTableInactive) {
      refetch({
        searchterm: (parsedSearch?.searchTerm as string) ?? null,
      } as Partial<OrganisationDataQueryVariables | EmployeeDataQueryVariables>)
    }
  }, [refetch, parsedSearch?.searchTerm, isDefaultListTableInactive])

  const getData = (
    data: OrganisationDataType | EmployeeDataType,
  ): DefaultListTableMainDataType | null =>
    isOrganisation
      ? (data as DefaultListTableDataTypeWithOrganisation).organisation[
          dataObjectPropertyName
        ]
      : (data as DefaultListTableDataTypeWithEmployee).employee[
          dataObjectPropertyName
        ]

  const onLoadMore = () => {
    if (responseData)
      fetchMore({
        variables: {
          after: getData(responseData)?.pageInfo.endCursor || PAGINATION.AFTER,
        },
      })
  }

  const intersectionObserver = new IntersectionObserver(([entry]) => {
    if (entry.isIntersecting) {
      onLoadMore()
    }
  })

  const data = responseData
    ? getData(responseData)?.[dataObjectPropertyName] || []
    : []
  const totalCount = responseData ? getData(responseData)?.totalCount ?? 0 : 0
  const hasNextPage = responseData
    ? getData(responseData)?.pageInfo.hasNextPage
    : false

  return {
    data,
    error,
    hasNextPage,
    intersectionObserver,
    isLoading,
    networkStatus,
    refetch,
    totalCount,
  }
}
