import { useMemo } from 'react'
import { useCurrentRefinements } from 'react-instantsearch'

export function useCurrentRefinement(attribute: string) {
  const { items } = useCurrentRefinements()

  const currentRefinement = useMemo(
    () => items.find(item => item.attribute === attribute)?.refinements ?? [],
    [items, attribute],
  )

  return currentRefinement
}
