import { isEmpty, omitBy } from 'lodash-es'
import queryString from 'query-string'

import { QueryMergeObject } from './queryString.types'

/**
 * This util method allows you to extract query string from the url.
 */
export const getQueryObject = (
  routerSearch: string,
): queryString.ParsedQuery<string> | null => {
  const queryStringObject = queryString.parse(routerSearch)

  if (isEmpty(queryStringObject)) {
    return null
  }

  return queryStringObject
}

/**
 * This util method allows you to convert object to a query string.
 */
export const convertToQueryString = (
  newQueryObj: queryString.ParsedQuery<string>,
): string => queryString.stringify(newQueryObj)

/**
 * This util method allows you to merge new query object with existing query string.
 * If the key in the merge query object is empty, it will be removed and won't be taken into account.
 */
export const mergeToQueryString = (
  routerSearch: string,
  mergeQuery: QueryMergeObject,
): string => {
  const queryStringObject = queryString.parse(routerSearch)
  const newQueryStringObject = {
    ...queryStringObject,
    ...mergeQuery,
  }

  if (isEmpty(newQueryStringObject)) {
    return ''
  }

  return queryString.stringify(
    omitBy(newQueryStringObject, value => value === ''),
  )
}
