/* eslint-disable @typescript-eslint/naming-convention */
export const LOCAL_STORAGE = {
  COOKIE_APPROVAL: 'cookie-approval',
}

export const ADDRESSES = {
  CUSTOM_LABEL: 'Custom_address',
  OFFICE_LABEL: 'Office',
}

export const PAGINATION = {
  AFTER: 0,
  ITEMS_PER_PAGE: 10,
}

export const REGEX = {
  CITY: "^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð()/ ,.'-]+$",
  COUNTRY:
    "^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$",
  HOUSENUMBER: '^[1-9]\\d*(?: ?(?:[a-zA-Z]|[/-] ?\\d+[a-zA-Z]?))?$',
  NAME: "^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$",
  STREET:
    "^[#.0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð()/ ,.'-]+$",
  ZIPCODE:
    '^([0-9A-Za-z]{5}|[0-9A-Za-z]{9}|(([0-9a-zA-Z]{5}-){1}[0-9a-zA-Z]{4}))$',
}

export const enum DEVICE_TYPE {
  Laptop = 'laptop',
  Smartphone = 'smartphone',
  Tablet = 'tablet',
  Other = 'other',
}

export const SLIDER_BREAKPOINTS_1 = {
  0: {
    slidesPerGroup: 1,
    slidesPerView: 1,
  },
  1080: {
    slidesPerGroup: 1,
    slidesPerView: 1,
  },
  1190: {
    slidesPerGroup: 1,
    slidesPerView: 1,
  },
  1541: {
    slidesPerGroup: 1,
    slidesPerView: 1,
  },
}

export const SLIDER_BREAKPOINTS_3 = {
  0: {
    slidesPerGroup: 1,
    slidesPerView: 1,
  },
  1080: {
    slidesPerGroup: 1,
    slidesPerView: 1,
  },
  1190: {
    slidesPerGroup: 2,
    slidesPerView: 2,
  },
  1541: {
    slidesPerGroup: 3,
    slidesPerView: 3,
  },
}

export const SLIDER_BREAKPOINTS_4 = {
  0: {},
  1080: {
    slidesPerView: 2,
  },
  1190: {
    slidesPerView: 3,
  },
  1541: {
    slidesPerView: 4,
  },
}

export const SLIDER_BREAKPOINTS_5 = {
  0: {
    slidesPerGroup: 1,
    slidesPerView: 1,
  },
  1080: {
    slidesPerGroup: 3,
    slidesPerView: 3,
  },
  1190: {
    slidesPerGroup: 4,
    slidesPerView: 4,
  },
  1541: {
    slidesPerGroup: 5,
    slidesPerView: 5,
  },
  576: {
    slidesPerGroup: 2,
    slidesPerView: 2,
  },
}

export const SLIDER_BREAKPOINTS_ADMIN_DASHBOARD = {
  0: {
    slidesPerGroup: 1,
    slidesPerView: 1,
  },
  1200: {
    slidesPerGroup: 4,
    slidesPerView: 4,
  },
  400: {
    slidesPerGroup: 2,
    slidesPerView: 2,
  },
  768: {
    slidesPerGroup: 3,
    slidesPerView: 3,
  },
}

export const STREET_NUMBER_SPLIT_STRING = ' | '

export const PORTAL_BREAKPOINTS = {
  LG: '992px',
  MD: '768px',
  SM: '576px',
  XL: '1200px',
  XS: '0',
  XXL: '1440px',
  XXXL: '1920px',
}

export const countryISOCodes = {
  US: 'US',
}

export const TEXTAREA_BLOCKED_CHARACTERS = /[*^_~{}|<>[\]\\]/gi
export const TEXTINPUT_BLOCKED_CHARACTERS = /[*^_~{}|<>[\]\\!?@#$%]/gi
export const TEXTINPUT_BLOCKED_CHARACTERS_ALLOWED_UNDERSCORE =
  /[*^~{}|<>[\]\\!?@#$%]/gi
export const TEXTSEARCH_BLOCKED_CHARACTERS = /[*^_~{}|<>[\]\\!?#$%,/`]/gi
