import addtocart from 'Assets/images/everphoneplus/addtocart.png'
import bugfixing from 'Assets/images/everphoneplus/bugfixing.png'
import savings from 'Assets/images/everphoneplus/savings.png'
import sharedgoals from 'Assets/images/everphoneplus/sharedgoals.png'

import { DashboardBenefitSlideProps } from '../DashboardBenefitSlide/BenefitSlide.types'

export const EMPLOYEE_DASHBOARD_PLUS_BENEFITS_SLIDES: DashboardBenefitSlideProps[] =
  [
    {
      content: 'EverphonePlusBenefits_enormous_savings_content',
      header: 'EverphonePlusBenefits_enormous_savings_header',
      id: 0,
      imageSource: savings,
    },
    {
      content: 'EverphonePlusBenefits_family_friends_content',
      header: 'EverphonePlusBenefits_family_friends_header',
      id: 1,
      imageSource: sharedgoals,
    },
    {
      content: 'EverphonePlusBenefits_damage_swap_content',
      header: 'EverphonePlusBenefits_damage_swap_header',
      id: 2,
      imageSource: bugfixing,
    },
    {
      content: 'EverphonePlusBenefits_buying_offer_content',
      header: 'EverphonePlusBenefits_buying_offer_header',
      id: 3,
      imageSource: addtocart,
    },
  ]
