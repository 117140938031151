import { Col } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'

import { ActionButton } from 'Components/_theme'

import { CardActionProps } from './CardActions.types'

export const CardAction = (props: CardActionProps) => {
  const { e2eSelector, icon, name, onActionClick, isHidden, variant } = props

  const intl = useIntl()

  if (isHidden) {
    return null
  }

  return (
    <Col className="d-flex">
      <ActionButton
        aria-label={intl.formatMessage({ id: name })}
        data-e2e={e2eSelector}
        icon={icon}
        onClick={onActionClick}
        variant={variant}
      >
        <FormattedMessage id={name} />
      </ActionButton>
    </Col>
  )
}
