import { useCallback, useMemo, useState } from 'react'
import { useCurrentRefinements, useHits } from 'react-instantsearch'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { Hit as AlgoliaHit } from '@algolia/client-search'
import { useMediaQuery } from '@react-hookz/web'
import { CellContext, ColumnDef, SortingState } from '@tanstack/react-table'
import { clsx } from 'clsx'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { OrdersPreviewColumnTypeEnum } from 'Modules/orders/types/OrdersPreviewColumnType.type'

import { Table } from 'Components/_theme'
import { FormatDate } from 'Components/FormatDate/FormatDate'
import { ImageWithFallback } from 'Components/ImageWithFallback/ImageWithFallback'
import { OrderStatusBadge } from 'Components/OrderStatusBadge/OrderStatusBadge'

import { useIsOrganisation } from 'Hooks'

import { buttonize, getFullContactName, getOrderDisplayName } from 'Utilities'

import { PORTAL_BREAKPOINTS } from 'Constants/constants'
import { E2E_TABLE_DEVICE_NAME, E2E_TABLE_LINK_ORDERS } from 'Constants/e2e'

import { SearchResultsDocument } from 'Types/globalSearch/searchResultsDocument.types'
import { ImagePlaceholderTypesEnum } from 'Types/imagePlaceholders.type'

import { CaseItemStatus, CaseItemType } from 'Portal/__generated__/globalTypes'
import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import { OrdersTableProps, OrderWithUrl } from './OrdersTable.types'

export const OrdersTable = observer((props: OrdersTableProps) => {
  const { isLoading, selectedRowIndex, onOpenPreviewColumn } = props
  const [sorting, setSorting] = useState<SortingState>([])
  const { hits } = useHits<AlgoliaHit<SearchResultsDocument>>()
  const intl = useIntl()

  const isOrganisation = useIsOrganisation()
  const { employeeId, organisationId } = useParams<{
    employeeId: string
    organisationId: string
  }>()

  // for now its only available for organisation and orders, not for empoyees and returns
  const isActiveEmployee = false

  const { items } = useCurrentRefinements()

  const routerHistory = useHistory()
  const isMobile = useMediaQuery(`(max-width: ${PORTAL_BREAKPOINTS.LG})`)

  const handleEnterDetails = useCallback(
    (
      _event:
        | React.MouseEvent<HTMLDivElement>
        | React.KeyboardEvent<HTMLDivElement>,
      id: string,
      url: string,
      rowIndex: number,
      previewColumnType: OrdersPreviewColumnTypeEnum,
    ) => {
      if (isMobile) {
        routerHistory.push(url)

        return
      }

      if (typeof onOpenPreviewColumn === 'function') {
        onOpenPreviewColumn(id, rowIndex, previewColumnType)
      }
    },
    [isMobile, onOpenPreviewColumn, routerHistory],
  )

  const renderOrdersHeader = useCallback(
    () => <FormattedMessage key="name" id="OrderListContent_header__title" />,
    [],
  )

  const renderEmployeeHeader = useCallback(
    () => (
      <FormattedMessage key="name" id="RequestListContent_header__employee" />
    ),
    [],
  )

  const renderDateHeader = useCallback(
    () => <FormattedMessage key="name" id="RequestListContent_header__date" />,
    [],
  )

  const renderStatusHeader = useCallback(
    () => (
      <FormattedMessage key="name" id="RequestListContent_header__status" />
    ),
    [],
  )

  const renderOrderCell = useCallback(
    ({ cell }: CellContext<OrderWithUrl, unknown>) => {
      const item = cell.row.original

      const getOrderItemName = () =>
        item['case_item.device_definition_name'] ?? ''

      const getItemId = () => {
        if (item.id) {
          return `${item.id}`
        }

        return ''
      }

      const getReturnAndOrderItemName = () => {
        const displayName = getOrderDisplayName(
          (item['case_item.type'] as CaseItemType) ?? '',
          getOrderItemName(),
        )

        return typeof displayName === 'string' ? (
          <FormattedMessage id={displayName} />
        ) : (
          <>
            <FormattedMessage id={displayName.type} />
            :&nbsp;{displayName.name}
          </>
        )
      }

      const getImageProps = (item: OrderWithUrl) => ({
        alt: item['case_item.device_definition_name'] || '',
        placeholderType: ImagePlaceholderTypesEnum.newOrderFlow,
        src: item['case_item.device_definition_image_url'] || '',
      })

      const previewColumnType = OrdersPreviewColumnTypeEnum.ORDER

      return (
        <div
          className="d-inline-flex"
          {...buttonize(
            handleEnterDetails,
            item.id,
            item.url,
            cell.row.index,
            previewColumnType,
          )}
        >
          <div className="d-flex justify-content-center align-items-center me-4 Table-device-image">
            <ImageWithFallback {...getImageProps(item)} />
          </div>

          <div className="d-flex flex-column justify-content-center">
            <div className="d-flex align-items-center">
              <strong data-e2e={E2E_TABLE_DEVICE_NAME}>
                {getReturnAndOrderItemName()}
              </strong>
            </div>

            <div className="d-flex table-text-subtitle text-muted">
              <span>#</span>

              <span className="text-ellipsis" data-orderid={item.id}>
                {getItemId()}
              </span>
            </div>
          </div>
        </div>
      )
    },
    [handleEnterDetails],
  )

  const renderExpandableContent = useCallback(
    ({ cell }: CellContext<OrderWithUrl, unknown>) => {
      const item = cell.row.original
      const contact = {
        firstName: item['case_item.employee_first_name'] ?? '',
        lastName: item['case_item.employee_last_name'] ?? '',
      }

      return (
        <div className="w-100 Table-details">
          {!isActiveEmployee && (
            <div className="Table-details-row">
              <span className="Table-row--expandable-title">
                {renderEmployeeHeader()}
              </span>

              <span className="text-ellipsis">
                {contact ? getFullContactName(contact) : '-'}
              </span>
            </div>
          )}

          <div className="Table-details-row">
            <span className="Table-row--expandable-title">
              {renderDateHeader()}
            </span>

            <span>
              {item['case_item.created'] && item['case_item.created'] > 0 && (
                <FormatDate
                  value={DateTime.fromSeconds(
                    item['case_item.created'],
                  ).toJSDate()}
                />
              )}
            </span>
          </div>

          <div className="Table-details-row">
            <span className="Table-row--expandable-title">
              {renderStatusHeader()}
            </span>

            <span>
              <OrderStatusBadge
                status={item['case_item.status'] as CaseItemStatus}
              />
            </span>
          </div>
        </div>
      )
    },
    [
      isActiveEmployee,
      renderDateHeader,
      renderEmployeeHeader,
      renderStatusHeader,
    ],
  )

  const renderEmployeeCell = useCallback(
    ({ cell }: CellContext<OrderWithUrl, unknown>) => {
      const item = cell.row.original
      const contact = {
        firstName: item['case_item.employee_first_name'] ?? '',
        lastName: item['case_item.employee_last_name'] ?? '',
      }
      const previewColumnType = OrdersPreviewColumnTypeEnum.ORDER

      return (
        <div
          className="d-flex align-items-center h-100"
          {...buttonize(
            handleEnterDetails,
            item.id,
            item.url,
            cell.row.index,
            previewColumnType,
          )}
        >
          <span className="text-ellipsis">
            {contact ? getFullContactName(contact) : '-'}
          </span>
        </div>
      )
    },
    [handleEnterDetails],
  )

  const renderDateCell = useCallback(
    ({ cell }: CellContext<OrderWithUrl, unknown>) => {
      const item = cell.row.original

      const previewColumnType = OrdersPreviewColumnTypeEnum.ORDER

      return (
        <div
          className="d-flex align-items-center h-100"
          {...buttonize(
            handleEnterDetails,
            item.id,
            item.url,
            cell.row.index,
            previewColumnType,
          )}
        >
          {item['case_item.created'] && item['case_item.created'] > 0 && (
            <FormatDate
              value={DateTime.fromSeconds(item['case_item.created']).toJSDate()}
            />
          )}
        </div>
      )
    },
    [handleEnterDetails],
  )

  const renderStatusCell = useCallback(
    ({ cell }: CellContext<OrderWithUrl, unknown>) => {
      const item = cell.row.original
      const previewColumnType = OrdersPreviewColumnTypeEnum.ORDER

      return (
        <div
          className="d-flex align-items-center h-100"
          {...buttonize(
            handleEnterDetails,
            item.id,
            item.url,
            cell.row.index,
            previewColumnType,
          )}
        >
          <OrderStatusBadge
            status={item['case_item.status'] as CaseItemStatus}
          />
        </div>
      )
    },
    [handleEnterDetails],
  )

  const columns = useMemo<ColumnDef<OrderWithUrl>[]>(() => {
    const tableColumns = [
      {
        cell: renderOrderCell,
        header: renderOrdersHeader,
        id: 'order',
        meta: {
          ExpandableContent: renderExpandableContent,
        },
      },
      {
        accessorFn: (row: OrderWithUrl) => row['case_item.employee_full_name'],
        cell: renderEmployeeCell,
        enableSorting: true,
        header: renderEmployeeHeader,
        id: 'case_item.employee_full_name',
      },
      {
        accessorFn: (row: OrderWithUrl) => row['case_item.created'],
        cell: renderDateCell,
        enableSorting: true,
        header: renderDateHeader,
        id: 'case_item.created',
      },
      {
        accessorFn: (row: OrderWithUrl) => row['case_item.status'],
        cell: renderStatusCell,
        enableSorting: true,
        header: renderStatusHeader,
        id: 'case_item.status',
      },
    ]

    return tableColumns.filter(column => {
      // don't show employee when logged in as employee - they are the same
      if (isActiveEmployee && column.id === 'case_item.employee_full_name') {
        return false
      }

      return true
    })
  }, [
    isActiveEmployee,
    renderDateCell,
    renderDateHeader,
    renderEmployeeCell,
    renderEmployeeHeader,
    renderExpandableContent,
    renderOrderCell,
    renderOrdersHeader,
    renderStatusCell,
    renderStatusHeader,
  ])

  const data = useMemo((): OrderWithUrl[] => {
    const parsedOrders = hits.map(hit => {
      const url = composePath({
        isOrganisation,
        params: {
          employeeId,
          orderId: hit['case_item.id'],
          organisationId,
        },
        paths: {
          employee: GLOBAL_ROUTE_PATHS.employee.order,
          organisation: GLOBAL_ROUTE_PATHS.organisation.order,
        },
      })

      return {
        ...hit,
        url,
      }
    })

    return [...parsedOrders]
  }, [employeeId, hits, isOrganisation, organisationId])

  const hasFiltersApplied = items.length > 0
  const customNoResultMessage = hasFiltersApplied
    ? intl.formatMessage({
        id: 'Filter_no_results_content',
      })
    : undefined

  return (
    <Table
      className={clsx(
        'Table--orders',
        isOrganisation ? 'Table--isAdmin' : 'Table--isEmployee',
      )}
      columns={columns}
      customNoResultsMessage={customNoResultMessage}
      data={data}
      e2eRowId={E2E_TABLE_LINK_ORDERS}
      isLoading={isLoading}
      onSortingChange={setSorting}
      selectedRowIndex={selectedRowIndex}
      sorting={sorting}
    />
  )
})
