import { gql } from '@apollo/client'

export const GET_ORDER_DETAILS = gql`
  query getOrderDetails($id: ID!, $organisationId: ID, $employeeId: ID) {
    caseItem(
      id: $id
      organisationId: $organisationId
      employeeId: $employeeId
    ) {
      id
      created
      caseType
      status
      customerLabel
      poRcn
      customClientReference
      billingIdentifier
      data {
        message
        requestedDevice {
          name
        }
        deviceDefinition {
          id
          name
          productType
          imageURL
        }
        fromRentCategory {
          upgradeAmount
        }
        requestedRentCategory {
          deviceDefinition {
            name
            productType
            imageURL
          }
          rentCategory {
            name
            copaymentNet
          }
        }
        requestedRentCategoryCheckout {
          message
        }
        requestedPhonePlan {
          name
          number
          employees
        }
        requestedShippingAddress {
          city
          country
          name
          nameAddition
          street
          streetAddition
          zipcode
        }
        requestedOfferOrder {
          copaymentPrice
        }
        isReplacementRequested
      }
      assignedEmployee {
        id
        contact {
          firstName
          lastName
          email
          phoneNumber
        }
      }
      assignedUser {
        firstName
        lastName
      }
      updateMessages {
        updateMessages {
          id
          userId
          created
          message
        }
      }
      rental {
        id
        deviceID
        earliestContractTermination
        employee {
          contact {
            firstName
            lastName
          }
        }
        startAt
        costCenter {
          name
        }
        rentCategory {
          copaymentNet
          displayedBasisAmount
        }
        stocklistItem {
          id
          imei
          deviceDefinition {
            name
          }
        }
      }
      phonePlan {
        id
        simcardNumber
        startAt
        endAt
        phonePlanTariff {
          name
        }
      }
      user {
        id
        firstName
        lastName
      }
      caseItemShipping {
        trackingURL
        serviceProvider
        trackingReference
        performedAt
      }
      shipping {
        trackingURL
        serviceProvider
        trackingReference
        performedAt
      }
      trackingStatuses {
        totalCount
        trackingStatuses {
          status
          created
        }
      }
      generatedQRCode
      offerOrderItem {
        customDeviceName
      }
    }
  }
`

export const GET_ORDER_LIST_PREVIEW_COLUMN = gql`
  query getOrderListPreviewColumn($id: ID!) {
    caseItem(id: $id) {
      id
      created
      caseType
      status
      data {
        requestedDevice {
          name
        }
        requestedRentCategory {
          deviceDefinition {
            codename
            manufacturer
            productType
            imageURL
            name
          }
          rentCategory {
            copaymentNet
          }
        }
        requestedOfferOrder {
          copaymentPrice
          deviceDefinition {
            name
          }
        }
        requestedPhonePlan {
          name
        }
      }
      assignedUser {
        firstName
        lastName
      }
      rental {
        stocklistItem {
          id
          imei
          imei2
          serialNumber
          eid
          deviceDefinition {
            name
          }
        }
      }
      generatedQRCode
      offerOrderItem {
        customDeviceName
      }
    }
  }
`
