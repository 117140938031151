import { FormattedMessage } from 'react-intl'
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons'

import { Alert } from 'Components/_theme'
import { CardDetailsListItem } from 'Components/CardDetailsList/CardDetailsList.types'
import { PageErrorState } from 'Components/PageErrorState/PageErrorState'
import { PresentationCard } from 'Components/PresentationCard/PresentationCard'
import { ScrollToTop } from 'Components/ScrollToTop/ScrollToTop'

import { useStore } from 'Stores/index'

import { DeviceTypeEnum } from 'Types/deviceTypes'

import { OrderStickyFooter } from '../../OrderStickyFooter/OrderStickyFooter'
import { LostReplacementInfoStepProps } from '../Steps.types'

export const LostReplacementInfoStep = (
  props: LostReplacementInfoStepProps,
) => {
  const { handleNextStep } = props

  const { rentalStore } = useStore()
  const { rentalDetails, isRentalDetailsError } = rentalStore

  if (isRentalDetailsError || !rentalDetails) {
    return <PageErrorState />
  }

  const { rental } = rentalDetails

  const generateListItems: CardDetailsListItem[] = [
    {
      label: 'RentalDetails_imei',
      value: rental?.stocklistItem?.imei,
    },
    {
      label: 'RentalDetails_imei2',
      value: rental?.stocklistItem?.imei2,
    },
    {
      label: 'RentalDetails_serial',
      value: rental?.stocklistItem?.serialNumber,
    },
  ]

  return (
    <div>
      <ScrollToTop />

      <Alert className="mt-4" iconLeft={faCircleInfo} variant="info">
        <FormattedMessage id="LostReplacement_info_text" />
      </Alert>

      <PresentationCard
        cardClassName="DeviceRentalStep mt-4"
        header={rental.stocklistItem?.deviceDefinition?.name ?? ''}
        imagePlaceholderType={
          rental.stocklistItem?.deviceDefinition?.productType as DeviceTypeEnum
        }
        imageSource={rental.stocklistItem?.deviceDefinition?.imageURL}
        listItems={generateListItems}
      />

      <OrderStickyFooter handleNextAction={handleNextStep} />
    </div>
  )
}
