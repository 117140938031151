import { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { faCartShopping } from '@fortawesome/pro-solid-svg-icons'
import { useMediaQuery } from '@react-hookz/web'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import { observer } from 'mobx-react'

import { TableCard } from 'Components/TableCard/TableCard'

import { useStore } from 'Stores/index'

import { buttonize, getFullContactName } from 'Utilities'

import { PORTAL_BREAKPOINTS } from 'Constants/constants'

import { ShippedOrder } from 'Types/dashboard/organisationDashboard.types'

export const ShippedOrdersCard = observer(() => {
  const { dashboardStore, portalSettingsStore } = useStore()
  const { shippedOrders } = dashboardStore
  const { i18n } = portalSettingsStore

  const isMobile = useMediaQuery(`(max-width: ${PORTAL_BREAKPOINTS.LG})`)

  const handleCellClick = (
    event:
      | React.KeyboardEvent<HTMLDivElement>
      | React.MouseEvent<HTMLDivElement, MouseEvent>,
    trackingURL: string,
  ) => {
    event.preventDefault()

    window.open(trackingURL, '_blank')
  }

  const renderDeviceHeader = useCallback(
    () => <FormattedMessage id="Recent_shipments" />,
    [],
  )

  const renderEmployeeHeader = useCallback(
    () => <FormattedMessage id="Employee" />,
    [],
  )

  const renderDeviceCell = useCallback(
    ({ cell }: CellContext<ShippedOrder, unknown>) => {
      const order = cell.row.original

      return (
        <div
          {...buttonize(handleCellClick, order.trackingURL)}
          className="d-flex align-items-center"
        >
          <span className="text-line-clamp fw-600">{order.deviceName}</span>
        </div>
      )
    },
    [],
  )

  const renderEmployeeCell = useCallback(
    ({ cell }: CellContext<ShippedOrder, unknown>) => {
      const order = cell.row.original

      return (
        <div
          {...buttonize(handleCellClick, order.trackingURL)}
          className="d-flex align-items-center"
        >
          <span className="text-ellipsis">{getFullContactName(order)}</span>
        </div>
      )
    },
    [],
  )

  const columns = useMemo<ColumnDef<ShippedOrder>[]>(() => {
    const tableColumns = [
      {
        cell: renderDeviceCell,
        header: renderDeviceHeader,
        id: 'device',
      },
    ]

    if (!isMobile) {
      tableColumns.splice(1, 0, {
        cell: renderEmployeeCell,
        header: renderEmployeeHeader,
        id: 'employee',
      })
    }

    return tableColumns
  }, [
    renderDeviceCell,
    renderDeviceHeader,
    isMobile,
    renderEmployeeCell,
    renderEmployeeHeader,
  ])

  const data = useMemo<ShippedOrder[]>(
    () => shippedOrders || [],
    [shippedOrders],
  )

  if (!shippedOrders) {
    return null
  }

  return (
    <TableCard
      className="mb-6"
      columns={columns}
      data={data}
      heading="ShippedOrdersCard_heading"
      icon={faCartShopping}
      iconColor="blue"
      subtitle="Total_last_30_days"
      tableClassName="TableCard--shippedOrders"
      total={shippedOrders.length.toLocaleString(i18n.locale)}
    />
  )
})
