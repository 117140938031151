import { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'

import { Icon } from 'Components/Icon/Icon'

import { getQueryObject, mergeToQueryString } from 'Utilities'

import {
  E2E_GS_AUTOCOMPLETE_OPTIONS_ALL,
  E2E_GS_AUTOCOMPLETE_OPTIONS_ITEM,
  E2E_GS_AUTOCOMPLETE_OPTIONS_TOGGLE,
} from 'Constants/e2e'

import { GlobalSearchDropdownProps } from './GlobalSearch.types'

export const GlobalSearchDropdown = (props: GlobalSearchDropdownProps) => {
  const { options } = props
  const initialSearchOptionTranslationId = 'GlobalSearch_SearchAll'
  const intl = useIntl()
  const routerHistory = useHistory()
  const routerLocation = useLocation()
  const [searchSelectedOption, setSearchSelectedOption] = useState<string>(
    initialSearchOptionTranslationId,
  )

  useEffect(() => {
    const queryObject = getQueryObject(routerLocation.search)

    if (!queryObject) {
      setSearchSelectedOption(initialSearchOptionTranslationId)

      return
    }

    const selectedOption = options.find(
      option => option.modelType === queryObject.searchModel,
    )

    if (!selectedOption) {
      return
    }

    setSearchSelectedOption(selectedOption.translationId)
  }, [options, routerLocation.search])

  const setSelectedOptionHandler = (
    selectedOptionId: string,
    selectedOptionModel?: string,
  ) => {
    if (!selectedOptionModel) {
      setSearchSelectedOption(selectedOptionId)

      const searchQuery = mergeToQueryString(routerLocation.search, {
        searchModel: '',
      })

      routerHistory.push({ search: searchQuery })
    } else {
      setSearchSelectedOption(selectedOptionId)

      const searchQuery = mergeToQueryString(routerLocation.search, {
        searchModel: selectedOptionModel,
      })

      routerHistory.push({ search: searchQuery })
    }
  }

  const searchOptions = options
    .filter(option => option.translationId !== searchSelectedOption)
    .map(option => (
      <Dropdown.Item
        key={option.path}
        aria-label={intl.formatMessage({
          id: option.translationId,
        })}
        data-e2e={E2E_GS_AUTOCOMPLETE_OPTIONS_ITEM}
        href="#"
        onClick={() =>
          setSelectedOptionHandler(option.translationId, option.modelType)
        }
      >
        <FormattedMessage id={option.translationId} />
      </Dropdown.Item>
    ))

  const renderSearchAllOption = () => {
    if (searchSelectedOption !== initialSearchOptionTranslationId) {
      return (
        <Dropdown.Item
          key={initialSearchOptionTranslationId}
          aria-label={intl.formatMessage({
            id: initialSearchOptionTranslationId,
          })}
          data-e2e={E2E_GS_AUTOCOMPLETE_OPTIONS_ALL}
          onClick={() =>
            setSelectedOptionHandler(initialSearchOptionTranslationId)
          }
        >
          <FormattedMessage id={initialSearchOptionTranslationId} />
        </Dropdown.Item>
      )
    }

    return null
  }

  return (
    <Dropdown className="gs-dropdown">
      <Dropdown.Toggle
        aria-label={intl.formatMessage({
          id: searchSelectedOption,
        })}
        className="GlobalSearchDropdown d-flex align-items-center justify-content-between justify-content-lg-start"
        data-e2e={E2E_GS_AUTOCOMPLETE_OPTIONS_TOGGLE}
        // We need to alter tabIndex for correct accessibility tab order
        // eslint-disable-next-line jsx-a11y/tabindex-no-positive
        tabIndex={1}
      >
        <FormattedMessage id={searchSelectedOption} />

        <Icon className="ms-2 mb-1" icon={faChevronDown} size={12} />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {searchOptions}

        {renderSearchAllOption()}
      </Dropdown.Menu>
    </Dropdown>
  )
}
