import { Field } from 'Components/Field/Field'
import { FieldTypeEnum } from 'Components/Field/Field.types'

import { FORM_OPTIONAL_MESSAGE_MAX_CHARACTERS } from './FormOptionalMessage.constants'
import { FormOptionalMessageProps } from './FormOptionalMessage.types'

export const FormOptionalMessage = (props: FormOptionalMessageProps) => {
  const { containerClassName } = props

  return (
    <Field
      feedbackTranslationValues={{
        amount: FORM_OPTIONAL_MESSAGE_MAX_CHARACTERS,
      }}
      label="Message_for_everphone"
      name="message"
      textareaProps={{
        maxLength: FORM_OPTIONAL_MESSAGE_MAX_CHARACTERS,
        showCounter: true,
      }}
      type={FieldTypeEnum.textareaAutosize}
      {...props}
      containerClassName={containerClassName}
    />
  )
}
