import { gql } from '@apollo/client'

export const GET_ALLOWED_ACLS = gql`
  query getAllowedAcls($first: Int!, $after: Int!) {
    allowedEmployees {
      employees {
        contact {
          firstName
          id
          lastName
        }
        organisation {
          logoImageURL
        }
        id
      }
      totalCount
    }
    organisations(pagination: { first: $first, after: $after }) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      organisations {
        id
        fullName
        logoImageURL
        isReadOnly
      }
    }
  }
`
