import { useStore } from 'Stores/index'

import { CountriesDE } from 'Constants/CountriesDE'
import { CountriesEN } from 'Constants/CountriesEN'
import { CountriesFR } from 'Constants/CountriesFR'
import { SupportedLanguagesEnum } from 'Constants/locale'

/**
 * This hook is used to get country list of currently used language.
 * Please wrap component with the `observer` method from 'react-mobx' for the hook to re-sync data properly.
 */
export const useCountryList = () => {
  const { portalSettingsStore } = useStore()
  const {
    i18n: { language: activeLanguage },
  } = portalSettingsStore

  switch (activeLanguage) {
    case SupportedLanguagesEnum.DE:
      return CountriesDE
    case SupportedLanguagesEnum.FR:
      return CountriesFR
    default:
      return CountriesEN
  }
}
