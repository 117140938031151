export enum DeviceTypeEnum {
  headset = 'headset',
  keyboard = 'keyboard',
  laptop = 'laptop',
  monitor = 'monitor',
  mouse = 'mouse',
  other = 'other',
  printer = 'printer',
  smartphone = 'smartphone',
  tablet = 'tablet',
}
