import { Col, Container, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { EmployeeDetailsCard } from 'Modules/employees/components/EmployeeDetailsCard/EmployeeDetailsCard'

import { PageErrorState } from 'Components/PageErrorState/PageErrorState'
import { PageHeader } from 'Components/PageHeader/PageHeader'
import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'
import { PhonePlansEntityCard } from 'Components/PhonePlansEntityCard/PhonePlansEntityCard'
import { RecentRequestsEntityCard } from 'Components/RecentRequestsEntityCard/RecentRequestsEntityCard'
import { RentalEntityCard } from 'Components/RentalEntityCard/RentalEntityCard'

import {
  getEmployeeDetails,
  getEmployeeDetailsVariables,
} from 'Constants/graphql/queries/detail/__generated__/getEmployeeDetails'
import { GET_EMPLOYEE_DETAILS } from 'Constants/graphql/queries/detail/GetEmployeeDetails'
import { PortalSectionEnum } from 'Constants/portalSection'

import { EMPLOYEE_RENTALS_CARD } from './Employee.constants'

export const Employee = () => {
  const { employeeId, organisationId } = useParams<{
    employeeId: string
    organisationId: string
  }>()

  const {
    data,
    loading: isLoading,
    error,
    refetch,
  } = useQuery<getEmployeeDetails, getEmployeeDetailsVariables>(
    GET_EMPLOYEE_DETAILS,
    {
      variables: {
        id: employeeId,
        organisationId,
      },
    },
  )

  if (isLoading) {
    return <PageLoadingState />
  }

  if (error) {
    return <PageErrorState />
  }

  if (!data) {
    return <PageErrorState />
  }

  const { employee } = data

  return (
    <Container className="pt-6" fluid>
      <div role="banner">
        <PageHeader portalSection={PortalSectionEnum.EmployeeDetails} />
      </div>

      <Row className="mt-4">
        <Col xxl={8}>
          <EmployeeDetailsCard
            actions={employee.actions}
            employee={employee}
            refetch={refetch}
          />

          <RentalEntityCard
            rentals={employee.rentals?.rentals ?? []}
            testId={EMPLOYEE_RENTALS_CARD}
          />
        </Col>

        <Col xxl={4}>
          <Row>
            <Col lg={6} xs={12} xxl={12}>
              <RecentRequestsEntityCard
                caseItems={employee.caseitems?.caseItems ?? []}
              />
            </Col>

            <Col lg={6} xs={12} xxl={12}>
              <PhonePlansEntityCard
                phonePlans={employee.phonePlans?.phonePlans ?? []}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
