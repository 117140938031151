import { CASE_ITEM_TYPE_TRANSLATION_IDS } from 'Constants/caseItemType'

import { CaseItemType } from 'Portal/__generated__/globalTypes'

import { dashOrSpaceToUnderscore } from '../dashOrSpaceToUnderscore/dashOrSpaceToUnderscore.util'
import { getCaseItemTypeTranslationId } from '../getCaseItemTypeTranslationId/getCaseItemTypeTranslationId.util'

export const getOrderDisplayName = (
  caseType: `${CaseItemType}`,
  deviceName: string,
) => {
  const formattedCaseType = dashOrSpaceToUnderscore(caseType)

  if (formattedCaseType === CaseItemType.general) {
    return CASE_ITEM_TYPE_TRANSLATION_IDS[formattedCaseType]
  }

  return {
    name: deviceName,
    type: getCaseItemTypeTranslationId(caseType),
  }
}
