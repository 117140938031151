import { FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { faChartPieSimple } from '@fortawesome/pro-solid-svg-icons'
import { observer } from 'mobx-react'

import { GlobalRouteTypesEnum } from 'Router/routeConfig.types'
import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { Card, CardBody, CardHeader, LinkButton } from 'Components/_theme'

import { useStore } from 'Stores/index'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import { FleetOverviewChart } from '../FleetOverviewChart/FleetOverviewChart'

export const DashboardFleetOverviewCard = observer(() => {
  const { dashboardStore } = useStore()
  const { fleetOverview } = dashboardStore
  const intl = useIntl()

  const { organisationId } = useParams<{
    organisationId?: string
  }>()

  if (!fleetOverview) {
    return null
  }

  const data = fleetOverview.map(({ count }) => count)
  const labels = fleetOverview.map(
    ({ name }) => name || intl.formatMessage({ id: 'Other' }),
  )

  const rentalsPath = composePath({
    params: {
      organisationId,
    },
    path: GLOBAL_ROUTE_PATHS[GlobalRouteTypesEnum.ORGANISATION].rentals,
  })

  return (
    <Card className="DashboardFleetOverviewCard">
      <CardHeader
        icon={faChartPieSimple}
        rightContent={
          <LinkButton size="small" to={rentalsPath}>
            <FormattedMessage id="View_rentals" />
          </LinkButton>
        }
        subtitle={intl.formatMessage({
          id: 'DashboardFleetOverviewCard_subtitle',
        })}
      >
        <FormattedMessage id="DashboardFleetOverviewCard_header" />
      </CardHeader>

      <CardBody>
        <FleetOverviewChart data={data} labels={labels} />
      </CardBody>
    </Card>
  )
})
