import { Table } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { faMobileAlt } from '@fortawesome/pro-solid-svg-icons'

import { ButtonGroup, Card, CardBody, CardHeader } from 'Components/_theme'
import { ModalErrorState } from 'Components/ModalErrorState/ModalErrorState'

import { DepotStockModalProps } from './DepotStockModal.types'

export const DepotStockModal = (props: DepotStockModalProps) => {
  const { hide, stocklistItems } = props
  const { deviceDefinition, imeis } = stocklistItems

  const intl = useIntl()

  if (!deviceDefinition) {
    return <ModalErrorState />
  }

  return (
    <Card>
      <CardHeader icon={faMobileAlt}>{deviceDefinition.name}</CardHeader>

      <CardBody>
        {!deviceDefinition && <ModalErrorState />}

        {deviceDefinition &&
          (imeis.length === 0 ? (
            <FormattedMessage
              id="DepotStockModal_no_available"
              values={{
                name: deviceDefinition.name,
              }}
            />
          ) : (
            <Table size="sm">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="IMEI" />
                  </th>

                  <th>
                    <FormattedMessage id="DepotStockModal_serial_number" />
                  </th>
                </tr>
              </thead>

              <tbody>
                {imeis.map(imei => (
                  <tr key={imei.imei || imei.serialNumber}>
                    <td>{imei.imei || '-'}</td>

                    <td>{imei.serialNumber || '-'}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ))}

        <ButtonGroup
          rightButton={{
            onClick: hide,
            text: intl.formatMessage({ id: 'Close' }),
          }}
        />
      </CardBody>
    </Card>
  )
}
