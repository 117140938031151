import { z } from 'zod'

import { getRental_rental } from 'Constants/graphql/queries/detail/__generated__/getRental'

import {
  requestPurchaseModalLandingSchema,
  requestPurchaseModalSummarySchema,
} from './RequestPurchaseModal.schema'

export type RequestPurchaseModalLandingSchema = z.infer<
  typeof requestPurchaseModalLandingSchema
>

export type RequestPurchaseModalSummarySchema = z.infer<
  typeof requestPurchaseModalSummarySchema
>

export enum RequestPurchaseModalStepsEnum {
  'LANDING' = '1',
  'SUMMARY' = '2',
  'CONFIRMATION' = '5',
  'INVALID' = '10',
}

export type RequestPurchaseModalStepsMap = {
  [key: string]: {
    component: React.FunctionComponent<any>
    props: any
    title: string
  }
}

export type RequestPurchaseModalProps = {
  rental: getRental_rental
  onHide: () => void
}

export type RequestPurchaseModalLandingProps = {
  rental: getRental_rental
  onSetCurrentStep: (step: `${RequestPurchaseModalStepsEnum}`) => void
  onHide: () => void
}

export type RequestPurchaseModalSummaryProps = {
  rental: getRental_rental
  onSetCurrentStep: (
    step: `${RequestPurchaseModalStepsEnum}`,
    data?: RequestPurchaseModalSummarySchema,
  ) => void
  isSubmitting: boolean
}

export type RequestPurchaseModalDeviceProps = {
  rental: getRental_rental
}

export type RequestPurchaseModalConfirmationProps = {
  onHide: () => void
}

export type RequestPurchaseModalInvalidProps = {
  onHide: () => void
}
