import { isEmpty, orderBy } from 'lodash-es'

import {
  dashOrSpaceToUnderscore,
  fetchFromApiUsingFullPath,
  handleFetchErrorFunctional,
} from 'Utilities'

import { ContentTypeEnum } from 'Types/content/content.types'
import { SearchAutocompleteResults } from 'Types/globalSearch/searchResults.types'

import {
  CaseItemStatus,
  CaseItemType,
  ReturnCaseStatus,
} from 'Portal/__generated__/globalTypes'
import { getApiConfig } from 'Portal/src/config/api.config'

import {
  GlobalSearchAutocompleteCategory,
  GlobalSearchAutocompleteInitialiseSource,
  GlobalSearchAutocompleteSource,
  GlobalSearchAutocompleteSuggestion,
  GlobalSearchAutocompleteSuggestionParsedDisplayOrder,
  GlobalSearchAutocompleteSuggestionParsedDisplayReturnCase,
  GlobalSearchAutocompleteTemplateBaseData,
  GlobalSearchAutocompleteTemplateItemData,
  InitialiseSourceConfigurationFn,
  InvalidSourceConfiguration,
  SourceConfigurationFn,
  ValidSourceConfiguration,
} from './GlobalSearch.types'

const filterUnwantedModels = (rawResponse: SearchAutocompleteResults) =>
  rawResponse.result.filter(
    resultItem =>
      resultItem.model !== ContentTypeEnum.stocklist_items &&
      resultItem.model !== ContentTypeEnum.invoices,
  )

export const stripHtmlFromString = (value: string) =>
  value.replace(/(<([^>]+)>)/gi, '')

const mapResponseToAutocompleteCategories = (
  rawResponse: SearchAutocompleteResults,
): GlobalSearchAutocompleteCategory[] | [] => {
  if (isEmpty(rawResponse.result)) {
    return []
  }

  const filteredResult = filterUnwantedModels(rawResponse)

  const categories = filteredResult
    .map<GlobalSearchAutocompleteCategory>(filteredResultItem => ({
      name: filteredResultItem.model,
      resultNo: filteredResultItem.count,
    }))
    .filter(categoryItem => categoryItem.resultNo > 0)

  return categories
}

const mapResponseToAutocompleteSuggestions = (
  rawResponse: SearchAutocompleteResults,
): GlobalSearchAutocompleteSuggestion[] | [] => {
  if (isEmpty(rawResponse.result)) {
    return []
  }

  const filteredResult = filterUnwantedModels(rawResponse)

  const combinedSearchHits = filteredResult
    .map(filteredResultItem => filteredResultItem.hits || [])
    .flat()

  const orderedSearchHits = orderBy(
    combinedSearchHits,
    ['text_match'],
    ['desc'],
  ).slice(0, 5)

  const suggestions = orderedSearchHits.map<GlobalSearchAutocompleteSuggestion>(
    orderedSearchHitsItem => ({
      category: orderedSearchHitsItem.category,
      display: orderedSearchHitsItem.display,
      highlights: orderedSearchHitsItem.highlights,
      id: orderedSearchHitsItem.entity_id,
      snippet: orderedSearchHitsItem.display,
      type: orderedSearchHitsItem.type,
    }),
  )

  return suggestions
}

const invalidSource = (
  sourceId: string,
  options: GlobalSearchAutocompleteInitialiseSource,
): InvalidSourceConfiguration[] => [
  {
    getItems() {
      return []
    },
    sourceId,
    templates: {
      noResults(
        noResultsTemplateData: GlobalSearchAutocompleteTemplateBaseData,
      ) {
        return options.renderTemplateNoResults(
          noResultsTemplateData.state.status,
          noResultsTemplateData.state.query,
        )
      },
    },
  },
]

const validSource = (
  sourceId: string,
  response: SearchAutocompleteResults,
  options: GlobalSearchAutocompleteInitialiseSource,
): ValidSourceConfiguration[] => [
  {
    getItemInputValue(
      itemTemplateData: GlobalSearchAutocompleteTemplateItemData,
    ) {
      if (itemTemplateData.item.type === 'direct') {
        return itemTemplateData.state.query
      }

      return stripHtmlFromString(itemTemplateData.item.snippet)
    },
    getItems() {
      return mapResponseToAutocompleteSuggestions(response)
    },
    onSelect(itemTemplateData: GlobalSearchAutocompleteTemplateItemData) {
      const { event, item, setActiveItemId } = itemTemplateData

      if (
        event instanceof KeyboardEvent &&
        event.type === 'keydown' &&
        event.code === 'Enter'
      ) {
        const { __autocomplete_id: id } = item

        setActiveItemId(id!)

        const elements = document.getElementsByClassName('gs-submit-button')
        const gsSubmit = elements[0] as HTMLButtonElement

        gsSubmit?.click()
      }

      if (options.onSelectHandler) {
        options.onSelectHandler()
      }
    },
    sourceId,
    templates: {
      footer(footerTemplateData: GlobalSearchAutocompleteTemplateBaseData) {
        return options.renderTemplateFooter(
          footerTemplateData.state.context.categories,
          footerTemplateData.state.query,
        )
      },
      header(headerTemplateData: GlobalSearchAutocompleteTemplateBaseData) {
        if (isEmpty(headerTemplateData.items)) {
          return null
        }

        return options.renderTemplateHeader()
      },
      item(itemTemplateData: GlobalSearchAutocompleteTemplateItemData) {
        return options.renderTemplateItem(itemTemplateData)
      },
      noResults(
        noResultsTemplateData: GlobalSearchAutocompleteTemplateBaseData,
      ) {
        return options.renderTemplateNoResults(
          noResultsTemplateData.state.status,
          noResultsTemplateData.state.query,
        )
      },
    },
  },
]

export const initialiseSources: InitialiseSourceConfigurationFn =
  (options: GlobalSearchAutocompleteInitialiseSource): SourceConfigurationFn =>
  (sourceProps: GlobalSearchAutocompleteSource) => {
    const { query, setContext } = sourceProps
    const sourceId = 'gSearch'
    const { globalSearch } = getApiConfig()
    const requestBody = JSON.stringify({
      active_orgId: options.activeOrganisationId,
      model: options.searchModel,
      query,
    })

    if (!query || query.length < 3) {
      return invalidSource(sourceId, options)
    }

    return fetchFromApiUsingFullPath(globalSearch.autocompleteUrl, {
      body: requestBody,
      headers: {
        authorization: options.token,
      },
      method: 'POST',
    })
      .then((response: SearchAutocompleteResults) => {
        setContext({
          categories: mapResponseToAutocompleteCategories(response),
        })

        return validSource(sourceId, response, options)
      })
      .catch(error => {
        handleFetchErrorFunctional()(error)

        return invalidSource(sourceId, options)
      })
  }

/**
 *
 * @param display format: caseItemId|caseItemType|deviceName|caseItemStatus
 */
export function parseOrderDisplay(display: string) {
  return display
    .split('|')
    .reduce<GlobalSearchAutocompleteSuggestionParsedDisplayOrder>(
      (result, current, index) => {
        switch (index) {
          case 0:
            result.highlight = current
            break
          case 1:
            result.type = current as CaseItemType
            break
          case 2:
            result.name = current
            break
          case 3:
            result.status = dashOrSpaceToUnderscore(current as CaseItemStatus)
            break
          default:
        }

        return result
      },
      {} as GlobalSearchAutocompleteSuggestionParsedDisplayOrder,
    )
}

/**
 *
 * @param display format: returnCaseId|deviceName|status
 */
export function parseReturnCaseDisplay(display: string) {
  return display
    .split('|')
    .reduce<GlobalSearchAutocompleteSuggestionParsedDisplayReturnCase>(
      (result, current, index) => {
        switch (index) {
          case 0:
            result.highlight = current
            break
          case 1:
            result.name = current
            break
          case 2:
            result.status = dashOrSpaceToUnderscore(current as ReturnCaseStatus)
            break
          default:
        }

        return result
      },
      {} as GlobalSearchAutocompleteSuggestionParsedDisplayReturnCase,
    )
}
