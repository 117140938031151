import { store } from 'Stores/index'

import { PortalSectionEnum } from 'Constants/portalSection'

import { Order, Orders } from '../pages'
import { OrdersRoutePathsEnum } from './ordersRouteConfig.types'

export const ORDERS_ROUTES = [
  {
    component: Order,
    onRender: () => {
      const { portalSettingsStore } = store
      const { setCurrentPortalSection } = portalSettingsStore

      setCurrentPortalSection(PortalSectionEnum.Orders)
    },
    path: `${OrdersRoutePathsEnum.ORDER}`,
  },
  {
    component: Orders,
    onRender: () => {
      const { portalSettingsStore } = store
      const { setCurrentPortalSection } = portalSettingsStore

      setCurrentPortalSection(PortalSectionEnum.Orders)
    },
    path: `${OrdersRoutePathsEnum.ORDERS}`,
  },
]
