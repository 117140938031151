import { getRental_rental } from 'Constants/graphql/queries/detail/__generated__/getRental'

export type RentalDetailsCardProps = {
  rental: getRental_rental
  refetch: () => void
}

export enum RentalDetailsCardModalTypes {
  CancelSmartphone,
  EarlyUpgradeDevice,
  ReplaceBrokenDevice,
  ExchangeDevice,
  ReturnDevice,
  LegacyUpgradeDevice,
  RentalCompleteUpgradeDevice,
  ReassignDevice,
  ProlongDevice,
  RequestPurchase,
  LostOrStolenModal,
  ReplaceFromDepot,
}
