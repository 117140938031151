import { components, GroupBase } from 'react-select'
import { faClose } from '@fortawesome/pro-solid-svg-icons'

import { Icon } from 'Components/Icon/Icon'
import { SelectOption } from 'Components/Select/Select.types'

import { SelectMultiValueRemoveProps } from './SelectMultiValueRemove.types'

export const SelectMultiValueRemove = <
  Option extends SelectOption,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: SelectMultiValueRemoveProps<Option, IsMulti, Group>,
) => (
  <components.MultiValueRemove {...props}>
    <Icon icon={faClose} size={14} />
  </components.MultiValueRemove>
)
