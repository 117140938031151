import { ChangeEvent } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation } from '@apollo/client'
import { zodResolver } from '@hookform/resolvers/zod'
import { observer } from 'mobx-react'

import { ButtonGroup, Card, CardBody, CardHeader } from 'Components/_theme'
import { Field } from 'Components/Field/Field'
import { FieldTypeEnum } from 'Components/Field/Field.types'
import { Form } from 'Components/Form/Form'
import { FormOptionalMessage } from 'Components/FormOptionalMessage/FormOptionalMessage'
import { ModalErrorState } from 'Components/ModalErrorState/ModalErrorState'
import { PhonePlansSelect } from 'Components/PhonePlansSelect/PhonePlansSelect'

import { useStore } from 'Stores/index'

import { useActiveUser } from 'Hooks'

import { getSelectValue } from 'Utilities'

import {
  E2E_NEW_PHONE_PLAN_MODAL_MESSAGE,
  E2E_NEW_PHONE_PLAN_MODAL_NAME_INPUT,
  E2E_NEW_PHONE_PLAN_MODAL_SUBMIT,
} from 'Constants/e2e'
import {
  mutationNewPhonePlan,
  mutationNewPhonePlanVariables,
} from 'Constants/graphql/mutations/__generated__/mutationNewPhonePlan'
import { MUTATION_NEW_PHONE_PLAN } from 'Constants/graphql/mutations/MutationNewPhonePlan'

import { iconNewPhonePlan } from 'Icons/iconNewPhonePlan'

import { newPhonePlanModalFormSchema } from './NewPhonePlanModal.schema'
import {
  NewPhonePlanModalFormSchema,
  NewPhonePlanModalProps,
} from './NewPhonePlanModal.types'
import { newPhonePlanModalDefaultValues } from './NewPhonePlanModal.values'

export const NewPhonePlanModal = observer((props: NewPhonePlanModalProps) => {
  const { handleSubmit, onClose } = props

  const { userStore } = useStore()
  const { user } = userStore
  const { activeOrganisation } = useActiveUser()
  const intl = useIntl()

  const formMethods = useForm<NewPhonePlanModalFormSchema>({
    defaultValues: newPhonePlanModalDefaultValues,
    resolver: zodResolver(newPhonePlanModalFormSchema),
  })

  const { control, watch } = formMethods
  const {
    fields: employeeFields,
    append: appendEmployee,
    remove: removeEmployee,
  } = useFieldArray({
    control,
    name: 'employees',
  })
  const employees = watch('employees')

  const [createPhonePlanCaseItem, { loading, error }] = useMutation<
    mutationNewPhonePlan,
    mutationNewPhonePlanVariables
  >(MUTATION_NEW_PHONE_PLAN, {
    onCompleted: () => {
      handleSubmit()
    },
  })

  const handleFormSubmit = formMethods.handleSubmit(data => {
    createPhonePlanCaseItem({
      variables: {
        input: {
          employees: data.employees?.map(employee => employee.name) || [],
          message: data.message,
          name: getSelectValue(data.phonePlan),
          organisationID: activeOrganisation?.id!,
          quantity: Number(data.quantity),
          userId: user.userId!.toString(),
        },
      },
    })
  })

  const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!employees) {
      return
    }

    if (Number(event.target.value) < employees?.length) {
      const difference = employees.length - Number(event.target.value)

      Array.from(Array(difference)).forEach(() => {
        removeEmployee(-1)
      })
    }

    if (Number(event.target.value) > employees?.length) {
      const difference = Number(event.target.value) - employees.length

      Array.from(Array(difference)).forEach(() => {
        appendEmployee({ name: '' }, { shouldFocus: false })
      })
    }
  }

  return (
    <Card>
      <CardHeader icon={iconNewPhonePlan}>
        <FormattedMessage id="New_phone_plan" />
      </CardHeader>

      {!error ? (
        <CardBody>
          <FormProvider {...formMethods}>
            <Form id="NEW_PHONE_PLAN_MODAL_FORM" onSubmit={handleFormSubmit}>
              <PhonePlansSelect
                containerClassName="mb-4"
                label="Choose_phone_plan"
                markAsRequired
                name="phonePlan"
              />

              <div role="group">
                <Field
                  autoComplete="off"
                  containerClassName="mb-4"
                  inputProps={{
                    max: 100,
                    min: 1,
                    onChange: handleQuantityChange,
                  }}
                  label="Quantity"
                  name="quantity"
                  shouldTranslateLabel
                  type={FieldTypeEnum.number}
                />

                {employeeFields.map((item, index) => (
                  <Field
                    key={item.id}
                    aria-label={
                      index > 0
                        ? `${intl.formatMessage({
                            id: 'For_employee',
                          })} ${index}`
                        : undefined
                    }
                    autoComplete="off"
                    containerClassName="mb-4"
                    e2eSelector={E2E_NEW_PHONE_PLAN_MODAL_NAME_INPUT}
                    label={index === 0 ? 'For_employee' : undefined}
                    name={`employees.${index}.name`}
                    placeholder={intl.formatMessage({
                      id: 'First_and_last_name',
                    })}
                    shouldTranslateLabel
                    type={FieldTypeEnum.text}
                  />
                ))}
              </div>

              <FormOptionalMessage
                containerClassName="mb-4"
                e2eSelector={E2E_NEW_PHONE_PLAN_MODAL_MESSAGE}
              />

              <ButtonGroup
                leftButton={{
                  onClick: onClose,
                  text: intl.formatMessage({ id: 'Cancel' }),
                }}
                rightButton={{
                  'data-e2e': E2E_NEW_PHONE_PLAN_MODAL_SUBMIT,
                  disabled: !!error,
                  isLoading: loading,
                  text: intl.formatMessage({ id: 'Order_phone_plan' }),
                  type: 'submit',
                }}
              />
            </Form>
          </FormProvider>
        </CardBody>
      ) : (
        <ModalErrorState />
      )}
    </Card>
  )
})
