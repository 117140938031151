import { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'

import { CardBody, PrimaryButton } from 'Components/_theme'

import {
  ReplaceBrokenDeviceLandingProps,
  ReplaceBrokenDeviceModalStepsEnum,
} from 'Modals/ReplaceBrokenDeviceModal/ReplaceBrokenDeviceModal.types'

import { E2E_REPLACE_BROKEN_DEVICE_BUTTON } from 'Constants/e2e'

export const ReplaceBrokenDeviceModalLanding = (
  props: ReplaceBrokenDeviceLandingProps,
) => {
  const { heading, isReplacementDeviceAvailableInDepot, onSetCurrentStep } =
    props

  const handleGoToReplacementForm = useCallback(() => {
    if (!isReplacementDeviceAvailableInDepot) {
      return
    }

    onSetCurrentStep(ReplaceBrokenDeviceModalStepsEnum.REPLACEMENT_FORM)
  }, [isReplacementDeviceAvailableInDepot, onSetCurrentStep])

  const handleGoToShippingForm = useCallback(() => {
    onSetCurrentStep(ReplaceBrokenDeviceModalStepsEnum.SHIPPING)
  }, [onSetCurrentStep])

  return (
    <>
      {heading}

      <CardBody>
        <PrimaryButton
          className="w-100"
          disabled={!isReplacementDeviceAvailableInDepot}
          onClick={handleGoToReplacementForm}
        >
          <FormattedMessage id="ReplaceBrokenDeviceModal_orderDepot" />
        </PrimaryButton>

        {!isReplacementDeviceAvailableInDepot && (
          <small className="d-block mt-1">
            <FormattedMessage id="ReplaceBrokenDeviceModal_noReplacementFound" />
          </small>
        )}

        <PrimaryButton
          className="w-100 mt-4"
          data-e2e={E2E_REPLACE_BROKEN_DEVICE_BUTTON}
          onClick={handleGoToShippingForm}
        >
          <FormattedMessage id="ReplaceBrokenDeviceModal_orderEverphone" />
        </PrimaryButton>

        <small className="d-block mt-1">
          <FormattedMessage id="ReplaceBrokenDeviceModal_replacementModelNote" />
        </small>
      </CardBody>
    </>
  )
}
