import { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  faBuildingShield,
  faKey,
  faRightFromBracket,
  faShield,
  faStamp,
} from '@fortawesome/pro-solid-svg-icons'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { Card } from 'Components/_theme'
import { OptionButton } from 'Components/_theme/OptionButton/OptionButton'

import { isActiveLanguage } from 'Utilities'

import { SupportedLanguagesEnum } from 'Constants/locale'

export const AccountOptions = () => {
  const handleLogout = useCallback(() => {
    localStorage.removeItem('theme')

    window.location.href = '/sicherheit/portal/logout'
  }, [])

  const isGermanLanguageActive = isActiveLanguage(SupportedLanguagesEnum.DE)

  return (
    <Card className="account-options">
      <ul>
        <li>
          <OptionButton
            asExternalLink
            iconLeft={faKey}
            to="/sicherheit/neues-passwort"
          >
            <FormattedMessage id="Reset_password" />
          </OptionButton>
        </li>

        <li>
          <OptionButton
            iconLeft={faBuildingShield}
            to={GLOBAL_ROUTE_PATHS.public.dataPrivacy}
          >
            <FormattedMessage id="Data_privacy" />
          </OptionButton>
        </li>

        <li>
          <OptionButton
            asExternalLink
            iconLeft={faStamp}
            to={
              isGermanLanguageActive
                ? 'https://www.everphone.com/de/impressum/'
                : 'https://www.everphone.com/imprint/'
            }
          >
            <FormattedMessage id="Imprint" />
          </OptionButton>
        </li>

        <li>
          <OptionButton
            asExternalLink
            iconLeft={faShield}
            to="https://security.everphone.app/"
          >
            <FormattedMessage id="Security" />
          </OptionButton>
        </li>

        <li>
          <OptionButton
            className="account-logout"
            iconLeft={faRightFromBracket}
            onClick={handleLogout}
            variant="danger"
          >
            <FormattedMessage id="Logout" />
          </OptionButton>
        </li>
      </ul>
    </Card>
  )
}
