import { useCallback, useMemo, useState } from 'react'
import { FormProvider, get, useWatch } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { cloneDeep } from '@apollo/client/utilities'

import { ButtonGroup, CardBody } from 'Components/_theme'
import { AlertWithError } from 'Components/AlertWithError/AlertWithError'
import { Field } from 'Components/Field/Field'
import { FieldTypeEnum } from 'Components/Field/Field.types'
import { Form } from 'Components/Form/Form'
import { SelectOption } from 'Components/Select/Select.types'

import {
  E2E_EDIT_EMPLOYEE_FORM_ALLOW_LOGIN,
  E2E_EDIT_EMPLOYEE_FORM_BACK_BUTTON,
  E2E_EDIT_EMPLOYEE_FORM_CONFIRMATION_CONTENT,
  E2E_EDIT_EMPLOYEE_FORM_COST_CENTER,
  E2E_EDIT_EMPLOYEE_FORM_EDIT_CONTENT,
  E2E_EDIT_EMPLOYEE_FORM_EMAIL,
  E2E_EDIT_EMPLOYEE_FORM_EMPLOYEE_ID,
  E2E_EDIT_EMPLOYEE_FORM_FIRST_NAME,
  E2E_EDIT_EMPLOYEE_FORM_LAST_NAME,
  E2E_EDIT_EMPLOYEE_FORM_OFFICE,
  E2E_EDIT_EMPLOYEE_FORM_PHONE_NUMBER,
  E2E_EDIT_EMPLOYEE_FORM_RENT_CATEGORY_TIER,
  E2E_EDIT_EMPLOYEE_FORM_SUBMIT_BUTTON,
} from 'Constants/e2e'

import { useActiveUser } from 'Portal/src/hooks'

import {
  EDIT_EMPLOYEE_FORM_CONFIRMATION_BACK_BUTTON,
  EDIT_EMPLOYEE_FORM_CONFIRMATION_CONTENT,
  EDIT_EMPLOYEE_FORM_EDIT_CONTENT,
  EMPLOYEE_COST_CENTER_NAME_MAX_CHARACTERS,
  EMPLOYEE_FIRST_NAME_MAX_CHARACTERS,
  EMPLOYEE_LAST_NAME_MAX_CHARACTERS,
} from '../EditEmployeeFormModal.constants'
import { EditEmployeeFormModalFormProps } from '../EditEmployeeFormModal.types'
import { EditEmployeeFormModalEmailConfirmation } from './EditEmployeeFormEmailConfirmation'

export const EditEmployeeFormModalForm = (
  props: EditEmployeeFormModalFormProps,
) => {
  const { employee, formMethods, isSubmitting, submitError, onHide, onSubmit } =
    props
  const [showEmailConfirmation, setShowEmailConfirmation] = useState(false)
  const isExistingEmployee = !!employee

  const { activeOrganisation } = useActiveUser()
  const intl = useIntl()

  const email = useWatch({
    control: formMethods.control,
    name: 'email',
  })

  const { formState } = formMethods
  const { errors } = formState
  const newCostCenterErrorMessage = get(errors, 'newCostCenter')?.message

  const areActivationEmailsEnabled =
    activeOrganisation?.emailConfig?.activationEmails

  const { costCenters, offices, rentCategoryTiers } = useMemo(() => {
    const offices =
      cloneDeep(activeOrganisation?.offices?.offices)
        ?.sort((a, b) => {
          if (a.locationName && b.locationName) {
            return a.locationName.localeCompare(b.locationName)
          }

          return 0
        })
        .map(office => ({
          label: office.locationName ?? '',
          value: office.id.toString(),
        })) ?? []

    const costCenters =
      cloneDeep(activeOrganisation?.costCenters?.costCenters)
        ?.sort((a, b) => {
          if (a.name && b.name) {
            return a.name.localeCompare(b.name)
          }

          return 0
        })
        .map(costCenter => ({
          label: costCenter.name,
          value: costCenter.id.toString(),
        })) ?? []

    const rentCategoryTiers =
      cloneDeep(activeOrganisation?.rentCategoryTiers?.rentCategoryTiers)
        ?.sort((a, b) => {
          if (a.displaySortOrder > b.displaySortOrder) {
            return 1
          }

          if (a.displaySortOrder < b.displaySortOrder) {
            return -1
          }

          return 0
        })
        .reduce((result, tier) => {
          if (tier.id !== null) {
            result.push({
              label: tier.name,
              value: tier.id.toString(),
            })
          }

          return result
        }, [] as SelectOption[]) ?? []

    return { costCenters, offices, rentCategoryTiers }
  }, [activeOrganisation])

  const noCostCentersMessage = useCallback(
    () => <FormattedMessage id="CostCenterSelect_NoResults" />,
    [],
  )

  const handleSubmit = formMethods.handleSubmit(data => {
    const newEmail = data.email?.trim().toLowerCase() || null
    const existingEmail = employee?.contact?.email?.trim().toLowerCase() || null
    const hasEmailChanged = newEmail !== existingEmail

    if (isExistingEmployee && hasEmailChanged && !showEmailConfirmation) {
      setShowEmailConfirmation(true)
    } else {
      setShowEmailConfirmation(false)

      // if newCostCenter is provided, reset costCenterID
      data.costCenterID = !data.newCostCenter ? data.costCenterID : null

      onSubmit(data)
    }
  })

  const renderContent = useMemo(() => {
    if (showEmailConfirmation) {
      return (
        <div
          data-e2e={E2E_EDIT_EMPLOYEE_FORM_CONFIRMATION_CONTENT}
          data-testid={EDIT_EMPLOYEE_FORM_CONFIRMATION_CONTENT}
        >
          <EditEmployeeFormModalEmailConfirmation
            existingEmail={employee?.contact.email ?? ''}
            newEmail={email ?? ''}
          />
        </div>
      )
    }

    return (
      <div
        data-e2e={E2E_EDIT_EMPLOYEE_FORM_EDIT_CONTENT}
        data-testid={EDIT_EMPLOYEE_FORM_EDIT_CONTENT}
      >
        {submitError && <AlertWithError error={submitError} />}

        <Field
          autoComplete="off"
          e2eSelector={E2E_EDIT_EMPLOYEE_FORM_FIRST_NAME}
          feedbackTranslationValues={{
            amount: EMPLOYEE_FIRST_NAME_MAX_CHARACTERS,
          }}
          label="First_name"
          markAsRequired
          name="firstName"
          type={FieldTypeEnum.text}
        />

        <Field
          autoComplete="off"
          containerClassName="mt-2"
          e2eSelector={E2E_EDIT_EMPLOYEE_FORM_LAST_NAME}
          feedbackTranslationValues={{
            amount: EMPLOYEE_LAST_NAME_MAX_CHARACTERS,
          }}
          label="Last_name"
          name="lastName"
          type={FieldTypeEnum.text}
        />

        <Field
          autoComplete="off"
          containerClassName="mt-2"
          e2eSelector={E2E_EDIT_EMPLOYEE_FORM_EMAIL}
          label="Email"
          name="email"
          type={FieldTypeEnum.email}
        />

        <Field
          containerClassName="mt-2"
          e2eSelector={E2E_EDIT_EMPLOYEE_FORM_PHONE_NUMBER}
          label="Phone_number"
          name="phoneNumber"
          phoneInputProps={{
            containerClass: 'InputPhone',
            country: 'de',
            enableLongNumbers: true,
            preferredCountries: ['de'],
          }}
          type={FieldTypeEnum.phoneNumber}
        />

        <Field
          autoComplete="off"
          containerClassName="mt-2"
          e2eSelector={E2E_EDIT_EMPLOYEE_FORM_EMPLOYEE_ID}
          inputProps={{
            allowUnderscoreCharacter: true,
          }}
          label="Employee_id"
          name="externalReference"
          type={FieldTypeEnum.text}
        />

        {!!offices?.length && (
          <Field
            containerClassName="mt-2"
            e2eSelector={E2E_EDIT_EMPLOYEE_FORM_OFFICE}
            label="Office"
            name="officeID"
            selectProps={{
              isClearable: true,
              options: offices,
            }}
            type={FieldTypeEnum.select}
          />
        )}

        <Field
          containerClassName="mt-2"
          e2eSelector={E2E_EDIT_EMPLOYEE_FORM_COST_CENTER}
          errorMessage={newCostCenterErrorMessage}
          feedbackTranslationValues={{
            amount: EMPLOYEE_COST_CENTER_NAME_MAX_CHARACTERS,
          }}
          label="Costcenter"
          name="costCenterID"
          selectProps={{
            isClearable: true,
            noOptionsMessage: noCostCentersMessage,
            onChange: (selected, actionMeta) => {
              if (actionMeta.action === 'create-option') {
                formMethods.setValue(
                  'newCostCenter',
                  actionMeta.option.value.toString(),
                )
              } else {
                formMethods.setValue('newCostCenter', null)
                formMethods.clearErrors('newCostCenter')
              }

              return selected
            },
            options: costCenters,
          }}
          type={FieldTypeEnum.selectCreatable}
        />

        {!!rentCategoryTiers?.length && (
          <Field
            containerClassName="mt-2"
            e2eSelector={E2E_EDIT_EMPLOYEE_FORM_RENT_CATEGORY_TIER}
            label="RentCategoryTier"
            name="rentCategoryTierID"
            selectProps={{
              isClearable: true,
              options: rentCategoryTiers,
            }}
            type={FieldTypeEnum.select}
          />
        )}

        {!isExistingEmployee && (
          <Field
            containerClassName="mt-4"
            e2eSelector={E2E_EDIT_EMPLOYEE_FORM_ALLOW_LOGIN}
            label={
              areActivationEmailsEnabled
                ? 'PutEmployeeModal_allow_login_send_email'
                : 'PutEmployeeModal_allow_login'
            }
            name="allowLogin"
            type="checkbox"
          />
        )}
      </div>
    )
  }, [
    areActivationEmailsEnabled,
    costCenters,
    email,
    employee,
    formMethods,
    isExistingEmployee,
    newCostCenterErrorMessage,
    noCostCentersMessage,
    offices,
    rentCategoryTiers,
    showEmailConfirmation,
    submitError,
  ])

  return (
    <CardBody>
      <FormProvider {...formMethods}>
        <Form data-testid="employeeForm" onSubmit={handleSubmit}>
          {renderContent}

          <ButtonGroup
            leftButton={{
              'data-e2e': E2E_EDIT_EMPLOYEE_FORM_BACK_BUTTON,
              'data-testid': EDIT_EMPLOYEE_FORM_CONFIRMATION_BACK_BUTTON,
              onClick: () => {
                if (!showEmailConfirmation) {
                  onHide()

                  return
                }

                setShowEmailConfirmation(false)
              },
              text: intl.formatMessage({
                id: !showEmailConfirmation ? 'Cancel' : 'Back',
              }),
            }}
            rightButton={{
              'data-e2e': E2E_EDIT_EMPLOYEE_FORM_SUBMIT_BUTTON,
              disabled: isSubmitting,
              text: intl.formatMessage({
                id: employee
                  ? 'PutEmployeeModal_save_changes'
                  : 'Create_employee',
              }),
              type: 'submit',
            }}
          />
        </Form>
      </FormProvider>
    </CardBody>
  )
}
