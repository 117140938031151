import { gql } from '@apollo/client'

export const GET_ORDER_TERMS = gql`
  query getOrderTerms($orderTermsInput: orderTermsInput) {
    getOrderTerms(input: $orderTermsInput) {
      OrderTerms {
        locale
        OrderTerm {
          organisation
          productOffer
          rentCategory
        }
      }
    }
  }
`
