import { GlobalRouteTypesEnum } from 'Router/routeConfig.types'
import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { useStore } from 'Stores/index'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import { useIsOrganisation } from '../useIsOrganisation/useIsOrganisation'

/**
 * This hooks returns current base url (ie: `/organisation/61` or `/employee/123`)
 * and its router path (`/organisation/:organisationID` or `/employee/:employeeId`)
 * depending if you are on employee or organisation
 *
 * Please wrap component with the `observer` method from 'react-mobx' for the hook to re-sync data properly.
 */
export function useBasePageUrl() {
  const { navigationStore } = useStore()
  const { mainNavigationConfig } = navigationStore
  const isOrganisation = useIsOrganisation()

  if (isOrganisation) {
    return {
      basePageUrl: composePath({
        params: {
          organisationId: mainNavigationConfig.id,
        },
        path: GLOBAL_ROUTE_PATHS.organisation.dashboard,
      }),
      basePath: GlobalRouteTypesEnum.ORGANISATION,
    }
  }

  return {
    basePageUrl: composePath({
      params: {
        employeeId: mainNavigationConfig.id,
      },
      path: GLOBAL_ROUTE_PATHS.employee.dashboard,
    }),
    basePath: GlobalRouteTypesEnum.EMPLOYEE,
  }
}
