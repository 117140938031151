import { EmployeesRoutePathsEnum } from 'Modules/employees/routes/employeesRouteConfig.types'

import { RootStore, store } from 'Stores/index'

import { PortalSectionEnum } from 'Constants/portalSection'

import { OrderCatchAll } from '../pages/OrderCatchAll/OrderCatchAll'
import { OrderConfirmation } from '../pages/OrderConfirmation/OrderConfirmation.page'
import { OrderEarlyUpgrade } from '../pages/OrderEarlyUpgrade/OrderEarlyUpgrade.page'
import { OrderLostReplacement } from '../pages/OrderLostReplacement/OrderLostReplacement.page'
import { OrderNew } from '../pages/OrderNew/OrderNew.page'
import { OrderProlong } from '../pages/OrderProlong/OrderProlong.page'
import { OrderRepairUpgrade } from '../pages/OrderRepairUpgrade/OrderRepairUpgrade.page'
import { OrderUpgrade } from '../pages/OrderUpgrade/OrderUpgrade.page'
import { ProlongConfirmation } from '../pages/ProlongConfirmation/ProlongConfirmation.page'
import { OrderRoutePathsEnum } from './orderRouteConfig.types'

export const ORDER_ROUTES = [
  {
    component: OrderNew,
    isAllowed: (store: RootStore) => {
      const { portalConfigStore, employeeStore, organisationStore } = store
      const { employee } = employeeStore
      const { organisation } = organisationStore
      const { portalConfig } = portalConfigStore

      const isOrderNewEnabledEmployee = employee?.canOrderNewDevice
      const isOrderNewEnabledOrganisation = portalConfig?.newDevice

      return !!(
        (employee?.id && isOrderNewEnabledEmployee) ||
        (organisation?.id && isOrderNewEnabledOrganisation)
      )
    },
    onRender: () => {
      const { portalSettingsStore } = store
      const { setCurrentPortalSection } = portalSettingsStore

      setCurrentPortalSection(PortalSectionEnum.Rentals)
    },
    path: `${OrderRoutePathsEnum.ORDER_NEW}`,
  },
  {
    component: OrderUpgrade,
    onRender: () => {
      const { portalSettingsStore } = store
      const { setCurrentPortalSection } = portalSettingsStore

      setCurrentPortalSection(PortalSectionEnum.Rentals)
    },
    path: `${OrderRoutePathsEnum.ORDER_UPGRADE}`,
  },
  {
    component: OrderEarlyUpgrade,
    onRender: () => {
      const { portalSettingsStore } = store
      const { setCurrentPortalSection } = portalSettingsStore

      setCurrentPortalSection(PortalSectionEnum.Rentals)
    },
    path: `${OrderRoutePathsEnum.ORDER_EARLY_UPGRADE}`,
  },
  {
    component: OrderProlong,
    onRender: () => {
      const { portalSettingsStore } = store
      const { setCurrentPortalSection } = portalSettingsStore

      setCurrentPortalSection(PortalSectionEnum.Rentals)
    },
    path: `${OrderRoutePathsEnum.ORDER_PROLONG}`,
  },
  {
    component: OrderConfirmation,
    path: `${OrderRoutePathsEnum.ORDER_CONFIRMATION}`,
  },
  {
    component: OrderConfirmation,
    path: `${OrderRoutePathsEnum.ORDER_CUSTOM_CONFIRMATION}`,
  },
  {
    component: ProlongConfirmation,
    path: `${OrderRoutePathsEnum.ORDER_PROLONG_CONFIRMATION}`,
  },
  {
    component: OrderRepairUpgrade,
    onRender: () => {
      const { portalSettingsStore } = store
      const { setCurrentPortalSection } = portalSettingsStore

      setCurrentPortalSection(PortalSectionEnum.Rentals)
    },
    path: `${OrderRoutePathsEnum.ORDER_REPAIR_UPGRADE}`,
  },
  {
    component: OrderLostReplacement,
    onRender: () => {
      const { portalSettingsStore } = store
      const { setCurrentPortalSection } = portalSettingsStore

      setCurrentPortalSection(PortalSectionEnum.Rentals)
    },
    path: `${OrderRoutePathsEnum.ORDER_LOST_REPLACEMENT}`,
  },
  {
    component: OrderCatchAll,
    path: `${OrderRoutePathsEnum.ORDER_CATCH_ALL}`,
  },
]

export const ORDER_EMPLOYEE_ROUTES = ORDER_ROUTES.map(route => ({
  ...route,
  path: `${EmployeesRoutePathsEnum.EMPLOYEE}/${route.path}`,
}))
