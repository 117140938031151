import { z } from 'zod'

import { zSelect } from 'Lib/zod'

import { FORM_MESSAGES_MAP } from 'Constants/formMessages'

import { SHIPPING_ADDRESS_FORM_ZIPCODE_MIN_CHARACTERS } from './ShippingAddressForm.constants'

const baseShippingAddressFormSchema = z.object({
  _label: z.string(),
  addressType: z.string(),
  city: z.string().min(1, FORM_MESSAGES_MAP.REQUIRED),
  country: zSelect.refine(arg => !!arg?.value, FORM_MESSAGES_MAP.REQUIRED),
  name: z.string().min(1, FORM_MESSAGES_MAP.REQUIRED),
  nameAddition: z.string(),
  zipcode: z
    .string()
    .min(
      SHIPPING_ADDRESS_FORM_ZIPCODE_MIN_CHARACTERS,
      FORM_MESSAGES_MAP.MIN_LENGTH,
    ),
})

export const officeShippingAddressFormSchema =
  baseShippingAddressFormSchema.merge(
    z.object({
      nameAddition: z.string().min(1, FORM_MESSAGES_MAP.REQUIRED),
    }),
  )

export const shippingAddressFormSchema = baseShippingAddressFormSchema.merge(
  z.object({
    street: z.string().min(1, FORM_MESSAGES_MAP.REQUIRED),
    streetAddition: z.string(),
    streetNumber: z.string().min(1, FORM_MESSAGES_MAP.REQUIRED),
  }),
)

export const usShippingAddressFormSchema = baseShippingAddressFormSchema.merge(
  z.object({
    state: zSelect.refine(arg => !!arg?.value, FORM_MESSAGES_MAP.REQUIRED),
    usAddress: z.string().min(1, FORM_MESSAGES_MAP.REQUIRED),
  }),
)

// all vendingMachine fields need to be optional
export const vendingMachineShippingAddressFormSchema =
  baseShippingAddressFormSchema.merge(
    z.object({
      _vendingMachineId: z.string(),
      city: z.string(),
      country: zSelect,
      name: z.string(),
      nameAddition: z.string(),
      street: z.string(),
      streetNumber: z.string(),
      zipcode: z.string(),
    }),
  )

// FLOW:
// 1. admin can edit all office address fields
// 2. employee can edit only nameAddition, other fields are disabled
export const employeeShippingAddressFormSchema = baseShippingAddressFormSchema
  .merge(
    z.object({
      city: z.string(),
      country: zSelect,
      name: z.string(),
      state: zSelect,
      street: z.string(),
      streetAddition: z.string(),
      streetNumber: z.string(),
      usAddress: z.string(),
      zipcode: z.string(),
    }),
  )
  .merge(officeShippingAddressFormSchema)
