import { forwardRef, useCallback } from 'react'
import { Image } from 'react-bootstrap'
import { useIntl } from 'react-intl'

import { getPlaceholderImage } from 'Utilities'

import { ImagePlaceholderTypesEnum } from 'Portal/src/types/imagePlaceholders.type'

import { ImageWithFallbackProps } from './ImageWithFallback.types'

export const ImageWithFallback = forwardRef(
  (
    props: ImageWithFallbackProps,
    ref: React.ForwardedRef<HTMLImageElement>,
  ) => {
    const { placeholderType, src, alt, ...rest } = props

    const intl = useIntl()

    const placeholderImage = getPlaceholderImage(placeholderType)
    const imageSource = src || placeholderImage
    const altText =
      alt ||
      intl.formatMessage({
        id:
          placeholderType === ImagePlaceholderTypesEnum.requests
            ? 'Img_alt_list_message'
            : placeholderType,
      })

    const handleImageError = useCallback(
      (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        event.currentTarget.onerror = null
        event.currentTarget.src = placeholderImage
      },
      [placeholderImage],
    )

    return (
      <Image
        ref={ref}
        alt={altText}
        src={imageSource}
        {...rest}
        onError={handleImageError}
      />
    )
  },
)
