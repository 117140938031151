import { z } from 'zod'

import { FORM_OPTIONAL_MESSAGE_MAX_CHARACTERS } from 'Components/FormOptionalMessage/FormOptionalMessage.constants'

import { zSelect } from 'Lib/zod'

import { FORM_MESSAGES_MAP } from 'Constants/formMessages'

export const exchangeDeviceModalFormSchema = z.object({
  depot: zSelect.refine(arg => !!arg?.value, FORM_MESSAGES_MAP.REQUIRED),
  depotStocklistItem: zSelect.refine(
    arg => !!arg?.value,
    FORM_MESSAGES_MAP.REQUIRED,
  ),
  message: z
    .string()
    .max(FORM_OPTIONAL_MESSAGE_MAX_CHARACTERS, FORM_MESSAGES_MAP.MAX_LENGTH),
})
