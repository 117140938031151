import { FormattedMessage, useIntl } from 'react-intl'
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons'

import { Alert, ButtonGroup, CardBody } from 'Components/_theme'
import { ModalDevicePresentationBlock } from 'Components/ModalDevicePresentationBlock/ModalDevicePresentationBlock'
import { ModalErrorState } from 'Components/ModalErrorState/ModalErrorState'

import { countryISOCodes } from 'Constants/constants'

import { ModalSummaryProps } from './ModalSummary.types'

export const ModalSummary = (props: ModalSummaryProps) => {
  const {
    formId,
    isEverphoneReplacement,
    isLoading,
    rental,
    shippingFormData,
    leftButtonE2ESelector,
    rightButtonE2ESelector,
    submitError,
    showAlert,
    onSubmit,
    onPreviousStep,
    heading,
  } = props
  const intl = useIntl()

  const hasError = !!submitError
  const isUnitedStatesAddressType =
    shippingFormData?.country?.value === countryISOCodes.US

  return (
    <>
      {heading}

      <CardBody>
        {!hasError ? (
          <>
            {showAlert && (
              <Alert iconLeft={faCircleInfo} variant="info">
                <FormattedMessage id="LostOrStolenModal_followInternalProcesses" />
              </Alert>
            )}

            <ModalDevicePresentationBlock className="mt-6" rental={rental}>
              {shippingFormData && (
                <>
                  <h6>
                    <FormattedMessage id="Shipping_address" />
                  </h6>

                  <p>
                    {shippingFormData.name}
                    <br />
                    {shippingFormData.nameAddition}
                    <br />
                    {isUnitedStatesAddressType
                      ? shippingFormData.usAddress
                      : `${shippingFormData.street} / ${shippingFormData.streetNumber}`}
                    <br />
                    {shippingFormData.zipcode} /{' '}
                    {isUnitedStatesAddressType
                      ? `${shippingFormData.city}, ${shippingFormData.state}`
                      : shippingFormData.city}
                    <br />
                    {shippingFormData.country?.label}
                  </p>
                </>
              )}
            </ModalDevicePresentationBlock>

            {isEverphoneReplacement && (
              <p className="small">
                <FormattedMessage id="ExchangeDeviceModal_shipping_label_info" />
              </p>
            )}
          </>
        ) : (
          <ModalErrorState error={submitError} />
        )}

        <ButtonGroup
          leftButton={{
            'data-e2e': leftButtonE2ESelector,
            onClick: onPreviousStep,
            text: intl.formatMessage({ id: 'Back' }),
          }}
          rightButton={
            !hasError
              ? {
                  'data-e2e': rightButtonE2ESelector,
                  disabled: isLoading,
                  form: formId,
                  onClick: onSubmit,
                  text: intl.formatMessage({ id: 'Submit' }),
                  type: 'submit',
                }
              : undefined
          }
        />
      </CardBody>
    </>
  )
}
