import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { observer } from 'mobx-react'

import {
  shouldShowTrackingReference,
  shouldShowTrackingURL,
} from 'Modules/orders/pages/Order/Order.utils'

import { Card, CardHeader } from 'Components/_theme'

import { CarrierAndTrackingId } from '../CarrierAndTrackingId/CarrierAndTrackingId'
import { ReturnShipmentTrackingCardProps } from '../ShipmentTrackingCard.types'

export const ReturnShipmentTrackingCard = observer(
  (props: ReturnShipmentTrackingCardProps) => {
    const { returnCase } = props

    const showTrackingLink = shouldShowTrackingURL(
      returnCase.latestShippingInfo?.trackingURL,
      returnCase.latestShippingInfo?.createdAt,
    )
    const showTrackingReference = shouldShowTrackingReference(
      returnCase.latestShippingInfo?.trackingReference,
    )

    const renderCarrierAndTrackingId = useMemo(() => {
      if (
        !returnCase.latestShippingInfo?.trackingReference ||
        (!showTrackingLink && !showTrackingReference)
      ) {
        return null
      }

      return (
        <CarrierAndTrackingId
          serviceProvider={returnCase.latestShippingInfo.carrier}
          showTrackingLink={showTrackingLink}
          showTrackingReference={showTrackingReference}
          trackingReference={returnCase.latestShippingInfo.trackingReference}
          trackingURL={returnCase.latestShippingInfo.trackingURL}
        />
      )
    }, [returnCase, showTrackingLink, showTrackingReference])

    if (!renderCarrierAndTrackingId) {
      return null
    }

    return (
      <Card className="ShipmentTrackingCard mb-6">
        <CardHeader>
          <FormattedMessage id="ShipmentTrackingCard_track_and_trace" />
        </CardHeader>

        <div className="ShipmentTrackingCard-body">
          {renderCarrierAndTrackingId}
        </div>
      </Card>
    )
  },
)
