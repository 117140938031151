import { clsx } from 'clsx'
import { observer } from 'mobx-react'

import { FooterMenuItem } from 'Components/FooterMenuItem/FooterMenuItem'

import { useFooterMenuItems, useIsServiceApp } from 'Hooks'

import ECOVADIS_LOGO_SRC from 'Assets/images/logos/ecovadis_logo.svg'

import { FooterProps } from './Footer.types'

export const Footer = observer((props: FooterProps) => {
  const { className, variant = 'dark', onLogin } = props

  const isServiceApp = useIsServiceApp()

  const { footerMenuItems } = useFooterMenuItems()

  const isLight = variant === 'light'
  const classNames = clsx(
    'Footer',
    isLight && 'Footer-light',
    onLogin && 'Footer-login',
    className,
  )

  if (isServiceApp) {
    return null
  }

  return (
    <footer className={classNames} role="contentinfo">
      <div>
        <span className="h6">{new Date().getFullYear()} © Everphone</span>
      </div>

      <ul className="Footer-links">
        {footerMenuItems.map(menuItem => (
          <li key={menuItem.id}>
            <FooterMenuItem
              item={menuItem}
              variant={isLight ? 'light' : 'dark'}
            />
          </li>
        ))}
      </ul>

      <img
        alt="ecovadis 2023 Silver Sustainability Rating"
        className="Footer-image"
        height={40}
        src={ECOVADIS_LOGO_SRC}
      />
    </footer>
  )
})
