import { RefObject } from 'react'

export const setContainerWidth = (
  destinationRef: RefObject<HTMLDivElement>,
  sourceRef?: RefObject<HTMLDivElement>,
  isDesktop?: boolean,
) => {
  if (!sourceRef) {
    return
  }

  const { current: destination } = destinationRef
  const { current: source } = sourceRef

  if (!destination || !source || !isDesktop) {
    destination?.removeAttribute('style')

    return
  }

  const { width } = source.getBoundingClientRect()

  destination.style.width = `${width}px`
}
