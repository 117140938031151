import { useCallback, useEffect, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { Field } from 'Components/Field/Field'
import { FieldTypeEnum } from 'Components/Field/Field.types'

import { useStore } from 'Stores/index'

import { useActiveUser, useCountryList } from 'Portal/src/hooks'

import { ShippingAddressFormAddressTypesProps } from './ShippingAddressForm.types'
import {
  getOrderAddressConfigFlags,
  parseAddressTypeRadioButtons,
} from './ShippingAddressForm.utils'

export const ShippingFormAddressTypes = (
  props: ShippingAddressFormAddressTypesProps,
) => {
  const { rental, hasDefaultValues, e2eSelector, customAddressTypesConfig } =
    props
  const { portalConfigStore, portalSettingsStore } = useStore()
  const { portalConfig } = portalConfigStore
  const { userMode } = portalSettingsStore
  const { activeOrganisation } = useActiveUser()
  const { control, setValue, clearErrors } = useFormContext()
  const intl = useIntl()
  const isOrderFlow = !!customAddressTypesConfig?.length
  const { canShipToCustomAddress, canShipToOffice, defaultIsCustomAddress } =
    getOrderAddressConfigFlags(userMode, portalConfig)
  const countryList = useCountryList()

  const watchAddressType = useWatch({
    control,
    name: 'addressType',
  })

  const addressTypesConfig = useMemo(() => {
    if (customAddressTypesConfig) {
      return customAddressTypesConfig
    }

    return parseAddressTypeRadioButtons(
      intl,
      portalConfig,
      activeOrganisation,
      countryList,
      rental,
    )
  }, [
    activeOrganisation,
    countryList,
    customAddressTypesConfig,
    intl,
    portalConfig,
    rental,
  ])

  const getDefaultSelectionIndex = useCallback(() => {
    // office address is at index 0
    // custom address is at index 1
    if (canShipToOffice && canShipToCustomAddress && defaultIsCustomAddress) {
      return 1
    }

    return 0
  }, [canShipToCustomAddress, canShipToOffice, defaultIsCustomAddress])

  // set intial address type after it is generated
  // skip if form has defaultValues
  useEffect(() => {
    if (hasDefaultValues) {
      return
    }

    const defaultIndex = isOrderFlow ? getDefaultSelectionIndex() : 0

    setValue('addressType', addressTypesConfig[defaultIndex].addressType)
    setValue('_label', addressTypesConfig[defaultIndex]._label)
    setValue(
      '_vendingMachineId',
      addressTypesConfig[defaultIndex]._vendingMachineId,
    )
  }, [
    addressTypesConfig,
    getDefaultSelectionIndex,
    hasDefaultValues,
    isOrderFlow,
    setValue,
  ])

  // on selecting address type prefill form with data
  useEffect(() => {
    clearErrors()

    const fieldsToReset = addressTypesConfig.find(
      addressTypeConfig => addressTypeConfig.addressType === watchAddressType,
    )

    if (!fieldsToReset) {
      return
    }

    const { addressType: _addressType, ...fields } = fieldsToReset

    for (const [key, value] of Object.entries(fields)) {
      setValue(key, value)
    }

    // customAddressTypesConfig incorrectly triggers rerender when
    // addressTypesConfig is set as dependency
    // causes the form to reset on order flow overwriting default values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearErrors, setValue, watchAddressType])

  return (
    <div className="mt-6">
      {addressTypesConfig.map(addressTypeConfig => (
        <Field
          key={addressTypeConfig.addressType}
          containerClassName="mt-1"
          e2eSelector={e2eSelector}
          label={addressTypeConfig._label}
          name="addressType"
          shouldTranslateLabel={false}
          type={FieldTypeEnum.radio}
          value={addressTypeConfig.addressType}
        />
      ))}
    </div>
  )
}
