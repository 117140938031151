import { getDepotDetails_depot_stocklistItems_stocklistItems } from 'Constants/graphql/queries/detail/__generated__/getDepotDetails'

import {
  GroupedStocklistItemsWithUrl,
  StocklistItemWithUrl,
} from './Depot.types'

export const groupStocklistItems = (stocklistItems: StocklistItemWithUrl[]) => {
  const results = stocklistItems.reduce<
    Map<string, GroupedStocklistItemsWithUrl>
  >(
    (
      result,
      stocklistItems: getDepotDetails_depot_stocklistItems_stocklistItems,
    ) => {
      // id will always exist, this is just to make typescript happy
      if (!stocklistItems.deviceDefinition?.id) {
        return result.set('0', {
          ...stocklistItems,
          imeis: [],
          numOfDevices: 0,
        })
      }

      const summary =
        result.get(stocklistItems.deviceDefinition.id) ||
        ({
          ...stocklistItems,
          numOfDevices: 0,
        } as GroupedStocklistItemsWithUrl)

      summary.numOfDevices += 1

      if (!summary.imeis) {
        summary.imeis = []

        if (stocklistItems.imei || stocklistItems.serialNumber) {
          summary.imeis = [
            {
              imei: stocklistItems.imei ?? '',
              serialNumber: stocklistItems.serialNumber,
            },
          ]
        }
      } else if (stocklistItems.imei || stocklistItems.serialNumber) {
        summary.imeis.push({
          imei: stocklistItems.imei ?? '',
          serialNumber: stocklistItems.serialNumber,
        })
      }

      return result.set(stocklistItems.deviceDefinition.id, summary)
    },
    new Map(),
  )

  return Array.from(results.values())
}
