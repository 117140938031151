import { useMemo } from 'react'

import { CardDetailsListItem } from 'Components/CardDetailsList/CardDetailsList.types'
import { FormattedCurrency } from 'Components/FormattedCurrency/FormattedCurrency'
import { OrderStatusBadge } from 'Components/OrderStatusBadge/OrderStatusBadge'
import { PresentationCard } from 'Components/PresentationCard/PresentationCard'

import { DeviceTypeEnum } from 'Types/deviceTypes'

import { OrderPresentationCardProps } from './OrderPresentationCard.types'

export const OrderPresentationCard = (props: OrderPresentationCardProps) => {
  const {
    status,
    requestedRentCategory,
    requestedOfferOrder,
    deviceDefinition,
    customOrderDeviceName,
  } = props

  const generatePresentationCardListItems = useMemo(
    (): CardDetailsListItem[] => [
      {
        label: 'RequestDetails_rent_category',
        value: requestedRentCategory?.rentCategory?.name,
      },
      {
        label: 'RequestDetails_monthly_copayment',
        value: (
          <FormattedCurrency
            value={
              requestedRentCategory?.rentCategory?.copaymentNet ??
              requestedOfferOrder?.copaymentPrice ??
              0
            }
          />
        ),
      },
    ],
    [
      requestedRentCategory?.rentCategory?.name,
      requestedRentCategory?.rentCategory?.copaymentNet,
      requestedOfferOrder?.copaymentPrice,
    ],
  )

  const deviceData = deviceDefinition || requestedRentCategory?.deviceDefinition

  if (!deviceData) {
    return null
  }

  const header = customOrderDeviceName || deviceData.name

  return (
    <PresentationCard
      header={header}
      imagePlaceholderType={deviceData.productType as DeviceTypeEnum}
      imageSource={deviceData.imageURL}
      listItems={generatePresentationCardListItems}
      rightContent={<OrderStatusBadge status={status} />}
      title="RequestDetails_device"
    />
  )
}
