export enum CheckboxButtonSizeEnum {
  small = 'small',
  medium = 'medium',
}

export type CheckboxButtonProps = {
  id: string
  label: string
  name: string
  onChange: (event: React.ChangeEvent<HTMLInputElement> | null) => void
  checked: boolean
  disabled?: boolean
  isInvalid?: boolean
  size?: `${CheckboxButtonSizeEnum}`
}
