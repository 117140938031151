import { z } from 'zod'

import { FORM_OPTIONAL_MESSAGE_MAX_CHARACTERS } from 'Components/FormOptionalMessage/FormOptionalMessage.constants'

import { FORM_MESSAGES_MAP } from 'Constants/formMessages'

export const requestPurchaseModalLandingSchema = z.object({
  hasIssues: z.boolean(),
})

export const requestPurchaseModalSummarySchema = z.object({
  email: z
    .string()
    .email(FORM_MESSAGES_MAP.EMAIL)
    .min(1, FORM_MESSAGES_MAP.REQUIRED),
  message: z
    .string()
    .max(FORM_OPTIONAL_MESSAGE_MAX_CHARACTERS, FORM_MESSAGES_MAP.MAX_LENGTH),
})
