import { useMemo } from 'react'
import { Pie } from 'react-chartjs-2'
import { useIntl } from 'react-intl'
import { ChartConfiguration } from 'chart.js'
import { observer } from 'mobx-react'

import { useStore } from 'Stores/index'

import { CHART_COLORS, CHART_FONT } from 'Constants/charts'

import { FleetOverviewChartProps } from './FleetOverviewChart.types'
import { deviceCountCardChartLegendPlugin } from './FleetOverviewChart.util'

export const FleetOverviewChart = observer((props: FleetOverviewChartProps) => {
  const { data, labels } = props
  const { portalSettingsStore } = useStore()
  const { i18n } = portalSettingsStore
  const intl = useIntl()

  const chartConfig: ChartConfiguration<'pie'> = {
    data: {
      datasets: [
        {
          backgroundColor: CHART_COLORS,
          data,
          label: intl.formatMessage({ id: 'Total' }),
        },
      ],
      labels,
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        htmlLegend: {
          containerID: 'deviceCountCardLegend',
        },
        legend: {
          display: false,
        },
        tooltip: {
          bodyFont: CHART_FONT,
          titleFont: CHART_FONT,
        },
      },
      responsive: true,
    },
    plugins: [deviceCountCardChartLegendPlugin(i18n)],
    type: 'pie',
  }

  const ariaLabel = useMemo(
    () =>
      `${intl.formatMessage({ id: 'DashboardFleetOverviewCard_header' })}:${labels.map(
        (item, index) =>
          ` ${item} ${intl.formatMessage({ id: 'Total' })} ${data[index]}`,
      )}`,
    [data, intl, labels],
  )

  return (
    <div className="d-flex flex-column flex-lg-row">
      <div className="DashboardFleetOverviewCard-chart">
        <Pie {...chartConfig} aria-label={ariaLabel} />
      </div>

      <div
        className="DashboardFleetOverviewCard-legend"
        id="deviceCountCardLegend"
      />
    </div>
  )
})
