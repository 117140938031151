import { clsx } from 'clsx'

import { Icon } from 'Components/Icon/Icon'

import { Card } from '../Card/Card'
import { CardBody } from '../CardBody/CardBody'
import { CardHeader } from '../CardHeader/CardHeader'
import { WidgetProps } from './Widget.types'

export const Widget = (props: WidgetProps) => {
  const {
    children,
    content,
    className,
    title,
    alt,
    imageSrc,
    primaryIcon,
    secondaryIcon,
    rightContent,
  } = props

  const classNames = clsx('Widget', className)

  return (
    <Card className={classNames}>
      <CardHeader
        icon={primaryIcon}
        rightContent={
          rightContent ?? (secondaryIcon ? <Icon icon={secondaryIcon} /> : null)
        }
      >
        {title}
      </CardHeader>

      <CardBody>
        <div className="Widget-container">
          <div className="Widget-content">{content}</div>

          <div className="Widget-image-container">
            <img
              alt={alt || title}
              className={clsx('Widget-image', title)}
              src={imageSrc}
            />
          </div>
        </div>

        {children}
      </CardBody>
    </Card>
  )
}
