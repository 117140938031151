import { useCallback, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { NetworkStatus } from '@apollo/client'
import { observer } from 'mobx-react'

import { EditEmployeeFormModal } from 'Modules/employees/components/EditEmployeeFormModal/EditEmployeeFormModal'
import { EmployeesPreviewColumn } from 'Modules/employees/components/EmployeesPreviewColumn/EmployeesPreviewColumn'
import { EmployeesTable } from 'Modules/employees/components/EmployeesTable/EmployeesTable'

import { Card } from 'Components/_theme'
import { ContentSidebar } from 'Components/ContentSidebar/ContentSidebar'
import { FaqCard } from 'Components/FaqCard/FaqCard'
import { ImpactTracker } from 'Components/ImpactTracker/ImpactTracker'
import { ListSearch } from 'Components/ListSearch/ListSearch'
import { ObserveMeOnceIAmMounted } from 'Components/ObserveMeOnceIAmMounted/ObserveMeOnceIAmMounted'
import { PageErrorState } from 'Components/PageErrorState/PageErrorState'
import { PageHeader } from 'Components/PageHeader/PageHeader'
import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'

import { useStore } from 'Stores/index'

import { useDefaultListTable, useModal, useSidebarVisibility } from 'Hooks'

import { toggleIntercomVisibility } from 'Utilities'

import { E2E_EMPLOYEES_PAGE_NEW_EMPLOYEE } from 'Constants/e2e'
import {
  getOrganisationEmployees,
  getOrganisationEmployeesVariables,
} from 'Constants/graphql/queries/listing/__generated__/getOrganisationEmployees'
import { GET_ORGANISATION_EMPLOYEES } from 'Constants/graphql/queries/listing/GetEmployees'
import { PortalSectionEnum } from 'Constants/portalSection'

import { ReportType } from 'Portal/__generated__/globalTypes'

export const EmployeesDefault = observer(() => {
  const { portalConfigStore, organisationStore, portalSettingsStore } =
    useStore()
  const { portalConfig } = portalConfigStore
  const { isAppReadOnly } = portalSettingsStore
  const { fetchOrganisationCostCenters } = organisationStore

  const intl = useIntl()

  const [selectedEntityId, setSelectedEntityId] = useState('')
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null)
  const [isPreviewColumnOpen, setIsPreviewColumnOpen] = useState(false)
  const isSidebarOpen = isPreviewColumnOpen
  const { isVisible: isPreviewColumnVisible } = useSidebarVisibility(
    isPreviewColumnOpen,
    isSidebarOpen,
  )

  const {
    data,
    error,
    hasNextPage,
    intersectionObserver,
    isLoading,
    networkStatus,
    totalCount,
    refetch,
  } = useDefaultListTable<
    getOrganisationEmployees,
    getOrganisationEmployeesVariables
  >({
    dataObjectPropertyName: 'employees',
    organisationQuery: GET_ORGANISATION_EMPLOYEES,
  })

  const handleSearch = useCallback(
    (value: string) => {
      refetch({ searchterm: value || null })
    },
    [refetch],
  )

  const handleOpenPreviewColumn = useCallback(
    (id: string, rowIndex: number | null) => {
      toggleIntercomVisibility(false)

      setSelectedEntityId(id)
      setSelectedRowIndex(rowIndex)
      setIsPreviewColumnOpen(true)
    },
    [],
  )

  const handleClosePreviewColumn = useCallback(() => {
    toggleIntercomVisibility(true)

    setIsPreviewColumnOpen(false)
    setSelectedRowIndex(null)
  }, [])

  const { setModal, hideModal } = useModal()

  const handleOpenEditEmployeeModal = useCallback(() => {
    setModal({
      component: EditEmployeeFormModal,
      isOpen: true,
      props: {
        onHide: hideModal,
        onSuccess: (refetchCostCenters: boolean) => {
          refetch()

          if (refetchCostCenters) {
            fetchOrganisationCostCenters()
          }

          hideModal()
        },
      },
    })
  }, [fetchOrganisationCostCenters, hideModal, refetch, setModal])

  if (error) {
    return <PageErrorState />
  }

  if (isLoading && !data) return <PageLoadingState />
  if (!data) return <PageErrorState />

  return (
    <Container className="pt-6" fluid>
      <div role="banner">
        <PageHeader portalSection={PortalSectionEnum.Employees} />
      </div>

      <Row>
        <Col xl={12} xxl={8}>
          <Card className="mb-4">
            <ListSearch
              buttonE2ESelector={E2E_EMPLOYEES_PAGE_NEW_EMPLOYEE}
              buttonLabel={intl.formatMessage({ id: 'New_employee' })}
              buttonOnClick={handleOpenEditEmployeeModal}
              buttonVisible={portalConfig?.createEmployee && !isAppReadOnly}
              downloadReportType={ReportType.employees}
              handleSearch={handleSearch}
              totalCount={totalCount}
            />

            <Row>
              <Col>
                <EmployeesTable
                  employees={data}
                  isLoading={isLoading}
                  isSearching={networkStatus === NetworkStatus.setVariables}
                  onOpenPreviewColumn={handleOpenPreviewColumn}
                  selectedRowIndex={selectedRowIndex}
                />
              </Col>
            </Row>
          </Card>

          <Row className="p-1">
            <Col>
              {hasNextPage && !isLoading && (
                <ObserveMeOnceIAmMounted
                  intersectionObserver={intersectionObserver}
                />
              )}
            </Col>
          </Row>
        </Col>

        <ContentSidebar
          isSidebarOpen={isPreviewColumnOpen}
          renderStickyColumnSideContent={
            <>
              <ImpactTracker />
              <FaqCard />
            </>
          }
        >
          <EmployeesPreviewColumn
            entityId={selectedEntityId}
            isVisible={isPreviewColumnVisible}
            onClosePreviewColumn={handleClosePreviewColumn}
          />
        </ContentSidebar>
      </Row>
    </Container>
  )
})
