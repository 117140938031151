import { Col, Container, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { observer } from 'mobx-react'

import { OrderDetailsCard } from 'Modules/orders/components/OrderDetailsCard/OrderDetailsCard'
import { OrderPresentationCard } from 'Modules/orders/components/OrderPresentationCard/OrderPresentationCard'

import { EmployeeEntityCard } from 'Components/EmployeeEntityCard/EmployeeEntityCard'
import { PageErrorState } from 'Components/PageErrorState/PageErrorState'
import { PageHeader } from 'Components/PageHeader/PageHeader'
import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'
import { QRCodeCard } from 'Components/QRCodeCard/QRCodeCard'
import { OrderShipmentTrackingCard } from 'Components/ShipmentTrackingCard/Order/OrderShipmentTrackingCard'

import { useActiveUser } from 'Hooks'

import {
  getOrderDetails,
  getOrderDetailsVariables,
} from 'Constants/graphql/queries/detail/__generated__/getOrderDetails'
import { GET_ORDER_DETAILS } from 'Constants/graphql/queries/detail/GetOrderDetails'
import { PortalSectionEnum } from 'Constants/portalSection'

import { getCaseItemTypeTranslationId } from 'Portal/src/utilities/getCaseItemTypeTranslationId/getCaseItemTypeTranslationId.util'

export const Order = observer(() => {
  const { activeOrganisation } = useActiveUser()
  const isOrganisation = Boolean(activeOrganisation?.id)
  const { employeeId, organisationId, orderId } = useParams<{
    employeeId: string
    organisationId: string
    orderId: string
  }>()

  const {
    loading: isLoading,
    data,
    error,
  } = useQuery<getOrderDetails, getOrderDetailsVariables>(GET_ORDER_DETAILS, {
    variables: { employeeId, id: orderId.toString(), organisationId },
  })

  if (isLoading) {
    return <PageLoadingState />
  }

  if (error) {
    return <PageErrorState />
  }

  if (!data) {
    return <PageErrorState />
  }

  const { caseItem } = data
  const title = getCaseItemTypeTranslationId(caseItem.caseType)
  const QRCode = caseItem?.generatedQRCode || ''

  return (
    <Container className="pt-6" fluid>
      <div role="banner">
        <PageHeader portalSection={PortalSectionEnum.OrderDetails} />
      </div>

      <Row>
        <Col xxl={8}>
          <OrderPresentationCard
            customOrderDeviceName={caseItem.offerOrderItem?.customDeviceName}
            deviceDefinition={caseItem.data.deviceDefinition}
            requestedOfferOrder={caseItem.data.requestedOfferOrder}
            requestedRentCategory={caseItem.data.requestedRentCategory}
            status={caseItem.status}
          />

          <OrderDetailsCard caseItem={caseItem} title={title} />
        </Col>

        <Col xxl={4}>
          <Row>
            <Col lg={6} xs={12} xxl={12}>
              {QRCode && <QRCodeCard qrCode={QRCode} />}

              <OrderShipmentTrackingCard caseItem={caseItem} />
            </Col>

            <Col lg={6} xs={12} xxl={12}>
              {isOrganisation && (
                <EmployeeEntityCard
                  employee={caseItem.assignedEmployee ?? null}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
})
