import { FormattedMessage } from 'react-intl'

import { OfferSummaryHeaderProps } from 'Modules/purchase/components/OfferSummaryHeader/OfferSummaryHeader.types'

import { Text } from 'Components/_theme'

import everphoneLogoLight from 'Assets/images/logos/everphone_logo_light.svg'

export const OfferSummaryHeader = (props: OfferSummaryHeaderProps) => {
  const { total, currencySymbol } = props

  return (
    <>
      <img
        alt="Everphone Logo"
        className="OfferSummary-logo d-block mb-9"
        src={everphoneLogoLight}
      />

      <Text className="OfferSummary-leadText mb-2">
        <FormattedMessage id="OfferSummary_heading" />
      </Text>
      <Text className="OfferSummary-leadPrice mb-9">
        {total.toFixed(2)}&nbsp;{currencySymbol}
      </Text>
    </>
  )
}
