import { ChangeEvent, forwardRef } from 'react'
import { clsx } from 'clsx'

import { TEXTAREA_BLOCKED_CHARACTERS } from 'Constants/constants'

import { TEXTAREA_CONTAINER } from './Textarea.constants'
import { TextareaProps } from './Textarea.types'

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    const {
      charactersCount,
      className,
      customComponent: CustomComponent,
      disabled,
      isFilled,
      isInvalid,
      isResizable,
      maxLength,
      markAsRequired,
      showCounter,
      size = 'medium',
      onChange,
      allowBlockedCharacters,
      ...restProps
    } = props

    const classNames = clsx(
      'Textarea',
      `Textarea-${size}`,
      isInvalid && 'Textarea-invalid',
      !isFilled && markAsRequired && 'Textarea-unfilled',
      isResizable && 'Textarea-resizable',
      disabled && 'Textarea-disabled',
      className,
    )

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
      if (!onChange) {
        return undefined
      }

      if (allowBlockedCharacters) {
        return onChange(e)
      }

      e.target.value = e.target.value.replace(TEXTAREA_BLOCKED_CHARACTERS, '')

      return onChange(e)
    }

    return (
      <div className={classNames} data-testid={TEXTAREA_CONTAINER}>
        {CustomComponent ? (
          <CustomComponent
            ref={ref}
            disabled={disabled}
            maxLength={maxLength}
            {...restProps}
            onChange={handleChange}
          />
        ) : (
          <textarea
            ref={ref}
            disabled={disabled}
            maxLength={maxLength}
            {...restProps}
            onChange={handleChange}
          />
        )}

        {showCounter && maxLength && (
          <div className="Textarea-counter">{`${charactersCount} / ${maxLength}`}</div>
        )}
      </div>
    )
  },
)
