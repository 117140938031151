import clsx from 'clsx'

import {
  CheckboxButtonProps,
  CheckboxButtonSizeEnum,
} from './CheckboxButton.types'

export const CheckboxButton = (props: CheckboxButtonProps) => {
  const {
    disabled,
    id,
    isInvalid,
    label,
    name,
    onChange,
    size = CheckboxButtonSizeEnum.medium,
    checked,
  } = props

  const classNames = clsx(
    'Button',
    'TertiaryButton',
    size === 'small' ? 'Button-small' : 'Button-medium',
    checked && 'CheckboxButton-checked',
    disabled && 'CheckboxButton-disabled',
    isInvalid && 'CheckboxButton-is-invalid',
  )

  const handleKeyDown = (event: React.KeyboardEvent<HTMLImageElement>) => {
    if (event.key === 'Enter') {
      onChange(null)
    }
  }

  return (
    <div
      className="CheckboxButton"
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
    >
      <label className={classNames} htmlFor={id}>
        {label}
      </label>

      <input
        checked={checked}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        type="checkbox"
      />
    </div>
  )
}
