import { useEffect, useState } from 'react'
import {
  Redirect,
  Route as ReactRoute,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import { observer } from 'mobx-react'

import { Account } from 'Modules/account/pages/Account.page'
import { Contact, ContactLanding } from 'Modules/contact/pages'
import { EmployeeDashboardDefault } from 'Modules/dashboard/components/EmployeeDashboardDefault/EmployeeDashboardDefault'
import { DataPrivacy } from 'Modules/dataPrivacy/pages'
import {
  Offer,
  OfferAccepted,
  OfferDeclined,
  PaymentFailed,
  PurchaseDevice,
  PurchaseSuccessful,
} from 'Modules/purchase/pages'
import { Sitemap } from 'Modules/sitemap/pages'

import { useStore } from 'Stores/index'
import { NavigationConfigTypeEnum } from 'Stores/navigationStore/navigationStore.types'

import { useAuthToken, useIsServiceApp } from 'Hooks'

import { AuthorisedLayout, UnauthorisedLayout } from '../layouts'
import { ProtectedRoute } from './ProtectedRoute'
import { PublicRoute } from './PublicRoute'
import { ROUTER_CONFIG } from './Router.config'
import { GLOBAL_ROUTE_PATHS, ROUTER_REDIRECTS } from './Router.constants'
import { RouterState } from './Router.types'
import { mapRouteWithChildrenToRoute } from './Router.utils'

export const Router = observer(() => {
  const { aclsStore, navigationStore } = useStore()
  const { allowedAcls } = aclsStore
  const {
    mainNavigationConfig: { url },
    setMainNavigationConfig,
  } = navigationStore
  const { pathname } = useLocation()
  const history = useHistory()
  const authToken = useAuthToken()
  const isServiceApp = useIsServiceApp()
  const [routerState, setRouterState] = useState<RouterState>({
    currentPath: '/',
    prevPath: '',
  })

  const { publicRoutes } = ROUTER_CONFIG
  const protectedRoutes = [
    ...mapRouteWithChildrenToRoute(ROUTER_CONFIG.protectedRoutes.organisation),
    ...mapRouteWithChildrenToRoute(ROUTER_CONFIG.protectedRoutes.employee),
  ]

  useEffect(() => {
    const checkPathACLS = () => {
      const pathParts = pathname.split('/')
      const isOrg = pathParts[1].includes('organisation')
      const isEmployee = pathParts[1].includes('employee')

      const isAllowedOrg =
        isOrg &&
        !!allowedAcls?.organisations.organisations?.some(
          org => org.id.toString() === pathParts[2],
        )

      const isAllowedEmployee =
        isEmployee &&
        !!allowedAcls?.allowedEmployees.employees?.some(
          employee => employee.id.toString() === pathParts[2],
        )

      const newId = pathParts[2]
      const newUrl = `/${pathParts[1]}/${newId}`

      if (!allowedAcls && (isOrg || isEmployee)) {
        localStorage.setItem(
          'lastSelected',
          isOrg ? 'organisation' : 'employee',
        )

        localStorage.setItem('lastSelectedId', pathParts[2])

        return
      }

      if ((isAllowedOrg || isAllowedEmployee) && newUrl !== url) {
        setMainNavigationConfig({
          id: newId,
          type: isAllowedOrg
            ? NavigationConfigTypeEnum.Organisation
            : NavigationConfigTypeEnum.Employee,
          url: newUrl,
        })

        return
      }

      if (
        !window.location.hash.includes(url) &&
        !window.location.hash.includes(GLOBAL_ROUTE_PATHS.public.contact) &&
        !window.location.hash.includes(GLOBAL_ROUTE_PATHS.public.dataPrivacy) &&
        !window.location.hash.includes(GLOBAL_ROUTE_PATHS.private.account) &&
        !window.location.hash.includes(GLOBAL_ROUTE_PATHS.public.offer) &&
        !window.location.hash.includes(
          GLOBAL_ROUTE_PATHS.public.offerAccepted,
        ) &&
        !window.location.hash.includes(
          GLOBAL_ROUTE_PATHS.public.offerDeclined,
        ) &&
        !window.location.hash.includes(
          GLOBAL_ROUTE_PATHS.public.purchaseSuccessful,
        ) &&
        !window.location.hash.includes(
          GLOBAL_ROUTE_PATHS.public.paymentFailed,
        ) &&
        !window.location.hash.includes(
          GLOBAL_ROUTE_PATHS.public.purchaseDevice.replace(':uuid', ''),
        ) &&
        !window.location.hash.includes(GLOBAL_ROUTE_PATHS.public.sitemap)
      ) {
        history.replace(url)
      }
    }

    checkPathACLS()
  }, [allowedAcls, history, pathname, url, setMainNavigationConfig])

  useEffect(() => {
    // MANAGE ROUTER STATE
    if (routerState.currentPath === pathname) {
      return
    }

    setRouterState(({ currentPath }) => ({
      currentPath: pathname,
      prevPath: currentPath,
    }))
  }, [pathname, routerState.currentPath])

  useEffect(() => {
    // EXECUTE CUSTOM ACTIONS
    ROUTER_CONFIG.customRouterActions.onRouteChange.forEach(action => {
      if (!action) {
        return
      }

      action(routerState.prevPath, routerState.currentPath)
    })
  }, [routerState])

  return (
    <Switch>
      {ROUTER_REDIRECTS.map(({ from, to }) => (
        <Redirect key={to} from={from} to={to} />
      ))}
      {protectedRoutes.map(route => (
        <ProtectedRoute key={route.path} {...route} exact />
      ))}
      {publicRoutes.map(({ path, ...rest }) => {
        const formattedPath = `/${path}`

        return (
          <PublicRoute
            key={formattedPath}
            path={formattedPath}
            {...rest}
            exact
          />
        )
      })}
      <Route
        exact
        path={GLOBAL_ROUTE_PATHS.public.contact}
        render={() => {
          if (authToken) {
            return (
              <AuthorisedLayout>
                <Contact />
              </AuthorisedLayout>
            )
          }

          return (
            <UnauthorisedLayout>
              <ContactLanding />
            </UnauthorisedLayout>
          )
        }}
      />

      <Route
        exact
        path={GLOBAL_ROUTE_PATHS.public.sitemap}
        render={() => {
          if (authToken) {
            return (
              <AuthorisedLayout>
                <Sitemap />
              </AuthorisedLayout>
            )
          }

          return (
            <UnauthorisedLayout>
              <Sitemap isUnauthorized />
            </UnauthorisedLayout>
          )
        }}
      />

      <Route
        exact
        path={GLOBAL_ROUTE_PATHS.public.offer}
        render={() => (
          <UnauthorisedLayout>
            <Offer />
          </UnauthorisedLayout>
        )}
      />

      <Route
        exact
        path={GLOBAL_ROUTE_PATHS.public.offerAccepted}
        render={() => (
          <UnauthorisedLayout>
            <OfferAccepted />
          </UnauthorisedLayout>
        )}
      />

      <Route
        exact
        path={GLOBAL_ROUTE_PATHS.public.offerDeclined}
        render={() => (
          <UnauthorisedLayout>
            <OfferDeclined />
          </UnauthorisedLayout>
        )}
      />

      <Route
        exact
        path={GLOBAL_ROUTE_PATHS.public.purchaseSuccessful}
        render={() => (
          <UnauthorisedLayout>
            <PurchaseSuccessful />
          </UnauthorisedLayout>
        )}
      />

      <Route
        exact
        path={GLOBAL_ROUTE_PATHS.public.paymentFailed}
        render={() => (
          <UnauthorisedLayout>
            <PaymentFailed />
          </UnauthorisedLayout>
        )}
      />

      <Route
        exact
        path={GLOBAL_ROUTE_PATHS.public.purchaseDevice}
        render={() => (
          <UnauthorisedLayout>
            <PurchaseDevice />
          </UnauthorisedLayout>
        )}
      />

      {isServiceApp && (
        <Route
          exact
          path={GLOBAL_ROUTE_PATHS.private.account}
          render={() => {
            if (authToken) {
              return (
                <AuthorisedLayout>
                  <Account />
                </AuthorisedLayout>
              )
            }

            return (
              <UnauthorisedLayout>
                <EmployeeDashboardDefault />
              </UnauthorisedLayout>
            )
          }}
        />
      )}

      <Route
        exact
        path={GLOBAL_ROUTE_PATHS.public.dataPrivacy}
        render={() => {
          if (authToken) {
            return (
              <AuthorisedLayout>
                <DataPrivacy />
              </AuthorisedLayout>
            )
          }

          return (
            <UnauthorisedLayout showFooter>
              <DataPrivacy />
            </UnauthorisedLayout>
          )
        }}
      />

      {!authToken ? (
        <Redirect to={GLOBAL_ROUTE_PATHS.public.login} />
      ) : (
        <ReactRoute exact path="/">
          <Redirect to={url} />
        </ReactRoute>
      )}
      <ReactRoute path="/*">
        <Redirect to={url} />
      </ReactRoute>
    </Switch>
  )
})
