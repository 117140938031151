import { useCallback, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { observer } from 'mobx-react'

import { RentalDetailsCard } from 'Modules/rentals/components/RentalDetailsCard/RentalDetailsCard'
import { RentalPresentationCard } from 'Modules/rentals/components/RentalPresentationCard/RentalPresentationCard'
import { RentalPresentationCardSwiper } from 'Modules/rentals/components/RentalPresentationCardSwiper/RentalPresentationCardSwiper'

import { DeviceHistoryEntityCard } from 'Components/DeviceHistoryEntityCard/DeviceHistoryEntityCard'
import { EmployeeEntityCard } from 'Components/EmployeeEntityCard/EmployeeEntityCard'
import { PageErrorState } from 'Components/PageErrorState/PageErrorState'
import { PageHeader } from 'Components/PageHeader/PageHeader'
import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'
import { PhonePlansEntityCard } from 'Components/PhonePlansEntityCard/PhonePlansEntityCard'
import { RecentRequestsEntityCard } from 'Components/RecentRequestsEntityCard/RecentRequestsEntityCard'

import { useStore } from 'Stores/index'

import { useIsServiceApp } from 'Hooks'

import { PAGINATION } from 'Constants/constants'
import {
  getRentalsPresentationDetails,
  getRentalsPresentationDetailsVariables,
} from 'Constants/graphql/queries/detail/__generated__/getRentalsPresentationDetails'
import { GET_RENTALS_PRESENTATION_DETAILS } from 'Constants/graphql/queries/detail/GetRentalsPresentationDetails'
import { PortalSectionEnum } from 'Constants/portalSection'

export const Rental = observer(() => {
  const { rentalStore } = useStore()
  const {
    rentalDetails,
    fetchRentalDetails,
    isLoadingRentalDetails,
    isRentalDetailsError,
  } = rentalStore
  const { employeeId, organisationId, rentalId } = useParams<{
    employeeId?: string
    organisationId?: string
    rentalId: string
  }>()
  const isOrganisation = !!organisationId
  const isServiceApp = useIsServiceApp()

  const [getRentalsPresentationDetails, { data }] = useLazyQuery<
    getRentalsPresentationDetails,
    getRentalsPresentationDetailsVariables
  >(GET_RENTALS_PRESENTATION_DETAILS)

  const handleRentalDetailsFetch = useCallback(() => {
    fetchRentalDetails({
      employeeId,
      isOrganisationView: isOrganisation,
      organisationId,
      rentalID: rentalId,
    })
  }, [employeeId, fetchRentalDetails, isOrganisation, organisationId, rentalId])

  useEffect(() => {
    handleRentalDetailsFetch()
  }, [handleRentalDetailsFetch])

  useEffect(() => {
    if (!employeeId || !isServiceApp) {
      return
    }

    getRentalsPresentationDetails({
      variables: {
        after: PAGINATION.AFTER,
        first: PAGINATION.ITEMS_PER_PAGE,
        id: employeeId,
      },
    })
  }, [employeeId, getRentalsPresentationDetails, isServiceApp])

  if (!isServiceApp && isLoadingRentalDetails) {
    return <PageLoadingState />
  }

  if (isRentalDetailsError || !rentalDetails) {
    return <PageErrorState />
  }

  const { rental } = rentalDetails

  return (
    <Container className="pt-4 pt-lg-6" fluid>
      <div role="banner">
        <PageHeader
          className={isServiceApp ? 'mb-0' : 'mb-4 mb-lg-6'}
          portalSection={PortalSectionEnum.RentalDetails}
        />
      </div>

      <Row>
        <Col xxl={8}>
          {isServiceApp ? (
            <RentalPresentationCardSwiper
              employeeId={employeeId ?? '0'}
              rentalId={rentalId ?? '0'}
              rentals={data?.employee.rentals?.rentals ?? []}
            />
          ) : (
            <RentalPresentationCard
              stocklistItem={rental.stocklistItem}
              title="Device"
            />
          )}

          <RentalDetailsCard
            refetch={handleRentalDetailsFetch}
            rental={rental}
          />
        </Col>

        <Col xxl={4}>
          <Row>
            <Col xs={12}>
              <RecentRequestsEntityCard
                caseItems={rental.caseItems?.caseItems ?? []}
              />
            </Col>

            <div className="d-block d-lg-flex d-xxl-block w-100">
              {isOrganisation && (
                <Col
                  className="flex-shrink-1 me-0 me-lg-6 me-xxl-0"
                  lg={6}
                  xs={12}
                  xxl={12}
                >
                  <EmployeeEntityCard employee={rental.employee ?? null} />
                </Col>
              )}

              <Col className="flex-shrink-1" lg={6} xs={12} xxl={12}>
                <DeviceHistoryEntityCard rental={rental} />
              </Col>
            </div>

            {isOrganisation && rental.phonePlan && (
              <Col lg={6} xs={12} xxl={12}>
                <PhonePlansEntityCard
                  phonePlans={rental.phonePlan ? [rental.phonePlan] : []}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  )
})
