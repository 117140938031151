import { useInstantSearch, usePagination } from 'react-instantsearch'
import { useIntl } from 'react-intl'

import {
  E2E_LIST_TOTAL_FROM,
  E2E_LIST_TOTAL_OF,
  E2E_LIST_TOTAL_TO,
} from 'Constants/e2e'
import { DEFAULT_PAGE_SIZE } from 'Constants/pagination'

export const TotalCounter = () => {
  const intl = useIntl()
  const { results } = useInstantSearch()

  const { currentRefinement: currentPage, nbHits: total } = usePagination()

  const pageSize = results?.hitsPerPage ?? DEFAULT_PAGE_SIZE

  const getPageStart = (page: number) => page * pageSize
  const firstIndex = Math.max(getPageStart(currentPage), 0) + 1
  const lastIndex = Math.min(getPageStart(currentPage + 1), total)

  const totalCounterLabel = intl.formatMessage({
    id: 'TotalCounter_total',
  })

  if (total === 0) {
    return null
  }

  return (
    <p className="TotalCounter">
      <span data-e2e={E2E_LIST_TOTAL_FROM}>{firstIndex}</span>-
      <span data-e2e={E2E_LIST_TOTAL_TO}>{lastIndex}</span> {totalCounterLabel}{' '}
      <span data-e2e={E2E_LIST_TOTAL_OF}>{total}</span>
    </p>
  )
}
