import { DateTime } from 'luxon'

import { GET_DATE_PATTERN_OPTIONS } from './dates.utils.constants'
import { GetDatePatternArgs } from './dates.utils.types'

export const getDatePattern = ({ useLuxonFormat }: GetDatePatternArgs = {}) => {
  const preferredLanguage = navigator.languages[0] || window.navigator.language
  const formatter = new Intl.DateTimeFormat(
    preferredLanguage,
    GET_DATE_PATTERN_OPTIONS,
  ).formatToParts()

  const format = formatter
    .map(part => {
      switch (part.type) {
        case 'day':
          return 'DD'
        case 'month':
          return 'MM'
        case 'year':
          return 'YYYY'
        default:
          return part.value
      }
    })
    .join('')

  return useLuxonFormat
    ? format.replaceAll('DD', 'dd').replaceAll('YYYY', 'yyyy')
    : format
}

export const formatTimestampToDate = (timestamp: string | number) => {
  const dateFormat = getDatePattern({ useLuxonFormat: true })

  return DateTime.fromSeconds(Number(timestamp)).toFormat(dateFormat)
}
