import { z } from 'zod'

import { zSelect } from 'Lib/zod'

import { FORM_MESSAGES_MAP } from 'Constants/formMessages'

import {
  EMPLOYEE_COST_CENTER_NAME_MAX_CHARACTERS,
  EMPLOYEE_FIRST_NAME_MAX_CHARACTERS,
  EMPLOYEE_LAST_NAME_MAX_CHARACTERS,
} from './EditEmployeeFormModal.constants'

export const editEmployeeFormCreateEmployeeSchema = z.object({
  allowLogin: z.boolean(),
  costCenterID: zSelect,
  email: z.string().email(FORM_MESSAGES_MAP.EMAIL).or(z.literal('')).nullable(),
  externalReference: z.string(),
  firstName: z
    .string()
    .min(1, FORM_MESSAGES_MAP.REQUIRED)
    .max(EMPLOYEE_FIRST_NAME_MAX_CHARACTERS, FORM_MESSAGES_MAP.MAX_LENGTH),
  lastName: z
    .string()
    .max(EMPLOYEE_LAST_NAME_MAX_CHARACTERS, FORM_MESSAGES_MAP.MAX_LENGTH)
    .nullable(),
  newCostCenter: z
    .string()
    .max(EMPLOYEE_COST_CENTER_NAME_MAX_CHARACTERS, FORM_MESSAGES_MAP.MAX_LENGTH)
    .nullable(),
  officeID: zSelect,
  phoneNumber: z.string().nullable(),
  rentCategoryTierID: zSelect,
})

export const editEmployeeFormEditEmployeeSchema = z.object({
  costCenterID: zSelect,
  email: z.string().email(FORM_MESSAGES_MAP.EMAIL).or(z.literal('')).nullable(),
  externalReference: z.string(),
  firstName: z
    .string()
    .min(1, FORM_MESSAGES_MAP.REQUIRED)
    .max(EMPLOYEE_FIRST_NAME_MAX_CHARACTERS, FORM_MESSAGES_MAP.MAX_LENGTH),
  lastName: z
    .string()
    .max(EMPLOYEE_LAST_NAME_MAX_CHARACTERS, FORM_MESSAGES_MAP.MAX_LENGTH)
    .nullable(),
  newCostCenter: z
    .string()
    .max(EMPLOYEE_COST_CENTER_NAME_MAX_CHARACTERS, FORM_MESSAGES_MAP.MAX_LENGTH)
    .nullable(),
  officeID: zSelect,
  phoneNumber: z.string().nullable(),
  rentCategoryTierID: zSelect,
})
