import { gql } from '@apollo/client'

export const GET_VENDING_MACHINE_DATA = gql`
  query getVendingMachineData($input: VendingMachineAvailabilityInput!) {
    checkVendingMachineAvailability(input: $input) {
      totalCount
      vendingMachines {
        id
        name
        addressID
        address {
          id
          name
          nameAddition
          street
          streetAddition
          zipcode
          city
          country
        }
      }
    }
  }
`
