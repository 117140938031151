import { gql } from '@apollo/client'

const FRAGMENT_ADDRESS_FIELDS = gql`
  fragment officeAddressFields on Address {
    city
    country
    id
    name
    nameAddition
    street
    streetAddition
    zipcode
  }
`

export const GET_EMPLOYEE_CONTACT = gql`
  ${FRAGMENT_ADDRESS_FIELDS}
  query getEmployeeContact($id: ID!) {
    employee(id: $id) {
      contact {
        firstName
        lastName
      }
      office {
        address {
          ...officeAddressFields
        }
      }
      organisation {
        contact {
          address {
            ...officeAddressFields
          }
        }
      }
    }
  }
`
