import { Chart, ChartOptions, UpdateMode } from 'chart.js'

import { I18nConfig } from 'Stores/portalSettingsStore/portalSettingsStore.types'

export const deviceCountCardChartLegendPlugin = (i18n: I18nConfig) => ({
  afterUpdate(
    chart: Chart<'pie'>,
    args: { mode: UpdateMode },
    options: ChartOptions<'pie'>,
  ) {
    const legendContainer = document.getElementById(options.containerID!)

    let legendContainerList = legendContainer?.querySelector('ul')

    if (!legendContainerList) {
      legendContainerList = document.createElement('ul')
      legendContainerList.setAttribute(
        'class',
        'DashboardFleetOverviewCard-legend-container',
      )

      legendContainer?.appendChild(legendContainerList)
    }

    while (legendContainerList.firstChild) {
      legendContainerList.firstChild.remove()
    }

    if (
      typeof chart.options.plugins?.legend?.labels?.generateLabels !==
      'function'
    ) {
      return
    }

    const legendItems = chart.options.plugins.legend.labels.generateLabels(
      chart as Chart,
    )

    legendItems.forEach((item, index) => {
      // Create legend item
      const legendItem = document.createElement('li')

      legendItem.setAttribute('class', 'DashboardFleetOverviewCard-legend-item')

      legendItem.onclick = () => {
        chart.toggleDataVisibility(item.index!)

        chart.update(args.mode)
      }

      // Create color box
      const boxSpan = document.createElement('span')

      boxSpan.style.background = item.fillStyle?.toString() || ''
      boxSpan.setAttribute('class', 'DashboardFleetOverviewCard-color-box')

      const nameContainer = document.createElement('p')

      nameContainer.style.textDecoration = item.hidden ? 'line-through' : ''
      nameContainer.setAttribute(
        'class',
        'DashboardFleetOverviewCard-legend-name',
      )

      // Create name
      const name = document.createTextNode(`${item.text}:`)

      nameContainer.appendChild(name)

      const totalContainer = document.createElement('p')

      totalContainer.setAttribute(
        'class',
        'DashboardFleetOverviewCard-legend-total',
      )

      // Create total
      const total = document.createTextNode(
        `${Number(chart.data.datasets[0].data[index]).toLocaleString(
          i18n.locale,
        )}`,
      )

      totalContainer.appendChild(total)

      // Append values to legend item and then append legend item to legend
      legendItem.appendChild(boxSpan)
      legendItem.appendChild(nameContainer)
      legendItem.appendChild(totalContainer)
      legendContainerList?.appendChild(legendItem)
    })
  },
  id: 'htmlLegend',
})
