import { action, makeObservable, observable } from 'mobx'

import { mutationProlongRental_prolongRental_rentalProlonging } from 'Constants/graphql/mutations/__generated__/mutationProlongRental'
import {
  getRental,
  getRentalVariables,
} from 'Constants/graphql/queries/detail/__generated__/getRental'
import { GET_RENTAL } from 'Constants/graphql/queries/detail/GetRental'

import ApolloLoader from 'Portal/src/ApolloLoader'

export class RentalStore {
  rentalDetails: getRental | null = null

  rentalProlonging: mutationProlongRental_prolongRental_rentalProlonging | null =
    null

  isLoadingRentalDetails = false

  isRentalDetailsError = false

  constructor() {
    makeObservable(this, {
      fetchRentalDetails: action,
      isLoadingRentalDetails: observable,
      rentalDetails: observable,
      rentalProlonging: observable,
      setIsLoadingRentalDetails: action,
      setRentalDetails: action,
      setRentalProlonging: action,
    })
  }

  fetchRentalDetails = async (variables: getRentalVariables) => {
    const { apolloClient } = ApolloLoader

    if (!apolloClient) {
      throw new Error('No apollo client found.')
    }

    try {
      this.setIsLoadingRentalDetails(true)
      this.setIsRentalDetailsError(false)

      const response = await apolloClient.query<getRental, getRentalVariables>({
        query: GET_RENTAL,
        variables,
      })

      if (response.error) {
        throw new Error(response.error.message)
      }

      this.setRentalDetails(response.data)
    } catch (error) {
      this.setIsRentalDetailsError(true)
      this.setRentalDetails(null)
    } finally {
      this.setIsLoadingRentalDetails(false)
    }
  }

  setRentalDetails = (rentalDetails: getRental | null) => {
    this.rentalDetails = rentalDetails
  }

  setRentalProlonging = (
    rentalProlonging: mutationProlongRental_prolongRental_rentalProlonging | null,
  ) => {
    this.rentalProlonging = rentalProlonging
  }

  setIsLoadingRentalDetails = (isLoading: boolean) => {
    this.isLoadingRentalDetails = isLoading
  }

  setIsRentalDetailsError = (isError: boolean) => {
    this.isRentalDetailsError = isError
  }
}
