export enum NoResultsTypeEnum {
  'simple',
  'custom',
}

export type NoResultsProps = {
  type: NoResultsTypeEnum
  className?: string
  noResultsText?: string
}
