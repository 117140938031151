import { useCallback, useEffect, useState } from 'react'
import { Prompt, useHistory } from 'react-router-dom'
import { Location } from 'history'
import { observer } from 'mobx-react'

import { OrderRoutePathsEnum } from 'Modules/order/routes/orderRouteConfig.types'
import { RentalsRoutePathsEnum } from 'Modules/rentals/routes/rentalsRouteConfig.types'

import { PreviewColumnTypeEnum } from 'Components/PreviewColumn/PreviewColumn.types'

import { useStore } from 'Stores/index'

import { useModal } from 'Hooks'

import { OrderGuardModalContent } from './OrderGuardModalContent'

export const OrderGuardModal = observer(() => {
  const history = useHistory()
  const [isNavigationAllowed, setIsNavigationAllowed] = useState(false)
  const [nextPath, setNextPath] = useState<string | undefined>()
  const { orderStore, portalSettingsStore } = useStore()
  const { setChosenDevice, setFetchError, setMutationError, mutationError } =
    orderStore
  const { setTogglePreviewColumn, previewColumnSettings } = portalSettingsStore

  const { setModal, hideModal } = useModal()

  const handleLeaveOrder = useCallback(() => {
    hideModal()
    setIsNavigationAllowed(true)
    setChosenDevice(null)
    setFetchError(false)
    setMutationError(false)

    const existingPreviewColumnBundleSetting = previewColumnSettings.find(
      previewColumnSetting =>
        previewColumnSetting.type === PreviewColumnTypeEnum.Bundle,
    )

    if (
      existingPreviewColumnBundleSetting &&
      existingPreviewColumnBundleSetting.isOpen
    ) {
      setTogglePreviewColumn(PreviewColumnTypeEnum.Bundle, false)
    }
  }, [
    hideModal,
    previewColumnSettings,
    setChosenDevice,
    setFetchError,
    setMutationError,
    setTogglePreviewColumn,
  ])

  const handleOpenGuardModal = useCallback(() => {
    setModal({
      component: OrderGuardModalContent,
      contentClassName: 'OrderGuardModal-content',
      isOpen: true,
      props: {
        onContinue: () => {
          hideModal()
        },
        onLeave: () => {
          handleLeaveOrder()
          hideModal()
        },
      },
    })
  }, [handleLeaveOrder, hideModal, setModal])

  const routeGuard = ({ pathname }: Location) => {
    // Disable GuardModal when redirecting user between prolonging and upgrade
    const disableRouteGuard =
      (history.location.pathname.includes('/order/prolong/') &&
        pathname.includes('/order/upgrade')) ||
      (history.location.pathname.includes('/order/upgrade') &&
        pathname.includes('/order/prolong'))

    const isNavigationEnabled =
      disableRouteGuard ||
      pathname.includes(OrderRoutePathsEnum.ORDER_CONFIRMATION) ||
      pathname.includes(OrderRoutePathsEnum.ORDER_CUSTOM_CONFIRMATION) ||
      pathname.includes(OrderRoutePathsEnum.ORDER_PROLONG_CONFIRMATION) ||
      pathname.includes(RentalsRoutePathsEnum.RENTALS) ||
      mutationError

    if (isNavigationEnabled) {
      setIsNavigationAllowed(true)

      return true
    }

    if (!isNavigationAllowed) {
      handleOpenGuardModal()
      setNextPath(pathname)

      return false
    }

    return true
  }

  useEffect(() => {
    if (isNavigationAllowed && nextPath) {
      history.push(nextPath)
    }
  }, [history, isNavigationAllowed, nextPath])

  useEffect(() => {
    const beforeunloadHandler = (event: BeforeUnloadEvent) => {
      if (isNavigationAllowed) {
        return null
      }

      const e = event || window.event

      e.preventDefault()
      event.returnValue = ''

      return ''
    }

    window.addEventListener('beforeunload', beforeunloadHandler)

    return () => {
      window.removeEventListener('beforeunload', beforeunloadHandler)
    }
  }, [isNavigationAllowed])

  return <Prompt message={routeGuard} when={!isNavigationAllowed} />
})
