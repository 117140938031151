import { ReactElement } from 'react'
import { FormattedMessage } from 'react-intl'
import { clsx } from 'clsx'
import { isObject } from 'lodash-es'

import { FormatDate } from 'Components/FormatDate/FormatDate'
import { FormattedCurrency } from 'Components/FormattedCurrency/FormattedCurrency'

import { CardDetailsListProps } from './CardDetailsList.types'

export const CardDetailsList = (props: CardDetailsListProps) => {
  const { className, listItems } = props

  const isValidValue = (value?: ReactElement | string | null) => {
    if (!value) {
      return false
    }

    if (isObject(value)) {
      if (
        value?.type === FormattedCurrency ||
        value?.type === FormattedMessage
      ) {
        return true
      }

      if (value?.type === FormatDate && !value?.props?.value) {
        return false
      }
    }

    return true
  }

  return (
    <dl className={clsx('CardDetailsList', className)}>
      {listItems.map(({ label, value }) => {
        if (!isValidValue(value)) {
          return null
        }

        return (
          <div key={label} className="d-flex">
            <dt className="fw-normal pe-2">
              <FormattedMessage id={label} />:
            </dt>

            <dd
              className="text-ellipsis"
              {...(typeof value === 'string' && { title: value })}
            >
              {value}
            </dd>
          </div>
        )
      })}
    </dl>
  )
}
