import { action, makeObservable, observable } from 'mobx'

import {
  getOrganisationCostCenters,
  getOrganisationCostCentersVariables,
} from 'Constants/graphql/queries/listing/__generated__/getOrganisationCostCenters'
import { GET_ORGANISATION_COST_CENTERS } from 'Constants/graphql/queries/listing/GetCostCenters'
import {
  getOrganisation,
  getOrganisation_organisation,
  getOrganisationVariables,
} from 'Constants/graphql/queries/organisation/__generated__/getOrganisation'
import { GET_ORGANISATION } from 'Constants/graphql/queries/organisation/getOrganisation'

import ApolloLoader from 'Portal/src/ApolloLoader'
import { setTheme } from 'Portal/src/utilities/theme/theme.util'

import type { RootStore } from '..'
import { createIntercom } from './organisationStore.util'

export class OrganisationStore {
  organisation: getOrganisation_organisation | null = null

  isLoadingOrganisation = true

  constructor(private readonly store: RootStore) {
    makeObservable(this, {
      fetchOrganisation: action,
      fetchOrganisationCostCenters: action,
      isLoadingOrganisation: observable,
      organisation: observable,
      setIsLoadingOrganisation: action,
      setOrganisation: action,
    })
  }

  fetchOrganisation = async (id: string) => {
    const { apolloClient } = ApolloLoader

    if (!apolloClient) return

    try {
      this.setIsLoadingOrganisation(true)

      const response = await apolloClient.query<
        getOrganisation,
        getOrganisationVariables
      >({
        query: GET_ORGANISATION,
        variables: {
          id,
        },
      })

      const {
        data: { organisation },
      } = response

      this.store.employeeStore.setEmployee(null)
      this.setOrganisation(organisation)

      await this.store.portalConfigStore.fetchPortalConfig(
        organisation.id,
        null,
      )

      const { portalConfig } = this.store.portalConfigStore

      if (portalConfig) {
        setTheme(portalConfig)
      }

      this.store.portalContentStore.setPortalContent(organisation.portalContent)
      this.store.orderStore.setIsCYOD(organisation.isCYOD)

      localStorage.setItem('lastSelected', 'organisation')
      localStorage.setItem('lastSelectedId', organisation.id.toString())

      createIntercom(organisation)
    } catch (error) {
      this.setOrganisation(null)
    } finally {
      this.setIsLoadingOrganisation(false)
    }
  }

  fetchOrganisationCostCenters = async () => {
    const { apolloClient } = ApolloLoader

    if (!apolloClient || !this.organisation?.id) return

    const response = await apolloClient.query<
      getOrganisationCostCenters,
      getOrganisationCostCentersVariables
    >({
      query: GET_ORGANISATION_COST_CENTERS,
      variables: {
        id: this.organisation.id,
      },
    })

    const {
      data: {
        organisation: { costCenters },
      },
    } = response

    this.setOrganisation({ ...this.organisation, costCenters })
  }

  setIsLoadingOrganisation = (isLoading: boolean) => {
    this.isLoadingOrganisation = isLoading
  }

  setOrganisation = (organisation: getOrganisation_organisation | null) => {
    this.organisation = organisation
  }
}
