import { useFormContext } from 'react-hook-form'
import TextareaAutosize from 'react-textarea-autosize'

import { Textarea } from 'Components/_theme'

import { FieldTextareaProps, FieldTypeEnum } from './Field.types'

export const FieldTextarea = (props: FieldTextareaProps) => {
  const {
    name,
    hasError,
    markAsRequired,
    inputClassName,
    textareaProps,
    type,
    e2eSelector,
    ...rest
  } = props
  const { register, watch } = useFormContext()

  const charactersCount = watch(name).length

  return (
    <Textarea
      charactersCount={charactersCount}
      className={inputClassName}
      customComponent={
        type === FieldTypeEnum.textareaAutosize ? TextareaAutosize : undefined
      }
      {...register(name)}
      {...textareaProps}
      {...rest}
      data-e2e={e2eSelector}
      id={name}
      isFilled={charactersCount !== 0}
      isInvalid={hasError}
      markAsRequired={markAsRequired}
    />
  )
}
