import { useCallback, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useMutation } from '@apollo/client'
import { faCartShopping } from '@fortawesome/pro-solid-svg-icons'

import { Card, CardBody, CardHeader } from 'Components/_theme'

import {
  mutationPurchaseDevice,
  mutationPurchaseDeviceVariables,
} from 'Constants/graphql/mutations/__generated__/mutationPurchaseDevice'
import { MUTATION_PURCHASE_DEVICE } from 'Constants/graphql/mutations/MutationPurchaseDevice'

import { RequestPurchaseModalConfirmation } from './components/RequestPurchaseModalConfirmation'
import { RequestPurchaseModalInvalid } from './components/RequestPurchaseModalInvalid'
import { RequestPurchaseModalLanding } from './components/RequestPurchaseModalLanding'
import { RequestPurchaseModalSummary } from './components/RequestPurchaseModalSummary'
import {
  RequestPurchaseModalProps,
  RequestPurchaseModalStepsEnum,
  RequestPurchaseModalStepsMap,
  RequestPurchaseModalSummarySchema,
} from './RequestPurchaseModal.types'

export const RequestPurchaseModal = (props: RequestPurchaseModalProps) => {
  const { onHide, rental } = props
  const [currentStep, setCurrentStep] =
    useState<`${RequestPurchaseModalStepsEnum}`>(
      RequestPurchaseModalStepsEnum.LANDING,
    )

  const [submitPurchaseDevice, { loading: isLoading }] = useMutation<
    mutationPurchaseDevice,
    mutationPurchaseDeviceVariables
  >(MUTATION_PURCHASE_DEVICE, {
    onCompleted: () => {
      setCurrentStep(RequestPurchaseModalStepsEnum.CONFIRMATION)
    },
  })

  const handleSetCurrentStep = useCallback(
    (
      step: `${RequestPurchaseModalStepsEnum}`,
      data?: RequestPurchaseModalSummarySchema,
    ) => {
      if (!data) {
        setCurrentStep(step)

        return
      }

      submitPurchaseDevice({
        variables: {
          input: {
            rentalID: rental.id,
            ...data,
          },
        },
      })
    },
    [rental.id, submitPurchaseDevice],
  )

  const stepsMap = useMemo(
    (): RequestPurchaseModalStepsMap => ({
      [RequestPurchaseModalStepsEnum.LANDING]: {
        component: RequestPurchaseModalLanding,
        props: {
          onHide,
          onSetCurrentStep: handleSetCurrentStep,
          rental,
        },
        title: 'RequestPurchaseModal_landingHeader',
      },
      [RequestPurchaseModalStepsEnum.SUMMARY]: {
        component: RequestPurchaseModalSummary,
        props: {
          isSubmitting: isLoading,
          onSetCurrentStep: handleSetCurrentStep,
          rental,
        },
        title: 'RequestPurchaseModal_summaryHeader',
      },
      [RequestPurchaseModalStepsEnum.CONFIRMATION]: {
        component: RequestPurchaseModalConfirmation,
        props: {
          onHide,
        },
        title: 'RequestPurchaseModal_confirmationHeader',
      },
      [RequestPurchaseModalStepsEnum.INVALID]: {
        component: RequestPurchaseModalInvalid,
        props: {
          onHide,
        },
        title: 'RequestPurchaseModal_invalidHeader',
      },
    }),
    [handleSetCurrentStep, isLoading, onHide, rental],
  )

  const renderStep = useMemo(() => {
    const { component: Component, props, title } = stepsMap[currentStep]

    return { component: <Component {...props} />, title }
  }, [stepsMap, currentStep])

  return (
    <Card>
      <CardHeader icon={faCartShopping}>
        <FormattedMessage id={renderStep.title} />
      </CardHeader>

      <CardBody>{renderStep.component}</CardBody>
    </Card>
  )
}
