import { useIntl } from 'react-intl'
import { clsx } from 'clsx'

import { ProgressBarProps } from './ProgressBar.types'

export const ProgressBar = (props: ProgressBarProps) => {
  const { className, value } = props

  const intl = useIntl()

  const classNames = clsx('ProgressBar', className)

  return (
    <div className={classNames}>
      <div
        aria-label={intl.formatMessage({ id: 'In_progress' })}
        aria-valuemax={100}
        aria-valuemin={0}
        aria-valuenow={value}
        className="ProgressBar-value"
        role="progressbar"
        style={{ width: `${value}%` }}
      />
    </div>
  )
}
