import { FormattedMessage, useIntl } from 'react-intl'

import { OfferStatusProps } from 'Modules/purchase/components/OfferStatus/OfferStatus.types'
import { PurchaseLayout } from 'Modules/purchase/layouts/PurchaseLayout/PurchaseLayout'

import { Heading } from 'Components/_theme'
import { LanguageDropdown } from 'Components/LanguageDropdown/LanguageDropdown'

export const OfferStatus = (props: OfferStatusProps) => {
  const { messages } = useIntl()
  const { offerStatus } = props

  const headingIntlKey = `OfferStatus_${offerStatus}_heading`
  const contentIntlKey = `OfferStatus_${offerStatus}_content`

  const headingIntlId = messages[headingIntlKey]
    ? headingIntlKey
    : 'OfferStatus_fallback_heading'

  const contentIntlId = messages[contentIntlKey]
    ? contentIntlKey
    : 'OfferStatus_fallback_content'

  return (
    <PurchaseLayout>
      <Heading className="PurchaseContent-heading" size={1}>
        <FormattedMessage id={headingIntlId} />
      </Heading>

      <div className="PurchaseContent-content">
        <FormattedMessage id={contentIntlId} />
      </div>

      <LanguageDropdown isLandingPage />
    </PurchaseLayout>
  )
}
