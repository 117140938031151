import { useMutation } from '@apollo/client'

import { notEmpty } from 'Modules/order/utils/notEmpty.utils'

import { handleFetchErrorFunctional } from 'Utilities'

import {
  mutationCreateOfferOrders,
  mutationCreateOfferOrdersVariables,
} from 'Constants/graphql/mutations/__generated__/mutationCreateOfferOrders'
import { MUTATION_CREATE_OFFER_ORDERS } from 'Constants/graphql/mutations/MutationCreateOfferOrders'

import { useStore } from 'Portal/src/stores'

import { UseOfferOrderMutationArgs } from './useOfferOrderMutation.types'

/**
 * This hook is used to generate appropriate mutation and its handlers
 * for placing an order for catalog and product offers.
 */
export const useOfferOrderMutation = (args: UseOfferOrderMutationArgs) => {
  const { handleOnCompletedOrder, variables, handleOnErrorOrder } = args
  const { orderStore } = useStore()
  const { setOrderedDevices, setMutationError } = orderStore

  const [handleOfferOrderMutation, { loading: isOfferOrderLoading }] =
    useMutation<mutationCreateOfferOrders, mutationCreateOfferOrdersVariables>(
      MUTATION_CREATE_OFFER_ORDERS,
      {
        onCompleted: data => {
          const offerOrders =
            data.createOfferOrders.offerOrders?.filter(notEmpty)

          if (offerOrders && offerOrders[0].caseItems) {
            const filteredDevices = offerOrders[0].caseItems.filter(notEmpty)

            setOrderedDevices(filteredDevices)
          }

          setMutationError(false)

          if (handleOnCompletedOrder) {
            handleOnCompletedOrder(data)
          }
        },
        onError: error => {
          handleFetchErrorFunctional()(error)
          setMutationError(true)

          if (handleOnErrorOrder) {
            handleOnErrorOrder(error)
          }
        },
        variables,
      },
    )

  return {
    handleOfferOrderMutation,
    isOfferOrderLoading,
  }
}
