import { gql } from '@apollo/client'

export const MUTATION_PROLONG_RENTAL = gql`
  mutation mutationProlongRental($rentalID: ID!) {
    prolongRental(rentalID: $rentalID) {
      id
      startAt
      isProlongingEligible
      rentalProlongingID
      rentalProlonging {
        id
        validTill
      }
    }
  }
`
