import { useEffect } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { zodResolver } from '@hookform/resolvers/zod'

import { ButtonGroup, CardBody } from 'Components/_theme'
import { DepotsSelect } from 'Components/DepotsSelect/DepotsSelect'
import { Field } from 'Components/Field/Field'
import { FieldTypeEnum } from 'Components/Field/Field.types'
import { Form } from 'Components/Form/Form'
import { ReplacementDeviceSelect } from 'Components/ReplacementDeviceSelect/ReplacementDeviceSelect'

import { EMPTY_SELECT_OPTION } from 'Constants/form'

import { modalDeviceReplacementFormSchema } from './ModalDeviceReplacementForm.schema'
import {
  ModalDeviceReplacementFormProps,
  ModalDeviceReplacementFormSchema,
} from './ModalDeviceReplacementForm.types'
import { modalDeviceReplacementFormDefaultValues } from './ModalDeviceReplacementForm.values'

export const ModalReplacementForm = (
  props: ModalDeviceReplacementFormProps,
) => {
  const {
    formId,
    isReplaceFromDepot,
    rental,
    previousDeviceLabel,
    onPreviousStep,
    onSubmit,
    e2eSelectors,
    heading,
  } = props
  const intl = useIntl()

  const formMethods = useForm<ModalDeviceReplacementFormSchema>({
    defaultValues: {
      ...modalDeviceReplacementFormDefaultValues,
      previousDevice: `${rental.stocklistItem?.deviceDefinition?.name} ${
        rental.stocklistItem?.imei || ''
      }`,
    },
    resolver: zodResolver(modalDeviceReplacementFormSchema),
  })

  const { control, setValue } = formMethods
  const watchDepot = useWatch({
    control,
    name: 'depot',
  })

  const handlePrev = () => {
    onPreviousStep()
  }

  const handleSubmit = formMethods.handleSubmit(data => {
    onSubmit(data)
  })

  useEffect(() => {
    // reset replacement device if depot is changed
    setValue('nextDeviceId', EMPTY_SELECT_OPTION)
  }, [setValue, watchDepot?.value])

  return (
    <>
      {heading}

      <CardBody>
        <FormProvider {...formMethods}>
          <Form id={formId} onSubmit={handleSubmit}>
            <Field
              autoComplete="off" // always disabled
              disabled
              e2eSelector={e2eSelectors?.previousDevice}
              label={previousDeviceLabel}
              name="previousDevice"
              type={FieldTypeEnum.text}
            />

            <DepotsSelect
              containerClassName="mt-4"
              e2eSelector={e2eSelectors?.depot}
              label="Depots"
              markAsRequired
              name="depot"
              rentalId={rental.id}
            />

            <ReplacementDeviceSelect
              containerClassName="mt-4"
              depotId={watchDepot?.value ?? ''}
              e2eSelector={e2eSelectors?.nextDevice}
              label="Replacement_device"
              markAsRequired
              name="nextDeviceId"
              rentalId={rental.id}
            />
          </Form>
        </FormProvider>

        <ButtonGroup
          leftButton={{
            'data-e2e': e2eSelectors?.back,
            onClick: handlePrev,
            text: intl.formatMessage({
              id: isReplaceFromDepot ? 'Cancel' : 'Back',
            }),
          }}
          rightButton={{
            'data-e2e': e2eSelectors?.submit,
            form: formId,
            text: intl.formatMessage({ id: 'Next' }),
            type: 'submit',
          }}
        />
      </CardBody>
    </>
  )
}
