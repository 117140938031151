import { useCallback } from 'react'
import { Configure, InstantSearch } from 'react-instantsearch'
import { UiState } from 'instantsearch.js'
import { observer } from 'mobx-react'
import TypesenseInstantsearchAdapter from 'typesense-instantsearch-adapter'

import { useStore } from 'Stores/index'

import { useInstantsearchIndexName } from 'Hooks'

import { InstantsearchProviderProps } from './InstantsearchProvider.types'

export const InstantsearchProvider = observer(
  (props: InstantsearchProviderProps) => {
    const { portalContentStore } = useStore()
    const { algoliaUIState, setAlgoliaUIState } = portalContentStore
    const { children, instantSearchConfig, sortBy } = props
    const indexName = useInstantsearchIndexName()

    const { userStore } = useStore()
    const { user } = userStore

    const { hostname, protocol, port } = new URL(window.GO_API_URL)
    const typesenseInstantsearchAdapter = new TypesenseInstantsearchAdapter({
      additionalSearchParameters: {
        // handled on the backend
        query_by: 'dummy',
        sort_by: sortBy,
      },
      server: {
        additionalHeaders: {
          authorization: `Bearer ${user.jwt ?? ''}`,
        },
        apiKey: '',
        cacheSearchResultsForSeconds: 0,
        nodes: [
          {
            host: hostname,
            path: '',
            port: Number(port) || 443,
            protocol: protocol.substring(0, protocol.length - 1),
          },
        ],
        sendApiKeyAsQueryParam: false,
      },
    })

    const handleStateChange = useCallback(
      (params: {
        uiState: UiState
        setUiState: (
          uiState: UiState | ((previousUiState: UiState) => UiState),
        ) => void
      }) => {
        setAlgoliaUIState(params.uiState)
      },
      [setAlgoliaUIState],
    )

    return (
      <InstantSearch
        future={{
          preserveSharedStateOnUnmount: false,
        }}
        indexName={indexName}
        initialUiState={algoliaUIState}
        onStateChange={handleStateChange}
        searchClient={typesenseInstantsearchAdapter.searchClient}
      >
        <Configure
          analytics={false}
          hitsPerPage={10}
          maxValuesPerFacet={100}
          {...instantSearchConfig}
        />

        {children}
      </InstantSearch>
    )
  },
)
