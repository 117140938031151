import { observer } from 'mobx-react'

import { EmployeeDashboardDefault } from 'Modules/dashboard/components/EmployeeDashboardDefault/EmployeeDashboardDefault'
import { EmployeeDashboardPlus } from 'Modules/dashboard/components/EmployeeDashboardPlus/EmployeeDashboardPlus'

import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'

import { useActiveUser } from 'Hooks'

export const EmployeeDashboard = observer(() => {
  const { activeEmployee } = useActiveUser()

  if (!activeEmployee) {
    return <PageLoadingState />
  }

  if (activeEmployee?.organisation?.isPlusOrganisation) {
    return <EmployeeDashboardPlus />
  }

  return <EmployeeDashboardDefault />
})
