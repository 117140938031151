import { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { faBars } from '@fortawesome/pro-solid-svg-icons'
import { useMediaQuery } from '@react-hookz/web'
import { clsx } from 'clsx'
import { observer } from 'mobx-react'

import { AccessibilityButton } from 'Components/AccessibilityButton/AccessibilityButton'
import { ACLDropdown } from 'Components/ACLDropdown/ACLDropdown'
import { GlobalSearch } from 'Components/GlobalSearch/GlobalSearch'
import { Icon } from 'Components/Icon/Icon'
import { LanguageDropdown } from 'Components/LanguageDropdown/LanguageDropdown'
import { Logo } from 'Components/Logo/Logo'

import { useStore } from 'Stores/index'

import { initialiseUserway } from 'Utilities'

import { PORTAL_BREAKPOINTS } from 'Constants/constants'
import {
  GLOBAL_SEARCH_WHITELIST,
  PORTAL_CONTENT_MODEL,
} from 'Constants/portalContentModel'

import { PortalContentModelItem } from 'Types/content/content.types'
import { AppEnvEnum } from 'Types/env.types'

import {
  useActiveUser,
  useIsOrganisation,
  useIsServiceApp,
  useShowGlobalSearch,
} from 'Portal/src/hooks'

import { TOP_BAR } from './Topbar.constants'
import { generateAcls, generateUserDetails } from './Topbar.util'

export const Topbar = observer(() => {
  const { aclsStore, portalSettingsStore } = useStore()
  const { allowedAcls, isLoadingAllowedAcls } = aclsStore
  const {
    isNewJoiner,
    toggleMenuVisibility,
    isCompactSidebar,
    isUserwayEnabled,
  } = portalSettingsStore
  const { activeOrganisation, activeEmployee } = useActiveUser()
  const shouldShowGlobalSearch = useShowGlobalSearch()
  const isMobile = useMediaQuery(`(max-width: ${PORTAL_BREAKPOINTS.LG})`)
  const intl = useIntl()
  const isOrganisation = useIsOrganisation()
  const isServiceApp = useIsServiceApp()

  useEffect(() => {
    if (isUserwayEnabled && window.APP_ENV === AppEnvEnum.PRODUCTION) {
      initialiseUserway()
    }
  }, [isUserwayEnabled])

  const userDetails = useMemo(
    () =>
      generateUserDetails(isOrganisation, activeOrganisation, activeEmployee),
    [activeEmployee, activeOrganisation, isOrganisation],
  )

  const acls = useMemo(
    () => generateAcls(allowedAcls, isNewJoiner),
    [allowedAcls, isNewJoiner],
  )

  const globalSearchSelectOptions = Object.keys(PORTAL_CONTENT_MODEL).reduce(
    (acc: PortalContentModelItem[], key) =>
      GLOBAL_SEARCH_WHITELIST.includes(PORTAL_CONTENT_MODEL[key].modelType)
        ? [...acc, PORTAL_CONTENT_MODEL[key]]
        : acc,
    [],
  )

  if (!allowedAcls && isLoadingAllowedAcls) {
    return null
  }

  return (
    <div
      className={clsx(
        'topbar-container',
        isCompactSidebar && !isMobile && 'topbar-with-compact-sidebar',
      )}
      data-testid={TOP_BAR}
      role="complementary"
    >
      <div className="topbar-inner-container">
        {!isNewJoiner && !isServiceApp && (
          <button
            aria-label={intl.formatMessage({ id: 'Open_menu' })}
            className="btn-menu-mobile open-left disable-btn"
            onClick={toggleMenuVisibility}
            type="button"
          >
            <Icon icon={faBars} />
          </button>
        )}

        {isServiceApp && <Logo />}

        <div
          className={clsx(
            'd-flex align-items-center ms-auto',
            !shouldShowGlobalSearch && 'justify-content-lg-end',
            shouldShowGlobalSearch && 'justify-content-lg-between',
          )}
        >
          {shouldShowGlobalSearch && (
            <GlobalSearch selectOptions={globalSearchSelectOptions} />
          )}

          <ul className="list-unstyled topbar-right-menu float-end d-lg-flex mb-0">
            {isUserwayEnabled && (
              <li className="notification-list d-flex align-items-center justify-content-center">
                <AccessibilityButton />
              </li>
            )}

            <li className="notification-list topbar-dropdown">
              <LanguageDropdown hideLanguageName={isMobile} />
            </li>

            <li className="notification-list">
              <ACLDropdown
                aclEmployees={
                  !isNewJoiner ? acls.employees : [acls.employees[0]]
                }
                aclOrganisations={acls.organisations}
                avatar={userDetails.avatar}
                username={userDetails.userName}
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
})
