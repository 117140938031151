import { FC, useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches'
import { faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { useMediaQuery } from '@react-hookz/web'
import { clsx } from 'clsx'
import { isEmpty } from 'lodash-es'
import { observer } from 'mobx-react'

import { Icon } from 'Components/Icon/Icon'

import { useStore } from 'Stores/index'

import { useActiveUser, useUserAuthToken } from 'Hooks'

import {
  // convertToQueryString,
  debouncePromise,
  getQueryObject,
} from 'Utilities'

import { PORTAL_BREAKPOINTS } from 'Constants/constants'
import { E2E_LOADING } from 'Constants/e2e'

// import { PORTAL_CONTENT_MODEL } from 'Constants/portalContentModel'
import {
  GlobalSearchAutocompleteCategory,
  GlobalSearchAutocompleteSourceStatusType,
  GlobalSearchAutocompleteTemplateItemData,
  GlobalSearchProps,
  SourceConfiguration,
  SourceConfigurationFn,
} from './GlobalSearch.types'
import { initialiseSources } from './GlobalSearch.utils'
import { GlobalSearchAutocomplete } from './GlobalSearchAutocomplete'
// import { GlobalSearchAutocompleteFooterItem } from './GlobalSearchAutocompleteFooterItem'
import { GlobalSearchAutocompleteItem } from './GlobalSearchAutocompleteItem'
import { GlobalSearchDropdown } from './GlobalSearchDropdown'
import { GlobalSearchMobileToggler } from './GlobalSearchMobileToggler'

export const GlobalSearch: FC<GlobalSearchProps> = observer(props => {
  const { selectOptions } = props
  const isMobileView = useMediaQuery(`(max-width: ${PORTAL_BREAKPOINTS.LG})`)
  const intl = useIntl()
  // const { replace } = useHistory()
  const { search: routerSearch } = useLocation()
  // const basePageUrl = useBasePageUrl()
  const authToken = useUserAuthToken()
  const currentSearchQuery = getQueryObject(routerSearch)
  const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
    key: 'RECENT_SEARCH',
    limit: 5,
    transformSource({ source }) {
      return {
        ...source,
        onSelect({ setIsOpen }) {
          setIsOpen(true)
        },
      }
    },
  })
  const [isMobileToggled, setIsMobileToggled] = useState(false)
  const { portalSettingsStore } = useStore()
  const { isMenuVisible, toggleMenuVisibility } = portalSettingsStore
  const { activeOrganisation } = useActiveUser()

  const placeholderText = intl.formatMessage({
    id: 'GlobalSearch_InputPlaceholder',
  })
  const noResultsText = intl.formatMessage({
    id: 'GlobalSearch_NoResultsMessage',
  })
  const searchHeaderText = intl.formatMessage({ id: 'GlobalSearch_Heading' })
  const searchTermValidationText = intl.formatMessage(
    {
      id: 'GlobalSearch_InputValidationMessage',
    },
    { limit: 3 },
  )

  const setMobileToggleHandler = useCallback(() => {
    if (isMenuVisible) {
      toggleMenuVisibility()
    }

    setIsMobileToggled(isMobileToggledValue => !isMobileToggledValue)
  }, [toggleMenuVisibility, isMenuVisible])

  // const setFooterItemPathHandler = useCallback(
  //   (item: GlobalSearchAutocompleteCategory, searchTerm: string) => {
  //     const modelPath = PORTAL_CONTENT_MODEL[item.name].path
  //     const itemPath = `${basePageUrl}/${modelPath}`
  //     const footerItemSearchQuery = convertToQueryString({ searchTerm })

  //     replace({
  //       pathname: itemPath,
  //       search: footerItemSearchQuery,
  //     })
  //   },
  //   [basePageUrl, replace],
  // )

  const renderTemplateItem = useCallback(
    (templateItemData: GlobalSearchAutocompleteTemplateItemData) => {
      const elements = document.getElementsByClassName('gs-submit-button')
      const gsSubmit = elements[0] as HTMLButtonElement

      return (
        <GlobalSearchAutocompleteItem
          intl={intl}
          onDirectItemClickHandler={() => {
            gsSubmit?.click()
          }}
          onSuggestionItemClickHandler={() => {
            gsSubmit?.click()
          }}
          suggestion={templateItemData.item}
        />
      )
    },
    [intl],
  )

  const renderTemplateHeader = useCallback(
    () => searchHeaderText,
    [searchHeaderText],
  )

  const renderTemplateNoResults = useCallback(
    (status: GlobalSearchAutocompleteSourceStatusType, searchTerm: string) => {
      if (!searchTerm || searchTerm.length < 3) {
        return searchTermValidationText
      }

      if (status === 'loading') {
        return (
          <div
            className={clsx(
              'LoadingState',
              'd-flex',
              'align-items-center',
              'justify-content-center',
            )}
          >
            <Icon icon={faSpinner} size={50} spin />

            <span className="visually-hidden" data-e2e={E2E_LOADING}>
              {intl.formatMessage({ id: 'Loading' })}
            </span>
          </div>
        )
      }

      return noResultsText
    },
    [intl, noResultsText, searchTermValidationText],
  )

  const renderTemplateFooter = useCallback(
    (
      templateFooterCategories: GlobalSearchAutocompleteCategory[],
      _searchTerm: string,
    ) => {
      if (isEmpty(templateFooterCategories)) {
        return null
      }

      // const relatedItemsText = intl.formatMessage({
      //   id: 'GlobalSearch_RelatedItems',
      // })

      // const footerItems = templateFooterCategories.map(
      //   templateFooterCategory => {
      //     const contentModelItem =
      //       PORTAL_CONTENT_MODEL[templateFooterCategory.name]
      //     const categoryNameText = intl.formatMessage({
      //       id: contentModelItem?.translationId || 'Fallback',
      //     })
      //     const inCategoryText = intl.formatMessage(
      //       {
      //         id: 'GlobalSearch_InCategory',
      //       },
      //       { category: categoryNameText },
      //     )

      //     return (
      //       <GlobalSearchAutocompleteFooterItem
      //         key={templateFooterCategory.name}
      //         isMobile={isMobileView}
      //         modelIcon={contentModelItem?.icon}
      //         onFooterItemClickHandler={() =>
      //           setFooterItemPathHandler(templateFooterCategory, searchTerm)
      //         }
      //         searchLocationResultsNumber={templateFooterCategory.resultNo}
      //         searchLocationResultsNumberText={relatedItemsText}
      //         searchLocationText={inCategoryText}
      //         searchTermText={searchTerm}
      //       />
      //     )
      //   },
      // )

      return null

      // return <ul className="gs-autocomplete-footer">{footerItems}</ul>
    },
    [],
  )

  const getSources = initialiseSources({
    activeOrganisationId: activeOrganisation?.id!,
    onSelectHandler: setMobileToggleHandler,
    renderTemplateFooter,
    renderTemplateHeader,
    renderTemplateItem,
    renderTemplateNoResults,
    searchModel: currentSearchQuery?.searchModel || '',
    token: authToken,
  })

  const debouncedSource = debouncePromise<
    SourceConfigurationFn,
    SourceConfiguration
  >(getSources, 500)

  const initialMobileCollection = {
    items: [],
    source: {
      sourceId: 'gSearchInitial',
      templates: {
        noResults() {
          return searchTermValidationText
        },
      },
    },
  }

  const renderGlobalSearch = () => {
    if (isMobileView) {
      return (
        <GlobalSearchMobileToggler
          isOpen={isMobileToggled}
          onToggle={setMobileToggleHandler}
        >
          <div className="d-flex flex-column w-100">
            <GlobalSearchDropdown options={selectOptions} />

            <GlobalSearchAutocomplete
              getSources={debouncedSource}
              initialCollection={initialMobileCollection}
              isInitiallyOpen
              openOnFocus
              placeholder={placeholderText}
              plugins={[recentSearchesPlugin]}
            />
          </div>
        </GlobalSearchMobileToggler>
      )
    }

    return (
      <div
        className="GlobalSearch d-flex align-items-center w-100"
        data-axe-exclude
      >
        <GlobalSearchDropdown options={selectOptions} />

        <GlobalSearchAutocomplete
          getSources={debouncedSource}
          openOnFocus
          placeholder={placeholderText}
          plugins={[recentSearchesPlugin]}
        />
      </div>
    )
  }

  return renderGlobalSearch()
})
