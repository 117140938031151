import { useCallback, useMemo } from 'react'
import { useClearRefinements, useRefinementList } from 'react-instantsearch'
import { ActionMeta, MultiValue } from 'react-select'
import { RefinementListItem } from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList'

import { Label } from 'Components/_theme'
import { Select } from 'Components/Select/Select'

import { scrollToTop } from 'Utilities'

import { useCurrentRefinement } from 'Algolia/hooks/useCurrentRefinement/useCurrentRefinement'

import { MultiselectFilterProps } from './MultiselectFilter.types'

export const MultiselectFilter = (props: MultiselectFilterProps) => {
  const { filterProps, label, selectProps, valueMapper } = props
  const { items, refine } = useRefinementList({
    // limits the number of items displayed
    // default is 10
    // amount is also controlled by maxValuesPerFacet in <Configure />
    limit: 100,
    // enforce default sort order
    // otherwise after first selection, the order is not preserved
    sortBy: ['name:asc'],
    ...filterProps,
  })
  const { refine: clearRefine } = useClearRefinements({
    includedAttributes: [filterProps.attribute],
  })
  const currentRefinement = useCurrentRefinement(filterProps.attribute)

  const value = useMemo<MultiValue<RefinementListItem>>(() => {
    if (typeof valueMapper === 'function') {
      return currentRefinement.reduce(valueMapper, [])
    }

    return currentRefinement.map(item => ({
      count: item.count ?? 0,
      isRefined: true,
      label:
        typeof filterProps.transformItems === 'function'
          ? items.find(i => i.value === item.value)?.label ?? item.label
          : item.label,
      value: item.value.toString(),
    }))
  }, [currentRefinement, filterProps.transformItems, items, valueMapper])

  const filteredItems = useMemo(() => items.filter(item => item.label), [items])

  const handleRefine = useCallback(
    (value: string) => {
      const values = value.split(',')

      if (values.length > 0) {
        values.forEach(val => {
          refine(val)
        })
      } else {
        refine(value)
      }
    },
    [refine],
  )

  const handleChange = useCallback(
    (
      _newValue: MultiValue<RefinementListItem>,
      actionMeta: ActionMeta<RefinementListItem>,
    ) => {
      scrollToTop()

      if (actionMeta.action === 'select-option' && actionMeta.option) {
        handleRefine(actionMeta.option.value)

        return
      }

      if (actionMeta.action === 'deselect-option' && actionMeta.option) {
        handleRefine(actionMeta.option.value)

        return
      }

      if (actionMeta.action === 'remove-value' && actionMeta.removedValue) {
        handleRefine(actionMeta.removedValue.value)

        return
      }

      if (actionMeta.action === 'clear') {
        clearRefine()
      }
    },
    [clearRefine, handleRefine],
  )

  const iconProps = useMemo(() => {
    if (!selectProps?.iconProps) {
      return {
        iconProps: undefined,
      }
    }

    return {
      iconProps: {
        size: 20,
        ...selectProps.iconProps,
      },
    }
  }, [selectProps?.iconProps])

  if (filteredItems.length === 0) {
    return null
  }

  return (
    <div className="Filter-input-container">
      <Label>{label}</Label>

      <Select
        isClearable
        isMulti
        isSearchable
        onChange={handleChange}
        options={filteredItems}
        value={value}
        {...selectProps}
        {...iconProps}
      />
    </div>
  )
}
