import { observer } from 'mobx-react'

import { useActiveUser } from 'Hooks'

import { InstantsearchProvider } from 'Portal/src/providers'
import { InstantsearchIndexProvider } from 'Portal/src/providers/InstantsearchIndexProvider/InstantsearchIndexProvider'

import { OrdersContent } from './Orders.content'

export const OrdersInstantsearch = observer(() => {
  const { activeEmployee, activeOrganisation } = useActiveUser()
  const organisationId =
    activeEmployee?.organisation?.id ?? activeOrganisation!.id

  return (
    <InstantsearchIndexProvider indexName="caseitems">
      <InstantsearchProvider
        instantSearchConfig={{
          filters: `case_item.organisation_id:${organisationId}`,
        }}
        sortBy="case_item.id:desc"
      >
        <OrdersContent />
      </InstantsearchProvider>
    </InstantsearchIndexProvider>
  )
})
