import { FormattedMessage } from 'react-intl'
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons'
import { clsx } from 'clsx'

import { Alert } from 'Components/_theme'

import { E2E_DISABLED_ORDER_PROCESS_ALERT } from 'Constants/e2e'

import { OrderDisabledAlertProps } from './OrderDisabledAlert.types'

export const OrderDisabledAlert = (props: OrderDisabledAlertProps) => {
  const { className } = props

  return (
    <div className={clsx(className)}>
      <Alert
        data-e2e={E2E_DISABLED_ORDER_PROCESS_ALERT}
        iconLeft={faCircleInfo}
        variant="info"
      >
        <FormattedMessage id="Alert_disabled_order_process" />
        &nbsp;
        <a href="mailto:service@everphone.de">service@everphone.de</a>
      </Alert>
    </div>
  )
}
