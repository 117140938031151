import { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useMutation } from '@apollo/client'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons'
import { clsx } from 'clsx'
import { observer } from 'mobx-react'

import { Icon } from 'Components/Icon/Icon'

import { useStore } from 'Stores/index'
import { I18nConfig } from 'Stores/portalSettingsStore/portalSettingsStore.types'

import { useIsServiceApp } from 'Hooks'

import { E2E_LANGUAGE_DROPDOWN } from 'Constants/e2e'
import {
  mutationUpdateUser,
  mutationUpdateUserVariables,
} from 'Constants/graphql/mutations/__generated__/mutationUpdateUser'
import { MUTATION_UPDATE_USER } from 'Constants/graphql/mutations/MutationUpdateUser'
import { AVAILABLE_LANGUAGES_ARRAY } from 'Constants/locale'

import { Language } from 'Portal/__generated__/globalTypes'
import { buttonize } from 'Portal/src/utilities'

import { LANGUAGE_DROPDOWN_CONTENT } from './LanguageDropdown.constants'
import { LanguageDropdownProps } from './LanguageDropdown.types'

export const LanguageDropdown = observer((props: LanguageDropdownProps) => {
  const { hideLanguageName, isLandingPage } = props
  const { portalSettingsStore, userStore } = useStore()
  const { i18n, setI18n } = portalSettingsStore
  const {
    user: { userId },
  } = userStore
  const [dropdownOpen, setDropDownOpen] = useState(false)
  const intl = useIntl()
  const isServiceApp = useIsServiceApp()

  const toggleDropdown = () => {
    setDropDownOpen(prevState => !prevState)
  }

  const availableLanguagesWithoutCurrent = AVAILABLE_LANGUAGES_ARRAY.filter(
    ({ language }) => language !== i18n.language,
  )

  const [updateUser] = useMutation<
    mutationUpdateUser,
    mutationUpdateUserVariables
  >(MUTATION_UPDATE_USER)

  const handleLanguageChange = (
    _event:
      | React.MouseEvent<HTMLSpanElement>
      | React.KeyboardEvent<HTMLSpanElement>,
    config: I18nConfig,
  ) => {
    setI18n(config)

    if (!isLandingPage) {
      updateUser({
        variables: {
          input: {
            id: userId?.toString() ?? '',
            language: config.language as Language,
          },
        },
      })
    }

    window.Intercom('update', {
      language_override: config.language === 'de' ? 'de' : 'en',
    })
  }

  return (
    <Dropdown
      className="ACLDropdown"
      onToggle={toggleDropdown}
      show={dropdownOpen}
    >
      <Dropdown.Toggle
        aria-expanded={dropdownOpen}
        aria-label={intl.formatMessage({ id: 'Language' })}
        as="button"
        className={clsx(
          'nav-link dropdown-toggle nav-user dropdown-arrow-none me-0 border-end-0',
          (isServiceApp || isLandingPage) && 'border-start-0',
        )}
        data-e2e={E2E_LANGUAGE_DROPDOWN}
        data-toggle="dropdown"
        onClick={toggleDropdown}
      >
        <div className="acl-dropdown d-flex flex-row align-items-center">
          <img
            alt={i18n.name}
            className="me-0 me-md-2"
            src={i18n.flag}
            width={24}
          />

          {!hideLanguageName && (
            <span className="align-middle">{i18n.name}</span>
          )}

          <Icon
            className="d-none d-md-block ms-2 mb-1"
            icon={dropdownOpen ? faChevronUp : faChevronDown}
            size={12}
          />
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="dropdown-menu-animated topbar-dropdown-menu"
        data-testid={LANGUAGE_DROPDOWN_CONTENT}
        onClick={toggleDropdown}
      >
        <div>
          {availableLanguagesWithoutCurrent.map(lang => (
            <div
              key={lang.name}
              className="dropdown-item notify-item"
              {...buttonize(handleLanguageChange, lang)}
              aria-label={`${lang.name} ${intl.formatMessage({ id: 'Language' })}`}
            >
              <img
                alt={lang.name}
                className="me-2"
                src={lang.flag}
                width={24}
              />
              &nbsp;
              <span className="align-middle">{lang.name}</span>
            </div>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
})
