import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { ApolloError, useMutation } from '@apollo/client'
import { zodResolver } from '@hookform/resolvers/zod'

import { setFormErrors } from 'Utilities'

import {
  mutationEmployeeUpdate,
  mutationEmployeeUpdateVariables,
} from 'Constants/graphql/mutations/__generated__/mutationEmployeeUpdate'
import { MUTATION_EMPLOYEE_UPDATE } from 'Constants/graphql/mutations/MutationEmployeeUpdate'

import { useActiveUser } from 'Portal/src/hooks'
import {
  cleanPhoneNumberWhenOnlyPrefix,
  createSelectOption,
  getSelectValue,
  validatePhoneNumber,
} from 'Portal/src/utilities/form/form.util'

import { editEmployeeFormEditEmployeeSchema } from '../EditEmployeeFormModal.schema'
import {
  EditEmployeeFormCreateEmployeeSchema,
  EditEmployeeFormEditEmployeeSchema,
  EditEmployeeFormModalEditEmployeeProps,
} from '../EditEmployeeFormModal.types'
import { EditEmployeeFormModalForm } from './EditEmployeeFormModalForm'

export const EditEmployeeFormModalEditEmployee = (
  props: EditEmployeeFormModalEditEmployeeProps,
) => {
  const { employee, onHide, onSuccess } = props
  const [submitError, setSubmitError] = useState<ApolloError | null>(null)

  const { activeOrganisation } = useActiveUser()
  const organisationID = activeOrganisation!.id

  const formMethods = useForm<EditEmployeeFormEditEmployeeSchema>({
    defaultValues: {
      costCenterID: createSelectOption({
        label: employee.costCenter?.name ?? '',
        value: employee.costCenter?.id?.toString() ?? '',
      }),
      email: employee.contact.email ?? null,
      externalReference: employee.externalReference ?? '',
      firstName: employee.contact.firstName ?? '',
      lastName: employee.contact.lastName ?? '',
      newCostCenter: null,
      officeID: createSelectOption({
        label: employee.office?.locationName ?? '',
        value: employee.office?.id?.toString() ?? '',
      }),
      phoneNumber: employee.contact.phoneNumber ?? '',
      rentCategoryTierID: createSelectOption({
        label:
          employee.organisation?.rentCategoryTiers?.rentCategoryTiers?.find(
            tier => tier.id === employee?.rentCategoryTierID || '',
          )?.name ?? '',
        value: employee.rentCategoryTierID?.toString() ?? '',
      }),
    },
    resolver: zodResolver(editEmployeeFormEditEmployeeSchema),
  })

  const [submitEditEmployee, { loading: isLoading }] = useMutation<
    mutationEmployeeUpdate,
    mutationEmployeeUpdateVariables
  >(MUTATION_EMPLOYEE_UPDATE, {
    onCompleted: data => {
      if (data.employeeUpdate.__typename === 'EmployeeUpdateProblem') {
        setFormErrors<EditEmployeeFormEditEmployeeSchema>(
          data,
          'employeeUpdate.invalidInputs',
          formMethods.setError,
        )

        return
      }

      onSuccess(!!formMethods.getValues('newCostCenter'))
    },
    onError: error => {
      setSubmitError(error)
    },
  })

  const handleSubmit = (
    data:
      | EditEmployeeFormCreateEmployeeSchema
      | EditEmployeeFormEditEmployeeSchema,
  ) => {
    if ('allowLogin' in data) {
      return
    }

    if (data.phoneNumber) {
      data.phoneNumber = cleanPhoneNumberWhenOnlyPrefix(
        validatePhoneNumber(data.phoneNumber),
      )
    }

    submitEditEmployee({
      variables: {
        input: {
          ...data,
          costCenterID: getSelectValue(data.costCenterID) || null,
          email: data.email || null,
          id: employee.id.toString(),
          officeID: getSelectValue(data.officeID) || '0',
          organisationID,
          rentCategoryTierID: getSelectValue(data.rentCategoryTierID) || null,
        },
      },
    })
  }

  return (
    <EditEmployeeFormModalForm
      employee={employee}
      formMethods={formMethods as any}
      isSubmitting={isLoading}
      onHide={onHide}
      onSubmit={handleSubmit}
      organisationID={organisationID}
      submitError={submitError}
    />
  )
}
