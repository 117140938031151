import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { AccountInfoProps } from 'Modules/account/components/AccountInfo/AccountInfo.types'

import { Card, CardBody } from 'Components/_theme'
import { Icon } from 'Components/Icon/Icon'

import condensedLogo from 'Assets/images/logos/condensed_logo.svg'

export const AccountInfo = (props: AccountInfoProps) => {
  const { username, userOrganisationName, userEmail, avatar } = props
  const intl = useIntl()

  const renderUserAvatar = () => {
    if (avatar) {
      return (
        <img
          alt={intl.formatMessage({ id: 'Img_alt_org_logo' })}
          src={avatar}
        />
      )
    }

    return <img alt="Logo" src={condensedLogo} />
  }

  return (
    <Card className="mb-4">
      <CardBody className="account-card">
        <div className="account-card-info">
          {renderUserAvatar()}

          <span className="account-name pt-2">{username}</span>

          <span>{userOrganisationName}</span>

          <span>{userEmail}</span>
        </div>

        <Link
          className="account-info-chevron"
          to={GLOBAL_ROUTE_PATHS.employee.dashboard}
        >
          <Icon icon={faChevronRight} size={16} />
        </Link>
      </CardBody>
    </Card>
  )
}
