import { gql } from '@apollo/client'

export const GET_ORGANISATION_COST_CENTERS = gql`
  query getOrganisationCostCenters($id: ID!) {
    organisation(id: $id) {
      costCenters {
        totalCount
        costCenters {
          id
          name
        }
      }
    }
  }
`
