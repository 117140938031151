/* eslint-disable sort-keys-fix/sort-keys-fix */
import { useIntl } from 'react-intl'
import {
  faBusinessTime,
  faLaptopMobile,
  faTrademark,
} from '@fortawesome/pro-solid-svg-icons'
import { observer } from 'mobx-react'

import { FilterColumn } from 'Components/FilterColumn/FilterColumn'

import { useStore } from 'Stores/index'

import {
  E2E_RENTAL_FILTER_COPAYMENT_AMOUNT,
  E2E_RENTAL_FILTER_EMPLOYEE_EXIT_DATE,
  E2E_RENTAL_FILTER_EMPLOYEE_EXIT_DATE_TOOLBAR,
  E2E_RENTAL_FILTER_END_AT,
  E2E_RENTAL_FILTER_END_AT_TOOLBAR,
  E2E_RENTAL_FILTER_IS_PROLONGED,
  E2E_RENTAL_FILTER_MANUFACTURER,
  E2E_RENTAL_FILTER_MINIMUM_CONTRACT_DURATION,
  E2E_RENTAL_FILTER_MODEL,
  E2E_RENTAL_FILTER_START_AT,
  E2E_RENTAL_FILTER_START_AT_TOOLBAR,
} from 'Constants/e2e'

import { MultidatepickerFilter } from 'Algolia/filters/MultidatepickerFilter/MultidatepickerFilter'
import { MultiselectFilter } from 'Algolia/filters/MultiselectFilter/MultiselectFilter'
import { RangeFilter } from 'Algolia/filters/RangeFilter/RangeFilter'
import { ToggleFilter } from 'Algolia/filters/ToggleFilter/ToggleFilter'
import { sortByNumberAsc } from 'Algolia/utils/sorting/sorting.utils'

import { RentalsFilterColumnProps } from './RentalsFilterColumn.types'

export const RentalsFilterColumn = observer(
  (props: RentalsFilterColumnProps) => {
    const { onCloseFilterColumn, isVisible } = props
    const { portalConfigStore } = useStore()
    const { portalConfig } = portalConfigStore
    const isProlongingEnabled = portalConfig?.isRentalProlongingEnabled ?? false
    const intl = useIntl()

    return (
      <FilterColumn isVisible={isVisible} onClose={onCloseFilterColumn}>
        <MultidatepickerFilter
          autoComplete="off"
          filterProps={{ attribute: 'rental.start_at' }}
          label={intl.formatMessage({
            id: 'typesenseAttribute_rental_start_at',
          })}
          multidatePickerProps={{
            e2eSelector: E2E_RENTAL_FILTER_START_AT,
            maxDate: new Date().setHours(23, 59, 59),
            toolbarE2eSelector: E2E_RENTAL_FILTER_START_AT_TOOLBAR,
            /* eslint-disable sort-keys-fix/sort-keys-fix */
            customToolbarConfig: {
              today: {
                scope: 'today',
              },
              last_7days: {
                length: 7,
                period: 'days',
                scope: 'past',
              },
              last_30days: {
                length: 30,
                period: 'days',
                scope: 'past',
              },
              last_6months: {
                length: 6,
                period: 'months',
                scope: 'past',
              },
            },
            /* eslint-enable sort-keys-fix/sort-keys-fix */
          }}
        />

        <MultidatepickerFilter
          autoComplete="off"
          filterProps={{ attribute: 'rental.end_at' }}
          label={intl.formatMessage({
            id: 'typesenseAttribute_rental_end_at',
          })}
          multidatePickerProps={{
            e2eSelector: E2E_RENTAL_FILTER_END_AT,
            toolbarE2eSelector: E2E_RENTAL_FILTER_END_AT_TOOLBAR,
            /* eslint-disable sort-keys-fix/sort-keys-fix */
            customToolbarConfig: {
              today: {
                scope: 'today',
              },
              last_7days: {
                length: 7,
                period: 'days',
                scope: 'past',
              },
              last_30days: {
                length: 30,
                period: 'days',
                scope: 'past',
              },
              last_6months: {
                length: 6,
                period: 'months',
                scope: 'past',
              },
            },
            /* eslint-enable sort-keys-fix/sort-keys-fix */
          }}
        />

        <MultidatepickerFilter
          autoComplete="off"
          disableSingleDateRefine
          filterProps={{ attribute: 'rental.upgrade_date' }}
          label={intl.formatMessage({
            id: 'typesenseAttribute_rental_upgrade_date',
          })}
          multidatePickerProps={{
            e2eSelector: E2E_RENTAL_FILTER_END_AT,
            toolbarE2eSelector: E2E_RENTAL_FILTER_END_AT_TOOLBAR,
            /* eslint-disable sort-keys-fix/sort-keys-fix */
            customToolbarConfig: {
              until_today: {
                scope: 'until_today',
              },
              last_7days: {
                length: 7,
                period: 'days',
                scope: 'past',
              },
              last_30days: {
                length: 30,
                period: 'days',
                scope: 'past',
              },
              last_6months: {
                length: 6,
                period: 'months',
                scope: 'past',
              },
            },
            /* eslint-enable sort-keys-fix/sort-keys-fix */
          }}
        />

        <MultidatepickerFilter
          autoComplete="off"
          filterProps={{ attribute: 'rental.employee.exit_date' }}
          label={intl.formatMessage({
            id: 'typesenseAttribute_rental_employee_exit_date',
          })}
          multidatePickerProps={{
            e2eSelector: E2E_RENTAL_FILTER_EMPLOYEE_EXIT_DATE,
            toolbarE2eSelector: E2E_RENTAL_FILTER_EMPLOYEE_EXIT_DATE_TOOLBAR,
          }}
        />

        <MultiselectFilter
          filterProps={{ attribute: 'rental.stocklist_item.manufacturer' }}
          label={intl.formatMessage({
            id: 'typesenseAttribute_rental_stocklist_item_manufacturer',
          })}
          selectProps={{
            iconProps: {
              icon: faTrademark,
            },
            instanceId: E2E_RENTAL_FILTER_MANUFACTURER,
          }}
        />

        <MultiselectFilter
          filterProps={{ attribute: 'rental.stocklist_item.codename' }}
          label={intl.formatMessage({
            id: 'typesenseAttribute_rental_stocklist_item_codename',
          })}
          selectProps={{
            iconProps: {
              icon: faLaptopMobile,
            },
            instanceId: E2E_RENTAL_FILTER_MODEL,
          }}
        />

        <MultiselectFilter
          filterProps={{
            attribute: 'rental.minimum_contract_duration',
            sortBy: sortByNumberAsc,
          }}
          label={intl.formatMessage({
            id: 'typesenseAttribute_rental_minimum_contract_duration',
          })}
          selectProps={{
            iconProps: {
              icon: faBusinessTime,
            },
            instanceId: E2E_RENTAL_FILTER_MINIMUM_CONTRACT_DURATION,
          }}
        />

        <RangeFilter
          filterProps={{
            attribute: 'rental.rental_price.copayment_amount',
            precision: 2,
          }}
          label={intl.formatMessage({
            id: 'typesenseAttribute_rental_rental_price_copayment_amount',
          })}
          rangeInputProps={{
            e2eSelector: E2E_RENTAL_FILTER_COPAYMENT_AMOUNT,
            range: true,
            step: 1,
            type: 'input',
          }}
        />

        {isProlongingEnabled && (
          <ToggleFilter
            checkboxButtonGroupProps={{
              e2eSelector: E2E_RENTAL_FILTER_IS_PROLONGED,
              name: 'isProlonged',
            }}
            filterProps={{ attribute: 'rental.is_prolonged' }}
            label={intl.formatMessage({
              id: 'typesenseAttribute_rental_is_prolonged',
            })}
          />
        )}
      </FilterColumn>
    )
  },
)
