import { components, GroupBase } from 'react-select'
import { faXmark } from '@fortawesome/pro-solid-svg-icons'

import { Icon } from 'Components/Icon/Icon'
import { SelectOption } from 'Components/Select/Select.types'

import { SelectClearIndicatorProps } from './SelectClearIndicator.types'

export const SelectClearIndicator = <
  Option extends SelectOption,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: SelectClearIndicatorProps<Option, IsMulti, Group>,
) => (
  <components.ClearIndicator {...props}>
    <Icon icon={faXmark} size={16} />
  </components.ClearIndicator>
)
