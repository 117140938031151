import { IntlShape } from 'react-intl'
import { DateTime } from 'luxon'

export const getLastFetchTranslation = (
  intl: IntlShape,
  lastFetch: DateTime | null,
) => {
  if (!lastFetch) {
    return null
  }

  const diff = lastFetch.diffNow(['days', 'hours', 'minutes', 'seconds'])

  if (diff.minutes === 0 && diff.hours === 0 && diff.days === 0) {
    return intl.formatMessage(
      { id: 'Last_fetch_seconds' },
      { seconds: Math.round(-diff.seconds) },
    )
  }

  if (diff.hours === 0 && diff.days === 0) {
    return intl.formatMessage(
      { id: 'Last_fetch_minutes' },
      { minutes: Math.round(-diff.minutes) },
    )
  }

  if (diff.days === 0) {
    return intl.formatMessage(
      { id: 'Last_fetch_hours' },
      { hours: Math.round(-diff.hours) },
    )
  }

  if (-diff.days > 0) {
    return intl.formatMessage(
      { id: 'Last_fetch_days' },
      { days: Math.round(-diff.days) },
    )
  }

  return null
}
