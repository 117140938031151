import { Image } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { faRefresh } from '@fortawesome/pro-solid-svg-icons'
import { clsx } from 'clsx'

import { Heading, PrimaryButton } from 'Components/_theme'

import { E2E_ERROR } from 'Constants/e2e'

import errorPageImage from 'Assets/images/errorpage/refresh_the_page.svg'

import { PAGE_ERROR_STATE } from './PageErrorState.constants'
import { PageErrorStateProps } from './PageErrorState.types'

export const PageErrorState = (props: PageErrorStateProps) => {
  const { children, fullScreen } = props
  const intl = useIntl()

  const handleRefreshPage = () => {
    // eslint-disable-next-line no-restricted-globals
    location.reload()
  }

  const classNames = clsx(
    'PageErrorState',
    fullScreen && 'PageErrorState-fullscreen',
  )

  return (
    <div
      className={classNames}
      data-e2e={E2E_ERROR}
      data-testid={PAGE_ERROR_STATE}
    >
      <Image
        alt={intl.formatMessage({
          id: 'Img_alt_page_error',
        })}
        src={errorPageImage}
      />

      <Heading as={3} size={2}>
        <FormattedMessage id="Error_heading" />
      </Heading>

      <span className="text-center mt-1">
        {children || (
          <>
            <FormattedMessage id="Error_occurred" />
            <br />
            <FormattedMessage id="Error_refresh_page" />
          </>
        )}
      </span>

      <PrimaryButton
        aria-label={intl.formatMessage({ id: 'Refresh_the_page' })}
        className="mt-4"
        iconLeft={faRefresh}
        onClick={handleRefreshPage}
      >
        <FormattedMessage id="Refresh_the_page" />
      </PrimaryButton>
    </div>
  )
}
