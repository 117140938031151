import { observer } from 'mobx-react'

import { NewJoinerLogin } from 'Modules/auth/components/NewJoinerLogin/NewJoinerLogin'
import { PortalLogin } from 'Modules/auth/components/PortalLogin/PortalLogin'

import { useStore } from 'Stores/index'
import { UserModeEnum } from 'Stores/portalSettingsStore/portalSettingsStore.types'

export const Login = observer(() => {
  const { portalSettingsStore } = useStore()
  const { userMode } = portalSettingsStore

  if (userMode === UserModeEnum.NEW_JOINER) {
    return <NewJoinerLogin />
  }

  return <PortalLogin />
})
