import { FormProvider, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation } from '@apollo/client'
import { zodResolver } from '@hookform/resolvers/zod'

import { ButtonGroup, Card, CardBody, CardHeader } from 'Components/_theme'
import { Form } from 'Components/Form/Form'
import { FormOptionalMessage } from 'Components/FormOptionalMessage/FormOptionalMessage'
import { ModalErrorState } from 'Components/ModalErrorState/ModalErrorState'

import {
  mutationLegacyUpgradeDevice,
  mutationLegacyUpgradeDeviceVariables,
} from 'Constants/graphql/mutations/__generated__/mutationLegacyUpgradeDevice'
import { MUTATION_LEGACY_UPGRADE_DEVICE } from 'Constants/graphql/mutations/MutationLegacyUpgradeDevice'

import { iconUpgradeDevice } from 'Icons/iconUpgradeDevice'

import { legacyUpgradeDeviceModalFormSchema } from './LegacyUpgradeDeviceModal.schema'
import {
  LegacyUpgradeDeviceModalFormSchema,
  LegacyUpgradeDeviceModalProps,
} from './LegacyUpgradeDeviceModal.types'

export const LegacyUpgradeDeviceModal = (
  props: LegacyUpgradeDeviceModalProps,
) => {
  const { handleSubmit, onClose, rental } = props

  const intl = useIntl()

  const formMethods = useForm<LegacyUpgradeDeviceModalFormSchema>({
    defaultValues: {
      message: '',
    },
    resolver: zodResolver(legacyUpgradeDeviceModalFormSchema),
  })

  const [createLegacyUpgradeCaseItem, { loading, error }] = useMutation<
    mutationLegacyUpgradeDevice,
    mutationLegacyUpgradeDeviceVariables
  >(MUTATION_LEGACY_UPGRADE_DEVICE, {
    onCompleted: () => {
      handleSubmit()
    },
  })

  const handleFormSubmit = formMethods.handleSubmit(data => {
    createLegacyUpgradeCaseItem({
      variables: {
        input: {
          message: data.message,
          rentalID: rental.id,
        },
      },
    })
  })

  return (
    <Card>
      <CardHeader icon={iconUpgradeDevice}>
        <FormattedMessage id="Upgrade_device" />
      </CardHeader>

      <CardBody>
        <FormProvider {...formMethods}>
          <Form
            id="LEGACY_UPGRADE_DEVICE_MODAL_FORM"
            onSubmit={handleFormSubmit}
          >
            {error ? <ModalErrorState /> : <FormOptionalMessage />}

            <ButtonGroup
              leftButton={{
                onClick: onClose,
                text: intl.formatMessage({ id: 'Cancel' }),
              }}
              rightButton={{
                disabled: !!error || loading,
                text: intl.formatMessage({
                  id: loading ? 'Loading' : 'Upgrade_device',
                }),
                type: 'submit',
              }}
            />
          </Form>
        </FormProvider>
      </CardBody>
    </Card>
  )
}
