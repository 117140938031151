import { clsx } from 'clsx'

import { E2E_ERROR } from 'Constants/e2e'

import { FeedbackTextProps } from './FeedbackText.types'

export const FeedbackText = (props: FeedbackTextProps) => {
  const { children, className, isInvalid, ...restProps } = props

  const classNames = clsx(
    'FeedbackText',
    isInvalid && 'FeedbackText-invalid',
    className,
  )

  return (
    <span
      className={classNames}
      data-e2e={isInvalid && E2E_ERROR}
      {...restProps}
      role="alert"
    >
      {children}
    </span>
  )
}
