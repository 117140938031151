import { useStore } from 'Stores/index'

import { ThemesEnum } from 'Constants/theme'

/**
 * useTheme hook is used to get current app theme
 */
export const useTheme = () => {
  const { portalConfigStore } = useStore()
  const { portalConfig } = portalConfigStore

  const isEverphoneTheme = !portalConfig?.customTheme
  const isTelekomTheme = portalConfig?.customTheme === `${ThemesEnum.TELEKOM}`

  return { isEverphoneTheme, isTelekomTheme }
}
