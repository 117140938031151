import { ContactDetails } from 'Modules/purchase/components/ContactDetails/ContactDetails'

import { Footer } from 'Components/Footer/Footer'

import decorBottomRight from 'Assets/images/contact/contact_decor_bottom_right.svg'
import decorTopRight from 'Assets/images/contact/contact_decor_top_right.svg'
import everphoneLogoLight from 'Assets/images/logos/everphone_logo_light.svg'

import { PurchaseLayoutProps } from './PurchaseLayout.types'

export const PurchaseLayout = (props: PurchaseLayoutProps) => {
  const { children } = props

  // TODO: extract this higher and share it with contact landing page
  return (
    <div className="PurchaseLayout">
      <div className="PurchaseLayout-content row">
        <div className="PurchaseLayout-content-left col-12 col-xl-8">
          <div className="PurchaseLayout-logo">
            <img alt="Everphone Logo" src={everphoneLogoLight} />
          </div>

          <div className="PurchaseLayout-main-content">
            <div className="d-flex justify-content-center align-items-center">
              <div className="col-8">{children}</div>
            </div>{' '}
          </div>

          <Footer className="d-none d-xl-flex" variant="light" />
        </div>

        <div className="PurchaseLayout-content-right col-12 col-xl-4">
          <img
            alt="Everphone shapes"
            className="decor-img img-fluid position-absolute px-6 top-0"
            src={decorTopRight}
          />

          <ContactDetails />

          <img
            alt="Everphone shapes"
            className="decor-img img-fluid position-absolute px-6 bottom-0"
            src={decorBottomRight}
          />
        </div>
      </div>

      <Footer className="d-flex d-xl-none" variant="light" />
    </div>
  )
}
