import { store } from 'Stores/index'

import { PortalSectionEnum } from 'Constants/portalSection'

import { Invoices } from '../pages'
import { InvoicesRoutePathsEnum } from './invoicesRouteConfig.types'

export const INVOICE_ROUTES = [
  {
    component: Invoices,
    onRender: () => {
      const { portalSettingsStore } = store
      const { setCurrentPortalSection } = portalSettingsStore

      setCurrentPortalSection(PortalSectionEnum.Invoices)
    },
    path: `${InvoicesRoutePathsEnum.INVOICES}`,
  },
]
