import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { ApolloError, useMutation } from '@apollo/client'
import { zodResolver } from '@hookform/resolvers/zod'

import { setFormErrors } from 'Utilities'

import { EMPTY_SELECT_OPTION } from 'Constants/form'
import {
  mutationEmployeeOnboarding,
  mutationEmployeeOnboardingVariables,
} from 'Constants/graphql/mutations/__generated__/mutationEmployeeOnboarding'
import { MUTATION_EMPLOYEE_ONBOARDING } from 'Constants/graphql/mutations/MutationEmployeeOnboarding'

import { useActiveUser } from 'Portal/src/hooks'
import {
  cleanPhoneNumberWhenOnlyPrefix,
  getSelectValue,
  validatePhoneNumber,
} from 'Portal/src/utilities/form/form.util'

import { editEmployeeFormCreateEmployeeSchema } from '../EditEmployeeFormModal.schema'
import {
  EditEmployeeFormCreateEmployeeSchema,
  EditEmployeeFormEditEmployeeSchema,
  EditEmployeeFormModalCreateEmployeeProps,
} from '../EditEmployeeFormModal.types'
import { EditEmployeeFormModalForm } from './EditEmployeeFormModalForm'

export const EditEmployeeFormModalCreateEmployee = (
  props: EditEmployeeFormModalCreateEmployeeProps,
) => {
  const { onHide, onSuccess } = props
  const [submitError, setSubmitError] = useState<ApolloError | null>(null)

  const { activeOrganisation } = useActiveUser()
  const organisationID = activeOrganisation!.id

  const formMethods = useForm<EditEmployeeFormCreateEmployeeSchema>({
    defaultValues: {
      allowLogin: false,
      costCenterID: EMPTY_SELECT_OPTION,
      email: '',
      externalReference: '',
      firstName: '',
      lastName: '',
      newCostCenter: null,
      officeID: null,
      phoneNumber: '',
      rentCategoryTierID: EMPTY_SELECT_OPTION,
    },
    resolver: zodResolver(editEmployeeFormCreateEmployeeSchema),
  })

  const [submitCreateEmployee, { loading: isLoading }] = useMutation<
    mutationEmployeeOnboarding,
    mutationEmployeeOnboardingVariables
  >(MUTATION_EMPLOYEE_ONBOARDING, {
    onCompleted: data => {
      if (
        data.createEmployeeOnboarding.__typename === 'EmployeeOnboardingProblem'
      ) {
        setFormErrors<EditEmployeeFormCreateEmployeeSchema>(
          data,
          'createEmployeeOnboarding.invalidInputs',
          formMethods.setError,
        )

        return
      }

      onSuccess(!!formMethods.getValues('newCostCenter'))
    },
    onError: error => {
      setSubmitError(error)
    },
  })

  const handleSubmit = (
    data:
      | EditEmployeeFormCreateEmployeeSchema
      | EditEmployeeFormEditEmployeeSchema,
  ) => {
    if (!('allowLogin' in data)) {
      return
    }

    data.email = data.email || null

    if (data.phoneNumber) {
      data.phoneNumber = cleanPhoneNumberWhenOnlyPrefix(
        validatePhoneNumber(data.phoneNumber),
      )
    }

    submitCreateEmployee({
      variables: {
        input: {
          ...data,
          costCenterID: getSelectValue(data.costCenterID) || null,
          officeID: getSelectValue(data.officeID) || '0',
          organisationID,
          rentCategoryTierID: getSelectValue(data.rentCategoryTierID) || null,
        },
      },
    })
  }

  return (
    <EditEmployeeFormModalForm
      employee={null}
      formMethods={formMethods as any}
      isSubmitting={isLoading}
      onHide={onHide}
      onSubmit={handleSubmit}
      organisationID={organisationID}
      submitError={submitError}
    />
  )
}
