import { gql } from '@apollo/client'

export const MUTATION_EARLY_UPGRADE_DEVICE = gql`
  mutation mutationEarlyUpgradeDevice($input: UpgradeDeviceCaseItemInput!) {
    earlyUpgradeDevice(input: $input) {
      caseItem {
        id
        subCaseItems {
          id
          data {
            deviceDefinition {
              id
              name
            }
            requestedRentCategory {
              deviceDefinition {
                id
              }
            }
          }
        }
      }
    }
  }
`
