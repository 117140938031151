import { backendDeTranslations } from './backend/backend.de'
import { dataPrivacyTranslations } from './dataPrivacy/dataPrivacy.de'

const de = {
  Accept: 'Akzeptieren',
  AccessoriesChooser_limitation_info:
    'Du kannst nur {amount} {amount, plural, =0 {Zubehörartikel} =1 {Zubehörartikel} other {Zubehörartikel}} auswählen. Bitte entferne einen vorhandenen Zubehörartikel, um einen anderen zu wählen.',
  AccessoriesChooser_limitation_info_one:
    'Du kannst nur einen Zubehörartikel auswählen. Bitte entferne einen vorhandenen Zubehörartikel, um einen anderen zu wählen.',
  AccessoriesChooser_skip: 'Überspringen',
  AccessoriesChooser_total_products: 'Wir haben {total} Produkte gefunden',
  Action: 'Action',
  Active_employees: 'Aktive Mitarbeiter*innen',
  Active_phone_plans: 'Aktive Tarife',
  Active_rentals: 'Aktive Mietgeräte',
  Address: 'Adresse',
  Addresses: 'Adressen',
  Admin: 'Admin',
  Admin_dashboard_early_upgrade_text:
    'Rental eligible for upgrade/ early upgrade',
  Agent: 'Bearbeiter*in',
  Alert_disabled_order_process:
    'Aktuell sind für dich keine Geräte zur Auswahl hinterlegt. Für eine Aktualisierung deines Portfolios kontaktiere uns bitte per E-Mail an ',
  Amount: 'Menge',
  Assign: 'Zuweisen',
  Assign_device: 'Gerät zuweisen',
  Assign_device_to_employee: 'Gerät einem*r Mitarbeiter*in zuweisen',
  Attachment_A: 'Anlage A',
  Attn: 'z. Hd.',
  Available: 'verfügbar',
  Awaiting_processing: 'Zur Bearbeitung bereit',
  Back: 'Zurück',
  Back_to_login: 'Back to login',
  BenefitSlide_get_in_touch_content:
    'Hier im Portal kannst du deine Firmengeräte bei Everphone ganz einfach selbst verwalten: Hier organisierst du deine Geräte, bestellst kostenlos Austauschgeräte bei Defekten oder orderst Upgrades. Über das Portal hast du auch den direkten Draht zu unseren FAQs und per Chat zu unserem Serviceteam.',
  BenefitSlide_get_in_touch_header: 'Kontaktiere uns',
  BenefitSlide_manage_devices_content:
    'Hier findest du den Überblick über deine Devices und Tarife, inklusive Geräte-IDs, Mietlaufzeiten und weiteren Basisinfos zu deinen Geräten.',
  BenefitSlide_manage_devices_header: 'Geräte verwalten',
  BenefitSlide_self_service_content:
    'Stimmt etwas nicht mit deinem Gerät? Löse sämtliche Probleme direkt mit uns. Deine IT oder deinen Admin musst du damit nicht belasten.',
  BenefitSlide_self_service_header: 'Selbst-Service',
  BenefitSlide_upgrade_devices_content:
    'Ist für dein Gerät ein kostenloses Upgrade verfügbar? Hier kannst du deine Bestelloptionen für die neuesten Modelle sehen.',
  BenefitSlide_upgrade_devices_header: 'Geräte-Upgrade',
  Broken_device: 'Defektes Gerät',
  Business_line: 'Business_line',
  Buy_leaseback: 'Buy_leaseback',
  Cancel: 'Abbrechen',
  Cancel_device: 'Gerät kündigen',
  Cancel_phone_plan: 'Tarif Kündigen',
  Cancelled: 'Storniert',
  CardActions_noActionEmployee_description:
    'Im Moment kannst du keine Änderungen an diesem*r Mitarbeiter*in vornehmen',
  CardActions_noActionRental_description:
    'Im Moment kannst du keine Änderungen an deiner Miete vornehmen',
  CardActions_noActionReturn_description:
    'Im Moment kannst du keine Änderungen an dieser Rückgabe vornehmen',
  CardActions_noAction_title: 'Keine Aktion vorhanden',
  Carrier: 'Paketdienst',
  ChangeExitDate: 'Austrittsdatum ändern',
  ChangeOfDeliveryAddress: 'Meine Lieferanschrift hat sich geändert',
  Change_phone_plan: 'Tarif Anpassen',
  Choose_new_device: 'Neues Gerät auswählen',
  Choose_phone_plan: 'Tarif auswählen',
  City: 'Stadt',
  CityState: 'Stadt, Staat',
  Clear: 'Löschen',
  Clear_all: 'Clear all',
  Clear_filter: 'Filter löschen',
  Close: 'Schließen',
  Color: 'Farbe',
  Comment: 'Anmerkung',
  Completed: 'Abgeschlossen',
  Confirm: 'Bestätigen',
  Confirmation_date: 'Bestätigungsdatum',
  ContactDescription_description:
    'Am schnellsten erreichst du uns über den Service-Chat. Klicke einfach auf den Button, um ein Chat-Fenster zu öffnen. Sollte deine Organisation keinen Chat-Support unterstützen, verwende bitte die Kontaktoptionen via E-Mail und Telefon.',
  ContactDescription_header: 'Hast du eine Frage?',
  ContactDetails_description: 'Hast du Fragen oder Anregungen?',
  ContactDetails_header: 'Weitere Kontaktmöglichkeiten',
  ContactDetails_openingHours: 'Montag-Sonntag, 7–20 Uhr',
  ContactDetails_service: '(Service in Deutsch und Englisch)',
  ContactLandingDescription_description:
    'Am schnellsten erreichst du uns über den Service-Chat. Klicke einfach auf den Button, um ein Chat-Fenster zu öffnen.',
  Contact_button: 'Zurück zum Dashboard',
  Contact_chat_with_us: 'Chat öffnen',
  Contact_login: 'Anmelden',
  Contact_login_text: 'Hast du bereits Zugang zum Portal?',
  Contact_position: 'Lead Client Services',
  Contact_position_text:
    'Mein Team und ich sind für dich da - schnelle Hilfe mit wenigen Klicks.',
  Contact_us: 'Kontakt / Hilfe',
  Continue: 'Fortsetzen',
  CookieApproval_cookies_stored_on_computer:
    'Diese Website speichert Cookies auf deinem Computer. Diese Cookies werden verwendet, um Informationen darüber zu sammeln, wie du mit unserer Website interagierst, und um uns zu ermöglichen, uns an dich zu erinnern.',
  CookieApproval_heading: 'Diese Website verwendet Cookies',
  CookieApproval_if_you_decline:
    'Wenn du ablehnst, werden deine Informationen beim Besuch dieser Website nicht erfasst.',
  CookieApproval_improve_experience_info:
    'Wir verwenden diese Informationen, um deine Browser-Erfahrung zu verbessern und anzupassen sowie um Analysen und Metriken über unsere Besucher sowohl auf dieser Website als auch in anderen Medien durchzuführen.',
  Copy: 'Kopieren',
  CostCenterSelect_Create: 'Kostenstelle erstellen',
  CostCenterSelect_NoResults:
    'Bitte gib mindestens ein Zeichen ein, um eine Kostenstelle zu erstellen.',
  Cost_center: 'Cost center',
  Costcenter: 'Kostenstelle',
  Country: 'Land',
  Create_DHL_return_label: 'Neues DHL-Rücksendelabel erstellen',
  Create_employee: 'Mitarbeiter*in hinzufügen',
  Created_by: 'Erstellt von',
  Current: 'Aktuell',
  Custom_address: 'Wunschadresse',
  DHL_Return_Label: 'DHL Rücksendeetikett',
  Dashboard: 'Dashboard',
  DashboardFleetOverviewCard_header: 'Flottenübersicht',
  DashboardFleetOverviewCard_subtitle: 'Alle Mietgeräte',
  DashboardOrdersReportCard_orders_report_header: 'Auftragsübersicht',
  DashboardOrdersReportCard_orders_report_subtitle: 'Aufträge nach Auftragsart',
  DashboardStatisticCard_contact_us: 'Kontaktiere uns, um zu starten',
  DashboardStatisticCard_this_month: '{percent} % diesen Monat',
  Dashboard_everphone_contact: 'Dein Everphone-Kontakt',
  Dashboard_most_popular: 'Beliebteste Geräte',
  Dashboard_the_benefits: 'Die Vorteile',
  Data_privacy: 'Data Privacy',
  Date: 'Datum',
  Delivery_status_Delivered: 'Dein Gerät wurde zugestellt',
  Delivery_status_Delivery: 'Lieferung in Kürze',
  Delivery_status_Failure:
    'Zustellung fehlgeschlagen - dein Gerät wird an Everphone zurückgeschickt',
  Delivery_status_Transit: 'Dein Gerät wurde versandt',
  Delivery_status_return_Delivered: 'Everphone hat deine Rücksendung erhalten',
  Delivery_status_return_Failure:
    'Zustellung fehlgeschlagen - Gerät wird an Absender zurückgeschickt',
  Delivery_status_return_Transit: 'Deine Rücksendung ist unterwegs',
  Depot: 'Depot',
  DepotListContent_badge_return: 'Rückgabe',
  DepotListContent_header_devices: 'Geräte',
  DepotListContent_header_name: 'Name',
  DepotListContent_header_type: 'Art',
  DepotStockModal_no_available: 'Keine "{name}" vorhanden',
  DepotStockModal_serial_number: 'Seriennummer',
  DepotTable_in_depot: 'in Depot',
  Depot_details: 'Detailansicht',
  Depots: 'Depots',
  Device: 'Gerät',
  DeviceChooserFilters_color: 'Farbe: {color}',
  DeviceChooserFilters_device_type: 'Gerättyp',
  DeviceChooserFilters_filter: 'Filter',
  DeviceChooserFilters_manufacturer: 'Hersteller',
  DeviceChooserFilters_memory: 'Speicher',
  DeviceChooserFilters_price: 'Preis',
  DeviceChooserFilters_recommended: 'Empfohlen',
  DeviceChooserFilters_total_products: 'Wir haben {total} Produkte gefunden',
  DeviceChooserFilters_view_all: 'Alle Filter anzeigen',
  DeviceChooserFilters_view_less: 'Weniger Filter anzeigen',
  DeviceChooser_limit_exceeded:
    'Du kannst leider keine weiteren Geräte bestellen. Bitte kontaktiere dein IT-Team für weitere Informationen.',
  DeviceDetails_exchange_requested: 'Austauschgerät beantragt',
  DeviceDetails_replacement_requested: 'Ersatzgerät beantragt',
  DeviceListContent_header__employee: 'Mitarbeiter*in',
  DeviceListContent_header__name: 'Name',
  DeviceListContent_header__start_date: 'Startdatum',
  DeviceListContent_header__status: 'Status',
  DeviceSlide_purchase_id: 'Anfrage-ID',
  DeviceSlide_request_date: 'Anfragedatum',
  DeviceSlide_request_id: 'Anfrage-ID',
  DeviceSlide_start_date: 'Startdatum',
  DeviceSlide_view_rental: 'Miete anzeigen',
  DeviceSlide_view_request: 'Anfrage anzeigen',
  Device_history: 'Gerätehistorie',
  Device_locked: 'Gerät gesperrt',
  Device_locked_alert:
    'Dieses Gerät ist derzeit entweder gesperrt oder hat noch MDM-Einschränkungen, so dass die Rückgabe nicht abgeschlossen werden kann. Bitte korrigiere dies, um fortzufahren.',
  Device_locked_badge_in_customer_lock: 'Gerätesperre - User Lock',
  Device_locked_badge_in_mdm: 'Gerätesperre - MDM Lock',
  Device_locked_badge_in_mdm_and_customer_lock:
    'Gerätesperre - User & MDM Lock',
  Device_lost_stolen: 'Verlorenes / gestohlenes Gerät',
  Devices: 'Geräte',
  DevicesCard_devices_header: 'Meine Geräte und Bestellungen',
  DevicesCard_purchases_header: 'Meine Kaufgeräte',
  DevicesCard_total:
    '(Gesamt: {total} {total, plural, =0 {Geräte} one {Gerät} other {Geräte}})',
  Done: 'Fertig',
  Download: 'Download',
  Download_report_error: 'Entschuldigung! Etwas ist schief gelaufen.',
  Download_report_preparing: 'Vorbereitung',
  Download_report_preparing_message:
    'Wir sind dran! Ihr Bericht wird gerade vorbereitet. Ein Link zum Herunterladen des Berichts wird hier erscheinen, sobald er verfügbar ist.',
  Download_report_report_ready:
    'Ihr Bericht vom {time} ist zum Download bereit.',
  Download_report_report_ready_download: 'Klicken Sie hier, um herunterzuladen',
  Download_report_report_ready_order_message:
    'Bestellungen, die älter als 1 Jahr sind, sind nicht im Bericht enthalten.',
  Download_report_request_report: 'Bericht anfordern',
  Duration: 'Zeitraum',
  Early_Upgrade: 'Vorzeitiges Upgrade',
  Early_upgrade_device: 'Vorzeitiges Upgrade',
  Edit: 'Bearbeiten',
  Edit_address: 'Adresse bearbeiten',
  Edit_employee: 'Mitarbeiter*in bearbeiten',
  Email: 'E-Mail',
  Employee: 'Mitarbeiter*in',
  EmployeeDashboardServiceApp_rent_button: 'Rent device',
  EmployeeDashboardServiceApp_title: 'Meine Geräte',
  EmployeeDashboard_button: 'Jetzt Angebote entdecken',
  EmployeeDashboard_title: 'Firmengeräte selbst verwalten',
  EmployeeDashboard_title_content:
    'Hier im Portal kannst du deine Geräte managen, FAQs lesen und du hast einen direkten Draht zu unserem Serviceteam.',
  EmployeeExitCard_heading: 'Anstehende Mitarbeiteraustritte',
  EmployeeListContent_IMEI: 'IMEI',
  EmployeeListContent_Rentals: 'Geräte',
  EmployeeListContent_header_device: 'Gerät',
  EmployeeListContent_header_employee: 'Mitarbeiter*in',
  EmployeeListContent_number_of_devices: 'Geräte',
  EmployeeOffboarding_date_in_past:
    'Austrittsdatum muss heute oder in der Zukunft liegen',
  EmployeeOffboarding_error: 'Fehler. Bitte probiere es erneut.',
  EmployeePurchases_no_purchases_yet:
    'Du hast noch keine Einkäufe. Möchtest du deinen ersten Einkauf bei Everphone beginnen?',
  Employee_details: 'Detailansicht',
  Employee_id: 'Personalnummer',
  Employee_leaves: 'Mitarbeiter*in verlässt die Firma',
  Employees: 'Mitarbeiter*innen',
  EmployeesSelect_NoResults: 'Kein*e Mitarbeiter*in gefunden',
  End: 'Ende',
  ErrorSubmittingRequest:
    'Leider gab es einen Fehler beim Übertragen deiner Anfrage.',
  Error_heading: 'Hoppla!',
  Error_occurred: 'Es ist ein Fehler aufgetreten',
  Error_refresh_page: 'Bitte lade diese Seite neu.',
  EverphonePlusBenefits_buying_offer_content:
    'Nach Ablauf der Mietzeit kann man die Miete verlängern, das Gerät zurückgeben oder zu einem attraktiven Preis abkaufen.',
  EverphonePlusBenefits_buying_offer_header: 'Kaufangebot nach Mietende',
  EverphonePlusBenefits_damage_swap_content:
    'Ist das Display gebrochen, der Akku erschöpft oder ein anderer Defekt aufgetreten, wird das Gerät von Everphone ohne weitere Kosten ausgetauscht.',
  EverphonePlusBenefits_damage_swap_header: 'Gerätetausch im Schadensfall',
  EverphonePlusBenefits_enormous_savings_content:
    'Neueste Technik mieten statt kaufen und von bis zu 52 % Ersparnis profitieren, da dein Arbeitgeber die Kosten von deiner Gehaltsabrechnung abzieht.',
  EverphonePlusBenefits_enormous_savings_header: 'Richtig sparen',
  EverphonePlusBenefits_family_friends_content:
    'Mit Everphone bekommst du dein Lieblingsgerät – für deine private Nutzung und frei nach deinen Wünschen konfigurierbar.',
  EverphonePlusBenefits_family_friends_header: 'Ideal für dich',
  EverphonePlusDashboard_see_offers: 'Jetzt Angebote entdecken',
  EverphonePlusDashboard_title: 'Mieten und sparen mit Everphone Plus',
  EverphonePlusDashboard_title_content:
    'Dein Arbeitgeber ermöglicht dir jetzt, Mobilgeräte und Zubehör auch privat zu mieten und zu nutzen. Die Mietbeträge werden monatlich direkt von deiner Gehaltsabrechnung abgezogen. Anstelle hoher Anschaffungskosten aus dem Netto-Lohn zahlst du einen monatlichen Betrag und profitierst von der Brutto-Regelung. Dank Steuerbegünstigung sparst du so doppelt.',
  Exchange: 'Austausch',
  ExchangeDeviceModal_shipping_label_info:
    'Dem Paket liegt ein Versandlabel bei. Bitte sende das Altgerät innerhalb der nächsten 14 Tage zurück',
  Exchange_device: 'Geräteaustausch',
  Excl_tax: 'Exkl. MwSt.',
  Exit_date: 'Austrittsdatum',
  Expand_content: 'Inhalte erweitern',
  Extend: 'Verlängern',
  Extend_now: 'Jetzt verlängern',
  Extend_rental: 'Miete verlängern oder neues Gerät auswählen',
  Fallback: 'MISSING_LABEL',
  FaqCard_description_part1:
    'Im Hilfebereich findest du Antworten auf häufig gestellte Fragen.',
  FaqCard_description_part2:
    'Falls du etwas anderes wissen möchtest, antworten wir dir gerne auch im Chat.',
  FaqCard_primary_cta: 'zum FAQ',
  FaqCard_secondary_cta: 'Chat öffnen',
  FaqCard_title: 'FAQ',
  Field_invalid: 'Dieses Feld ist fehlerhaft',
  Filter: 'Filter',
  Filter_by: 'Filtern nach',
  Filter_no_results_content: 'Bitte ändere den Filter oder setze ihn zurück',
  First_and_last_name: 'Vor- und Nachname...',
  First_name: 'Vorname',
  For_employee: 'Für Mitarbeiter*in (optional)',
  ForgotPasswordButton_label: 'Hast du dein Passwort vergessen?',
  FormMessage_email: 'Bitte trage eine gültige E-Mail-Adresse ein.',
  FormMessage_maxLength:
    'Dieses Feld darf höchstens {amount} Zeichen enthalten.',
  FormMessage_minLength:
    'Dieses Feld muss mindestens {amount} Zeichen enthalten.',
  FormMessage_required: 'Pflichtfeld.',
  German: 'Deutsch',
  GlobalSearch_CloseIcon: 'Suche schließen',
  GlobalSearch_Heading: 'Deine Suchvorschläge',
  GlobalSearch_InCategory: 'in {category}',
  GlobalSearch_InputPlaceholder:
    'Suche nach Gerät, Mitarbeiter*in oder Mobilfunktarif ...',
  GlobalSearch_InputValidationMessage:
    'Keine Übereinstimmung gefunden, bitte gib mehr als {limit} Zeichen ein.',
  GlobalSearch_NoResultsMessage:
    'Entschuldigung, wir haben keine Ergebnisse für diese Suche gefunden.',
  GlobalSearch_OpenIcon: 'Suche öffnen',
  GlobalSearch_RelatedItems: 'Ähnliche Artikel',
  GlobalSearch_SearchAll: 'Suche',
  GlobalSearch_SearchResults_header: 'Suchergebnisse',
  GlobalSearch_SearchResults_show_total:
    'Zeige {amount} {amount, plural, =0 {Suchergebnisse} one {Suchergebnis} other {Suchergebnisse}}',
  GlobalSearch_SearchResults_total:
    'Wir haben {amount} {amount, plural, =0 {Suchergebnisse} one {Suchergebnis} other {Suchergebnisse}} gefunden',
  GoToPage_after: 'Seite',
  GoToPage_pageInput: 'Page input',
  ILostMyDevice: 'Ich habe mein Device verloren',
  IMEI: 'IMEI',
  IMEI2: 'IMEI2',
  Id: 'Id',
  Img_alt_contact_guy:
    "Symbol eines Kundendienstmitarbeiters mit einem Headset, der an einem Schreibtisch sitzt und auf einem Laptop das Wort 'ever' anzeigt.",
  Img_alt_dashboard_banner:
    'Ein Mann steht und eine Frau sitzt an einem Schreibtisch und besprechen die Arbeit in einem hellen, modernen Büro mit großen Fenstern.',
  Img_alt_faq:
    'Symbol einer Lupe mit einem Fragezeichen über zwei sich überlappenden Browserfenstern.',
  Img_alt_impact_tracker:
    'Symbol, das eine Fabrik auf einem Globus mit CO₂-Emissionen, einem Blatt und einem Wassertropfen darstellt, die Umweltauswirkungen symbolisieren.',
  Img_alt_list_message: 'Nachrichten-Symbol',
  Img_alt_login_devices:
    'Vier elektronische Geräte—ein Laptop, ein Tablet und zwei Smartphones—zeigen bunte abstrakte Designs auf ihren Bildschirmen.',
  Img_alt_no_action:
    "Symbol eines Computerbildschirms mit einem überlappenden Browserfenster und einem 'X'-Symbol, das einen Fehler oder eine blockierte Aktion anzeigt.",
  Img_alt_order_confirmation:
    'Illustration eines lächelnden Lieferanten, der ein Paket mit einem Häkchen hält, was anzeigt, dass die Bestellung bestätigt ist.',
  Img_alt_org_logo: 'Organisationslogo',
  Img_alt_page_error:
    'Symbol, das anzeigt, dass etwas Unerwartetes passiert ist, mit einem Computerbildschirm, einem Browserfenster und einem Aktualisierungssymbol, das vorschlägt, die Seite zu aktualisieren.',
  ImpactTracker_desc: 'Bisherige Senkung der Umweltauswirkungen',
  ImpactTracker_popover_desc:
    'Der ökologische Fußabdruck dieses Refurbisht-Geräts wird mit dem eines neuen Geräts verglichen.',
  ImpactTracker_popover_link: 'Mehr dazu',
  ImpactTracker_prolonging_device: 'Verlängertes Gerät',
  ImpactTracker_refurbished_device: 'Refurbisht-Gerät',
  ImpactTracker_saved_co2: 'Gespartes CO₂',
  ImpactTracker_saved_raw: 'Gesparte Rohstoffe',
  ImpactTracker_saved_water: 'Gespartes Wasser',
  ImpactTracker_title: 'Ökologische Wirkung',
  Imprint: 'Impressum',
  In_customs: 'Zollabfertigung',
  In_progress: 'in Bearbeitung',
  Inactive: 'nicht aktiviert',
  Incl_tax: 'Inkl. MwSt.',
  InvalidCredentials: 'Falscher Benutzername oder falsches Passwort',
  Invoice: 'Rechnung',
  Invoices: 'Rechnungen',
  InvoicesContent_header_amount: 'Menge',
  InvoicesContent_header_attachments: 'Anhänge',
  InvoicesContent_header_date: 'Datum',
  InvoicesContent_header_due_date: 'Fällig',
  InvoicesContent_header_number: 'Nummer',
  Item: 'Produkt',
  Language: 'Sprache',
  Last_fetch_days:
    'Letzte Aktualisierung: vor {days} {days, plural, =0 {Tagen} one {Tag} other {Tagen}}',
  Last_fetch_hours:
    'Letzte Aktualisierung: vor {hours} {hours, plural, =0 {Stunden} one {Stunde} other {Stunden}}',
  Last_fetch_minutes:
    'Letzte Aktualisierung: vor {minutes} {minutes, plural, =0 {Minuten} one {Minute} other {Minuten}}',
  Last_fetch_seconds:
    'Letzte Aktualisierung: vor {seconds} {seconds, plural, =0 {Sekunden} one {Sekunde} other {Sekunden}}',
  Last_name: 'Nachname',
  Link_to_external_page_title: '{name} - in einem neuen Tab öffnen',
  Link_to_page_title: 'Zu {name} Seite wechseln',
  Loading: 'Lädt ...',
  Login: 'Anmelden',
  Login_SSO: 'Mit SSO (Single Sign-on) anmelden',
  Login_error_not_allowed_to_login: 'Du bist nicht zum Login berechtigt',
  Login_error_password_reset:
    'Dein Passwort wurde seit 12 Monaten nicht geändert. Unsere Sicherheitsrichtlinien erfordern mindestens alle 12 Monate das Festlegen eines neuen Passworts. Wir haben dir einen Link zum Zurücksetzen des Passworts an deine hinterlegte E-Mail-Adresse gesendet, damit du ein neues Passwort festlegen kannst.',
  Login_failed: 'Login fehlgeschlagen',
  Login_not_authorized: 'Du bist nicht zum Login berechtigt',
  Login_page: 'Anmeldeseite',
  Logout: 'Abmelden',
  LostDeviceModal_order_new_device: 'Neues Gerät bestellen',
  LostOrStolenModal_confirmationNoReplacement_heading:
    'Wir haben deine Anfrage erhalten',
  LostOrStolenModal_followInternalProcesses:
    'Bitte denke auch an evtl. nötige interne Schritte bei Verlust/Diebstahl eines Geräts in deiner Organisation.',
  LostOrStolenModal_header: 'Verlorenes/gestohlenes Gerät melden',
  LostOrStolenModal_noReplacement: 'Ich benötige kein Ersatzgerät',
  LostOrStolenModal_noReplacementFound:
    'Es wurde kein passendes Austauschgerät in einem deiner Depots gefunden',
  LostOrStolenModal_orderDepot: 'Ersatzgerät aus dem Depot bestellen',
  LostOrStolenModal_orderEverphone: 'Ersatzgerät von Everphone bestellen',
  LostOrStolenModal_replacementModelNote:
    '* ein gleiches oder ähnliches Gerät wird als Ersatz zugesandt',
  LostReplacement_info_header: 'Verlorenes/gestohlenes Gerät',
  LostReplacement_info_text:
    'Bitte wählen Sie ein neues Gerät Ihrer Wahl als Ersatz. Es wird eine neue Miete mit dem ausgewählten Gerät erstellt.',
  Lost_stolen_device: 'Gerät verloren / gestohlen',
  Message: 'Nachricht',
  Message_for_everphone: 'Nachricht an Everphone',
  Most_recent: 'Letzte',
  MultidatePicker_last_days: 'Letzte {number} Tage',
  MultidatePicker_last_months: 'Letzte {number} Monate',
  MultidatePicker_next_days: 'Nächste {number} Tage',
  MultidatePicker_next_months: 'Nächste {number} Monate',
  MultidatePicker_placeholder: 'Datum auswählen',
  MultidatePicker_today: 'Heute',
  MultidatePicker_until_today: 'Until today',
  MultidatePicker_yesterday: 'Gestern',
  My_account: 'My account',
  My_devices: 'My devices',
  NetPriceCalculator_additional_health_insurance:
    'Zusatzbeitragssatz der gesetzlichen Krankenversicherung (in Prozent)',
  NetPriceCalculator_apply: 'Anwenden',
  NetPriceCalculator_calculate: 'Effektive Preise berechnen',
  NetPriceCalculator_child_allowance: 'Zahl der Kinderfreibeträge',
  NetPriceCalculator_church_tax: 'Kirchensteuerpflicht',
  NetPriceCalculator_effective_net_price_calculator:
    'Effektiver Nettopreis-Rechner',
  NetPriceCalculator_enter_salary: 'Gib deinen Jahresbruttolohn ein',
  NetPriceCalculator_enter_your_health_insurance:
    'Gib deinen zusätzlichen Krankenversicherungsbeitrag ein',
  NetPriceCalculator_enter_your_tax_info:
    'Ergänze deine Steuerdetails, um die geschätzten effektiven Preise nach der Gehaltsumwandlung zu berechnen.',
  NetPriceCalculator_estimation_disclaimer:
    '** Die Schätzung dient nur der Orientierung. Der resultierende Nettolohnabzug wird durch deine tatsächliche Steuererklärung bestimmt.',
  NetPriceCalculator_gross_salary: 'Jahresbruttolohn',
  NetPriceCalculator_prices_visible:
    'Deine geschätzten effektiven Preise sind jetzt sichtbar.',
  NetPriceCalculator_recalculate: 'Effektivpreise neu berechnen',
  NetPriceCalculator_required_fields: 'Pflichtfelder',
  NetPriceCalculator_state: 'Staat',
  NetPriceCalculator_tax_class: 'Steuerklasse',
  NetPriceCalculator_value: 'Wert',
  New: 'Neu',
  NewOrderConfirmation_color: 'Farbe:',
  NewOrderConfirmation_heading: 'Deine Bestellung ist bestätigt',
  NewOrderConfirmation_items: 'Artikel',
  NewOrderConfirmation_label: 'Customer label:',
  NewOrderConfirmation_months: '{total} Monate',
  NewOrderConfirmation_order_date: 'Bestelldatum',
  NewOrderConfirmation_price: 'Preis',
  NewOrderConfirmation_quantity: 'Menge: {count}',
  NewOrderConfirmation_rental_period: 'Mietzeitraum',
  NewOrderConfirmation_request_id: 'Anfrage-ID',
  NewOrderConfirmation_shipping_address: 'Lieferadresse',
  NewOrderConfirmation_subheading:
    'Wir haben deine Bestellung erhalten und bereiten sie für den Versand vor. Wir werden dich per E-Mail benachrichtigen, sobald sie auf dem Weg ist!',
  NewOrderConfirmation_to_dashboard: 'Zurück zum Dashboard',
  NewOrderConfirmation_to_employee: 'Zurück zum*r Mitarbeiter*in',
  NewOrderConfirmation_to_rental: 'Zurück zum Mietgerät',
  NewOrderConfirmation_to_rentals: 'Zurück zu den Mietgeräten',
  NewOrderConfirmation_total_amount: 'Monatliche Zuzahlung (ohne MwSt.)',
  NewOrderConfirmation_total_amount_basis: 'Gesamtbetrag pro Monat',
  NewOrderConfirmation_total_amount_gross: 'Monatliche Zuzahlung (inkl. MwSt.)',
  NewOrderSummary_accept_terms:
    'Hiermit akzeptiere ich die Bedingungen des Zuzahlungsbetrages.',
  NewOrderSummary_billing_identifier_placeholder:
    'Bitte gib die Billing Identifier an (Optional, maximal {limit} {limit, plural, =0 {Zeichen} one {Zeichen} other {Zeichen}})',
  NewOrderSummary_custom_client_reference_placeholder:
    'Bitte gib die Custom client reference an (Optional, maximal {limit} {limit, plural, =0 {Zeichen} one {Zeichen} other {Zeichen}})',
  NewOrderSummary_device: 'Gerät',
  NewOrderSummary_error_header: 'Entschuldigung! Etwas ist schief gelaufen',
  NewOrderSummary_error_header_address_error:
    'Sorry! We were unable to complete your order.',
  NewOrderSummary_error_subheader:
    'Wir konnten deine Bestellung nicht bearbeiten. Bitte versuche es erneut.',
  NewOrderSummary_error_subheader_address_error:
    'There is an error in the shipping address you entered. Please correct them and try again',
  NewOrderSummary_external_id: 'External ID',
  NewOrderSummary_external_id_error: 'Die externe ID muss eindeutig sein.',
  NewOrderSummary_external_id_placeholder:
    'Bitte gib deine external ID an (Optional, eindeutig, maximal 60 Zeichen)',
  NewOrderSummary_label_placeholder:
    'Bitte gib die Customer-Label-Details an (Optional, maximal {limit} {limit, plural, =0 {Zeichen} one {Zeichen} other {Zeichen}})',
  NewOrderSummary_months: 'Monate',
  NewOrderSummary_net_total:
    'Dein geschätzter Gesamtnettobetrag pro Monat (ohne MwSt.)**  ',
  NewOrderSummary_order: 'Bestellung',
  NewOrderSummary_phone_plan_details:
    'Optionale Kommentare zur Bestellung eines Mobilfunktarifs',
  NewOrderSummary_po_rcn_placeholder:
    'Bitte gib die PO/RCN an (Optional, maximal {limit} {limit, plural, =0 {Zeichen} one {Zeichen} other {Zeichen}})',
  NewOrderSummary_price: 'Preis',
  NewOrderSummary_quantity: 'Menge',
  NewOrderSummary_rental_duration: 'Mietzeitraum',
  NewOrderSummary_shipping_address: 'Lieferadresse',
  NewOrderSummary_to_dashboard: 'Zurück zum Dashboard',
  NewOrderSummary_total: 'Monatliche Zuzahlung (ohne MwSt.)',
  NewOrderSummary_total_basis: 'Gesamtbetrag pro Monat',
  NewOrderSummary_total_gross: 'Monatliche Zuzahlung (inkl. MwSt.)',
  NewOrder_choose_accessories_header: 'Wähle dein Zubehör (optional)',
  NewOrder_choose_accessories_subheader:
    'Die monatlichen Mietgebühren und etwaige monatliche Zuzahlungen werden ohne gültige MwSt. ausgewiesen.',
  NewOrder_choose_device_header: 'Neues Gerät auswählen',
  NewOrder_choose_device_subheader:
    'Die monatlichen Mietgebühren und etwaige monatliche Zuzahlungen werden ohne gültige MwSt. ausgewiesen.',
  NewOrder_order_summary_header: 'Bestellungsübersicht',
  NewOrder_order_summary_subheader: 'Bitte überprüfe deine Bestellung',
  NewOrder_shipping_address_header: 'Lieferadresse',
  NewOrder_shipping_address_subheader: 'Wo möchtest du deine Geräte erhalten?',
  NewOrder_step: 'Schritt',
  NewPhonePlanModal_prices_may_vary:
    'Preise sind variabel und dienen als Indikatoren',
  NewSmartphoneExchangeModal_DescriptionExchange:
    'Die Mietzeit ist abgelaufen. Du kannst jetzt ein neues Gerät wählen!',
  NewSmartphoneFlow_limit_exceeded:
    'Du kannst leider keine weiteren Geräte bestellen. Bitte gehe zu "Anfragen", um deine letzten Bestellungen zu sehen oder kontaktiere dein IT-Team für weitere Informationen.',
  New_device: 'Neues Gerät',
  New_device_bundle: 'Neues Gerätepaket',
  New_employee: 'Mitarbeiter*in hinzufügen',
  New_joiners_this_month: 'Aktuelle Neuzugänge',
  New_phone_plan: 'Neuer Tarif',
  New_question: 'Allgemeine Anfrage',
  Next: 'Weiter',
  No: 'Nein',
  No_depot_found: 'Keine Depots gefunden',
  No_devices_found: 'Keine Geräte verfügbar',
  No_name_given: 'Kein Name angegeben',
  No_results: 'Du hast noch keine {entity}',
  No_results_title: 'Hoppla! Kann deine Ergebnisse nicht finden',
  OfferAcceptedContent_content:
    'Die Zahlungsanweisungen gehen dir in Kürze per E-Mail zu – prüfe bitte deinen Posteingang. Sollte keine E-Mail kommen, wende dich bitte an uns, wir unterstützen dich gerne.',
  OfferAcceptedContent_heading: 'Bestätigung erhalten',
  OfferDeclinedContent_content_line1:
    'Bitte folge den Anweisungen der E-Mail, damit der Rückversand rechtzeitig erfolgt.',
  OfferDeclinedContent_content_line2:
    'Bei weiteren Fragen wende dich bitte an unser Support-Team.',
  OfferDeclinedContent_heading:
    'Danke für die Bestätigung. Dein Angebot ist nun abgelaufen.',
  OfferErrorContent_content_line1:
    'Hier scheint etwas schief gelaufen zu sein. Kontaktiere uns bitte per E-Mail oder Telefon.',
  OfferErrorContent_heading: 'Hoppla! Ein Fehler ist aufgetreten',
  OfferPaymentForm_billing_details: 'Rechnungsangaben',
  OfferPaymentForm_payment_details: 'Zahlungsangaben',
  OfferPaymentForm_submit: 'Zahlen',
  OfferStatus_fallback_content:
    'Seems like something went wrong here. Please contact us via e-mail or phone.',
  OfferStatus_fallback_heading: 'Oops!',
  OfferStatus_payment_failed_content:
    'Unfortunately there was an error processing your purchase. If you need assistance, please contact us.',
  OfferStatus_payment_failed_heading: 'Payment unsuccessful',
  OfferStatus_purchased_content:
    'Thank you for your purchase. We hope you enjoy your device.',
  OfferStatus_purchased_heading: 'Purchase complete!',
  OfferSummaryFooter_conditions_link: 'Bedingungen',
  OfferSummaryFooter_dataprotection_link: 'Datenschutz',
  OfferSummary_heading: 'Zahlung an Everphone Enterprise V GmbH',
  OfferSummary_subtotal: 'Zwischensumme',
  OfferSummary_tax: 'USt.',
  OfferSummary_total: 'Fällige Gesamtsumme',
  Office: 'Office',
  Open: 'Offen',
  Open_accessibility_menu: 'Menü für barrierefreies Lesen öffnen',
  Open_menu: 'Menü öffnen',
  Open_orders: 'Offene Bestellungen',
  Open_repairs: 'Offene Reparaturen',
  Order: 'Auftrag',
  'Order Confirmed!': 'Bestellung bestätigt!',
  OrderIntercomButton_Contact_form: 'Kontakt / Hilfe.',
  OrderIntercomButton_Have_a_question: 'Hast du eine Frage?',
  OrderIntercomButton_Open_chat: 'Chat öffnen',
  OrderIntercomButton_Subtitle:
    'Wenn deine Organisation die Chat-Funktion nicht unterstützt, verwende bitte das',
  OrderListContent_header__title: 'Bestellung',
  OrderProlong_extend_rental_header: 'Miete verlängern',
  OrderProlong_extend_rental_subheader:
    'Mietverlängerungen sind 12 Monate gültig.',
  OrderSelectionCard_refurbished_desc:
    'Finde heraus, wie die Wahl eines Refurbished-Geräts die Auswirkungen auf die Umwelt im Vergleich zu einem neuen Gerät verringern kann',
  OrderSelectionCard_refurbished_title:
    'Entscheide dich für ein refurbishtes Modell als nächstes Gerät',
  Order_DeviceRentalStep_heading: 'Upgrade',
  Order_EarlyUpgrade_Info:
    'Für ein Upgrade während der Mietzeit wird eine Bearbeitungsgebühr erhoben. Upgrades sind auf Modelle der aktuellen oder einer höheren Mietkategorie beschränkt. Die Mietlaufzeit für das ausgewählte neue Modell wird zum Zeitpunkt der Auswahl angegeben.',
  Order_Upgrade_Info:
    'Die Mietzeit ist abgelaufen. Du kannst jetzt ein neues Gerät wählen!',
  Order_details: 'Bestellungsübersicht',
  Order_new_device: 'Neues Gerät bestellen',
  Order_phone_plan: 'Tarif bestellen',
  Order_replacement_requested: 'Ersatzgerät beantragt',
  Order_upgrade_fee_info:
    'dieser Betrag wird wie die Zuzahlung von deinem Gehalt abgezogen',
  Orders: 'Bestellungen',
  Orders_and_returns: 'Bestellungen\n& Rückgaben',
  Organisation: 'Organisation',
  Organisations: 'Organisationen',
  'Original device': 'Dieses Gerät',
  Other: 'Sonstiges',
  OurSupportWillGetInTouch:
    'Unser Service-Team wird dich umgehend kontaktieren.',
  Overview: 'Übersicht',
  PageSizeSelect_label: '{size} Zeilen/Seite',
  Pagination_jumpBackwardLabel: 'Zurück',
  Pagination_jumpForwardLabel: 'Vorwärts',
  Pagination_nextLabel: 'Nächste Seite',
  Pagination_pageLabel: 'Seite {page}',
  Pagination_previousLabel: 'Vorherige Seite',
  Password: 'Passwort',
  Password_reset_content:
    'Bitte prüfe den Posteingang deiner beruflichen E-Mail-Adresse. Die Anweisungen für das Zurücksetzen des Passworts senden wir dir dorthin.',
  Password_reset_header: 'Passwort wurde zurückgesetzt',
  Payment: 'Zahlung',
  PaymentFailedContent_content_line1:
    'Leider ist bei der Zahlung ein Fehler aufgetreten. Bitte überprüfe deine Zahlungsangaben und versuche es nochmal.',
  PaymentFailedContent_content_line2: 'Bei Fragen kontaktiere uns bitte.',
  PaymentFailedContent_cta: 'Nochmal versuchen',
  PaymentFailedContent_heading: 'Zahlung fehlgeschlagen',
  Pending: 'In Prüfung',
  Percent_of_total: '({total}% of total)',
  Phone: 'Telefon',
  PhonePlanDetails_no_pin_number: 'Kein PIN-Code angegeben',
  PhonePlanDetails_no_puk_number: 'Kein PUK-Code angegeben',
  PhonePlanDetails_pin_number: 'PIN',
  PhonePlanDetails_provider_hint:
    'Hinweis: Mobilfunktarife können nur durch den Vertragsinhaber selbst beim jeweiligen Anbieter gekündigt werden.',
  PhonePlanDetails_puk_number: 'PUK',
  PhonePlanListContent_header__employee: 'Mitarbeiter*in',
  PhonePlanListContent_header__name: 'Name',
  PhonePlanListContent_header__phone_number: 'Telefonnummer',
  PhonePlanListContent_header__start_date: 'Startdatum',
  Phone_number: 'Telefonnummer',
  Phone_plan: 'Tarif',
  Phone_plan_details: 'Detailansicht',
  Phone_plans: 'Tarife',
  PleaseTryAgainLater: 'Bitte versuche es später noch einmal.',
  PortalLogin_accountLocked:
    '3 fehlgeschlagene Login-Versuche. Bitte warte fünf Minuten vor dem nächsten Versuch.',
  PortalLogin_description:
    'Die Rundum-Lösung, um deine mobilen Firmengeräte mühelos zu verwalten',
  PortalLogin_header: 'Willkommen',
  PortalLogin_header_to: 'Willkommen bei {name}',
  PortalLogin_title: 'Work smarter with Everphone',
  Postcode: 'PLZ',
  Preparing: 'Preparing',
  PreviewColumn_copayment_amount: 'Zuzahlung',
  PreviewColumn_cost_center: 'Kostenstelle',
  PreviewColumn_created: 'Erstellt',
  PreviewColumn_delivery_status: 'Lieferstatus',
  PreviewColumn_eid: 'EID',
  PreviewColumn_email: 'E-Mail',
  PreviewColumn_employee: 'Mitarbeiter*in',
  PreviewColumn_employee_id: 'Personalnummer',
  PreviewColumn_footer_button: 'Alle Informationen anzeigen',
  PreviewColumn_footer_question: 'Neue Anfrage',
  PreviewColumn_imei_1: 'IMEI',
  PreviewColumn_imei_2: 'IMEI 2',
  PreviewColumn_intercom_intro:
    'Ich habe eine Frage bezüglich {type} ID: {id}: ',
  PreviewColumn_min_term: 'Mindestlaufzeit',
  PreviewColumn_monthly_amount: 'Monatliche Miete',
  PreviewColumn_months:
    '{total} {total, plural, =0 {Monate} one {Monat} other {Monate}}',
  PreviewColumn_office: 'Office',
  PreviewColumn_organisation: 'Organisation',
  PreviewColumn_phone_number: 'Telefonnummer',
  PreviewColumn_reason: 'Grund',
  PreviewColumn_request_date: 'Anfragedatum',
  PreviewColumn_request_id: 'Anfrage-ID',
  PreviewColumn_return_completed: 'Rückgabe abgeschlossen',
  PreviewColumn_return_date: 'Rückgabedatum',
  PreviewColumn_return_id: 'Rückgabe-ID',
  PreviewColumn_serial: 'Seriennummer',
  PreviewColumn_sim_card_number: 'SIM-Kartennummer',
  PreviewColumn_start: 'Startdatum',
  PreviewColumn_start_of_rental: 'Mietbeginn',
  PreviewColumn_title_preview: '{columnType} Vorschau',
  PreviewColumn_type_employee: 'Mitarbeiter*in',
  PreviewColumn_type_phone_plan: 'Tarif',
  PreviewColumn_type_rental: 'Mietgerät',
  PreviewColumn_type_request: 'Anfrage',
  PreviewColumn_upgrade_date: 'Upgrade ab',
  Price: 'Preis',
  Processing: 'in Bearbeitung',
  ProgressHeader_title: 'Schritt {step}: {title}',
  ProlongConfirmation_device: 'Gerät',
  ProlongConfirmation_heading: 'Mietlaufzeit verlängert',
  ProlongConfirmation_new_end_date: 'Verlängert bis',
  ProlongConfirmation_new_price: 'Preis',
  ProlongConfirmation_subheading:
    'Danke, dass du zur nachhaltigeren Nutzung von Mobilgeräten beiträgst!',
  ProlongConfirmation_to_dashboard: 'Zurück zum Dashboard',
  ProlongConfirmation_total_amount: 'Monatliche Zuzahlung (ohne MwSt.)',
  ProlongationSummary_accept:
    'Hiermit akzeptiere ich die Bedingungen für die Mietverlängerung.',
  Prolongation_valid_till: 'Miete verlängert bis',
  Prolonged_rentals: 'Verlängerte Mieten',
  PurchaseListContent_header__date: 'Datum',
  PurchaseListContent_header__name: 'Name',
  PurchaseListContent_header__status: 'Status',
  PurchaseMyDevice: 'Ich möchte mein Gerät kaufen',
  PurchaseSuccessfulContent_content_line1:
    'Vielen Dank für deinen Einkauf. Wir schicken dir in Kürze eine Bestätigungs-E-Mail.',
  PurchaseSuccessfulContent_content_line2:
    'Wir wünschen dir viel Freude mit deinem Gerät!',
  PurchaseSuccessfulContent_content_line3:
    'Solltest du keine Bestätigungs-E-Mail erhalten, kontaktiere uns bitte. Wir helfen gern.',
  PurchaseSuccessfulContent_heading: 'Kauf erfolgreich!',
  Purchase_device: 'Geräteankauf',
  Purchases: 'Kaufgeräte',
  PutEmployeeModal_allow_login: 'Erlaube Benutzer, sich einzuloggen',
  PutEmployeeModal_allow_login_send_email:
    'Erlaube Benutzer, sich einzuloggen und sende Willkommens-E-Mail',
  PutEmployeeModal_save_changes: 'Änderungen speichern',
  QRCodeCard_heading: 'Scan this QR code',
  Quantity: 'Anzahl',
  QuestionDeliveryTime: 'Frage zur Lieferzeit',
  QuestionDeviceManagement: 'Frage zum Mobile-Device-Management',
  QuestionReplacingDevice: 'Frage zum Austausch bei Defekt',
  RangeInput_aria_label:
    'Verschieben, um den Wert zwischen {min} und {max} auszuwählen',
  RangeInput_max_value: 'Maximalwert',
  RangeInput_min_value: 'Minimalwert',
  RangeInput_selectedValue: 'Ausgewählter Wert',
  RangeInput_value: 'Wert',
  Reason: 'Grund',
  ReassignDevice_Button: 'Gerät neu zuweisen',
  ReassignDevice_ModalSearchEmployee: 'Neue*n Mitarbeiter*in auswählen',
  ReassignDevice_ModalTitle: 'Gerät neu zuweisen',
  ReassignDevice_SubmissionError: 'Fehler. Bitte versuche es erneut.',
  ReassignDevice_SubmissionSuccess:
    'Das Gerät wurde {employeeName} neu zugewiesen.',
  Reassign_device: 'Gerät neu zuweisen',
  Received: 'Erhalten',
  Recent_requests: 'Aktuelle Anfragen',
  Recent_shipments: 'Jüngste Sendungen',
  Refresh_data: 'Daten aktualisieren',
  Refresh_the_page: 'Lade die Seite neu',
  Reject: 'Ablehnen',
  Rejected: 'Storniert',
  RentCategoryTier: 'Miet-Kategorie-Tier',
  Rent_category_tier: 'Miet-Kategorie-Tier',
  Rent_new_device: 'Neues Gerät mieten',
  Rental: 'Mietgerät',
  RentalDetails_alert_cancel:
    'Der Antrag auf Stornierung dieses Gerätes wird gerade bearbeitet. Du kannst den Bearbeitungsstand',
  RentalDetails_alert_lost_device:
    'Dieses Gerät ist als verloren oder gestohlen gemeldet. Du kannst den Bearbeitungsstand',
  RentalDetails_alert_prolonging:
    'Verlängere deinen Mietzeitraum für niedrigere monatliche Kosten oder führe ein Upgrade auf ein neues Gerät (mit neuem Mietzeitraum) durch.',
  RentalDetails_alert_repair:
    'Du hast dieses Gerät als defekt gemeldet. Du kannst den Bearbeitungsstand',
  RentalDetails_alert_subhead: 'überprüfen',
  RentalDetails_alert_upgrade:
    'Du kannst nun auf ein neues Gerät upgraden. Dadurch wird ein neuer Mietvertrag mit einer neuen Laufzeit abgeschlossen.',
  RentalDetails_check_status: 'hier ',
  RentalDetails_copayment: 'Monatliche Zuzahlung',
  RentalDetails_eid: 'EID',
  RentalDetails_imei: 'IMEI',
  RentalDetails_imei2: 'IMEI2',
  RentalDetails_minimum_term: 'Mindestlaufzeit',
  RentalDetails_monthly_amount: 'Monatliche Miete',
  RentalDetails_months: '{total} Monate',
  RentalDetails_select_a_new: 'Neues Modell für kostenloses Upgrade wählen ab',
  RentalDetails_serial: 'Seriennummer',
  RentalDetails_start_of_rental: 'Mietstart',
  RentalDetails_you_can_now:
    'Ein Geräteupgrade ist nach einer einmaligen Early-Upgrade-Gebühr möglich',
  RentalDetails_your_rental: 'Dein Gerät',
  RentalDuration: 'Mindestlaufzeit: {total} Monate',
  RentalEntityCard_rental_id: 'Gerät ID: {id}',
  RentalProlongingButton_additional_12_months:
    'Zusätzliche 12 Monate Service, inklusive schnellem Gerätetausch',
  RentalProlongingButton_extend_your_rental:
    'Klicke hier, um deinen Vertrag zu verlängern und die Umwelt zu schützen',
  RentalProlongingButton_plant_trees:
    'Für jede Verlängerung pflanzt Everphone 30 Bäume',
  RentalProlongingButton_save_50:
    'Klicke hier, um mit der Verlängerung deines Mietvertrags bis zu 50% zu sparen und gleichzeitig die Umwelt zu schützen.',
  RentalProlonging_not_eligible:
    'Dieses Gerät kann zur Zeit nicht verlängert werden',
  Rental_details: 'Detailansicht',
  Rental_prolonging_description:
    'Du kannst jetzt die Laufzeit deines aktuellen Geräts verlängern und sparen. Everphone unterstützt Nachhaltigkeit und pflanzt für jede Verlängerung 30 (!) Bäume. Außerdem sparst du je nach Modell bis zu 50 % deiner Zuzahlung. Alternativ kannst du ein neues Gerät auswählen.',
  Rental_prolonging_info_title: 'Miete verlängern oder neues Gerät auswählen',
  Rental_prolonging_prolong_upgrade: 'Neues Gerät auswählen',
  Rentals: 'Mietgeräte',
  RepairUpgrade_info_header: 'Repair Upgrade',
  RepairUpgrade_info_text:
    'Leider ist Ihr Gerät nicht für eine Reparatur geeignet. Sie haben jedoch die Möglichkeit, auf ein neueres Modell Ihrer Wahl zu wechseln. Dies startet einen neuen Mietzeitraum.',
  Repair_upgrade: 'Repair upgrade',
  ReplaceBrokenDeviceModal_header: 'Defektes Gerät melden',
  ReplaceBrokenDeviceModal_noReplacementFound:
    'Kein passendes Ersatzgerät in Depot(s) gefunden',
  ReplaceBrokenDeviceModal_orderDepot: 'Ersatzgerät aus Depot bestellen',
  ReplaceBrokenDeviceModal_orderEverphone:
    'Ersatzgerät von Everphone bestellen *',
  ReplaceBrokenDeviceModal_replacementModelNote:
    '* Das gleiche oder ein ähnliches Modell wird als Ersatz geschickt.',
  Replace_broken_device: 'Defektes Gerät ersetzen',
  Replace_from_depot: 'Ersatzgerät aus Depot',
  Replacement_device: 'Austauschgerät',
  Request: 'Anfrage',
  RequestDetails_Track_Order: 'Verfolge deine Bestellung',
  RequestDetails_agent: 'Bearbeiter*in',
  RequestDetails_billing_identifier: 'Billing identifier',
  RequestDetails_custom_client_reference: 'Custom client reference',
  RequestDetails_customerLabel: 'Customer Label Details',
  RequestDetails_date: 'Datum',
  RequestDetails_device: 'Gerät',
  RequestDetails_employee: 'Mitarbeiter*in',
  RequestDetails_monthly_copayment: 'Monatliche Zuzahlung',
  RequestDetails_po_rcn: 'PO/RCN',
  RequestDetails_rent_category: 'Mietkategorie',
  RequestDetails_request_id: 'Anfrage-ID',
  RequestListContent_header__agent: 'Bearbeiter*in',
  RequestListContent_header__date: 'Datum',
  RequestListContent_header__employee: 'Mitarbeiter*in',
  RequestListContent_header__status: 'Status',
  RequestListContent_header__title: 'Anfrage',
  RequestPurchaseModal_confirmationDescription:
    'Wir möchten dir den bestmöglichen Ankaufspreis anbieten. Unser Team wird dir in Kürze ein Angebot per E-Mail schicken.',
  RequestPurchaseModal_confirmationHeader: 'Geräteankauf',
  RequestPurchaseModal_confirmationTitle:
    'Vielen Dank für dein Interesse am Kauf des Geräts.',
  RequestPurchaseModal_invalidDescription:
    'Bitte kontaktiere dein IT-Team für die Rückgabe des Geräts.',
  RequestPurchaseModal_invalidHeader: 'Geräteankauf',
  RequestPurchaseModal_invalidTitle:
    'Leider kann dieses Gerät nicht gekauft werden.',
  RequestPurchaseModal_landingCheckbox:
    'Gibt es sichtbare Schäden oder Leistungsprobleme mit dem Gerät?',
  RequestPurchaseModal_landingDescription:
    'Um dir ein Ankaufsangebot machen zu können, benötigen wir einige Informationen zu deinem Gerät. Bitte stelle sicher, dass Modellbezeichnung, Seriennummer und IMEI(s) mit deinem Gerät übereinstimmen.',
  RequestPurchaseModal_landingHeader: 'Geräteankauf',
  RequestPurchaseModal_landingTitle:
    'Vielen Dank für dein Interesse am Kauf des Geräts.',
  RequestPurchaseModal_summaryHeader: 'Geräteankauf - Zusammenfassung',
  Request_details: 'Detailansicht',
  Request_id: 'Anfrage-ID',
  Required_fields: '* Pflichtfelder',
  Reset: 'Zurücksetzen',
  Reset_password: 'Reset Password',
  Reset_password_content:
    'Um dein Passwort zurückzusetzen, gib bitte deine berufliche E-Mail-Adresse ein. Wir schicken die Anleitung zum Zurücksetzen deines Passworts dorthin.',
  Reset_password_header: 'Passwort zurücksetzen',
  Resolved_unreturned: 'Gelöst, keine Rückgabe',
  Results: 'Results',
  Return: 'Rückgabe',
  ReturnDetails_created: 'Erstellt',
  ReturnDetails_device: 'Gerät',
  ReturnDetails_employee: 'Mitarbeiter*in',
  ReturnDetails_imei: 'IMEI',
  ReturnDetails_imei2: 'IMEI2',
  ReturnDetails_reason_for_return: 'Rückgabegrund',
  ReturnDetails_return_completed: 'Rückgabe Abgeschlossen',
  ReturnDetails_return_date: 'Rückgabedatum',
  ReturnDetails_serial_number: 'Seriennummer',
  ReturnDetails_start_of_rental: 'Startdatum',
  ReturnDetails_your_return: 'Deine Rückgabe',
  ReturnDeviceModal_no_depots: 'Keine Rückgabe-Depots gefunden',
  ReturnListContent_header__created: 'Erstellt',
  ReturnListContent_header__employee: 'Mitarbeiter*in',
  ReturnListContent_header__status: 'Status',
  ReturnListContent_header__title: 'Rückgaben',
  Return_depot: 'Rückgabe-Depot',
  Return_details: 'Rückgabedetails',
  Return_device: 'Geräte-Rückgabe',
  Return_id: 'Rückgabe-ID',
  Returns: 'Rückgaben',
  RouteGuard_continue: 'Weiter',
  RouteGuard_heading: 'Bist du sicher, dass du die Seite verlassen willst?',
  RouteGuard_leave_page: 'Seite verlassen',
  RouteGuard_subheading:
    'Wenn du die Seite verlässt, geht deine Bestellung verloren.',
  SSOButton_Google_label: 'Anmeldung mit Google',
  SSOButton_Microsoft_label: 'Anmeldung mit Microsoft',
  Save: 'Speichern',
  Search: 'Suchen',
  Security: 'Sicherheit',
  See_all: 'Alle anzeigen',
  See_delivery_status: 'Sendungsverfolgung',
  See_more: 'Mehr anzeigen',
  Select_country: 'Wähle ein Land',
  Select_org_or_employee: 'Organisation oder Mitarbeiter*in auswählen',
  Select_placeholder: 'Auswählen...',
  SelectionCard_effective_price: '** Effektiver Preis (ca.)',
  SelectionCard_monthly_copayment: 'Monatliche Zuzahlung',
  SelectionCard_monthly_price: 'Monatlicher Preis',
  Sender_country: 'Land des Absenders',
  Serial_number: 'Seriennummer',
  ServiceApp_show_details: 'Show Details',
  ShipmentTrackingCard_delivery_status: 'Lieferstatus',
  ShipmentTrackingCard_shipping: 'Lieferdetails',
  ShipmentTrackingCard_shipping_address: 'Lieferadresse',
  ShipmentTrackingCard_track_and_trace: 'Sendungsverfolgung',
  ShipmentTrackingCard_track_order: 'Sendungsverfolgung',
  ShipmentTrackingCard_track_return: 'Sendungsverfolgung',
  ShippedOrdersCard_heading: 'Sendungsverfolgung',
  ShippingAddressForm_heading: 'Wie lautet die Lieferadresse?',
  Shipping_address: 'Lieferadresse',
  Sim_card_number: 'SIM-Kartennummer',
  Sitemap: 'Sitemap',
  Sitemap_additional_pages: 'Weitere Seiten',
  Sitemap_main_sections: 'Hauptbereiche',
  Sold: 'Verkauft',
  Start: 'Start',
  Start_date: 'Startdatum',
  State: 'Staat',
  Status: 'Status',
  Street_addition: 'Adresszusatz',
  Street_name: 'Straße',
  Street_name_and_number: 'Straße & Hausnummer',
  Street_number: 'Hausnummer',
  Submit: 'Senden',
  Success: 'Erfolg',
  Summary: 'Zusammenfassung',
  TableCard_total:
    '{amount} {amount, plural, =0 {devices} =1 {device} other {devices}} in total',
  TechnicalIssues: 'Ich habe ein technisches Problem',
  Terms: 'Terms',
  To: 'An',
  Total: 'Gesamt',
  TotalCounter_total: 'von',
  Total_last_30_days: 'Gesamt in den letzten 30 Tagen',
  Total_orders:
    'Insgesamt {total} {total, plural, =0 {Bestellungen} =1 {Bestellung} other {Bestellungen}}',
  Tracking_reference: 'Sendungsnummer',
  Unknown_case_type: 'Unbekannte Fallart',
  Unknown_contact: 'Unbekannter Kontakt',
  Unnamed_phone_plan: 'Unbenannter Tarif',
  UnreturnedDevicesCard_heading: 'Offene Rücksendungen',
  Upcoming: 'Anstehend',
  Update_tracking_information: 'Sendungsnummer aktualisieren',
  Upgrade: 'Upgrade',
  Upgrade_Fee_ExclVAT: 'Einmalige Upgrade-Gebühr (ohne MwSt.)',
  Upgrade_device: 'Geräte-Upgrade',
  Upgrade_possible: 'Upgrade möglich',
  Username: 'Nutzername',
  VAT: 'MwSt.',
  Valid_from_date: 'Gültigkeitsdatum',
  View_orders: 'Bestellungen ansehen',
  View_rentals: 'Mietgeräte ansehen',
  Waiting: 'Warten',
  Yes: 'Ja',
  YourRequestHasBeenSubmitted: 'Deine Anfrage wurde übermittelt.',
  Zip: 'ZIP',
  for: 'für',
  typesenseAttribute_case_item_copayment: 'Zuzahlung',
  typesenseAttribute_case_item_created: 'Datum',
  typesenseAttribute_case_item_device_definition_manufacturer: 'Hersteller',
  typesenseAttribute_case_item_device_definition_name: 'Modell',
  typesenseAttribute_case_item_device_definition_type: 'Gerättyp',
  typesenseAttribute_case_item_employee: 'Mitarbeiter*in',
  typesenseAttribute_case_item_status: 'Bestellstatus',
  typesenseAttribute_case_item_type: 'Bestellart',
  typesenseAttribute_employee_cost_center_name: 'Kostenstelle',
  typesenseAttribute_employee_email: 'E-Mail-Adresse',
  typesenseAttribute_employee_exit_date: 'Austrittsdatum',
  typesenseAttribute_employee_external_reference:
    'Employee ID (External reference)',
  typesenseAttribute_employee_full_name: 'Mitarbeiter*in',
  typesenseAttribute_employee_has_active_rental: 'Aktive Mietgeräte',
  typesenseAttribute_employee_office: 'Office',
  typesenseAttribute_employee_phone_number: 'Telefonnummer',
  typesenseAttribute_employee_rent_category_tier: 'Miet-Kategorie-Tier',
  typesenseAttribute_rental_employee_exit_date: 'Austrittsdatum Mitarbeiter*in',
  typesenseAttribute_rental_end_at: 'Mietende',
  typesenseAttribute_rental_is_prolonged: 'Wurde verlängert',
  typesenseAttribute_rental_minimum_contract_duration:
    'Mindestlaufzeit Vertrag',
  typesenseAttribute_rental_rental_price_copayment_amount: 'Zuzahlung',
  typesenseAttribute_rental_start_at: 'Mietbeginn',
  typesenseAttribute_rental_stocklist_item_codename: 'Modell',
  typesenseAttribute_rental_stocklist_item_manufacturer: 'Hersteller',
  typesenseAttribute_rental_upgrade_date: 'Upgrade verfügbar ab',
  typesenseAttribute_return_case_created: 'Rückgabe erstellt',
  typesenseAttribute_return_case_employee_exit_date:
    'Austrittsdatum Mitarbeiter*in',
  typesenseAttribute_return_case_employee_office: 'Standort',
  typesenseAttribute_return_case_open_since: 'Rückgabe erwartet seit',
  typesenseAttribute_return_case_rental_cost_center: 'Kostenstelle',
  typesenseAttribute_return_case_return_depot_name: 'Rückgabedepot',
  typesenseAttribute_return_case_status: 'Rückgabestatus',
  typesenseAttribute_return_case_stocklist_item_codename: 'Modell',
  typesenseAttribute_return_case_stocklist_item_manufacturer: 'Hersteller',
  typesenseAttribute_unknown_attribute: 'Unbekanntes Merkmal',
  ...dataPrivacyTranslations,
  ...backendDeTranslations,
}

export default de
