import { forwardRef } from 'react'
import { clsx } from 'clsx'

import { RadioProps } from './Radio.types'

export const Radio = forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
  const {
    name,
    checked,
    className,
    disabled,
    isInvalid,
    id,
    label,
    onChange,
    value,
  } = props

  const classNames = clsx(
    'Radio',
    disabled && 'Radio-disabled',
    isInvalid && 'Radio-is-invalid',
    className,
  )

  return (
    <label className={classNames} htmlFor={id}>
      <input
        ref={ref}
        checked={checked}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        type="radio"
        value={value}
      />
      {label}
    </label>
  )
})
