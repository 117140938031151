import de from './de'
import en from './en'
import fr from './fr'

export interface Messages {
  [propName: string]: {
    [propName: string]: string
  }
}

const messages: Messages = {
  de,
  en,
  fr,
}

export default messages
