import { CustomToolbarConfig } from 'Components/MultidatePicker/MultidatePicker.types'

/* eslint-disable sort-keys-fix/sort-keys-fix */
export const employeeExitDateCustomToolbarConfig: CustomToolbarConfig = {
  today: {
    scope: 'today',
  },
  last_7days: {
    length: 7,
    period: 'days',
    scope: 'past',
  },
  last_30days: {
    length: 30,
    period: 'days',
    scope: 'past',
  },
  last_6months: {
    length: 6,
    period: 'months',
    scope: 'past',
  },
  next_30days: {
    length: 30,
    period: 'days',
    scope: 'future',
  },
}
/* eslint-enable sort-keys-fix/sort-keys-fix */
