import { Col, Row } from 'react-bootstrap'
import { clsx } from 'clsx'
import { observer } from 'mobx-react'
import { A11y, Autoplay, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Card, CardBody, Heading, PrimaryButton } from 'Components/_theme'

import { SLIDER_BREAKPOINTS_1 } from 'Constants/constants'

import { DashboardHeaderCardProps } from './DashboardHeaderCard.types'

export const DashboardHeaderCard = observer(
  (props: DashboardHeaderCardProps) => {
    const { content } = props

    return (
      <Card className={clsx(content.length > 1 && 'pb-6', 'mb-6')}>
        <CardBody>
          <Swiper
            autoplay={{ delay: 8000 }}
            breakpoints={SLIDER_BREAKPOINTS_1}
            className="mt-1"
            modules={[A11y, Autoplay, Pagination]}
            pagination={{ clickable: true }}
            spaceBetween={30}
          >
            {content.map(slide => (
              <SwiperSlide key={slide.id}>
                <Row className="d-flex flex-column flex-xxl-row">
                  <Col
                    className="d-flex flex-column justify-content-center"
                    xs={{ order: 'last' }}
                    xxl={{ order: 'first' }}
                  >
                    <div className="mt-4 mt-xxl-0">
                      <Heading size={2}>{slide.header}</Heading>
                    </div>
                    <div className="mt-2">{slide.subtitle}</div>
                    {slide.showButton && (
                      <div className="mt-6">
                        <PrimaryButton onClick={slide.onClick}>
                          {slide.buttonLabel}
                        </PrimaryButton>
                      </div>
                    )}
                  </Col>
                  <Col
                    className="d-flex flex-column justify-content-center"
                    xs={{ order: 'first' }}
                    xxl={{ order: 'last', span: '6' }}
                  >
                    <div className="d-flex justify-content-end">
                      {slide.image}
                    </div>
                  </Col>
                </Row>
              </SwiperSlide>
            ))}
          </Swiper>
        </CardBody>
      </Card>
    )
  },
)
