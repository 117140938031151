import { FormattedMessage, useIntl } from 'react-intl'
import { faEnvelope, faSquarePhone } from '@fortawesome/pro-solid-svg-icons'

import { Heading, LinkButton } from 'Components/_theme'
import { Icon } from 'Components/Icon/Icon'

import purchaseLandingSplash from 'Assets/images/purchaselanding/purchaselandingsplash.svg'

export const ContactDetails = () => {
  const intl = useIntl()

  return (
    <div className="PurchaseContactDetails">
      <Heading as={3} className="mb-4" size={2}>
        <FormattedMessage id="ContactDetails_header" />
      </Heading>

      <p className="text-center mb-9">
        <FormattedMessage id="ContactDetails_description" />
      </p>

      <a
        className="d-none d-xl-flex align-items-center mb-6"
        href="mailto:service@everphone.de"
        title={intl.formatMessage({ id: 'Email' })}
      >
        <Icon className="me-2" icon={faEnvelope} /> service@everphone.de
      </a>

      <a
        className="d-none d-xl-flex align-items-center mb-9"
        href="tel:4930516958275"
        title={intl.formatMessage({ id: 'Phone' })}
      >
        <Icon className="me-2" icon={faSquarePhone} /> +49 30 516958275
      </a>

      <div className="d-flex d-xl-none mb-9">
        <LinkButton
          asExternalLink
          className="me-2"
          iconLeft={faEnvelope}
          title={intl.formatMessage({ id: 'Email' })}
          to="mailto:service@everphone.de"
        >
          <FormattedMessage id="Email" />
        </LinkButton>
      </div>

      <div className="text-center mb-xl-0">
        <FormattedMessage id="ContactDetails_openingHours" />
        <br />
        <FormattedMessage id="ContactDetails_service" />
      </div>

      <div className="mt-9">
        <img
          alt="Person sat at a computer desk wearing a headset with a mouse pointer clicking a question mark"
          src={purchaseLandingSplash}
        />
      </div>
    </div>
  )
}
