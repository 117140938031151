import { useState } from 'react'
import { useIntl } from 'react-intl'
import { faBath, faCar, faRecycle } from '@fortawesome/pro-solid-svg-icons'
import { clsx } from 'clsx'

import { buttonize } from 'Utilities'

import { IMPACT_TRACKER_CHART_LEGEND_BUTTON } from './ImpactTracker.contants'
import {
  ImpactTrackerChartDataset,
  ImpactTrackerChartProps,
  ImpactTrackerUnits,
} from './ImpactTracker.types'
import {
  isDatasetActive,
  mapDatasetToChartItemValues,
  valueFormatter,
} from './ImpactTracker.util'
import { ImpactTrackerChartItem } from './ImpactTrackerChartItem'

export const ImpactTrackerChart = (props: ImpactTrackerChartProps) => {
  const {
    data: { datasets, totalCo2, totalRawMaterial, totalWater },
    interactive = true,
    showLegend = true,
  } = props
  const intl = useIntl()

  const [selectedDatasets, setselectedDatasets] = useState(datasets)

  // Such solution keeps the specific order of datasets
  const handleToggleDataset = (
    _event:
      | React.MouseEvent<HTMLSpanElement>
      | React.KeyboardEvent<HTMLSpanElement>,
    datasetToToggle: ImpactTrackerChartDataset,
  ) => {
    setselectedDatasets(selectedDatasets =>
      isDatasetActive(selectedDatasets, datasetToToggle)
        ? selectedDatasets.filter(
            dataset => dataset.label !== datasetToToggle.label,
          )
        : datasets.filter(
            dataset =>
              dataset.label === datasetToToggle.label ||
              isDatasetActive(selectedDatasets, dataset),
          ),
    )
  }

  const savedKilometers: number = selectedDatasets.reduce(
    (counter, currentDataset) => counter + currentDataset.valueKilometers,
    0,
  )

  const savedShowers: number = selectedDatasets.reduce(
    (counter, currentDataset) => counter + currentDataset.valueShowers,
    0,
  )

  return (
    <div className="ImpactTrackerChart">
      <ImpactTrackerChartItem
        defaultIconColor="grey"
        icon={faCar}
        iconText={`${valueFormatter(savedKilometers)} ${
          ImpactTrackerUnits.kilometers
        }`}
        interactive={interactive}
        title={intl.formatMessage({
          id: 'ImpactTracker_saved_co2',
        })}
        totalValue={totalCo2}
        unit={ImpactTrackerUnits.kilograms}
        values={datasets.map(dataset =>
          mapDatasetToChartItemValues(selectedDatasets, dataset, 'valueCo2'),
        )}
      />

      <ImpactTrackerChartItem
        defaultIconColor="grey"
        icon={faBath}
        iconText={`x ${valueFormatter(savedShowers)}`}
        interactive={interactive}
        title={intl.formatMessage({
          id: 'ImpactTracker_saved_water',
        })}
        totalValue={totalWater}
        unit={ImpactTrackerUnits.liters}
        valueFormatter={value => value.toLocaleString().replaceAll(',', '.')}
        values={datasets.map(dataset =>
          mapDatasetToChartItemValues(selectedDatasets, dataset, 'valueWater'),
        )}
      />

      <ImpactTrackerChartItem
        className="mb-0"
        defaultIconColor="grey"
        icon={faRecycle}
        interactive={interactive}
        title={intl.formatMessage({
          id: 'ImpactTracker_saved_raw',
        })}
        totalValue={totalRawMaterial}
        unit={ImpactTrackerUnits.kilograms}
        values={datasets.map(dataset =>
          mapDatasetToChartItemValues(
            selectedDatasets,
            dataset,
            'valueRawMaterial',
          ),
        )}
      />

      {showLegend && (
        <div className="ImpactTrackerChart-legend mt-6 d-flex justify-content-start">
          {datasets.map(dataset => (
            <span
              key={dataset.label}
              className={clsx(
                'me-4',
                dataset.color,
                isDatasetActive(selectedDatasets, dataset) && 'active',
              )}
              data-testid={IMPACT_TRACKER_CHART_LEGEND_BUTTON}
              {...buttonize(handleToggleDataset, dataset)}
            >
              {dataset.label}
            </span>
          ))}
        </div>
      )}
    </div>
  )
}
