import { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { useMediaQuery } from '@react-hookz/web'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import { clsx } from 'clsx'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { OrdersPreviewColumnTypeEnum } from 'Modules/orders/types/OrdersPreviewColumnType.type'

import { Table } from 'Components/_theme'
import { FormatDate } from 'Components/FormatDate/FormatDate'
import { ImageWithFallback } from 'Components/ImageWithFallback/ImageWithFallback'
import { OrderStatusBadge } from 'Components/OrderStatusBadge/OrderStatusBadge'
import { ReturnStatusBadge } from 'Components/ReturnStatusBadge/ReturnStatusBadge'

import { useIsOrganisation } from 'Hooks'

import {
  buttonize,
  dashOrSpaceToUnderscore,
  getFullContactName,
  getOrderDisplayName,
} from 'Utilities'

import { PORTAL_BREAKPOINTS } from 'Constants/constants'
import { E2E_TABLE_DEVICE_NAME, E2E_TABLE_LINK_ORDERS } from 'Constants/e2e'

import { ImagePlaceholderTypesEnum } from 'Types/imagePlaceholders.type'

import { CaseItemType } from 'Portal/__generated__/globalTypes'
import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import {
  EmployeeOrdersWithUrl,
  EmployeeReturnsWithUrl,
  OranisationReturnsWithUrl,
  OrdersTableProps,
  OrganisationOrdersWithUrl,
} from './OrdersTable.types'
import { getContact, getDate } from './OrdersTable.utils'

export const OrdersTable = (props: OrdersTableProps) => {
  const {
    orders,
    returns = [],
    isActiveEmployee,
    isLoading,
    isSearching,
    onOpenPreviewColumn,
    selectedRowIndex,
  } = props

  const { employeeId, organisationId } = useParams<{
    employeeId: string
    organisationId: string
  }>()
  const isOrganisation = useIsOrganisation()

  const routerHistory = useHistory()
  const isTablet = useMediaQuery(`(min-width: ${PORTAL_BREAKPOINTS.LG})`)

  const handleEnterDetails = useCallback(
    (
      _event:
        | React.MouseEvent<HTMLDivElement>
        | React.KeyboardEvent<HTMLDivElement>,
      id: string,
      url: string,
      rowIndex: number,
      previewColumnType: OrdersPreviewColumnTypeEnum,
    ) => {
      if (!isTablet) {
        routerHistory.push(url)

        return
      }

      if (typeof onOpenPreviewColumn === 'function') {
        onOpenPreviewColumn(id, rowIndex, previewColumnType)
      }
    },
    [isTablet, onOpenPreviewColumn, routerHistory],
  )

  const renderOrdersHeader = useCallback(
    () => <FormattedMessage id="OrderListContent_header__title" />,
    [],
  )

  const renderEmployeeHeader = useCallback(
    () => <FormattedMessage id="RequestListContent_header__employee" />,
    [],
  )

  const renderDateHeader = useCallback(
    () => <FormattedMessage id="RequestListContent_header__date" />,
    [],
  )

  const renderStatusHeader = useCallback(
    () => <FormattedMessage id="RequestListContent_header__status" />,
    [],
  )

  const renderOrderCell = useCallback(
    ({
      cell,
    }: CellContext<
      | EmployeeOrdersWithUrl
      | EmployeeReturnsWithUrl
      | OranisationReturnsWithUrl
      | OrganisationOrdersWithUrl,
      unknown
    >) => {
      const item = cell.row.original

      const getOrderItemName = () => {
        if ('offerOrderItem' in item && item.offerOrderItem?.customDeviceName) {
          return item.offerOrderItem?.customDeviceName
        }

        if ('data' in item && item.data.requestedDevice) {
          return item.data.requestedDevice.name ?? ''
        }

        if (
          'data' in item &&
          item.data.requestedRentCategory?.deviceDefinition?.name
        ) {
          return item.data.requestedRentCategory?.deviceDefinition?.name
        }

        if (
          'data' in item &&
          item.data.requestedOfferOrder?.deviceDefinition?.name
        ) {
          return item.data.requestedOfferOrder?.deviceDefinition?.name
        }

        if (
          'data' in item &&
          item.data.requestedRentCategory?.deviceDefinition?.productType
        ) {
          return item.data.requestedRentCategory.deviceDefinition.productType
        }

        if (
          'data' in item &&
          dashOrSpaceToUnderscore(item.caseType) ===
            CaseItemType.new_phone_plan &&
          item.data.requestedPhonePlan?.name
        ) {
          return item.data.requestedPhonePlan.name
        }

        return ''
      }

      const getReturnItemName = () => {
        if (
          'stocklistItem' in item &&
          item.stocklistItem?.deviceDefinition?.manufacturer
        ) {
          return (
            <>
              <FormattedMessage id="Return" />
              :&nbsp;
              {`${item.stocklistItem.deviceDefinition.manufacturer} ${item.stocklistItem.deviceDefinition.codename}`}
            </>
          )
        }

        return <FormattedMessage id="Return" />
      }

      const getItemId = () => {
        if ('stocklistItem' in item && item.id) {
          return `${item.id}`
        }

        return `${item.id}`
      }

      const getReturnAndOrderItemName = () => {
        if ('stocklistItem' in item) {
          return getReturnItemName()
        }

        const displayName = getOrderDisplayName(
          item.caseType ?? '',
          getOrderItemName(),
        )

        return typeof displayName === 'string' ? (
          <FormattedMessage id={displayName} />
        ) : (
          <>
            <FormattedMessage id={displayName.type} />
            :&nbsp;{displayName.name}
          </>
        )
      }

      const getImageProps = (
        item:
          | EmployeeOrdersWithUrl
          | EmployeeReturnsWithUrl
          | OranisationReturnsWithUrl
          | OrganisationOrdersWithUrl,
      ) => {
        if ('stocklistItem' in item) {
          return {
            alt: item.stocklistItem?.deviceDefinition?.codename || '',
            placeholderType: ImagePlaceholderTypesEnum.newOrderFlow,
            src: item.stocklistItem?.deviceDefinition?.imageURL || '',
          }
        }

        return {
          alt:
            item.data.requestedRentCategory?.deviceDefinition?.codename || '',
          placeholderType: ImagePlaceholderTypesEnum.requests,
          src:
            item.data.requestedRentCategory?.deviceDefinition?.imageURL || '',
        }
      }

      const previewColumnType =
        'stocklistItem' in item
          ? OrdersPreviewColumnTypeEnum.RETURN
          : OrdersPreviewColumnTypeEnum.ORDER

      return (
        <div
          className="d-inline-flex"
          {...buttonize(
            handleEnterDetails,
            item.id,
            item.url,
            cell.row.index,
            previewColumnType,
          )}
        >
          <div className="d-flex justify-content-center align-items-center me-4 Table-device-image">
            <ImageWithFallback {...getImageProps(item)} />
          </div>

          <div className="d-flex flex-column justify-content-center">
            <div className="d-flex align-items-center">
              <strong data-e2e={E2E_TABLE_DEVICE_NAME}>
                {getReturnAndOrderItemName()}
              </strong>
            </div>

            <div className="d-flex table-text-subtitle text-muted">
              <span>#</span>

              <span className="text-ellipsis" data-orderid={item.id}>
                {getItemId()}
              </span>
            </div>
          </div>
        </div>
      )
    },
    [handleEnterDetails],
  )

  const renderExpandableContent = useCallback(
    ({
      cell,
    }: CellContext<
      | EmployeeOrdersWithUrl
      | EmployeeReturnsWithUrl
      | OranisationReturnsWithUrl
      | OrganisationOrdersWithUrl,
      unknown
    >) => {
      const item = cell.row.original
      const contact = getContact(item)
      const date = getDate(item)

      return (
        <div className="w-100 Table-details">
          {!isActiveEmployee && (
            <div className="Table-details-row">
              <span className="Table-row--expandable-title">
                {renderEmployeeHeader()}
              </span>

              <span className="text-ellipsis">
                {contact ? getFullContactName(contact) : '-'}
              </span>
            </div>
          )}

          <div className="Table-details-row">
            <span className="Table-row--expandable-title">
              {renderDateHeader()}
            </span>

            <span>
              <FormatDate value={date} />
            </span>
          </div>

          <div className="Table-details-row">
            <span className="Table-row--expandable-title">
              {renderStatusHeader()}
            </span>

            <span>
              {'stocklistItem' in item ? (
                <ReturnStatusBadge status={item.status} />
              ) : (
                <OrderStatusBadge status={item.status} />
              )}
            </span>
          </div>
        </div>
      )
    },
    [
      isActiveEmployee,
      renderDateHeader,
      renderEmployeeHeader,
      renderStatusHeader,
    ],
  )

  const renderEmployeeCell = useCallback(
    ({
      cell,
    }: CellContext<
      | EmployeeOrdersWithUrl
      | EmployeeReturnsWithUrl
      | OranisationReturnsWithUrl
      | OrganisationOrdersWithUrl,
      unknown
    >) => {
      const item = cell.row.original
      const contact = getContact(item)
      const previewColumnType =
        'stocklistItem' in item
          ? OrdersPreviewColumnTypeEnum.RETURN
          : OrdersPreviewColumnTypeEnum.ORDER

      return (
        <div
          className="d-flex align-items-center h-100"
          {...buttonize(
            handleEnterDetails,
            item.id,
            item.url,
            cell.row.index,
            previewColumnType,
          )}
        >
          <span className="text-ellipsis">
            {contact ? getFullContactName(contact) : '-'}
          </span>
        </div>
      )
    },
    [handleEnterDetails],
  )

  const renderDateCell = useCallback(
    ({
      cell,
    }: CellContext<
      | EmployeeOrdersWithUrl
      | EmployeeReturnsWithUrl
      | OranisationReturnsWithUrl
      | OrganisationOrdersWithUrl,
      unknown
    >) => {
      const item = cell.row.original
      const date = getDate(item)
      const previewColumnType =
        'stocklistItem' in item
          ? OrdersPreviewColumnTypeEnum.RETURN
          : OrdersPreviewColumnTypeEnum.ORDER

      return (
        <div
          className="d-flex align-items-center h-100"
          {...buttonize(
            handleEnterDetails,
            item.id,
            item.url,
            cell.row.index,
            previewColumnType,
          )}
        >
          <FormatDate value={date} />
        </div>
      )
    },
    [handleEnterDetails],
  )

  const renderStatusCell = useCallback(
    ({
      cell,
    }: CellContext<
      | EmployeeOrdersWithUrl
      | EmployeeReturnsWithUrl
      | OranisationReturnsWithUrl
      | OrganisationOrdersWithUrl,
      unknown
    >) => {
      const item = cell.row.original
      const previewColumnType =
        'stocklistItem' in item
          ? OrdersPreviewColumnTypeEnum.RETURN
          : OrdersPreviewColumnTypeEnum.ORDER

      return (
        <div
          className="d-flex align-items-center h-100"
          {...buttonize(
            handleEnterDetails,
            item.id,
            item.url,
            cell.row.index,
            previewColumnType,
          )}
        >
          {'stocklistItem' in item ? (
            <ReturnStatusBadge status={item.status} />
          ) : (
            <OrderStatusBadge status={item.status} />
          )}
        </div>
      )
    },
    [handleEnterDetails],
  )

  const columns = useMemo<
    ColumnDef<
      | EmployeeOrdersWithUrl
      | EmployeeReturnsWithUrl
      | OranisationReturnsWithUrl
      | OrganisationOrdersWithUrl
    >[]
  >(() => {
    const tableColumns = [
      {
        cell: renderOrderCell,
        header: renderOrdersHeader,
        id: 'order',
        meta: {
          ExpandableContent: renderExpandableContent,
        },
      },
      {
        cell: renderEmployeeCell,
        header: renderEmployeeHeader,
        id: 'employee',
      },
      {
        cell: renderDateCell,
        header: renderDateHeader,
        id: 'date',
      },
      {
        cell: renderStatusCell,
        header: renderStatusHeader,
        id: 'status',
      },
    ]

    return tableColumns.filter(column => {
      // don't show employee when logged in as employee - they are the same
      if (isActiveEmployee && column.id === 'employee') {
        return false
      }

      return true
    })
  }, [
    isActiveEmployee,
    renderDateCell,
    renderDateHeader,
    renderEmployeeCell,
    renderEmployeeHeader,
    renderExpandableContent,
    renderOrderCell,
    renderOrdersHeader,
    renderStatusCell,
    renderStatusHeader,
  ])

  const data = useMemo((): (
    | EmployeeOrdersWithUrl
    | EmployeeReturnsWithUrl
    | OranisationReturnsWithUrl
    | OrganisationOrdersWithUrl
  )[] => {
    const parsedOrders = orders.map(order => {
      const url = composePath({
        isOrganisation,
        params: {
          employeeId,
          orderId: order.id,
          organisationId,
        },
        paths: {
          employee: GLOBAL_ROUTE_PATHS.employee.order,
          organisation: GLOBAL_ROUTE_PATHS.organisation.order,
        },
      })

      return {
        ...order,
        url,
      }
    })

    const parsedReturns = returns.map(singleReturn => {
      const url = composePath({
        isOrganisation,
        params: {
          employeeId,
          returnCaseId: singleReturn.id,
        },
        paths: {
          employee: GLOBAL_ROUTE_PATHS.employee.return,
          organisation: GLOBAL_ROUTE_PATHS.organisation.return,
        },
      })

      return {
        ...singleReturn,
        url,
      }
    })

    return [...parsedOrders, ...parsedReturns]
  }, [employeeId, isOrganisation, orders, organisationId, returns])

  return (
    <Table
      className={clsx(
        'Table--orders',
        isOrganisation ? 'Table--isAdmin' : 'Table--isEmployee',
      )}
      columns={columns}
      data={data}
      e2eRowId={E2E_TABLE_LINK_ORDERS}
      isLoading={isLoading}
      isSearching={isSearching}
      selectedRowIndex={selectedRowIndex}
    />
  )
}
