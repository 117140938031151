import { FormatDate } from 'Components/FormatDate/FormatDate'

import { getPhonePlanListPreviewColumn_phoneplan } from 'Constants/graphql/queries/detail/__generated__/getPhonePlanListPreviewColumn'

export const generatePhonePlansPreviewColumnContent = (
  phonePlan?: getPhonePlanListPreviewColumn_phoneplan,
) => [
  {
    copyable: true,
    label: 'PreviewColumn_phone_number',
    value: phonePlan?.phoneNumber,
  },
  {
    copyable: true,
    label: 'PreviewColumn_sim_card_number',
    value: phonePlan?.simcardNumber,
  },
  {
    label: 'PreviewColumn_start',
    value: <FormatDate value={phonePlan?.startAt} />,
  },
]
