import { clsx } from 'clsx'

import { CardBodyProps } from './CardBody.types'

export const CardBody = (props: CardBodyProps) => {
  const { children, className, ...restProps } = props

  return (
    <div className={clsx('CardBody', className)} {...restProps}>
      {children}
    </div>
  )
}
