import { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { faCartShopping, faQuestion } from '@fortawesome/pro-solid-svg-icons'
import { observer } from 'mobx-react'

import { RentalDetailsCardModalTypes } from 'Modules/rentals/components/RentalDetailsCard/RentalDetailsCard.types'
import {
  getEarlyUpgradePath,
  getProlongPath,
  getUpgradePath,
} from 'Modules/rentals/utils/rental.utils'

import { Card } from 'Components/_theme'
import { OptionButton } from 'Components/_theme/OptionButton/OptionButton'
import { OptionButtonProps } from 'Components/_theme/OptionButton/OptionButton.types'

import { ExchangeDeviceModal } from 'Modals/ExchangeDeviceModal/ExchangeDeviceModal'
import { LegacyUpgradeDeviceModal } from 'Modals/LegacyUpgradeDeviceModal/LegacyUpgradeDeviceModal'
import { LostOrStolenModal } from 'Modals/LostOrStolenModal/LostOrStolenModal'
import { LostOrStolenModalTypesEnum } from 'Modals/LostOrStolenModal/LostOrStolenModal.types'
import { ReplaceBrokenDeviceModal } from 'Modals/ReplaceBrokenDeviceModal/ReplaceBrokenDeviceModal'
import { RequestPurchaseModal } from 'Modals/RequestPurchaseModal/RequestPurchaseModal'
import { ReturnDeviceModal } from 'Modals/ReturnDeviceModal/ReturnDeviceModal'
import { OPTION_BUTTON } from 'Modals/ServiceAppActionsPopup/ServiceAppActionsPopup.constants'

import { useStore } from 'Stores/index'
import { OriginRouteTypeEnum } from 'Stores/orderStore/orderStore.types'

import { useModal } from 'Hooks'

import {
  E2E_ASK_QUESTION_ACTION,
  E2E_EXCHANGE_DEVICE_ACTION,
  E2E_LOST_STOLEN_DEVICE_ACTION,
  E2E_ORDER_EARLY_UGPRADE_ACTION,
  E2E_ORDER_LEGACY_UPGRADE_ACTION,
  E2E_ORDER_PROLONG_ACTION,
  E2E_ORDER_UPGRADE_ACTION,
  E2E_PURCHASE_DEVICE_ACTION,
  E2E_REPLACE_BROKEN_DEVICE_ACTION,
  E2E_RETURN_DEVICE_ACTION,
} from 'Constants/e2e'

import { iconExchangeDevice } from 'Icons/iconExchangeDevice'
import { iconLostDevice } from 'Icons/iconLostDevice'
import { iconReturnDevice } from 'Icons/iconReturnDevice'
import { iconUpgradeDevice } from 'Icons/iconUpgradeDevice'

import { openIntercomChat } from 'Portal/src/utilities/openIntercomChat/openIntercomChat.util'

import { ServiceAppActionsPopupContentProps } from './ServiceAppActionsPopupContent.types'

export const ServiceAppActionsPopupContent = observer(
  (props: ServiceAppActionsPopupContentProps) => {
    const { rental } = props
    const { orderStore } = useStore()
    const { setOriginRoute } = orderStore

    const history = useHistory()
    const { employeeId } = useParams<{
      employeeId?: string
    }>()

    const { setModal, hideModal } = useModal()

    const handleModalSubmit = useCallback(() => {
      hideModal()
    }, [hideModal])

    const handleOpenReplaceBrokenDeviceModal = useCallback(() => {
      setModal({
        component: ReplaceBrokenDeviceModal,
        isOpen: true,
        props: {
          handleSubmit: handleModalSubmit,
          hide: hideModal,
          rental,
        },
      })
    }, [handleModalSubmit, hideModal, rental, setModal])

    const handleOpenLegacyUpgradeDeviceModal = useCallback(() => {
      setModal({
        component: LegacyUpgradeDeviceModal,
        isOpen: true,
        props: {
          handleSubmit: handleModalSubmit,
          rental,
        },
      })
    }, [handleModalSubmit, rental, setModal])

    const handleOpenExchangeDeviceModal = useCallback(() => {
      setModal({
        component: ExchangeDeviceModal,
        isOpen: true,
        props: {
          handleSubmit: handleModalSubmit,
          hide: hideModal,
          rental,
        },
      })
    }, [handleModalSubmit, hideModal, rental, setModal])

    const handleOpenReturnDeviceModal = useCallback(() => {
      setModal({
        component: ReturnDeviceModal,
        isOpen: true,
        props: {
          handleSubmit: handleModalSubmit,
          hide: hideModal,
          rental,
        },
      })
    }, [handleModalSubmit, hideModal, rental, setModal])

    const handleOpenRequestPurchaseModal = useCallback(() => {
      setModal({
        component: RequestPurchaseModal,
        isOpen: true,
        props: {
          onHide: handleModalSubmit,
          rental,
        },
      })
    }, [handleModalSubmit, rental, setModal])

    const handleOpenLostOrStolenModal = useCallback(() => {
      setModal({
        component: LostOrStolenModal,
        isOpen: true,
        props: {
          onSubmit: () => {},
          rental,
          type: LostOrStolenModalTypesEnum.EMPLOYEE,
        },
      })
    }, [rental, setModal])

    const handleEarlyUpgrade = useCallback(() => {
      setOriginRoute({
        type: OriginRouteTypeEnum.Rental,
        url: history.location.pathname,
      })

      const path = getEarlyUpgradePath(false, rental, employeeId)

      history.push(path)
    }, [employeeId, history, rental, setOriginRoute])

    const handleUpgrade = useCallback(() => {
      setOriginRoute({
        type: OriginRouteTypeEnum.Rental,
        url: history.location.pathname,
      })

      const path = getUpgradePath(false, rental, employeeId)

      history.push(path)
    }, [employeeId, history, rental, setOriginRoute])

    const handleProlong = useCallback(() => {
      setOriginRoute({
        type: OriginRouteTypeEnum.Rental,
        url: history.location.pathname,
      })

      const path = getProlongPath(false, rental, employeeId)

      history.push(path)
    }, [employeeId, history, rental, setOriginRoute])

    const actions = useMemo(
      () => [
        {
          e2eSelector: E2E_ORDER_EARLY_UGPRADE_ACTION,
          icon: iconUpgradeDevice,
          id: RentalDetailsCardModalTypes.EarlyUpgradeDevice,
          isHidden: !rental.actions.showEarlyUpgradeAction,
          name: 'Early_upgrade_device',
          onActionClick: handleEarlyUpgrade,
        },
        {
          e2eSelector: E2E_ORDER_LEGACY_UPGRADE_ACTION,
          icon: iconUpgradeDevice,
          id: RentalDetailsCardModalTypes.LegacyUpgradeDevice,
          isHidden: !rental.actions.showUpgradeAction,
          name: 'Upgrade_device',
          onActionClick: handleOpenLegacyUpgradeDeviceModal,
        },
        {
          e2eSelector: E2E_ORDER_UPGRADE_ACTION,
          icon: iconUpgradeDevice,
          id: RentalDetailsCardModalTypes.RentalCompleteUpgradeDevice,
          isHidden: !rental.actions.showStandardUpgradeAction,
          name: 'Upgrade_device',
          onActionClick: handleUpgrade,
        },
        {
          e2eSelector: E2E_ORDER_PROLONG_ACTION,
          icon: iconUpgradeDevice,
          id: RentalDetailsCardModalTypes.ProlongDevice,
          isHidden: !rental.actions.showDeviceProlongingAction,
          name: 'Extend_rental',
          onActionClick: handleProlong,
        },
        {
          e2eSelector: E2E_REPLACE_BROKEN_DEVICE_ACTION,
          icon: iconExchangeDevice,
          id: RentalDetailsCardModalTypes.ReplaceBrokenDevice,
          isHidden: !rental.actions.showReplaceBrokenDeviceAction,
          name: 'Replace_broken_device',
          onActionClick: handleOpenReplaceBrokenDeviceModal,
        },
        {
          e2eSelector: E2E_LOST_STOLEN_DEVICE_ACTION,
          icon: iconLostDevice,
          id: RentalDetailsCardModalTypes.LostOrStolenModal,
          isHidden: !rental.actions.showLostOrStolenAction,
          name: 'Device_lost_stolen',
          onActionClick: handleOpenLostOrStolenModal,
        },
        {
          e2eSelector: E2E_EXCHANGE_DEVICE_ACTION,
          icon: iconExchangeDevice,
          id: RentalDetailsCardModalTypes.ExchangeDevice,
          isHidden: !rental.actions.showExchangeAction,
          name: 'Exchange_device',
          onActionClick: handleOpenExchangeDeviceModal,
        },
        {
          e2eSelector: E2E_RETURN_DEVICE_ACTION,
          icon: iconReturnDevice,
          id: RentalDetailsCardModalTypes.ReturnDevice,
          isHidden: !rental.actions.showReturnAction,
          name: 'Return_device',
          onActionClick: handleOpenReturnDeviceModal,
        },
        {
          e2eSelector: E2E_PURCHASE_DEVICE_ACTION,
          icon: faCartShopping,
          id: RentalDetailsCardModalTypes.RequestPurchase,
          isHidden: !rental.actions.showPurchaseDeviceAction,
          name: 'Purchase_device',
          onActionClick: handleOpenRequestPurchaseModal,
        },
        {
          e2eSelector: E2E_ASK_QUESTION_ACTION,
          icon: faQuestion,
          id: 'questionButton',
          name: 'PreviewColumn_footer_question',
          onActionClick: () => openIntercomChat(null),
          variant: 'info',
        },
      ],
      [
        rental,
        handleEarlyUpgrade,
        handleOpenLegacyUpgradeDeviceModal,
        handleUpgrade,
        handleProlong,
        handleOpenReplaceBrokenDeviceModal,
        handleOpenLostOrStolenModal,
        handleOpenExchangeDeviceModal,
        handleOpenReturnDeviceModal,
        handleOpenRequestPurchaseModal,
      ],
    )

    return (
      <>
        {actions.map(
          ({ e2eSelector, name, id, icon, isHidden, onActionClick, variant }) =>
            !isHidden && (
              <Card
                key={id}
                className="ServiceAppActionsPopup-action mb-1 mx-2 p-0"
              >
                <OptionButton
                  data-e2e={e2eSelector}
                  data-testid={OPTION_BUTTON}
                  iconLeft={icon}
                  onClick={onActionClick}
                  variant={(variant ?? 'basic') as OptionButtonProps['variant']}
                >
                  <FormattedMessage id={name} />
                </OptionButton>
              </Card>
            ),
        )}
      </>
    )
  },
)
