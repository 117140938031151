import { observer } from 'mobx-react'

import { useShowInstantsearchList } from 'Hooks'

import { EmployeesDefault } from './Default/Employees.page'
import { EmployeesInstantsearch } from './Instantsearch/Employees.page'

export const Employees = observer(() => {
  const showInstantSearchList = useShowInstantsearchList()

  if (showInstantSearchList) {
    return <EmployeesInstantsearch />
  }

  return <EmployeesDefault />
})
