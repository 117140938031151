import { useMemo } from 'react'
import { isNull } from 'lodash-es'

import { CardDetailsListItem } from 'Components/CardDetailsList/CardDetailsList.types'
import { PresentationCard } from 'Components/PresentationCard/PresentationCard'

import { DeviceTypeEnum } from 'Types/deviceTypes'

import { RentalPresentationCardProps } from './RentalPresentationCard.types'

export const RentalPresentationCard = (props: RentalPresentationCardProps) => {
  const { title, stocklistItem } = props

  const generateListItems = useMemo(
    (): CardDetailsListItem[] => [
      {
        label: 'RentalDetails_imei',
        value: stocklistItem?.imei,
      },
      {
        label: 'RentalDetails_imei2',
        value: stocklistItem?.imei2,
      },
      {
        label: 'RentalDetails_serial',
        value: stocklistItem?.serialNumber,
      },
      {
        label: 'RentalDetails_eid',
        value: stocklistItem?.eid,
      },
    ],
    [
      stocklistItem?.eid,
      stocklistItem?.imei,
      stocklistItem?.imei2,
      stocklistItem?.serialNumber,
    ],
  )

  if (isNull(stocklistItem)) {
    return null
  }

  return (
    <PresentationCard
      header={stocklistItem?.deviceDefinition?.name ?? ''}
      imagePlaceholderType={
        stocklistItem?.deviceDefinition?.productType as DeviceTypeEnum
      }
      imageSource={stocklistItem.deviceDefinition?.imageURL}
      listItems={generateListItems}
      title={title}
    />
  )
}
