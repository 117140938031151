/* eslint-disable react/button-has-type */
import { ForwardedRef, forwardRef } from 'react'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons'
import { clsx } from 'clsx'

import { Icon } from 'Components/Icon/Icon'

import { ButtonProps } from './Button.types'

export const Button = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps
>((props, ref) => {
  const {
    asIcon,
    children,
    className,
    disabled,
    iconLeft,
    iconRight,
    isLoading,
    href,
    size = 'medium',
    type = 'button',
    ...restProps
  } = props

  const iconSize = size === 'large' ? 24 : 16

  const content = (
    <>
      {iconLeft && <Icon icon={iconLeft} size={iconSize} />}
      <span>{children}</span>
      {(iconRight || isLoading) && (
        <Icon
          icon={isLoading ? faSpinnerThird : (iconRight as IconDefinition)}
          size={iconSize}
          spin={isLoading}
        />
      )}
    </>
  )

  const isDisabled = disabled || isLoading

  const classNames = clsx(
    'Button',
    `Button-${size}`,
    asIcon && 'Button-icon',
    iconLeft && 'Button-icon-left',
    (iconRight || isLoading) && 'Button-icon-right',
    className,
  )

  // LinkButton as Icon
  if (href && asIcon) {
    return (
      <a
        ref={ref as ForwardedRef<HTMLAnchorElement>}
        aria-label={restProps['aria-label']}
        className={classNames}
        href={href}
      >
        <Icon
          icon={isLoading ? faSpinnerThird : asIcon}
          size={iconSize}
          spin={isLoading}
        />
      </a>
    )
  }

  // IconButton
  if (asIcon) {
    return (
      <button
        ref={ref as ForwardedRef<HTMLButtonElement>}
        className={classNames}
        disabled={isDisabled}
        type={type}
        {...restProps}
      >
        <Icon
          icon={isLoading ? faSpinnerThird : asIcon}
          size={iconSize}
          spin={isLoading}
        />
      </button>
    )
  }

  // LinkButton
  if (href) {
    return (
      <a
        ref={ref as ForwardedRef<HTMLAnchorElement>}
        className={classNames}
        href={href}
        title={restProps.title}
      >
        {content}
      </a>
    )
  }

  return (
    <button
      ref={ref as ForwardedRef<HTMLButtonElement>}
      className={classNames}
      disabled={isDisabled}
      type={type}
      {...restProps}
    >
      {content}
    </button>
  )
})
