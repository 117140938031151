import { useCallback, useMemo, useState } from 'react'
import { Form } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { faCircleInfo, faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { zodResolver } from '@hookform/resolvers/zod'
import { observer } from 'mobx-react'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { getUpgradePath } from 'Modules/rentals/utils/rental.utils'

import { Alert, LinkButton, PrimaryButton } from 'Components/_theme'
import { CardDetailsListItem } from 'Components/CardDetailsList/CardDetailsList.types'
import { Field } from 'Components/Field/Field'
import { FieldTypeEnum } from 'Components/Field/Field.types'
import { FormatDate } from 'Components/FormatDate/FormatDate'
import { FormattedCurrency } from 'Components/FormattedCurrency/FormattedCurrency'
import { Icon } from 'Components/Icon/Icon'
import { PageErrorState } from 'Components/PageErrorState/PageErrorState'
import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'
import { PresentationCard } from 'Components/PresentationCard/PresentationCard'
import { ScrollToTop } from 'Components/ScrollToTop/ScrollToTop'

import { useStore } from 'Stores/index'

import { useActiveUser, useIsOrganisation } from 'Hooks'

import {
  E2E_PROLONGING_CHOOSE_NEW_DEVICE,
  E2E_PROLONGING_EXTEND_BUTTON,
} from 'Constants/e2e'
import {
  mutationProlongRental,
  mutationProlongRentalVariables,
} from 'Constants/graphql/mutations/__generated__/mutationProlongRental'
import { MUTATION_PROLONG_RENTAL } from 'Constants/graphql/mutations/MutationProlongRental'

import { DeviceTypeEnum } from 'Types/deviceTypes'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import { OrderStickyFooter } from '../../OrderStickyFooter/OrderStickyFooter'
import { prolongInfoStepSchema } from './ProlongInfoStep.schema'
import { ProlongInfoStepSchema } from './ProlongInfoStep.types'

export const ProlongInfoStep = observer(() => {
  const { rentalStore, orderStore } = useStore()
  const {
    rentalDetails,
    isLoadingRentalDetails,
    isRentalDetailsError,
    setRentalProlonging,
  } = rentalStore

  const { setIsUpgradeFromProlongingPage } = orderStore

  const { rentalId, employeeId, organisationId } = useParams<{
    rentalId: string
    employeeId?: string
    organisationId?: string
  }>()

  const { activeOrganisation, activeEmployee } = useActiveUser()

  const activeEmployeeId = employeeId || activeEmployee?.id.toString()

  const isOrganisation = useIsOrganisation()

  const history = useHistory()
  const intl = useIntl()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)

  const formMethods = useForm<ProlongInfoStepSchema>({
    defaultValues: {
      terms: false,
    },
    resolver: zodResolver(prolongInfoStepSchema),
  })

  const [createProlongDeviceCaseItem, { loading: isMutationLoading }] =
    useMutation<mutationProlongRental, mutationProlongRentalVariables>(
      MUTATION_PROLONG_RENTAL,
      {
        onCompleted: data => {
          setIsLoading(false)

          setRentalProlonging(data.prolongRental.rentalProlonging)

          const path = composePath({
            isOrganisation,
            params: {
              employeeId: activeEmployeeId,
              organisationId: activeOrganisation?.id,
            },
            paths: {
              employee: GLOBAL_ROUTE_PATHS.employee.orderProlongConfirmation,
              organisation: activeEmployeeId
                ? GLOBAL_ROUTE_PATHS.organisation
                    .employeeOrderProlongConfirmation
                : GLOBAL_ROUTE_PATHS.organisation.orderProlongConfirmation,
            },
          })

          history.replace(path)
        },
        onError: () => {
          setError(true)
        },
        variables: {
          rentalID: rentalId,
        },
      },
    )

  const handleSubmit = formMethods.handleSubmit(() => {
    setIsLoading(true)

    createProlongDeviceCaseItem()
  })

  const redirectToUpgrade = useCallback(() => {
    if (!rentalDetails) {
      return
    }

    const path = getUpgradePath(
      isOrganisation,
      rentalDetails?.rental,
      employeeId || rentalDetails.rental.employee?.id,
      organisationId,
    )

    setIsUpgradeFromProlongingPage(true)

    history.push(path)
  }, [
    employeeId,
    history,
    isOrganisation,
    organisationId,
    rentalDetails,
    setIsUpgradeFromProlongingPage,
  ])

  const renderNextButtons = useMemo(
    () => (
      <>
        <LinkButton
          className="btn-link me-4"
          data-e2e={E2E_PROLONGING_CHOOSE_NEW_DEVICE}
          onClick={redirectToUpgrade}
        >
          <FormattedMessage id="Rental_prolonging_prolong_upgrade" />
        </LinkButton>

        <PrimaryButton
          aria-label={intl.formatMessage({
            id: 'Extend',
          })}
          data-e2e={`${E2E_PROLONGING_EXTEND_BUTTON}`}
          type="submit"
        >
          {!isLoading ? (
            <FormattedMessage id="Extend_now" />
          ) : (
            <Icon icon={faSpinner} spin />
          )}
        </PrimaryButton>
      </>
    ),
    [redirectToUpgrade, intl, isLoading],
  )

  if (isLoadingRentalDetails || isMutationLoading) {
    return <PageLoadingState />
  }

  if (isRentalDetailsError || !rentalDetails) {
    return <PageErrorState />
  }

  if (!rentalDetails.rental.actions.showDeviceProlongingAction) {
    return (
      <Alert className="mt-4" iconLeft={faCircleInfo} variant="info">
        <FormattedMessage id="RentalProlonging_not_eligible" />
      </Alert>
    )
  }

  const { rental } = rentalDetails

  const { startDate, endDate, prolongAfterInMonths } =
    rental.rentalProlongingPrice

  const generateListItems: CardDetailsListItem[] = [
    ...(rentalDetails.rental.coPaymentAmount > 0
      ? [
          {
            label: 'RentalDetails_copayment',
            value: (
              <>
                <span className="text-primary">
                  <FormattedCurrency
                    value={rental.rentalProlongingPrice?.copaymentAmount ?? 0}
                  />
                </span>
                &nbsp;
                <span className="text-decoration-line-through">
                  <FormattedCurrency value={rental.coPaymentAmount} />
                </span>
              </>
            ),
          },
        ]
      : [
          {
            label: 'RentalDetails_copayment',
            value: (
              <span>
                <FormattedCurrency
                  value={rental.rentalProlongingPrice?.copaymentAmount ?? 0}
                />
              </span>
            ),
          },
        ]),
    {
      label: 'Start',
      value: <FormatDate value={startDate} />,
    },
    ...(prolongAfterInMonths < 6
      ? [
          {
            label: 'End',
            value: <FormatDate value={endDate} />,
          },
        ]
      : [
          {
            label: 'Duration',
            value: (
              <FormattedMessage
                id="RentalDetails_months"
                values={{
                  total: rental.rentalProlongingPrice.prolongAfterInMonths,
                }}
              />
            ),
          },
        ]),
  ]

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit}>
        <div className="py-6">
          <ScrollToTop />

          {error && (
            <Alert className="mb-6" iconLeft={faCircleInfo} variant="info">
              {error}
            </Alert>
          )}

          <p className="mb-6">
            <FormattedMessage id="Rental_prolonging_description" />
          </p>

          <PresentationCard
            cardClassName="DeviceRentalStep"
            header={rental.stocklistItem?.deviceDefinition?.name ?? ''}
            imagePlaceholderType={
              rental.stocklistItem?.deviceDefinition
                ?.productType as DeviceTypeEnum
            }
            imageSource={rental.stocklistItem?.deviceDefinition?.imageURL}
            listItems={generateListItems}
          />

          <Field
            containerClassName="mt-6"
            label="ProlongationSummary_accept"
            name="terms"
            type={FieldTypeEnum.checkbox}
          />
        </div>

        <OrderStickyFooter renderNextButtonElement={() => renderNextButtons} />
      </Form>
    </FormProvider>
  )
})
