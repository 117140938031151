import { useMediaQuery } from '@react-hookz/web'
import { clsx } from 'clsx'

import { Icon } from 'Components/Icon/Icon'

import { PORTAL_BREAKPOINTS } from 'Constants/constants'

import { PrimaryButton } from '../PrimaryButton/PrimaryButton'
import { ActionButtonProps } from './ActionButton.types'

export const ActionButton = (props: ActionButtonProps) => {
  const {
    children,
    className,
    icon,
    variant = 'basic',
    disabled,
    onClick,
    ...restProps
  } = props

  const classNames = clsx(
    'ActionButton',
    variant !== 'basic' && `ActionButton-${variant}`,
    className,
  )

  const isMobile = useMediaQuery(`(max-width: ${PORTAL_BREAKPOINTS.MD})`)

  return (
    <PrimaryButton
      appearance="outline"
      className={classNames}
      disabled={disabled}
      onClick={onClick}
      size="large"
      variant={variant}
      {...restProps}
    >
      <span className="ActionButton-content">
        <Icon icon={icon} size={isMobile ? 16 : 32} />

        <span className="ActionButton-content-text">{children}</span>
      </span>
    </PrimaryButton>
  )
}
