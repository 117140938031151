import { observer } from 'mobx-react'

import { useShowInstantsearchList } from 'Hooks'

import { OrdersDefault } from './Default/Orders.page'
import { OrdersInstantsearch } from './Instantsearch/Orders.page'

export const Orders = observer(() => {
  const showInstantSearchList = useShowInstantsearchList()

  if (showInstantSearchList) {
    return <OrdersInstantsearch />
  }

  return <OrdersDefault />
})
