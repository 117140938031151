import { faXmark } from '@fortawesome/pro-solid-svg-icons'
import { clsx } from 'clsx'

import { Card, TertiaryButton } from 'Components/_theme'

import { SERVICE_APP_ACTIONS_POPUP_CLOSE } from 'Modals/ServiceAppActionsPopup/ServiceAppActionsPopup.constants'

import { ServiceAppActionsPopupContainerProps } from './ServiceAppActionsPopupContainer.types'

export const ServiceAppActionsPopupContainer = (
  props: ServiceAppActionsPopupContainerProps,
) => {
  const { onClose, children, className } = props

  return (
    <Card
      className={clsx(
        'ContentCard ServiceAppActionsPopup position-relative',
        className,
      )}
    >
      <TertiaryButton
        aria-label="Close"
        asIcon={faXmark}
        className="ServiceAppActionsPopup-close position-absolute"
        data-testid={SERVICE_APP_ACTIONS_POPUP_CLOSE}
        onClick={onClose}
      />
      {children}
    </Card>
  )
}
