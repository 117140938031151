import { useCallback, useMemo, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import TextareaAutosize from 'react-textarea-autosize'
import { useQuery } from '@apollo/client'
import {
  faPenToSquare,
  faSpinner,
  faTurnDownRight,
} from '@fortawesome/pro-solid-svg-icons'
import { clsx } from 'clsx'
import { observer } from 'mobx-react'

import { showErrorModal } from 'Modules/order/utils/errorModal.utils'

import {
  Checkbox,
  Heading,
  LinkButton,
  PrimaryButton,
  TertiaryButton,
  Textarea,
} from 'Components/_theme'
import { FormattedCurrency } from 'Components/FormattedCurrency/FormattedCurrency'
import { Icon } from 'Components/Icon/Icon'
import { ImageWithFallback } from 'Components/ImageWithFallback/ImageWithFallback'

import { useStore } from 'Stores/index'

import { useActiveUser, useIsOrganisation, useModal } from 'Hooks'

import { countryISOCodes } from 'Constants/constants'
import { E2E_NEW_ORDER_SUBMIT } from 'Constants/e2e'
import {
  getOrderTerms,
  getOrderTermsVariables,
} from 'Constants/graphql/queries/detail/__generated__/getOrderTerms'
import { GET_ORDER_TERMS } from 'Constants/graphql/queries/detail/GetOrderTerms'
import { SupportedLanguagesEnum } from 'Constants/locale'

import { ImagePlaceholderTypesEnum } from 'Types/imagePlaceholders.type'

import { OrderDeviceResponseVariant } from 'Portal/src/mappers/orderDeviceMapper/orderDevice.types'

import { OrderStickyFooter } from '../OrderStickyFooter/OrderStickyFooter'
import {
  OrderSummaryDeviceRowRendererProps,
  OrderSummaryProps,
  OrderSummaryTerms,
} from './OrderSummary.types'
import { OrderSummaryQuantityInput } from './OrderSummaryQuantityInput'
import { OrderSummaryTextField } from './OrderSummaryTextField'

export const OrderSummary = observer((props: OrderSummaryProps) => {
  const {
    chosenDevice,
    chosenAccessories,
    chosenShippingAddress,
    isLoading,
    onOrderButtonClick,
    totalPrice,
    onBackButtonClick,
    onChangeDeviceButtonClick,
    onSetChosenDeviceQuantity,
    onSetAccessoryTextField,
    onSetAccessoryQuantity,
    onSetAddonDeviceTextField,
    onSetChosenDeviceTextField,
    onSetExternalId,
    isEarlyUpgrade,
    earlyUpgradeFee,
    isNewOrder,
    orderDeviceResponseVariant,
    orderExternalId,
    onSetPhonePlanDetails,
    phonePlanDetails,
  } = props

  const { portalConfigStore, portalSettingsStore, orderStore } = useStore()

  const { portalConfig } = portalConfigStore
  const {
    i18n: { language },
  } = portalSettingsStore
  const { isProductOffer } = orderStore
  const isOrganisation = useIsOrganisation()

  const { setModal, hideModal } = useModal()

  const isBundle = chosenDevice?.isBundle

  const chosenDeviceName = isBundle
    ? chosenDevice.bundleName || chosenDevice.customDeviceName
    : chosenDevice?.deviceDefinition.name

  const chosenDeviceImage = isBundle
    ? chosenDevice.bundleImage
    : chosenDevice?.deviceDefinition.imageURL

  const enableCustomerLabels = portalConfig?.enableCustomerLabels ?? false
  const enablePoRcnInOrderSummary =
    isOrganisation && !!portalConfig?.poRcnInOrderSummary
  const enableClientReferenceInOrderSummary =
    isOrganisation && !!portalConfig?.customClientReferenceInOrderSummary
  const enableBillingIdentifierInOrderSummary =
    isOrganisation && !!portalConfig?.billingIdentifierInOrderSummary

  const intl = useIntl()

  const { activeOrganisation, activeEmployee } = useActiveUser()
  const organisation =
    activeOrganisation || activeEmployee?.organisation || null

  const {
    data: orderTerms,
    loading: isTermsListLoading,
    error: termsError,
  } = useQuery<getOrderTerms, getOrderTermsVariables>(GET_ORDER_TERMS, {
    onError: error => {
      showErrorModal(
        () => {
          hideModal()
        },
        setModal,
        error,
        hideModal,
      )
    },
    variables: {
      orderTermsInput: {
        hasItemsWithCoPayment: [...chosenAccessories.values()].some(
          accessory => accessory.copaymentNet > 0,
        ),
        offerID: chosenDevice?.offerId || '0',
        organisationID: organisation?.id || '0',
      },
    },
  })

  const [termsAcceptance, setTermsAcceptance] = useState<OrderSummaryTerms>({
    orgTerms: false,
    productOfferTerms: false,
    rentCategoryTerms: false,
  })

  const termsForCurrentLanguage = orderTerms?.getOrderTerms.OrderTerms?.find(
    orderTerm => orderTerm?.locale === language,
  )?.OrderTerm

  const termsFallback = orderTerms?.getOrderTerms.OrderTerms?.find(
    orderTerm => orderTerm?.locale === SupportedLanguagesEnum.EN,
  )?.OrderTerm

  const applicableTerms = termsForCurrentLanguage ||
    termsFallback || {
      organisation: null,
      productOffer: null,
      rentCategory: null,
    }

  const terms = useMemo(
    () => [
      {
        checked: termsAcceptance.orgTerms,
        label: applicableTerms.organisation ?? '',
        name: 'orgTerms',
        show: !!applicableTerms.organisation,
      },
      {
        checked: termsAcceptance.rentCategoryTerms,
        label: applicableTerms.rentCategory ?? '',
        name: 'rentCategoryTerms',
        show: !!applicableTerms.rentCategory,
      },
      {
        checked: termsAcceptance.productOfferTerms,
        label: applicableTerms.productOffer ?? '',
        name: 'productOfferTerms',
        show: !!applicableTerms.productOffer,
      },
    ],
    [
      applicableTerms.organisation,
      applicableTerms.productOffer,
      applicableTerms.rentCategory,
      termsAcceptance.orgTerms,
      termsAcceptance.productOfferTerms,
      termsAcceptance.rentCategoryTerms,
    ],
  )

  const isDevicePhoneRelatedType = (type?: string | null) =>
    type === 'sim_card' || type === 'phone_plan'

  const showPhonePlanDetailsField = useMemo(
    () =>
      isProductOffer &&
      (isDevicePhoneRelatedType(chosenDevice?.deviceDefinition.productType) ||
        chosenDevice?.addonDevices?.some(device =>
          isDevicePhoneRelatedType(device.deviceDefinition.productType),
        ) ||
        [...chosenAccessories.values()].some(accessory =>
          isDevicePhoneRelatedType(accessory.deviceDefinition.productType),
        )),
    [chosenAccessories, chosenDevice, isProductOffer],
  )

  const atLeastOneTermIsNotAccepted =
    (!!applicableTerms.organisation && !termsAcceptance.orgTerms) ||
    (!!applicableTerms.rentCategory && !termsAcceptance.rentCategoryTerms) ||
    (!!applicableTerms.productOffer && !termsAcceptance.productOfferTerms)

  const showNestedView =
    chosenDevice?.bundleImage !== chosenDevice?.deviceDefinition.imageURL ||
    (chosenDevice?.bundleName || chosenDevice?.customDeviceName) !==
      chosenDevice?.deviceDefinition.name

  const isRentCategoryDevice =
    orderDeviceResponseVariant === OrderDeviceResponseVariant.RENT_CATEGORY

  const renderSummaryHeadingRow = useMemo(
    () => (
      <Row className="order-container grey-container p-2 px-4 d-flex justify-content-between align-items-center">
        <Col className="ps-0" xs={isNewOrder ? 6 : 8}>
          <Heading as={6} size={3}>
            <FormattedMessage id="NewOrderSummary_device" />
          </Heading>
        </Col>

        <Col className="text-end" xs={2}>
          <Heading as={6} size={3}>
            <FormattedMessage id="NewOrderSummary_rental_duration" />
          </Heading>
        </Col>

        {isNewOrder && isOrganisation && (
          <Col className="text-end" xs={2}>
            <Heading as={6} size={3}>
              <FormattedMessage id="NewOrderSummary_quantity" />
            </Heading>
          </Col>
        )}

        <Col className="text-end pe-0" xs={2}>
          <Heading as={6} size={3}>
            <FormattedMessage id="NewOrderSummary_price" />
          </Heading>
        </Col>
      </Row>
    ),
    [isNewOrder, isOrganisation],
  )

  const renderSummaryDeviceRow = useCallback(
    (props: OrderSummaryDeviceRowRendererProps) => {
      const {
        isAddon,
        isBundleRow,
        isBundleItemRow,
        isCreateSeparateRental,
        deviceName,
        deviceImage,
        deviceQuantity,
        deviceRentalDuration,
        deviceId,
        devicePrice,
        deviceCustomerLabel,
        poRcn,
        customClientReference,
        billingIdentifier,
      } = props

      const showCustomerLabel =
        isOrganisation && enableCustomerLabels && !isBundleRow
      const showPoRcn =
        isOrganisation && enablePoRcnInOrderSummary && !isBundleRow
      const showClientReference =
        isOrganisation && enableClientReferenceInOrderSummary && !isBundleRow
      const showBillingIdentifier =
        isOrganisation && enableBillingIdentifierInOrderSummary && !isBundleRow

      const hideDurationAndPrice = isAddon && !isCreateSeparateRental

      return (
        <Row
          key={deviceId}
          className={clsx(
            'px-4 py-6 device-overview',
            isNewOrder && !isOrganisation && 'justify-content-between',
          )}
        >
          <Col
            className={clsx(isBundleItemRow && 'd-flex')}
            xs={isNewOrder ? 6 : 8}
          >
            {isBundleItemRow && (
              <Icon className="me-2" icon={faTurnDownRight} size={20} />
            )}
            <div className="d-flex align-items-center">
              <ImageWithFallback
                alt={deviceName || ''}
                placeholderType={ImagePlaceholderTypesEnum.newOrderFlow}
                src={deviceImage || ''}
              />

              <strong className="ms-2 ms-md-6">{deviceName}</strong>
            </div>
          </Col>

          <Col className="d-flex justify-content-end align-items-center" xs={2}>
            {!isBundleRow && !hideDurationAndPrice && (
              <>
                {deviceRentalDuration}&nbsp;
                <FormattedMessage id="NewOrderSummary_months" />
              </>
            )}
          </Col>

          {isNewOrder && isOrganisation && (
            <Col
              className="d-flex justify-content-end align-items-center"
              xs={2}
            >
              {isBundleItemRow || hideDurationAndPrice ? (
                deviceQuantity
              ) : (
                <OrderSummaryQuantityInput
                  controlId={`deviceQuantity-${deviceId}`}
                  defaultValue={deviceQuantity}
                  onChangeInput={value => {
                    onSetChosenDeviceQuantity(value)
                  }}
                />
              )}
            </Col>
          )}

          {!hideDurationAndPrice && (
            <Col
              className="d-flex justify-content-end align-items-center"
              xs={2}
            >
              {!isBundleRow && <FormattedCurrency value={devicePrice} />}
            </Col>
          )}

          {showCustomerLabel && (
            <Col className="ps-0" md={8} xs={12}>
              <OrderSummaryTextField
                controlId={`customDeviceLabel-${deviceId}`}
                defaultValue={deviceCustomerLabel || ''}
                onChangeLabel={event =>
                  isAddon
                    ? onSetAddonDeviceTextField(
                        'customerLabel',
                        event.currentTarget.value,
                        deviceId,
                      )
                    : onSetChosenDeviceTextField(
                        'customerLabel',
                        event.currentTarget.value,
                      )
                }
                placeholderTranslationId="NewOrderSummary_label_placeholder"
              />
            </Col>
          )}

          {showPoRcn && (
            <Col className="ps-0" md={8} xs={12}>
              <OrderSummaryTextField
                controlId={`poRcn-${deviceId}`}
                defaultValue={poRcn || ''}
                onChangeLabel={event =>
                  isAddon
                    ? onSetAddonDeviceTextField(
                        'poRcn',
                        event.currentTarget.value,
                        deviceId,
                      )
                    : onSetChosenDeviceTextField(
                        'poRcn',
                        event.currentTarget.value,
                      )
                }
                placeholderTranslationId="NewOrderSummary_po_rcn_placeholder"
              />
            </Col>
          )}

          {showClientReference && (
            <Col className="ps-0" md={8} xs={12}>
              <OrderSummaryTextField
                controlId={`customClientReference-${deviceId}`}
                defaultValue={customClientReference || ''}
                onChangeLabel={event =>
                  isAddon
                    ? onSetAddonDeviceTextField(
                        'customClientReference',
                        event.currentTarget.value,
                        deviceId,
                      )
                    : onSetChosenDeviceTextField(
                        'customClientReference',
                        event.currentTarget.value,
                      )
                }
                placeholderTranslationId="NewOrderSummary_custom_client_reference_placeholder"
              />
            </Col>
          )}

          {showBillingIdentifier && (
            <Col className="ps-0" md={8} xs={12}>
              <OrderSummaryTextField
                controlId={`billingIdentifier-${deviceId}`}
                defaultValue={billingIdentifier || ''}
                onChangeLabel={event =>
                  isAddon
                    ? onSetAddonDeviceTextField(
                        'billingIdentifier',
                        event.currentTarget.value,
                        deviceId,
                      )
                    : onSetChosenDeviceTextField(
                        'billingIdentifier',
                        event.currentTarget.value,
                      )
                }
                placeholderTranslationId="NewOrderSummary_billing_identifier_placeholder"
              />
            </Col>
          )}
        </Row>
      )
    },
    [
      enableCustomerLabels,
      enableBillingIdentifierInOrderSummary,
      enableClientReferenceInOrderSummary,
      enablePoRcnInOrderSummary,
      isNewOrder,
      isOrganisation,
      onSetChosenDeviceTextField,
      onSetChosenDeviceQuantity,
      onSetAddonDeviceTextField,
    ],
  )

  const maxAccessoriesQuantity = chosenDevice?.quantity

  return (
    <>
      <Container className="OrderFlow-summary" fluid>
        <Row className="overflow-auto">
          <Col className="scrollable-on-mobile">
            {renderSummaryHeadingRow}

            {(showNestedView || !isBundle) &&
              renderSummaryDeviceRow({
                billingIdentifier: chosenDevice?.billingIdentifier || '',
                customClientReference:
                  chosenDevice?.customClientReference || '',
                deviceCustomerLabel: chosenDevice?.customerLabel || '',
                deviceImage: chosenDeviceImage || '',
                deviceName: chosenDeviceName || '',
                devicePrice: chosenDevice?.copaymentNet || 0,
                deviceQuantity: chosenDevice?.quantity || 1,
                deviceRentalDuration:
                  chosenDevice?.minimumContractDuration || 0,
                isBundleRow: isBundle,
                poRcn: chosenDevice?.poRcn || '',
              })}

            {isBundle && (
              <>
                {renderSummaryDeviceRow({
                  billingIdentifier: chosenDevice?.billingIdentifier || '',
                  customClientReference:
                    chosenDevice?.customClientReference || '',
                  deviceCustomerLabel: chosenDevice?.customerLabel || '',
                  deviceImage: chosenDevice?.deviceDefinition.imageURL || '',
                  deviceName:
                    chosenDevice?.customDeviceName ||
                    chosenDevice?.deviceDefinition.name ||
                    '',
                  devicePrice: chosenDevice?.copaymentNet || 0,
                  deviceQuantity: chosenDevice?.quantity || 1,
                  deviceRentalDuration:
                    chosenDevice?.minimumContractDuration || 0,
                  isBundleItemRow: showNestedView,
                  poRcn: chosenDevice?.poRcn || '',
                })}

                {chosenDevice?.addonDevices?.map(addonDevice =>
                  renderSummaryDeviceRow({
                    billingIdentifier: addonDevice?.billingIdentifier || '',
                    customClientReference:
                      addonDevice?.customClientReference || '',
                    deviceCustomerLabel: addonDevice?.customerLabel || '',
                    deviceId: addonDevice.id,
                    deviceImage: addonDevice.deviceDefinition.imageURL || '',
                    deviceName:
                      addonDevice.customDeviceName ||
                      addonDevice.deviceDefinition.name,
                    devicePrice: addonDevice.copaymentNet,
                    deviceQuantity: addonDevice.quantity,
                    deviceRentalDuration:
                      addonDevice.minimumContractDuration || 0,
                    isAddon: true,
                    isBundleItemRow: showNestedView,
                    isCreateSeparateRental: addonDevice.isCreateSeparateRental,
                    poRcn: addonDevice?.poRcn || '',
                  }),
                )}
              </>
            )}

            {[...chosenAccessories].map(chosenAccessory => {
              const [_id, accessory] = chosenAccessory

              return (
                <Row
                  key={accessory.rentCategoryId + accessory.deviceDefinition.id}
                  className={clsx(
                    'd-flex px-4 py-6 device-overview',
                    isNewOrder && !isOrganisation && 'justify-content-between',
                  )}
                >
                  <Col className="ps-0" xs={isNewOrder ? 6 : 8}>
                    <div className="d-flex align-items-center">
                      <ImageWithFallback
                        alt={
                          accessory.customDeviceName ||
                          accessory.deviceDefinition.name
                        }
                        placeholderType={ImagePlaceholderTypesEnum.newOrderFlow}
                        src={accessory.deviceDefinition.imageURL || ''}
                      />

                      <strong className="ms-2 ms-md-6">
                        {accessory.customDeviceName ||
                          accessory.deviceDefinition.name}
                      </strong>
                    </div>
                  </Col>

                  <Col
                    className="d-flex justify-content-end align-items-center"
                    xs={2}
                  >
                    {(isRentCategoryDevice ||
                      accessory.isCreateSeparateRental) && (
                      <>
                        {accessory?.minimumContractDuration}&nbsp;
                        <FormattedMessage id="NewOrderSummary_months" />
                      </>
                    )}
                  </Col>

                  {isNewOrder && isOrganisation && (
                    <Col
                      className="d-flex justify-content-end align-items-center"
                      xs={2}
                    >
                      <OrderSummaryQuantityInput
                        canChooseOnlyMinOrMaxQuantity={
                          !accessory.isCreateSeparateRental &&
                          accessory.basisAmount + accessory.copaymentNet > 0
                        }
                        controlId="accessoryQuantity"
                        defaultValue={accessory.quantity ?? 1}
                        maxQuantity={maxAccessoriesQuantity}
                        minQuantity={0}
                        onChangeInput={value => {
                          onSetAccessoryQuantity(value, accessory)
                        }}
                      />
                    </Col>
                  )}

                  {(isRentCategoryDevice ||
                    accessory.isCreateSeparateRental) && (
                    <Col
                      className="d-flex justify-content-end align-items-center"
                      xs={2}
                    >
                      <FormattedCurrency value={accessory.copaymentNet || 0} />
                    </Col>
                  )}

                  {isOrganisation && enableCustomerLabels && (
                    <Col className="ps-0" md={8} xs={12}>
                      <OrderSummaryTextField
                        controlId="customAccessoryLabel"
                        defaultValue={accessory.customerLabel ?? ''}
                        onChangeLabel={event =>
                          onSetAccessoryTextField(
                            'customerLabel',
                            event.currentTarget.value,
                            accessory,
                          )
                        }
                        placeholderTranslationId="NewOrderSummary_label_placeholder"
                      />
                    </Col>
                  )}

                  {enablePoRcnInOrderSummary && (
                    <Col className="ps-0" md={8} xs={12}>
                      <OrderSummaryTextField
                        controlId="poRcnAccessory"
                        defaultValue={accessory.poRcn || ''}
                        onChangeLabel={event =>
                          onSetAccessoryTextField(
                            'poRcn',
                            event.currentTarget.value,
                            accessory,
                          )
                        }
                        placeholderTranslationId="NewOrderSummary_po_rcn_placeholder"
                      />
                    </Col>
                  )}

                  {enableClientReferenceInOrderSummary && (
                    <Col className="ps-0" md={8} xs={12}>
                      <OrderSummaryTextField
                        controlId="customClientReferenceAccessory"
                        defaultValue={accessory.customClientReference || ''}
                        onChangeLabel={event =>
                          onSetAccessoryTextField(
                            'customClientReference',
                            event.currentTarget.value,
                            accessory,
                          )
                        }
                        placeholderTranslationId="NewOrderSummary_custom_client_reference_placeholder"
                      />
                    </Col>
                  )}

                  {enableBillingIdentifierInOrderSummary && (
                    <Col className="ps-0" md={8} xs={12}>
                      <OrderSummaryTextField
                        controlId="billingIdentifierAccessory"
                        defaultValue={accessory.billingIdentifier || ''}
                        onChangeLabel={event =>
                          onSetAccessoryTextField(
                            'billingIdentifier',
                            event.currentTarget.value,
                            accessory,
                          )
                        }
                        placeholderTranslationId="NewOrderSummary_billing_identifier_placeholder"
                      />
                    </Col>
                  )}
                </Row>
              )
            })}
          </Col>

          {showPhonePlanDetailsField && (
            <Textarea
              aria-label={intl.formatMessage({
                id: 'NewOrderSummary_phone_plan_details',
              })}
              charactersCount={phonePlanDetails?.length || 0}
              className="p-0 mb-4"
              customComponent={TextareaAutosize}
              defaultValue={phonePlanDetails || ''}
              id="phonePlanDetails"
              maxLength={200}
              onChange={event =>
                onSetPhonePlanDetails(event.currentTarget.value)
              }
              placeholder={intl.formatMessage({
                id: 'NewOrderSummary_phone_plan_details',
              })}
              showCounter
            />
          )}
        </Row>

        <Row
          className={clsx({
            'pb-4': isEarlyUpgrade,
            'pb-6': !isEarlyUpgrade,
          })}
        >
          <Col>
            <Row className="price-container px-4 d-flex justify-content-between align-items-center">
              <Col className="ps-0" md="auto" xs={8}>
                <Heading as={5} size={4}>
                  <FormattedMessage id="NewOrderSummary_total" />
                </Heading>
              </Col>

              <Col className="text-end pe-0">
                <Heading as={5} className="mt-1" size={4}>
                  <FormattedCurrency value={totalPrice.totalWithoutTax} />
                </Heading>
              </Col>
            </Row>

            {portalConfig?.showCopaymentWithAndWithoutTax && (
              <Row className="price-container px-4 d-flex justify-content-between align-items-center">
                <Col className="ps-0" md="auto" xs={8}>
                  <Heading as={5} size={4}>
                    <FormattedMessage id="NewOrderSummary_total_gross" />
                  </Heading>
                </Col>

                <Col className="text-end pe-0">
                  <Heading as={5} className="mt-1" size={4}>
                    <FormattedCurrency value={totalPrice.totalWithTax} />
                  </Heading>
                </Col>
              </Row>
            )}

            {isOrganisation &&
              portalConfig?.showRentalBasisAmountAtOrderFlow &&
              !!totalPrice.totalBasis &&
              !chosenDevice?.forcePriceHide && (
                <Row className="price-container px-4 d-flex justify-content-between align-items-center">
                  <Col className="ps-0" md="auto" xs={8}>
                    <Heading as={5} size={4}>
                      <FormattedMessage id="NewOrderSummary_total_basis" />
                    </Heading>
                  </Col>

                  <Col className="text-end pe-0">
                    <Heading as={5} className="mt-1" size={4}>
                      <FormattedCurrency value={totalPrice.totalBasis} />
                    </Heading>
                  </Col>
                </Row>
              )}

            {!!chosenDevice?.calculatedNet && (
              <Row className="position-relative order-container green-container mt-4 px-4 d-flex justify-content-between align-items-center">
                <Col
                  className="d-flex align-items-center ps-0"
                  md="auto"
                  xs={8}
                >
                  <LinkButton
                    aria-label={intl.formatMessage({
                      id: 'Edit',
                    })}
                    className="text-secondary me-1 p-0"
                    onClick={onChangeDeviceButtonClick}
                  >
                    <Icon icon={faPenToSquare} size={14} />
                  </LinkButton>

                  <Heading as={5} size={4}>
                    &nbsp;
                    <FormattedMessage id="NewOrderSummary_net_total" />
                  </Heading>
                </Col>

                <Col className="d-flex align-items-center justify-content-end pe-0">
                  <div>
                    <Heading as={5} className="mt-1" size={4}>
                      ≈&nbsp;
                      <FormattedCurrency
                        value={totalPrice.totalCalculatedNet}
                      />
                    </Heading>
                  </div>
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        {isEarlyUpgrade && (
          <Row className="mt-2 pb-6">
            <Col>
              <Row className="blue-container p-4 d-flex justify-content-between">
                <Col className="ps-0" md="auto" xs={8}>
                  <Heading as={5} className="m-0 mb-2" size={4}>
                    <FormattedMessage id="Upgrade_Fee_ExclVAT" />
                  </Heading>

                  <p className="m-0">
                    <FormattedMessage id="Order_upgrade_fee_info" />
                  </p>
                </Col>

                <Col className="d-flex align-items-center justify-content-end pe-0">
                  <Heading as={5} className="mt-1" size={4}>
                    <FormattedCurrency value={earlyUpgradeFee || 0} />
                  </Heading>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {portalConfig?.externalIDInOrderSummary && (
          <Row className="pt-4">
            <Col className="ps-0">
              <Heading as={6} className="mb-2" size={5}>
                <FormattedMessage id="NewOrderSummary_external_id" />
              </Heading>
              <OrderSummaryTextField
                controlId="orderExternalId"
                defaultValue={orderExternalId || ''}
                onChangeLabel={event => onSetExternalId(event.target.value)}
                placeholderTranslationId="NewOrderSummary_external_id_placeholder"
              />
            </Col>
          </Row>
        )}

        <Row
          className={portalConfig?.externalIDInOrderSummary ? 'pt-2' : 'pt-4'}
        >
          <Col className="p-0">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <Heading as={6} size={5}>
                <FormattedMessage id="NewOrderSummary_shipping_address" />
              </Heading>

              <TertiaryButton
                aria-label={intl.formatMessage({ id: 'Edit_address' })}
                asIcon={faPenToSquare}
                onClick={onBackButtonClick}
              />
            </div>

            <Textarea
              aria-label={intl.formatMessage({
                id: 'NewOrderSummary_shipping_address',
              })}
              className="mb-6"
              cols={3}
              id="ShippingAddress"
              onChange={event => event.preventDefault()}
              value={`${chosenShippingAddress?.name}${
                chosenShippingAddress?.nameAddition
                  ? ` ${chosenShippingAddress.nameAddition}\n`
                  : '\n'
              }${
                chosenShippingAddress?.country?.value === countryISOCodes.US
                  ? chosenShippingAddress.usAddress
                  : `${chosenShippingAddress?.street} ${chosenShippingAddress?.streetNumber} ${chosenShippingAddress?.streetAddition}`.trim()
              }\n${chosenShippingAddress?.zipcode} ${
                chosenShippingAddress?.country?.value === countryISOCodes.US
                  ? `${chosenShippingAddress.city}, ${
                      chosenShippingAddress.state?.value ?? ''
                    }`.trim()
                  : chosenShippingAddress?.city
              } ${chosenShippingAddress?.country?.value ?? ''}`}
            />

            {terms.map(
              ({ show, name, ...rest }) =>
                show && (
                  <Checkbox
                    key={name}
                    className="text-linebreak mt-6"
                    id={name}
                    name={name}
                    onChange={() =>
                      setTermsAcceptance(termsAcceptance => ({
                        ...termsAcceptance,
                        [name]:
                          !termsAcceptance[name as keyof OrderSummaryTerms],
                      }))
                    }
                    {...rest}
                  />
                ),
            )}
          </Col>
        </Row>
      </Container>

      <OrderStickyFooter
        handlePreviousAction={onBackButtonClick}
        renderNextButtonElement={() => (
          <PrimaryButton
            aria-label={intl.formatMessage({
              id: 'NewOrderSummary_order',
            })}
            className="ms-1"
            data-e2e={E2E_NEW_ORDER_SUBMIT}
            disabled={
              atLeastOneTermIsNotAccepted ||
              isTermsListLoading ||
              isLoading ||
              !!termsError
            }
            id="order"
            onClick={onOrderButtonClick}
          >
            {isLoading ? (
              <Icon icon={faSpinner} spin />
            ) : (
              <FormattedMessage id="NewOrderSummary_order" />
            )}
          </PrimaryButton>
        )}
      />
    </>
  )
})
