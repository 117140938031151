import { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'

import { ButtonGroup, CardBody } from 'Components/_theme'
import { ShippingAddressForm } from 'Components/ShippingAddressForm/ShippingAddressForm'
import { ShippingAddressFormSchema } from 'Components/ShippingAddressForm/ShippingAddressForm.types'

import { ModalShippingFormProps } from './ModalShippingForm.types'

export const ModalShippingForm = (props: ModalShippingFormProps) => {
  const {
    formId,
    rental,
    shippingFormData,
    leftButtonE2ESelector,
    rightButtonE2ESelector,
    onPreviousStep,
    onSubmit,
  } = props
  const [isSubmitting, setIsSubmitting] = useState(false)
  const intl = useIntl()

  const handlePrev = useCallback(() => {
    onPreviousStep()
  }, [onPreviousStep])

  const handleSubmit = useCallback(
    (data: ShippingAddressFormSchema) => {
      onSubmit(data)
    },
    [onSubmit],
  )

  return (
    <CardBody>
      <ShippingAddressForm
        defaultValues={shippingFormData}
        formId={formId}
        onSetIsSubmitting={setIsSubmitting}
        onSubmit={handleSubmit}
        rental={rental}
        showOptionsHeader
        useModalLayout
      />

      <ButtonGroup
        leftButton={{
          'data-e2e': leftButtonE2ESelector,
          onClick: handlePrev,
          text: intl.formatMessage({ id: 'Back' }),
        }}
        rightButton={{
          'data-e2e': rightButtonE2ESelector,
          disabled: isSubmitting,
          form: formId,
          text: intl.formatMessage({ id: 'Next' }),
          type: 'submit',
        }}
      />
    </CardBody>
  )
}
