import {
  IconDefinition,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core'

export const iconRepairDevice: IconDefinition = {
  icon: [
    32,
    32,
    [],
    'e012',
    'M9.287 0.059 C 8.801 0.116,7.945 0.403,7.544 0.645 C 6.494 1.279,5.781 2.216,5.479 3.360 L 5.360 3.813 5.360 16.000 L 5.360 28.187 5.479 28.640 C 5.785 29.801,6.557 30.801,7.600 31.387 C 8.056 31.643,8.807 31.881,9.362 31.944 C 10.011 32.019,21.989 32.019,22.640 31.944 C 23.204 31.880,24.028 31.612,24.456 31.355 C 25.471 30.742,26.138 29.896,26.490 28.771 L 26.640 28.293 26.640 16.000 L 26.640 3.707 26.490 3.229 C 26.133 2.088,25.406 1.178,24.400 0.613 C 23.944 0.357,23.193 0.119,22.638 0.056 C 22.053 -0.011,9.866 -0.008,9.287 0.059 M24.000 15.973 L 24.000 27.627 16.000 27.627 L 8.000 27.627 8.000 15.973 L 8.000 4.320 16.000 4.320 L 24.000 4.320 24.000 15.973 M13.227 10.109 C 13.036 10.150,12.715 10.247,12.514 10.325 L 12.148 10.467 13.527 11.847 L 14.906 13.227 14.066 14.066 L 13.227 14.906 11.847 13.527 L 10.468 12.148 10.305 12.598 C 10.011 13.410,9.952 14.099,10.112 14.869 C 10.416 16.344,11.546 17.541,12.998 17.927 C 13.564 18.078,14.434 18.087,15.002 17.948 L 15.418 17.846 17.491 19.917 L 19.565 21.988 19.816 21.960 C 20.060 21.932,20.099 21.901,21.000 21.000 C 21.901 20.099,21.932 20.060,21.960 19.816 L 21.988 19.565 19.917 17.491 L 17.846 15.418 17.948 15.002 C 18.093 14.408,18.084 13.550,17.927 12.990 C 17.595 11.808,16.686 10.768,15.597 10.325 C 14.894 10.040,13.953 9.954,13.227 10.109',
  ],
  iconName: 'repairDevice' as IconName,
  prefix: 'fac' as IconPrefix,
}
