import { Col, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { A11y, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Card, CardBody, Heading } from 'Components/_theme'

import { SLIDER_BREAKPOINTS_4 } from 'Constants/constants'

import { DashboardBenefitSlide } from '../DashboardBenefitSlide/BenefitSlide'
import { DashboardBenefitsCardProps } from './DashboardBenefitsCard.types'

export const DashboardBenefitsCard = (props: DashboardBenefitsCardProps) => {
  const { slideProps } = props

  return (
    <Card className="mb-6">
      <div className="overflow-hidden">
        <CardBody>
          <Row>
            <Col className="dashboard-card-title">
              <Heading size={5}>
                <FormattedMessage id="Dashboard_the_benefits" />
              </Heading>
            </Col>
          </Row>
          <Row className="mt-2 pb-6">
            <Col>
              <Swiper
                breakpoints={SLIDER_BREAKPOINTS_4}
                className="mt-2"
                modules={[Navigation, Pagination, A11y]}
                navigation
                pagination={{ clickable: true }}
                spaceBetween={10}
              >
                {slideProps.map(slide => (
                  <SwiperSlide key={slide.id}>
                    <DashboardBenefitSlide {...slide} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Col>
          </Row>
        </CardBody>
      </div>
    </Card>
  )
}
