import { GroupBase } from 'react-select'
import { observer } from 'mobx-react'

import { Field } from 'Components/Field/Field'
import { FieldTypeEnum } from 'Components/Field/Field.types'
import { SelectOption } from 'Components/Select/Select.types'

import { SHIPPING_PROVIDERS } from 'Constants/ShippingProviders'

import { CarrierSelectProps } from './CarrierSelect.types'

export const CarrierSelect = observer(
  <
    Option extends SelectOption,
    IsMulti extends boolean,
    Group extends GroupBase<Option> = GroupBase<Option>,
  >(
    props: CarrierSelectProps<Option, IsMulti, Group>,
  ) => {
    const carrierOptions = Object.values(SHIPPING_PROVIDERS).map(provider => ({
      label: provider,
      value: provider,
    }))

    return (
      <Field
        {...props}
        selectProps={{
          isClearable: true,
          options: carrierOptions,
        }}
        type={FieldTypeEnum.select}
      />
    )
  },
)
