export type MainNavigationConfig = {
  type: NavigationConfigTypeEnum
  url: string
  id: string
}

export enum NavigationConfigTypeEnum {
  Organisation,
  Employee,
}
