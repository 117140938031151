import { AppEnvEnum } from 'Portal/src/types/env.types'

import { useActiveUser } from '../useActiveUser/useActiveUser'

export const useShowInstantsearchList = () => {
  const { activeOrganisation } = useActiveUser()

  const enableListFiltering =
    localStorage.getItem('enable_list_filtering') === 'true'
  const isDev =
    window.APP_ENV === AppEnvEnum.GCPDEV || window.APP_ENV === AppEnvEnum.DEV

  if (!enableListFiltering && isDev) {
    return false
  }

  if (activeOrganisation !== null) {
    return true
  }

  return false
}
