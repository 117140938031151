import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  faCircleUser,
  faEnvelope,
  faLaptopMobile,
} from '@fortawesome/pro-solid-svg-icons'
import { observer } from 'mobx-react'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { MobileNavigationLink } from 'Components/_theme/MobileNavigationLink/MobileNavigationLink'

import { useStore } from 'Stores/index'

import { PortalSectionEnum } from 'Constants/portalSection'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import { MOBILE_NAVIGATION_BAR_LABELS } from './MobileNavigationBar.constants'

export const MobileNavigationBar = observer(() => {
  const { employeeStore, portalSettingsStore } = useStore()
  const { employee } = employeeStore
  const { currentPortalSection } = portalSettingsStore

  const { employeeId: employeeIdFromParams } = useParams<{
    employeeId: string
  }>()

  const employeeId = employeeIdFromParams || employee?.id

  const mobileNavigationBarItems = useMemo(
    () => [
      {
        icon: faLaptopMobile,
        isActive:
          currentPortalSection !== PortalSectionEnum.Contact &&
          currentPortalSection !== PortalSectionEnum.Account,
        label: MOBILE_NAVIGATION_BAR_LABELS[0],
        path: composePath({
          params: { employeeId },
          path: GLOBAL_ROUTE_PATHS.employee.dashboard,
        }),
      },
      {
        icon: faEnvelope,
        isActive: currentPortalSection === PortalSectionEnum.Contact,
        label: MOBILE_NAVIGATION_BAR_LABELS[1],
        path: composePath({
          params: {},
          path: GLOBAL_ROUTE_PATHS.public.contact,
        }),
      },
      {
        icon: faCircleUser,
        isActive: currentPortalSection === PortalSectionEnum.Account,
        label: MOBILE_NAVIGATION_BAR_LABELS[2],
        path: GLOBAL_ROUTE_PATHS.private.account,
      },
    ],
    [currentPortalSection, employeeId],
  )

  return (
    <div className="MobileNavigationBar" role="navigation">
      {mobileNavigationBarItems.map(item => (
        <MobileNavigationLink
          key={item.label}
          icon={item.icon}
          isActive={item.isActive}
          label={item.label}
          path={item.path}
        />
      ))}
    </div>
  )
})
