import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { GroupBase } from 'react-select'

import { SelectOption } from 'Components/Select/Select.types'

import { SelectMultiValueLabelProps } from './SelectMultiValueLabel.types'

export const SelectMultiValueLabel = <
  Option extends SelectOption,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: SelectMultiValueLabelProps<Option, IsMulti, Group>,
) => {
  const { children, innerProps } = props

  return (
    <OverlayTrigger
      overlay={<Tooltip id="tooltip-MultiValueLabel">{children}</Tooltip>}
      placement="top"
      trigger={['hover', 'focus']}
    >
      <div className={innerProps.className}>{children}</div>
    </OverlayTrigger>
  )
}
