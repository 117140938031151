import { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { clsx } from 'clsx'

import { Heading } from 'Components/_theme'

import { E2E_HEADING_SECTION_KEYS } from 'Constants/e2e'
import { PORTAL_HEADING_TRANSLATION_IDS } from 'Constants/portalSection'

import { setRouteTitle } from 'Portal/src/router/Router.utils'

import { PageHeaderProps } from './PageHeader.types'

export const PageHeader = (props: PageHeaderProps) => {
  const intl = useIntl()

  const { className = 'mb-4 mb-lg-6', portalSection, subheading } = props

  useEffect(() => {
    setRouteTitle(
      intl.formatMessage({
        id: PORTAL_HEADING_TRANSLATION_IDS[portalSection],
      }),
    )
  }, [intl, portalSection])

  return (
    <Heading
      as={4}
      className={clsx(className)}
      data-e2e={E2E_HEADING_SECTION_KEYS[portalSection]}
      size={1}
    >
      <FormattedMessage id={PORTAL_HEADING_TRANSLATION_IDS[portalSection]} />

      {subheading && <>&nbsp;-&nbsp;{subheading}</>}
    </Heading>
  )
}
