import { FormProvider, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { zodResolver } from '@hookform/resolvers/zod'
import { observer } from 'mobx-react'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { ButtonGroup, Card, CardBody, CardHeader } from 'Components/_theme'
import { Form } from 'Components/Form/Form'
import { FormOptionalMessage } from 'Components/FormOptionalMessage/FormOptionalMessage'
import { ReturnDepotsSelect } from 'Components/ReturnDepotsSelect/ReturnDepotsSelect'

import { useActiveUser, useIsOrganisation } from 'Hooks'

import { getSelectValue } from 'Utilities'

import { E2E_RETURN_DEVICE_MODAL_SUBMIT } from 'Constants/e2e'
import { EMPTY_SELECT_OPTION } from 'Constants/form'
import {
  mutationReturnDevice,
  mutationReturnDeviceVariables,
} from 'Constants/graphql/mutations/__generated__/mutationReturnDevice'
import { MUTATION_RETURN_DEVICE } from 'Constants/graphql/mutations/MutationReturnDevice'

import { iconReturnDevice } from 'Icons/iconReturnDevice'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import { returnDeviceModalFormSchema } from './ReturnDeviceModal.schema'
import {
  ReturnDeviceModalFormSchema,
  ReturnDeviceModalProps,
} from './ReturnDeviceModal.types'

export const ReturnDeviceModal = observer((props: ReturnDeviceModalProps) => {
  const { handleSubmit, onClose, rental } = props

  const intl = useIntl()
  const history = useHistory()
  const isOrganisation = useIsOrganisation()
  const { activeEmployee, activeOrganisation } = useActiveUser()

  const formMethods = useForm<ReturnDeviceModalFormSchema>({
    defaultValues: {
      depot: EMPTY_SELECT_OPTION,
      message: '',
    },
    resolver: zodResolver(returnDeviceModalFormSchema),
  })

  const [createReturnDeviceCaseItem, { loading, error }] = useMutation<
    mutationReturnDevice,
    mutationReturnDeviceVariables
  >(MUTATION_RETURN_DEVICE, {
    onCompleted: data => {
      handleSubmit()

      const path = composePath({
        isOrganisation,
        params: {
          employeeId: activeEmployee?.id,
          orderId: data.returnDevice.id,
          organisationId: activeOrganisation?.id,
        },
        paths: {
          employee: GLOBAL_ROUTE_PATHS.employee.order,
          organisation: GLOBAL_ROUTE_PATHS.organisation.order,
        },
      })

      history.replace(path)
    },
  })

  const handleFormSubmit = formMethods.handleSubmit(data => {
    createReturnDeviceCaseItem({
      variables: {
        input: {
          message: data.message,
          rentalID: rental.id,
          returnDepotID: getSelectValue(data.depot) || null,
        },
      },
    })
  })

  return (
    <Card>
      <CardHeader icon={iconReturnDevice}>
        <FormattedMessage id="Return_device" />
      </CardHeader>

      <CardBody>
        <FormProvider {...formMethods}>
          <Form id="RETURN_DEVICE_MODAL_FORM" onSubmit={handleFormSubmit}>
            <div className="h6 mb-4">
              <FormattedMessage id="Device" />
            </div>

            <div className="mb-4">
              {rental.stocklistItem?.deviceDefinition?.name}{' '}
              {rental.stocklistItem?.imei ?? ''}
            </div>

            <ReturnDepotsSelect
              containerClassName="mb-4"
              label="Depot"
              markAsRequired
              name="depot"
              shouldTranslateLabel
            />

            <FormOptionalMessage />

            <ButtonGroup
              leftButton={{
                onClick: onClose,
                text: intl.formatMessage({ id: 'Cancel' }),
              }}
              rightButton={{
                'data-e2e': E2E_RETURN_DEVICE_MODAL_SUBMIT,
                disabled: !!error,
                isLoading: loading,
                text: intl.formatMessage({
                  id: 'Return',
                }),
                type: 'submit',
              }}
            />
          </Form>
        </FormProvider>
      </CardBody>
    </Card>
  )
})
