import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { clsx } from 'clsx'

import { Icon } from 'Components/Icon/Icon'

import { E2E_LINK_SECTION_KEYS } from 'Constants/e2e'

import { NavigationButtonProps } from './NavigationButton.types'

export const NavigationButton = (props: NavigationButtonProps) => {
  const { isActive, isCompact, item, toggleMenuVisibility } = props

  const intl = useIntl()

  const classNames = clsx(
    'NavigationButton',
    isActive && 'NavigationButton-is-active',
    isCompact && 'NavigationButton-is-compact',
  )

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    toggleMenuVisibility()

    if (item.onClick) {
      e.preventDefault()

      item.onClick()
    }
  }

  return (
    <Link
      className={classNames}
      data-e2e={E2E_LINK_SECTION_KEYS[item.key]}
      onClick={handleLinkClick}
      title={intl.formatMessage({ id: item.name })}
      to={item.path}
    >
      <Icon className="NavigationButton-icon" icon={item.icon} size={24} />

      {!isCompact && (
        <>
          <span className="NavigationButton-label">
            <FormattedMessage id={item.name} />
          </span>

          <Icon
            className="NavigationButton-chevron"
            icon={faChevronRight}
            size={13}
          />
        </>
      )}
    </Link>
  )
}
