import ReactGA from 'react-ga'

import { GA_TRACKING_ID } from 'Portal/src/config/config'

export const setGtagPage = (page: string) => {
  ReactGA.set({
    page,
  }) // Update the user's current page
  ReactGA.pageview(page) // Record a pageview for the given page
}

export const loadGtagScript = () => {
  ReactGA.initialize(GA_TRACKING_ID)
  // Track's the initial page visit.
  setGtagPage(window.location.hash)
}
