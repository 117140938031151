import { gql } from '@apollo/client'

export const GET_PORTAL_CONFIG = gql`
  query getPortalConfig($organisationID: ID!, $employeeID: ID) {
    portalConfigStoreForUserAndOrg(
      organisationID: $organisationID
      employeeId: $employeeID
    ) {
      customDeliveryAddress
      isEmployeeLeavesFirmButtonEnabled
      assignDevice
      cancelDevice
      cancelPhonePlan
      changePhonePlan
      deviceExchange
      deviceLost
      earlyUpgradeDevice
      newDevice
      createEmployee
      newPhonePlan
      rentalCompleteUpgradeDevice
      repairDevice
      returnOfDevice
      upgradeDevice
      showInvoices
      editEmployee
      enableDeviceBrandAccessoriesBundling
      shipDeviceToCustomAddress
      shipToOfficeAddress
      enableAccessibilityWidget
      isCustomDeliveryAddressForNewJoinersDisabled
      showNewDeviceButtonToAdmin
      editEmployee
      enableCustomerLabels
      groupDevicesByRentCategory
      impactTracker
      customTheme
      showCopaymentWithAndWithoutTax
      showRentalBasisAmountAtOrderFlow
      enableProductOffers
      enableNewAdminDashboard
      customClientReferenceInOrderSummary
      poRcnInOrderSummary
      billingIdentifierInOrderSummary
      isRentalProlongingEnabled
      isDeviceSelectionForLostDeviceDisabled
      externalIDInOrderSummary
    }
  }
`
