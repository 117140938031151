export const CREATE_RETURN_LABEL_MODAL_FORM = 'CREATE_RETURN_LABEL_MODAL_FORM'

export const fallbackCountryUrlHash: Record<string, string> = {
  AT: 'c1b635bfed9d93c7dc14596a1364aed15278f10c77d51850b3b575bdd3cce493',
  BE: '0ae27751b80b692b10f2b6bb918beb80b48b18d13084966ef4f5a3ab1dd36fd3',
  BG: '785ba02374a766a30a30b9d4323023858df3ff701a91ef20c217e204d2ff6f6c',
  CH: '3a57864d289cffe94fcab91d66778e12650c92db5486483991c49f76dcb5b379',
  CY: '7e1a6a800459281cd3794dc8f1d9280055d23e9fda76784b3bda631f3aa88812',
  CZ: '5aa3a964fc9a2cf8f233e3ef817e696b0b22e57c329333b234ce7c0cddf9c782',
  DE: '58438ad5d3ef96d5f3d40e01a7c793bc5d1b464a4e1f1bb9fb1cf84ac907e088',
  DK: '173b2827922a8cb641fe32c93c6316faa54dda40bc8997ca2f7396ccae0ee662',
  EE: 'd971661e820e2650044080a185eddb82e375931f583325698627e1fce63a615a',
  ES: '68b20a85ec691ae1960bb70df2b5b28bf3f6d7dfc154ed2fb6f936375b0440fc',
  FI: '2cb760f3e569503eb64bea3cff42100eac00e9412ae96731c074682060360ef9',
  FR: 'f695614ecfea78f82671a08a136e2cfe19e075f7397bfaff2b05370591febe1a',
  GB: '751203d483d71bcbbc355148e56f4f81e29be40e97246b75c0617c675361a0c2',
  GR: '8d555f5b612f86cf1b298139f54acf0f6815addcff3688c065cda1b49cb87ad0',
  HR: '89395817aeb4b1af540f8e7c4fb3a2a7b08292715a612c710c914eff8fbda2ba',
  HU: '9fd00f8ff819c66fe627f91ccf33603b8ec54d56aef2892fe05defd8bb56d562',
  IE: 'e1ab9b8d1384b18ab7dfb0f8a0860483b0fa909a6b973967511f6a2958aa4278',
  IT: '9802393acf2312169e2203a0f840cafe421172edbab84b1fcd226523cbcc54e5',
  LT: '98dc178007cf0ded0e8177bfd47c87dec28a424f15bc9daffa4023ef8c561442',
  LU: 'f80769192973362190308a8ace743fa38ba7c2c486ff648853fd1e0d204578c6',
  LV: '36c11d9fa023f6d2ad6c1b59c3f85e86a3bcda5f9750332577719d99571e16b3',
  MT: 'f226a53fd2dc15de430a791863af9f2a378dcbb3ad0e14d61dae8700902d56bc',
  NL: '110c53438eb781b732edddd70ce57cc7ff7a4d30ee9bae9940109442993b5241',
  PL: 'eaffdf396871e73c7665b5a254e798400355e61cad6c459d5193aea26f0e26a9',
  PT: '19671e49a2b5c28bd1093fcdbd795d6d1eb0a5a0f96003213441117426f9ea86',
  RO: '1578a708336810471496f3c5fe2024e04018173a3a868894d72da6ab88003f7c',
  SE: '00bbf09c12560f5b373b6d1752dd773153b8d0f9f76f975e6435835d162c1572',
  SI: '8725c151e54638d5fab3147cf4820477a8b399e5266af0fa9ccbf76a66451e83',
  SK: 'e7712001be3e8da9937e5c87c5e2536bfaf1b1b320ae671e6baa2fdf4bfd66d8',
}

export const pickUpRequestFormUrl =
  'https://docs.google.com/forms/d/e/1FAIpQLSe4YuyLKhm7D41PdISqGhdoqYzjYj3NoZqTtlQqM-Cv3tfIfw/viewform'
