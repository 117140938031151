import { EMPTY_SELECT_OPTION } from 'Constants/form'

import { ShippingAddressTypeEnum } from 'Types/address/address.types'

import { ShippingAddressFormSchema } from './ShippingAddressForm.types'

export const shippingFormDefaultValues: ShippingAddressFormSchema = {
  _label: '',
  _vendingMachineId: '0',
  addressType: ShippingAddressTypeEnum.CUSTOM_ADDRESS,
  city: '',
  country: EMPTY_SELECT_OPTION,
  name: '',
  nameAddition: '',
  state: EMPTY_SELECT_OPTION,
  street: '',
  streetAddition: '',
  streetNumber: '',
  usAddress: '',
  zipcode: '',
}
