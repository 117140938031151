import { useMemo } from 'react'
import { Line } from 'react-chartjs-2'
import { useIntl } from 'react-intl'

import { CHART_FONT } from 'Constants/charts'

import {
  STATISTIC_CARD_CHART_BACKGROUND_COLOR,
  STATISTIC_CARD_CHART_HEIGHT,
  STATISTIC_CARD_CHART_TICK_COLOR,
} from './StatisticCardChart.constants'
import { StatisticCardChartProps } from './StatisticCardChart.types'
import { getLineColor } from './StatisticCardChart.util'

export const StatisticCardChart = (props: StatisticCardChartProps) => {
  const { data, label, labels, progress, title } = props

  const intl = useIntl()

  const chartConfig = {
    data: {
      datasets: [
        {
          backgroundColor: STATISTIC_CARD_CHART_BACKGROUND_COLOR,
          borderColor: getLineColor(progress),
          data,
          fill: true,
          label: intl.formatMessage({ id: label }),
          pointBackgroundColor: getLineColor(progress),
        },
      ],
      labels,
    },
    height: STATISTIC_CARD_CHART_HEIGHT,
    options: {
      elements: {
        line: {
          tension: 0.5,
        },
      },
      maintainAspectRatio: true,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          bodyFont: CHART_FONT,
          displayColors: false,
          titleFont: CHART_FONT,
        },
      },
      responsive: true,
      scales: {
        x: {
          border: {
            dash: [2, 4],
            display: false,
          },
          grid: {
            drawTicks: false,
          },
          ticks: {
            color: STATISTIC_CARD_CHART_TICK_COLOR,
            font: CHART_FONT,
          },
        },
        y: {
          display: false,
          max: Math.max(...data) + 20,
          min: Math.min(...data) - 20,
        },
      },
    },
  }

  const ariaLabel = useMemo(
    () =>
      `${intl.formatMessage({ id: title })}:${labels.map(
        (item, index) =>
          ` ${item} ${intl.formatMessage({ id: label })} ${data[index]}`,
      )}`,
    [data, intl, label, labels, title],
  )

  return <Line {...chartConfig} aria-label={ariaLabel} />
}
