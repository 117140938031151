import { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useMountEffect } from '@react-hookz/web'

import { CardBody, PrimaryButton } from 'Components/_theme'

import {
  LostOrStolenModalStepsEnum,
  LostOrStolenModalTypesEnum,
} from 'Modals/LostOrStolenModal/LostOrStolenModal.types'

import {
  E2E_LOST_STOLEN_REPLACEMENT_DEPOT,
  E2E_LOST_STOLEN_REPLACEMENT_EVERPHONE,
  E2E_LOST_STOLEN_REPLACEMENT_NONE,
} from 'Constants/e2e'

import { ReplacementType } from 'Portal/__generated__/globalTypes'

import { LostOrStolenModalLandingProps } from './LostOrStolenModalLanding.types'

export const LostOrStolenModalLanding = (
  props: LostOrStolenModalLandingProps,
) => {
  const {
    type,
    isDeviceSelectionForLostDeviceEnabled,
    isNoReplacementEnabled,
    isReplacementDeviceAvailableInDepot,
    onSetCurrentStep,
    onSetSelectionType,
    onSetShippingFormData,
    onOrderLostReplacementRedirect,
  } = props

  const handleGoToReplacementForm = useCallback(() => {
    if (!isReplacementDeviceAvailableInDepot) {
      return
    }

    onSetSelectionType(ReplacementType.depot)
    onSetCurrentStep(LostOrStolenModalStepsEnum.REPLACEMENT_FORM)
  }, [
    isReplacementDeviceAvailableInDepot,
    onSetCurrentStep,
    onSetSelectionType,
  ])

  const handleGoToShippingForm = useCallback(() => {
    onSetSelectionType(ReplacementType.everphone)
    onSetCurrentStep(LostOrStolenModalStepsEnum.SHIPPING_FORM)
  }, [onSetCurrentStep, onSetSelectionType])

  const handleRedirectToLostReplacementOrder = () => {
    onOrderLostReplacementRedirect()
  }

  const handleGoToSummary = useCallback(() => {
    onSetSelectionType(ReplacementType.none)
    onSetCurrentStep(LostOrStolenModalStepsEnum.SUMMARY)
  }, [onSetCurrentStep, onSetSelectionType])

  useMountEffect(() => {
    onSetShippingFormData(null)
  })

  return (
    <CardBody>
      {type === LostOrStolenModalTypesEnum.ORGANISATION_WITH_DEPOT && (
        <>
          <PrimaryButton
            className="w-100"
            data-e2e={E2E_LOST_STOLEN_REPLACEMENT_DEPOT}
            disabled={!isReplacementDeviceAvailableInDepot}
            onClick={handleGoToReplacementForm}
          >
            <FormattedMessage id="LostOrStolenModal_orderDepot" />
          </PrimaryButton>

          {!isReplacementDeviceAvailableInDepot && (
            <small className="d-block mt-1">
              <FormattedMessage id="LostOrStolenModal_noReplacementFound" />
            </small>
          )}
        </>
      )}

      <PrimaryButton
        className="w-100 mt-4"
        data-e2e={E2E_LOST_STOLEN_REPLACEMENT_EVERPHONE}
        onClick={
          isDeviceSelectionForLostDeviceEnabled
            ? handleRedirectToLostReplacementOrder
            : handleGoToShippingForm
        }
      >
        <FormattedMessage id="LostOrStolenModal_orderEverphone" />
        {isDeviceSelectionForLostDeviceEnabled ? '' : ' *'}
      </PrimaryButton>

      {isNoReplacementEnabled && (
        <PrimaryButton
          className="w-100 mt-4"
          data-e2e={E2E_LOST_STOLEN_REPLACEMENT_NONE}
          onClick={handleGoToSummary}
        >
          <FormattedMessage id="LostOrStolenModal_noReplacement" />
        </PrimaryButton>
      )}

      {!isDeviceSelectionForLostDeviceEnabled && (
        <small className="d-block mt-4">
          <FormattedMessage id="LostOrStolenModal_replacementModelNote" />
        </small>
      )}
    </CardBody>
  )
}
