import { pick } from 'lodash-es'

import { useStore } from 'Stores/index'

import { CountriesDE } from 'Constants/CountriesDE'
import { CountriesEN } from 'Constants/CountriesEN'
import { CountriesFR } from 'Constants/CountriesFR'
import { SupportedLanguagesEnum } from 'Constants/locale'

const getCountryList = (countries: { [k: string]: string }) =>
  Object.fromEntries(
    Object.entries(
      pick(
        countries,
        'AT',
        'BE',
        'BG',
        'HR',
        'CY',
        'CZ',
        'DK',
        'EE',
        'FI',
        'FR',
        'DE',
        'GR',
        'HU',
        'IE',
        'IT',
        'LV',
        'LT',
        'LU',
        'MT',
        'NL',
        'NO',
        'PL',
        'PT',
        'RO',
        'SK',
        'SI',
        'ES',
        'SE',
        'CH',
        'GB',
      ),
    ),
  )

/**
 * This hook is used to get country list for DHL shipping label modal.
 * Please wrap component with the `observer` method from 'react-mobx' for the hook to re-sync data properly.
 */
export const useShippingLabelCountryList = () => {
  const { portalSettingsStore } = useStore()
  const {
    i18n: { language: activeLanguage },
  } = portalSettingsStore

  switch (activeLanguage) {
    case SupportedLanguagesEnum.DE:
      return getCountryList(CountriesDE)
    case SupportedLanguagesEnum.FR:
      return getCountryList(CountriesFR)
    default:
      return getCountryList(CountriesEN)
  }
}
