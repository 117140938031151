import { gql } from '@apollo/client'

export const MUTATION_LEGACY_UPGRADE_DEVICE = gql`
  mutation mutationLegacyUpgradeDevice(
    $input: LegacyUpgradeDeviceCaseItemInput!
  ) {
    legacyUpgradeDevice(input: $input) {
      id
    }
  }
`
