import { IntlShape } from 'react-intl'

import { FormatDate } from 'Components/FormatDate/FormatDate'
import { FormattedCurrency } from 'Components/FormattedCurrency/FormattedCurrency'
import { RentalStatusBadge } from 'Components/RentalStatusBadge/RentalStatusBadge'
import { RentalStatus } from 'Components/RentalStatusBadge/RentalStatusBadge.types'

import { getRentalsPreviewColumn_rental } from 'Constants/graphql/queries/detail/__generated__/getRentalsPreviewColumn'
import { getEmployee_employee } from 'Constants/graphql/queries/employee/__generated__/getEmployee'

export const generateRentalsPreviewColumnContent = (
  intl: IntlShape,
  isOrganisation: boolean,
  rental?: getRentalsPreviewColumn_rental,
) => [
  {
    copyable: true,
    label: 'PreviewColumn_imei_1',
    value: rental?.stocklistItem?.imei,
  },
  {
    copyable: true,
    label: 'PreviewColumn_imei_2',
    value: rental?.stocklistItem?.imei2,
  },
  {
    copyable: true,
    label: 'PreviewColumn_serial',
    value: rental?.stocklistItem?.serialNumber,
  },
  {
    copyable: true,
    label: 'PreviewColumn_eid',
    value: rental?.stocklistItem?.eid,
  },
  ...(isOrganisation && rental?.amount && !rental?.forcePriceHide
    ? [
        {
          label: 'PreviewColumn_monthly_amount',
          value: <FormattedCurrency value={rental.amount} />,
        },
      ]
    : []),
  {
    label: 'PreviewColumn_copayment_amount',
    value: <FormattedCurrency value={rental?.coPaymentAmount ?? 0} />,
  },
  {
    label: 'PreviewColumn_start',
    value: <FormatDate value={rental?.startAt} />,
  },
  {
    label: 'PreviewColumn_min_term',
    value: rental?.minimumContractDuration
      ? intl.formatMessage(
          {
            id: 'PreviewColumn_months',
          },
          { total: rental?.minimumContractDuration },
        )
      : '',
  },
  {
    label: 'PreviewColumn_upgrade_date',
    value: <FormatDate value={rental?.newDeviceDate} />,
  },
]

export const generateRentalBadge = (
  employee: getEmployee_employee | null,
  rental?: getRentalsPreviewColumn_rental,
) => {
  const canOrderNewDevice = employee?.canOrderNewDevice || true

  const canUpgradeDevice = employee?.canUpgradeDevice || true

  if (
    !rental?.rentalProlonging?.validFrom &&
    rental?.isUpgradeEligible &&
    !rental?.isUpgradePending &&
    canOrderNewDevice
  ) {
    return <RentalStatusBadge status={RentalStatus.new_device} />
  }

  if (
    rental?.isEarlyUpgradeEligible &&
    !rental?.isUpgradePending &&
    canUpgradeDevice
  ) {
    return <RentalStatusBadge status={RentalStatus.upgrade} />
  }

  return null
}
