import { useMemo } from 'react'
import { useClearRefinements } from 'react-instantsearch'
import { useIntl } from 'react-intl'
import { CurrentRefinementsConnectorParamsItem } from 'instantsearch.js/es/connectors/current-refinements/connectCurrentRefinements'

import { FilterBadge } from 'Components/FilterBadge/FilterBadge'

import { getTypesenseAttributeTranslationId } from 'Utilities'

import {
  E2E_LIST_ACTIVE_FILTER_BADGE,
  E2E_LIST_ACTIVE_FILTER_CLEAR_BUTTON,
} from 'Constants/e2e'

import {
  getSeparator,
  parseRefinement,
  parseReturnCaseStatusRefinements,
} from './ActiveFilters.utils'

export const ActiveFiltersItem = (
  props: CurrentRefinementsConnectorParamsItem,
) => {
  const { attribute, refinements } = props

  const intl = useIntl()

  // FIXME: includedAttributes causes addional network requests
  const { refine } = useClearRefinements({
    includedAttributes: [attribute],
  })
  const separator = getSeparator(refinements)
  const translatedAttribute = intl.formatMessage({
    id: getTypesenseAttributeTranslationId(attribute),
  })

  const parsedRefinements = useMemo(() => {
    if (refinements[0].attribute === 'return_case.status') {
      return `"${parseReturnCaseStatusRefinements(refinements, intl)}"`
    }

    return `"${refinements
      .map(refinement => parseRefinement(refinement, intl))
      .join(separator)}"`
  }, [intl, refinements, separator])

  return (
    <FilterBadge
      e2eSelectors={{
        button: E2E_LIST_ACTIVE_FILTER_CLEAR_BUTTON,
        container: E2E_LIST_ACTIVE_FILTER_BADGE,
      }}
      onRemove={refine}
    >
      {translatedAttribute}: {parsedRefinements}
    </FilterBadge>
  )
}
