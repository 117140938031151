import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { observer } from 'mobx-react'

import { CardDetailsListItem } from 'Components/CardDetailsList/CardDetailsList.types'
import { FormatDate } from 'Components/FormatDate/FormatDate'
import { FormattedCurrency } from 'Components/FormattedCurrency/FormattedCurrency'
import { PageErrorState } from 'Components/PageErrorState/PageErrorState'
import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'
import { PresentationCard } from 'Components/PresentationCard/PresentationCard'
import { ScrollToTop } from 'Components/ScrollToTop/ScrollToTop'

import { useStore } from 'Stores/index'

import { useIsOrganisation } from 'Hooks'

import { DeviceTypeEnum } from 'Types/deviceTypes'

import { OrderStickyFooter } from '../../OrderStickyFooter/OrderStickyFooter'
import { OrderUpgradeFee } from '../../OrderUpgradeFee/OrderUpgradeFee'
import { DeviceRentalStepProps } from '../Steps.types'

export const DeviceRentalStep = observer((props: DeviceRentalStepProps) => {
  const { handleNextStep, isEarlyUpgrade } = props
  const { rentalStore } = useStore()
  const { rentalDetails, isLoadingRentalDetails, isRentalDetailsError } =
    rentalStore
  const isOrganisation = useIsOrganisation()

  useEffect(() => {
    if (
      rentalDetails?.rental.actions.showDeviceProlongingAction &&
      handleNextStep
    ) {
      handleNextStep()
    }
  }, [handleNextStep, rentalDetails?.rental.actions.showDeviceProlongingAction])

  if (isLoadingRentalDetails) {
    return <PageLoadingState />
  }

  if (isRentalDetailsError || !rentalDetails) {
    return <PageErrorState />
  }

  const { rental } = rentalDetails

  const generateListItems: CardDetailsListItem[] = [
    ...(isOrganisation && rental.amount
      ? [
          {
            label: 'RentalDetails_monthly_amount',
            value: <FormattedCurrency value={rental.amount} />,
          },
        ]
      : []),
    {
      label: 'RentalDetails_copayment',
      value: <FormattedCurrency value={rental.coPaymentAmount} />,
    },
    {
      label: 'RentalDetails_minimum_term',
      value: (
        <FormattedMessage
          id="RentalDetails_months"
          values={{
            total: rental.minimumContractDuration,
          }}
        />
      ),
    },
    {
      label: 'RentalDetails_start_of_rental',
      value: <FormatDate value={rental.startAt} />,
    },
    ...(!rental.rentalProlonging
      ? [
          {
            label: 'RentalDetails_select_a_new',
            value: <FormatDate value={rental.newDeviceDate} />,
          },
        ]
      : [
          {
            label: 'Prolongation_valid_till',
            value: <FormatDate value={rental.rentalProlonging?.validTill} />,
          },
        ]),
  ]

  return (
    <>
      <div className="py-6">
        <ScrollToTop />

        {!isEarlyUpgrade ? (
          <p className="mb-6">
            <FormattedMessage id="Order_Upgrade_Info" />
          </p>
        ) : (
          <p className="mb-6">
            <FormattedMessage id="Order_EarlyUpgrade_Info" />
          </p>
        )}

        <PresentationCard
          cardClassName="DeviceRentalStep"
          header={rental.stocklistItem?.deviceDefinition?.name ?? ''}
          imagePlaceholderType={
            rental.stocklistItem?.deviceDefinition
              ?.productType as DeviceTypeEnum
          }
          imageSource={rental.stocklistItem?.deviceDefinition?.imageURL}
          listItems={generateListItems}
        />

        {isEarlyUpgrade && (
          <OrderUpgradeFee className="mt-6" price={rental.upgradeAmount || 0} />
        )}
      </div>

      <OrderStickyFooter handleNextAction={handleNextStep} />
    </>
  )
})
