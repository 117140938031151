import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import { ParsedQuery } from 'query-string'

import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'

import { fetchFromPublicApiUsingPartialPath, getQueryObject } from 'Utilities'

import { setRouteTitle } from 'Portal/src/router/Router.utils'

import { OfferError } from '../OfferError/OfferError.page'
import { OfferParsedSearch, OfferResponse } from './Offer.types'

export const Offer = () => {
  const [isError, setIsError] = useState(false)
  const history = useHistory()
  const { search: locationSearch } = useLocation()
  const intl = useIntl()

  const parsedSearch: ParsedQuery<string> | null =
    getQueryObject(locationSearch)
  const { uuid, accept } = parsedSearch as OfferParsedSearch
  const accepted = accept === 'true'

  useEffect(() => {
    const fetchFromApi = async () => {
      try {
        const response =
          await fetchFromPublicApiUsingPartialPath<OfferResponse>(
            `/purchase-device/${uuid}/${accepted ? 'accept' : 'decline'}`,
            { method: 'POST' },
          )

        if (response.success && accepted) {
          history.replace('/offer-accepted')
        }

        if (response.success && !accepted) {
          history.replace('/offer-declined')
        }

        if (!response.success) {
          throw new Error(response.error)
        }
      } catch (error) {
        setIsError(true)
      }
    }

    fetchFromApi()
  }, [accepted, history, uuid])

  useEffect(() => {
    setRouteTitle(
      intl.formatMessage({
        id: isError ? 'OfferErrorContent_heading' : 'Loading',
      }),
    )
  }, [accepted, intl, isError])

  if (isError) {
    return <OfferError />
  }

  return <PageLoadingState />
}
