import { createContext, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  faCheckCircle,
  faCircleInfo,
  faDownload,
} from '@fortawesome/pro-solid-svg-icons'
import { DateTime } from 'luxon'

import { Alert, PrimaryButton } from 'Components/_theme'

import {
  E2E_TABLE_DOWNLOAD_REPORT,
  E2E_TABLE_DOWNLOAD_REPORT_INFO,
} from 'Constants/e2e'

import { ReportStatus } from 'Portal/__generated__/globalTypes'

import { useDownloadReport } from './DownloadReport.hooks'
import {
  DownloadReportAlertProps,
  DownloadReportButtonProps,
  DownloadReportProviderComponent,
  DownloadReportProviderProps,
  DownloadReportState,
} from './DownloadReport.types'

const DownloadReportContext = createContext<DownloadReportState | null>(null)

const DownloadReportProvider: DownloadReportProviderComponent = (
  props: DownloadReportProviderProps,
): JSX.Element => {
  const { reportType, reportFilter, hideDownloadReportComponents, children } =
    props

  const downloadReport = useDownloadReport(
    reportType,
    hideDownloadReportComponents,
    reportFilter,
  )

  return (
    <DownloadReportContext.Provider value={downloadReport}>
      {children}
    </DownloadReportContext.Provider>
  )
}

const Button = (props: DownloadReportButtonProps) => {
  const { className } = props
  const downloadReport = useContext(DownloadReportContext)

  if (
    !downloadReport ||
    !downloadReport.isDownloadEnabled ||
    downloadReport.hideDownloadReportComponents
  ) {
    return null
  }

  const isProcessing =
    downloadReport.reportData?.status === ReportStatus.process

  return (
    <PrimaryButton
      appearance="outline"
      className={className}
      data-e2e={E2E_TABLE_DOWNLOAD_REPORT}
      disabled={downloadReport.isLoading}
      iconLeft={!isProcessing ? faDownload : undefined}
      isLoading={isProcessing}
      onClick={downloadReport.onRequestReportClick}
    >
      <FormattedMessage
        id={
          isProcessing
            ? 'Download_report_preparing'
            : 'Download_report_request_report'
        }
      />
    </PrimaryButton>
  )
}

const ReportAlert = (props: DownloadReportAlertProps) => {
  const { additionalReadyMessage } = props

  const downloadReport = useContext(DownloadReportContext)

  if (!downloadReport || !downloadReport.isDownloadEnabled) {
    return null
  }

  if (
    downloadReport.hideDownloadReportComponents &&
    downloadReport.reportData?.status !== ReportStatus.done
  ) {
    return null
  }

  if (
    downloadReport.reportData?.status === ReportStatus.error ||
    downloadReport.reportError
  ) {
    return (
      <Alert
        className="mx-5 mb-2 mb-lg-0"
        data-e2e={E2E_TABLE_DOWNLOAD_REPORT_INFO}
        iconLeft={faDownload}
        variant="danger"
      >
        <FormattedMessage id="Download_report_error" />{' '}
        {downloadReport.reportError?.message}
      </Alert>
    )
  }

  if (downloadReport.reportData?.status === ReportStatus.process) {
    return (
      <Alert
        className="mx-5 mb-2 mb-lg-0"
        data-e2e={E2E_TABLE_DOWNLOAD_REPORT_INFO}
        iconLeft={faCircleInfo}
        variant="info"
      >
        <FormattedMessage id="Download_report_preparing_message" />
      </Alert>
    )
  }

  if (downloadReport.reportData?.status === ReportStatus.done) {
    return (
      <Alert
        buttonRight={{
          'aria-label': downloadReport.reportUrl,
          asExternalLink: true,
          children: (
            <FormattedMessage id="Download_report_report_ready_download" />
          ),
          disabled: !downloadReport.reportUrl,
          download: downloadReport.reportData.fileName,
          href: downloadReport.reportUrl,
          iconRight: downloadReport.reportUrl ? faDownload : undefined,
          isLoading: !downloadReport.reportUrl,
          size: 'small',
          variant: 'black',
        }}
        className="mx-5 mb-2 mb-lg-0"
        data-e2e={E2E_TABLE_DOWNLOAD_REPORT_INFO}
        iconLeft={faCheckCircle}
        variant="success"
      >
        <FormattedMessage
          id="Download_report_report_ready"
          values={{
            time: DateTime.fromSeconds(
              parseInt(`${downloadReport.reportData.generatedAt}`, 10),
            )
              .toLocal()
              .toFormat('yyyy-MM-dd HH:mm'),
          }}
        />{' '}
        {additionalReadyMessage && <>{additionalReadyMessage} </>}
      </Alert>
    )
  }

  return null
}

DownloadReportProvider.Button = Button
DownloadReportProvider.Alert = ReportAlert

export { DownloadReportProvider }
