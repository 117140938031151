import { Fragment, useCallback, useEffect, useMemo, useRef } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import {
  faChevronRight,
  faCircleChevronDown,
} from '@fortawesome/pro-solid-svg-icons'
import {
  Cell,
  CellContext,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  Header,
  useReactTable,
} from '@tanstack/react-table'
import { clsx } from 'clsx'
import { isEmpty } from 'lodash-es'

import { TABLE_ROW } from 'Components/_theme/Table/Table.constants'
import { Icon } from 'Components/Icon/Icon'
import { NoResults } from 'Components/NoResults/NoResults'
import { NoResultsTypeEnum } from 'Components/NoResults/NoResults.types'
import { SearchLoadingState } from 'Components/SearchLoadingState/SearchLoadingState'

import { E2E_TABLE_ROW } from 'Constants/e2e'

import { SortableHeading } from 'Algolia/components/SortableHeading/SortableHeading'

import { Heading } from '../Heading/Heading'
import { TableListStaticColumnsEnum, TableProps } from './Table.types'

export const Table = <
  T extends {
    id?: string
    url?: string
  },
>(
  props: TableProps<T>,
) => {
  const {
    className,
    columns,
    data,
    isSearching,
    isLoading,
    onSortingChange,
    hideArrow,
    sorting,
    e2eRowId,
    selectedRowIndex,
    customNoResultsMessage,
  } = props

  const rowsRef = useRef<Map<number, HTMLTableRowElement | null>>(new Map())
  const intl = useIntl()

  const createHeaderClassNames = useCallback((column: Header<T, unknown>) => {
    const baseClass = 'Table-cell Table-cell--head'

    if (column.id === TableListStaticColumnsEnum.DETAILS) {
      return clsx(baseClass, 'Table-cell--details')
    }

    if (column.id === TableListStaticColumnsEnum.ICON) {
      return clsx(baseClass, 'Table-cell--icon')
    }

    return baseClass
  }, [])

  const createCellClassNames = useCallback((cell: Cell<T, unknown>) => {
    const baseClass = 'Table-cell'

    if (cell.column.id === TableListStaticColumnsEnum.DETAILS) {
      return clsx(baseClass, 'Table-cell--details')
    }

    if (cell.column.id === TableListStaticColumnsEnum.ICON) {
      return clsx(baseClass, 'Table-cell--icon')
    }

    return baseClass
  }, [])

  const createDataE2E = useCallback(
    (cell: Cell<T, unknown>) => ({
      ...(cell.column.id === TableListStaticColumnsEnum.ICON && {
        'data-e2e': e2eRowId,
      }),
    }),
    [e2eRowId],
  )

  const handleExpandRow = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, clickedRowIndex: number) => {
      const { current: rows } = rowsRef

      if (!rows) {
        return
      }

      rows.forEach((row, rowIndex) => {
        if (rowIndex !== clickedRowIndex) {
          row?.classList.remove('Table-row--isExpanded')
        }
      })

      rows.get(clickedRowIndex)?.classList.toggle('Table-row--isExpanded')
    },
    [],
  )

  const renderDetailsButton = useCallback(
    ({ cell }: CellContext<T, unknown>) => (
      <button
        aria-label={intl.formatMessage({ id: 'Expand_content' })}
        className="Table-cell--details-button"
        onClick={event => handleExpandRow(event, cell.row.index)}
        type="button"
      >
        <Icon icon={faCircleChevronDown} size={20} />
      </button>
    ),
    [handleExpandRow, intl],
  )

  const renderIcon = useCallback(
    ({ cell }: CellContext<T, unknown>) => {
      const { url } = cell.row.original

      if (!url) {
        return null
      }

      return (
        <Link
          aria-label={intl.formatMessage({ id: 'PreviewColumn_footer_button' })}
          title={intl.formatMessage({ id: 'PreviewColumn_footer_button' })}
          to={url}
        >
          <div className="Table-chevron d-flex justify-content-end">
            <Icon icon={faChevronRight} size={16} />
          </div>
        </Link>
      )
    },
    [intl],
  )

  const tableColumns = useMemo<ColumnDef<T>[]>(() => {
    const detailsColumn: ColumnDef<T> = {
      cell: renderDetailsButton,
      id: TableListStaticColumnsEnum.DETAILS,
    }

    const iconColumn: ColumnDef<T> = {
      cell: renderIcon,
      id: TableListStaticColumnsEnum.ICON,
    }

    const preparedColumns = columns.map(column => ({
      ...column,
    }))

    if (hideArrow) {
      return [detailsColumn, ...preparedColumns]
    }

    return [detailsColumn, ...preparedColumns, iconColumn]
  }, [columns, hideArrow, renderDetailsButton, renderIcon])

  const table = useReactTable({
    columns: tableColumns,
    data,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    onSortingChange,
    state: {
      sorting,
    },
  })
  const { rows } = table.getRowModel()

  const renderProgressInfo = useMemo(() => {
    let progressInfo = null

    if (isSearching || isLoading) {
      progressInfo = <SearchLoadingState />
    }

    if (!isLoading && isEmpty(data)) {
      progressInfo = (
        <NoResults
          className="pb-4"
          noResultsText={customNoResultsMessage}
          type={
            customNoResultsMessage
              ? NoResultsTypeEnum.custom
              : NoResultsTypeEnum.simple
          }
        />
      )
    }

    if (!progressInfo) {
      return null
    }

    return (
      <tr className="Table-row Table-row--no-results" role="row">
        <td className="Table-cell">{progressInfo}</td>
      </tr>
    )
  }, [isSearching, isLoading, data, customNoResultsMessage])

  const renderExpandableContent = useCallback((cells: Cell<T, unknown>[]) => {
    const expandableContentIndex = cells.findIndex(
      cell =>
        typeof cell.column.columnDef.meta?.ExpandableContent === 'function',
    )

    if (expandableContentIndex === -1) {
      return null
    }

    return (
      <tr className="Table-row Table-row--expandable" role="row">
        <td className="Table-row--expandable-cell w-100">
          {flexRender(
            cells[expandableContentIndex].column.columnDef.meta
              ?.ExpandableContent,
            cells[expandableContentIndex].getContext(),
          )}
        </td>
      </tr>
    )
  }, [])

  const renderRows = useMemo(
    () =>
      rows.map(row => {
        const cells = row.getVisibleCells()

        return (
          <Fragment key={`row__${row.id}`}>
            <tr
              ref={tableRow => {
                rowsRef.current.set(row.index, tableRow)
              }}
              className="Table-row"
              data-e2e={E2E_TABLE_ROW}
              data-id={row.original.id}
              data-testid={TABLE_ROW}
              role="row"
            >
              {cells.map(cell => (
                <td
                  key={cell.id}
                  className={clsx(createCellClassNames(cell))}
                  {...createDataE2E(cell)}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>

            {renderExpandableContent(cells)}
          </Fragment>
        )
      }),
    [createCellClassNames, createDataE2E, renderExpandableContent, rows],
  )

  useEffect(() => {
    const { current: rows } = rowsRef

    if (typeof selectedRowIndex === 'undefined') {
      return
    }

    rows.forEach(row => {
      row?.classList.remove('Table-row--isSelected')
    })

    if (selectedRowIndex !== null && rows.has(selectedRowIndex)) {
      rows.get(selectedRowIndex)?.classList.add('Table-row--isSelected')
    }
  }, [selectedRowIndex])

  return (
    <table className={clsx('Table', className)} role="table">
      <thead className="Table-head">
        {table.getHeaderGroups().map(headerGroup => (
          <tr
            key={headerGroup.id}
            className="Table-row Table-row--head"
            role="row"
          >
            {headerGroup.headers.map(header => (
              <th
                key={header.id}
                className={clsx(createHeaderClassNames(header))}
              >
                {header.column.getCanSort() ? (
                  <SortableHeading
                    as={6}
                    clearSorting={header.column.clearSorting}
                    columnName={header.column.columnDef.id!}
                    getIsSorted={header.column.getIsSorted}
                    size={2}
                    toggleSorting={header.column.toggleSorting}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </SortableHeading>
                ) : (
                  <Heading as={6} size={2}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </Heading>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody className="Table-body">
        {renderRows}

        {renderProgressInfo}
      </tbody>
    </table>
  )
}
