import { useCallback, useMemo } from 'react'
import { debounce, isArray } from 'lodash-es'

import { Label } from 'Components/_theme'
import { RangeInput } from 'Components/RangeInput/RangeInput'

import { scrollToTop } from 'Utilities'

import { useCurrentRefinement } from 'Algolia/hooks/useCurrentRefinement/useCurrentRefinement'

import { useRangeSlider } from 'Portal/src/hooks/useRangeSlider/useRangeSlider'

import { RangeFilterProps } from './RangeFilter.types'

export const RangeFilter = (props: RangeFilterProps) => {
  const { filterProps, label, rangeInputProps } = props

  const { range, refine } = useRangeSlider(filterProps)
  const currentRefinement = useCurrentRefinement(filterProps.attribute)
  const value = useMemo(() => {
    const minRange = range.min ?? 0
    const maxRange = range.max ?? 0

    if (!currentRefinement.length) {
      return [minRange, maxRange]
    }

    let firstValue = minRange
    let secondValue = maxRange

    if (currentRefinement.length === 2) {
      firstValue = Number(currentRefinement[0]?.value) || minRange
      secondValue = Number(currentRefinement[1]?.value) || maxRange

      return [firstValue, secondValue]
    }

    const { value, operator } = currentRefinement[0]

    if (operator === '>=') {
      firstValue = Number(value) || minRange
    }

    if (operator === '<=') {
      secondValue = Number(value) || maxRange
    }

    return [firstValue, secondValue]
  }, [currentRefinement, range.max, range.min])

  const handleChange = (value: number | number[]) => {
    scrollToTop()

    if (isArray(value)) {
      refine([value[0], value[1]])

      return
    }

    refine([undefined, value])
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleChange = useCallback(debounce(handleChange, 500), [
    handleChange,
  ])

  return (
    <div className="Filter-input-container">
      <Label>{label}</Label>

      <RangeInput
        max={range.max ?? 0}
        min={range.min ?? 0}
        onChange={debouncedHandleChange}
        value={value}
        {...rangeInputProps}
      />
    </div>
  )
}
