export const toggleIntercomVisibility = (isVisible: boolean) => {
  const intercomContainer = document.getElementsByClassName(
    'intercom-lightweight-app',
  )

  if (intercomContainer.length < 1) {
    return
  }

  const [element] = intercomContainer

  if (isVisible) {
    element.classList.remove('d-none')
  } else {
    element.classList.add('d-none')
  }
}
