import { ParseStreetString } from './parseStreetString.types'

export const parseStreetString = (
  street?: string | null,
): ParseStreetString => {
  if (!street) {
    return {
      streetName: '',
      streetNumber: '',
    }
  }

  const streetParts = street.split(' ')
  const streetNumber = streetParts
    .filter(streetPart => parseInt(streetPart, 10))
    .join(' ')
  const streetName = streetParts
    .filter(streetPart => !parseInt(streetPart, 10))
    .join(' ')

  return {
    streetName,
    streetNumber,
  }
}
