import {
  IconDefinition,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core'

export const iconNewPhonePlan: IconDefinition = {
  icon: [
    32,
    32,
    [],
    'e011',
    'M8.115 4.792 L 3.360 9.587 3.361 19.394 C 3.362 30.183,3.341 29.501,3.692 30.221 C 3.912 30.673,4.468 31.271,4.913 31.535 C 5.100 31.646,5.421 31.790,5.627 31.855 L 6.000 31.973 15.973 31.973 C 25.339 31.973,25.967 31.968,26.287 31.879 C 26.828 31.730,27.242 31.482,27.694 31.034 C 28.027 30.705,28.149 30.538,28.316 30.179 C 28.431 29.934,28.550 29.591,28.582 29.417 C 28.664 28.971,28.664 3.029,28.582 2.583 C 28.386 1.510,27.501 0.522,26.400 0.145 L 26.053 0.027 19.462 0.012 L 12.870 -0.004 8.115 4.792 M25.547 16.000 L 25.547 28.800 16.027 28.800 L 6.507 28.800 6.507 19.850 L 6.507 10.899 10.307 7.051 L 14.107 3.202 19.827 3.201 L 25.547 3.200 25.547 16.000 M14.441 14.520 L 14.427 16.400 12.547 16.414 L 10.667 16.428 10.667 17.973 L 10.667 19.519 12.547 19.533 L 14.427 19.547 14.441 21.427 L 14.455 23.307 16.000 23.307 L 17.545 23.307 17.559 21.427 L 17.573 19.547 19.453 19.533 L 21.333 19.519 21.333 17.973 L 21.333 16.427 19.440 16.427 L 17.547 16.427 17.547 14.533 L 17.547 12.640 16.001 12.640 L 14.455 12.640 14.441 14.520',
  ],
  iconName: 'newPhonePlan' as IconName,
  prefix: 'fac' as IconPrefix,
}
