import { PreviewColumnTypeEnum } from 'Components/PreviewColumn/PreviewColumn.types'

export interface I18nConfig {
  flag: string
  language: string
  locale: string
  name: string
}

export type PreviewColumnBaseSetting = {
  type: Exclude<PreviewColumnTypeEnum, PreviewColumnTypeEnum.Bundle>
  isOpen: boolean
}

export type PreviewColumnBundleSetting = {
  type: PreviewColumnTypeEnum.Bundle
  isOpen: boolean
  handleOnRentAction: () => void
}

export type PreviewColumnBaseSettingProps = {
  type: Exclude<PreviewColumnTypeEnum, PreviewColumnTypeEnum.Bundle>
}

export type PreviewColumnBundleSettingProps = {
  type: PreviewColumnTypeEnum.Bundle
  handleOnRentAction: () => void
}

export type PreviewColumnSetting =
  | PreviewColumnBaseSetting
  | PreviewColumnBundleSetting

export type PreviewColumnSettingProps =
  | PreviewColumnBaseSettingProps
  | PreviewColumnBundleSettingProps

export enum UserModeEnum {
  DEFAULT = 'DEFAULT',
  NEW_JOINER = 'NEW_JOINER',
}
