import { FormattedMessage } from 'react-intl'
import { DateTime } from 'luxon'

import { ParsedSearchResultDocument } from 'Modules/searchResults/types/searchResults.types'
import { getSearchResultValueFromHighlight } from 'Modules/searchResults/utils/searchResults.utils'

import { FormatDate } from 'Components/FormatDate/FormatDate'

import { ContentTypeEnum } from 'Types/content/content.types'

import { CaseItemType } from 'Portal/__generated__/globalTypes'

import { SearchResultDetailsProps } from './SearchResultsDetails.types'

export const SearchResultDetails = (props: SearchResultDetailsProps) => {
  const { document, model, highlights } = props

  const renderItem = (
    label: string,
    keys: (keyof ParsedSearchResultDocument)[],
    isDate = false,
  ) => {
    const content = keys
      .map(key => getSearchResultValueFromHighlight(key, document, highlights))
      .join(' ')

    if (content.trim() === '') {
      return null
    }

    if (isDate) {
      const contentNumber = Number(content)

      if (contentNumber < 0) {
        return null
      }

      // BE returns timestamp
      const date = DateTime.fromSeconds(contentNumber).toJSDate()

      return (
        <>
          <dt>
            <FormattedMessage id={label} />:
          </dt>

          <dd>
            <FormatDate value={date} />
          </dd>
        </>
      )
    }

    return (
      <>
        <dt>
          <FormattedMessage id={label} />:
        </dt>

        <dd
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </>
    )
  }

  const renderRentalDetails = () => (
    <>
      {renderItem('IMEI', ['rental.stocklist_item.imei'])}
      {renderItem('Serial_number', ['rental.stocklist_item.serial_number'])}
      {renderItem('Employees', [
        'rental.employee.first_name',
        'rental.employee.last_name',
      ])}
      {renderItem('Start_date', ['rental.start_at'], true)}
    </>
  )

  const renderPhonePlanDetails = () => (
    <>
      {renderItem('Employee', ['employee.first_name', 'employee.last_name'])}
      {renderItem('Phone_number', ['phone_plan.phone_number'])}
      {renderItem('Start_date', ['phone_plan.start_at'], true)}
    </>
  )

  const renderEmployeesDetails = () => (
    <>
      {renderItem('Email', ['employee.email'])}
      {renderItem('Device', ['rental.stocklist_item.name'])}
      {renderItem('IMEI', ['rental.stocklist_item.imei'])}
    </>
  )

  const renderReturnsDetails = () => (
    <>
      {renderItem('IMEI', ['return_case.stocklist_item.imei'])}
      {renderItem('Serial_number', ['return_case.stocklist_item.serial'])}
      {renderItem('Employees', [
        'return_case.employee.first_name',
        'return_case.employee.last_name',
      ])}
    </>
  )

  const renderOrderDetails = () => {
    switch (document['case_item.type']) {
      case CaseItemType.early_upgrade_device:
      case CaseItemType.upgrade_device:
        return (
          <>
            {renderItem('Request_id', ['case_item.id'])}
            {renderItem('Device', ['rental.stocklist_item.name'])}
            {renderItem('Employee', [
              'employee.first_name',
              'employee.last_name',
            ])}
            {renderItem('Start_date', ['case_item.created'], true)}
          </>
        )
      default:
        return (
          <>
            {renderItem('Request_id', ['case_item.id'])}
            {renderItem('Employee', [
              'employee.first_name',
              'employee.last_name',
            ])}
            {renderItem('Start_date', ['case_item.created'], true)}
          </>
        )
    }
  }

  const renderDetails = () => {
    switch (model) {
      case ContentTypeEnum.rentals:
        return renderRentalDetails()
      case ContentTypeEnum.phone_plans:
        return renderPhonePlanDetails()
      case ContentTypeEnum.employees:
        return renderEmployeesDetails()
      case ContentTypeEnum.caseitems:
        return renderOrderDetails()
      case ContentTypeEnum.return_cases:
        return renderReturnsDetails()
      default:
        return null
    }
  }

  return renderDetails()
}
