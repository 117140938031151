import { TrackingStatusEnum } from 'Types/trackingStatus/trackingStatus.types'

import { DeliveryStatusItem } from '../DeliveryStatusItem/DeliveryStatusItem'
import { DeliveryStatusProps } from './DeliveryStatus.types'

export const DeliveryStatus = (props: DeliveryStatusProps) => {
  const { trackingStatuses, trackingURL, type } = props

  const transitStatus = trackingStatuses.find(
    status => status.status === TrackingStatusEnum.transit,
  )
  const deliveredStatus = trackingStatuses.find(
    status => status.status === TrackingStatusEnum.delivered,
  )
  const failureStatus = trackingStatuses.find(
    status => status.status === TrackingStatusEnum.failure,
  )
  const isInTransit = !!transitStatus
  const isFailed = !!failureStatus
  const isDelivered = !!deliveredStatus && !isFailed

  return (
    <div className="DeliveryStatus">
      <DeliveryStatusItem
        isCurrent={isInTransit && !isDelivered && !isFailed}
        isDelivered={isDelivered}
        isDone={isInTransit}
        status={TrackingStatusEnum.transit}
        timestamp={transitStatus?.created}
        type={type}
      />

      {!isFailed ? (
        <DeliveryStatusItem
          isCurrent={isDelivered}
          isDelivered={isDelivered}
          isDone={isDelivered}
          status={TrackingStatusEnum.delivered}
          timestamp={deliveredStatus?.created}
          trackingURL={trackingURL}
          type={type}
        />
      ) : (
        <DeliveryStatusItem
          isCurrent={isFailed}
          isDelivered={isDelivered}
          isDone={isFailed}
          status={TrackingStatusEnum.failure}
          timestamp={failureStatus?.created}
          type={type}
        />
      )}
    </div>
  )
}
