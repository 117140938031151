import { gql } from '@apollo/client'

export const GET_RETURN_CASE = gql`
  query getReturnCase($id: ID!) {
    returnCaseItem(id: $id) {
      id
      createdAt
      returnDate
      closedAt
      caseItem {
        caseType
      }
      employee {
        id
        contact {
          firstName
          lastName
          email
          phoneNumber
        }
      }
      latestShippingInfo {
        id
        carrier
        trackingReference
        trackingURL
        createdAt
      }
      rental {
        startAt
      }
      status
      substatus
      stocklistItem {
        serialNumber
        imei
        imei2
        deviceDefinition {
          imageURL
          name
          productType
        }
      }
      trackingStatuses {
        totalCount
        trackingStatuses {
          status
          created
        }
      }
    }
  }
`

export const GET_RETURN_CASE_PREVIEW_COLUMN = gql`
  query getReturnCasePreviewColumn($id: ID!) {
    returnCaseItem(id: $id) {
      id
      createdAt
      returnDate
      closedAt
      caseItem {
        caseType
      }
      employee {
        id
        contact {
          firstName
          lastName
        }
      }
      rental {
        startAt
      }
      status
      stocklistItem {
        eid
        imei
        imei2
        serialNumber
        deviceDefinition {
          imageURL
          name
          productType
        }
      }
      substatus
    }
  }
`
