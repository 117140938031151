import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons'

import { Alert } from 'Components/_theme'
import { CardDetailsListItem } from 'Components/CardDetailsList/CardDetailsList.types'
import { FormatDate } from 'Components/FormatDate/FormatDate'
import { PresentationCard } from 'Components/PresentationCard/PresentationCard'

import { ImagePlaceholderTypesEnum } from 'Types/imagePlaceholders.type'

import { PhonePlanPresentationCardProps } from './PhonePlanPresentationCard.types'

export const PhonePlanPresentationCard = (
  props: PhonePlanPresentationCardProps,
) => {
  const { phonePlan } = props

  const generateListItems = useMemo(
    (): CardDetailsListItem[] => [
      {
        label: 'Phone_number',
        value: phonePlan.phoneNumber,
      },
      {
        label: 'Sim_card_number',
        value: phonePlan.simcardNumber,
      },
      {
        label: 'PhonePlanDetails_pin_number',
        value: phonePlan.pin,
      },
      {
        label: 'PhonePlanDetails_puk_number',
        value: phonePlan.puk,
      },
      {
        label: 'Start',
        value: <FormatDate value={phonePlan.startAt} />,
      },
      {
        label: 'End',
        value: <FormatDate value={phonePlan.endAt} />,
      },
    ],
    [
      phonePlan.endAt,
      phonePlan.phoneNumber,
      phonePlan.pin,
      phonePlan.puk,
      phonePlan.simcardNumber,
      phonePlan.startAt,
    ],
  )

  return (
    <PresentationCard
      header={phonePlan.phonePlanTariff?.name ?? ''}
      imagePlaceholderType={ImagePlaceholderTypesEnum.phonePlan}
      imageSource={phonePlan.phonePlanTariff?.logo}
      listItems={generateListItems}
      title="Phone_plan"
    >
      <Alert
        className="mt-2 mb-2"
        iconLeft={faCircleExclamation}
        variant="info"
      >
        <FormattedMessage id="PhonePlanDetails_provider_hint" />
      </Alert>
    </PresentationCard>
  )
}
