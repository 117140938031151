import { SearchResultDocumentKey } from 'Types/globalSearch/searchResults.types'

export const GS_AUTOCOMPLETE_DIRECT_FIELDS: Partial<
  Record<SearchResultDocumentKey, string>
> = {
  'employee.cost_center_name': 'Cost_center',
  'employee.first_name': 'First_name',
  'employee.last_name': 'Last_name',
  'rental.stocklist_item.imei': 'IMEI',
  'rental.stocklist_item.imei2': 'IMEI2',
}
