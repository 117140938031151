import { z } from 'zod'

// clearing react select returns null
// therefore we need to make it nullable
export const zSelect = z
  .object({
    label: z.string(),
    value: z.string(),
  })
  .nullable()

export const zSelectMulti = z
  .array(
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  )
  .nullable()
