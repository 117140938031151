import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { clsx } from 'clsx'

import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  LinkButton,
} from 'Components/_theme'

import { TableCardProps } from './TableCard.types'

export const TableCard = <T extends {}>(props: TableCardProps<T>) => {
  const {
    heading,
    icon,
    iconColor,
    total,
    columns,
    data,
    className,
    tableClassName,
    subtitle,
  } = props

  const { formatMessage } = useIntl()

  const renderSubtitle = useMemo(
    () => `${formatMessage({ id: subtitle || 'Total' })}: ${total}`,
    [formatMessage, subtitle, total],
  )

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  const { pageSize } = table.getState().pagination

  return (
    <Card className={className}>
      <CardHeader color={iconColor} icon={icon} subtitle={renderSubtitle}>
        <FormattedMessage id={heading} />
      </CardHeader>

      <CardBody className="pt-0 px-0">
        <table className={clsx('TableCard', tableClassName)} role="table">
          <thead className="TableCard-head">
            {table.getHeaderGroups().map(headerGroup => (
              <tr
                key={headerGroup.id}
                className="TableCard-row TableCard-row--head"
              >
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    className="TableCard-cell TableCard-cell--head"
                  >
                    <Heading as={6} size={2}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </Heading>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody className="TableCard-body">
            {table.getRowModel().rows.map(row => (
              <tr key={row.id} className="TableCard-row" role="row">
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id} className="TableCard-cell">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}

            {pageSize < data.length && (
              <tr className="w-100 d-flex justify-content-center mt-3">
                <td>
                  <LinkButton
                    onClick={() => {
                      table.setPageSize(pageSize + 10)
                    }}
                    type="button"
                  >
                    <FormattedMessage id="See_more" />
                  </LinkButton>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </CardBody>
    </Card>
  )
}
