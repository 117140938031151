import { gql } from '@apollo/client'

export const GET_ORGANISATION = gql`
  query getOrganisation($id: ID!) {
    organisation(id: $id) {
      id
      isCYOD
      accountManager {
        id
        firstName
        lastName
      }
      contact {
        id
        address {
          id
          city
          country
          name
          nameAddition
          street
          streetAddition
          zipcode
        }
      }
      costCenters {
        totalCount
        costCenters {
          id
          name
        }
      }
      emailConfig {
        activationEmails
      }
      fullName
      individualTermsEN
      individualTermsDE
      isPlusOrganisation
      logoImageURL
      offices {
        totalCount
        offices {
          id
          locationName
        }
      }
      portalContent {
        dashboardHeaderEN
        dashboardHeaderDE
        dashboardSubtitleEN
        dashboardSubtitleDE
        dashboardButtonEN
        dashboardButtonDE
        dashboardImageURL
        faqCardURLEN
        faqCardURLDE
      }
      rentCategoryTiers {
        rentCategoryTiers {
          id
          name
          displaySortOrder
        }
      }
      shortName
      currency
      depots(pagination: { after: 0, first: 0 }) {
        stocklistItemCount
        totalCount
      }
      invoices(pagination: { after: 0, first: 0 }) {
        totalCount
      }
      phonePlans(pagination: { after: 0, first: 0 }) {
        totalCount
      }
      impactFactors {
        status
        factors {
          name
          value
          valueConverted
          converter
        }
      }
      fulfillmentProviderMapping {
        country
        URLHash
      }
    }
  }
`
