import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { clsx } from 'clsx'

import { Icon } from 'Components/Icon/Icon'

import { MobileNavigationLinkProps } from './MobileNavigationLink.types'

export const MobileNavigationLink = (props: MobileNavigationLinkProps) => {
  const { icon, isActive, label, path } = props
  const intl = useIntl()

  const classNames = clsx(
    'MobileNavigationLink',
    isActive && 'MobileNavigationLink-is-active',
  )

  return (
    <Link
      aria-label={intl.formatMessage({ id: label })}
      className={classNames}
      title={intl.formatMessage({ id: label })}
      to={path}
    >
      <Icon icon={icon} />

      <span className="MobileNavigationLink-label">
        <FormattedMessage id={label} />
      </span>
    </Link>
  )
}
