import type * as React from 'react'
import { useMemo } from 'react'
import { Col, Form, InputGroup, Row } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  faCalculator,
  faChevronDown,
  faChevronUp,
  faCircleInfo,
} from '@fortawesome/pro-solid-svg-icons'
import classNames from 'classnames'
import { observer } from 'mobx-react'

import {
  Alert,
  Input,
  Label,
  PrimaryButton,
  RadioButtonGroup,
  TertiaryButton,
  Widget,
} from 'Components/_theme'
import { RadioButtonSizeEnum } from 'Components/_theme/RadioButton/RadioButton.types'
import { ProgressFormRange } from 'Components/ProgressFormRange/ProgressFormRange'

import { useStore } from 'Stores/index'

import { calculateNetPrices } from 'Utilities'

import { germanStates } from 'Constants/germanStates'

import CalculatorImage from 'Assets/images/layout/calculator.svg'

import {
  NETPRICE_CALCULATOR_CHURCH_TAXES,
  NETPRICE_CALCULATOR_TAX_CLASSES,
} from './NetPriceCalculator.constants'

export const NetPriceCalculator = observer(() => {
  const intl = useIntl()
  const { orderStore } = useStore()
  const { netPriceCalculator, setNetPriceCalculator } = orderStore

  const {
    calculationVisible,
    childAllowanceValue,
    churchTaxValue,
    collapse,
    healthValue,
    salaryValue,
    taxClassValue,
  } = netPriceCalculator

  const onChildAllowanceInput = (event: React.FormEvent<HTMLInputElement>) => {
    setNetPriceCalculator({
      ...netPriceCalculator,
      childAllowanceValue: +event.currentTarget.value,
    })
  }

  const toggleCollapse = () => {
    setNetPriceCalculator({
      ...netPriceCalculator,
      collapse: !collapse,
    })
  }

  const generateCalculateButtonLabel = useMemo(() => {
    if (calculationVisible) {
      return intl.formatMessage({
        id: 'NetPriceCalculator_recalculate',
      })
    }

    return intl.formatMessage({
      id: 'NetPriceCalculator_calculate',
    })
  }, [calculationVisible, intl])

  return (
    <Widget
      className={classNames(
        'mb-4 mb-xxl-6 NetPriceCalculator',
        collapse && 'closed',
      )}
      content={
        <>
          <p>
            <FormattedMessage id="NetPriceCalculator_enter_your_tax_info" />
          </p>

          {calculationVisible && (
            <Alert
              className="align-items-center"
              iconLeft={faCircleInfo}
              title={intl.formatMessage({
                id: 'NetPriceCalculator_prices_visible',
              })}
              variant="success"
            >
              <FormattedMessage id="NetPriceCalculator_estimation_disclaimer" />
            </Alert>
          )}
        </>
      }
      imageSrc={CalculatorImage}
      primaryIcon={faCalculator}
      rightContent={
        <TertiaryButton
          aria-label={intl.formatMessage({
            id: 'Expand_content',
          })}
          asIcon={collapse ? faChevronDown : faChevronUp}
          onClick={toggleCollapse}
        />
      }
      title={intl.formatMessage({
        id: 'NetPriceCalculator_effective_net_price_calculator',
      })}
    >
      <Row
        className={classNames(
          collapse && 'closed',
          'net-price-calculator-body',
          !collapse && 'mb-2',
          'collapsible',
        )}
      >
        <Col>
          <Row>
            <Col>
              <Form
                onSubmit={(e: React.FormEvent) => {
                  e.preventDefault()
                  setNetPriceCalculator({
                    ...netPriceCalculator,
                    calculationVisible: true,
                    collapse: true,
                  })
                  calculateNetPrices(orderStore)
                }}
              >
                <Row>
                  <Col className="mb-6">
                    <Form.Group controlId="calculatorFormSalary">
                      <InputGroup>
                        <Label htmlFor="calculatorFormSalary" isRequired>
                          <FormattedMessage id="NetPriceCalculator_gross_salary" />
                        </Label>

                        <Input
                          autoComplete="off"
                          contentBefore="€"
                          id="calculatorFormSalary"
                          min={1}
                          name="calculatorFormSalary"
                          onChange={e => {
                            setNetPriceCalculator({
                              ...netPriceCalculator,
                              salaryValue: Math.abs(+e.target.value).toString(),
                            })
                          }}
                          onWheel={() =>
                            (document.activeElement as HTMLElement).blur()
                          }
                          placeholder={intl.formatMessage({
                            id: 'NetPriceCalculator_enter_salary',
                          })}
                          required
                          type="number"
                          value={salaryValue}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-6">
                    <Form.Group controlId="calculatorFormTaxClass">
                      <Label id="calculatorFormTaxClass" isRequired>
                        <FormattedMessage id="NetPriceCalculator_tax_class" />
                      </Label>
                      <br />

                      <RadioButtonGroup
                        aria-labelledby="calculatorFormTaxClass"
                        className="w-100"
                        idPrefix={`${intl.formatMessage({
                          id: 'NetPriceCalculator_tax_class',
                        })}: `}
                        name="calculatorFormTaxClass"
                        onChange={value => {
                          setNetPriceCalculator({
                            ...netPriceCalculator,
                            taxClassValue: +value,
                          })
                        }}
                        radioButtons={NETPRICE_CALCULATOR_TAX_CLASSES.map(
                          taxClass => ({
                            label: taxClass.name,
                            value: taxClass.value.toString(),
                          }),
                        )}
                        size={RadioButtonSizeEnum.medium}
                        value={taxClassValue.toString()}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-6">
                    <Form.Group controlId="calculatorFormState">
                      <Label htmlFor="calculatorFormState">
                        <FormattedMessage id="NetPriceCalculator_state" />
                      </Label>

                      <Form.Select
                        id="calculatorFormState"
                        name="calculatorFormState"
                        onChange={e => {
                          setNetPriceCalculator({
                            ...netPriceCalculator,
                            stateValue: e.currentTarget.value,
                          })
                        }}
                      >
                        {Object.entries(germanStates).map(el => (
                          <option key={el[0]} value={`${el[0]}`}>
                            {`${el[1]}`}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-6">
                    <Form.Group controlId="calculatorFormChildAllowance">
                      <Label
                        className="mb-2"
                        htmlFor="calculatorFormChildAllowance"
                      >
                        <FormattedMessage id="NetPriceCalculator_child_allowance" />
                      </Label>

                      <ProgressFormRange
                        id="calculatorFormChildAllowance"
                        max={6}
                        min={0}
                        name="calculatorFormChildAllowance"
                        onInputCallback={onChildAllowanceInput}
                        step={0.5}
                      />
                      <div className="mt-1">
                        <Form.Text>
                          <FormattedMessage id="NetPriceCalculator_value" />
                          :&nbsp;{childAllowanceValue}
                        </Form.Text>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-6">
                    <Form.Group controlId="calculatorFormChurchTax">
                      <Label id="calculatorFormChurchTax">
                        <FormattedMessage id="NetPriceCalculator_church_tax" />
                      </Label>
                      <br />
                      <RadioButtonGroup
                        aria-labelledby="calculatorFormChurchTax"
                        className="w-100"
                        idPrefix={`${intl.formatMessage({
                          id: 'NetPriceCalculator_church_tax',
                        })}: `}
                        name="calculatorFormChurchTax"
                        onChange={value => {
                          setNetPriceCalculator({
                            ...netPriceCalculator,
                            churchTaxValue: +value,
                          })
                        }}
                        radioButtons={NETPRICE_CALCULATOR_CHURCH_TAXES.map(
                          churchTax => ({
                            label: churchTax.name,
                            value: churchTax.value.toString(),
                          }),
                        )}
                        size={RadioButtonSizeEnum.medium}
                        value={churchTaxValue.toString()}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-6">
                    <Form.Group controlId="calculatorFormInsuranceContribution">
                      <InputGroup>
                        <Label
                          htmlFor="calculatorFormInsuranceContribution"
                          isRequired
                        >
                          <FormattedMessage id="NetPriceCalculator_additional_health_insurance" />
                        </Label>

                        <Input
                          autoComplete="off"
                          contentBefore="%"
                          id="calculatorFormInsuranceContribution"
                          name="calculatorFormInsuranceContribution"
                          onChange={e => {
                            setNetPriceCalculator({
                              ...netPriceCalculator,
                              healthValue: Math.abs(+e.target.value)
                                .toString()
                                .replace(',', '.'),
                            })
                          }}
                          onWheel={() =>
                            (document.activeElement as HTMLElement).blur()
                          }
                          placeholder={intl.formatMessage({
                            id: 'NetPriceCalculator_enter_your_health_insurance',
                          })}
                          required
                          type="number"
                          value={healthValue}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span>
                      *&nbsp;
                      <FormattedMessage id="NetPriceCalculator_required_fields" />
                    </span>
                  </Col>
                </Row>

                <Row className="mt-9">
                  <Col>
                    <PrimaryButton className="w-100" type="submit">
                      <FormattedMessage id="NetPriceCalculator_apply" />
                    </PrimaryButton>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
      {collapse && (
        <PrimaryButton
          appearance="outline"
          className={classNames(collapse && 'mt-4')}
          onClick={toggleCollapse}
        >
          {generateCalculateButtonLabel}
        </PrimaryButton>
      )}
    </Widget>
  )
})
