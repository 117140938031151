import { formatDate } from 'Utilities'

import { FormatDateProps } from './FormatDate.types'

export const FormatDate = (props: FormatDateProps) => {
  const { value, shouldShowTime } = props

  if (!value) {
    return null
  }

  return <div>{formatDate(value, shouldShowTime)}</div>
}
