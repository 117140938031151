import { useEffect } from 'react'
import { useIntl } from 'react-intl'

import { OfferAcceptedContent } from 'Modules/purchase/components/OfferAcceptedContent/OfferAcceptedContent'
import { PurchaseLayout } from 'Modules/purchase/layouts/PurchaseLayout/PurchaseLayout'

import { setRouteTitle } from 'Portal/src/router/Router.utils'

export const OfferAccepted = () => {
  const intl = useIntl()

  useEffect(() => {
    setRouteTitle(
      intl.formatMessage({
        id: 'OfferAcceptedContent_heading',
      }),
    )
  }, [intl])

  return (
    <PurchaseLayout>
      <OfferAcceptedContent />
    </PurchaseLayout>
  )
}
