import { useCallback, useEffect } from 'react'
import { useMountEffect } from '@react-hookz/web'
import { observer } from 'mobx-react'

import { PageLoadingState } from 'Components/PageLoadingState/PageLoadingState'
import { ScrollToTop } from 'Components/ScrollToTop/ScrollToTop'
import { ShippingAddressFormSchema } from 'Components/ShippingAddressForm/ShippingAddressForm.types'

import { useStore } from 'Stores/index'
import { OrderStepEnum } from 'Stores/orderStore/orderStore.types'

import { useActiveUser } from 'Portal/src/hooks'

import { OrderShippingAddressForm } from '../../OrderShippingAddressForm/OrderShippingAddressForm'
import { AddressStepProps } from '../Steps.types'

export const AddressStep = observer((props: AddressStepProps) => {
  const { activeEmployeeId, handleNextStep, handlePreviousStep } = props
  const { orderStore } = useStore()
  const { activeOrganisation, activeEmployee } = useActiveUser()
  const {
    clearChosenDeviceLimitationRules,
    chosenShippingAddress,
    setChosenShippingAddress,
    officeAddress,
    shouldSkipAccessories,
    fetchEmployeeContact,
    employeeContact,
    vendingMachinesData,
    fetchVendingMachineData,
    setCurrentStep,
  } = orderStore
  const organisationId = activeOrganisation?.id
  const needsEmployeeContact = !activeEmployee && !!activeEmployeeId
  const isLoading =
    (needsEmployeeContact && employeeContact.isLoading) ||
    vendingMachinesData.isLoading

  const handleNextAction = useCallback(() => {
    if (handleNextStep) {
      handleNextStep()
    }
  }, [handleNextStep])

  const handlePreviousAction = useCallback(() => {
    if (handlePreviousStep) {
      if (shouldSkipAccessories) {
        clearChosenDeviceLimitationRules()
      }

      handlePreviousStep()
    }
  }, [
    clearChosenDeviceLimitationRules,
    handlePreviousStep,
    shouldSkipAccessories,
  ])

  const handleSubmitAddressForm = useCallback(
    (shippingAddress: ShippingAddressFormSchema | null) => {
      if (!shippingAddress) {
        return
      }

      setChosenShippingAddress(shippingAddress)

      handleNextAction()
    },
    [handleNextAction, setChosenShippingAddress],
  )

  useMountEffect(() => {
    setCurrentStep(OrderStepEnum.address)
  })

  useEffect(() => {
    if (needsEmployeeContact) {
      fetchEmployeeContact(activeEmployeeId)
    }
  }, [activeEmployeeId, fetchEmployeeContact, needsEmployeeContact])

  useEffect(() => {
    fetchVendingMachineData(organisationId)
  }, [fetchVendingMachineData, organisationId])

  return (
    <>
      <ScrollToTop />

      {!isLoading ? (
        <OrderShippingAddressForm
          chosenShippingAddress={chosenShippingAddress}
          employeeContact={employeeContact.data}
          officeAddress={officeAddress}
          onBackButtonClick={handlePreviousAction}
          onSubmitButtonClick={handleSubmitAddressForm}
          vendingMachines={vendingMachinesData.data}
        />
      ) : (
        <PageLoadingState />
      )}
    </>
  )
})
