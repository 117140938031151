import { useEffect } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation } from '@apollo/client'
import { zodResolver } from '@hookform/resolvers/zod'
import { isEmpty } from 'lodash-es'
import { observer } from 'mobx-react'

import { ButtonGroup, Card, CardBody, CardHeader } from 'Components/_theme'
import { AlertWithError } from 'Components/AlertWithError/AlertWithError'
import { DepotDeviceSelect } from 'Components/DepotDeviceSelect/DepotDeviceSelect'
import { DepotsSelect } from 'Components/DepotsSelect/DepotsSelect'
import { Field } from 'Components/Field/Field'
import { FieldTypeEnum } from 'Components/Field/Field.types'
import { Form } from 'Components/Form/Form'
import { FormOptionalMessage } from 'Components/FormOptionalMessage/FormOptionalMessage'

import { useStore } from 'Stores/index'

import { useActiveUser } from 'Hooks'

import { getFullContactName, getSelectValue } from 'Utilities'

import {
  E2E_ASSIGN_DEVICE_BUTTON_CANCEL,
  E2E_ASSIGN_DEVICE_BUTTON_SUBMIT,
  E2E_ASSIGN_DEVICE_DEPOT,
  E2E_ASSIGN_DEVICE_DEVICE,
  E2E_ASSIGN_DEVICE_MESSAGE,
} from 'Constants/e2e'
import { EMPTY_SELECT_OPTION } from 'Constants/form'
import {
  mutationAssignDeviceToEmployee,
  mutationAssignDeviceToEmployeeVariables,
} from 'Constants/graphql/mutations/__generated__/mutationAssignDeviceToEmployee'
import { MUTATION_ASSIGN_DEVICE_TO_EMPLOYEE } from 'Constants/graphql/mutations/MutationAssignDeviceToEmployee'

import { iconAssignDevice } from 'Icons/iconAssignDevice'

import { ASSIGN_DEVICE_MODAL_FORM } from './AssignDeviceModal.constants'
import { assignDeviceModalFormSchema } from './AssignDeviceModal.schema'
import {
  AssignDeviceModalFormSchema,
  AssignDeviceModalProps,
} from './AssignDeviceModal.types'
import { assignDeviceFormDefaultValues } from './AssignDeviceModal.values'

export const AssignDeviceModal = observer((props: AssignDeviceModalProps) => {
  const { onClose, employee, onSubmit } = props
  const { userStore } = useStore()
  const { user } = userStore

  const intl = useIntl()

  const { activeEmployee, activeOrganisation } = useActiveUser()
  const organisationID =
    activeEmployee?.organisation?.id || activeOrganisation!.id

  const formMethods = useForm<AssignDeviceModalFormSchema>({
    defaultValues: {
      ...assignDeviceFormDefaultValues,
      employee: `${getFullContactName(employee.contact)}`,
    },
    resolver: zodResolver(assignDeviceModalFormSchema),
  })

  const { control, setValue } = formMethods
  const watchDepot = useWatch({
    control,
    name: 'depot',
  })

  const [submitAssignDevice, { loading: isLoading, error }] = useMutation<
    mutationAssignDeviceToEmployee,
    mutationAssignDeviceToEmployeeVariables
  >(MUTATION_ASSIGN_DEVICE_TO_EMPLOYEE, {
    onCompleted: () => {
      onSubmit()
    },
  })
  const hasError = !isEmpty(error)

  const handleSubmit = formMethods.handleSubmit(data => {
    submitAssignDevice({
      variables: {
        input: {
          employeeID: employee.id,
          message: data.message,
          organisationID,
          stocklistItem: getSelectValue(data.depotDevice),
          userID: user.userId?.toString() ?? '0',
        },
      },
    })
  })

  useEffect(() => {
    // reset depot device if selected depot is changed
    setValue('depotDevice', EMPTY_SELECT_OPTION)
  }, [setValue, watchDepot?.value])

  return (
    <Card>
      <CardHeader icon={iconAssignDevice}>
        <FormattedMessage id="Assign_device" />
      </CardHeader>

      <CardBody>
        {hasError && <AlertWithError error={error} />}

        <FormProvider {...formMethods}>
          <Form id="ASSIGN_DEVICE_MODAL_FORM" onSubmit={handleSubmit}>
            <Field
              autoComplete="off"
              disabled
              label="Employee"
              name="employee"
              type={FieldTypeEnum.text}
            />

            <DepotsSelect
              containerClassName="mt-4"
              e2eSelector={E2E_ASSIGN_DEVICE_DEPOT}
              label="Depots"
              markAsRequired
              name="depot"
            />

            <DepotDeviceSelect
              containerClassName="mt-4"
              depotId={watchDepot?.value ?? ''}
              e2eSelector={E2E_ASSIGN_DEVICE_DEVICE}
              employeeId={employee.id}
              isAssignDeviceModal
              label="Device"
              markAsRequired
              name="depotDevice"
            />

            <FormOptionalMessage
              containerClassName="mt-4"
              e2eSelector={E2E_ASSIGN_DEVICE_MESSAGE}
            />
          </Form>
        </FormProvider>

        <ButtonGroup
          leftButton={{
            'data-e2e': E2E_ASSIGN_DEVICE_BUTTON_CANCEL,
            onClick: onClose,
            text: intl.formatMessage({ id: 'Cancel' }),
          }}
          rightButton={
            !hasError
              ? {
                  'data-e2e': E2E_ASSIGN_DEVICE_BUTTON_SUBMIT,
                  disabled: isLoading,
                  form: ASSIGN_DEVICE_MODAL_FORM,
                  isLoading,
                  text: intl.formatMessage({ id: 'Assign' }),
                  type: 'submit',
                }
              : undefined
          }
        />
      </CardBody>
    </Card>
  )
})
