import { gql } from '@apollo/client'

export const GET_EMPLOYEE_DETAILS = gql`
  query getEmployeeDetails($id: ID!, $organisationId: ID) {
    employee(id: $id, organisationId: $organisationId) {
      id
      actions {
        showAssignDeviceAction
        showOrderNewDeviceAction
        showEmployeeLeavesFirmAction
        showOrderNewPhonePlanAction
      }
      canOrderNewDevice
      canOrder
      canUpgradeDevice
      costCenter {
        id
        name
      }
      exitDate
      externalReference
      rentCategoryTierID
      openNewDeviceCaseItems {
        rentCategory {
          id
          copaymentNet
          copaymentGross
          deviatingAdvertisementPrice
          descriptionDe
          descriptionEn
          name
          individualTerms
          isFixedBundle
          displayedBasisAmount
          upgradeAmount
          displaySortOrder
        }
        deviceDefinition {
          codename
          manufacturer
          storage
          productType
          displaySize
          id
        }
      }
      caseitems(orderBy: { field: id, order: DESC }) {
        totalCount
        caseItems {
          id
          assignedEmployeeID
          parentCaseItem {
            caseType
            created
            id
            status
          }
          data {
            requestedRentCategory {
              deviceDefinition {
                codename
                color
                displaySize
                edition
                id
                imageURL
                manufacturer
                name
                productType
                ram
                storage
              }
              rentCategory {
                displayedBasisAmount
                copaymentGross
                copaymentNet
                deviatingAdvertisementPrice
                descriptionDe
                descriptionEn
                id
                individualTerms
                isFixedBundle
                name
                upgradeAmount
                upgradeAmount
                displaySortOrder
              }
            }
          }
          status
          created
          caseType
        }
      }
      contact {
        id
        firstName
        lastName
        email
        phoneNumber
        type
        address {
          id
          city
          country
          name
          nameAddition
          street
          zipcode
          streetAddition
        }
      }
      office {
        id
        address {
          id
          city
          country
          name
          nameAddition
          street
          zipcode
          streetAddition
        }
        locationName
        organisation {
          id
          shortName
        }
      }
      organisation {
        depots {
          totalCount
        }
        contact {
          address {
            city
            country
            id
            name
            nameAddition
            street
            zipcode
            streetAddition
          }
        }
        id
        fullName
        individualTermsEN
        individualTermsDE
        shortName
        rentCategoryTiers {
          rentCategoryTiers {
            id
            name
            displaySortOrder
          }
        }
      }
      phonePlans {
        phonePlans {
          id
          simcardNumber
          endAt
          startAt
          phoneNumber
          phonePlanTariff {
            id
            name
            logo
          }
        }
      }
      rentals {
        rentals {
          id
          costCenter {
            id
            name
          }
          deviceID
          created
          startAt
          endAt
          description
          minimumContractDuration
          amount
          coPaymentAmount
          initiatedAt
          isUpgradeEligible
          isUpgradePending
          isEarlyUpgradeEligible
          rentalProlonging {
            validFrom
          }
          rentCategory {
            id
            copaymentGross
            copaymentNet
            displayedBasisAmount
            deviatingAdvertisementPrice
            name
            individualTerms
            isFixedBundle
            deviceDefinitions {
              deviceDefinitions {
                id
                codename
                color
                imageURL
                manufacturer
                storage
              }
            }
            displaySortOrder
          }
          stocklistItem {
            id
            imei
            imei2
            serialNumber
            eid
            deviceDefinition {
              id
              name
              imageURL
              productType
            }
          }
        }
      }
      rentCategories {
        rentcategories {
          id
          displayedBasisAmount
          copaymentNet
          copaymentGross
          descriptionDe
          descriptionEn
          deviatingAdvertisementPrice
          deviceDefinitions {
            deviceDefinitions {
              codename
              color
              displaySize
              edition
              id
              imageURL
              manufacturer
              productType
              storage
            }
          }
          displaySortOrder
          id
          individualTerms
          isFixedBundle
          name
        }
      }
      user {
        id
        email
        firstName
        lastName
        language
      }
      limitationRules {
        totalCount
        limitationRule {
          type
          active
          pending
          available
        }
      }
    }
  }
`

export const GET_EMPLOYEE_LIST_PREVIEW_COLUMN = gql`
  query getEmployeeListPreviewColumn($id: ID!) {
    employee(id: $id) {
      id
      externalReference
      costCenter {
        name
      }
      contact {
        firstName
        lastName
        email
        phoneNumber
      }
      office {
        locationName
      }
      organisation {
        fullName
      }
    }
  }
`
