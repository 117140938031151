import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { clsx } from 'clsx'

import { Input, PrimaryButton } from 'Components/_theme'

import { E2E_DATE_PICKER_CLEAR_BUTTON } from 'Constants/e2e'

import { FieldDatepickerProps } from './Field.types'

export const FieldDatepicker = (props: FieldDatepickerProps) => {
  const {
    inputClassName,
    name,
    hasError,
    datepickerProps,
    e2eSelector,
    autoComplete,
    ...rest
  } = props
  const { showClearButton, ...restDatepickerProps } = datepickerProps || {}
  const { register, resetField, trigger, getFieldState } = useFormContext()
  const fieldState = getFieldState(name)

  return (
    <div className="d-flex">
      <Input
        aria-label={name}
        autoComplete={autoComplete}
        className={clsx(inputClassName)}
        {...register(name)}
        {...rest}
        {...restDatepickerProps}
        data-e2e={e2eSelector}
        id={name}
        isInvalid={hasError}
        type="date"
      />

      {showClearButton && (
        <PrimaryButton
          appearance="outline"
          className="ms-2"
          data-e2e={E2E_DATE_PICKER_CLEAR_BUTTON}
          onClick={() => {
            resetField(name, {
              defaultValue: '',
              keepDirty: true,
              keepError: true,
              keepTouched: true,
            })

            if (fieldState.isTouched) {
              trigger(name)
            }
          }}
        >
          <FormattedMessage id="Clear" />
        </PrimaryButton>
      )}
    </div>
  )
}
