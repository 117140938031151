import { CardBody } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

import { PrimaryButton } from 'Components/_theme'

import { ReplaceBrokenDeviceConfirmationProps } from 'Modals/ReplaceBrokenDeviceModal/ReplaceBrokenDeviceModal.types'

import modalConfirmationIcon from 'Assets/images/modal-confirmation.svg'
import modalConfirmationBottomDecor from 'Assets/images/modal-confirmation-bottom-decor.svg'
import modalConfirmationDecor from 'Assets/images/modal-confirmation-decor.svg'
import telekomModalConfirmationIcon from 'Assets/images/telekom_modal-confirmation.svg'
import telekomModalConfirmationBottomDecor from 'Assets/images/telekom_modal-confirmation-bottom-decor.svg'
import telekomModalConfirmationDecor from 'Assets/images/telekom_modal-confirmation-decor.svg'

export const ReplaceBrokenDeviceModalConfirmation = (
  props: ReplaceBrokenDeviceConfirmationProps,
) => {
  const { isTelekomTheme, requestIdRef, closeButtonE2ESelector, onClose } =
    props

  const modalConfirmationIconSrc = isTelekomTheme
    ? telekomModalConfirmationIcon
    : modalConfirmationIcon
  const modalConfirmationDecorSrc = isTelekomTheme
    ? telekomModalConfirmationDecor
    : modalConfirmationDecor
  const modalConfirmationBottomDecorSrc = isTelekomTheme
    ? telekomModalConfirmationBottomDecor
    : modalConfirmationBottomDecor

  return (
    <CardBody>
      <div
        aria-live="polite"
        className="ReplaceBrokenDeviceModalConfirmation__header-wrapper"
        role="status"
      >
        <div className="ReplaceBrokenDeviceModalConfirmation__image-wrapper">
          <img
            alt="Confirmation"
            className="ReplaceBrokenDeviceModalConfirmation__image"
            src={modalConfirmationIconSrc}
          />
        </div>

        <h4>
          <FormattedMessage id="NewOrderConfirmation_heading" />
        </h4>

        <img
          alt="decor"
          className="ReplaceBrokenDeviceModalConfirmation__topDecor"
          src={modalConfirmationDecorSrc}
        />

        <div className="ReplaceBrokenDeviceModalConfirmation__decors">
          <img
            alt="bottom decor"
            className="ReplaceBrokenDeviceModalConfirmation__decors__decor"
            src={modalConfirmationBottomDecorSrc}
          />
          <img
            alt="bottom decor"
            className="ReplaceBrokenDeviceModalConfirmation__decors__decor"
            src={modalConfirmationBottomDecorSrc}
          />
          <img
            alt="bottom decor"
            className="ReplaceBrokenDeviceModalConfirmation__decors__decor"
            src={modalConfirmationBottomDecorSrc}
          />
          <img
            alt="bottom decor"
            className="ReplaceBrokenDeviceModalConfirmation__decors__decor"
            src={modalConfirmationBottomDecorSrc}
          />
        </div>
      </div>

      <div className="ReplaceBrokenDeviceModalConfirmation__content">
        {requestIdRef?.current && (
          <div className="ReplaceBrokenDeviceModalConfirmation__requestId">
            <h6 className="mt-6">
              <FormattedMessage id="NewOrderConfirmation_request_id" />
            </h6>

            <p>{`# ${requestIdRef.current}`}</p>
          </div>
        )}

        <PrimaryButton
          className="ReplaceBrokenDeviceModalConfirmation__button"
          data-e2e={closeButtonE2ESelector}
          onClick={onClose}
        >
          <FormattedMessage id="Done" />
        </PrimaryButton>
      </div>
    </CardBody>
  )
}
