import { observer } from 'mobx-react'

import { useShowInstantsearchList } from 'Hooks'

import { ReturnsDefault } from './Default/Returns.page'
import { ReturnsInstantsearch } from './Instantsearch/Returns.page'

export const Returns = observer(() => {
  const showInstantSearchList = useShowInstantsearchList()

  if (showInstantSearchList) {
    return <ReturnsInstantsearch />
  }

  return <ReturnsDefault />
})
