import { IntlShape } from 'react-intl'

import { getOrderOrganisation_organisation_rentCategories_rentcategories_deviceDefinitions_deviceDefinitions_impactFactors } from 'Constants/graphql/queries/feature/__generated__/getOrderOrganisation'
import { getOrganisation_organisation_impactFactors } from 'Constants/graphql/queries/organisation/__generated__/getOrganisation'

import {
  ImpactFactorsStatuses,
  ImpactFactorsTypes,
  ImpactTrackerChartData,
  ImpactTrackerChartDataset,
  ImpactTrackerItemValue,
} from './ImpactTracker.types'

export const valueFormatter = (value: number) => {
  if (value >= 1_000_000) {
    return `${(value / 1_000_000).toFixed(1).replace(/\.0$/, '')}M`
  }

  if (value >= 1_000) {
    return `${(value / 1_000).toFixed(1).replace(/\.0$/, '')}K`
  }

  return value
}

export const isDatasetActive = (
  datasets: ImpactTrackerChartDataset[],
  dataset: ImpactTrackerChartDataset,
): boolean => {
  const datasetsLabels = datasets.map(({ label }) => label)

  return datasetsLabels.includes(dataset.label)
}

export const mapDatasetToChartItemValues = (
  selectedDatasets: ImpactTrackerChartDataset[],
  dataset: ImpactTrackerChartDataset,
  valueName: keyof Pick<
    ImpactTrackerChartDataset,
    'valueCo2' | 'valueWater' | 'valueRawMaterial'
  >,
): ImpactTrackerItemValue => ({
  active: selectedDatasets.map(({ label }) => label).includes(dataset.label),
  color: dataset.color,
  value: dataset[valueName],
})

export const mapOrderImpactFactorsToImpactTrackerChartData = (
  impactFactors: getOrderOrganisation_organisation_rentCategories_rentcategories_deviceDefinitions_deviceDefinitions_impactFactors[],
): ImpactTrackerChartData => {
  const newDeviceImpactFactors = impactFactors.find(
    ({ status }) => status === ImpactFactorsStatuses.NEW,
  )

  const refurbishedDeviceImpactFactors = impactFactors.find(
    ({ status }) => status === ImpactFactorsStatuses.REFURB,
  )

  const refurbishedDeviceImpactFactorsCo2 =
    refurbishedDeviceImpactFactors?.factors?.find(
      ({ name }) => name === ImpactFactorsTypes.CO2,
    )
  const refurbishedDeviceImpactFactorsRawMaterials =
    refurbishedDeviceImpactFactors?.factors?.find(
      ({ name }) => name === ImpactFactorsTypes.RAW_MATERIAL,
    )
  const refurbishedDeviceImpactFactorsWater =
    refurbishedDeviceImpactFactors?.factors?.find(
      ({ name }) => name === ImpactFactorsTypes.WATER,
    )

  return {
    datasets: [
      {
        color: 'green',
        label: '',
        valueCo2: refurbishedDeviceImpactFactorsCo2?.value ?? 0,
        valueKilometers: refurbishedDeviceImpactFactorsCo2?.valueConverted ?? 0,
        valueRawMaterial:
          refurbishedDeviceImpactFactorsRawMaterials?.value ?? 0,
        valueShowers: refurbishedDeviceImpactFactorsWater?.valueConverted ?? 0,
        valueWater: refurbishedDeviceImpactFactorsWater?.value ?? 0,
      },
    ],
    totalCo2:
      newDeviceImpactFactors?.factors?.find(
        ({ name }) => name === ImpactFactorsTypes.CO2,
      )?.value ?? 0,
    totalRawMaterial:
      newDeviceImpactFactors?.factors?.find(
        ({ name }) => name === ImpactFactorsTypes.RAW_MATERIAL,
      )?.value ?? 0,
    totalWater:
      newDeviceImpactFactors?.factors?.find(
        ({ name }) => name === ImpactFactorsTypes.WATER,
      )?.value ?? 0,
  }
}

export const mapOrganisationImpactFactorsToImpactTrackerChartData = (
  impactFactors: getOrganisation_organisation_impactFactors[],
  intl: IntlShape,
): ImpactTrackerChartData => {
  const newDeviceImpactFactors = impactFactors.find(
    ({ status }) => status === ImpactFactorsStatuses.NEW,
  )

  const refurbishedDeviceImpactFactors = impactFactors.find(
    ({ status }) => status === ImpactFactorsStatuses.REFURB,
  )

  const refurbishedDeviceImpactFactorsCo2 =
    refurbishedDeviceImpactFactors?.factors?.find(
      ({ name }) => name === ImpactFactorsTypes.CO2,
    )
  const refurbishedDeviceImpactFactorsRawMaterials =
    refurbishedDeviceImpactFactors?.factors?.find(
      ({ name }) => name === ImpactFactorsTypes.RAW_MATERIAL,
    )
  const refurbishedDeviceImpactFactorsWater =
    refurbishedDeviceImpactFactors?.factors?.find(
      ({ name }) => name === ImpactFactorsTypes.WATER,
    )

  const prolongedDeviceImpactFactors = impactFactors.find(
    ({ status }) => status === ImpactFactorsStatuses.PROLONG,
  )

  const prolongedDeviceImpactFactorsCo2 =
    prolongedDeviceImpactFactors?.factors?.find(
      ({ name }) => name === ImpactFactorsTypes.CO2,
    )
  const prolongedDeviceImpactFactorsRawMaterials =
    prolongedDeviceImpactFactors?.factors?.find(
      ({ name }) => name === ImpactFactorsTypes.RAW_MATERIAL,
    )
  const prolongedDeviceImpactFactorsWater =
    prolongedDeviceImpactFactors?.factors?.find(
      ({ name }) => name === ImpactFactorsTypes.WATER,
    )

  return {
    datasets: [
      {
        color: 'green',
        label: intl.formatMessage({
          id: 'ImpactTracker_refurbished_device',
        }),
        valueCo2: refurbishedDeviceImpactFactorsCo2?.value ?? 0,
        valueKilometers: refurbishedDeviceImpactFactorsCo2?.valueConverted ?? 0,
        valueRawMaterial:
          refurbishedDeviceImpactFactorsRawMaterials?.value ?? 0,
        valueShowers: refurbishedDeviceImpactFactorsWater?.valueConverted ?? 0,
        valueWater: refurbishedDeviceImpactFactorsWater?.value ?? 0,
      },
      {
        color: 'light-green',
        label: intl.formatMessage({
          id: 'ImpactTracker_prolonging_device',
        }),
        valueCo2: prolongedDeviceImpactFactorsCo2?.value ?? 0,
        valueKilometers: prolongedDeviceImpactFactorsCo2?.valueConverted ?? 0,
        valueRawMaterial: prolongedDeviceImpactFactorsRawMaterials?.value ?? 0,
        valueShowers: prolongedDeviceImpactFactorsWater?.valueConverted ?? 0,
        valueWater: prolongedDeviceImpactFactorsWater?.value ?? 0,
      },
    ],
    totalCo2:
      newDeviceImpactFactors?.factors?.find(
        ({ name }) => name === ImpactFactorsTypes.CO2,
      )?.value ?? 0,
    totalRawMaterial:
      newDeviceImpactFactors?.factors?.find(
        ({ name }) => name === ImpactFactorsTypes.RAW_MATERIAL,
      )?.value ?? 0,
    totalWater:
      newDeviceImpactFactors?.factors?.find(
        ({ name }) => name === ImpactFactorsTypes.WATER,
      )?.value ?? 0,
  }
}
