import { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Heading, PrimaryButton } from 'Components/_theme'
import { LanguageDropdown } from 'Components/LanguageDropdown/LanguageDropdown'

import { setRouteTitle } from 'Portal/src/router/Router.utils'

export const PaymentFailedContent = () => {
  const intl = useIntl()

  useEffect(() => {
    setRouteTitle(
      intl.formatMessage({
        id: 'PaymentFailedContent_heading',
      }),
    )
  }, [intl])

  return (
    <>
      <Heading className="PurchaseContent-heading" size={1}>
        <FormattedMessage id="PaymentFailedContent_heading" />
      </Heading>

      <div className="PurchaseContent-content">
        <p>
          <FormattedMessage id="PaymentFailedContent_content_line1" />
        </p>

        <p>
          <FormattedMessage id="PaymentFailedContent_content_line2" />
        </p>
      </div>

      <div>
        <PrimaryButton>
          <FormattedMessage id="PaymentFailedContent_cta" />
        </PrimaryButton>
      </div>

      <LanguageDropdown isLandingPage />
    </>
  )
}
