import { useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { GroupBase } from 'react-select'
import { useLazyQuery } from '@apollo/client'
import { observer } from 'mobx-react'

import { Field } from 'Components/Field/Field'
import { FieldTypeEnum } from 'Components/Field/Field.types'
import { SelectOption } from 'Components/Select/Select.types'

import {
  getReturnDepots,
  getReturnDepotsVariables,
} from 'Constants/graphql/queries/listing/__generated__/getReturnDepots'
import { GET_RETURN_DEPOTS } from 'Constants/graphql/queries/listing/GetReturnDepots'

import { useActiveUser } from 'Portal/src/hooks'

import { ReturnDepotsSelectProps } from './ReturnDepotsSelect.types'

export const ReturnDepotsSelect = observer(
  <
    Option extends SelectOption,
    IsMulti extends boolean,
    Group extends GroupBase<Option> = GroupBase<Option>,
  >(
    props: ReturnDepotsSelectProps<Option, IsMulti, Group>,
  ) => {
    const { name, ...rest } = props
    const [depotOptions, setDepotOptions] = useState<SelectOption[]>([])
    const { activeOrganisation } = useActiveUser()
    const { setValue } = useFormContext()

    const [getReturnDepotsData, { data, loading: isLoading }] = useLazyQuery<
      getReturnDepots,
      getReturnDepotsVariables
    >(GET_RETURN_DEPOTS, {
      onCompleted: data => {
        const parsedDepots: SelectOption[] =
          data.organisation.returnDepots?.depots?.map(depot => ({
            label: depot.locationName,
            value: depot.id.toString(),
          })) ?? []

        // select first depot on load
        if (parsedDepots.length > 0) {
          setValue(name, parsedDepots[0])
        }

        setDepotOptions(parsedDepots)
      },
      variables: {
        id: activeOrganisation?.id!,
      },
    })

    useEffect(() => {
      if (activeOrganisation) {
        getReturnDepotsData()
      }
    }, [activeOrganisation, getReturnDepotsData])

    const defaultNoOptionsMessage = useCallback(
      () => <FormattedMessage id="No_depot_found" />,
      [],
    )

    if (!data?.organisation.returnDepots?.depots?.length) {
      return null
    }

    return (
      <Field
        {...rest}
        name={name}
        selectProps={{
          isClearable: false,
          isLoading,
          noOptionsMessage: defaultNoOptionsMessage,
          options: depotOptions,
        }}
        type={FieldTypeEnum.select}
      />
    )
  },
)
