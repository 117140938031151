import { useCallback } from 'react'
import { useMountEffect } from '@react-hookz/web'
import { observer } from 'mobx-react'

import { ScrollToTop } from 'Components/ScrollToTop/ScrollToTop'

import { useStore } from 'Stores/index'
import { OrderStepEnum } from 'Stores/orderStore/orderStore.types'

import { OrderSummary } from '../../OrderSummary/OrderSummary'
import { SummaryStepProps } from '../Steps.types'

export const SummaryStep = observer((props: SummaryStepProps) => {
  const {
    setCurrentOrderStep,
    handleOrderSubmit,
    isSubmitLoading,
    activeEmployeeId,
    handlePreviousStep,
    isEarlyUpgrade,
    isNewOrder,
  } = props
  const { orderStore, rentalStore } = useStore()
  const { rentalDetails } = rentalStore
  const {
    chosenShippingAddress,
    chosenAccessories,
    netPriceCalculator,
    setNetPriceCalculator,
    chosenDevice,
    getTotalPrice,
    setAccessoryTextField,
    setAccessoryQuantity,
    setChosenDeviceTextField,
    setAddonDeviceTextField,
    setChosenDeviceQuantity,
    setCurrentStep,
    setExternalId,
    setPhonePlanDetails,
    orderDevices,
    orderExternalId,
    phonePlanDetails,
  } = orderStore

  const totalPrice = getTotalPrice || {
    totalBasis: 0,
    totalCalculatedNet: 0,
    totalWithTax: 0,
    totalWithoutTax: 0,
  }

  const handlePreviousAction = useCallback(() => {
    if (handlePreviousStep) {
      handlePreviousStep()
    }
  }, [handlePreviousStep])

  useMountEffect(() => {
    setCurrentStep(OrderStepEnum.summary)
  })

  return (
    <>
      <ScrollToTop />

      <OrderSummary
        activeEmployeeId={activeEmployeeId}
        chosenAccessories={chosenAccessories}
        chosenDevice={chosenDevice}
        chosenShippingAddress={chosenShippingAddress}
        earlyUpgradeFee={rentalDetails?.rental.upgradeAmount || 0}
        isEarlyUpgrade={isEarlyUpgrade}
        isLoading={isSubmitLoading}
        isNewOrder={isNewOrder}
        onBackButtonClick={handlePreviousAction}
        onChangeDeviceButtonClick={() => {
          setNetPriceCalculator({
            ...netPriceCalculator,
            collapse: false,
          })

          if (setCurrentOrderStep) {
            setCurrentOrderStep(1)
          }
        }}
        onOrderButtonClick={handleOrderSubmit}
        onSetAccessoryQuantity={(quantity, accessory) => {
          setAccessoryQuantity(quantity, accessory)
        }}
        onSetAccessoryTextField={(key, value, accessory) => {
          setAccessoryTextField(key, value, accessory)
        }}
        onSetAddonDeviceTextField={(key, value, addonDeviceId) => {
          setAddonDeviceTextField(key, value, addonDeviceId)
        }}
        onSetChosenDeviceQuantity={quantity => {
          setChosenDeviceQuantity(quantity)
        }}
        onSetChosenDeviceTextField={(field, value) => {
          setChosenDeviceTextField(field, value)
        }}
        onSetExternalId={value => {
          setExternalId(value)
        }}
        onSetPhonePlanDetails={value => {
          setPhonePlanDetails(value)
        }}
        orderDeviceResponseVariant={orderDevices?.variant}
        orderExternalId={orderExternalId}
        phonePlanDetails={phonePlanDetails}
        totalPrice={totalPrice}
      />
    </>
  )
})
