import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import clsx from 'clsx'
import { DateTime } from 'luxon'

import { Badge } from 'Components/_theme'
import { Icon } from 'Components/Icon/Icon'

import { TRACKING_STATUS_MODEL } from 'Constants/trackingStatus'

import { TrackingStatusEnum } from 'Types/trackingStatus/trackingStatus.types'

import { DeliveryStatusItemProps } from './DeliveryStatusItem.types'

export const DeliveryStatusItem = (props: DeliveryStatusItemProps) => {
  const {
    isCurrent,
    status,
    timestamp,
    isDelivered,
    isDone,
    type,
    trackingURL,
  } = props

  const classNames = clsx(
    'DeliveryStatusItem',
    isDone && status !== TrackingStatusEnum.failure
      ? 'DeliveryStatusItem-done'
      : 'DeliveryStatusItem-pending',
    status === TrackingStatusEnum.failure && 'DeliveryStatusItem-failure',
  )

  const renderTrackingURL = useMemo(() => {
    if (trackingURL) {
      return (
        <a
          className="fw-bold"
          href={trackingURL}
          rel="noreferrer"
          target="_blank"
        >
          <FormattedMessage id="See_delivery_status" />
        </a>
      )
    }

    return null
  }, [trackingURL])

  return (
    <div className="d-flex align-items-center">
      <div className={classNames}>
        <div className="DeliveryStatusItem-connector-container">
          {status !== 'transit' && (
            <div className="DeliveryStatusItem-connector" />
          )}
        </div>

        <div className="DeliveryStatusItem-wrapper">
          <div className="DeliveryStatusItem-container">
            <Icon icon={TRACKING_STATUS_MODEL[type][status].icon} size={20} />
          </div>

          <div className="ms-4 d-flex justify-content-center flex-column">
            <FormattedMessage
              id={
                TRACKING_STATUS_MODEL[type][status].translationId[
                  isDelivered ? 'delivered' : 'transit'
                ]
              }
            />

            <div className="DeliveryStatusItem-timestamp">
              {timestamp
                ? DateTime.fromISO(timestamp, {
                    zone: 'UTC',
                  }).toLocaleString(DateTime.DATETIME_SHORT)
                : renderTrackingURL}
            </div>
          </div>

          {isCurrent && (
            <div className="d-flex align-items-center ms-auto">
              <Badge color="light">
                <FormattedMessage id="Current" />
              </Badge>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
