import { useMemo } from 'react'
import { GroupBase } from 'react-select'
import { observer } from 'mobx-react'

import { Field } from 'Components/Field/Field'
import { FieldTypeEnum } from 'Components/Field/Field.types'
import { SelectOption } from 'Components/Select/Select.types'

import { useCountryList } from 'Hooks'

import { useShippingLabelCountryList } from 'Portal/src/hooks/useShippingLabelCountryList/useShippingLabelCountryList'

import { CountrySelectProps } from './CountrySelect.types'

export const CountrySelect = observer(
  <
    Option extends SelectOption,
    IsMulti extends boolean,
    Group extends GroupBase<Option> = GroupBase<Option>,
  >(
    props: CountrySelectProps<Option, IsMulti, Group>,
  ) => {
    const { isShippingLabelList, disabled, ...rest } = props

    const countryList = useCountryList()
    const shippingLabelCountryList = useShippingLabelCountryList()

    const countryOptions = useMemo(() => {
      if (isShippingLabelList) {
        return Object.keys(shippingLabelCountryList).map(isoCode => ({
          label: shippingLabelCountryList[isoCode],
          value: isoCode,
        }))
      }

      return Object.keys(countryList).map(isoCode => ({
        label: countryList[isoCode],
        value: isoCode,
      }))
    }, [countryList, isShippingLabelList, shippingLabelCountryList])

    return (
      <Field
        {...rest}
        selectProps={{
          isClearable: true,
          isDisabled: disabled,
          options: countryOptions,
        }}
        type={FieldTypeEnum.select}
      />
    )
  },
)
