import { FormattedMessage } from 'react-intl'

import { Card, CardBody, CardHeader } from 'Components/_theme'

import { QRCodeProps } from './QRCodeCard.types'

export const QRCodeCard = (props: QRCodeProps) => {
  const { qrCode } = props

  return (
    <Card className="mb-4">
      <CardHeader>
        <FormattedMessage id="QRCodeCard_heading" />
      </CardHeader>

      <CardBody>
        <img
          alt="QR Code"
          height={128}
          src={`data:image/png;base64,${qrCode}`}
          width={128}
        />
      </CardBody>
    </Card>
  )
}
