import { Controller, useFormContext } from 'react-hook-form'

import { RadioButtonGroup } from 'Components/_theme/RadioButtonGroup/RadioButtonGroup'

import { FieldRadioButtonGroupProps } from './Field.types'

export const FieldRadioButtonGroup = (props: FieldRadioButtonGroupProps) => {
  const { hasError, name, radioButtonGroupProps } = props

  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, value, ...field } }) => (
        <RadioButtonGroup
          ref={ref}
          {...field}
          {...radioButtonGroupProps}
          defaultValue={value}
          isInvalid={hasError}
        />
      )}
    />
  )
}
