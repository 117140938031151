import { Image } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'

import { ButtonGroup, Card, CardBody, Heading, Text } from 'Components/_theme'

import routeGuardBG from 'Assets/images/new-order-route-guard.svg'

import { OrderGuardModalContentProps } from './OrderGuardModal.types'

export const OrderGuardModalContent = (props: OrderGuardModalContentProps) => {
  const { onContinue, onLeave } = props

  const intl = useIntl()

  return (
    <Card className="text-center">
      <CardBody>
        <Image fluid src={routeGuardBG} />

        <Heading className="mt-9 mb-2" size={4}>
          <FormattedMessage id="RouteGuard_heading" />
        </Heading>

        <Text className="pb-6">
          <FormattedMessage id="RouteGuard_subheading" />
        </Text>

        <ButtonGroup
          leftButton={{
            onClick: onLeave,
            text: intl.formatMessage({ id: 'RouteGuard_leave_page' }),
          }}
          rightButton={{
            onClick: onContinue,
            text: intl.formatMessage({ id: 'RouteGuard_continue' }),
          }}
        />
      </CardBody>
    </Card>
  )
}
