import { forwardRef, useState } from 'react'
import { useDebouncedEffect, usePrevious } from '@react-hookz/web'

import { Input } from 'Components/_theme'

import { DebouncedInputProps } from './DebouncedInput.types'

export const DebouncedInput = forwardRef<HTMLInputElement, DebouncedInputProps>(
  (props, ref) => {
    const {
      delay = 500,
      onChange,
      value: inputValue,
      min,
      max,
      ...restProps
    } = props
    const [value, setValue] = useState(inputValue)
    const previousValue = usePrevious(value)

    const handleInputOnChange = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const { value } = event.target

      if (!Number.isNaN(Number(value))) {
        const numValue = Number(value)

        if (min !== undefined && numValue < Number(min)) {
          return
        }

        if (max !== undefined && numValue > Number(max)) {
          return
        }
      }

      setValue(value)
    }

    useDebouncedEffect(
      () => {
        // Prevents onChange from being called on initial render
        if (value === previousValue || previousValue === undefined) {
          return
        }

        onChange(value ?? '')
      },
      [value],
      delay,
    )

    return (
      <Input
        ref={ref}
        {...restProps}
        max={max}
        min={min}
        onChange={handleInputOnChange}
        value={value}
      />
    )
  },
)
