import { useEffect, useMemo, useState } from 'react'
import { Button, Dropdown } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import {
  faBuildings,
  faChevronDown,
  faChevronUp,
  faRightFromBracket,
  faUser,
} from '@fortawesome/pro-solid-svg-icons'
import { clsx } from 'clsx'
import { observer } from 'mobx-react'

import { Heading } from 'Components/_theme'
import { Icon } from 'Components/Icon/Icon'

import { useStore } from 'Stores/index'

import { useActiveUser, useIsOrganisation } from 'Hooks'

import { handleNewJoinerLogout } from 'Utilities'

import {
  E2E_ACL_DROPDOWN,
  E2E_ACL_DROPDOWN_CONTENT,
  E2E_LOGOUT,
} from 'Constants/e2e'

import { UserModeEnum } from 'Portal/src/stores/portalSettingsStore/portalSettingsStore.types'

import { ACLDropdownProps } from './ACLDropdown.types'

export const ACLDropdown = observer((props: ACLDropdownProps) => {
  const { avatar, username, aclEmployees, aclOrganisations } = props

  const { portalSettingsStore } = useStore()
  const { userMode } = portalSettingsStore

  const { activeEmployee, activeOrganisation } = useActiveUser()
  const isOrganisation = useIsOrganisation()
  const intl = useIntl()

  const [dropdownOpen, setDropDownOpen] = useState(false)
  const [activeId, setActiveId] = useState('')

  const hasEmployee = !!aclEmployees.length
  const hasOrganisation = !!aclOrganisations.length
  const hasMoreThanOneEmployee = aclEmployees.length > 1
  const hasMoreThanOneOrganisation = aclOrganisations.length > 1
  const hasOnlyOneEmployee = aclEmployees.length === 1
  const hasOnlyOneOrganisation = aclOrganisations.length === 1

  useEffect(() => {
    if (activeOrganisation) {
      setActiveId(activeOrganisation.id)
    }

    if (activeEmployee) {
      setActiveId(activeEmployee.id)
    }
  }, [activeEmployee, activeOrganisation])

  const toggleDropdown = () => {
    setDropDownOpen(!dropdownOpen)
  }

  const handleLogout = (isNewJoiner: boolean) => {
    if (isNewJoiner) {
      handleNewJoinerLogout()
    }

    localStorage.removeItem('theme')
  }

  const renderUserAvatar = useMemo(() => {
    if (avatar) {
      return (
        <img
          alt={intl.formatMessage({ id: 'Img_alt_org_logo' })}
          src={avatar}
        />
      )
    }

    return (
      <Icon
        className="profile-dropdown-icon"
        icon={isOrganisation ? faBuildings : faUser}
      />
    )
  }, [avatar, intl, isOrganisation])

  const renderEmployees = useMemo(() => {
    if (hasMoreThanOneEmployee || (hasOnlyOneEmployee && hasOrganisation)) {
      return (
        <>
          <div className="dropdown-header noti-title">
            <Heading as={6} className="text-overflow m-0" size={2}>
              <FormattedMessage id="Employees" />
            </Heading>
          </div>

          {aclEmployees.map(employee => (
            <Link
              key={employee.id}
              className={clsx(
                'acl-employees-dropdown dropdown-item notify-item',
                employee.id === activeId &&
                  activeEmployee &&
                  'dropdown-item--active',
              )}
              to={employee.redirectTo}
            >
              <div className="d-flex justify-between align-items-center">
                {employee.img ? (
                  <div className="organisation-img d-flex align-items-center ACLDropdown-icon">
                    <img alt={employee.label} src={employee.img} />
                  </div>
                ) : (
                  <Icon
                    className="profile-dropdown-icon organisation-img ACLDropdown-icon"
                    icon={employee.icon}
                    size={24}
                  />
                )}

                <span>{employee.label}</span>
              </div>
            </Link>
          ))}

          {!hasOrganisation && <hr />}
        </>
      )
    }

    return null
  }, [
    aclEmployees,
    activeEmployee,
    activeId,
    hasMoreThanOneEmployee,
    hasOnlyOneEmployee,
    hasOrganisation,
  ])

  const renderOrganisations = useMemo(() => {
    if (hasMoreThanOneOrganisation || (hasOnlyOneOrganisation && hasEmployee)) {
      return (
        <>
          <div className="dropdown-header noti-title">
            <Heading as={6} className="text-overflow m-0" size={2}>
              <FormattedMessage id="Organisations" />
            </Heading>
          </div>

          {aclOrganisations.map(organisation => (
            <Link
              key={organisation.id}
              className={clsx(
                'acl-employees-dropdown dropdown-item notify-item',
                organisation.id === activeId &&
                  activeOrganisation &&
                  'dropdown-item--active',
              )}
              to={organisation.redirectTo}
            >
              <div className="d-flex justify-between align-items-center">
                {organisation.img ? (
                  <div className="organisation-img d-flex align-items-center ACLDropdown-icon">
                    <img alt={organisation.label} src={organisation.img} />
                  </div>
                ) : (
                  <Icon
                    className="profile-dropdown-icon organisation-img ACLDropdown-icon"
                    icon={organisation.icon}
                    size={24}
                  />
                )}

                <span>{organisation.label}</span>
              </div>
            </Link>
          ))}
        </>
      )
    }

    return null
  }, [
    aclOrganisations,
    activeId,
    activeOrganisation,
    hasEmployee,
    hasMoreThanOneOrganisation,
    hasOnlyOneOrganisation,
  ])

  const renderLogoutButton = useMemo(() => {
    const content = (
      <>
        <Icon className="ACLDropdown-icon" icon={faRightFromBracket} />

        <FormattedMessage id="Logout" />
      </>
    )

    if (userMode === UserModeEnum.DEFAULT) {
      return (
        <a
          className="dropdown-item notify-item"
          data-e2e={E2E_LOGOUT}
          href="/sicherheit/portal/logout"
          onClick={() => handleLogout(false)}
          title={intl.formatMessage({ id: 'Logout' })}
        >
          {content}
        </a>
      )
    }

    return (
      <Button
        aria-label={intl.formatMessage({ id: 'Logout' })}
        className="btn-link btn-logout dropdown-item notify-item"
        data-e2e={E2E_LOGOUT}
        onClick={() => handleLogout(true)}
      >
        {content}
      </Button>
    )
  }, [intl, userMode])

  return (
    <Dropdown
      align="end"
      className="ACLDropdown"
      onToggle={toggleDropdown}
      show={dropdownOpen}
    >
      <Dropdown.Toggle
        aria-expanded={dropdownOpen}
        aria-label={intl.formatMessage({ id: 'Select_org_or_employee' })}
        as="button"
        className="nav-link dropdown-toggle nav-user dropdown-arrow-none me-0"
        data-e2e={E2E_ACL_DROPDOWN}
        data-toggle="dropdown"
        onClick={toggleDropdown}
      >
        <div className="acl-dropdown d-flex flex-row align-items-center">
          <div className="acl-dropdown-img d-flex align-items-center justify-content-center ACLDropdown-icon">
            {renderUserAvatar}
          </div>

          <div className="d-flex overflow-hidden">
            <span className="account-user-name text-ellipsis" title={username}>
              {username}
            </span>

            <Icon
              className="d-none d-md-block ms-2 mb-1"
              icon={dropdownOpen ? faChevronUp : faChevronDown}
              size={12}
            />
          </div>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu
        aria-label={`${intl.formatMessage({ id: 'Organisations' })} ${intl.formatMessage({ id: 'Employees' })} `}
        className="dropdown-menu-animated topbar-dropdown-menu profile-dropdown"
        data-e2e={E2E_ACL_DROPDOWN_CONTENT}
        onClick={toggleDropdown}
        role="navigation"
      >
        <div className="dropdown-container">
          {renderEmployees}

          {hasOrganisation && hasMoreThanOneEmployee && <hr />}

          {renderOrganisations}
        </div>

        {hasMoreThanOneOrganisation && <hr />}

        {renderLogoutButton}
      </Dropdown.Menu>
    </Dropdown>
  )
})
