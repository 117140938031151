import { forwardRef } from 'react'
import { clsx } from 'clsx'

import { CheckboxProps } from './Checkbox.types'

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => {
    const { className, disabled, id, isInvalid, label, ...restProps } = props

    const classNames = clsx(
      'Checkbox',
      disabled && 'Checkbox-disabled',
      isInvalid && 'Checkbox-is-invalid',
      className,
    )

    return (
      <label className={classNames} htmlFor={id}>
        <input
          ref={ref}
          disabled={disabled}
          id={id}
          type="checkbox"
          {...restProps}
        />

        {label}
      </label>
    )
  },
)
