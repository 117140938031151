import { useMemo } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { faChevronRight, faLeaf } from '@fortawesome/pro-solid-svg-icons'
import { observer } from 'mobx-react'

import {
  Card,
  CardBody,
  CardHeader,
  LinkButton,
  SecondaryButton,
  Text,
} from 'Components/_theme'
import { Icon } from 'Components/Icon/Icon'

import { useIsOrganisation } from 'Hooks'

import { isActiveLanguage } from 'Utilities'

import { getOrganisation_organisation_impactFactors } from 'Constants/graphql/queries/organisation/__generated__/getOrganisation'
import { SupportedLanguagesEnum } from 'Constants/locale'

import ImpactTrackerImg from 'Assets/images/layout/impact_tracker.svg'

import { useStore } from 'Portal/src/stores'

import {
  IMPACT_TRACKER_HELP_URL_DE,
  IMPACT_TRACKER_HELP_URL_EN,
} from './ImpactTracker.contants'
import { mapOrganisationImpactFactorsToImpactTrackerChartData } from './ImpactTracker.util'
import { ImpactTrackerChart } from './ImpactTrackerChart'

export const ImpactTracker = observer(() => {
  const intl = useIntl()
  const { portalConfigStore, organisationStore, employeeStore } = useStore()
  const { portalConfig } = portalConfigStore
  const { organisation } = organisationStore
  const { employee } = employeeStore

  const isOrganisation = useIsOrganisation()

  const isGermanLanguageActive = isActiveLanguage(SupportedLanguagesEnum.DE)

  const helpUrl = useMemo(
    () =>
      isGermanLanguageActive
        ? IMPACT_TRACKER_HELP_URL_DE
        : IMPACT_TRACKER_HELP_URL_EN,
    [isGermanLanguageActive],
  )

  const data = isOrganisation
    ? organisation?.impactFactors
    : employee?.organisation?.impactFactors

  const showImpactTracker = portalConfig?.impactTracker && data

  if (!showImpactTracker) {
    return null
  }

  return (
    <Card className="mb-6 ImpactTracker d-none d-xxl-block">
      <CardHeader
        icon={faLeaf}
        rightContent={
          <OverlayTrigger
            offset={[-110, 10]}
            overlay={
              <Popover id="ImpactTracker-popover">
                <Popover.Body className="ImpactTrackerPopoverContent">
                  <FormattedMessage id="ImpactTracker_popover_desc" />
                </Popover.Body>
              </Popover>
            }
            placement="bottom"
            trigger="focus"
          >
            <div className="ms-4">
              <SecondaryButton
                aria-label={intl.formatMessage({
                  id: 'ImpactTracker_popover_desc',
                })}
                asIcon={faLeaf}
                onClick={event => {
                  event.stopPropagation()
                  // Safari fix: focus needs to be triggered manualy to open bootstrap popover
                  event.currentTarget.focus()
                }}
                size="small"
              />
            </div>
          </OverlayTrigger>
        }
      >
        <FormattedMessage id="ImpactTracker_title" />
      </CardHeader>

      <CardBody>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <Text>
              <FormattedMessage id="ImpactTracker_desc" />
            </Text>
            <LinkButton
              asExternalLink
              className="mt-2"
              href={helpUrl}
              target="_blank"
            >
              <FormattedMessage id="ImpactTracker_popover_link" />
              <Icon
                className="ImpactTrackerOutgoingIcon ms-1"
                icon={faChevronRight}
                size={16}
              />
            </LinkButton>
          </div>

          <img
            alt={intl.formatMessage({
              id: 'Img_alt_impact_tracker',
            })}
            src={ImpactTrackerImg}
          />
        </div>

        <div className="mt-6">
          <ImpactTrackerChart
            data={mapOrganisationImpactFactorsToImpactTrackerChartData(
              data as getOrganisation_organisation_impactFactors[],
              intl,
            )}
            interactive={false}
          />
        </div>
      </CardBody>
    </Card>
  )
})
