import { clsx } from 'clsx'

import { TextProps } from './Text.types'

export const Text = (props: TextProps) => {
  const { className, children } = props

  const classNames = clsx('Text', className)

  return <p className={classNames}>{children}</p>
}
