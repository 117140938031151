import { GetRedirectUrlArgs } from 'Modules/order/components/OrderWrapper/OrderWrapper.types'

export const getRedirectUrl = (args: GetRedirectUrlArgs) => {
  const {
    orderFlowType,
    portalConfig,
    isOrganisation,
    employeeId,
    rentalDetails,
    isUpgradeFromProlongingPage,
    activeEmployee,
    orderDevices,
    isOrderDisabled,
  } = args

  if (isOrderDisabled) {
    return '/'
  }

  // Check conditions block only when backend returns some devices to choose from
  if (
    (orderDevices?.allDevices && orderDevices?.allDevices.length > 0) ||
    (orderDevices?.groupedDevices && orderDevices?.groupedDevices?.length > 0)
  ) {
    if (orderFlowType === 'NEW_ORDER') {
      // logged user portal config disallows new order
      if (!portalConfig?.newDevice) {
        return '/'
      }

      // Organisation cant order new device
      // Do not trigger when organisation performs "Rent new device" action for specific employee
      if (
        isOrganisation &&
        !employeeId &&
        !portalConfig?.showNewDeviceButtonToAdmin
      ) {
        return '/'
      }
    }

    // performs early upgrade on device when the action is disabled
    if (
      orderFlowType === 'EARLY_UPGRADE' &&
      !rentalDetails?.rental.actions.showEarlyUpgradeAction
    ) {
      return '/'
    }

    // performs prolong on device when the action is disabled
    if (
      orderFlowType === 'PROLONG' &&
      !rentalDetails?.rental.actions.showDeviceProlongingAction
    ) {
      return '/'
    }

    // performs upgrade on device when the action is disabled (allow upgrade when user performs prolonging)
    if (
      orderFlowType === 'UPGRADE' &&
      !rentalDetails?.rental.actions.showStandardUpgradeAction &&
      !isUpgradeFromProlongingPage
    ) {
      return '/'
    }

    // User tries to prolong/early/upgrade device from different employee than the device owner
    if (
      orderFlowType !== 'NEW_ORDER' &&
      activeEmployee &&
      activeEmployee.id !== rentalDetails?.rental.employee?.id
    ) {
      return '/'
    }
    // END: Check conditions block only when backend returns some devices to choose from
  }

  return null
}
