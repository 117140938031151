import { gql } from '@apollo/client'

export const GET_ADDRESS_VALIDATION = gql`
  query getAddressValidation(
    $address: AddressInput!
    $addressID: ID
    $organisationID: ID
  ) {
    addressValidation(
      address: $address
      addressID: $addressID
      organisationID: $organisationID
    ) {
      ... on AddressValidationSuccess {
        __typename
      }

      ... on AddressValidationProblem {
        __typename
        invalidInputs {
          field
          message
        }
      }
    }
  }
`
