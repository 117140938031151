import { IntlShape } from 'react-intl'

export const parseLabel = (
  intl: IntlShape,
  label?: string,
  shouldTranslate?: boolean,
) => {
  if (!label) {
    return ''
  }

  return shouldTranslate ? intl.formatMessage({ id: label }) : label
}
