import { clsx } from 'clsx'

import { HeadingProps } from './Heading.types'

export const Heading = (props: HeadingProps) => {
  const {
    as,
    children,
    className,
    color = 'default',
    size,
    ...restProps
  } = props

  const HeadingTag = `h${size}` as keyof JSX.IntrinsicElements

  const classNames = clsx(
    color !== 'default' && `Heading-${color}`,
    as && `h${as}`,
    className,
  )

  return (
    <HeadingTag className={classNames} {...restProps}>
      {children}
    </HeadingTag>
  )
}
