import { IntlShape } from 'react-intl'

import { ShippingAddressFormAddressTypeConfig } from 'Components/ShippingAddressForm/ShippingAddressForm.types'
import { getOrderAddressConfigFlags } from 'Components/ShippingAddressForm/ShippingAddressForm.utils'
import { shippingFormDefaultValues } from 'Components/ShippingAddressForm/ShippingAddressForm.values'

import {
  createSelectOption,
  getCityAndStateAbbr,
  getFullContactName,
  parseStreetString,
} from 'Utilities'

import { ADDRESSES } from 'Constants/constants'
import { getPortalConfig_portalConfigStoreForUserAndOrg } from 'Constants/graphql/queries/detail/__generated__/getPortalConfig'
import { getEmployee_employee } from 'Constants/graphql/queries/employee/__generated__/getEmployee'
import { getEmployeeContact_employee_contact } from 'Constants/graphql/queries/employee/__generated__/getEmployeeContact'
import { getVendingMachineData_checkVendingMachineAvailability_vendingMachines } from 'Constants/graphql/queries/feature/__generated__/getVendingMachineData'
import { getOrganisation_organisation } from 'Constants/graphql/queries/organisation/__generated__/getOrganisation'

import { ShippingAddressTypeEnum } from 'Types/address/address.types'

import { OrderAddressFields } from 'Portal/src/stores/orderStore/orderStore.types'
import { UserModeEnum } from 'Portal/src/stores/portalSettingsStore/portalSettingsStore.types'

export const parseAddressTypeRadioButtonsForOrder = (
  intl: IntlShape,
  userMode: `${UserModeEnum}`,
  portalConfig: getPortalConfig_portalConfigStoreForUserAndOrg | null,
  organisation: getOrganisation_organisation | null,
  employee: getEmployee_employee | null,
  countryList: { [k: string]: string },
  officeAddress?: OrderAddressFields | null,
  employeeContact?: getEmployeeContact_employee_contact | null,
  vendingMachines?:
    | getVendingMachineData_checkVendingMachineAvailability_vendingMachines[]
    | null,
): ShippingAddressFormAddressTypeConfig[] => {
  const { canShipToCustomAddress, canShipToOffice } =
    getOrderAddressConfigFlags(userMode, portalConfig)
  const addressTypes: ShippingAddressFormAddressTypeConfig[] = []

  const generateOfficeLabel = () => {
    if (officeAddress?.name) {
      return officeAddress.name
    }

    if (organisation?.shortName) {
      return `${organisation.shortName} ${ADDRESSES.OFFICE_LABEL}`
    }

    if (employee?.organisation?.shortName) {
      return `${employee?.organisation?.shortName} ${ADDRESSES.OFFICE_LABEL}`
    }

    return `${ADDRESSES.OFFICE_LABEL}`
  }

  const generateName = () => {
    if (employee?.office || officeAddress) {
      return employee?.office?.address?.name || officeAddress?.name || ''
    }

    if (organisation?.contact?.address?.name) {
      return organisation.contact.address.name
    }

    if (employee?.organisation?.contact?.address?.name) {
      return employee.organisation.contact.address.name
    }

    return ''
  }

  const generateOfficeNameAddition = () => {
    if (employee?.office || officeAddress) {
      let nameAddition = ''

      if (
        employee?.office?.address?.nameAddition ||
        officeAddress?.nameAddition
      ) {
        nameAddition +=
          employee?.office?.address?.nameAddition ||
          officeAddress?.nameAddition ||
          ''
      }

      if (employee?.contact || employeeContact) {
        if (nameAddition.length > 0) nameAddition += ' '

        nameAddition +=
          getFullContactName(employeeContact || employee?.contact, false) || ''
      }

      return nameAddition
    }

    if (
      employee?.contact ||
      employeeContact ||
      organisation?.contact?.address?.nameAddition ||
      employee?.organisation?.contact?.address?.nameAddition
    ) {
      let nameAddition = ''

      if (
        organisation?.contact?.address?.nameAddition ||
        employee?.organisation?.contact?.address?.nameAddition
      ) {
        nameAddition +=
          organisation?.contact?.address?.nameAddition ||
          employee?.organisation?.contact?.address?.nameAddition ||
          ''
      }

      if (employee?.contact || employeeContact) {
        if (nameAddition.length > 0) nameAddition += ' '

        nameAddition +=
          getFullContactName(employeeContact || employee?.contact, false) || ''
      }

      return nameAddition
    }

    return ''
  }

  const generateCustomAddressName = () => {
    if (employeeContact || employee) {
      return getFullContactName(employeeContact || employee?.contact)
    }

    return officeAddress?.nameAddition || ''
  }

  // add office address
  if (canShipToOffice) {
    const { city, state } = getCityAndStateAbbr(officeAddress?.city)
    const officeAddressStreet = parseStreetString(officeAddress?.street || '')
    const countryLabel = officeAddress?.country
      ? countryList[officeAddress.country]
      : ''

    addressTypes.push({
      _label: generateOfficeLabel(),
      _vendingMachineId: '0',
      addressType: ShippingAddressTypeEnum.OFFICE,
      city,
      country: createSelectOption({
        label: countryLabel,
        value: officeAddress?.country,
      }),
      name: generateName(),
      nameAddition: generateOfficeNameAddition(),
      state,
      street: officeAddressStreet?.streetName || '',
      streetAddition: officeAddress?.streetAddition || '',
      streetNumber: officeAddressStreet?.streetNumber || '',
      usAddress: officeAddress?.street || '',
      zipcode: officeAddress?.zipcode || '',
    })
  }

  // add custom address
  if (canShipToCustomAddress) {
    const countryLabel = officeAddress?.country
      ? countryList[officeAddress.country]
      : ''

    addressTypes.push({
      ...shippingFormDefaultValues,
      _label: intl.formatMessage({
        id: ADDRESSES.CUSTOM_LABEL,
      }),
      country: createSelectOption({
        label: countryLabel,
        value: officeAddress?.country,
      }),
      name: generateCustomAddressName(),
    })
  }

  // add vending machines addresses
  if (vendingMachines && vendingMachines.length > 0) {
    vendingMachines.forEach(vendingMachine => {
      const { city, state } = getCityAndStateAbbr(vendingMachine.address?.city)
      const vendingMachineStreet = parseStreetString(
        vendingMachine.address?.street || '',
      )
      const countryLabel = vendingMachine.address?.country
        ? countryList[vendingMachine.address.country]
        : ''

      addressTypes.push({
        _label: vendingMachine.name,
        _vendingMachineId: vendingMachine.id.toString(),
        addressType:
          `${ShippingAddressTypeEnum.VENDING_MACHINE}-${vendingMachine.addressID}` as ShippingAddressTypeEnum,
        city,
        country: createSelectOption({
          label: countryLabel,
          value: vendingMachine.address?.country,
        }),
        name: vendingMachine.address?.name || '',
        nameAddition: vendingMachine.address?.nameAddition || '',
        state,
        street: vendingMachineStreet.streetName,
        streetAddition: vendingMachine.address?.streetAddition || '',
        streetNumber: vendingMachineStreet.streetNumber,
        usAddress: vendingMachine.address?.street || '',
        zipcode: vendingMachine.address?.zipcode || '',
      })
    })
  }

  return addressTypes
}
