import { cloneDeep } from 'lodash-es'

import type { OrderStore } from 'Stores/orderStore/orderStore'

import { OrderDevice } from 'Portal/src/mappers/orderDeviceMapper/orderDevice.types'

type NetPriceDevice = {
  rentCategoryId: string
  calculatedNet: number
}

export const calculateNetPrices = (orderStore: OrderStore) => {
  const {
    accessories,
    orderDevices,
    netPriceCalculator,
    setAccessories,
    setOrderDevices,
  } = orderStore

  const updatedDevices: OrderDevice[] = []
  const updatedAccessories: OrderDevice[] = []

  fetch(`${window.GO_API_URL}/net-price-calculator`, {
    body: JSON.stringify({
      accessories: accessories?.map(accessory => ({
        copaymentNet: accessory.copaymentNet,
        rentCategoryId: accessory.rentCategoryId,
      })),
      childAllowance: netPriceCalculator.childAllowanceValue,
      churchTax: !!netPriceCalculator.churchTaxValue,
      devices: orderDevices?.allDevices?.map(device => ({
        copaymentNet: device.copaymentNet,
        rentCategoryId: device.rentCategoryId,
      })),
      healthContribution: +netPriceCalculator.healthValue,
      salary: +netPriceCalculator.salaryValue,
      state: netPriceCalculator.stateValue,
      taxClass: netPriceCalculator.taxClassValue,
    }),
    method: 'POST',
  })
    .then(response => response.json())
    .then(
      (response: {
        netPriceDevices: NetPriceDevice[]
        netPriceAccessories: NetPriceDevice[]
      }) => {
        if (orderDevices?.allDevices) {
          orderDevices.allDevices.forEach(device => {
            const updatedDevice = cloneDeep(device)
            const netPriceDevice = response.netPriceDevices.find(
              netPriceDevice =>
                netPriceDevice.rentCategoryId === device.rentCategoryId,
            )

            if (netPriceDevice) {
              updatedDevice.calculatedNet = netPriceDevice.calculatedNet
              updatedDevices.push(updatedDevice)
            }
          })
        }

        if (accessories) {
          accessories.forEach(accessory => {
            const updatedAccessory = cloneDeep(accessory)
            const netPriceAccessory = response.netPriceAccessories.find(
              netPriceAccessory =>
                netPriceAccessory.rentCategoryId === accessory.rentCategoryId,
            )

            if (netPriceAccessory) {
              updatedAccessory.calculatedNet = netPriceAccessory.calculatedNet
              updatedAccessories.push(updatedAccessory)
            }
          })
        }
      },
    )
    .then(() => {
      if (orderDevices) {
        setOrderDevices({
          allDevices: updatedDevices,
          groupedDevices: orderDevices?.groupedDevices || null,
          variant: orderDevices.variant,
        })
      }

      setAccessories(updatedAccessories)
    })
}
