import { FieldPolicy } from '@apollo/client'
import { SafeReadonly } from '@apollo/client/cache/core/types/common'

/*
  - Apollo will send different models to pagination with different args depending on which object the pagination will be executed. 
  - The future implementation of this merge and pagination will be the addition of generic types.
  - The reference used to create was relayStylePagination from apollo.
  - More information https://github.com/apollographql/apollo-client/blob/master/src/utilities/policies/pagination.ts#L58
*/
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const mergePagination = <T>(
  prev: any,
  next: any,
  args: any,
): SafeReadonly<T> => {
  if (!next) {
    return prev
  }

  // In case of no results on a search/filter/first request there's no need to merge.
  if (
    !prev ||
    args?.pagination.after === 0 ||
    next.totalCount > prev.totalCount
  ) {
    return next
  }

  // Find the array entry and merge it
  const arrEntry = Object.entries(next).find(entry => Array.isArray(entry[1]))

  if (arrEntry) {
    const key = arrEntry[0]

    // Combine the prev and next arrays using the found key.
    if (key && Array.isArray(prev[key]) && Array.isArray(next[key])) {
      return {
        ...prev,
        [key]: [...prev[key], ...next[key]],
        pageInfo: next.pageInfo,
      }
    }
  }

  return next
}
/*
  This pagination police will work for connection structures i.e
  type InvoiceConnection {
    totalCount: Int!
    invoices: [Invoice!]
    pageInfo: PageInfo!
  }
*/
export const pagination = <T>(): FieldPolicy<T, T> => ({
  merge(existing, incoming, { args }): SafeReadonly<T> {
    return mergePagination<T>(existing, incoming, args)
  },
  read(existing): SafeReadonly<T> | undefined {
    return existing
  },
})
