import { Button } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { faTree } from '@fortawesome/pro-solid-svg-icons'

import { Icon } from 'Components/Icon/Icon'
import { ImageWithFallback } from 'Components/ImageWithFallback/ImageWithFallback'

import { E2E_PROLONGING_BANNER_BUTTON } from 'Constants/e2e'

import { DeviceTypeEnum } from 'Types/deviceTypes'

import { RentalProlongingButtonProps } from './RentalProlongingButton.types'

export const RentalProlongingButton = (props: RentalProlongingButtonProps) => {
  const { onClick, deviceName, imageUrl, productType, copaymentAmount } = props

  const intl = useIntl()

  return (
    <Button
      aria-label={intl.formatMessage({
        id: 'OrderProlong_extend_rental_header',
      })}
      className="RentalProlongingButton d-flex flex-row justify-content-center align-items-center my-6 p-6"
      data-e2e={E2E_PROLONGING_BANNER_BUTTON}
      onClick={onClick}
      variant="transparent"
    >
      <div>
        <ImageWithFallback
          alt={deviceName}
          className="RentalProlongingButton-device-image"
          placeholderType={productType as DeviceTypeEnum}
          src={imageUrl || ''}
        />
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center">
        {copaymentAmount ? (
          <p>
            <FormattedMessage id="RentalProlongingButton_save_50" />
          </p>
        ) : (
          <FormattedMessage id="RentalProlongingButton_extend_your_rental" />
        )}
        <div>
          <Icon icon={faTree} size={20} />
          &nbsp;
          <Icon icon={faTree} size={20} />
          &nbsp; <FormattedMessage id="RentalProlongingButton_plant_trees" />
          &nbsp;
          <Icon icon={faTree} size={20} />
          &nbsp;
          <Icon icon={faTree} size={20} />
        </div>
        <p className="mb-0">
          <FormattedMessage id="RentalProlongingButton_additional_12_months" />
        </p>
      </div>
    </Button>
  )
}
