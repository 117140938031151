import { RefObject, useCallback, useEffect } from 'react'

export const useEscapeKeyListenerEffect = (
  action: () => void,
  isActive: boolean,
  listenerRef?: RefObject<HTMLElement>,
) => {
  const handleKeyPress: EventListener = useCallback(
    (evt: Event) => {
      const keyboardEvent = evt as KeyboardEvent

      if (keyboardEvent.key === 'Escape') {
        action()
        keyboardEvent.stopPropagation()
      }
    },
    [action],
  )

  useEffect(() => {
    const current = listenerRef ? listenerRef.current : window

    if (isActive) {
      current?.addEventListener('keydown', handleKeyPress)
    }

    return () => {
      current?.removeEventListener('keydown', handleKeyPress)
    }
  }, [listenerRef, handleKeyPress, isActive])
}
