import { useCallback, useEffect, useMemo } from 'react'
import { Col, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash-es'
import { A11y, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { DashboardServiceAppDevice } from 'Modules/dashboard/components//DashboardServiceAppDevice/DashboardServiceAppDevice'
import { getNewCaseItems } from 'Modules/dashboard/utils/getNewCaseItems.utils'

import { Card, CardBody, Heading } from 'Components/_theme'

import { ServiceAppActionsPopup } from 'Modals/ServiceAppActionsPopup/ServiceAppActionsPopup'

import { useActiveUser, useIsServiceApp, useModal } from 'Hooks'

import { buttonize } from 'Utilities'

import { SLIDER_BREAKPOINTS_3 } from 'Constants/constants'
import { getEmployeeDashboard_employee_rentals_rentals } from 'Constants/graphql/queries/employee/__generated__/getEmployeeDashboard'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import {
  DashboardDeviceSlideTypeEnum,
  DashboardDevicesProps,
} from './DashboardDevices.types'
import { DashboardDeviceSlide } from './DashboardDeviceSlide'

export const DashboardDevices = (props: DashboardDevicesProps) => {
  const { caseItems, header, rentals, renderServiceApp } = props

  const isServiceApp = useIsServiceApp()
  const { activeEmployee } = useActiveUser()
  const history = useHistory()

  const { setModal, hideModal, isModalOpen } = useModal()

  const openServiceAppActionsPopup = useCallback(
    (rental: getEmployeeDashboard_employee_rentals_rentals) => {
      setModal({
        component: ServiceAppActionsPopup,
        isOpen: true,
        props: {
          rentalID: rental.id,
        },
        type: 'popup',
      })
    },
    [setModal],
  )

  useEffect(() => {
    if (!isServiceApp && isModalOpen) {
      hideModal()
    }
  }, [hideModal, isModalOpen, isServiceApp])

  const deviceClickHandler = (
    _event:
      | React.MouseEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
    type: DashboardDeviceSlideTypeEnum,
    rental?: getEmployeeDashboard_employee_rentals_rentals,
    orderId?: string,
  ) => {
    if (type === DashboardDeviceSlideTypeEnum.Request) {
      const orderPath = composePath({
        params: {
          employeeId: activeEmployee?.id,
          orderId,
        },
        path: GLOBAL_ROUTE_PATHS.employee.order,
      })

      history.push(orderPath)

      return
    }

    if (rental) {
      openServiceAppActionsPopup(rental)
    }
  }

  const newCaseItems = caseItems?.caseItems
    ? getNewCaseItems(caseItems?.caseItems)
    : []

  const itemsLength = useMemo(() => {
    let length = 0

    if (newCaseItems?.length) {
      length += newCaseItems.length
    }

    if (rentals?.length) {
      length += rentals.length
    }

    return length
  }, [newCaseItems?.length, rentals?.length])

  if (isEmpty(newCaseItems) && isEmpty(rentals)) {
    return null
  }

  const swiperContent = (
    <Card className="my-devices-container overflow-hidden mb-6">
      <CardBody>
        <Row className="mb-4">
          <Col className="d-flex">
            <Heading as={5} size={2}>
              <FormattedMessage id={header} />
            </Heading>

            <span className="ms-2">
              <FormattedMessage
                id="DevicesCard_total"
                values={{
                  total: itemsLength,
                }}
              />
            </span>
          </Col>
        </Row>

        <Row className="pb-6">
          <Col>
            <Swiper
              breakpoints={SLIDER_BREAKPOINTS_3}
              className="h-100 mt-2"
              modules={[Navigation, Pagination, A11y]}
              navigation
              pagination={{ clickable: true }}
              spaceBetween={10}
            >
              {newCaseItems?.map(caseItem => (
                <SwiperSlide key={caseItem.id}>
                  <DashboardDeviceSlide
                    created={caseItem.created}
                    id={caseItem.id}
                    imageURL={
                      caseItem.data.requestedRentCategory?.deviceDefinition
                        ?.imageURL
                    }
                    name={
                      caseItem.data.requestedRentCategory?.deviceDefinition
                        ?.name ?? 'New device'
                    }
                    productType={
                      caseItem.data.requestedRentCategory?.deviceDefinition
                        ?.productType
                    }
                    status={caseItem.status}
                    type={DashboardDeviceSlideTypeEnum.Request}
                  />
                </SwiperSlide>
              ))}

              {rentals?.map(rental => (
                <SwiperSlide key={rental.id}>
                  <DashboardDeviceSlide
                    id={rental?.id}
                    imageURL={rental?.stocklistItem?.deviceDefinition?.imageURL}
                    imei={rental?.stocklistItem?.imei}
                    isEarlyUpgradable={rental.actions.showEarlyUpgradeAction}
                    isUpgradable={
                      rental.actions.showStandardUpgradeAction ||
                      rental.actions.showDeviceProlongingAction
                    }
                    name={rental?.stocklistItem?.deviceDefinition?.name ?? ''}
                    productType={
                      rental?.stocklistItem?.deviceDefinition?.productType
                    }
                    startAt={rental.startAt}
                    type={DashboardDeviceSlideTypeEnum.Rental}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )

  const serviceAppContent = (
    <div className="mb-4">
      {newCaseItems?.map(caseItem => (
        <div
          key={caseItem.id}
          {...buttonize(
            deviceClickHandler,
            DashboardDeviceSlideTypeEnum.Request,
            null,
            caseItem.id,
          )}
        >
          <DashboardServiceAppDevice
            created={caseItem.created}
            id={caseItem.id}
            imageURL={
              caseItem.data.requestedRentCategory?.deviceDefinition?.imageURL
            }
            name={
              caseItem.data.requestedRentCategory?.deviceDefinition?.name ??
              'New device'
            }
            productType={
              caseItem.data.requestedRentCategory?.deviceDefinition?.productType
            }
            status={caseItem.status}
            type={DashboardDeviceSlideTypeEnum.Request}
          />
        </div>
      ))}

      {rentals?.map(rental => (
        <div
          key={rental.id}
          {...buttonize(
            deviceClickHandler,
            DashboardDeviceSlideTypeEnum.Rental,
            rental,
          )}
        >
          <DashboardServiceAppDevice
            id={rental?.id}
            imageURL={rental?.stocklistItem?.deviceDefinition?.imageURL}
            imei={rental?.stocklistItem?.imei}
            isEarlyUpgradable={rental.actions.showEarlyUpgradeAction}
            isUpgradable={
              rental.actions.showStandardUpgradeAction ||
              rental.actions.showDeviceProlongingAction
            }
            name={rental?.stocklistItem?.deviceDefinition?.name ?? ''}
            productType={rental?.stocklistItem?.deviceDefinition?.productType}
            startAt={rental.startAt}
            type={DashboardDeviceSlideTypeEnum.Rental}
          />
        </div>
      ))}
    </div>
  )

  return isServiceApp && renderServiceApp ? serviceAppContent : swiperContent
}
