import { ReactElement } from 'react'
import { FormattedMessage } from 'react-intl'
import { clsx } from 'clsx'
import { isObject } from 'lodash-es'

import { CopyClipboardButton } from 'Components/CopyClipboardButton/CopyClipboardButton'
import { FormatDate } from 'Components/FormatDate/FormatDate'
import { FormattedCurrency } from 'Components/FormattedCurrency/FormattedCurrency'

import { PreviewColumnContentProps } from './PreviewColumn.types'

export const PreviewColumnContent = (props: PreviewColumnContentProps) => {
  const { content, twoColumn, children } = props

  const isValidValue = (value?: ReactElement | string | null) => {
    if (!value) {
      return false
    }

    if (isObject(value)) {
      if (
        value?.type === FormattedCurrency ||
        value?.type === FormattedMessage
      ) {
        return true
      }

      if (value?.type === FormatDate && !value?.props?.value) {
        return false
      }
    }

    return true
  }

  return (
    <div className="PreviewColumn-content">
      <div
        className={clsx(
          twoColumn
            ? 'PreviewColumn-content-2-column'
            : 'PreviewColumn-content-1-column',
        )}
      >
        {content.map(cell => {
          if (!isValidValue(cell.value)) {
            return null
          }

          return (
            <div
              key={cell.label}
              className="PreviewColumn-content-cell d-flex overflow-hidden align-items-center p-4"
            >
              <div className="text-ellipsis">
                <p className="PreviewColumn-content-cell-title fw-bold mb-0">
                  <FormattedMessage id={cell.label} />
                </p>

                <div className="mb-0 text-ellipsis">{cell.value}</div>
              </div>

              <div className="ms-auto">
                {cell.copyable && (
                  <CopyClipboardButton value={String(cell.value)} />
                )}
              </div>
            </div>
          )
        })}
      </div>

      {children}
    </div>
  )
}
