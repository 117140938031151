import { EMPTY_SELECT_OPTION } from 'Constants/form'
import { US_STATES } from 'Constants/usStates'

import { CityAndStateAbbr } from './getCityAndStateAbbr.types'

export const getCityAndStateAbbr = (city?: string | null): CityAndStateAbbr => {
  if (!city) {
    return {
      city: '',
      state: EMPTY_SELECT_OPTION,
    }
  }

  const commaIdx = city.lastIndexOf(',')
  const citySubstr = city.substring(0, commaIdx)
  const stateSubstr = city.substring(commaIdx + 2)
  const stateListItem = US_STATES.find(
    stateItem =>
      stateItem.label === stateSubstr || stateItem.value === stateSubstr,
  )

  if (!stateListItem) {
    return {
      city,
      state: EMPTY_SELECT_OPTION,
    }
  }

  return {
    city: citySubstr,
    state: {
      label: stateListItem.label,
      value: stateListItem.value.toString(),
    },
  }
}
