import { useEffect } from 'react'
import { useApolloClient } from '@apollo/client'
import { observer } from 'mobx-react'

import { useStore } from 'Stores/index'

import { useUserAuthToken } from 'Hooks'

import { getFullContactName } from 'Utilities'

import { getIntercomUserVerification } from 'Constants/graphql/queries/feature/__generated__/getIntercomUserVerification'
import { GET_INTERCOM_USER_VERIFICATION } from 'Constants/graphql/queries/feature/GetIntercomUserVerification'

import { AppEnvEnum } from 'Types/env.types'

export const IntercomAuthProvider = observer(() => {
  const apolloClient = useApolloClient()
  const authToken = useUserAuthToken()
  const { userStore } = useStore()
  const { user } = userStore

  useEffect(() => {
    const authenticateIntercomUser = async () => {
      const response = await apolloClient.query<getIntercomUserVerification>({
        query: GET_INTERCOM_USER_VERIFICATION,
      })

      if (response.data.intercomUserVerification) {
        const { intercomUserVerification } = response.data

        window.Intercom('update', {
          email: intercomUserVerification.user?.email ?? '',
          is_admin: intercomUserVerification.isAdmin,
          language_override: user.language === 'de' ? 'de' : 'en',
          name: getFullContactName(intercomUserVerification.user),
          user_hash: intercomUserVerification.hashId,
          user_id: intercomUserVerification.user?.id,
        })
      }
    }

    if (!authToken || window.APP_ENV === AppEnvEnum.DEV) {
      return
    }

    authenticateIntercomUser()
  }, [apolloClient, authToken, user])

  return null
})
