import { Col, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons'
import { useMediaQuery } from '@react-hookz/web'
import { isEmpty } from 'lodash-es'
import { observer } from 'mobx-react'

import { Alert } from 'Components/_theme'
import { OrderSelectionCard } from 'Components/OrderSelectionCard/OrderSelectionCard'

import { useStore } from 'Stores/index'

import { isOrderLimitExceeded } from 'Utilities'

import { PORTAL_BREAKPOINTS } from 'Constants/constants'
import { E2E_ORDER_ALERT_CANNOT_ORDER_MORE } from 'Constants/e2e'

import { OrderDisabledAlert } from '../OrderDisabledAlert/OrderDisabledAlert'
import { DeviceChooserProps } from './DeviceChooser.types'

export const DeviceChooser = observer((props: DeviceChooserProps) => {
  const { orderStore } = useStore()
  const { isCYOD } = orderStore
  const { chosenDeviceId, devices, limitationRules, onChooseDevice } = props
  const isFullHDDesktop = useMediaQuery(
    `(min-width: ${PORTAL_BREAKPOINTS.XXXL})`,
  )

  if (!isCYOD) {
    return <OrderDisabledAlert className="py-6" />
  }

  if (isOrderLimitExceeded(limitationRules) || isEmpty(devices)) {
    return (
      <div className="py-6">
        <Alert
          data-e2e={E2E_ORDER_ALERT_CANNOT_ORDER_MORE}
          iconLeft={faCircleInfo}
          variant="info"
        >
          <FormattedMessage id="DeviceChooser_limit_exceeded" />
        </Alert>
      </div>
    )
  }

  const xxlColSizeWithTracker = isFullHDDesktop ? 6 : 9

  return (
    <div className="d-flex flex-wrap pb-6">
      <div className="w-100">
        <Row className="g-4">
          {devices?.map(device => (
            <Col
              key={device.id}
              lg={device.deviceDefinition.impactFactors ? 8 : 4}
              sm={device.deviceDefinition.impactFactors ? 12 : 6}
              xl={device.deviceDefinition.impactFactors ? 6 : 3}
              xs={12}
              xxl={
                device.deviceDefinition.impactFactors
                  ? xxlColSizeWithTracker
                  : 3
              }
            >
              <OrderSelectionCard
                chooseItem={onChooseDevice}
                isSelected={chosenDeviceId === device.id}
                item={device}
                limitationRules={limitationRules}
              />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  )
})
