import { FormattedMessage } from 'react-intl'

import { CarrierAndTrackingIdProps } from './CarrierAndTrackingId.types'

export const CarrierAndTrackingId = (props: CarrierAndTrackingIdProps) => {
  const {
    serviceProvider,
    showTrackingLink,
    showTrackingReference,
    trackingReference,
    trackingURL,
  } = props

  return (
    <div className="d-flex flex-column flex-md-row align-items-md-center">
      {showTrackingReference && (
        <div className="flex-grow-1">
          <div className="mb-3">
            <span className="ShipmentTrackingCard-title fw-normal">
              <FormattedMessage id="Carrier" />
            </span>

            <p className="m-0 pe-2 text-break">{serviceProvider}</p>
          </div>

          <div>
            <span className="ShipmentTrackingCard-title fw-normal">
              <FormattedMessage id="Tracking_reference" />
            </span>

            <p className="m-0 pe-2 text-break">
              {showTrackingLink ? (
                <a
                  className="fw-bold"
                  href={trackingURL}
                  rel="noreferrer"
                  target="_blank"
                >
                  {trackingReference}
                </a>
              ) : (
                trackingReference
              )}
            </p>
          </div>
        </div>
      )}
    </div>
  )
}
