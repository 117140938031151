import { forwardRef, useEffect, useState } from 'react'
import { clsx } from 'clsx'

import { RadioButton } from '../RadioButton/RadioButton'
import { RadioButtonSizeEnum } from '../RadioButton/RadioButton.types'
import { RadioButtonGroupProps } from './RadioButtonGroup.types'

export const RadioButtonGroup = forwardRef<
  HTMLDivElement,
  RadioButtonGroupProps
>((props, ref) => {
  const {
    className,
    value,
    disabled,
    idPrefix,
    isInvalid,
    name,
    onChange,
    radioButtons,
    size = RadioButtonSizeEnum.medium,
    e2eSelector,
  } = props

  const [localValue, setLocalValue] = useState<string | undefined>(value)

  const handleChange = (value: string) => {
    setLocalValue(value)

    if (typeof onChange === 'function') {
      onChange(value)
    }
  }

  const classNames = clsx('RadioButtonGroup', className)

  useEffect(() => {
    setLocalValue(value)
  }, [value])

  return (
    <div
      ref={ref}
      aria-labelledby={props['aria-labelledby']}
      className={classNames}
      data-e2e={e2eSelector}
      role="radiogroup"
    >
      {radioButtons.map(radioButton => (
        <RadioButton
          key={radioButton.value}
          disabled={disabled}
          id={`${idPrefix || ''}${radioButton.value}`}
          isInvalid={isInvalid}
          label={radioButton.label}
          name={name}
          onChange={() => handleChange(radioButton.value)}
          size={size}
          value={radioButton.value === localValue}
        />
      ))}
    </div>
  )
})
