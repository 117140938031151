import { useStore } from 'Stores/index'

/**
 * This hook is used to get either active employee or active organisation information from the store.
 * Please wrap component with the `observer` method from 'react-mobx' for the hook to re-sync data properly.
 */
export function useActiveUser() {
  const { organisationStore, employeeStore } = useStore()
  const { organisation } = organisationStore
  const { employee } = employeeStore

  return {
    activeEmployee: employee,
    activeOrganisation: organisation,
  }
}
