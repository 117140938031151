import { Form } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation } from '@apollo/client'
import { zodResolver } from '@hookform/resolvers/zod'
import { isEmpty } from 'lodash-es'
import { observer } from 'mobx-react'

import { ButtonGroup, Card, CardBody, CardHeader } from 'Components/_theme'
import { AlertWithError } from 'Components/AlertWithError/AlertWithError'
import { FormOptionalMessage } from 'Components/FormOptionalMessage/FormOptionalMessage'

import { useStore } from 'Stores/index'

import { handleFetchErrorFunctional } from 'Utilities'

import {
  E2E_CANCEL_DEVICE_HEADER,
  E2E_CANCEL_DEVICE_SUBMIT,
} from 'Constants/e2e'
import {
  mutationCancelDevice,
  mutationCancelDeviceVariables,
} from 'Constants/graphql/mutations/__generated__/mutationCancelDevice'
import { MUTATION_CANCEL_DEVICE } from 'Constants/graphql/mutations/MutationCancelDevice'

import { iconCancelDevice } from 'Icons/iconCancelDevice'

import { CANCEL_DEVICE_MODAL_FORM } from './CancelDeviceModal.constants'
import { cancelDeviceModalFormSchema } from './CancelDeviceModal.schema'
import {
  CancelDeviceModalFormSchema,
  CancelDeviceModalProps,
} from './CancelDeviceModal.types'
import { cancelDeviceModalDefaultValues } from './CancelDeviceModal.values'

export const CancelDeviceModal = observer((props: CancelDeviceModalProps) => {
  const { handleModalSubmit, onClose, rental } = props
  const { userStore } = useStore()
  const { user } = userStore

  const intl = useIntl()

  const [createCancelDeviceCaseItem, { loading: isLoading, error }] =
    useMutation<mutationCancelDevice, mutationCancelDeviceVariables>(
      MUTATION_CANCEL_DEVICE,
      {
        onCompleted: () => {
          handleModalSubmit()
        },
        onError: error => {
          handleFetchErrorFunctional()(error)
        },
      },
    )

  const formMethods = useForm<CancelDeviceModalFormSchema>({
    defaultValues: {
      ...cancelDeviceModalDefaultValues,
    },

    resolver: zodResolver(cancelDeviceModalFormSchema),
  })

  const hasError = !isEmpty(error)

  const handleSubmit = formMethods.handleSubmit(data => {
    createCancelDeviceCaseItem({
      variables: {
        input: {
          message: data.message,
          organisationID: rental.organisation?.id ?? '0',
          rentalID: rental.id,
          requestingUserID: user.userId?.toString() ?? '0',
        },
      },
    })
  })

  return (
    <Card>
      <CardHeader
        color="red"
        e2eSelector={E2E_CANCEL_DEVICE_HEADER}
        icon={iconCancelDevice}
      >
        <FormattedMessage id="Cancel_device" />
      </CardHeader>

      <CardBody>
        {hasError && <AlertWithError error={error} />}

        <FormProvider {...formMethods}>
          <Form id={CANCEL_DEVICE_MODAL_FORM} onSubmit={handleSubmit}>
            <FormOptionalMessage />
          </Form>
        </FormProvider>

        <ButtonGroup
          leftButton={{
            onClick: onClose,
            text: intl.formatMessage({ id: 'Cancel' }),
          }}
          rightButton={
            !hasError
              ? {
                  'data-e2e': E2E_CANCEL_DEVICE_SUBMIT,
                  disabled: isLoading,
                  form: CANCEL_DEVICE_MODAL_FORM,
                  isLoading,
                  text: intl.formatMessage({
                    id: 'Cancel_device',
                  }),
                  type: 'submit',
                }
              : undefined
          }
        />
      </CardBody>
    </Card>
  )
})
