import { ReplaceBrokenDeviceModalStepsEnum } from './ReplaceBrokenDeviceModal.types'

export const getReplaceBrokenDeviceModalFirstStep = (
  hasDepots: boolean,
  isReplaceFromDepot: boolean,
) => {
  if (isReplaceFromDepot) {
    return ReplaceBrokenDeviceModalStepsEnum.REPLACEMENT_FORM
  }

  if (!hasDepots) {
    return ReplaceBrokenDeviceModalStepsEnum.SHIPPING
  }

  return ReplaceBrokenDeviceModalStepsEnum.LANDING
}
