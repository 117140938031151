import { useCallback, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { observer } from 'mobx-react'

import { Card, CardBody, CardHeader } from 'Components/_theme'

import { iconReassignDevice } from 'Icons/iconReassignDevice'

import { ReassignDeviceModalConfirmation } from './components/ReassignDeviceModalConfirmation'
import { ReassignDeviceModalForm } from './components/ReassignDeviceModalForm'
import {
  ReassignDeviceModalProps,
  ReassignDeviceModalStepsEnum,
  ReassignDeviceModalStepsMap,
} from './ReassignDeviceModal.types'

export const ReassignDeviceModal = observer(
  (props: ReassignDeviceModalProps) => {
    const { handleSubmit, onClose, rental } = props

    const [employeeName, setEmployeeName] = useState('')
    const [currentStep, setCurrentStep] =
      useState<`${ReassignDeviceModalStepsEnum}`>(
        ReassignDeviceModalStepsEnum.FORM,
      )

    const handleFormSubmit = useCallback(() => {
      setCurrentStep(ReassignDeviceModalStepsEnum.CONFIRMATION)
    }, [])

    const stepsMap = useMemo(
      (): ReassignDeviceModalStepsMap => ({
        [ReassignDeviceModalStepsEnum.FORM]: {
          component: ReassignDeviceModalForm,
          props: {
            onClose,
            onSubmit: handleFormSubmit,
            rental,
            setEmployeeName,
          },
        },
        [ReassignDeviceModalStepsEnum.CONFIRMATION]: {
          component: ReassignDeviceModalConfirmation,
          props: {
            employeeName,
            onClose: handleSubmit,
          },
        },
      }),
      [employeeName, handleFormSubmit, handleSubmit, onClose, rental],
    )

    const renderStep = useMemo(() => {
      const { component: Component, props } = stepsMap[currentStep]

      return { component: <Component {...props} /> }
    }, [stepsMap, currentStep])

    return (
      <Card>
        <CardHeader icon={iconReassignDevice}>
          <FormattedMessage id="ReassignDevice_ModalTitle" />
        </CardHeader>

        <CardBody>{renderStep.component}</CardBody>
      </Card>
    )
  },
)
