import { useCurrentRefinements } from 'react-instantsearch'

import { ClearActiveFilters } from '../ClearActiveFilters/ClearActiveFilters'
import { ActiveFiltersItem } from './ActiveFiltersItem'

export const ActiveFilters = () => {
  const { items } = useCurrentRefinements()

  if (items.length === 0) {
    return null
  }

  return (
    <>
      {items.map(item => (
        <ActiveFiltersItem key={item.attribute} {...item} />
      ))}

      <ClearActiveFilters />
    </>
  )
}
