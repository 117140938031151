import { z } from 'zod'

import { FORM_OPTIONAL_MESSAGE_MAX_CHARACTERS } from 'Components/FormOptionalMessage/FormOptionalMessage.constants'

import { FORM_MESSAGES_MAP } from 'Constants/formMessages'

export const legacyUpgradeDeviceModalFormSchema = z.object({
  message: z
    .string()
    .max(FORM_OPTIONAL_MESSAGE_MAX_CHARACTERS, FORM_MESSAGES_MAP.MAX_LENGTH),
})
