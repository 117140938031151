import { SupportedLanguagesEnum } from 'Constants/locale'

export const getPreferredLanguage = () => {
  const preferredLanguage = navigator.languages[0] || window.navigator.language
  const langISO = preferredLanguage.split('-')[0]

  switch (langISO) {
    case SupportedLanguagesEnum.EN:
    case SupportedLanguagesEnum.DE:
    case SupportedLanguagesEnum.FR:
      return langISO
    default:
      return SupportedLanguagesEnum.EN
  }
}
