import { FormattedNumber } from 'react-intl'
import { observer } from 'mobx-react'

import { useActiveUser } from 'Hooks'

import { FormattedCurrencyProps } from './FormattedCurrency.types'

export const FormattedCurrency = observer((props: FormattedCurrencyProps) => {
  const { value } = props
  const { activeOrganisation, activeEmployee } = useActiveUser()
  const currency =
    activeEmployee?.organisation?.currency.valueOf() ??
    activeOrganisation?.currency.valueOf() ??
    'EUR'

  return (
    <FormattedNumber
      currency={currency}
      // eslint-disable-next-line react/style-prop-object
      style="currency"
      value={value}
    />
  )
})
