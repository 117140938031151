import { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { Heading, LinkButton, PrimaryButton } from 'Components/_theme'

import { openIntercomChat } from 'Utilities'

import { setRouteTitle } from 'Portal/src/router/Router.utils'

import { EmployeeTile } from '../EmployeeTile/EmployeeTile'

export const ContactLandingDescription = () => {
  const intl = useIntl()

  useEffect(() => {
    setRouteTitle(
      intl.formatMessage({
        id: 'Contact_us',
      }),
    )
  }, [intl])

  return (
    <div className="ContactLandingDescription">
      <Heading size={1}>
        <FormattedMessage id="ContactDescription_header" />
      </Heading>

      <p className="m-0">
        <FormattedMessage id="ContactLandingDescription_description" />
      </p>

      <PrimaryButton
        className="ContactLandingDescription__button"
        onClick={openIntercomChat}
      >
        <FormattedMessage id="Contact_chat_with_us" />
      </PrimaryButton>

      <div className="ContactLandingDescription__content d-flex w-100">
        <div className="ContactLandingDescription-login border-top pt-6 me-auto">
          <p>
            <FormattedMessage id="Contact_login_text" />
          </p>

          <LinkButton
            className="fw-bold"
            title={intl.formatMessage({ id: 'Contact_login' })}
            to={GLOBAL_ROUTE_PATHS.public.login}
          >
            <FormattedMessage id="Contact_login" />
          </LinkButton>
        </div>

        <div className="ContactLandingDescription-employee-tile d-flex justify-content-end ms-9 d-none d-xl-block">
          <EmployeeTile isLanding />
        </div>
      </div>
    </div>
  )
}
