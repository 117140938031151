import { FormattedMessage } from 'react-intl'
import { faMobileAlt } from '@fortawesome/pro-solid-svg-icons'

import { Card, CardHeader } from 'Components/_theme'

import { EditEmployeeFormModalCreateEmployee } from './components/EditEmployeeFormModalCreateEmployee'
import { EditEmployeeFormModalEditEmployee } from './components/EditEmployeeFormModalEditEmployee'
import { EditEmployeeFormModalProps } from './EditEmployeeFormModal.types'

export const EditEmployeeFormModal = (props: EditEmployeeFormModalProps) => {
  const { employee, onHide, onSuccess } = props
  const isExistingEmployee = !!employee

  return (
    <Card>
      <CardHeader
        icon={faMobileAlt}
        subtitle={<FormattedMessage id="Required_fields" />}
      >
        <FormattedMessage id={employee ? 'Edit_employee' : 'New_employee'} />
      </CardHeader>

      {isExistingEmployee ? (
        <EditEmployeeFormModalEditEmployee
          employee={employee}
          onHide={onHide}
          onSuccess={onSuccess}
        />
      ) : (
        <EditEmployeeFormModalCreateEmployee
          onHide={onHide}
          onSuccess={onSuccess}
        />
      )}
    </Card>
  )
}
