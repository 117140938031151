export enum PortalSectionEnum {
  Dashboard = 'dashboard',
  Depots = 'depots',
  Employees = 'employees',
  Invoices = 'invoices',
  PhonePlans = 'phone-plans',
  Purchases = 'purchases',
  Rentals = 'rentals',
  Orders = 'orders',
  Contact = 'contact',
  DepotDetails = 'depot-details',
  EmployeeDetails = 'employee-details',
  PhonePlanDetails = 'phone-plan-details',
  RentalDetails = 'rental-details',
  OrderDetails = 'order-details',
  NewOrder = 'new-order',
  DataPrivacy = 'data-privacy',
  Account = 'account',
  Returns = 'returns',
  ReturnDetails = 'return-details',
  OrdersAndReturns = 'orders-and-returns',
  Sitemap = 'sitemap',
  LoginPage = 'login-page',
}

export const PORTAL_HEADING_TRANSLATION_IDS: Record<PortalSectionEnum, string> =
  {
    [PortalSectionEnum.Dashboard]: 'Dashboard',
    [PortalSectionEnum.Depots]: 'Depots',
    [PortalSectionEnum.DepotDetails]: 'Depot_details',
    [PortalSectionEnum.Employees]: 'Employees',
    [PortalSectionEnum.EmployeeDetails]: 'Employee_details',
    [PortalSectionEnum.Invoices]: 'Invoices',
    [PortalSectionEnum.PhonePlans]: 'Phone_plans',
    [PortalSectionEnum.PhonePlanDetails]: 'Phone_plan_details',
    [PortalSectionEnum.Purchases]: 'Purchases',
    [PortalSectionEnum.Rentals]: 'Rentals',
    [PortalSectionEnum.Orders]: 'Orders',
    [PortalSectionEnum.Contact]: 'Contact_us',
    [PortalSectionEnum.RentalDetails]: 'Rental_details',
    [PortalSectionEnum.OrderDetails]: 'Order_details',
    [PortalSectionEnum.NewOrder]: 'Choose_new_device',
    [PortalSectionEnum.DataPrivacy]: 'DataPrivacy_footer_title',
    [PortalSectionEnum.Account]: 'My_account',
    [PortalSectionEnum.Returns]: 'Returns',
    [PortalSectionEnum.ReturnDetails]: 'Return_details',
    [PortalSectionEnum.OrdersAndReturns]: 'Orders_and_returns',
    [PortalSectionEnum.Sitemap]: 'Sitemap',
    [PortalSectionEnum.LoginPage]: 'Login_page',
  }
