import { ChangeEvent } from 'react'
import { useIntl } from 'react-intl'
import TextareaAutosize from 'react-textarea-autosize'

import { Textarea } from 'Components/_theme'

import { TEXTAREA_CHARACTER_LIMIT } from './OrderSummary.constants'
import { OrderSummaryTextFieldProps } from './OrderSummary.types'

export const OrderSummaryTextField = (props: OrderSummaryTextFieldProps) => {
  const { controlId, defaultValue, onChangeLabel, placeholderTranslationId } =
    props
  const intl = useIntl()
  const placeholder = intl.formatMessage(
    {
      id: placeholderTranslationId,
    },
    {
      limit: TEXTAREA_CHARACTER_LIMIT,
    },
  )

  const handleOnChangeLabel = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length > TEXTAREA_CHARACTER_LIMIT) {
      return
    }

    onChangeLabel(event)
  }

  return (
    <Textarea
      aria-label={placeholder}
      charactersCount={defaultValue.length}
      className="pt-2"
      customComponent={TextareaAutosize}
      defaultValue={defaultValue}
      id={controlId}
      maxLength={TEXTAREA_CHARACTER_LIMIT}
      onChange={handleOnChangeLabel}
      placeholder={placeholder}
      showCounter
      size="small"
    />
  )
}
