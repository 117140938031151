import { clsx } from 'clsx'

import { Button } from 'Components/_theme/Button/Button'

import { SecondaryButtonProps } from './SecondaryButton.types'

export const SecondaryButton = (props: SecondaryButtonProps) => {
  const { className, variant = 'basic', ...restProps } = props

  const classNames = clsx(
    'SecondaryButton',
    variant !== 'basic' && `SecondaryButton-${variant}`,
    className,
  )

  return <Button className={classNames} {...restProps} />
}
