import { useCallback } from 'react'
import { useMountEffect } from '@react-hookz/web'
import { observer } from 'mobx-react'

import { ScrollToTop } from 'Components/ScrollToTop/ScrollToTop'

import { useStore } from 'Stores/index'

import { OrderStepEnum } from 'Portal/src/stores/orderStore/orderStore.types'

import { AccessoriesChooser } from '../../AccessoriesChooser/AccessoriesChooser'
import { AccessoriesStepProps } from '../Steps.types'

export const AccessoriesStep = observer((props: AccessoriesStepProps) => {
  const { handleNextStep, handlePreviousStep } = props
  const { orderStore } = useStore()
  const {
    chosenAccessories,
    clearChosenAccessories,
    clearChosenDeviceLimitationRules,
    toggleAccessorySelection,
    accessories,
    limitationRules,
    setCurrentStep,
  } = orderStore

  const handleNextAction = useCallback(() => {
    if (handleNextStep) {
      handleNextStep()
    }
  }, [handleNextStep])

  const handlePreviousAction = useCallback(() => {
    if (handlePreviousStep) {
      clearChosenDeviceLimitationRules()

      handlePreviousStep()
    }
  }, [clearChosenDeviceLimitationRules, handlePreviousStep])

  useMountEffect(() => {
    setCurrentStep(OrderStepEnum.accessories)
  })

  return (
    <>
      <ScrollToTop />

      <AccessoriesChooser
        accessories={accessories || []}
        chosenAccessories={chosenAccessories}
        limitationRules={limitationRules}
        onBackButtonClick={() => {
          clearChosenAccessories()
          handlePreviousAction()
        }}
        onNextButtonClick={handleNextAction}
        onSkipButtonClick={() => {
          clearChosenAccessories()
          handleNextAction()
        }}
        toggleAccessorySelection={toggleAccessorySelection}
      />
    </>
  )
})
