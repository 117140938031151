import { isEmpty } from 'lodash-es'

import {
  getCatalog_catalog_offers_offerConfigGroups,
  getCatalog_catalog_offers_offerConfigGroups_descriptions,
  getCatalog_catalog_offers_offerConfigGroups_offerConfigs_offerConfigs,
  getCatalog_catalog_offers_offerConfigGroups_offerConfigs_offerConfigs_offer_offerItems_offerItems,
  getCatalog_catalog_rentCategories_rentcategories,
} from 'Constants/graphql/queries/feature/__generated__/getCatalog'
import { SupportedLanguagesEnum } from 'Constants/locale'

import {
  OrderDevice,
  OrderDeviceGroup,
  OrderDeviceItemPayload,
  OrderDeviceMappedResponse,
  OrderDeviceResponseVariant,
} from './orderDevice.types'

const processProduceItem = (
  productOfferItem: getCatalog_catalog_offers_offerConfigGroups_offerConfigs_offerConfigs_offer_offerItems_offerItems,
  currentOffer: getCatalog_catalog_offers_offerConfigGroups_offerConfigs_offerConfigs,
): OrderDevice => ({
  basisAmount: productOfferItem.basePrice,
  bundleBasisAmount: currentOffer.offer?.totalBasisPrice,
  bundleCopaymentGross: currentOffer.offer?.totalCopaymentGross,
  bundleCopaymentNet: currentOffer.offer?.totalCopaymentNet,
  calculatedNet: 0,
  copaymentGross: productOfferItem.copaymentGross,
  copaymentNet: productOfferItem.copaymentPrice,
  customDeviceName: productOfferItem.customDeviceName,
  deviceDefinition: productOfferItem.deviceDefinition,
  forcePriceHide: currentOffer.forcePriceHide,
  id: `${currentOffer.offer?.id}_${productOfferItem.id}`,
  isCreateSeparateRental: productOfferItem.isCreateSeparateRental,
  minimumContractDuration: productOfferItem.rentalDuration,
  offerId: currentOffer.offer?.id || '',
  offerItemId: productOfferItem.id,
  quantity: 1,
  rentCategoryId: currentOffer.offer?.id || '',
})

const getAllDevices = (groupedDevices: OrderDeviceGroup[]) =>
  groupedDevices.reduce<OrderDevice[]>(
    (finalDevicesList, currentDeviceGroup) => [
      ...finalDevicesList,
      ...currentDeviceGroup.devices,
    ],
    [],
  )

const getRentCategoryDescription = (
  rentCategory: getCatalog_catalog_rentCategories_rentcategories,
  activeLanguage: string,
) => {
  const { descriptionDe, descriptionEn } = rentCategory

  if (!descriptionDe || !descriptionEn) {
    return null
  }

  if (activeLanguage === SupportedLanguagesEnum.DE) {
    return descriptionDe
  }

  return descriptionEn
}

const getProductOffersDescription = (
  productOfferDescriptions: getCatalog_catalog_offers_offerConfigGroups_descriptions | null,
  activeLanguage: string,
) => {
  const productOfferDescription = productOfferDescriptions?.descriptions?.find(
    productOfferDescription =>
      productOfferDescription.language === activeLanguage,
  )

  return productOfferDescription?.content || null
}

export const productOffersDeviceMapper = (
  productOfferGroups: getCatalog_catalog_offers_offerConfigGroups[] | null,
): OrderDeviceMappedResponse | null => {
  if (!productOfferGroups) {
    return null
  }

  const groupedDevices = productOfferGroups.map<OrderDeviceGroup>(
    productOfferGroup => {
      const productOffersConfigs = productOfferGroup.offerConfigs?.offerConfigs

      const devices = productOffersConfigs?.reduce<OrderDevice[]>(
        (offerDevicesList, currentOffer) => {
          const primaryDevice =
            currentOffer.offer?.offerItems?.offerItems?.find(
              offerItem => offerItem.isPrimaryDevice,
            )
          const addonDevices =
            currentOffer.offer?.offerItems?.offerItems?.filter(
              offerItem => !offerItem.isPrimaryDevice && offerItem.isMandatory,
            )

          const optionalDevices =
            currentOffer.offer?.offerItems?.offerItems?.filter(
              offerItem => !offerItem.isPrimaryDevice && !offerItem.isMandatory,
            )

          const termsLabels = currentOffer.terms?.offerTerms ?? []

          if (primaryDevice) {
            const offerDevices: OrderDevice = {
              bundleImage:
                currentOffer.offer?.customImage ||
                primaryDevice.deviceDefinition.imageURL,
              bundleName:
                currentOffer.offer?.alternativeName ||
                primaryDevice.customDeviceName ||
                primaryDevice.deviceDefinition.name,
              bundleQuantity:
                addonDevices && !isEmpty(addonDevices)
                  ? addonDevices.length + 1
                  : 1,
              isBundle: !isEmpty(addonDevices),
              ...processProduceItem(primaryDevice, currentOffer),
              addonDevices:
                addonDevices?.map(addonDevice =>
                  processProduceItem(addonDevice, currentOffer),
                ) || [],
              hasDeviceWithCoPaymentPrice:
                currentOffer.hasDeviceWithCoPaymentPrice ?? false,
              optionalDevices:
                optionalDevices?.map(addonDevice =>
                  processProduceItem(addonDevice, currentOffer),
                ) || [],
              termsLabels,
            }

            return [...offerDevicesList, offerDevices]
          }

          return offerDevicesList
        },
        [],
      )

      return {
        descriptionDE: getProductOffersDescription(
          productOfferGroup.descriptions,
          SupportedLanguagesEnum.DE,
        ),
        descriptionEN: getProductOffersDescription(
          productOfferGroup.descriptions,
          SupportedLanguagesEnum.EN,
        ),
        descriptionFR: getProductOffersDescription(
          productOfferGroup.descriptions,
          SupportedLanguagesEnum.FR,
        ),
        devices: devices || [],
        displaySortOrder: productOfferGroup.displaySortOrder,
        id: productOfferGroup.id,
        name: productOfferGroup.name,
      }
    },
  )

  const allDevices = getAllDevices(groupedDevices)

  return {
    allDevices,
    groupedDevices,
    variant: OrderDeviceResponseVariant.CATALOG,
  }
}

export const rentCategoriesDeviceMapper = (
  rentCategories: getCatalog_catalog_rentCategories_rentcategories[] | null,
): OrderDeviceMappedResponse | null => {
  if (!rentCategories) {
    return null
  }

  const groupedDevices = rentCategories.map<OrderDeviceGroup>(rentCategory => {
    const deviceDefinitions = rentCategory.deviceDefinitions?.deviceDefinitions

    const devices = deviceDefinitions?.map<OrderDevice>(deviceDefinition => ({
      basisAmount: rentCategory.displayedBasisAmount,
      calculatedNet: 0,
      copaymentGross: rentCategory.copaymentGross,
      copaymentNet: rentCategory.copaymentNet,
      deviceDefinition,
      forcePriceHide: rentCategory.forcePriceHide,
      id: `${rentCategory.id}_${deviceDefinition.id}`,
      minimumContractDuration: rentCategory.minimumContractDuration,
      offerId: rentCategory.id,
      quantity: 1,
      rentCategoryId: rentCategory.id,
    }))

    return {
      descriptionDE: getRentCategoryDescription(
        rentCategory,
        SupportedLanguagesEnum.DE,
      ),
      descriptionEN: getRentCategoryDescription(
        rentCategory,
        SupportedLanguagesEnum.EN,
      ),
      devices: devices || [],
      displaySortOrder: rentCategory.displaySortOrder,
      id: rentCategory.id,
      name: rentCategory.name,
    }
  })

  const allDevices = getAllDevices(groupedDevices)

  return {
    allDevices,
    groupedDevices,
    variant: OrderDeviceResponseVariant.RENT_CATEGORY,
  }
}

export const productOffersDeviceItemPayloadMapper = (
  orderDeviceItem: OrderDevice | null,
  chosenAccessories: OrderDevice[] | null,
): OrderDeviceItemPayload[] => {
  if (!orderDeviceItem) {
    return []
  }

  const addonItems = orderDeviceItem.addonDevices?.map<OrderDeviceItemPayload>(
    addonDevice => ({
      offerItemId: addonDevice.offerItemId || '',
      quantity: addonDevice.quantity,
      ...(addonDevice.customerLabel && {
        customerLabel: addonDevice.customerLabel,
      }),
      ...(addonDevice.billingIdentifier && {
        billingIdentifier: addonDevice.billingIdentifier,
      }),
      ...(addonDevice.customClientReference && {
        customClientReference: addonDevice.customClientReference,
      }),
      ...(addonDevice.poRcn && {
        poRcn: addonDevice.poRcn,
      }),
    }),
  )

  const accessoryItems = chosenAccessories?.map<OrderDeviceItemPayload>(
    accessoryDevice => ({
      offerItemId: accessoryDevice.offerItemId || '',
      quantity: accessoryDevice.quantity,
      ...(accessoryDevice.customerLabel && {
        customerLabel: accessoryDevice.customerLabel,
      }),
      ...(accessoryDevice.billingIdentifier && {
        billingIdentifier: accessoryDevice.billingIdentifier,
      }),
      ...(accessoryDevice.customClientReference && {
        customClientReference: accessoryDevice.customClientReference,
      }),
      ...(accessoryDevice.poRcn && {
        poRcn: accessoryDevice.poRcn,
      }),
    }),
  )

  return [
    {
      offerItemId: orderDeviceItem.offerItemId || '',
      quantity: orderDeviceItem.quantity,
      ...(orderDeviceItem.customerLabel && {
        customerLabel: orderDeviceItem.customerLabel,
      }),
      ...(orderDeviceItem.billingIdentifier && {
        billingIdentifier: orderDeviceItem.billingIdentifier,
      }),
      ...(orderDeviceItem.customClientReference && {
        customClientReference: orderDeviceItem.customClientReference,
      }),
      ...(orderDeviceItem.poRcn && {
        poRcn: orderDeviceItem.poRcn,
      }),
    },
    ...(addonItems || []),
    ...(accessoryItems || []),
  ]
}
