import {
  IMAGE_PLACEHOLDER_MAP,
  PLACEHOLDER_ACCESSORY,
} from 'Constants/imagePlaceholders'

import { ImagePlaceholderTypes } from 'Types/imagePlaceholders.type'

export const getPlaceholderImage = (
  placeholderType?: ImagePlaceholderTypes,
) => {
  if (!placeholderType) {
    return PLACEHOLDER_ACCESSORY
  }

  return IMAGE_PLACEHOLDER_MAP[placeholderType]
}
