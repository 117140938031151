import { Controller, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { GroupBase } from 'react-select'
import { clsx } from 'clsx'

import { Select } from 'Components/Select/Select'
import { SelectOption } from 'Components/Select/Select.types'

import { FieldSelectProps } from './Field.types'
import { parseLabel } from './Field.utils'

export const FieldSelect = <
  Option extends SelectOption,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: FieldSelectProps<Option, IsMulti, Group>,
) => {
  const {
    name,
    selectProps,
    placeholder,
    hasError,
    e2eSelector,
    markAsRequired,
  } = props
  const intl = useIntl()
  const { control, watch } = useFormContext()
  const isFilled = !!watch(name)

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, onChange, ...field } }) => (
        <Select
          ref={ref}
          {...field}
          {...selectProps}
          className={clsx(
            hasError && 'ReactSelect--hasError is-invalid',
            selectProps?.className,
          )}
          inputId={name}
          instanceId={e2eSelector}
          isFilled={isFilled}
          markAsRequired={markAsRequired}
          onChange={(selected, actionMeta) =>
            typeof selectProps?.onChange === 'function'
              ? onChange(selectProps?.onChange(selected, actionMeta))
              : onChange(selected)
          }
          placeholder={parseLabel(intl, placeholder)}
        />
      )}
    />
  )
}
