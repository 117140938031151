import { gql } from '@apollo/client'

export const GET_PHONE_PLAN_PRICING = gql`
  query getPhonePlanPricing {
    phonePlanPricing {
      totalCount
      phonePlanPricing {
        name
        provider
        priceForCustomerPortal
        includedDataVolumeInGb
      }
    }
  }
`
