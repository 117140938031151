import {
  IconDefinition,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core'

export const iconAssignDevice: IconDefinition = {
  icon: [
    32,
    32,
    [],
    'e001',
    'M9.287 0.059 C 8.801 0.116,7.945 0.403,7.544 0.645 C 6.494 1.279,5.781 2.216,5.479 3.360 L 5.360 3.813 5.360 16.000 L 5.360 28.187 5.479 28.640 C 5.785 29.801,6.557 30.801,7.600 31.387 C 8.056 31.643,8.807 31.881,9.362 31.944 C 10.011 32.019,21.989 32.019,22.640 31.944 C 23.204 31.880,24.028 31.612,24.456 31.355 C 25.471 30.742,26.138 29.896,26.490 28.771 L 26.640 28.293 26.640 16.000 L 26.640 3.707 26.490 3.229 C 26.133 2.088,25.406 1.178,24.400 0.613 C 23.944 0.357,23.193 0.119,22.638 0.056 C 22.053 -0.011,9.866 -0.008,9.287 0.059 M24.000 16.000 L 24.000 27.680 16.000 27.680 L 8.000 27.680 8.000 16.000 L 8.000 4.320 16.000 4.320 L 24.000 4.320 24.000 16.000 M15.566 9.389 C 14.343 9.574,13.347 10.508,13.094 11.707 C 12.951 12.386,13.014 12.951,13.303 13.573 C 13.769 14.576,14.699 15.218,15.796 15.293 C 18.087 15.450,19.679 13.067,18.663 10.999 C 18.468 10.602,18.026 10.107,17.632 9.846 C 17.080 9.479,16.222 9.290,15.566 9.389 M14.880 16.935 C 12.878 17.212,11.126 17.943,10.432 18.790 C 10.084 19.215,10.027 19.450,10.027 20.454 L 10.027 21.333 16.000 21.333 L 21.973 21.333 21.973 20.454 C 21.973 19.450,21.916 19.215,21.568 18.790 C 20.526 17.518,17.241 16.607,14.880 16.935',
  ],
  iconName: 'assignDevice' as IconName,
  prefix: 'fac' as IconPrefix,
}
