import { clsx } from 'clsx'

import { LinkButton } from '../LinkButton/LinkButton'
import { PrimaryButton } from '../PrimaryButton/PrimaryButton'
import { ButtonGroupProps } from './ButtonGroup.types'

export const ButtonGroup = (props: ButtonGroupProps) => {
  const { className, leftButton, linkButton, rightButton } = props

  const classNames = clsx(
    'ButtonGroup',
    !leftButton && 'ButtonGroup-push-right',
    className,
  )

  return (
    <div className={classNames}>
      {leftButton && (
        <PrimaryButton
          appearance="outline"
          aria-label={leftButton.text}
          {...leftButton}
        >
          {leftButton.text}
        </PrimaryButton>
      )}

      <div>
        {linkButton && (
          <LinkButton
            aria-label={linkButton.text}
            title={linkButton.text}
            {...linkButton}
          >
            {linkButton.text}
          </LinkButton>
        )}

        {rightButton && (
          <PrimaryButton
            aria-label={rightButton.text}
            className="ButtonGroup-right"
            {...rightButton}
          >
            {rightButton.text}
          </PrimaryButton>
        )}
      </div>
    </div>
  )
}
