import { FormProvider, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { faTruckArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { zodResolver } from '@hookform/resolvers/zod'
import { observer } from 'mobx-react'

import { ButtonGroup, Card, CardBody, CardHeader } from 'Components/_theme'
import { CountrySelect } from 'Components/CountrySelect/CountrySelect'
import { Form } from 'Components/Form/Form'

import { useActiveUser } from 'Hooks'

import {
  E2E_CREATE_SHIPPING_LABEL_COUNTRY,
  E2E_CREATE_SHIPPING_LABEL_HEADER,
  E2E_CREATE_SHIPPING_LABEL_SUBMIT,
} from 'Constants/e2e'

import { useShippingLabelCountryList } from 'Portal/src/hooks/useShippingLabelCountryList/useShippingLabelCountryList'

import { createReturnLabelModalFormSchema } from './CreateReturnLabel.schema'
import {
  CreateReturnLabelModalFormSchema,
  CreateReturnLabelModalProps,
} from './CreateReturnLabel.types'
import { getReturnLabelURL } from './CreateReturnLabel.utils'
import { CREATE_RETURN_LABEL_MODAL_FORM } from './CreateReturnLabelModal.constants'
import { createReturnLabelModalDefaultValues } from './CreateReturnLabelModal.values'

export const CreateReturnLabelModal = observer(
  (props: CreateReturnLabelModalProps) => {
    const { onClose, returnCase } = props

    const { activeOrganisation, activeEmployee } = useActiveUser()
    const organisation =
      activeOrganisation || activeEmployee?.organisation || null

    const intl = useIntl()

    const countries = useShippingLabelCountryList()

    const formMethods = useForm<CreateReturnLabelModalFormSchema>({
      defaultValues: createReturnLabelModalDefaultValues(
        organisation,
        countries,
      ),
      resolver: zodResolver(createReturnLabelModalFormSchema),
    })

    const handleSubmit = formMethods.handleSubmit(data => {
      // Done this way because window.open doesn't open in new tab in Safari
      const a = document.createElement('a')

      a.setAttribute(
        'href',
        getReturnLabelURL(
          organisation?.id,
          returnCase.id,
          data.country?.value,
          organisation?.fulfillmentProviderMapping || null,
        ) || '',
      )
      a.setAttribute('target', '_blank')
      a.click()

      onClose()
    })

    return (
      <Card>
        <CardHeader
          e2eSelector={E2E_CREATE_SHIPPING_LABEL_HEADER}
          icon={faTruckArrowRight}
        >
          <FormattedMessage id="DHL_Return_Label" />
        </CardHeader>

        <CardBody>
          <FormProvider {...formMethods}>
            <Form id={CREATE_RETURN_LABEL_MODAL_FORM} onSubmit={handleSubmit}>
              <CountrySelect
                containerClassName="mt-2"
                e2eSelector={E2E_CREATE_SHIPPING_LABEL_COUNTRY}
                isShippingLabelList
                label="Sender_country"
                markAsRequired
                name="country"
              />
            </Form>
          </FormProvider>

          <ButtonGroup
            leftButton={{
              onClick: onClose,
              text: intl.formatMessage({ id: 'Cancel' }),
            }}
            rightButton={{
              'data-e2e': E2E_CREATE_SHIPPING_LABEL_SUBMIT,
              form: CREATE_RETURN_LABEL_MODAL_FORM,
              text: intl.formatMessage({
                id: 'Continue',
              }),
              type: 'submit',
            }}
          />
        </CardBody>
      </Card>
    )
  },
)
