import type * as React from 'react'
import { clsx } from 'clsx'
import { observer } from 'mobx-react'
import SimpleBar from 'simplebar-react'

import { Logo } from 'Components/Logo/Logo'
import { PortalMenu } from 'Components/PortalMenu/PortalMenu'

import { useStore } from 'Stores/index'
import { UserModeEnum } from 'Stores/portalSettingsStore/portalSettingsStore.types'

import { useActiveUser, useMainMenuItems, useTheme } from 'Hooks'

import poweredByLogo from 'Assets/images/logos/powered_by_everphone.svg'

const simpleBarStyles: React.CSSProperties = {
  maxHeight: '100%',
  overflowX: 'hidden',
}

export const LeftSidebar = observer(() => {
  const { portalSettingsStore } = useStore()
  const { isCompactSidebar, isMenuVisible, toggleMenuVisibility, userMode } =
    portalSettingsStore

  const { activeEmployee, activeOrganisation } = useActiveUser()

  const { isEverphoneTheme } = useTheme()

  const { mainMenuItems } = useMainMenuItems()

  return (
    <>
      <div
        className={clsx(
          'left-side-menu',
          'mt-2',
          isMenuVisible && 'left-side-menu-open',
          isCompactSidebar && 'left-side-menu-compact',
          userMode === UserModeEnum.NEW_JOINER && 'left-side-menu-closed',
        )}
      >
        <Logo asLink={userMode !== UserModeEnum.NEW_JOINER} showAdminBadge />

        {!activeOrganisation && !activeEmployee ? null : (
          <SimpleBar
            scrollbarMaxSize={320}
            style={simpleBarStyles}
            tabIndex={-1}
          >
            <PortalMenu menuItems={mainMenuItems} />
          </SimpleBar>
        )}

        {!isEverphoneTheme && !isCompactSidebar && (
          <img
            alt="Powered by Everphone"
            className="powered-by w-100 position-absolute"
            role="region"
            src={poweredByLogo}
          />
        )}
      </div>

      <div
        className={clsx(!isMenuVisible && 'd-none')}
        onClick={toggleMenuVisibility}
        role="none"
      />
    </>
  )
})
