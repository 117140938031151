import { FormattedMessage, useIntl } from 'react-intl'
import { faEnvelope, faSquarePhone } from '@fortawesome/pro-solid-svg-icons'

import { Card, CardBody, Heading, LinkButton } from 'Components/_theme'
import { Icon } from 'Components/Icon/Icon'

import contactDetailsIllustration from 'Assets/images/contact/contact_details_illustration.svg'

export const ContactDetails = () => {
  const intl = useIntl()

  return (
    <Card>
      <CardBody className="h-100 d-flex align-items-center justify-content-center">
        <div className="ContactDetails d-flex flex-column flex-md-row flex-xl-column w-100">
          <div className="col-12 col-md-8 col-xl-12">
            <Heading as={3} className="mb-4" size={2}>
              <FormattedMessage id="ContactDetails_header" />
            </Heading>

            <p className="ContactDetails-description text-center mb-9">
              <FormattedMessage id="ContactDetails_description" />
            </p>

            <a
              className="d-none d-xl-flex align-items-center mb-6"
              href="mailto:service@everphone.de"
              title={intl.formatMessage({ id: 'Email' })}
            >
              <Icon className="me-2" icon={faEnvelope} /> service@everphone.de
            </a>

            <a
              className="d-none d-xl-flex align-items-center mb-9"
              href="tel:4930516958275"
              title={intl.formatMessage({ id: 'Phone' })}
            >
              <Icon className="me-2" icon={faSquarePhone} /> +49 30 516958275
            </a>

            <div className="d-flex d-xl-none mb-9 justify-content-center">
              <LinkButton
                asExternalLink
                className="me-2"
                iconLeft={faEnvelope}
                title={intl.formatMessage({ id: 'Email' })}
                to="mailto:service@everphone.de"
              >
                <FormattedMessage id="Email" />
              </LinkButton>

              <LinkButton
                asExternalLink
                iconLeft={faSquarePhone}
                title={intl.formatMessage({ id: 'Phone' })}
                to="tel:4930516958275"
              >
                <FormattedMessage id="Phone" />
              </LinkButton>
            </div>

            <div className="ContactDetails-service text-center mb-6 mb-md-0 mb-xl-9">
              <FormattedMessage id="ContactDetails_openingHours" />
              <br />
              <FormattedMessage id="ContactDetails_service" />
            </div>
          </div>

          <div className="col-12 col-md-4 col-xl-12 d-flex justify-content-center align-items-center">
            <img
              alt={intl.formatMessage({ id: 'Img_alt_contact_guy' })}
              className="ContactDetails-illustration img-fluid"
              src={contactDetailsIllustration}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  )
}
