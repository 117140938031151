import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { faMessageDots } from '@fortawesome/pro-solid-svg-icons'
import { isNumber } from 'lodash-es'

import { GLOBAL_ROUTE_PATHS } from 'Router/Router.constants'

import { Card, CardBody, TertiaryButton } from 'Components/_theme'
import { Icon } from 'Components/Icon/Icon'

import { useStore } from 'Stores/index'

import { PORTAL_CONTENT_MODEL } from 'Constants/portalContentModel'

import { ContentTypeEnum } from 'Types/content/content.types'

import { composePath } from 'Portal/src/utilities/composePath/composePath.util'

import { StatisticCardChart } from '../StatisticCardChart/StatisticCardChart'
import { getLineColor } from '../StatisticCardChart/StatisticCardChart.util'
import { DashboardStatisticCardProps } from './DashboardStatisticCard.types'
import { getProgressIcon } from './DashboardStatisticCard.util'

export const DashboardStatisticCard = (props: DashboardStatisticCardProps) => {
  const intl = useIntl()
  const {
    count,
    contentType,
    data,
    labels,
    progress,
    title,
    secondaryTitle,
    secondaryCount,
  } = props
  const { portalSettingsStore } = useStore()
  const { i18n } = portalSettingsStore
  const history = useHistory()
  const { organisationId } = useParams<{
    organisationId?: string
  }>()

  const {
    icon,
    path: modelPath,
    translationId,
  } = PORTAL_CONTENT_MODEL[contentType]

  const handleButtonClick = () => {
    if (contentType === ContentTypeEnum.phone_plans && count === 0) {
      window.open(
        'https://everphone.com/en/service/business-phone-plans/',
        '_blank',
      )

      return
    }

    history.push(
      composePath({
        params: {
          organisationId,
        },
        path: `${GLOBAL_ROUTE_PATHS.organisation.dashboard}/${modelPath}`,
      }),
    )
  }

  return (
    <Card className="DashboardStatisticCard">
      <CardBody className="h-100 d-flex flex-column justify-content-between">
        <div className="d-flex">
          <div className="mb-1">
            <p className="DashboardStatisticCard-title mb-1">
              {title
                ? intl.formatMessage({ id: title })
                : intl.formatMessage({ id: translationId })}
            </p>

            {contentType === ContentTypeEnum.phone_plans && count === 0 ? (
              <span className="h5">
                {intl.formatMessage({ id: 'Inactive' })}
              </span>
            ) : (
              <span className="h4">{count.toLocaleString(i18n.locale)}</span>
            )}
          </div>

          <div className="ms-auto d-flex align-items-center">
            <TertiaryButton
              aria-label={intl.formatMessage({
                id: translationId,
              })}
              asIcon={icon}
              onClick={handleButtonClick}
            />
          </div>
        </div>

        {!!data && !!labels && isNumber(progress) && (
          <>
            <div className="d-flex align-items-center mb-1">
              {contentType === ContentTypeEnum.phone_plans && count === 0 ? (
                <>
                  <Icon className="me-2" icon={faMessageDots} size={16} />

                  <FormattedMessage id="DashboardStatisticCard_contact_us" />
                </>
              ) : (
                <>
                  <Icon
                    className="me-2"
                    color={getLineColor(progress)}
                    icon={getProgressIcon(progress)}
                    size={16}
                  />

                  <FormattedMessage
                    id="DashboardStatisticCard_this_month"
                    values={{ percent: progress }}
                  />
                </>
              )}
            </div>

            <StatisticCardChart
              data={data}
              label={translationId}
              labels={labels}
              progress={progress}
              title={title}
            />
          </>
        )}

        {!!secondaryTitle && isNumber(secondaryCount) && (
          <div className="d-flex mt-4">
            <div className="mb-1">
              <p className="DashboardStatisticCard-title mb-1">
                {intl.formatMessage({ id: secondaryTitle })}
              </p>

              <span className="h4">
                {secondaryCount.toLocaleString(i18n.locale)}
              </span>

              {contentType === ContentTypeEnum.employees &&
                secondaryCount > 0 && (
                  <span className="ms-2">
                    <FormattedMessage
                      id="Percent_of_total"
                      values={{
                        total: ((secondaryCount / count) * 100).toFixed(1),
                      }}
                    />
                  </span>
                )}
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  )
}
